import { capitalize } from 'lodash';

const WEEKDAYS = {
  1: 'segunda',
  2: 'terça',
  3: 'quarta',
  4: 'quinta',
  5: 'sexta',
  6: 'sábado',
  7: 'domingo'
};

export default class WorkingHours {
  /**
   * @param {object} params
   * @param {number[]} params.weekday - Array of weekdays from 1 (monday) to 7 (sunday)
   * @param {string} params.openAt - Ex: 09:00
   * @param {string} params.closeAt - Ex: 18:00
   */
  constructor({ weekday, openAt, closeAt }) {
    this.weekday = weekday.filter(day => !!WEEKDAYS[day]);
    this.openAt = openAt;
    this.closeAt = closeAt;
  }

  /**
   * Returns the formatted working hours for a given weekday range
   *
   * @example
   * const workingHours = new WorkingHours({
   *  weekday: [1, 2, 3, 4, 5],
   *  openAt: '09:00',
   *  closeAt: '18:00'
   * });
   * workingHours.formattedWorkingHours(); // 'Segunda à sexta (09:00 • 18:00)'
   *
   * @example
   * const workingHours = new WorkingHours({
   *  weekday: [1, 2],
   *  openAt: '09:00',
   *  closeAt: '18:00'
   * });
   * workingHours.formattedWorkingHours(); // 'Segunda e terça (09:00 • 18:00)'
   *
   * @example
   * const workingHours = new WorkingHours({
   *  weekday: [6],
   *  openAt: '09:00',
   *  closeAt: '13:00'
   * });
   * workingHours.formattedWorkingHours(); // 'Sábados (09:00 • 13:00)'
   *
   * @example
   * const workingHours = new WorkingHours({
   *  weekday: [1, 2, 5],
   *  openAt: '09:00',
   *  closeAt: '13:00'
   * });
   * workingHours.formattedWorkingHours(); // 'Segunda, terça e sextas (09:00 • 13:00)'
   *
   * @returns {string}
   */
  formattedWorkingHours() {
    if (this.weekday.length === 1) {
      return capitalize(`${this.formatSingleDay()} ${this.formatHours()}`);
    }

    if (this.weekday.length === 2) {
      return capitalize(`${this.formatTwoDays()} ${this.formatHours()}`);
    }

    return capitalize(`${this.formatRangeDays()} ${this.formatHours()}`);
  }

  formatHours() {
    return `(${this.openAt} • ${this.closeAt})`;
  }

  formatSingleDay() {
    return `${WEEKDAYS[this.weekday[0]]}s`;
  }

  formatTwoDays() {
    return `${WEEKDAYS[this.weekday[0]]} e ${WEEKDAYS[this.weekday[1]]}`;
  }

  formatRangeDays() {
    if (this.isContinuousDays()) return this.formatContinuousDays();

    return this.formatNonContinuousDays();
  }

  isContinuousDays() {
    let isContinuous = true;
    this.weekday.forEach((day, index) => {
      if (index === 0) return;
      if (day - this.weekday[index - 1] !== 1) {
        isContinuous = false;
      }
    });

    return isContinuous;
  }

  formatContinuousDays() {
    return `${WEEKDAYS[this.weekday[0]]} à ${
      WEEKDAYS[this.weekday[this.weekday.length - 1]]
    }`;
  }

  formatNonContinuousDays() {
    const days = this.weekday.map(day => WEEKDAYS[day]);
    const lastDay = days.pop();

    return `${days.join(', ')} e ${lastDay}`;
  }
}
