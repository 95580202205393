import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';
import { blue, root } from 'theme/colors';
import InfoLine from '../info-line/info-line.component';

const buttonStyle = {
  backgroundColor: blue[500],
  color: root[0],
  display: 'flex',
  fontWeight: 700,
  justifyContent: 'center',
  padding: '12px',
  width: 'fit-content',
  borderRadius: '8px'
};

const DropOffDeliveryInfo = ({
  senderPostalCode,
  pickupScheduledUpdatedDate
}) => {
  const history = useHistory();
  const { t } = useTranslation('ui');

  const redirectToServiceAgenciesPage = () => {
    const route = ROUTES.dropOffAgenciesLocation
      .replace(':senderPostalCode', senderPostalCode)
      .replace(':pickupScheduledUpdatedDate', pickupScheduledUpdatedDate);
    history.push(route);
  };

  return (
    <Box bgcolor={root[0]} display="flex" flexDirection="column">
      <InfoLine icon="warehouse">
        <Typography fontWeight="bold">
          {t('dropOffDeliveryInfo.title')}
        </Typography>
      </InfoLine>
      <Box height="24px" />
      <InfoLine icon="pin">
        <Typography>{t('dropOffDeliveryInfo.description')}</Typography>
        <Box display="flex" style={{ marginTop: '8px' }}>
          <Button
            fullWidth
            onClick={redirectToServiceAgenciesPage}
            style={buttonStyle}
          >
            <span style={{ padding: '0px 16px' }}>
              {t('dropOffDeliveryInfo.button')}
            </span>
          </Button>
        </Box>
      </InfoLine>
    </Box>
  );
};

DropOffDeliveryInfo.defaultProps = {
  senderPostalCode: '',
  pickupScheduledUpdatedDate: ''
};

DropOffDeliveryInfo.propTypes = {
  senderPostalCode: PropTypes.string,
  pickupScheduledUpdatedDate: PropTypes.string
};

export default DropOffDeliveryInfo;
