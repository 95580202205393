import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/components';
import { blue, root, grey } from 'theme/colors';
import { useUserRecords } from 'UI/contexts';
import AccountTypeInformed from 'crm/entities/events/account-type-informed/account-type-informed';
import { USER_TYPE } from 'crm/constants';
import CRMUser from 'crm/entities/user/crm-user.model';

const SignUpModalityForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();

  const [isCNPJSignUp, setIsCNPJSignUp] = useState(false);
  const [isCPFSignUp, setIsCPFSignUp] = useState(false);

  const handleSubmit = isLegalPerson => {
    const { email } = userRecords;
    const userType = isLegalPerson
      ? USER_TYPE.CORPORATE_USER
      : USER_TYPE.PERSONAL_CUSTOMER;

    new AccountTypeInformed({
      ...userRecords,
      userType
    }).sendToCrm();

    setUserRecords({
      ...userRecords,
      userType
    });

    new CRMUser({ email, userType }).sendToCrm();

    handleOnNextStep({ isLegalPerson });
  };

  return (
    <Box p={3}>
      <Typography fontWeight="fontWeightMedium" variant="subtitle1">
        {t('signUpCompany.modality.title')}
      </Typography>
      <Typography
        fontWeight="fontWeightMedium"
        color={grey[200]}
        variant="body2"
      >
        {t('signUpCompany.modality.subtitle')}
      </Typography>
      <Box display="flex" py={3}>
        <Box mr={1} flex={1}>
          <Button
            bgColor={root[0]}
            borderColor={isCPFSignUp ? blue[500] : grey[50]}
            borderWidth={2}
            fullWidth
            label={t('signUpCompany.modality.optionLabel.cpf')}
            fontWeight={500}
            textColor={root[900]}
            variant="outlined"
            onClick={() => {
              setIsCPFSignUp(true);
              setIsCNPJSignUp(false);
            }}
          />
        </Box>
        <Box flex={1}>
          <Button
            bgColor={root[0]}
            borderColor={isCNPJSignUp ? blue[500] : grey[50]}
            borderWidth={2}
            fullWidth
            label={t('signUpCompany.modality.optionLabel.cnpj')}
            fontWeight={500}
            textColor={root[900]}
            variant="outlined"
            onClick={() => {
              setIsCPFSignUp(false);
              setIsCNPJSignUp(true);
            }}
          />
        </Box>
      </Box>
      <Box>
        <Button
          fullWidth
          disabled={!isCPFSignUp && !isCNPJSignUp}
          label={t('signUpCompany.submitButton.label')}
          onClick={() => handleSubmit(isCNPJSignUp)}
        />
      </Box>
    </Box>
  );
};

SignUpModalityForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default SignUpModalityForm;
