import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { Box, Typography, Stack, Button, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const PackageDeliveredInfo = ({
  handleShowDeliveryReceipt,
  recipientName,
  recipientDocument,
  locationDescription
}) => {
  const { t } = useTranslation('ui');

  if (!recipientName) {
    return (
      <Box p={spacing.inset.large}>
        <Skeleton height={200} width={330} />
      </Box>
    );
  }

  const getDeliveryLocationDescriptionMap = key => {
    const translationsMap = {
      INVALID: 'invalid',
      RECIPIENT: 'recipient',
      OTHER_RECIPIENT: 'otherRecipient',
      CONCIERGE: 'concierge',
      MAILBOX: 'mailbox'
    };

    return `shipmentDetailsV2.packageDeliveredInfo.deliveryDetailsMap.${
      translationsMap[key]
    }`;
  };

  return (
    <Box>
      <Box alignItems="center" display="flex">
        <Box flexGrow={1}>
          <Stack
            p={spacing.inset.large}
            gap={spacing.stack.xxxsmall}
            sx={{ whiteSpace: 'normal' }}
          >
            <Typography variant="subtitleLarge">
              {t('shipmentDetailsV2.packageDeliveredInfo.receiverName')}
            </Typography>
            <Typography variant="bodyTextMedium">{recipientName}</Typography>
          </Stack>
        </Box>
        <Box pr={spacing.inset.small}>
          <Button
            onClick={() => handleShowDeliveryReceipt()}
            size="medium"
            variant="text"
          >
            {t('shipmentDetailsV2.packageDeliveredInfo.button')}
          </Button>
        </Box>
      </Box>
      <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.packageDeliveredInfo.document')}
        </Typography>
        <Typography variant="bodyTextMedium">{recipientDocument}</Typography>
      </Stack>
      <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.packageDeliveredInfo.deliveryDetails')}
        </Typography>
        <Typography variant="bodyTextMedium">
          {t(getDeliveryLocationDescriptionMap(locationDescription))}
        </Typography>
      </Stack>
    </Box>
  );
};

PackageDeliveredInfo.propTypes = {
  recipientName: PropTypes.string.isRequired,
  recipientDocument: PropTypes.string.isRequired,
  locationDescription: PropTypes.string.isRequired,
  handleShowDeliveryReceipt: PropTypes.func.isRequired
};

export default PackageDeliveredInfo;
