import React from 'react';
import { spacing } from '@loggi/front-design-tokens';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const URL = process.env.REACT_APP_CORREIOS_TRACKING_URL;

export const HowToTrackInfo = () => {
  const { t } = useTranslation('ui');

  return (
    <Box pb={spacing.inset.large} display="flex" flexDirection="column">
      <Box px={spacing.inset.large} pt={spacing.inset.large}>
        <Typography pb={spacing.stack.nano} variant="subtitleMedium">
          {t('shipmentDetailsV2.howToTrackInfo.title')}
        </Typography>
        <Typography pb={spacing.stack.nano} variant="bodyTextMedium">
          {t('shipmentDetailsV2.howToTrackInfo.text')}
        </Typography>
        <Typography variant="subtitleSmall">
          {t('shipmentDetailsV2.howToTrackInfo.agenciesInfo')}
        </Typography>
      </Box>
      <Box pl={spacing.inset.small} pt={spacing.stack.xxxsmall}>
        <Button size="small" variant="text" href={URL} targe="_blank">
          {t('shipmentDetailsV2.howToTrackInfo.button')}
        </Button>
      </Box>
    </Box>
  );
};

HowToTrackInfo.propTypes = {};

export default HowToTrackInfo;
