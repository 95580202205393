import { styled, TextField } from '@mui/material';
import { blue, grey, red, root, yellow } from 'theme/colors';

export const PrimaryInput = styled(TextField)({
  '& input': {
    fontSize: '18px',
    caretColor: blue[500],
    padding: '8px 0',
    height: '27px',
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
      WebkitTextFillColor: blue[500],
      WebkitBoxShadow: '0 0 0 30px white inset'
    }
  },
  '& .MuiOutlinedInput-root, & .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: '0',
    padding: '0',
    transition: 'all 300ms ease, font-weight 0s, color 0s'
  },
  '& .MuiInputBase-root.Mui-focused': {
    borderBottom: `1px solid ${blue[500]}`
  },
  '& .MuiInputBase-root': {
    borderBottom: `1px solid ${grey[200]}`
  },
  '&.MuiTextField-root.--display': {
    '& .MuiOutlinedInput-root': {
      border: 'none'
    }
  },
  '& .MuiFormHelperText-root.Mui-error': { color: red[500] },
  '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error': {
    borderBottom: `1px solid ${red[500]}`
  },
  '& .MuiFormHelperText-root': {
    fontSize: '14px',
    fontWeight: '700',
    padding: '8px 0 0 0',
    margin: '0'
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: grey[200],
    opacity: 1,
    lineHeight: '150%'
  },
  '& .MuiAutocomplete-inputRoot': {
    paddingRight: '30px'
  },
  '& .MuiOutlinedInput-root .MuiIconButton-root': {
    color: blue[500]
  }
});

export const WhiteInput = styled(TextField)({
  '& input': {
    caretColor: root[0],
    color: root[0],
    fontSize: '18px',
    padding: '4px 0',
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
      WebkitTextFillColor: root[0],
      WebkitBoxShadow: `0 0 0 30px ${blue[500]} inset`
    }
  },
  '& .MuiOutlinedInput-root, & .MuiOutlinedInput-notchedOutline': {
    borderRadius: '0',
    border: 'none',
    padding: '0',
    transition: 'all 300ms ease, font-weight 0s, color 0s'
  },
  '& .MuiInputBase-root, & .MuiInputBase-root.Mui-focused': {
    borderBottom: `1px solid ${root[0]}`
  },
  '&.MuiTextField-root.--display': {
    '& .MuiOutlinedInput-root': {
      border: 'none'
    },
    '& .MuiOutlinedInput-input': {
      color: root[0]
    }
  },
  '& .MuiFormHelperText-root.Mui-error': { color: yellow[500] },
  '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error': {
    borderBottomColor: yellow[500]
  },
  '& .MuiFormHelperText-root': {
    fontSize: '14px',
    fontWeight: '700',
    margin: '8px 0 0 0'
  },
  '& .MuiOutlinedInput-root .MuiIconButton-root': {
    color: root[0]
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: root[0],
    opacity: 1,
    lineHeight: '150%'
  }
});
