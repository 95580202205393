import { Box } from '@mui/material';
import { FORMS_VARIANTS, INPUT_TYPES } from 'constants/index';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../button/button.component';
import CheckboxTerms from '../line-inputs/checkbox-terms-input/checkbox-terms-input.component';
import {
  createInitialStateFromFieldValues,
  getInitialStep
} from './step-form-wrapper.helper';
import { StepInput } from './step-input/step-input.component';

const StepFormWrapper = ({
  buttonLabel,
  fields,
  hasCheckbox,
  initialStep,
  isInputLabelVisible,
  isLoading,
  onStepChange,
  onSubmit,
  variant
}) => {
  const [step, setStep] = useState(getInitialStep({ fields, initialStep }));
  const [inputsValues, setInputsValues] = useState(
    createInitialStateFromFieldValues(fields)
  );
  const [checked, setChecked] = useState(false);
  const [hasCheckboxError, setHasCheckboxError] = useState(false);
  const [error, setError] = useState({ message: undefined, field: undefined });
  const { t } = useTranslation('ui');
  const { background, color } = FORMS_VARIANTS[variant];

  function validateInput(fieldName) {
    const currentField = fields[fieldName];
    const currentFieldValue = inputsValues[fieldName];
    const currentFieldValidators = currentField?.validator;
    if (!currentFieldValidators) return '';
    const validatorError = currentFieldValidators(currentFieldValue);
    setError({
      message: t(validatorError?.errorMessage) ?? '',
      field: fieldName
    });
    return validatorError?.errorMessage;
  }

  function handleCheckboxChange() {
    setError({ message: '' });
    setChecked(!checked);
    setHasCheckboxError(false);
  }

  function handleInputChange(fieldName) {
    return function propagate(newValue) {
      setError({ message: '' });
      return setInputsValues(prev => ({
        ...prev,
        [fieldName]: newValue
      }));
    };
  }

  function handleStepJump(newStep) {
    return function propagate() {
      const fieldName = Object.keys(fields)[step];
      const inputError = validateInput(fieldName);
      if (isLoading) return;
      if (newStep > step && (error.message || inputError)) return;
      setStep(newStep);
    };
  }

  function handleNextStep() {
    const fieldName = Object.keys(fields)[step];
    const inputError = validateInput(fieldName);
    const hasError = error.field === fieldName && error.message;
    if (hasError || inputError || isLoading) return;
    if (step === Object.keys(fields).length - 1) {
      if (hasCheckbox && !checked) setHasCheckboxError(true);
      else onSubmit(inputsValues);
    } else setStep(prev => prev + 1);
  }

  useEffect(() => {
    onStepChange?.(step, inputsValues);
  }, [inputsValues, onStepChange, step]);

  return (
    <Box className="step-form-wrapper">
      {Object.entries(fields).map(
        ([fieldName, { label, placeholder, type }], index) => {
          const isDisplayVisible = index > 0 ? !!inputsValues[fieldName] : true;
          const currentFieldError =
            error.field === fieldName ? error.message : '';
          return (
            <StepInput
              error={currentFieldError}
              isActive={step === index}
              isDisplayVisible={isDisplayVisible}
              isLabelVisible={isInputLabelVisible}
              isLoading={isLoading}
              key={fieldName}
              label={label}
              name={fieldName}
              onDisplayClick={handleStepJump(index)}
              onEnterKeyDown={handleNextStep}
              onInputChange={handleInputChange(fieldName)}
              placeholder={placeholder}
              type={type}
              value={inputsValues[fieldName]}
              variant={variant}
            />
          );
        }
      )}
      {hasCheckbox && step === Object.keys(fields).length - 1 && (
        <CheckboxTerms
          checked={checked}
          error={hasCheckboxError}
          onChange={handleCheckboxChange}
        />
      )}
      <Button
        isLoading={isLoading}
        bgColor={background}
        fullWidth
        label={buttonLabel}
        onClick={handleNextStep}
        textColor={color}
      />
    </Box>
  );
};

StepFormWrapper.defaultProps = {
  buttonLabel: '',
  hasCheckbox: false,
  initialStep: 0,
  isInputLabelVisible: true,
  isLoading: false,
  onStepChange: null,
  variant: 'primary'
};

StepFormWrapper.propTypes = {
  buttonLabel: PropTypes.string,
  fields: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(INPUT_TYPES)).isRequired,
      validator: PropTypes.func
    }).isRequired
  ).isRequired,
  hasCheckbox: PropTypes.bool,
  initialStep: PropTypes.number,
  isInputLabelVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  onStepChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.keys(FORMS_VARIANTS))
};

export default StepFormWrapper;
