import { fromMoneyToNumber } from 'utils';

export default class ValidatedCoupon {
  constructor(params) {
    /** @type {string} */
    this.couponCode = params.couponCode;
    /** @type {string} */
    this.couponTitle = params.couponTitle;
    /** @type {string} */
    this.couponDescription = params.couponDescription;
    /** @type {number} */
    this.totalAmount = params.totalAmount;
    /** @type {number} */
    this.amountWithAppliedDiscount = params.amountWithAppliedDiscount;
  }

  static fromJson(json) {
    return new ValidatedCoupon({
      couponCode: json.coupon_code,
      couponTitle: json.coupon_title,
      couponDescription: json.coupon_description,
      totalAmount: fromMoneyToNumber(json.total_amount),
      amountWithAppliedDiscount: fromMoneyToNumber(
        json.amount_with_applied_discount
      )
    });
  }
}
