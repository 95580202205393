import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { getCreationMethod, getFirstName, getLastName } from 'crm/utils';

export default class AccountCreationStarted extends CRMBaseEvent {
  constructor({ email, name, provider, origin }) {
    super();
    this.eventName = EVENTS.ACCOUNT_CREATION_STARTED;
    this.payload = {
      email,
      origin,
      first_name: getFirstName(name),
      last_name: getLastName(name),
      registration_method: getCreationMethod(provider)
    };
  }
}
