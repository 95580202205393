import useGooglePlacesApi from '@loggi/components/src/one/address-autocomplete/hooks/useGooglePlacesApi';
import { Box, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { DocumentInput, PhoneInput, TextInput } from 'UI/components/input';
import { useCorp } from 'UI/contexts/corp-middleware/corp-middleware.context';
import Footer from 'UI/shipment/components/shipment-footer';
import useFormManager from 'UI/shipment/hooks/form-manager';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { machineContextToCorpPayload } from 'UI/shipment/state-machine/utils';
import { INPUT_TYPES } from 'constants/index';
import { getCorpShipmentDataUseCase } from 'domain/use-cases';
import { ValidatorBuilder } from 'infra';
import { AmateurQuoting } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { newHome } from 'routes/routes';
import { hasAddressNumber as _hasAddressNumber, fillCompanyId } from 'utils';

const hasAddressNumber = corpPayload =>
  _hasAddressNumber({ structured: { main: corpPayload.address.description } });

export default function DeliveryCustomerPage() {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();
  const history = useHistory();
  const { createCorpOrder } = useCorp();
  useGooglePlacesApi();
  const [isLoading, setIsLoading] = useState(false);
  const shipmentContext = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const shouldMoveOrderToCorp =
    shipmentContext.context.serviceType ===
    'AMATEUR_SERVICE_TYPE_LOCAL_EXPRESS';

  const isIndispatch = AmateurQuoting.isIndespacho({
    serviceType: shipmentContext.context.serviceType
  });

  const fields = {
    name: {
      label: t('deliveryCustomerV2.nameLabel'),
      type: INPUT_TYPES.text,
      validator: new ValidatorBuilder()
        .nonEmptyString('deliveryCustomerV2.errorMessages.invalidName')
        .build(),
      value: shipmentContext.context.deliveryCustomer?.name,
      defaultValue: shipmentContext.context.deliveryCustomer?.name || ''
    },
    phone: {
      label: t('deliveryCustomerV2.phoneLabel'),
      type: INPUT_TYPES.phone,
      validator: isIndispatch
        ? undefined
        : new ValidatorBuilder().phoneNumberPattern().build(),
      value: shipmentContext.context.deliveryCustomer?.phone,
      defaultValue: shipmentContext.context.deliveryCustomer?.phone || ''
    },
    cnpjCpf: {
      label: t('deliveryCustomerV2.cnpjCpfLabel'),
      type: INPUT_TYPES.deliveryCustomerDocument,
      validator: shouldMoveOrderToCorp
        ? undefined
        : new ValidatorBuilder().cnpjCpfPattern().build(),
      value: shipmentContext.context.deliveryCustomer?.cnpjCpf,
      defaultValue: shipmentContext.context.deliveryCustomer?.cnpjCpf || ''
    }
  };

  const moveOrderToNativeCorp = async state => {
    function sendCorpOrderAnalyticsEvent({ status, orderId, error }) {
      const events = {
        created: { event: 'budget::nativeCorp::orderCreated', orderId },
        notCreated: { event: 'budget::nativeCorp::orderNotFinished' },
        error: { event: 'budget::nativeCorp::orderCreationError', error }
      };
      if (events[status]) window.dataLayer?.push(events[status]);
      else
        Sentry.captureException(
          `Invalid status for native corpOrderCreation: ${JSON.stringify(
            status
          )}`
        );
    }

    setIsLoading(true);
    const { pickup, delivery } = await getCorpShipmentDataUseCase(state);
    await createCorpOrder({ pickup, delivery })
      .then(({ status, orderId }) => {
        try {
          // check if native device supports 'corpOrderResult answering'
          if (status) sendCorpOrderAnalyticsEvent({ status, orderId });
          if (status && status !== 'created') return;
          shipmentDispatcher.clear();
          history.push(fillCompanyId({ companyId, route: newHome }));
        } catch (err) {
          Sentry.captureException(err);
        }
      })
      .catch(error => sendCorpOrderAnalyticsEvent({ status: 'error', error }))
      .finally(() => setIsLoading(false));

    if (!hasAddressNumber(pickup) || !hasAddressNumber(delivery))
      Sentry.captureMessage('Sending address without number to native corp', {
        level: 'warning'
      });
  };

  const handleSubmit = async ({ cnpjCpf, name, phone }) => {
    let payload = { cnpjCpf, name, phone };

    if (isIndispatch) {
      payload = {
        name,
        phone: shipmentContext.context.pickupCustomer.phone,
        cnpjCpf
      };
    }

    shipmentDispatcher.setDeliveryCustomer(payload);
    const derivedStateFromMachine = machineContextToCorpPayload({
      ...shipmentContext.context,
      deliveryCustomer: payload
    });

    if (shouldMoveOrderToCorp) moveOrderToNativeCorp(derivedStateFromMachine);
    else shipmentDispatcher.continue();
  };

  const form = useFormManager({ fields, onSubmit: handleSubmit });

  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1">
          {t('deliveryCustomerV2.title')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} mb={5}>
        <TextInput
          id="name-input"
          label={fields.name.label}
          value={form.values.name}
          error={t(form.errors.name)}
          onChange={name => form.setValue('name', name)}
        />
        {!isIndispatch && (
          <PhoneInput
            id="phone-input"
            label={fields.phone.label}
            value={form.values.phone}
            error={t(form.errors.phone)}
            onChange={phone => form.setValue('phone', phone)}
          />
        )}
        {!shouldMoveOrderToCorp && (
          <DocumentInput
            id="document-input"
            label={fields.cnpjCpf.label}
            value={form.values.cnpjCpf}
            error={t(form.errors.cnpjCpf)}
            onChange={cnpjCpf => form.setValue('cnpjCpf', cnpjCpf)}
          />
        )}
      </Box>
      <Footer>
        <Footer.PrimaryAction onClick={form.commit} disabled={isLoading} />
      </Footer>
    </>
  );
}
