export const sharingUseCase = async ({ text, title, url }) => {
  const shareData = {
    text,
    title,
    url
  };

  if (navigator.share) {
    return navigator.share(shareData);
  }

  return false;
};

export default { sharingUseCase };
