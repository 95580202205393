import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { grey } from 'theme/colors';

export const Heading = ({ hasPuller, illustration }) => (
  <Box alignItems="center" display="flex" flexDirection="column" width="100%">
    <Box mb={4} width="fit-content">
      {hasPuller && (
        <Box
          bgcolor={grey[50]}
          borderRadius="100px"
          data-testid="puller"
          height="6px"
          width="32px"
        />
      )}
    </Box>
    {illustration && <Box mb={4}>{illustration}</Box>}
  </Box>
);

Heading.defaultProps = {
  illustration: null
};

Heading.propTypes = {
  hasPuller: PropTypes.bool.isRequired,
  illustration: PropTypes.node
};

export default { Heading };
