import { useFeatureSwitch } from 'hooks/feature-switch';

export const useTagDownloadOnMobile = () => {
  const { isFetching, data } = useFeatureSwitch(
    'enable_tag_download_on_mobile',
    false
  );

  return {
    isLoading: isFetching,
    isEnabled: data
  };
};

export default useTagDownloadOnMobile;
