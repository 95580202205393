import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { BaseDrawer } from 'UI/components';
import { colors, spacing } from '@loggi/front-design-tokens';

export const CancelShipmentDrawer = ({
  isOpen,
  onClose,
  handleCancelShipment
}) => {
  const { t } = useTranslation('ui');

  return (
    <BaseDrawer isOpen={isOpen} variant="temporary" vertical>
      <Stack gap={spacing.stack.xxxsmall} pt={spacing.stack.xxsmall}>
        <Typography
          variant="headingMedium"
          color={colors.neutrals['on-surface']}
        >
          {t('pixPayment.cancelShipmentDrawer.title')}
        </Typography>
        <Typography
          variant="bodyTextLarge"
          color={colors.neutrals['on-surface']}
        >
          {t('pixPayment.cancelShipmentDrawer.description')}
        </Typography>
        <Stack pt={spacing.stack.xxxsmall} gap={spacing.stack.xxxsmall}>
          <Button variant="contained" onClick={handleCancelShipment}>
            {t('pixPayment.cancelShipmentDrawer.buttons.confirm')}
          </Button>
          <Button variant="blank" onClick={onClose}>
            {t('pixPayment.cancelShipmentDrawer.buttons.dismiss')}
          </Button>
        </Stack>
      </Stack>
    </BaseDrawer>
  );
};
CancelShipmentDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCancelShipment: PropTypes.func.isRequired
};

export default CancelShipmentDrawer;
