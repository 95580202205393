import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Divider, Stack, Typography } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';

/**
 * @param {string} title
 * @param {{
 * dimensions: import('UI/shipment/state-machine/types').PackageDimensions
 * }}
 */
export default function PackageInfo({ title, dimensions }) {
  const { t } = useTranslation('ui');

  return (
    <>
      <Stack gap={spacing.stack.xxxsmall} p={spacing.inset.large}>
        <Typography
          variant="subtitleLarge"
          color={colors.neutrals['on-surface']}
        >
          {title}
        </Typography>
        <Typography
          variant="bodyTextMedium"
          color={colors.neutrals['on-surface']}
        >
          {t('checkout.packageDimensions.main', {
            lengthCm: dimensions.lengthCm,
            widthCm: dimensions.widthCm,
            heightCm: dimensions.heightCm,
            weightKg: (dimensions.weightG / 1000).toLocaleString('pt-BR', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            })
          })}
        </Typography>
      </Stack>
      <Divider color={colors.neutrals['outline-low']} />
    </>
  );
}

PackageInfo.propTypes = {
  title: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    heightCm: PropTypes.number,
    widthCm: PropTypes.number,
    lengthCm: PropTypes.number,
    weightG: PropTypes.number
  }).isRequired
};
