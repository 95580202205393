/* eslint-disable import/prefer-default-export, camelcase */
import { getI18n } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import { LAST_MILE_COMPANY_TYPE_LOGGI } from '../constants';
import addressApi from './address-api';

const getPostalCodeFromAddress = async (companyId, address) => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'one');

  return addressApi
    .getPostalCodeFromAddress({
      address,
      companyId
    })
    .json(({ postal_code }) => {
      // The coverage endpoint only accepts numbers, so a postal code
      // that looks like 123450-000 will fail. We need to remove
      // all non-numeric characters.
      const formattedPostalCode = postal_code?.replace(/\D/g, '');

      return {
        errorMessage: formattedPostalCode
          ? null
          : t('addressField.errorMessages.invalidAddress'),
        postalCode: formattedPostalCode
      };
    })
    .catch(e => {
      Sentry.captureException(e, {
        contexts: {
          getPostalCodeFromAddress: { address }
        }
      });
      // The error handling here is tricky because it could come from
      // the endpoint or from Wally itself. Instead of handling the error on
      // the front-end, we will just display a generic error message for now.
      return {
        errorMessage: t('addressField.errorMessages.invalidAddress'),
        postalCode: null
      };
    });
};

const getOutOfCoverageMessage = (response, isCompanySignup = false) => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'one');
  const hasLoggiLastMileCompany = isCompanySignup
    ? Boolean(response?.pickupLmcTypes?.includes(LAST_MILE_COMPANY_TYPE_LOGGI))
    : true;
  const validCoverage = response?.hasPickup && hasLoggiLastMileCompany;

  return validCoverage
    ? undefined
    : t('addressField.errorMessages.originOutOfCoverageArea');
};

export const getOriginCoverage = async (
  postalCode,
  address = '',
  withCoverageParams = false,
  isCompanySignup = false
) => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'one');
  const POSTAL_CODE_LENGTH = 8;

  if (postalCode.length < POSTAL_CODE_LENGTH) {
    return `${t('addressField.errorMessages.invalidCoverageRequestPayload')}`;
  }

  return addressApi
    .getCoverage({ originPostalCode: postalCode })
    .badRequest(
      () => `${t('addressField.errorMessages.invalidCoverageRequestPayload')}`
    )
    .error(422, () => `${t('addressField.errorMessages.postalCodeNotFound')}`)
    .forbidden(() => `${t('addressField.errorMessages.accessDenied')}`)
    .json(response =>
      withCoverageParams
        ? response
        : getOutOfCoverageMessage(response, isCompanySignup)
    )
    .catch(e => {
      Sentry.captureException(e, {
        contexts: {
          getCoverage: { originPostalCode: address || '', postalCode }
        }
      });

      return t('addressField.errorMessages.asyncValidation');
    });
};

export const getOriginCoverageParams = async (companyId, address) => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'one');
  const response = await getPostalCodeFromAddress(companyId, address);

  const postalCode = response?.postalCode;
  const errorMessage = response?.errorMessage;
  const postalCodeNotFound = t('addressField.errorMessages.postalCodeNotFound');

  if (errorMessage) return errorMessage;
  if (!postalCode) {
    Sentry.captureException(postalCodeNotFound, {
      contexts: {
        getCoverageResponse: response
      }
    });
    return postalCodeNotFound;
  }

  return getOriginCoverage(postalCode, address, true);
};

export const validateDestinationCoverage = async (companyId, address) => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'one');
  const { postalCode, errorMessage } = await getPostalCodeFromAddress(
    companyId,
    address
  );

  if (errorMessage) return errorMessage;

  return addressApi
    .getCoverage({ destinationPostalCode: postalCode })
    .badRequest(
      () => `${t('addressField.errorMessages.invalidCoverageRequestPayload')}`
    )
    .error(422, () => `${t('addressField.errorMessages.postalCodeNotFound')}`)
    .forbidden(() => `${t('addressField.errorMessages.accessDenied')}`)
    .json(({ hasDelivery }) =>
      hasDelivery
        ? undefined
        : t('addressField.errorMessages.destinationOutOfCoverageArea')
    )
    .catch(e => {
      Sentry.captureException(e, {
        contexts: {
          getCoverage: { destinationPostalCode: address, postalCode }
        }
      });

      return t('addressField.errorMessages.asyncValidation');
    });
};
