/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseInputField } from './base-input';

function formatCpf(value = '') {
  return value
    .replace(/(\d{11})\d/, '$1')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2');
}

function formatCnpj(value = '') {
  return value
    .replace(/(\d{14})\d/, '$1')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
}

function defaultFormatter(value) {
  return value;
}

function unformatValue(value = '') {
  return value.replace(/\D/g, '');
}

export function DocumentInput(props) {
  let formatter = defaultFormatter;
  if (props.value.length === 11) {
    formatter = formatCpf;
  }
  if (props.value.length === 14) {
    formatter = formatCnpj;
  }

  return (
    <BaseInputField
      id={props.id}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      formatValue={formatter}
      unformatValue={unformatValue}
      inputMode="numeric"
    />
  );
}

DocumentInput.defaultProps = {
  value: '',
  error: null
};

DocumentInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};
