import React from 'react';
import {
  AWS_COGNITO_IMPERSONATION_ERRORS,
  useAmplifyAuth
} from '@loggi/authentication-lib';
import { useSnackbar } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { accessDenied } from 'routes/routes';
import { Box, Typography } from '@mui/material';
import { blue, root } from 'theme/colors';
import ImpersonateForm from './impersonate-form';

export default function ImpersonatePage() {
  const { t } = useTranslation('ui');
  const showSnackbar = useSnackbar();
  const history = useHistory();
  const [isLoading, setLoading] = React.useState(false);
  const { state, impersonationSignIn } = useAmplifyAuth();
  const { error: stateError } = state;

  const onSuccess = () => {
    showSnackbar({
      message: t('impersonation.toast.success'),
      severity: 'success'
    });
    setLoading(false);
  };

  const onError = React.useCallback(
    errorCode => {
      if (
        errorCode ===
        AWS_COGNITO_IMPERSONATION_ERRORS.UserLambdaValidationException.code
      ) {
        history.push(accessDenied);
      }

      const isKnownError = AWS_COGNITO_IMPERSONATION_ERRORS[errorCode];

      const errorMessage = isKnownError
        ? t(`impersonation.errors.${errorCode}`)
        : t('impersonation.errors.DefaultException');

      showSnackbar({
        message: errorMessage,
        severity: 'error'
      });

      setLoading(false);
    },
    [history, showSnackbar, t]
  );

  const handleSubmit = ({ email }) => {
    setLoading(true);
    impersonationSignIn(email, onSuccess);
  };

  React.useEffect(() => {
    if (stateError) {
      const errorCode = stateError.message.code;
      onError(errorCode);
    }

    return undefined;
  }, [stateError, onError]);

  return (
    <Box>
      <Box
        p={3}
        pt={9}
        bgcolor={blue[500]}
        color={root[0]}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100%" maxWidth="720px">
          <Typography variant="h2">
            <strong>{t('impersonation.title')}</strong>
          </Typography>
          <br />
          <Typography variant="subtitle2">
            {t('impersonation.subtitle')}
          </Typography>
        </Box>
      </Box>
      <Box p={3} display="flex" flexDirection="column" alignItems="center">
        <Box width="100%" maxWidth="720px">
          <ImpersonateForm onSubmit={handleSubmit} disableSubmit={isLoading} />
        </Box>
      </Box>
    </Box>
  );
}
