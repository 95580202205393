/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { grey } from 'theme/colors';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { FooterContext } from './context';
import Payment from './payment';
import { PrimaryAction, SecondaryAction } from './actions';
import EstimatePrice from './estimate';

const THEMES = {
  light: {
    bgColor: 'rgba(255, 255, 255, 0.5)',
    textColor: 'black'
  },
  dark: {
    bgColor: 'rgba(0, 0, 0, 0.8)',
    textColor: 'white'
  }
};

const horizontalPad = 3;
const FooterBorder = () => (
  <Divider
    flexItem
    sx={{
      mx: -horizontalPad,
      border: 'none',
      borderTop: `2px solid ${grey[50]}`
    }}
  />
);

/**
 * Shipment footer
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.theme - defaults to 'light'
 * @param {boolean} props.showBorder - defaults to false
 * @returns {React.ReactElement}
 */
export default function Footer({
  children,
  theme = 'light',
  showBorder = false
}) {
  const { textColor, bgColor } = THEMES[theme];
  const isMobileDevice = checkIsMobile();

  return (
    <FooterContext.Provider value={{ theme, bgColor, textColor }}>
      <Box
        gap="8px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb="40px"
        px={horizontalPad}
        pt="16px"
        width="100%"
        position={isMobileDevice ? 'fixed' : 'absolute'}
        bottom="0"
        right="0"
        left="0"
        bgcolor={bgColor}
        color={textColor}
        sx={{ backdropFilter: 'saturate(180%) blur(12px)' }}
        data-testid="shipment-footer"
      >
        {showBorder ? <FooterBorder /> : null}
        {children}
      </Box>
    </FooterContext.Provider>
  );
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  showBorder: PropTypes.bool
};

Footer.Border = FooterBorder;
Footer.PrimaryAction = PrimaryAction;
Footer.SecondaryAction = SecondaryAction;
Footer.EstimatePrice = EstimatePrice;
Footer.Payment = Payment;
