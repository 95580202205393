import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { root } from 'theme/colors';
import useStyles from 'theme/custom-classes';
import { useHistory } from 'react-router-dom';

export const BACK_BUTTON_TEST_ID = 'title-bar.back-button';

export default function TrackerPage() {
  const { t } = useTranslation('ui');
  const history = useHistory();

  const headerProps = {
    backgroundColor: root[0]
  };
  const classes = useStyles(headerProps);

  return (
    <>
      <Box
        bgcolor={root[0]}
        style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
        data-testid="tracker-page"
      >
        <Box className={classes.header} role="menubar">
          <IconButton
            color="inherit"
            data-testid={BACK_BUTTON_TEST_ID}
            disableRipple
            style={{ padding: '0px' }}
            onClick={history.goBack}
          >
            <ArrowBackIos color="primary" />
          </IconButton>
        </Box>
        <Box p={2} style={{ flex: 1 }}>
          <iframe
            title={t('trackerPage.iframeTitle')}
            src={process.env.REACT_APP_PACKAGE_TRACKER_URL}
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        </Box>
      </Box>
    </>
  );
}
