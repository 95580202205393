import { loggiWebApi } from '@loggi/authentication-lib';

const BEYOND_API_URL = process.env.REACT_APP_PY_BEYOND_URL;

const waitingPickupsApi = {
  /** @typedef {import('./waiting-pickups-api.type').WaitingPickup} WaitingPickup */
  /** @returns {Promise<WaitingPickup[]>} */
  list(companyId) {
    const endpoint = `/pickup/v1/waiting-pickups/${companyId}/`;
    return loggiWebApi
      .url(`${BEYOND_API_URL}${endpoint}`, true)
      .get()
      .json();
  }
};

export default waitingPickupsApi;
