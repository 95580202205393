import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class UserIntentionInformed extends CRMBaseEvent {
  constructor({ intention }) {
    super();
    this.eventName = EVENTS.USER_INTENTION_INFORMED;
    this.payload = {
      intention
    };
  }
}
