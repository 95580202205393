import { Box } from '@mui/material';
import { BaseDrawer } from 'UI/components';
import Header from 'UI/shipment/components/header';
import useDeliveryServices from 'UI/shipment/hooks/delivery-services';
import useEstimate from 'UI/shipment/hooks/estimate';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { ReactComponent as OutsideCoverageAreaIllustration } from 'assets/outside-coverage-area.svg';
import { useSnackbar } from 'hooks';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { root } from 'theme/colors';
import ShipmentQuotationPresented from 'crm/entities/events/shipment-quotation-presented/shipment-quotation-presented';
import {
  DestinationAddressDisplay,
  OriginAddressDisplay,
  UnavailableServiceForAddressTypeDrawer
} from 'UI/shipment/components';
import { AmateurQuoting } from 'models';
import { isUsingInputAddress } from 'UI/shipment/state-machine/utils';
import ShipmentAddressByCepCorpAlertClicked from 'crm/entities/events/shipment-address-by-cep-corp-alert-clicked/shipment-address-by-cep-corp-alert-clicked';
import { DeliveryServices } from './delivery-services/delivery-services.component';
import {
  evaluateDeliveryServicesDisplay,
  hasSelectedAddresses
} from './budget.helper';

export default function BudgetPage() {
  const { t } = useTranslation('ui');
  const showSnackbar = useSnackbar();
  const { companyId } = useParams();
  const [dismissedOutOfCoverage, setDismissedOutOfCoverage] = useState(false);
  const [hasSentQuotationEvent, setHasSentQuotationEvent] = useState(false);
  const [displayUnavailableService, setDisplayUnavailableService] = useState(
    false
  );
  const genericApiErrorMessage = t('budgetV2.errorMessages.genericError');
  const reloadButtonLabel = t('budgetV2.labels.reloadButtonLabel');

  const estimateQuery = useEstimate({
    onError() {
      showSnackbar({
        message: genericApiErrorMessage,
        buttonLabel: reloadButtonLabel
      });
    }
  });
  const shipmentContext = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();

  const sendShipmentQuotationPresentedEvent = useCallback(
    estimateData => {
      const { context } = shipmentContext;
      const event = ShipmentQuotationPresented.fromShipmentContext({
        context,
        companyId,
        availableServices: estimateData?.availableServices
      });
      event.sendToCrm();
    },
    [shipmentContext, companyId]
  );

  const deliveryServices = useDeliveryServices();
  const hasDeliveryServices =
    (deliveryServices && deliveryServices.length > 0) ?? undefined;

  const isAddressesSelected = hasSelectedAddresses(shipmentContext);

  const isLoadingServices =
    isAddressesSelected && hasDeliveryServices === undefined;

  const canDisplayDeliveryServices = evaluateDeliveryServicesDisplay(
    isAddressesSelected,
    hasDeliveryServices,
    isLoadingServices
  );

  useEffect(() => {
    if (hasSentQuotationEvent) return;
    if (
      canDisplayDeliveryServices &&
      estimateQuery.isSuccess &&
      deliveryServices
    ) {
      sendShipmentQuotationPresentedEvent(deliveryServices);
      setHasSentQuotationEvent(true);
    }
  }, [
    canDisplayDeliveryServices,
    deliveryServices,
    estimateQuery,
    hasSentQuotationEvent,
    setHasSentQuotationEvent,
    sendShipmentQuotationPresentedEvent
  ]);

  const handleServiceOptionClick = serviceQuoting => {
    if (
      AmateurQuoting.isCorp(serviceQuoting.serviceType) &&
      isUsingInputAddress(shipmentContext.context)
    ) {
      setDisplayUnavailableService(true);
      return;
    }
    shipmentDispatcher.selectServiceType(serviceQuoting.serviceType);
  };

  const sendUnavailableCorpServiceForCepEvent = () => {
    new ShipmentAddressByCepCorpAlertClicked({
      cepOrigin: shipmentContext.context.originAddress?._correios?.cep,
      cepDestination: shipmentContext.context.destinationAddress?._correios?.cep
    }).sendToCrm();
  };

  return (
    <Box color={root[900]} minHeight="100vh">
      <Header>
        <Header.ArrowBackButton onGoBack={shipmentDispatcher.back} />
      </Header>

      <Box py={5} px={3}>
        <Box>
          <OriginAddressDisplay
            onClick={shipmentDispatcher.clickPickupAddress}
          />
        </Box>
        <Box mt={2}>
          <DestinationAddressDisplay
            onClick={shipmentDispatcher.clickDeliveryAddress}
          />
        </Box>

        {canDisplayDeliveryServices && (
          <Box mt={4} data-testid="delivery-services">
            <DeliveryServices
              onClick={handleServiceOptionClick}
              quoting={{
                isLoading: estimateQuery.isLoading || estimateQuery.isFetching,
                value: deliveryServices
              }}
            />
          </Box>
        )}
      </Box>
      <UnavailableServiceForAddressTypeDrawer
        isVisible={displayUnavailableService}
        onClose={() => setDisplayUnavailableService(false)}
        onConfirm={() => {
          sendUnavailableCorpServiceForCepEvent();
          shipmentDispatcher.clickEditPickupAddress();
        }}
      />
      <BaseDrawer
        description={t('budgetV2.outOfCoverage.description')}
        hasPuller
        illustration={<OutsideCoverageAreaIllustration />}
        isOpen={hasDeliveryServices === false && !dismissedOutOfCoverage}
        labels={{ confirm: t('budgetV2.outOfCoverage.onConfirm') }}
        onConfirmClick={() => setDismissedOutOfCoverage(true)}
        title={t('budgetV2.outOfCoverage.title')}
      />
    </Box>
  );
}
