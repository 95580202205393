import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useEffect } from 'react';

export const useSetupHotjar = () => {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const sub = authenticatedUser?.sub ?? null;
  const email = authenticatedUser?.email ?? null;
  const name = authenticatedUser?.name ?? null;

  useEffect(() => {
    if (sub && email && name && window.hj) {
      window.hj('identify', sub, {
        Name: name,
        email,
        release: process.env.REACT_APP_VERSION
      });
    }
  }, [sub, email, name]);
};

export default { useSetupHotjar };
