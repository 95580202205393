import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'UI/components';
import { useTranslation } from 'react-i18next';

/**
 * @param {object} props
 * @param {boolean} props.isVisible
 * @param {function} props.onClose
 * @param {function} props.onConfirm
 */
export default function UnavailableServiceForAddressTypeDrawer({
  isVisible,
  onClose,
  onConfirm
}) {
  const { t } = useTranslation('ui');

  return (
    <BaseDrawer
      isOpen={isVisible}
      title={t('unavailableServiceForAddressTypeDrawer.title')}
      description={t('unavailableServiceForAddressTypeDrawer.description')}
      labels={{
        confirm: t('unavailableServiceForAddressTypeDrawer.labels.confirm')
      }}
      onConfirmClick={onConfirm}
      onDismissClick={onClose}
      variant="temporary"
      hasPuller
      alignTitleLeft
      alignDescriptionLeft
    />
  );
}

UnavailableServiceForAddressTypeDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
