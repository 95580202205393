import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { spacing } from '@loggi/front-design-tokens';
import { TRACKING_STATUS } from 'constants/tracking.constants';
import { TrackingCodeAccordion } from './tracking-code-accordion/tracking-code-accordion.component';
import { DestinationInfoAccordion } from './destination-info-accordion/destination-info-accordion.component';
import { SenderInfoAccordion } from './sender-info-accordion/sender-info-accordion.component';
import { PackageDetailsAccordion } from './package-details-accordion/package-details-accordion.component';

const conditionComponents = {
  showTrackingCodeAccordion: ({
    isDropoff,
    isIndispatch,
    status,
    trackingCode
  }) => ({
    component: TrackingCodeAccordion,
    shouldRender:
      (!isDropoff &&
        !isIndispatch &&
        [
          TRACKING_STATUS.pickupAllocated,
          TRACKING_STATUS.processing,
          TRACKING_STATUS.onRouteToDelivery,
          TRACKING_STATUS.problemNeedMoreInfo,
          TRACKING_STATUS.lost,
          TRACKING_STATUS.deliveryFailure,
          TRACKING_STATUS.inTransit,
          TRACKING_STATUS.canceledByAllocation,
          TRACKING_STATUS.returningCompleted,
          TRACKING_STATUS.returningToShipper,
          TRACKING_STATUS.delivered
        ].includes(status)) ||
      (isDropoff &&
        [
          TRACKING_STATUS.processing,
          TRACKING_STATUS.onRouteToDelivery,
          TRACKING_STATUS.problemNeedMoreInfo,
          TRACKING_STATUS.lost,
          TRACKING_STATUS.deliveryFailure,
          TRACKING_STATUS.inTransit,
          TRACKING_STATUS.canceledByAllocation,
          TRACKING_STATUS.returningCompleted,
          TRACKING_STATUS.returningToShipper,
          TRACKING_STATUS.delivered
        ].includes(status)),
    props: { trackingCode }
  }),
  showSenderInfoAccordion: ({
    isDropoff,
    isIndispatch,
    status,
    senderName,
    senderPhone,
    pickupAddress
  }) => ({
    component: SenderInfoAccordion,
    shouldRender:
      !isDropoff &&
      !isIndispatch &&
      [
        TRACKING_STATUS.pickupScheduled,
        TRACKING_STATUS.pickupAllocated,
        TRACKING_STATUS.processing,
        TRACKING_STATUS.onRouteToDelivery,
        TRACKING_STATUS.problemNeedMoreInfo,
        TRACKING_STATUS.lost,
        TRACKING_STATUS.deliveryFailure,
        TRACKING_STATUS.inTransit,
        TRACKING_STATUS.canceledByAllocation,
        TRACKING_STATUS.returningCompleted,
        TRACKING_STATUS.returningToShipper,
        TRACKING_STATUS.delivered
      ].includes(status),
    props: { senderName, senderAddress: pickupAddress, senderPhone }
  }),
  showDestinationAccordion: ({
    isDropoff,
    isIndispatch,
    status,
    receiverName,
    destinationAddress,
    receiverPhone
  }) => ({
    component: DestinationInfoAccordion,
    shouldRender:
      (isDropoff &&
        [
          TRACKING_STATUS.pickupScheduled,
          TRACKING_STATUS.canceledByAllocation,
          TRACKING_STATUS.delivered
        ].includes(status)) ||
      (!isDropoff &&
        !isIndispatch &&
        [
          TRACKING_STATUS.pickupScheduled,
          TRACKING_STATUS.canceledByAllocation,
          TRACKING_STATUS.delivered
        ].includes(status)) ||
      (isIndispatch && status === TRACKING_STATUS.pickupScheduled),
    props: { receiverName, receiverAddress: destinationAddress, receiverPhone }
  }),
  showPackageDetailsAccordion: ({
    isDropoff,
    isIndispatch,
    status,
    packageDetails,
    isPackaged
  }) => ({
    component: PackageDetailsAccordion,
    shouldRender:
      (isDropoff &&
        [
          TRACKING_STATUS.pickupScheduled,
          TRACKING_STATUS.processing,
          TRACKING_STATUS.onRouteToDelivery,
          TRACKING_STATUS.problemNeedMoreInfo,
          TRACKING_STATUS.lost,
          TRACKING_STATUS.deliveryFailure,
          TRACKING_STATUS.inTransit,
          TRACKING_STATUS.canceledByAllocation,
          TRACKING_STATUS.returningCompleted,
          TRACKING_STATUS.returningToShipper,
          TRACKING_STATUS.delivered
        ].includes(status)) ||
      (!isDropoff &&
        !isIndispatch &&
        [
          TRACKING_STATUS.pickupScheduled,
          TRACKING_STATUS.pickupAllocated,
          TRACKING_STATUS.processing,
          TRACKING_STATUS.onRouteToDelivery,
          TRACKING_STATUS.problemNeedMoreInfo,
          TRACKING_STATUS.lost,
          TRACKING_STATUS.deliveryFailure,
          TRACKING_STATUS.inTransit,
          TRACKING_STATUS.canceledByAllocation,
          TRACKING_STATUS.returningCompleted,
          TRACKING_STATUS.returningToShipper,
          TRACKING_STATUS.delivered
        ].includes(status)) ||
      (isIndispatch &&
        [
          TRACKING_STATUS.pickupScheduled,
          TRACKING_STATUS.processing,
          TRACKING_STATUS.canceledByAllocation
        ].includes(status)),
    props: { packageDetails, isPackaged }
  })
};

export const ShipmentDetailsAccordions = ({
  isDropoff,
  isIndispatch,
  status,
  trackingCode,
  destinationAddress,
  receiverName,
  packageDetails,
  isPackaged,
  pickupAddress,
  senderPhone,
  senderName,
  receiverPhone
}) => {
  const renderedComponents = Object.keys(conditionComponents).map(key => {
    const { component: Component, shouldRender, props } = conditionComponents[
      key
    ]({
      isDropoff,
      isIndispatch,
      status,
      trackingCode,
      destinationAddress,
      receiverName,
      packageDetails,
      isPackaged,
      pickupAddress,
      senderPhone,
      senderName,
      receiverPhone
    });

    // eslint-disable-next-line react/jsx-props-no-spreading
    return shouldRender ? <Component key={key} {...props} /> : <></>;
  });

  return (
    <Box display="flex" flexDirection="column" gap={spacing.stack.nano}>
      {renderedComponents.map(component => (
        <>{component}</>
      ))}
    </Box>
  );
};

ShipmentDetailsAccordions.propTypes = {
  isDropoff: PropTypes.bool.isRequired,
  isIndispatch: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  trackingCode: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string.isRequired,
  receiverName: PropTypes.string.isRequired,
  packageDetails: PropTypes.shape({
    formatDimensions: PropTypes.func.isRequired,
    matchingPackagingLabel: PropTypes.func.isRequired,
    formatWeightToKg: PropTypes.func.isRequired
  }).isRequired,
  isPackaged: PropTypes.bool.isRequired,
  pickupAddress: PropTypes.string.isRequired,
  senderPhone: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
  receiverPhone: PropTypes.string.isRequired
};

export default ShipmentDetailsAccordions;
