import { useFeatureSwitch } from 'hooks/feature-switch';

const DEFAULT_SERVICES_ORDER = ['corp', 'beyond', 'dropoff', 'indespacho'];

export default function useDeliveryServicesOrder() {
  return useFeatureSwitch(
    'budget_delivery_services_order',
    DEFAULT_SERVICES_ORDER
  ).data;
}
