import { Box } from '@mui/material';
import { INPUT_TYPES } from 'constants/index';
import { ValidatorBuilder } from 'infra';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { blue } from 'theme/colors';
import { Button, StepFormWrapper } from 'UI/components';

export const PasswordResetForm = ({
  onSubmit,
  onRequestResendConfirmationCode
}) => {
  const { t } = useTranslation('ui');
  const [step, setStep] = useState(0);
  const fields = {
    confirmationCode: {
      label: t('codeConfirmation.label'),
      type: INPUT_TYPES.confirmationCode,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .codeLengthConfirmation('codeConfirmation.errorMessage')
        .build()
    },
    newPassword: {
      label: t('newPassword.label'),
      type: INPUT_TYPES.password,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .passwordLength('newPassword.errorMessage')
        .build()
    }
  };

  const handleStepChange = currStep => {
    if (step === currStep) return;
    setStep(currStep);
  };

  return (
    <Box py={2} px={3}>
      <StepFormWrapper
        fields={fields}
        onStepChange={handleStepChange}
        onSubmit={onSubmit}
        buttonLabel={t('submitButton')}
      />
      {step === 0 && (
        <Box mt={3}>
          <Button
            fullWidth
            label={t('resendConfirmationCode')}
            onClick={onRequestResendConfirmationCode}
            variant="outlined"
            textColor={blue[500]}
          />
        </Box>
      )}
    </Box>
  );
};

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onRequestResendConfirmationCode: PropTypes.func.isRequired
};

export default { PasswordResetForm };
