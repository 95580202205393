import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentOriginByGoogleInputStarted extends CRMBaseEvent {
  constructor() {
    super();
    this.eventName = EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_INPUT_STARTED;
    this.payload = {};
  }
}
