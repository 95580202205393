import { APP_INFO } from 'crm/constants';
import { getFsValueUseCase } from 'domain/use-cases';
import CRMBaseEntity from '../crm-base-entity.model';

/**
 * Abstract class representing a CRM Revenue Event.
 *
 * Subclasses should define the following:
 * @property {string} eventName - The name of the event associated with the CRM entity.
 * @property {number} amount - The value of the revenue.
 * @property {object} payload - The payload to be sent to the CRM.
 */
export default class CRMBaseRevenueEvent extends CRMBaseEntity {
  constructor() {
    super();
    /** @type {number} The value of the revenue */
    this.amount = undefined;
  }

  cleanPayload() {
    const cleanedPayload = super.cleanPayload();
    return {
      product: APP_INFO.PRODUCT,
      service: APP_INFO.SERVICE,
      stack: APP_INFO.STACK,
      ...cleanedPayload
    };
  }

  async sendToCrm() {
    const disabledEventsList = await getFsValueUseCase('disable_crm_events');
    if (
      disabledEventsList === '*' ||
      disabledEventsList.includes(this.eventName)
    ) {
      return;
    }

    this.enrichesPayloadWithGeneralAppData();
    const cleanedPayload = this.cleanPayload();
    this.crmService.sendRevenueEvent(this, cleanedPayload);
  }
}
