import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Avatar, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@loggi/loggi-icons';
import { borders, colors, spacing } from '@loggi/front-design-tokens';

import ValidatedCoupon from 'UI/shipment/models/validated-coupon.model';
import { currencyFormatter } from 'utils';

/**
 * @param {import('UI/shipment/models/validated-coupon.model').default} validatedCoupon
 * @param {Function} onClick
 */
export default function Coupon({ validatedCoupon, onClick }) {
  const { t } = useTranslation('ui');

  let couponAmount;
  if (validatedCoupon) {
    couponAmount =
      validatedCoupon.totalAmount - validatedCoupon.amountWithAppliedDiscount;
  }

  return (
    <Stack
      backgroundColor={colors.neutrals.surface}
      borderTop={`${borders.width.thin} solid ${
        colors.neutrals['outline-low']
      }`}
      direction="row"
      p={spacing.stack.xxxsmall}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center">
        <Avatar size="medium">
          <Icon
            name="Ticket01"
            color={colors.interaction['on-secondary']}
            size="medium"
          />
        </Avatar>
        <Stack px={spacing.inline.xxsmall} gap={spacing.stack.nano}>
          <Typography
            variant="bodyHighlightMedium"
            color={colors.neutrals['on-surface']}
          >
            {validatedCoupon
              ? validatedCoupon.couponTitle
              : t('checkoutFooter.coupon.title')}
          </Typography>
          {validatedCoupon && (
            <Typography
              variant="bodyTextMedium"
              data-testid="coupon-subtitle"
              color={colors.neutrals['on-surface-variant']}
            >
              {t('checkoutFooter.coupon.subtitle', {
                couponAmount: currencyFormatter(couponAmount)
              })}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Button size="small" variant="blank" onClick={onClick}>
        {validatedCoupon
          ? t('checkoutFooter.coupon.changeButton')
          : t('checkoutFooter.coupon.applyButton')}
      </Button>
    </Stack>
  );
}

Coupon.defaultProps = {
  validatedCoupon: null
};

Coupon.propTypes = {
  validatedCoupon: PropTypes.instanceOf(ValidatedCoupon),
  onClick: PropTypes.func.isRequired
};
