import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HomeServicesChecked from 'crm/entities/events/home-services-checked/home-services-checked';
import { ServiceCard } from './service-card/service-card.component';
import { infoServiceCards } from './services-cards.helper';
import { BoxWithHidenScrollBar } from './services-cards.styled';

export const ServicesCards = ({ onClick }) => {
  const { t } = useTranslation('ui');
  const [hasSendEvent, setHasSendEvent] = React.useState(false);
  const scrollRef = React.useRef();

  const handleSendEvent = () => {
    if (!hasSendEvent) {
      new HomeServicesChecked().sendToCrm();
      setHasSendEvent(true);
    }
  };

  const handleOnWheel = e => {
    const container = scrollRef.current;
    container.scrollLeft += e.deltaY;
  };

  return (
    <BoxWithHidenScrollBar
      ref={scrollRef}
      onScroll={handleSendEvent}
      onWheel={handleOnWheel}
      data-testid="services-cards"
      display="flex"
    >
      {infoServiceCards.map(infoServiceCard => (
        <Box key={infoServiceCard.title} mr={2}>
          <ServiceCard
            title={t(infoServiceCard.title)}
            description={t(infoServiceCard.description)}
            buttonLabel={t(infoServiceCard.buttonLabel)}
            onClick={() => onClick(infoServiceCard.name)}
          />
        </Box>
      ))}
    </BoxWithHidenScrollBar>
  );
};

ServicesCards.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default { ServicesCards };
