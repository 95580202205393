import { Box, Typography, styled, Hidden, ListItem, List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { blue, grey } from 'theme/colors';
import { useUserRecords } from 'UI/contexts';
import { Button, BaseDrawer, BaseDialog } from 'UI/components';
import { FORMS_VARIANTS } from 'constants/index';
import integratorsOptionsList from 'constants/integrators.constants';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import LineInputBase from 'UI/components/line-inputs/line-input-base.component';
import CRMUser from 'crm/entities/user/crm-user.model';
import AccountIntegratorsInformed from 'crm/entities/events/account-integrators-informed/account-integrators-informed';

export const OTHER_INTEGRATOR_INPUT_TEST_ID = 'other-integrator-input';
export const OTHER_INTEGRATOR_BUTTON_LABEL = 'other-integrator-button';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  list: {
    width: '100%',
    '& > :last-child': {
      '& .MuiDivider-root': {
        display: 'none'
      }
    },
    display: 'flex',
    flexWrap: 'wrap',
    [breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    gap: spacing(1)
  },
  listItem: {
    width: '157.5px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: spacing(1.5),
    border: `2px solid ${grey[50]}`,
    '&.Mui-selected': {
      backgroundColor: 'initial',
      borderColor: blue[500],
      borderRadius: spacing(1.5)
    },
    '& .MuiTypography-root': {
      fontWeight: '500',
      width: '100%',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'center'
    }
  },
  logo: {
    height: '100%'
  }
}));

const FixedBottomBox = styled(Box)(({ isDesktop }) => {
  return !isDesktop
    ? {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }
    : {};
});

const IntegratorStepForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const isDesktop = !checkIsMobile();
  const [selectedIntegrators, setSelectedIntegrators] = useState([]);
  const { userRecords, setUserRecords } = useUserRecords();
  const styles = useStyles();
  const { background, color } = FORMS_VARIANTS.primary;
  const [openModal, setOpenModal] = useState(false);
  const [otherIntegrator, setOtherIntegrator] = useState('');
  const [tempOtherIntegrator, setTempOtherIntegrator] = useState('');

  useEffect(() => {
    setSelectedIntegrators([...userRecords.integrators]);
    setOtherIntegrator(userRecords.otherIntegrator);
    setTempOtherIntegrator(userRecords.otherIntegrator);
  }, [userRecords.integrators, userRecords.otherIntegrator]);

  const handleOnSubmit = ({ hasIntegrator }) => {
    const integrators = hasIntegrator ? selectedIntegrators : [];
    const otherIntegratorOption = hasIntegrator ? otherIntegrator : '';

    setUserRecords({
      ...userRecords,
      integrators,
      otherIntegrator: otherIntegratorOption
    });

    new CRMUser({
      email: userRecords.email,
      integrators,
      otherIntegrator: otherIntegratorOption
    }).sendToCrm();

    new AccountIntegratorsInformed({
      ...userRecords,
      integrators,
      otherIntegrator: otherIntegratorOption,
      shipmentQualification: userRecords?.usuallySend,
      volumeQualification: userRecords?.estimatedMonthlyPackages
    }).sendToCrm();

    handleOnNextStep();
  };

  const handleSelectIntegrator = integrator => {
    const currentList = [...selectedIntegrators];
    if (currentList.includes(integrator)) {
      currentList.splice(currentList.indexOf(integrator), 1);
    } else {
      currentList.push(integrator);
    }
    setSelectedIntegrators(currentList);
  };

  const handleOnConfirmModal = () => {
    setOtherIntegrator(tempOtherIntegrator);
    setOpenModal(false);
  };

  return (
    <Box pt={3} px={3} pb={20}>
      <Typography fontWeight="fontWeightMedium" variant="subtitle1" mb={3}>
        {t('signUpCompany.input.integrator.label')}
      </Typography>
      <List className={styles.list} disablePadding>
        {integratorsOptionsList.map(({ label, value, logo: Logo }) => (
          <ListItem
            key={label}
            aria-label={label}
            button
            className={styles.listItem}
            disableGutters
            onClick={() => handleSelectIntegrator(value)}
            selected={selectedIntegrators.includes(value)}
          >
            <Logo className={styles.logo} />
          </ListItem>
        ))}
        <ListItem
          button
          aria-label={OTHER_INTEGRATOR_BUTTON_LABEL}
          className={styles.listItem}
          disableGutters
          onClick={() => setOpenModal(true)}
          selected={openModal || !!otherIntegrator}
        >
          <Typography px={2}>
            {otherIntegrator ||
              t('signUpCompany.input.integrator.hasIntegrator.other')}
          </Typography>
        </ListItem>
      </List>
      <FixedBottomBox isDesktop={isDesktop} p={3}>
        <Button
          fullWidth
          bgColor={color}
          label={t('signUpCompany.input.integrator.hasIntegrator.no')}
          onClick={() => handleOnSubmit({ hasIntegrator: false })}
          textColor={background}
          variant="text"
        />
        <Button
          fullWidth
          disabled={!selectedIntegrators.length && !otherIntegrator}
          bgColor={background}
          label={t('signUpCompany.submitButton.label')}
          onClick={() => handleOnSubmit({ hasIntegrator: true })}
          textColor={color}
        />
      </FixedBottomBox>
      <Hidden smUp>
        <BaseDrawer
          variant="temporary"
          alignTitleLeft
          isOpen={openModal}
          labels={{
            confirm: t(
              'signUpCompany.input.integrator.hasIntegrator.modal.confirmButtonLabel'
            )
          }}
          onConfirmClick={() => handleOnConfirmModal()}
          title={t('signUpCompany.input.integrator.hasIntegrator.modal.title')}
        >
          <LineInputBase
            value={tempOtherIntegrator}
            onChange={({ target }) => setTempOtherIntegrator(target.value)}
            inputProps={{
              'data-testid': OTHER_INTEGRATOR_INPUT_TEST_ID
            }}
          />
        </BaseDrawer>
      </Hidden>
      <Hidden smDown>
        <BaseDialog
          isOpen={openModal}
          labels={{
            confirm: t(
              'signUpCompany.input.integrator.hasIntegrator.modal.confirmButtonLabel'
            )
          }}
          onConfirm={() => handleOnConfirmModal()}
        >
          <Box
            gap={3}
            alignItems="center"
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <Typography variant="subtitle1">
              {t('signUpCompany.input.integrator.hasIntegrator.modal.title')}
            </Typography>
            <LineInputBase
              value={tempOtherIntegrator}
              onChange={({ target }) => setTempOtherIntegrator(target.value)}
              inputProps={{
                'data-testid': OTHER_INTEGRATOR_INPUT_TEST_ID
              }}
            />
          </Box>
        </BaseDialog>
      </Hidden>
    </Box>
  );
};

IntegratorStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default IntegratorStepForm;
