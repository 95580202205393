import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Divider, Stack, Typography } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';
import { getRelativeDay, getTimeString } from 'utils';

/**
 * @param {string} title
 * @param {{
 * pickupSchedule: import('UI/shipment/state-machine/types').PickupSchedule
 * }}
 */
export default function PickupSchedule({ title, pickupSchedule }) {
  const { t } = useTranslation('weekDays');
  const [pickupScheduleBegin, pickupScheduleEnd] = pickupSchedule;
  const pickupScheduleDay = getRelativeDay(pickupScheduleBegin);
  const pickupScheduleBeginHour = getTimeString(pickupScheduleBegin);
  const pickupScheduleEndHour = getTimeString(pickupScheduleEnd);
  const translatedPickupDay = t(pickupScheduleDay);

  return (
    <>
      <Stack gap={spacing.stack.xxxsmall} p={spacing.inset.large}>
        <Typography
          variant="subtitleLarge"
          color={colors.neutrals['on-surface']}
        >
          {title}
        </Typography>
        <Typography
          variant="bodyTextMedium"
          color={colors.neutrals['on-surface']}
        >
          {t('checkout.pickupSchedule.pickupAt', {
            begin: pickupScheduleBeginHour,
            day: translatedPickupDay,
            end: pickupScheduleEndHour,
            ns: 'ui'
          })}
        </Typography>
      </Stack>
      <Divider color={colors.neutrals['outline-low']} />
    </>
  );
}

PickupSchedule.propTypes = {
  title: PropTypes.string.isRequired,
  pickupSchedule: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired
};
