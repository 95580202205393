import * as rudderanalytics from 'rudder-sdk-js';
import {
  registerInitializedSDK,
  isSDKInitialized
} from 'crm/adapters/registry/initialize';
import CRMBaseConfig from '../crm-base-config';
import CRMAdapterRudder from '../../rudder/crm-adapter-rudder';

export default class RudderConfig extends CRMBaseConfig {
  static get name() {
    return 'rudderstack';
  }

  static isInitialized() {
    return isSDKInitialized(RudderConfig.name);
  }

  static initialize({ writeKey, dataPlaneUrl }) {
    rudderanalytics.load(writeKey, dataPlaneUrl);
    registerInitializedSDK(RudderConfig.name);
  }

  static createAdapter() {
    if (!RudderConfig.isInitialized()) return null;
    return new CRMAdapterRudder();
  }
}
