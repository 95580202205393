const START_TIME_WEEKDAYS_UTC = 1100;
const END_TIME_WEEKDAYS_UTC = 2300;

const START_TIME_SATURDAY_UTC = 1200;
const END_TIME_SATURDAY_UTC = 1900;

const MONDAY = 1;
const FRIDAY = 5;
const SATURDAY = 6;

const isWithinWorkdaysWorkingHours = (today, time) =>
  today >= MONDAY &&
  today <= FRIDAY &&
  time >= START_TIME_WEEKDAYS_UTC &&
  time <= END_TIME_WEEKDAYS_UTC;

const isWithinSaturdayWorkingHours = (today, time) =>
  today === SATURDAY &&
  time >= START_TIME_SATURDAY_UTC &&
  time <= END_TIME_SATURDAY_UTC;

export const supportIsWithinWorkingHoursNow = (now = new Date()) => {
  const today = now.getUTCDay();
  const time = now.getUTCHours() * 100 + now.getUTCMinutes();

  return (
    isWithinWorkdaysWorkingHours(today, time) ||
    isWithinSaturdayWorkingHours(today, time)
  );
};

export default { supportIsWithinWorkingHoursNow };
