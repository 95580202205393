import React from 'react';
import PropTypes from 'prop-types';

import { Button, Stack } from '@mui/material';
import { spacing } from '@loggi/front-design-tokens';

export default function SecondaryAction({ title, onClick }) {
  return (
    <Stack px={spacing.stack.xxsmall} pb={spacing.stack.xxsmall}>
      <Button variant="blank" onClick={onClick}>
        {title}
      </Button>
    </Stack>
  );
}

SecondaryAction.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
