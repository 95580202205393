import { useAmplifyAuth } from '@loggi/authentication-lib';
import PropTypes from 'prop-types';
import { UserProfileButton } from 'UI/components';
import { useShipmentDispatcher } from 'UI/shipment/state-machine/context';
import { useCurrentUser } from 'hooks/current-user/use-current-user.hook';
import React from 'react';
import { logoutInsiderUser } from 'mobile';
import AccountLoggedOut from 'crm/entities/events/account-logged-out/account-logged-out';
import { withEventsObserver } from 'UI/decorators/events-observer/events-observer';
import MenuOpened from 'crm/entities/events/menu-opened/menu-opened';
import { getFsValueUseCase } from 'domain/use-cases/get-fs-value/get-fs-value.use-case';

export default function UserMenuButton({ variant }) {
  const { signOut } = useAmplifyAuth();
  const { user } = useCurrentUser();
  const shipmentDispatcher = useShipmentDispatcher();

  const handleSignOut = async () => {
    window.localStorage.clear();
    shipmentDispatcher.exit();
    new AccountLoggedOut({ email: user.email }).sendToCrm();
    logoutInsiderUser();
    signOut();
  };

  if (!user) return <></>;

  return (
    <UserProfileButton
      variant={variant}
      onSignOut={handleSignOut}
      user={user}
    />
  );
}

UserMenuButton.defaultProps = {
  variant: 'dark'
};

UserMenuButton.propTypes = {
  variant: PropTypes.string
};

export const UserMenuButtonWithEventsObserver = withEventsObserver(
  UserMenuButton,
  {
    user_profile_menu_button: {
      click: () => {
        getFsValueUseCase('enable_menu_opened_event').then(isEnabled => {
          if (isEnabled) {
            new MenuOpened().sendToCrm();
          }
        });
      }
    }
  }
);
