import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

/** @callback loginErrorHandler @param {string} error @returns {void} */
/** @callback logoutHandler @returns {void} */

/**
 * @type {{
 *  setNativeLoginErrorHandler: (callback: loginErrorHandler) => void,
 *  setLogoutHandler: (callback: logoutHandler) => void
 * }}
 */
const INITIAL_SETTERS = {
  setNativeLoginErrorHandler: undefined,
  setLogoutHandler: undefined
};

const NativeAppEventHandlerContext = createContext(INITIAL_SETTERS);
NativeAppEventHandlerContext.displayName = 'NativeAppEventHandler';

export function NativeAppEventHandlerProvider({ children }) {
  const [handlers, setHandlers] = useState({
    handleLoginError: undefined,
    handleLogout: undefined
  });

  /* Reminder: remove setNativeLoginErrorHandler from all code base, 
   since it was replaced by 'native event promise encapsulation' (see native-app-event-sender.js) and
   now it's only temporaly maintaned to suport "diferent UI + Native versions";
   So it must be removed after we have most people with ConsumerApp updated.
  */
  const setters = {
    setNativeLoginErrorHandler: handleLoginError =>
      setHandlers(prev => ({ ...prev, handleLoginError })),
    setLogoutHandler: handleLogout =>
      setHandlers(prev => ({ ...prev, handleLogout }))
  };

  /* register functions globally, so nativeApp can consume it;
   * do not change property name without also chaging in native apps */
  window.nativeAppEventHandler = handlers;

  return (
    <NativeAppEventHandlerContext.Provider value={setters}>
      {children}
    </NativeAppEventHandlerContext.Provider>
  );
}

export function useNativeAppEventHandler() {
  return useContext(NativeAppEventHandlerContext);
}

NativeAppEventHandlerProvider.propTypes = { children: PropTypes.node };
NativeAppEventHandlerProvider.defaultProps = { children: null };
