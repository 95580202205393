import LoggiFacil from 'assets/about-loggi-facil.png';
import LoggiExpresso from 'assets/about-loggi-expresso.png';
import LoggiPonto from 'assets/about-loggi-ponto.png';
import EnvioCorreios from 'assets/about-envio-correios.png';

const informationsAboutServices = {
  loggiExpresso: {
    image: LoggiExpresso,
    slidesInformations: [
      {
        title: 'aboutServiceCarousel.loggiExpresso.firstSlide.title',
        descriptions: [
          'aboutServiceCarousel.loggiExpresso.firstSlide.descriptions.first'
        ],
        list: [
          'aboutServiceCarousel.loggiExpresso.firstSlide.listDescriptions.first',
          'aboutServiceCarousel.loggiExpresso.firstSlide.listDescriptions.second',
          'aboutServiceCarousel.loggiExpresso.firstSlide.listDescriptions.third',
          'aboutServiceCarousel.loggiExpresso.firstSlide.listDescriptions.fourth'
        ],
        availability:
          'aboutServiceCarousel.loggiExpresso.firstSlide.availability'
      },
      {
        title: 'aboutServiceCarousel.loggiExpresso.secondSlide.title',
        descriptions: [
          'aboutServiceCarousel.loggiExpresso.secondSlide.descriptions.first',
          'aboutServiceCarousel.loggiExpresso.secondSlide.descriptions.second'
        ],
        list: [],
        availability: ''
      }
    ]
  },
  loggiFacil: {
    image: LoggiFacil,
    slidesInformations: [
      {
        title: 'aboutServiceCarousel.loggiFacil.firstSlide.title',
        descriptions: [
          'aboutServiceCarousel.loggiFacil.firstSlide.descriptions.first'
        ],
        list: [
          'aboutServiceCarousel.loggiFacil.firstSlide.listDescriptions.first',
          'aboutServiceCarousel.loggiFacil.firstSlide.listDescriptions.second',
          'aboutServiceCarousel.loggiFacil.firstSlide.listDescriptions.third',
          'aboutServiceCarousel.loggiFacil.firstSlide.listDescriptions.fourth',
          'aboutServiceCarousel.loggiFacil.firstSlide.listDescriptions.fifth'
        ],
        availability: 'aboutServiceCarousel.loggiFacil.firstSlide.availability'
      },
      {
        title: 'aboutServiceCarousel.loggiFacil.secondSlide.title',
        descriptions: [
          'aboutServiceCarousel.loggiFacil.secondSlide.descriptions.first',
          'aboutServiceCarousel.loggiFacil.secondSlide.descriptions.second'
        ],
        list: [],
        availability: ''
      },
      {
        title: 'aboutServiceCarousel.loggiFacil.thirdSlide.title',
        descriptions: [
          'aboutServiceCarousel.loggiFacil.thirdSlide.descriptions.first',
          'aboutServiceCarousel.loggiFacil.thirdSlide.descriptions.second'
        ],
        list: [],
        availability: ''
      }
    ]
  },
  loggiPonto: {
    image: LoggiPonto,
    slidesInformations: [
      {
        title: 'aboutServiceCarousel.loggiPonto.firstSlide.title',
        descriptions: [
          'aboutServiceCarousel.loggiPonto.firstSlide.descriptions.first'
        ],
        list: [
          'aboutServiceCarousel.loggiPonto.firstSlide.listDescriptions.first',
          'aboutServiceCarousel.loggiPonto.firstSlide.listDescriptions.second',
          'aboutServiceCarousel.loggiPonto.firstSlide.listDescriptions.third',
          'aboutServiceCarousel.loggiPonto.firstSlide.listDescriptions.fourth',
          'aboutServiceCarousel.loggiPonto.firstSlide.listDescriptions.fifth'
        ],
        availability: 'aboutServiceCarousel.loggiPonto.firstSlide.availability'
      },
      {
        title: 'aboutServiceCarousel.loggiPonto.secondSlide.title',
        descriptions: [
          'aboutServiceCarousel.loggiPonto.secondSlide.descriptions.first',
          'aboutServiceCarousel.loggiPonto.secondSlide.descriptions.second'
        ],
        list: [],
        availability: ''
      }
    ]
  },
  correios: {
    image: EnvioCorreios,
    slidesInformations: [
      {
        title: 'aboutServiceCarousel.correios.firstSlide.title',
        descriptions: [
          'aboutServiceCarousel.correios.firstSlide.descriptions.first',
          'aboutServiceCarousel.correios.firstSlide.descriptions.second'
        ],
        list: [
          'aboutServiceCarousel.correios.firstSlide.listDescriptions.first',
          'aboutServiceCarousel.correios.firstSlide.listDescriptions.second',
          'aboutServiceCarousel.correios.firstSlide.listDescriptions.third'
        ],
        availability: ''
      },
      {
        title: 'aboutServiceCarousel.correios.secondSlide.title',
        descriptions: [],
        list: 'aboutServiceCarousel.correios.secondSlide.listDescriptions',
        availability: 'aboutServiceCarousel.correios.secondSlide.availability'
      }
    ]
  }
};

/** @param {keyof informationsAboutServices} serviceName */
export const getInformationsAboutServicetype = serviceName => {
  return informationsAboutServices[serviceName];
};

export default { getInformationsAboutServicetype };
