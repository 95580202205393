import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDomainUseCase } from 'UI/contexts';

export const CouponsContext = createContext({
  coupons: {},
  getCoupons: async () => {},
  isLoading: false
});

export function CouponsProvider({ children }) {
  const getCouponsUseCase = useDomainUseCase('getCouponsUseCase');
  const [coupons, setCoupons] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getCoupons = useCallback(
    async companyId => {
      try {
        setIsLoading(true);
        const response = await getCouponsUseCase(companyId);
        setCoupons(response);
      } catch {
        setCoupons({ coupons: [] });
      } finally {
        setIsLoading(false);
      }
    },
    [getCouponsUseCase]
  );

  return (
    <CouponsContext.Provider
      value={{
        coupons,
        getCoupons,
        isLoading,
        setCoupons,
        setIsLoading
      }}
    >
      {children}
    </CouponsContext.Provider>
  );
}

CouponsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useCoupons = () => {
  return useContext(CouponsContext);
};
