import React from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@loggi/front-design-system';
import { Box, Typography } from '@mui/material';
import { spacing } from '@loggi/front-design-tokens';

export const ActionVisualizerMobile = ({ children, label, closeAction }) => {
  return (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      justifyContent="center"
      flexDirection="column"
    >
      <Box mt={spacing.stack.xxsmall} pb={spacing.inset.medium}>
        <AppBar data-testid="app-bar" mobile handleClickBack={closeAction} />
      </Box>
      <Box margin={spacing.stack.xxsmall}>
        <Typography variant="subtitleLarge">{label}</Typography>
      </Box>
      {children}
    </Box>
  );
};

ActionVisualizerMobile.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  closeAction: PropTypes.func.isRequired
};

export default ActionVisualizerMobile;
