import { spacing } from '@loggi/front-design-tokens';
import PropTypes from 'prop-types';
import { Stack, Box, Typography, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ShipmentDetailsCancelShipment = ({
  handlePackageCancellation
}) => {
  const { t } = useTranslation('ui');
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="left"
      px={spacing.inset.small}
      py={spacing.inset.large}
      gap={spacing.stack.nano}
    >
      <Stack pl={spacing.inset.xxsmall} gap={spacing.stack.nano}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.cancelShipment.title')}
        </Typography>
        <Typography variant="bodyTextMedium">
          {t('shipmentDetailsV2.cancelShipment.text')}
        </Typography>
      </Stack>
      <Box>
        <Button
          onClick={handlePackageCancellation}
          size="medium"
          variant="text"
        >
          {t('shipmentDetailsV2.cancelShipment.button')}
        </Button>
      </Box>
    </Box>
  );
};

ShipmentDetailsCancelShipment.propTypes = {
  handlePackageCancellation: PropTypes.func.isRequired
};

export default ShipmentDetailsCancelShipment;
