import { Box, Link, Typography } from '@mui/material';
import { useFeatureSwitch } from 'hooks/feature-switch';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export function Slide01() {
  const { t } = useTranslation('ui');
  return (
    <Box>
      <Typography variant="h2">
        <Trans t={t} i18nKey="aboutPackaging.slides.01.heading" />
      </Typography>
      <Box height="24px" />
      <Typography variant="body1">
        {t('aboutPackaging.slides.01.lines.0')}
      </Typography>
      <Box height="16px" />
      <Typography variant="body1">
        {t('aboutPackaging.slides.01.lines.1')}
      </Typography>
    </Box>
  );
}

export function Slide02() {
  const { t } = useTranslation('ui');
  const externalPackagingHelpURL = useFeatureSwitch(
    'external_packaging_informations_url'
  ).data;

  return (
    <Box>
      <Typography variant="h2">
        <Trans t={t} i18nKey="aboutPackaging.slides.02.heading" />
      </Typography>
      <Box height="24px" />
      <Typography variant="body1">
        {t('aboutPackaging.slides.02.lines.0')}
      </Typography>
      <Box height="14px" />
      <Typography variant="body1">
        {t('aboutPackaging.slides.02.lines.1')}
      </Typography>
      <Box height="14px" />
      <Typography variant="body1">
        {t('aboutPackaging.slides.02.lines.2')}
      </Typography>
      <Box height="14px" />
      <Link
        href={externalPackagingHelpURL}
        color="primary"
        underline="none"
        target="_blank"
      >
        <Typography variant="body1">
          <strong>{t('aboutPackaging.slides.02.lines.3')}</strong>
        </Typography>
      </Link>
    </Box>
  );
}

export function Slide03() {
  const { t } = useTranslation('ui');
  return (
    <Box>
      <Typography variant="h2">
        <Trans t={t} i18nKey="aboutPackaging.slides.03.heading" />
      </Typography>
      <Box height="24px" />
      <Typography variant="body1">
        {t('aboutPackaging.slides.03.lines.0')}
      </Typography>
      <Box height="16px" />
      <Typography variant="body1">
        {t('aboutPackaging.slides.03.lines.1')}
      </Typography>
    </Box>
  );
}
