import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { blue } from 'theme/colors';
import { Button } from 'UI/components/button/button.component';

export const GridButton = ({
  cols,
  id,
  disabled,
  isLoading,
  label,
  onClick,
  outlined
}) => {
  if (!label) return null;
  return (
    <Grid item xs={cols}>
      <Button
        buttonId={id}
        disabled={disabled}
        fullWidth
        isLoading={isLoading}
        label={label}
        onClick={onClick}
        textColor={outlined ? blue[500] : undefined}
        variant={outlined ? 'outlined' : undefined}
      />
    </Grid>
  );
};

GridButton.defaultProps = {
  id: '',
  cols: 12,
  disabled: false,
  isLoading: false,
  label: '',
  outlined: false
};

GridButton.propTypes = {
  id: PropTypes.string,
  cols: PropTypes.number,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  outlined: PropTypes.bool
};

export default { GridButton };
