import { getFsValueUseCase } from '../get-fs-value/get-fs-value.use-case';

export const isUserAllowedOnGroupedShipmentInPickupUseCase = async userEmail => {
  const emailsAllowed = await getFsValueUseCase(
    'allowed_users_on_grouped_shipment'
  );
  if (!emailsAllowed) return false;

  if (emailsAllowed === '*') return true;
  return emailsAllowed.includes(userEmail);
};

export default { isUserAllowedOnGroupedShipmentInPickupUseCase };
