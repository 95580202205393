import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from 'UI/components';
import IconCircleContainer from '../icon-circle-container/icon-circle-container.component';

const InfoLine = ({ children, icon }) => (
  <Box
    display="grid"
    gridTemplateColumns="40px 1fr"
    style={{ gridGap: '16px' }}
  >
    <IconCircleContainer>
      <SvgIcon icon={icon} />
    </IconCircleContainer>
    <Box display="flex" flexDirection="column" justifyContent="center">
      {children}
    </Box>
  </Box>
);

InfoLine.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired
};

export default InfoLine;
