/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import useServiceQuoting from 'UI/shipment/hooks/service-quoting';
import { useShipmentContext } from 'UI/shipment/state-machine/context';

export const SentEventContext = createContext();

export const SentEventContextProvider = ({ children }) => {
  const [sentEvent, setSentEvent] = useState('');

  return (
    <SentEventContext.Provider value={{ sentEvent, setSentEvent }}>
      {children}
    </SentEventContext.Provider>
  );
};

/**
 * NOTE:
 * In production builds, class names are minified, leading to issues if the class name
 * attribute is used directly (e.g., `CRMEvent.name`).
 * This is why we use the `eventKey` prop to explicitly pass the event name.
 */
export default function CaptureShipmentPageView({
  children,
  CRMEvent,
  eventKey,
  withoutQuoting = false
}) {
  const { context } = useShipmentContext();
  const quoting = useServiceQuoting();
  const { sentEvent, setSentEvent } = useContext(SentEventContext);

  useEffect(() => {
    let eventPayload = null;

    if (eventKey && sentEvent !== eventKey) {
      if (withoutQuoting) {
        eventPayload = { context };
      } else if (quoting) {
        eventPayload = { context, quoting };
      }
      if (eventPayload) {
        CRMEvent?.fromShipmentContext(eventPayload).sendToCrm();
        setSentEvent(eventKey);
      }
    }
  }, [
    CRMEvent,
    context,
    quoting,
    sentEvent,
    setSentEvent,
    withoutQuoting,
    eventKey
  ]);

  return children;
}

SentEventContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

CaptureShipmentPageView.propTypes = {
  children: PropTypes.node.isRequired,
  CRMEvent: PropTypes.func.isRequired,
  eventKey: PropTypes.string.isRequired,
  withoutQuoting: PropTypes.bool
};

CaptureShipmentPageView.defaultProps = {
  withoutQuoting: false
};
