import mar from '@loggi/mar';
import { createTheme as createThemeV5 } from '@mui/material/styles';
import components from './components';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

/** @type {import('@mui/material').ThemeOptions} */
const themeV5 = {
  ...mar,
  components,
  shape: {
    borderRadius: 0.5
  },
  palette,
  shadows,
  typography
};

export default createThemeV5(themeV5);
