/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import CRMBaseAdapter from 'crm/adapters/crm-base-adapter';
import { removeUndefinedValues } from 'utils';
import { INSIDER_EVENTS } from 'crm/constants';

/**
 * Configuration class for Insider CRM.
 *
 * This class provides methods to check if Insider is initialized,
 * initialize the Insider object, and reinitialize Insider.
 */
class InsiderConfig {
  static isInitialized() {
    return !!window.Insider;
  }

  static initInsiderObject() {
    window.insider_object = window.insider_object || {};
  }

  static reinitializeInsider() {
    window.Insider.eventManager.dispatch('init-manager:re-initialize');
  }
}

/**
 * CRM Adapter for Insider.
 *
 * This class extends from CRMBaseAdapter and implements the sendUser and sendEvent methods
 * for the Insider CRM platform.
 *
 * @extends {CRMBaseAdapter}
 */
export default class CRMAdapterInsider extends CRMBaseAdapter {
  get events() {
    return INSIDER_EVENTS;
  }

  formatUserPayload(userPayload) {
    const {
      email,
      first_name: name,
      last_name: surname,
      gender,
      phone: phone_number,
      company_id,
      email_optin = true,
      ...other_attributes
    } = userPayload;

    const companyId = company_id ? Number(company_id) : undefined;

    return removeUndefinedValues({
      email,
      name,
      surname,
      email_optin,
      gender,
      phone_number,
      custom: {
        company_id: companyId,
        ...other_attributes
      }
    });
  }

  sendUser(userPayload) {
    if (!userPayload || !InsiderConfig.isInitialized()) return;

    InsiderConfig.initInsiderObject();
    const formattedUserPayload = this.formatUserPayload(userPayload);
    if (
      JSON.stringify(formattedUserPayload) !==
      JSON.stringify(window.insider_object.user)
    ) {
      window.insider_object.user = formattedUserPayload;
      InsiderConfig.reinitializeInsider();
    }
  }

  _formatEventPayload(eventParams) {
    const eventPayload = eventParams;
    const { company_id: companyId } = eventPayload;
    if (companyId) {
      eventPayload.company_id = Number(companyId);
    }
    return eventPayload;
  }

  sendEvent(eventName, eventPayload) {
    if (!InsiderConfig.isInitialized()) return;
    const formattedEventPayload = this._formatEventPayload(eventPayload);

    window.Insider.track('events', [
      {
        event_name: eventName,
        event_params: {
          custom: formattedEventPayload
        }
      }
    ]);
  }
}
