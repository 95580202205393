import { Box, SwipeableDrawer, Typography } from '@mui/material';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { grey, red, root } from 'theme/colors';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { Button } from '../button/button.component';

const ShipmentCancelDrawer = ({ onConfirm, onDismiss, open }) => {
  const { t } = useTranslation('ui');
  const isMobileDevice = checkIsMobile();

  return (
    <SwipeableDrawer
      anchor="bottom"
      disableSwipeToOpen
      onClose={onDismiss}
      onOpen={noop}
      open={open}
      PaperProps={{
        square: false,
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          alignItems: 'center'
        }
      }}
    >
      <Box
        alignItems="center"
        color={root[900]}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={isMobileDevice ? '100%' : '860px'}
        p={3}
      >
        <Box
          bgcolor={grey[100]}
          borderRadius="100px"
          height="4px"
          width="24px"
        />
        <Box mt={6} mb={2}>
          <Typography align="center" variant="subtitle1">
            {t('shipmentCancelDrawer.title')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography align="center" variant="subtitle2">
            {t('shipmentCancelDrawer.subtitle')}
          </Typography>
        </Box>
        <Box width="100%">
          <Box mb={1}>
            <Button
              bgColor={red[500]}
              fullWidth
              label={t('shipmentCancelDrawer.confirmButton')}
              onClick={onConfirm}
            />
          </Box>
          <Button
            fullWidth
            label={t('shipmentCancelDrawer.declineButton')}
            onClick={onDismiss}
            textColor={red[500]}
            variant="outlined"
          />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

ShipmentCancelDrawer.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default ShipmentCancelDrawer;
