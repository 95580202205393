import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { USER_IS_NOT_ACTIVE, USER_NOT_FOUND } from 'mobile/constants';

const HELP_LINK =
  'https://ajuda.loggi.com/hc/pt-br/requests/new?utm_source=beyond';

const NativeSignInErrorAlert = ({ error }) => {
  const { t } = useTranslation('ui');
  let i18nKey;

  switch (error) {
    case USER_IS_NOT_ACTIVE:
      i18nKey = 'nativeSignIn.errorMessages.userNotActiveError';
      break;
    case USER_NOT_FOUND:
      i18nKey = 'nativeSignIn.errorMessages.userNotFound';
      break;
    default:
      i18nKey = 'nativeSignIn.errorMessages.genericError';
      Sentry.captureException(`Native login error: ${error}`);
  }

  const linkTag = (
    <Link target="_blank" href={HELP_LINK} underline="always" color="inherit" />
  );
  return <Trans t={t} i18nKey={i18nKey} components={[linkTag]} />;
};

NativeSignInErrorAlert.propTypes = {
  error: PropTypes.string.isRequired
};

export default NativeSignInErrorAlert;
