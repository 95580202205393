/* eslint-disable camelcase */
import * as Yup from 'yup';
import { SchemaValidator } from './schema-validator.model';

export class Address extends SchemaValidator {
  constructor({
    placeId,
    place_id,
    description,
    complement = '',
    structured: { main, secondary } = { main: '', secondary: '' }
  } = {}) {
    super();
    this.placeId = place_id || placeId;
    this.description = description;
    this.complement = complement;
    this.structured = { main, secondary };
  }

  static get schema() {
    return Yup.object().shape({
      description: Yup.string().required(),
      placeId: Yup.string().required(),
      complement: Yup.string()
    });
  }

  /**
   * Returns the description of the address. It is either the
   * address description from Google Places API or
   * the raw postal code
   *
   * @returns {string | undefined} The address description
   */
  getDescription() {
    return this.description;
  }

  get place_id() {
    // For compatibility with AddressField
    return this.placeId;
  }

  /**
   * @param {import('utils/types').ClassProperties<Address>} object
   * @returns {Address|null}
   */
  static fromObject(object) {
    if (!object) return null;
    return new Address(object);
  }

  toWidget() {
    return {
      widget: {
        placesApiInfo: { placeId: this.placeId ?? '' },
        address: this.description
      }
    };
  }

  /**
   * @returns {string} A string containing only the city and state extracted from the address description.
   */
  getCityState() {
    const cleanedAddress = this.structured.secondary;
    if (!cleanedAddress) return '';
    const addressParts = cleanedAddress.split(',');
    return addressParts[addressParts.length - 1].trim();
  }
}

export default Address;
