import {
  Box,
  Button as MuiButton,
  CircularProgress,
  styled
} from '@mui/material';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { blue, root } from 'theme/colors';
import {
  dimensions,
  getBoxShadow,
  getComponent,
  getDisabledStyle,
  getLabelStyle,
  getMuiVariant,
  getStylesByVariant,
  onClickPropType
} from './button.helper';
import { Icon } from './icon.component';

const CustomMuiButton = styled(MuiButton)({
  alignItems: 'center',
  display: 'flex',
  fontFamily: 'inherit',
  padding: '0px 16px',
  textDecoration: 'none',
  '& > span': {
    lineHeight: '150%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  '& *': { pointerEvents: 'none' }
});

export const Button = ({
  alignment,
  bgColor,
  borderColor,
  borderWidth,
  disabled,
  fontWeight,
  fullWidth,
  href,
  icon,
  id,
  isLoading,
  label,
  onClick,
  size,
  textColor,
  to,
  type,
  variant,
  disableShadow
}) => {
  const dimension = get(dimensions, size, dimensions.md);
  const variantStyle = getStylesByVariant(
    bgColor,
    borderColor,
    borderWidth,
    textColor,
    disabled,
    variant
  );
  const width = fullWidth ? '100%' : 'fit-content';

  return (
    <CustomMuiButton
      component={getComponent(href, to)}
      disabled={disabled}
      href={href}
      id={id}
      onClick={isLoading ? noop : onClick}
      rel="noopener"
      style={{
        ...dimension,
        ...(disableShadow ? {} : getBoxShadow(disabled, variant)),
        ...getDisabledStyle(disabled, variant),
        ...variantStyle,
        fontWeight,
        justifyContent: alignment,
        width
      }}
      target={href && '_blank'}
      to={to}
      type={type}
      variant={getMuiVariant({ variant })}
    >
      {icon && !isLoading && (
        <Icon
          color={variantStyle.color}
          disabled={disabled}
          icon={icon}
          label={label}
          size={dimension.iconSize}
        />
      )}
      <span style={getLabelStyle({ alignment })}>{label}</span>
      {isLoading && (
        <div style={{ position: 'relative', top: 0, height: '50%' }}>
          <Box color={variantStyle.color} ml={2} position="absolute">
            <CircularProgress color="inherit" size={dimension.loaderSize} />
          </Box>
        </div>
      )}
    </CustomMuiButton>
  );
};

Button.defaultProps = {
  alignment: 'center',
  bgColor: blue[500],
  borderColor: undefined,
  borderWidth: 1,
  disabled: false,
  fontWeight: 700,
  fullWidth: false,
  href: '',
  icon: '',
  id: undefined,
  isLoading: false,
  onClick: noop,
  size: 'md',
  textColor: root[0],
  to: null,
  type: 'button',
  variant: 'contained',
  disableShadow: false
};

Button.propTypes = {
  alignment: PropTypes.oneOf(['center', 'left', 'right']),
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  disabled: PropTypes.bool,
  fontWeight: PropTypes.number,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: onClickPropType,
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  textColor: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string
    })
  ]),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'outlined-neutral',
    'text'
  ]),
  disableShadow: PropTypes.bool
};

export default { Button };
