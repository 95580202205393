import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useShipmentContext } from 'UI/shipment/state-machine/context';
import AddressCoverageUseCase from 'UI/shipment/use-cases/get-address-coverage';
import { getAmateurCoverageUseCase } from 'domain/use-cases';
import { InputAddress } from '../models';

/**
 * It returns a list of service types available for the given address.
 *
 * @param {object} options
 * @param {import('models').Address | import('UI/shipment/models').InputAddress} options.address
 * @param {(data: {hasCoverage: boolean, serviceTypes: Array<string>}) => void} options.onSuccess
 * @param {(error: Error) => void} options.onError
 * @returns {import('@tanstack/react-query').UseQueryResult<{hasCoverage: boolean, serviceTypes: Array<string>}, Error>}
 */
export default function useAmateurAddressCoverage({
  address,
  onSuccess,
  onError
}) {
  const { context } = useShipmentContext();
  const { companyId } = context;
  const isUsingInputAddress = address instanceof InputAddress;

  return useQuery(
    [
      'amateur-address-coverage',
      isUsingInputAddress ? address.correios.cep : address?.placeId,
      companyId
    ],
    function fetcher() {
      if (isUsingInputAddress)
        return new AddressCoverageUseCase({ address, companyId }).execute();

      return getAmateurCoverageUseCase({ address, companyId });
    },
    {
      enabled: !!address,
      refetchOnWindowFocus: false,
      onSuccess,
      onError(error) {
        captureException(error);
        if (onError) onError(error);
      }
    }
  );
}
