import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS, { NEW_VOLUME_QUALIFICATION_OPTION_STRING } from 'crm/constants';
import {
  formatPhoneNumber,
  getCreationMethod,
  getFirstName,
  getLastName
} from 'crm/utils';

export default class AccountCreationFailed extends CRMBaseEvent {
  constructor({
    name,
    userType,
    document,
    sharedName,
    email,
    phone,
    provider,
    cep,
    segmentName,
    averageTicket,
    integrators,
    otherIntegrator,
    shipmentQualification,
    volumeQualification,
    errorStatus,
    errorMessage
  }) {
    super();
    this.eventName = EVENTS.ACCOUNT_CREATION_FAILED;
    this.payload = {
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      phone: formatPhoneNumber(phone),
      registration_method: getCreationMethod(provider),
      user_type: userType,
      document,
      shared_name: sharedName,
      cep,
      segment: segmentName,
      average_ticket: averageTicket,
      integrators,
      other_integrator: otherIntegrator,
      shipment_qualification: shipmentQualification,
      volume_qualification: volumeQualification,
      volume_qualification_string:
        NEW_VOLUME_QUALIFICATION_OPTION_STRING[volumeQualification],
      error_status: errorStatus,
      error_message: errorMessage
    };
  }
}
