import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Dark, Light } from './skeleton-loader.styled';

const variants = { dark: Dark, light: Light };

/** @param {{variant: keyof variants}} */
const SkeletonLoader = ({ height, variant, width }) => {
  const Component = get(variants, variant, variants.dark);
  return (
    <Component animation="wave" height={height} variant="rect" width={width} />
  );
};

SkeletonLoader.defaultProps = { height: 16, variant: 'dark', width: 80 };

SkeletonLoader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(Object.keys(variants)),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default SkeletonLoader;
