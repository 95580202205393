import { VARIANTS_BY_STATUS } from 'constants/tracking.constants';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { blue, green, grey, root } from 'theme/colors';
import * as Style from './package-status-dot.style.module.css';

export const STYLES_BY_VARIANTS = {
  danger: { '--fg': `${root[0]}`, '--bg': `${root[0]}80` },
  info: { '--fg': `${grey[300]}`, '--bg': `${grey[50]}` },
  normal: { '--fg': `${blue[500]}`, '--bg': `${blue[50]}` },
  success: { '--fg': `${green[500]}`, '--bg': `${green[50]}` }
};

const getCustomStyle = status => {
  const variant = get(VARIANTS_BY_STATUS, status, 'normal');
  return get(STYLES_BY_VARIANTS, variant, STYLES_BY_VARIANTS.normal);
};

const PackageStatusDot = ({ status }) => {
  const style = !status ? STYLES_BY_VARIANTS.danger : getCustomStyle(status);

  return <div className={Style.statusDot} style={style} />;
};

PackageStatusDot.propTypes = {
  status: PropTypes.oneOf([...Object.keys(VARIANTS_BY_STATUS), ''])
};

PackageStatusDot.defaultProps = {
  status: ''
};

export default PackageStatusDot;
