import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from 'theme/custom-classes';
import { SvgIcon } from 'UI/components';

export const Header = ({ handlePrev, handleClose, title }) => {
  const { header } = useStyles();

  return (
    <Box
      alignItems="center"
      className={header}
      display="flex"
      justifyContent="space-between"
      mb={2}
    >
      <IconButton onClick={handlePrev}>
        <SvgIcon icon="chevronLeft" size="24" />
      </IconButton>
      <Box mx={0.5}>
        <Typography variant="body2" style={{ lineHeight: 'unset' }}>
          <strong>{title}</strong>
        </Typography>
      </Box>
      <IconButton onClick={handleClose}>
        <SvgIcon icon="delete" size="32" />
      </IconButton>
    </Box>
  );
};

Header.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default { Header };
