import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentDestinationByGoogleAddressSelected extends CRMBaseEvent {
  constructor({ addressInputed, addressSelected }) {
    super();
    this.eventName = EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_ADDRESS_SELECTED;
    this.payload = {
      address_inputed: addressInputed,
      address_selected: addressSelected
    };
  }
}
