import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useLocalStorageState } from 'use-local-storage-state';

const INITIAL_STATE = { id: null, brand: '', number: '' };
const STORAGE_KEY = 'loggi-beyond@user-data';

const save = setter => {
  return (paymentOption = {}) => {
    const b64 = Buffer.from(JSON.stringify(paymentOption)).toString('base64');
    setter(b64);
  };
};

const parse = b64 => {
  const raw = Buffer.from(b64, 'base64');
  return JSON.parse(raw);
};

export const paymentOptionsRepo = {
  useLocalStorageState() {
    const [rawFormsValues, setFormsValues] = useLocalStorageState(
      STORAGE_KEY,
      Buffer.from(JSON.stringify(INITIAL_STATE)).toString('base64')
    );
    const setter = save(setFormsValues);
    return [parse(rawFormsValues), setter];
  }
};

export const PaymentOptionsContext = createContext();

export const PaymentOptionsProvider = ({ children }) => {
  const [state, setter] = paymentOptionsRepo.useLocalStorageState();
  return (
    <PaymentOptionsContext.Provider value={{ state, setter }}>
      {children}
    </PaymentOptionsContext.Provider>
  );
};

PaymentOptionsProvider.propTypes = { children: PropTypes.node.isRequired };

export const usePaymentOption = () => {
  const { state, setter } = useContext(PaymentOptionsContext);
  return [state, setter];
};

export default { paymentOptionsRepo };
