import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Stack } from '@mui/material';

export const PickupInfo = ({ pickupStatus }) => {
  const { t } = useTranslation('ui');
  return (
    <Box p={spacing.inset.large}>
      <Stack>
        <Typography pb={spacing.stack.nano} variant="subtitleMedium">
          {t('shipmentDetailsV2.pickupInfo.title')}
        </Typography>
        <Typography variant="bodyTextMedium">{pickupStatus}</Typography>
      </Stack>
    </Box>
  );
};

PickupInfo.propTypes = {
  pickupStatus: PropTypes.string.isRequired
};

export default PickupInfo;
