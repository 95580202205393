import React, { useState } from 'react';
import { Box, Checkbox, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Search } from '@loggi/front-design-system';
import { spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import { STATUS_FILTER_OPTIONS } from 'UI/shipments-v2/constants/shipments.constants';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import { ActionVisualizerMobile } from '../action-visualizer-mobile/action-visualizer-mobile.component';
import { CardSelectionInline } from '../card-selection-inline/card-selection-inline.component';

export const StatusFilterMobile = ({ handleClose }) => {
  const [searchFieldValue, setSearchFieldValue] = useState(null);
  const { t } = useTranslation('ui');

  const { setStatusFilter, statusFilter, shipments } = useShipments();

  const handleSearch = label => {
    setSearchFieldValue(label);
  };

  const clearFilters = () => {
    setStatusFilter();
  };

  const handleSelectFilter = item => {
    const newActiveItems = statusFilter?.map(i => i.value).includes(item.value)
      ? statusFilter.filter(selectedItem => selectedItem.value !== item.value)
      : [...statusFilter, item];

    setStatusFilter(newActiveItems);
  };

  const filteredOptions = searchFieldValue
    ? STATUS_FILTER_OPTIONS.filter(option =>
        option.label
          .trim()
          .toLowerCase()
          .includes(searchFieldValue.toLowerCase())
      )
    : STATUS_FILTER_OPTIONS;

  return (
    <ActionVisualizerMobile
      closeAction={handleClose}
      label={t('myShipmentsV2.filterShipmentsMobile.title')}
    >
      <Box
        px={spacing.inset.large}
        marginBottom={spacing.stack.xxsmall}
        display="flex"
      >
        <Search
          label={t('myShipmentsV2.filterShipmentsMobile.searchInputLabel')}
          size="small"
          onChange={handleSearch}
        />
      </Box>
      <Box
        flexDirection="column"
        gap={spacing.stack.xxsmall}
        display="flex"
        overflow="auto"
        flexGrow={1}
        mb={spacing.stack.xxsmall}
      >
        {filteredOptions.map(option => {
          const selected = statusFilter
            .map(i => i.value)
            .includes(option.value);
          return (
            <CardSelectionInline
              key={option.label}
              selected={selected}
              label={option.label}
              handleClick={() => handleSelectFilter(option)}
            >
              <Checkbox checked={selected} />
            </CardSelectionInline>
          );
        })}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        px={spacing.inset.large}
        py={spacing.stack.xxsmall}
        gap={spacing.stack.nano}
      >
        <Button variant="contained" onClick={handleClose} size="large">
          {t('myShipmentsV2.filterShipmentsMobile.button')}
          {shipments.length}
          {t('myShipmentsV2.filterShipmentsMobile.buttonAux')}
        </Button>
        <Button variant="blank" onClick={clearFilters} size="large">
          {t('myShipmentsV2.filterShipmentsMobile.clearButton')}
        </Button>
      </Box>
    </ActionVisualizerMobile>
  );
};

StatusFilterMobile.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default StatusFilterMobile;
