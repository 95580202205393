import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { INPUT_TYPES } from 'constants/index';
import { useCurrentUser } from 'hooks/current-user/use-current-user.hook';
import { ValidatorBuilder } from 'infra';
import { AmateurQuoting } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { yellow } from 'theme/colors';
import { PhoneInput, TextInput } from 'UI/components/input';
import Footer from 'UI/shipment/components/shipment-footer';
import useFormManager from 'UI/shipment/hooks/form-manager';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { useCompanyData } from 'hooks/company-data/company-data.hook';

const INDISPATCH_USER_PHONE = '00000000000';

export default function PickupCustomerPage() {
  const { t } = useTranslation('ui');
  const shipmentContext = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const { user } = useCurrentUser();
  const companyId = user?.companyId ?? null;
  const { data } = useCompanyData(companyId);

  const serviceIsIndespacho = AmateurQuoting.isIndespacho({
    serviceType: shipmentContext.context.serviceType
  });
  const serviceIsDropoff = AmateurQuoting.isDropoff(
    shipmentContext.context.serviceType
  );

  const nameLabel = serviceIsIndespacho
    ? 'pickupCustomerPageV2.nameLabelIndespacho'
    : 'pickupCustomerPageV2.nameLabel';

  const fields = {
    name: {
      label: t(nameLabel),
      type: INPUT_TYPES.text,
      validator: new ValidatorBuilder()
        .nonEmptyString('pickupCustomerPageV2.errorMessages.invalidName')
        .build(),
      value: shipmentContext.context.pickupCustomer?.name,
      defaultValue: shipmentContext.context.pickupCustomer?.name || user?.name
    },
    phone: {
      label: t('pickupCustomerPageV2.phoneLabel'),
      type: INPUT_TYPES.phone,
      validator: serviceIsIndespacho
        ? undefined
        : new ValidatorBuilder().phoneNumberPattern().build(),
      value: serviceIsIndespacho
        ? INDISPATCH_USER_PHONE
        : shipmentContext.context.pickupCustomer?.phone,
      defaultValue: serviceIsIndespacho
        ? INDISPATCH_USER_PHONE
        : shipmentContext.context.pickupCustomer?.phone || data?.landline1
    },
    email: {
      label: t('pickupCustomerPageV2.emailLabel'),
      type: INPUT_TYPES.email,
      validator: serviceIsIndespacho
        ? new ValidatorBuilder()
            .emailPattern(t('pickupCustomerPageV2.errorMessages.invalidEmail'))
            .build()
        : undefined,
      value: shipmentContext.context.pickupCustomer?.email,
      defaultValue: shipmentContext.context.pickupCustomer?.email || user?.email
    }
  };

  const handleSubmit = async ({ email, name, phone }) => {
    const payload = { email, name, phone };
    shipmentDispatcher.continue(payload);
  };

  const form = useFormManager({ fields, onSubmit: handleSubmit });

  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1">
          {t('pickupCustomerPageV2.title')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} mb={5}>
        <TextInput
          id="name-input"
          label={fields.name.label}
          value={form.values.name}
          error={t(form.errors.name)}
          onChange={name => form.setValue('name', name)}
        />
        {!serviceIsIndespacho && (
          <PhoneInput
            id="phone-input"
            label={fields.phone.label}
            value={form.values.phone}
            error={t(form.errors.phone)}
            onChange={phone => form.setValue('phone', phone)}
          />
        )}
        {(serviceIsIndespacho || serviceIsDropoff) && (
          <TextInput
            id="email-input"
            label={fields.email.label}
            value={form.values.email}
            error={t(form.errors.email)}
            onChange={email => form.setValue('email', email)}
          />
        )}
        {serviceIsIndespacho && (
          <Box
            bgcolor={yellow[50]}
            borderRadius="8px"
            color={yellow[900]}
            p={2}
          >
            <Typography variant="body2" fontWeight="500">
              {t('pickupCustomerPageV2.alertShipmentIndispatch')}
            </Typography>
          </Box>
        )}
        <DropoffMessage serviceType={shipmentContext.context.serviceType} />
      </Box>
      <Footer>
        <Footer.PrimaryAction onClick={form.commit} />
      </Footer>
    </>
  );
}

function DropoffMessage({ serviceType }) {
  const { t } = useTranslation('ui');
  if (!AmateurQuoting.isDropoff(serviceType)) return null;

  return (
    <Box bgcolor={yellow[50]} borderRadius="8px" color={yellow[900]} p={2}>
      <Typography variant="body2" fontWeight="500">
        {t('pickupCustomerPageV2.alertShipmentIndispatch')}
      </Typography>
    </Box>
  );
}

DropoffMessage.propTypes = {
  serviceType: PropTypes.string.isRequired
};
