import { loggiWebApi } from '@loggi/authentication-lib';

const BEYOND_API_URL = process.env.REACT_APP_PY_BEYOND_URL;

/**
 * @param {object} params
 * @param {object} params.payload
 * @param {string} params.companyId
 * @param {import('models/company.model').Company | undefined} params.company
 */
export const getQuoting = async ({ payload, companyId }) => {
  const base = BEYOND_API_URL;
  const baseEndpoint = '/pricing/v1/estimate/:companyId/';

  const endpoint = baseEndpoint.replace(':companyId', companyId);

  const url = base + endpoint;

  return loggiWebApi
    .url(url, true)
    .post(payload)
    .json();
};

export const getCoverage = async ({ companyId, payload }) => {
  const endpoint = companyId
    ? `/beyond/api/shipment/v1/coverage/${companyId}/`
    : '/beyond/api/shipment/v1/coverage/';

  return loggiWebApi
    .url(endpoint)
    .post(payload)
    .json();
};
