export class OccasionalTrackingStatus {
  constructor({
    occasionalShipperStatusCode,
    label,
    title,
    description,
    additionalInformation,
    actionRequired,
    updateTime
  }) {
    this.occasionalShipperStatusCode = occasionalShipperStatusCode;
    this.label = label;
    this.title = title;
    this.description = description;
    this.additionalInformation = additionalInformation;
    this.actionRequired = actionRequired;
    this.updateTime = updateTime;
  }

  static parseApiResponse(response) {
    if (Object.keys(response).length === 0) return null;

    return new OccasionalTrackingStatus({
      occasionalShipperStatusCode: response.occasionalShipperStatusCode,
      label: response.label,
      title: response.title,
      description: response.description,
      additionalInformation: response.additionalInformation,
      actionRequired: response.actionRequired,
      updateTime: response.updateTime
    });
  }
}

export default OccasionalTrackingStatus;
