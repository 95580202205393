import { Box, Divider, Typography } from '@mui/material';
import { Button, SvgIcon } from 'UI/components';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { ReactComponent as HandWithMobileIllustration } from 'assets/hand-with-mobile.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { newHome, occasionalTracking } from 'routes/routes';
import { grey } from 'theme/colors';
import { fillCompanyId } from 'utils';
import { areAddressesPresent } from 'UI/shipment/state-machine/utils';

import { ThemeProvider } from '@mui/material/styles';
import { colors } from '@loggi/front-design-tokens';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';

import { ShipmentInfo } from './shipment-info/shipment-info.component';

export const SuccessPageV2 = () => {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();
  const history = useHistory();
  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const hasValidInfos =
    areAddressesPresent(context) && context?.packageDimensions;

  if (!hasValidInfos) {
    return (
      <Redirect
        to={{
          pathname: fillCompanyId({ companyId, route: newHome }),
          search: window.location.search
        }}
      />
    );
  }

  const handleCreateShipment = () => {
    shipmentDispatcher.continue();
    history.push(fillCompanyId({ companyId, route: newHome }));
  };

  const handleViewDetails = () => {
    shipmentDispatcher.continue();
    history.push(
      occasionalTracking
        .replace(':companyId', context.companyId)
        .replace(':loggiKey', context.shipmentOrder.loggiKey)
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        alignItems="center"
        bgcolor={grey[250]}
        color={colors.neutrals.surface}
        display="flex"
        justifyContent="space-between"
        p={3}
        pt={10}
      >
        <Box>
          <Typography
            variant="subtitleLarge"
            color={colors.neutrals.surface}
            fontWeight={400}
          >
            {t('indispatchSuccessPage.title')}
          </Typography>
          <Typography variant="bodyTextMedium">
            {t('indispatchSuccessPage.subtitle')}
          </Typography>
        </Box>
        <SvgIcon
          color={colors.neutrals.surface}
          icon="loadingCircle"
          size="32"
        />
      </Box>
      <Box>
        <Box alignItems="center" display="flex" gap={3} p={3}>
          <Box height="80px">
            <HandWithMobileIllustration />
          </Box>
          <Box>
            <Typography variant="body1" color={colors.neutrals['on-surface']}>
              {t('indispatchSuccessPage.postcodeInformation.title')}
            </Typography>
            <Typography sx={{ color: grey[200] }} variant="bodyTextMedium">
              {t('indispatchSuccessPage.postcodeInformation.subtitle')}
            </Typography>
          </Box>
        </Box>
        <Divider color={colors.neutrals['outline-low']} />
        <ShipmentInfo
          deliveryAddress={
            context.deliveryAddress ?? context.destinationAddress.toStructured()
          }
          returnAddress={
            context.pickupAddress ?? context.originAddress.toStructured()
          }
          dimensions={context.packageDimensions}
          packagingService={context.packagingService}
        />
        <Box display="flex" flexDirection="column" gap={1} p={3}>
          <Button
            fontWeight={700}
            fullWidth
            label={t('indispatchSuccessPage.buttons.createShipment')}
            onClick={handleCreateShipment}
          />
          <Button
            fullWidth
            fontWeight={700}
            textColor={colors.interaction.primary}
            variant="text"
            label={t('indispatchSuccessPage.buttons.seeDetails')}
            onClick={handleViewDetails}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default { SuccessPageV2 };
