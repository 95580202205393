import { fetchOccasionalTrackingDetailsUseCase } from 'domain/use-cases';
import { useCallback, useEffect, useState } from 'react';

export const useShipmentDetails = ({ companyId, loggiKey }) => {
  const [state, setState] = useState({
    /** @type {import('models').OccasionalTrackingInformation} */
    shipment: null,
    isLoading: true,
    isError: false
  });

  const effectFn = useCallback(async () => {
    try {
      const shipment = await fetchOccasionalTrackingDetailsUseCase(
        companyId,
        loggiKey
      );

      setState(currentState => ({
        ...currentState,
        shipment,
        isLoading: false,
        isError: false
      }));
    } catch (_err) {
      setState(currentState => ({
        ...currentState,
        shipment: null,
        isLoading: false,
        isError: true
      }));
    }
  }, [companyId, loggiKey]);

  useEffect(() => {
    if (companyId && loggiKey) {
      effectFn();
    }
  }, [companyId, loggiKey, effectFn]);

  return { ...state, reFetch: effectFn };
};

export default useShipmentDetails;
