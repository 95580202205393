const handleSnackbarError = (
  showSnackbar,
  errorMessage,
  onClose = () => {}
) => {
  showSnackbar({
    message: errorMessage,
    severity: 'error',
    buttonLabel: 'ENTENDI',
    onClose
  });
};

export default handleSnackbarError;
