import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import validateCoupon from 'domain/use-cases/validate-coupon/validate-coupon.use-case';
import { useShipmentContext } from 'UI/shipment/state-machine/context';
import useServiceQuoting from './service-quoting';

/**
 * Fetches the coupon by its code. If the coupon code is valid and appliable,
 * it will return the coupon with the original amount and the amount with
 * the discount already applied.
 */
export default function useCoupon() {
  const { context } = useShipmentContext();
  const serviceQuoting = useServiceQuoting();
  const { companyId, serviceType } = context;
  const couponCode = context.coupon;
  const totalAmount = serviceQuoting?.price;

  return useQuery(
    ['coupon', String(context.companyId), context.coupon],
    () => validateCoupon({ companyId, couponCode, totalAmount, serviceType }),
    {
      enabled: !!companyId && !!couponCode && !!totalAmount,
      retry: false,
      onError(err) {
        captureException(err);
      }
    }
  );
}
