import { captureException } from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDomainUseCase } from 'UI/contexts';

const getQueryKey = companyId => ['tracking-summary', String(companyId)];

export const useTrackingSummary = companyId => {
  const fetchSummary = useDomainUseCase('fetchOccasionalTrackingListUseCase');
  const key = getQueryKey(companyId);
  return useQuery(
    key,
    () => fetchSummary(companyId).then(response => response || []),
    {
      enabled: !!companyId,
      onError(err) {
        captureException(err);
      }
    }
  );
};

export const useTrackingSummaryCachedData = companyId => {
  const key = getQueryKey(companyId);
  const queryClient = useQueryClient();
  return queryClient.getQueryData(key);
};

export default { useTrackingSummary };
