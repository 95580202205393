import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { PaymentOptionsProvider } from 'repositories';
import PageSenderViewed from 'crm/entities/events/page-sender-viewed/page-sender-viewed';
import PageRecipientViewed from 'crm/entities/events/page-recipient-viewed/page-recipient-viewed';
import * as Routes from 'routes/routes';
import CaptureShipmentPageView, {
  SentEventContextProvider
} from 'UI/decorators/crm-integration/page-view/capture-page-view';
import PackageDimensionsPageViewed from 'crm/entities/events/package-dimensions-page-viewed/package-dimensions-page-viewed';
import NewPackageSizePageViewed from 'crm/entities/events/new-package-size-page-viewed/new-package-size-page-viewed';
import PageContentDeclarationViewed from 'crm/entities/events/page-content-declaration-viewed/page-content-declaration-viewed';
import PagePickupScheduleViewed from 'crm/entities/events/page-pickup-schedule-viewed/page-pickup-schedule-viewed';
import PageDataConfirmationViewed from 'crm/entities/events/page-data-confirmation-viewed/page-data-confirmation-viewed';
import PageCheckoutViewed from 'crm/entities/events/page-checkout-viewed/page-checkout-viewed';
import Header from './components/header';
import AboutPackagingPage from './pages/about-packaging/about-packaging.page';
import DeliveryCustomerPage from './pages/delivery-customer/delivery-customer.page';
import DeliveryAddressPage from './pages/delivery/delivery.page';
import IndispatchPage from './pages/indispatch/indispatch.page';
import InsuranceValuePage from './pages/insurance-value/insurance-value.page';
import PackageDimensionsPage from './pages/package-dimensions/package-dimensions.page';
import NewPackageSizePage from './pages/package-size-new/package-size.page';
import PackageSizePage from './pages/package-size/package-size.page';
import PackagingRequiringPage from './pages/packaging-requiring/packaging-requiring.page';
import PickupCustomerPage from './pages/pickup-customer/pickup-customer.page';
import PickupSchedulePage from './pages/pickup-schedule/pickup-schedule.page';
import PickupAddressPage from './pages/pickup/pickup.page';
import CheckoutPage from './pages/checkout/checkout.page';
import { useShipmentDispatcher } from './state-machine/context';
import ShipmentCreatedV2 from './pages/shipment_created_v2/shipment-created.page';
import AgenciesPage from './pages/agencies/agencies.page';
import DropoffOnboardingPage from './pages/dropoff-onboarding/dropoff-onboarding.page';
import PostalCodeAddressesPage from './pages/postal-code-addresses/postal-code-addresses.page';
import OriginAddressPage from './pages/origin-address/origin-address.page';
import DestinationAddressPage from './pages/destination-address/destination-address.page';
import PixPaymentPage from './pages/pix-payment/pix-payment.page';
import PaymentPage from './pages/payment/payment.page';
import AddWalletBalanceOptionsPage from './pages/wallet/add-balance-options/add-wallet-balance-options.page';
import PreDefinedRechargeOptions from './pages/wallet/pre-defined-recharge-options/pre-defined-recharge-options.page';
import InputRechargeValuePage from './pages/wallet/input-recharge-value/input-recharge-value.page';
import BalanceSuccessfullyAddedPage from './pages/wallet/balance-successfully-added/balance-successfully-added.page';
import AddBalanceErrorPage from './pages/wallet/add-balance-error/add-balance-error.page';
import { CouponsPage } from './pages/coupons/coupons.page';

export default function ShipmentRouter() {
  const fallbackPath = Routes.companyIdGuard;
  const shipmentDispatcher = useShipmentDispatcher();
  const summaryRouterMatch = useRouteMatch(Routes.shipmentSummaryV2);
  const summaryIndespachoRouterMatch = useRouteMatch(
    Routes.shipmentIndispatchSummaryV2
  );
  const summarySuccessIndespachoRouterMatch = useRouteMatch(
    Routes.shipmentIndispatchSuccessV2
  );
  const isAtCheckout =
    summaryRouterMatch?.isExact ||
    summaryIndespachoRouterMatch?.isExact ||
    summarySuccessIndespachoRouterMatch?.isExact;
  const isAtCheckoutSuccess = useRouteMatch(Routes.shipmentSummarySuccessV2)
    ?.isExact;

  const summaryCouponRouterMatch = useRouteMatch(
    Routes.shipmentSummaryCouponsV2
  );
  const paymentCouponRouterMatch = useRouteMatch(Routes.shipmentPaymentCoupons);
  const isAtCoupon =
    summaryCouponRouterMatch?.isExact || paymentCouponRouterMatch?.isExact;

  const isAtIndispatchOnboarding = useRouteMatch(
    Routes.shipmentIndispatchOnboardingV2
  )?.isExact;
  const isAtPackagingInformations = useRouteMatch(
    Routes.shipmentPackagingInformationsV2
  )?.isExact;
  const isAtShipmentCreated = useRouteMatch(Routes.shipmentCreatedV2)?.isExact;
  const isAtDropoffOnboarding = useRouteMatch(Routes.shipmentDropoffOnboarding)
    ?.isExact;
  const isAtPixPayment = useRouteMatch(Routes.shipmentPaymentPix)?.isExact;
  const isAtPayment = useRouteMatch(Routes.shipmentPayment)?.isExact;
  const isAtAddWalletBalanceOptions = useRouteMatch(
    Routes.shipmentAddWalletBalanceOptions
  )?.isExact;
  const isAtPreDefinedRechargeOptions = useRouteMatch(
    Routes.shipmentPreDefinedRechargeOptions
  )?.isExact;
  const isAtInputRechargeValue = useRouteMatch(
    Routes.shipmentInputRechargeValue
  )?.isExact;
  const isAtAddWalletBalanceSuccess = useRouteMatch(
    Routes.shipmentAddWalletBalanceSuccess
  )?.isExact;
  const isAtAddWalletBalanceError = useRouteMatch(
    Routes.shipmentAddWalletBalanceError
  )?.isExact;

  const showHeader =
    !isAtCheckout &&
    !isAtCheckoutSuccess &&
    !isAtCoupon &&
    !isAtIndispatchOnboarding &&
    !isAtPackagingInformations &&
    !isAtShipmentCreated &&
    !isAtDropoffOnboarding &&
    !isAtPayment &&
    !isAtPixPayment &&
    !isAtAddWalletBalanceOptions &&
    !isAtPreDefinedRechargeOptions &&
    !isAtInputRechargeValue &&
    !isAtAddWalletBalanceSuccess &&
    !isAtAddWalletBalanceError;

  const onGoBack = () => {
    shipmentDispatcher.back();
  };

  return (
    <PaymentOptionsProvider>
      <SentEventContextProvider>
        <Box display="flex" flexDirection="column" width="100%">
          {showHeader && (
            <Header>
              <Header.ArrowBackButton onGoBack={onGoBack} />
            </Header>
          )}
          <Switch>
            <Route path={Routes.shipmentPickupAddressV2} exact>
              <PageLayout>
                <PickupAddressPage />
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentDeliveryAddressV2} exact>
              <PageLayout>
                <DeliveryAddressPage />
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentPostalCodeAddresses} exact>
              <PageLayout>
                <PostalCodeAddressesPage />
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentOriginAddress} exact>
              <PageLayout>
                <OriginAddressPage />
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentDestinationAddress} exact>
              <PageLayout>
                <DestinationAddressPage />
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentRequirePackagingV2} exact>
              <PageLayout>
                <PackagingRequiringPage />
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentPickupCustomerV2} exact>
              <PageLayout>
                <CaptureShipmentPageView
                  CRMEvent={PageSenderViewed}
                  eventKey="PageSenderViewed"
                >
                  <PickupCustomerPage />
                </CaptureShipmentPageView>
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentDeliveryCustomerV2} exact>
              <PageLayout>
                <CaptureShipmentPageView
                  CRMEvent={PageRecipientViewed}
                  eventKey="PageRecipientViewed"
                >
                  <DeliveryCustomerPage />
                </CaptureShipmentPageView>
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentInsuranceValueV2} exact>
              <PageLayout>
                <CaptureShipmentPageView
                  CRMEvent={PageContentDeclarationViewed}
                  eventKey="PageContentDeclarationViewed"
                >
                  <InsuranceValuePage />
                </CaptureShipmentPageView>
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentListAgenciesV2} exact>
              <PageLayout>
                <AgenciesPage />
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentPackageSizeV2} exact>
              <PageLayout>
                <PackageSizePage />
              </PageLayout>
            </Route>
            <Route path={Routes.newShipmentPackageSizeV2} exact>
              <PageLayout>
                <CaptureShipmentPageView
                  CRMEvent={NewPackageSizePageViewed}
                  eventKey="NewPackageSizePageViewed"
                >
                  <NewPackageSizePage />
                </CaptureShipmentPageView>
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentPackageDimensionsV2} exact>
              <PageLayout>
                <CaptureShipmentPageView
                  CRMEvent={PackageDimensionsPageViewed}
                  eventKey="PackageDimensionsPageViewed"
                >
                  <PackageDimensionsPage />
                </CaptureShipmentPageView>
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentPackagingInformationsV2} exact>
              <AboutPackagingPage />
            </Route>
            <Route path={Routes.shipmentDropoffOnboarding} exact>
              <DropoffOnboardingPage />
            </Route>
            <Route path={Routes.shipmentPickupScheduleV2} exact>
              <PageLayout>
                <CaptureShipmentPageView
                  CRMEvent={PagePickupScheduleViewed}
                  eventKey="PagePickupScheduleViewed"
                >
                  <PickupSchedulePage />
                </CaptureShipmentPageView>
              </PageLayout>
            </Route>
            <Route path={Routes.shipmentSummaryV2}>
              <CaptureShipmentPageView
                CRMEvent={PageDataConfirmationViewed}
                eventKey="PageDataConfirmationViewed"
              >
                <CheckoutPage />
              </CaptureShipmentPageView>
            </Route>
            <Route path={Routes.shipmentPayment} exact>
              <CaptureShipmentPageView
                CRMEvent={PageCheckoutViewed}
                eventKey="PageCheckoutViewed"
              >
                <PaymentPage />
              </CaptureShipmentPageView>
            </Route>
            <Route path={Routes.shipmentPaymentCoupons} exact>
              <CouponsPage />
            </Route>
            <Route path={Routes.shipmentPaymentPix} exact>
              <PixPaymentPage />
            </Route>
            <Route path={Routes.shipmentCreatedV2} exact>
              <ShipmentCreatedV2 />
            </Route>
            <Route path={Routes.shipmentIndispatchV2}>
              <IndispatchPage />
            </Route>
            <Route path={Routes.shipmentAddWalletBalanceOptions} exact>
              <AddWalletBalanceOptionsPage />
            </Route>
            <Route path={Routes.shipmentPreDefinedRechargeOptions} exact>
              <PreDefinedRechargeOptions />
            </Route>
            <Route path={Routes.shipmentInputRechargeValue} exact>
              <InputRechargeValuePage />
            </Route>
            <Route path={Routes.shipmentAddWalletBalanceSuccess} exact>
              <BalanceSuccessfullyAddedPage />
            </Route>
            <Route path={Routes.shipmentAddWalletBalanceError} exact>
              <AddBalanceErrorPage />
            </Route>
            <Redirect to={fallbackPath} />
          </Switch>
        </Box>
      </SentEventContextProvider>
    </PaymentOptionsProvider>
  );
}

/**
 * PageLayout is a temporary component that for now will just
 * add some padding to selected pages. The summary page is quite different
 * from all other shipment pages.
 * It's expected to move this component to a more generic place in the future,
 * and use it inside of each page. I'm not doing this for now to avoid having
 * too many differences between the original files and the new ones.
 */
// eslint-disable-next-line react/prop-types
function PageLayout({ children }) {
  return (
    <Box p={3} pb={20} height="100%">
      {children}
    </Box>
  );
}

PageLayout.prototype = {
  children: PropTypes.node.isRequired
};
