import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentDimensionsInputed extends CRMBaseEvent {
  /**
   * @param {object}
   * @param {number} height in centimeters
   * @param {number} width in centimeters
   * @param {number} length in centimeters
   * @param {number} weight in grams
   */
  constructor({ height, width, length, weight }) {
    super();
    this.eventName = EVENTS.SHIPMENT_DIMENSIONS_INPUTED;
    this.payload = {
      height,
      width,
      length,
      weight: parseFloat(weight / 1000)
    };
  }
}
