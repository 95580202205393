/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseInputField } from './base-input';

const formatValue = (value = '') => {
  return value
    .substring(0, 11)
    .replace(/(\d{11})(\d)/, '$1')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3');
};

const unformatValue = (value = '') => {
  return value.replace(/\D/g, '').substring(0, 11);
};

export function PhoneInput(props) {
  return (
    <BaseInputField
      id={props.id}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      formatValue={formatValue}
      unformatValue={unformatValue}
      type="tel"
      inputMode="tel"
    />
  );
}

PhoneInput.defaultProps = {
  error: null
};

PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};
