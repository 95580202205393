import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/components';
import { blue, root } from 'theme/colors';

export default function Footer({ isLast, onNext, onFinish }) {
  const { t } = useTranslation('ui');
  const label = isLast
    ? t('informationsDeck.footer.continue')
    : t('informationsDeck.footer.next');

  const handleClick = () => {
    if (!isLast) return onNext();
    return onFinish();
  };

  return (
    <Button
      label={label}
      onClick={handleClick}
      textColor={isLast ? root[0] : blue[500]}
      variant={isLast ? 'contained' : 'outlined'}
      fullWidth
      disableShadow
    />
  );
}

Footer.propTypes = {
  isLast: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};
