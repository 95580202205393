import { Box } from '@mui/material';
import { ValidatorBuilder } from 'infra';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepFormWrapper } from 'UI/components';
import { useDomainUseCase, useUserRecords } from 'UI/contexts';
import { useQueryParams } from 'hooks';
import { ACCESS_EMAIL_SIGNUP } from 'UI/pages/sign-up/user/form/user-credentials-form.component';
import CRMUser from 'crm/entities/user/crm-user.model';
import AccountPhoneInformed from 'crm/entities/events/account-phone-informed/account-phone-informed';
import { getFirstName, getLastName } from 'crm/utils';
import { ProgressBar } from '../../../progress-bar/progress-bar.component';

const UserCredentialsForm = ({
  onSubmit,
  handleOnNextStep,
  userEmail,
  userName
}) => {
  const { provider: queryProvider } = useQueryParams();

  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();
  const [activeStep, setActiveStep] = useState(1);
  const [isCnpjStepsEnabled, setIsCnpjStepsEnabled] = useState(null);
  const [sendTheFirstIdentify, setSendTheFirstIdentify] = useState(true);
  const { name: userRecordName } = userRecords;

  const name = userRecordName || userName;
  const email = userRecords?.email || userEmail;
  const provider = queryProvider || ACCESS_EMAIL_SIGNUP;

  if (sendTheFirstIdentify) {
    new CRMUser({
      email,
      firstName: getFirstName(name),
      lastName: getLastName(name),
      registrationMethod: provider
    }).sendToCrm();

    setSendTheFirstIdentify(false);
  }

  const isCnpjStepsEnabledUseCase = useDomainUseCase(
    'isCnpjStepsEnabledUseCase'
  );

  useEffect(() => {
    if (isCnpjStepsEnabled === null) {
      isCnpjStepsEnabledUseCase().then(setIsCnpjStepsEnabled);
    }
  }, [isCnpjStepsEnabled, isCnpjStepsEnabledUseCase]);

  const fields = {
    phone: {
      label: t('signUpCompany.input.tel.label'),
      type: 'phone',
      value: userRecords.phone,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .phoneNumberPattern(t('signUpCompany.input.tel.errorMessage'))
        .build()
    }
  };

  if (!isCnpjStepsEnabled && isCnpjStepsEnabled !== null) {
    fields.cpf = {
      label: t('signUpCompany.input.cpf.label'),
      type: 'cpf',
      value: userRecords.cpf,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .cpfPattern(t('signUpCompany.input.cpf.errorMessage'))
        .build()
    };
  }

  const maxSteps = Object.keys(fields).length;

  const onStepChange = step => {
    setActiveStep(step + 1);
  };

  const handleSubmit = userCredentials => {
    const { phone, cpf } = userCredentials;

    setUserRecords({
      ...userRecords,
      phone,
      cpf,
      email,
      name,
      provider
    });

    new CRMUser({
      email,
      phone
    }).sendToCrm();

    new AccountPhoneInformed({
      name,
      email,
      phone,
      provider
    }).sendToCrm();

    return isCnpjStepsEnabled ? handleOnNextStep() : onSubmit(userCredentials);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <StepFormWrapper
        fields={fields}
        onSubmit={handleSubmit}
        onStepChange={onStepChange}
        buttonLabel={t('signUpCompany.submitButton.label')}
        hasCheckbox={!isCnpjStepsEnabled}
      />
      {!isCnpjStepsEnabled && (
        <Box position="fixed" bottom="0" left="0" width="100%">
          <ProgressBar activeStep={activeStep} maxSteps={maxSteps} />
        </Box>
      )}
    </Box>
  );
};

UserCredentialsForm.defaultProps = {
  userEmail: null,
  userName: null
};

UserCredentialsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleOnNextStep: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  userName: PropTypes.string
};

export default UserCredentialsForm;
