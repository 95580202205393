import translationsOverrides from 'locales/adyen-component-translation.json';
import { blue } from 'theme/colors';

export const adyenConfig = {
  locale: 'pt-BR',
  autoFocus: true,
  environment: process.env.REACT_APP_ADYEN_ENV,
  clientKey: process.env.REACT_APP_ADYEN_COMPONENT_CLIENT_KEY,
  translations: { 'pt-BR': translationsOverrides },
  paymentMethodsConfiguration: {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      enableStoreDetails: true,
      showStoredPaymentMethods: true
    },
    pix: { countdownTime: 30 },
    qrCode: { countdownTime: 30 }
  },
  showPayButton: true,
  ideal: {
    showImage: true
  }
};

const baseStyle = {
  color: blue[500],
  fontSmoothing: 'antialiased',
  fontWeight: 400,
  fontFamily: 'Montserrat, sans-serif'
};

const placeholderStyle = {
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '16px'
};

export const adyenCardCheckoutConfig = {
  brands: ['mc', 'visa', 'amex'],
  ariaLabels: {
    lang: 'pt-BR',
    encryptedCardNumber: {
      label: 'Número do cartão',
      iframeTitle: 'cc'
    },
    encryptedExpiryDate: {
      label: 'Validade',
      iframeTitle: 'expiry-date'
    },
    encryptedSecurityCode: {
      label: 'CVV'
    },
    holderName: {
      label: 'Nome no cartão'
    }
  },
  styles: {
    base: baseStyle,
    error: { color: blue[500] },
    placeholder: placeholderStyle,
    validated: { color: blue[500] }
  }
};
