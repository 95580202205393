import { useMutation } from '@tanstack/react-query';
import ShipmentOrderSummary from 'UI/shipment/models/shipping-order-summary.model';
import shippingApi from 'service/shipping-api';

const PY_BEYOND_URL = `${
  process.env.REACT_APP_PY_BEYOND_URL
}/shipment/v1/shipping/:companyId/`;
const PY_DROPOFF_URL = `${
  process.env.REACT_APP_PY_BEYOND_URL
}/shipment/v1/dropoff-shipment/:companyId/`;

const isDropoff = payload =>
  payload.packages[0].package.freight_type_service ===
  'AMATEUR_SERVICE_TYPE_DROPOFF';

/** @returns {Promise<import('./create-shipment-order.type').ShipmentOrder>} */
const createShipmentOrder = async ({ companyId, payload }) => {
  const url = isDropoff(payload) ? PY_DROPOFF_URL : PY_BEYOND_URL;

  return shippingApi
    .create(url.replace(':companyId', companyId), payload)
    .then(ShipmentOrderSummary.parseApiResponse)
    .then(shippingOrder => {
      const updatedShippingOrder = { ...shippingOrder };
      updatedShippingOrder.price =
        payload.payment_data?.amount ?? payload.indispatch_price;
      return updatedShippingOrder;
    });
};

export default function useCreateShipmentOrder({ onMutate, onError } = {}) {
  return useMutation(createShipmentOrder, { onError, onMutate });
}
