/* eslint-disable camelcase */
import { useUserAllowedOnGroupedShipmentInPickup } from 'hooks/user-allowed-on-grouped-shipment-in-pickup/user-allowed-on-grouped-shipment-in-pickup.hook';
import { useWaitingPickup } from 'hooks/waiting-pickups/waiting-pickup.hook';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks';
import { useCurrentUser } from 'hooks/current-user/use-current-user.hook';
import { Address } from 'models';
import usePlacesAutocomplete from 'use-places-autocomplete';
import useGooglePlacesApi from '@loggi/components/src/one/address-autocomplete/hooks/useGooglePlacesApi';
import { useGroupedShipmentBlocklist } from 'hooks/grouped-shipment-blocklist/grouped-shipment-blocklist';
import { useShipmentDispatcher } from 'UI/shipment/state-machine/context';
import getPickupScheduleCoverage from 'domain/use-cases/get-pickup-schedule-coverage/get-pickup-schedule-coverage.use-case';
import { InputAddress } from 'UI/shipment/models';
import { GroupedShipmentDrawer } from './grouped-shipment-drawer';

const defaultContextValue = {
  isLoading: true,
  isEnabled: false,
  isVisible: false,
  isLoader: false,
  /** @type {number?} */
  chosenPickup: null,
  show: () => {},
  close: () => {},
  confirm: () => {},
  reset: () => {}
};

const GroupedShipmentInPickupContext = React.createContext(defaultContextValue);

const useConvertAddressToPlaces = ({ fullAddress, onResult }) => {
  const [callbackCalled, setCallbackCalled] = useState(false);
  const { autocompleteSessionToken, googleMaps } = useGooglePlacesApi();
  const placesAutocomplete = usePlacesAutocomplete({
    callbackName: 'initGoogleMaps',
    debounce: 400,
    googleMaps,
    requestOptions: {
      sessionToken: autocompleteSessionToken,
      componentRestrictions: { country: 'br' }
    }
  });

  React.useEffect(() => {
    if (!!fullAddress && !placesAutocomplete.value) {
      placesAutocomplete.setValue(fullAddress);
    }
  }, [
    fullAddress,
    placesAutocomplete,
    placesAutocomplete.value,
    placesAutocomplete.setValue
  ]);

  const isPlacesResultReady =
    !!placesAutocomplete.value &&
    placesAutocomplete?.suggestions?.data &&
    placesAutocomplete?.suggestions?.data?.length;

  React.useEffect(() => {
    // only call onResult callback when places is ready and
    // the callback wasn't called before.
    if (isPlacesResultReady && callbackCalled === false) {
      onResult(placesAutocomplete.suggestions.data[0]);
      setCallbackCalled(true);
    }
  }, [
    isPlacesResultReady,
    callbackCalled,
    onResult,
    placesAutocomplete.suggestions.data
  ]);

  const reset = () => {
    setCallbackCalled(false);
    placesAutocomplete.setValue(null);
  };

  return { reset };
};

export const GroupedShipmentInPickupProvider = ({
  children,
  visibility = false
}) => {
  const { t } = useTranslation('ui');
  const [chosenPickup, setChosenPickup] = useState(null);
  const [chosenFullAddress, setChosenFullAddress] = useState(null);
  const [convertedAddress, setConvertedAddress] = useState(false);
  const [isVisible, setIsVisibility] = useState(visibility);
  const [isLoader, setIsLoader] = useState(false);
  const onCloseCallback = React.useRef(null);
  const showSnackbar = useSnackbar();
  const { user } = useCurrentUser();
  const companyId = user?.companyId ?? null;
  const userIsAllowed = useUserAllowedOnGroupedShipmentInPickup();
  const groupedShipmentBlocklist = useGroupedShipmentBlocklist(user?.companyId);
  const waitingPickup = useWaitingPickup(
    groupedShipmentBlocklist.isBlocked ? null : companyId
  );
  const shipmentDispatcher = useShipmentDispatcher();

  const convertAddressControl = useConvertAddressToPlaces({
    fullAddress: chosenFullAddress,
    onResult: async result => {
      if (convertedAddress) return;

      const address = new Address({
        description: waitingPickup.data?.origin_address?.full_address,
        placeId: result.place_id,
        structured: {
          main: result.structured_formatting.main_text,
          secondary: result.structured_formatting.secondary_text
        }
      });

      const addressCoverage = await getPickupScheduleCoverage(
        companyId,
        address
      );

      if (addressCoverage.hasError) {
        showSnackbar({
          message: t(addressCoverage.message),
          severity: 'error'
        });
        return;
      }

      setChosenPickup(Number(waitingPickup.data?.id));
      setConvertedAddress(true);
      setIsLoader(false);

      shipmentDispatcher.selectPickupToReuse({
        pickupOrderScheduleId: waitingPickup.data?.id,
        pickupSchedule: [
          new Date(waitingPickup.data?.start_time),
          new Date(waitingPickup.data?.end_time)
        ],
        pickupAddress: address,
        originAddress: waitingPickup.data.origin_address_v2?.correios_address
          ? InputAddress.fromObject({
              correios: waitingPickup.data.origin_address_v2?.correios_address
            })
          : null,
        pickupCustomer: {
          name: waitingPickup.data?.shipper_name,
          phone: waitingPickup.data?.shipper_phone
        }
      });
    }
  });

  const show = ({ onClose } = {}) => {
    onCloseCallback.current = onClose;
    setIsVisibility(true);
  };

  const close = () => {
    setIsVisibility(false);
    if (onCloseCallback.current) {
      onCloseCallback.current();
    }
  };

  const confirm = React.useCallback(() => {
    setChosenFullAddress(waitingPickup.data.origin_address.full_address);
    setIsLoader(true);
    setIsVisibility(false);
  }, [waitingPickup.data]);

  const reset = React.useCallback(() => {
    setChosenPickup(null);
    setChosenFullAddress(null);
    setConvertedAddress(null);
    convertAddressControl.reset();
    waitingPickup.refetch();
  }, [convertAddressControl, waitingPickup]);

  const isLoading = userIsAllowed.isLoading || waitingPickup.isLoading;
  const isEnabled =
    !groupedShipmentBlocklist.isBlocked &&
    userIsAllowed.isAllowed &&
    !!waitingPickup.data;

  const contextValue = React.useMemo(
    () => ({
      isLoading,
      isEnabled,
      isVisible: isVisible && isEnabled,
      isLoader,
      chosenPickup,
      show,
      close,
      confirm,
      reset
    }),
    [chosenPickup, confirm, isEnabled, isLoader, isLoading, isVisible, reset]
  );

  return (
    <GroupedShipmentInPickupContext.Provider value={contextValue}>
      {children}
      {!!isEnabled && (
        <GroupedShipmentDrawer
          pickupStartTime={new Date(waitingPickup.data.start_time)}
          pickupEndTime={new Date(waitingPickup.data.end_time)}
          fullAddress={waitingPickup.data.origin_address.full_address}
          isVisible={contextValue.isVisible}
          onConfirm={confirm}
          onCancel={close}
        />
      )}
    </GroupedShipmentInPickupContext.Provider>
  );
};

GroupedShipmentInPickupProvider.propTypes = {
  children: PropTypes.node.isRequired,
  visibility: PropTypes.bool
};

GroupedShipmentInPickupProvider.defaultProps = {
  visibility: false
};

export const useGroupedShipment = () => {
  return React.useContext(GroupedShipmentInPickupContext);
};

/**
 * @template {React.ComponentType<any>} T
 * @param {T} Component
 * @returns {T}
 */
export const withGroupedShipment = Component => {
  return props => {
    return (
      <GroupedShipmentInPickupProvider>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </GroupedShipmentInPickupProvider>
    );
  };
};
