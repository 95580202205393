import { PACKAGE_SIZES as OLD_PACKAGE_SIZES } from 'constants/index';
import PACKAGE_SIZES from 'constants/package-size.constants';

export default class PackageDetailsModel {
  constructor({ insuranceValue, lengthCm, widthCm, heightCm, weightG }) {
    this.lengthCm = lengthCm;
    this.widthCm = widthCm;
    this.heightCm = heightCm;
    this.weightG = weightG;
    this.insuranceValue = insuranceValue;
  }

  /**
   * Finds the macking package label for the package dimensions or return null.
   *
   * @example
   * new PackageDetailsModel({ lengthCm: 1, widthCm: 2, heightCm: 3 })
   *    .matchingPackagingLabel() // "P"
   *
   * @returns {"PP"|"P"|"M"|"G"|null}
   */
  matchingPackagingLabel() {
    const pairsOfPackageSizes = Object.entries(PACKAGE_SIZES);
    const packageSize = pairsOfPackageSizes.find(([, size]) => {
      return (
        this.heightCm === size.heightCm &&
        this.lengthCm === size.lengthCm &&
        this.widthCm === size.widthCm
      );
    });
    if (packageSize) {
      return packageSize[0];
    }

    const pairsOfOldPackageSizes = Object.entries(OLD_PACKAGE_SIZES);
    const oldPackageSize = pairsOfOldPackageSizes.find(([, size]) => {
      return (
        this.heightCm === size.heightCm &&
        this.lengthCm === size.lengthCm &&
        this.widthCm === size.widthCm
      );
    });
    if (oldPackageSize) {
      return oldPackageSize[0];
    }

    return null;
  }

  formatDimensions() {
    return `${this.lengthCm}x${this.widthCm}x${this.heightCm}cm`;
  }

  formatWeightToKg() {
    const value = parseFloat(this.weightG / 1000).toFixed(2);
    return `${value}kg`;
  }

  static fromJson(json) {
    return new PackageDetailsModel({
      insuranceValue: parseFloat(json.insuranceValue),
      weightG: json.weightG,
      lengthCm: json.lengthCm,
      widthCm: json.widthCm,
      heightCm: json.heightCm
    });
  }
}
