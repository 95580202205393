import { Box, List, ListItem, Typography } from '@mui/material';
import { BaseDrawer } from 'UI/components';
import useServiceLocationAgencies from 'UI/shipment/hooks/service-location-agencies';
import ServiceLocationModel from 'UI/shipment/models/service-location.model';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { grey, root } from 'theme/colors';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { getGeneralAppStyles } from 'utils';
import LoadingView from './loading-view';

const DIVIDER_STYLE = `1px solid ${grey[50]}`;

/**
 * This component fetches and list the agencies for a given postal code.
 *
 * @param {object} props
 * @param {string} props.postalCode
 */
export default function ServiceLocationAgencies({ postalCode }) {
  const agenciesQuery = useServiceLocationAgencies(postalCode);
  const isMobileDevice = checkIsMobile();
  const { maxListHeight, scrollbarWidth } = getGeneralAppStyles({
    isMobileDevice
  });

  if (agenciesQuery.isLoading) return <LoadingView />;
  if (agenciesQuery.isError)
    return (
      <Box>
        <LoadingView />
        <ErrorView onRetry={agenciesQuery.refetch} />
      </Box>
    );

  return (
    <Box
      borderTop={DIVIDER_STYLE}
      maxHeight={maxListHeight}
      style={{ overflowY: 'auto', scrollbarWidth }}
    >
      <List disablePadding>
        {agenciesQuery.data.map(agency => (
          <ListItem
            key={agency.name}
            disablePadding
            sx={{ borderBottom: DIVIDER_STYLE }}
          >
            <AgencyItem agency={agency} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

ServiceLocationAgencies.propTypes = {
  postalCode: PropTypes.string.isRequired
};

/**
 * @param {object} props
 * @param {ServiceLocationModel} props.agency
 */
function AgencyItem({ agency }) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      gap="16px"
      py="24px"
      color={root[900]}
    >
      <Box display="flex" flexDirection="column" gap="4px">
        <Typography variant="body2" fontWeight="bold">
          {agency.name}
        </Typography>
        <Typography variant="body2" whiteSpace="pre-wrap">
          {agency.formattedAddress()}
        </Typography>
        <Typography variant="body2" color={grey[200]}>
          {agency.formattedWorkingTime()}
        </Typography>
      </Box>

      <Typography variant="body2" fontWeight="medium">
        {agency.formattedDistance()}
      </Typography>
    </Box>
  );
}

AgencyItem.propTypes = {
  agency: PropTypes.instanceOf(ServiceLocationModel).isRequired
};

function ErrorView({ onRetry }) {
  const { t } = useTranslation('ui');
  return (
    <BaseDrawer
      title={t('listAgenciesComponent.error.title')}
      description={t('listAgenciesComponent.error.description')}
      labels={{
        confirm: t('listAgenciesComponent.error.confirm')
      }}
      onConfirmClick={onRetry}
      onDismissClick={onRetry}
      variant="temporary"
      hasPuller
      isOpen
    />
  );
}

ErrorView.propTypes = {
  onRetry: PropTypes.func.isRequired
};
