import waitingPickupsApi from 'service/waiting-pickups-api';

export const getWaitingPickupUseCase = async companyId => {
  const pickups = await waitingPickupsApi.list(companyId);
  if (pickups.length === 0) return null;

  return pickups[0];
};

export default { getWaitingPickupUseCase };
