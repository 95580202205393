/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ShipmentMachineProvider } from 'UI/shipment/state-machine/context';
import createShipmentMachineNavigationProcessor from 'UI/shipment/state-machine/navigation-processor';

/**
 * @template {React.ComponentType<any>} T
 * @param {T} Component
 * @returns {T}
 */
export default function withShipmentMachine(Component) {
  return function EnhancedWithShipmentMachine(props) {
    const history = useHistory();
    const onStateChange = createShipmentMachineNavigationProcessor({ history });

    return (
      <ShipmentMachineProvider onStateChange={onStateChange}>
        <Component {...props} />
      </ShipmentMachineProvider>
    );
  };
}
