import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import {
  formatPhoneNumber,
  getCreationMethod,
  getFirstName,
  getLastName
} from 'crm/utils';

export default class AccountPhoneInformed extends CRMBaseEvent {
  constructor({ name, email, phone, provider }) {
    super();
    this.eventName = EVENTS.ACCOUNT_PHONE_INFORMED;
    this.payload = {
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      phone: formatPhoneNumber(phone),
      registration_method: getCreationMethod(provider)
    };
  }
}
