import { Box, Slide } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, InfiniteLoading } from 'UI/components';
import getStyle from 'UI/pages/utils/utils';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import UserCredentialsForm from './form/user-credentials-form.component';

export const USER_SIGNUP_FORM_ID = 'user-signup-form';
export const USER_SIGNUP_LOADING_ID = 'user-signup-loading';

const SLIDE_TRANSITION_TIMEOUT = 640;

const SignUpUser = ({ onGoBack, onSubmit, hasSignedUp }) => {
  const { t } = useTranslation('ui');
  const isDesktop = !checkIsMobile();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async userCredentials => {
    setLoading(true);
    const result = await onSubmit(userCredentials);
    if (!result) setLoading(false);
  };

  return (
    <Box style={{ ...getStyle(isDesktop), overflowX: 'hidden' }}>
      <Slide
        direction="left"
        in={!loading && !hasSignedUp}
        timeout={{
          appear: SLIDE_TRANSITION_TIMEOUT,
          enter: SLIDE_TRANSITION_TIMEOUT,
          exit: SLIDE_TRANSITION_TIMEOUT / 2
        }}
        unmountOnExit
      >
        <Box height="100vh">
          <Header onGoBack={onGoBack} />
          <UserCredentialsForm
            onSubmit={handleSubmit}
            data-testid={USER_SIGNUP_FORM_ID}
          />
        </Box>
      </Slide>
      <Slide
        direction="left"
        in={loading}
        mountOnEnter
        timeout={SLIDE_TRANSITION_TIMEOUT}
        unmountOnExit
      >
        <Box
          data-testid={USER_SIGNUP_LOADING_ID}
          display="flex"
          height={isDesktop ? '100%' : '100vh'}
          justifyContent="center"
        >
          <InfiniteLoading
            title={t('signUpUser.loadingScreen.title')}
            description={t('signUpUser.loadingScreen.description')}
          />
        </Box>
      </Slide>
    </Box>
  );
};

SignUpUser.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  hasSignedUp: PropTypes.bool.isRequired
};

export default SignUpUser;
