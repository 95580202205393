import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRACKING_STATUS } from 'constants/tracking.constants';
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Typography,
  Box
} from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Icon } from '@loggi/loggi-icons';
import { useHistory } from 'react-router-dom';
import { useShipmentDetailsV2 } from 'hooks/shipment-details-v2/shipment-details-v2.hook';
import {
  getServiceType,
  trackingPath
} from 'UI/shipments-v2/utils/helpers.utils';
import {
  calculatePostingLimitDate,
  formatTimestampToFriendlyDate
} from 'UI/shipments-v2/utils/date.utils';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import { LoadingScreen } from '../loading-screen/loading-screen.component';
import { NoResults } from '../no-results/no-results.component';
import { RequestError } from '../request-error/request-error.component';

const TableHeaders = () => {
  const { t } = useTranslation('ui');
  const tableHeaders = [
    t('myShipmentsV2.tableHeaders.destination'),
    t('myShipmentsV2.tableHeaders.service'),
    t('myShipmentsV2.tableHeaders.packageCode'),
    t('myShipmentsV2.tableHeaders.createdAt'),
    t('myShipmentsV2.tableHeaders.status'),
    ''
  ];

  return (
    <TableHead>
      {tableHeaders.map((header, i) => (
        <TableCell
          variant={i === tableHeaders.length - 1 ? 'iconColumn' : undefined}
          key={header}
        >
          {header}
        </TableCell>
      ))}
    </TableHead>
  );
};

const ShipmentRow = ({
  companyId,
  shipment: {
    loggiKey,
    receiverName,
    status: { label, description, occasionalShipperStatusCode, updateTime },
    isDropoff,
    isIndispatch,
    trackingCode,
    packageCreatedTime,
    destinationAddress,
    correiosTrackCode
  },
  setSelectedShipment
}) => {
  const [startSelection, setStartSelection] = useState(null);
  const { push } = useHistory();
  const { t } = useTranslation('ui');

  const { isEnabled } = useShipmentDetailsV2(companyId);

  const handleMouseUp = event => {
    const endSelection = event.clientX;

    if (startSelection === endSelection) {
      if (isEnabled) {
        setSelectedShipment({ companyId, loggiKey });
      } else {
        push(trackingPath({ companyId, loggiKey }));
      }
    }

    setStartSelection(null);
  };

  const handleMouseDown = event => {
    setStartSelection(event.clientX);
  };

  const renderServiceCell = status => {
    if (
      status === TRACKING_STATUS.canceledByAllocation ||
      !description ||
      description === 'Entrega até ' ||
      description.includes('None')
    ) {
      return (
        <Typography
          sx={{
            textWrap: 'auto'
          }}
          variant="subtitleSmall"
        >
          {t(getServiceType(isIndispatch, isDropoff))}
        </Typography>
      );
    }

    if (
      (isIndispatch() || isDropoff()) &&
      status === TRACKING_STATUS.pickupScheduled
    ) {
      return (
        <>
          <Typography
            sx={{
              textWrap: 'auto'
            }}
            variant="subtitleSmall"
          >
            {t('myShipmentsV2.tablePendingStatus')}{' '}
            {calculatePostingLimitDate(updateTime)}
          </Typography>
          <Typography variant="bodyTextMedium">
            {t(getServiceType(isIndispatch, isDropoff))}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography
          sx={{
            textWrap: 'auto'
          }}
          variant="subtitleSmall"
        >
          {description}
        </Typography>
        <Typography variant="bodyTextMedium">
          {t(getServiceType(isIndispatch, isDropoff))}
        </Typography>
      </>
    );
  };

  return (
    <TableRow onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
      <TableCell>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          variant="subtitleSmall"
        >
          {receiverName}
        </Typography>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          variant="bodyTextMedium"
        >
          {destinationAddress}
        </Typography>
      </TableCell>
      <TableCell>{renderServiceCell(occasionalShipperStatusCode)}</TableCell>
      <TableCell>
        {isIndispatch() && correiosTrackCode ? correiosTrackCode : trackingCode}
      </TableCell>
      <TableCell>{formatTimestampToFriendlyDate(packageCreatedTime)}</TableCell>
      <TableCell>{label}</TableCell>
      <TableCell variant="iconColumn">
        <IconButton size="small">
          <Icon
            name="ArrowRight"
            color={colors.interaction.primary}
            size="small"
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

ShipmentRow.propTypes = {
  companyId: PropTypes.string.isRequired,
  shipment: PropTypes.shape({
    loggiKey: PropTypes.string.isRequired,
    trackingCode: PropTypes.string.isRequired,
    receiverName: PropTypes.string.isRequired,
    status: PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      occasionalShipperStatusCode: PropTypes.string.isRequired,
      updateTime: PropTypes.string.isRequired
    }).isRequired,
    isDropoff: PropTypes.func.isRequired,
    isIndispatch: PropTypes.func.isRequired,
    packageCreatedTime: PropTypes.string.isRequired,
    destinationAddress: PropTypes.string.isRequired,
    correiosTrackCode: PropTypes.string
  }).isRequired,
  setSelectedShipment: PropTypes.func.isRequired
};

export const ShipmentsTable = () => {
  const {
    displayedShipments = [],
    companyId,
    isLoading,
    isError,
    setSelectedShipment
  } = useShipments();

  if (isLoading) return <LoadingScreen />;

  if (isError)
    return (
      <Box display="flex" flexGrow={1} mb={spacing.stack.xxxsmall}>
        <Box display="flex" flexGrow={1} flexDirection="column">
          <Table>
            <TableHeaders />
          </Table>
          <Box
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifyContent="center"
            flexGrow={1}
            mb={spacing.stack.xxxsmall}
          >
            <RequestError />
          </Box>
        </Box>
      </Box>
    );

  if (!displayedShipments.length)
    return (
      <Box display="flex" flexGrow={1} mb={spacing.stack.xxxsmall}>
        <Box display="flex" flexGrow={1} flexDirection="column">
          <Table>
            <TableHeaders />
          </Table>
          <Box
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifyContent="center"
            flexGrow={1}
            mb={spacing.stack.xxxsmall}
          >
            <NoResults />
          </Box>
        </Box>
      </Box>
    );

  if (!displayedShipments.length)
    return (
      <Box display="flex" flexGrow={1} mb={spacing.stack.xxxsmall}>
        <Box display="flex" flexGrow={1} flexDirection="column">
          <Table>
            <TableHeaders />
          </Table>
          <Box
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifyContent="center"
            flexGrow={1}
            mb={spacing.stack.xxxsmall}
          >
            <NoResults />
          </Box>
        </Box>
      </Box>
    );

  return (
    <Box mb={spacing.stack.xxxsmall}>
      <Table>
        <TableHeaders />
        <TableBody>
          {displayedShipments.map(shipment => (
            <ShipmentRow
              key={shipment.loggiKey}
              companyId={companyId}
              shipment={shipment}
              setSelectedShipment={setSelectedShipment}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ShipmentsTable;
