import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useDomainUseCase } from 'UI/contexts';

/**
 * Gets the feature switch and resolves its value
 *
 * @param {string} fs
 * @param {any} defaultValue
 */
export const useFeatureSwitch = (fs, defaultValue = null) => {
  const getFsValue = useDomainUseCase('getFsValueUseCase');

  const query = useQuery(['feature-switch', fs], () => getFsValue(fs), {
    enabled: !!fs,
    initialData: defaultValue,
    onError(err) {
      captureException(err);
    }
  });

  return {
    ...query,
    data: query.data ?? defaultValue
  };
};

export default useFeatureSwitch;
