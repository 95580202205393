import { Box, styled } from '@mui/material';

export const HiddenScrollbarBox = styled(Box)({
  overflow: 'auto',
  scrollbarWidth: 'none',
  scrollBehavior: 'smooth',
  scrollPadding: '24px',
  scrollSnapType: 'x mandatory',
  '&::-webkit-scrollbar': { display: 'none' }
});

export default { HiddenScrollbarBox };
