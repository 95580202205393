import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentRecipientInputed extends CRMBaseEvent {
  constructor({ deliveryCustomer }) {
    super();
    this.eventName = EVENTS.SHIPMENT_RECIPIENT_INPUTED;
    this.payload = {
      recipient_name: deliveryCustomer?.name,
      recipient_phone: deliveryCustomer?.phone,
      recipient_document: deliveryCustomer?.cnpjCpf
    };
  }
}
