export const parseLocalStorage = () => {
  const storageBlacklist = ['CognitoIdentityServiceProvider', '_hjid'];
  const parsedStorage = {};

  Object.entries(localStorage)
    .filter(([key]) => {
      return !storageBlacklist.some(keyText => key.match(keyText));
    })
    .forEach(([key, value]) => {
      let parsedValue;

      try {
        parsedValue = JSON.parse(value);
      } catch {
        parsedValue = value;
      }

      parsedStorage[key] = parsedValue;
    });

  return parsedStorage;
};

export const addLocalStorageToEvent = event => ({
  ...event,
  contexts: {
    ...event.contexts,
    'Local Storage': parseLocalStorage()
  }
});
