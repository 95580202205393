import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { getAboutServiceNameValue } from 'crm/utils';

export default class HomeServiceClicked extends CRMBaseEvent {
  constructor({ serviceName }) {
    super();
    this.eventName = EVENTS.HOME_SERVICE_CLICKED;
    this.payload = {
      shipment_type: getAboutServiceNameValue(serviceName)
    };
  }
}
