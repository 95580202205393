import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { getPackagingValue } from 'crm/utils';

export default class ShipmentPackagingInputed extends CRMBaseEvent {
  /**
   * @param {object}
   * @param {string} option from
   */
  constructor({ packagingService }) {
    super();
    this.eventName = EVENTS.SHIPMENT_PACKAGING_INPUTED;
    this.payload = {
      packaging: getPackagingValue(packagingService)
    };
  }
}
