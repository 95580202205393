/* eslint-disable class-methods-use-this */
import * as rudderanalytics from 'rudder-sdk-js';
import CRMBaseAdapter from 'crm/adapters/crm-base-adapter';
import { RUDDERSTACK_EVENTS } from 'crm/constants';

/**
 * CRM Adapter for RudderStack.
 *
 * This class extends from CRMBaseAdapter and implements the sendUser and sendEvent methods
 * for the RudderStack CRM platform.
 *
 * @extends {CRMBaseAdapter}
 */
export default class CRMAdapterRudder extends CRMBaseAdapter {
  get events() {
    return RUDDERSTACK_EVENTS;
  }

  sendUser(userPayload) {
    if (!userPayload?.email) return;
    const identifier = userPayload.email;
    rudderanalytics.identify(identifier, userPayload);
  }

  sendEvent(eventName, eventPayload) {
    rudderanalytics.track(eventName, eventPayload);
  }
}
