import { IconButton as Button, styled } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from 'UI/components';
import {
  dimensions,
  getDisabledStyle,
  getStylesByVariant
} from './icon-button.helper';

const CustomMuiButton = styled(Button)({
  '& *': { pointerEvents: 'none' }
});

export const IconButton = ({
  borderColor,
  disabled,
  icon,
  id,
  onClick,
  size,
  variant
}) => {
  const dimension = get(dimensions, size, dimensions.small);
  const variantStyle = getStylesByVariant(variant, borderColor);
  const disabledStyle = getDisabledStyle(disabled, variant);
  const { color: iconColor } = disabled ? disabledStyle : variantStyle;

  return (
    <CustomMuiButton
      disabled={disabled}
      id={id}
      data-testid={id}
      onClick={onClick}
      size={size}
      style={{
        ...dimension,
        ...variantStyle,
        ...disabledStyle,
        borderRadius: '50%',
        padding: '0'
      }}
    >
      <SvgIcon color={iconColor} icon={icon} size={dimension.iconSize} />
    </CustomMuiButton>
  );
};
IconButton.defaultProps = {
  borderColor: '',
  disabled: false,
  id: '',
  size: 'small',
  variant: 'contained'
};

IconButton.propTypes = {
  borderColor: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['contained', 'outlined'])
};

export default { IconButton };
