import { loggiWebApi } from '@loggi/authentication-lib';

const nonWorkingDaysApi = {
  list({ address, endDate, nonWorkingDaysType, startDate }) {
    const url = '/beyond/api/shipment/v1/non-working-days/';
    const payload = {
      address: { ...address },
      end_date: endDate,
      non_working_day_type: nonWorkingDaysType,
      start_date: startDate
    };
    return loggiWebApi
      .url(url)
      .post(payload)
      .json();
  }
};

export default nonWorkingDaysApi;
