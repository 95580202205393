import { useEffect } from 'react';
import { SHIPMENT_FILTERS_SESSION_STORAGE_KEY } from '../constants/shipments.constants';

const useSaveSessionStorageFilters = (
  statusFilterValue,
  sortValue,
  groupingValue,
  searchValue,
  page,
  isFilterAutoSelected,
  selectedShipment
) => {
  useEffect(() => {
    const fieldValues = {
      statusFilterValue,
      sortValue,
      groupingValue,
      searchValue,
      page,
      isFilterAutoSelected,
      selectedShipment
    };

    sessionStorage.setItem(
      SHIPMENT_FILTERS_SESSION_STORAGE_KEY,
      JSON.stringify(fieldValues)
    );
  }, [
    groupingValue,
    isFilterAutoSelected,
    page,
    searchValue,
    sortValue,
    statusFilterValue,
    selectedShipment
  ]);
};

export default useSaveSessionStorageFilters;
