import React from 'react';

const KEYBOARD_EVENTS = { blur: 'BLUR', focus: 'FOCUS' };
const HIDDEN_BY_EVENT = {
  [KEYBOARD_EVENTS.blur]: false,
  [KEYBOARD_EVENTS.focus]: true
};

export default function AutohideOnInputFocus({ children }) {
  const [isHidden, setHidden] = React.useState(false);

  React.useEffect(() => {
    const listener = type => ({ target }) => {
      if (!target.matches('input')) return;
      setHidden(HIDDEN_BY_EVENT[type]);
    };
    const onBlur = listener(KEYBOARD_EVENTS.blur);
    const onFocus = listener(KEYBOARD_EVENTS.focus);
    document.addEventListener('focusin', onFocus);
    document.addEventListener('focusout', onBlur);
    return () => {
      document.removeEventListener('focusin', onFocus);
      document.removeEventListener('focusout', onBlur);
    };
  });

  if (isHidden) return null;
  return children;
}
