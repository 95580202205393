import React from 'react';
import PropTypes from 'prop-types';

import { Stack, IconButton } from '@mui/material';
import { Icon } from '@loggi/loggi-icons';
import { colors, spacing } from '@loggi/front-design-tokens';

export default function CloseHeader({ onClick }) {
  return (
    <Stack pt={spacing.stack.medium}>
      <Stack
        direction="row"
        px={spacing.inset.xxsmall}
        mt={spacing.stack.nano}
        pb={spacing.inset.medium}
        justifyContent="flex-end"
      >
        <IconButton
          data-testid="close-button"
          variant="blank"
          size="medium"
          color="secondary"
          onClick={onClick}
        >
          <Icon
            name="ActionClose"
            color={colors.interaction['on-secondary']}
            size="medium"
          />
        </IconButton>
      </Stack>
    </Stack>
  );
}

CloseHeader.propTypes = {
  onClick: PropTypes.func.isRequired
};
