export class PaymentMethod {
  constructor({ data, isValid }) {
    const { paymentMethod } = data;
    this.type = 'credit_card';
    this.cardHolderName = paymentMethod.holderName;
    this.cardNumber = paymentMethod.encryptedCardNumber;
    this.expiryMonth = paymentMethod.encryptedExpiryMonth;
    this.expiryYear = paymentMethod.encryptedExpiryYear;
    this.securityCode = paymentMethod.encryptedSecurityCode;
    this._isValid = isValid;
  }

  get isValid() {
    return this._isValid;
  }
}

export default PaymentMethod;
