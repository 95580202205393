import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { getCouponsUseCase } from 'domain/use-cases/get-coupons/get-coupons.use-case';
import { useShipmentContext } from 'UI/shipment/state-machine/context';

/**
 * Fetches the list of coupons available.
 */
export default function useCoupons() {
  const { context } = useShipmentContext();
  const { companyId, serviceType } = context;

  return useQuery(
    ['coupons', String(companyId)],
    () => getCouponsUseCase(companyId, serviceType),
    {
      enabled: !!companyId,
      onError(err) {
        captureException(err);
      }
    }
  );
}
