import { Slides } from 'UI/shipment/components/informations-deck';
import { useShipmentDispatcher } from 'UI/shipment/state-machine/context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Slide01, Slide02, Slide03 } from './slides';

export default function AboutPackagingPage() {
  const { t } = useTranslation('ui');
  const shipmentDispatcher = useShipmentDispatcher();

  return (
    <Slides
      title={t('aboutPackaging.title')}
      onContinue={shipmentDispatcher.back}
      onClose={shipmentDispatcher.back}
    >
      <Slide01 />
      <Slide02 />
      <Slide03 />
    </Slides>
  );
}
