import { styled, Tab, Tabs as MuiTabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { blue, grey, root } from 'theme/colors';

const StyledTabs = styled(MuiTabs)({
  height: 56,
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    display: 'flex',
    height: 4,
    justifyContent: 'center'
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: blue[500],
    borderRadius: 40,
    maxWidth: 56,
    width: '100%'
  }
});

const StyledTab = styled(Tab)({
  color: grey[500],
  fontSize: '16px',
  fontWeight: '400',
  padding: '16px 24px 12px',
  '&.Mui-disabled': {
    color: grey[200]
  },
  '&.Mui-selected': {
    color: root[900],
    fontWeight: '700'
  }
});

const StyledTabPanel = styled(TabPanel)({
  padding: '32px 24px'
});

const Tabs = ({ initialTab, tabs }) => {
  const [tab, setTab] = useState(initialTab);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <TabContext value={tab}>
      <StyledTabs
        onChange={handleChange}
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />
        }}
        value={tab}
      >
        {tabs.map(({ label, value, disabled }) => (
          <StyledTab
            disabled={disabled}
            disableRipple
            key={value}
            label={label}
            value={value}
          />
        ))}
      </StyledTabs>
      {tabs.map(({ component, value }) => (
        <StyledTabPanel key={value} value={value}>
          {component}
        </StyledTabPanel>
      ))}
    </TabContext>
  );
};

Tabs.propTypes = {
  initialTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};

export default Tabs;
