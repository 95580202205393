import { spacing } from '@loggi/front-design-tokens';
import { Box, ThemeProvider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { InfiniteLoading } from 'UI/components';

export const LoadingScreen = () => {
  const { t } = useTranslation('ui');

  return (
    <Box mt={spacing.stack.xxxlarge}>
      <ThemeProvider theme={theme}>
        <InfiniteLoading title={t('myShipmentsV2.lookingForPackagesMessage')} />
      </ThemeProvider>
    </Box>
  );
};

export default LoadingScreen;
