import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdyenCheckout from '@adyen/adyen-web';
import {
  adyenCardCheckoutConfig,
  adyenConfig
} from 'configuration/adyen-component-config';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';

import useWalletPaymentSession from 'hooks/wallet-payment-session/wallet-payment-session.hook';
import { InfiniteLoading } from 'UI/components';
import { ON_PAYMENT_COMPLETED_STATUS_CODE } from 'UI/shipment/pages/payment/constants';
import startPixPayment, { calcCoutDownTime } from './adyen-pix-payment-helper';
import './adyen-pix-payment.css';

export default function AdyenPixPayment({
  amount,
  companyId,
  handlePaymentCompleted,
  onError
}) {
  const { t } = useTranslation('ui');

  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  const paymentSessionQuery = useWalletPaymentSession({
    companyId,
    amount
  });

  const isFetchingPaymentSession = paymentSessionQuery.isFetching;

  const initAdyenCheckout = useCallback(async () => {
    const paymentSession = paymentSessionQuery.data;

    const hasPixPaymentSession = JSON.parse(
      window.localStorage.getItem('pix_payment_session')
    );

    const onPaymentCompleted = res => {
      if (res.resultCode === ON_PAYMENT_COMPLETED_STATUS_CODE.AUTHORISED) {
        const rechargeId =
          hasPixPaymentSession?.paymentSession?.rechargeId ??
          paymentSession?.rechargeId;
        window.localStorage.removeItem('pix_payment_session');
        handlePaymentCompleted(rechargeId);
      }
    };

    if (!hasPixPaymentSession) {
      const initPaymentSessionTime = new Date().getTime();
      window.localStorage.setItem(
        'pix_payment_session',
        JSON.stringify({ paymentSession, initPaymentSessionTime })
      );
    }

    if (hasPixPaymentSession) {
      const countdownTime = calcCoutDownTime(
        hasPixPaymentSession.initPaymentSessionTime
      );
      Object.assign(adyenConfig.paymentMethodsConfiguration, {
        pix: { countdownTime },
        qrCode: { countdownTime }
      });
    }

    const session = hasPixPaymentSession?.paymentSession ?? paymentSession;
    const checkout = await AdyenCheckout({
      ...adyenConfig,
      session,
      onPaymentCompleted,
      onError
    });

    checkout
      .create('pix', {
        ...adyenCardCheckoutConfig
      })
      .mount(`#pix-container`);
    setIsMounted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSessionQuery.data, amount]);

  useEffect(() => {
    if (!isFetchingPaymentSession && !isMounted) {
      initAdyenCheckout();
    }
  }, [isFetchingPaymentSession, isMounted, initAdyenCheckout]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const handleStartPix = () => {
        setIsLoading(false);
        clearInterval(intervalId);
      };
      startPixPayment(handleStartPix);
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && (
        <Box pt={spacing.stack.large}>
          <InfiniteLoading
            title={t('pixPayment.generatingCode')}
            titleVariant="subtitleMedium"
          />
        </Box>
      )}
      <Box
        id="pix-container"
        width="100%"
        display={isLoading ? 'none' : 'block'}
      />
    </>
  );
}

AdyenPixPayment.propTypes = {
  amount: PropTypes.number.isRequired,
  companyId: PropTypes.string.isRequired,
  handlePaymentCompleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};
