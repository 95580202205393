/**
   * Removes the undefined values from an object recursively
   * @example
   * // Input
   * {
      a: 1,
      b: undefined,
      c: {
        d: 2,
        e: undefined
      },
      f: [3, undefined, 4],
      g: [undefined, undefined]
    }
   * // Output
   * {
      a: 1,
      c: {
        d: 2
      },
      f: [3, 4],
      g: []
    }
   */
export const removeUndefinedValues = object => {
  const deepClean = obj => {
    if (typeof obj !== 'object' || obj === null) return obj;
    if (Array.isArray(obj))
      return obj.map(deepClean).filter(item => item !== undefined);

    return Object.entries(obj).reduce((acc, [key, value]) => {
      const cleanedValue = deepClean(value);
      if (cleanedValue !== undefined) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
  };

  return deepClean(object);
};

export default { removeUndefinedValues };
