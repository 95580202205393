import { captureException } from '@sentry/react';
import { PACKAGE_SIZES } from 'constants/index';
import NEW_PACKAGE_SIZES from 'constants/package-size.constants';
import { isEmpty, isNil, omit, omitBy } from 'lodash';
import { Address, AmateurQuoting, ShippingPayload } from 'models';
import {
  NO_PACKAGING,
  SIMPLE_PACKAGING
} from '../constants/packaging-service-type';
import { InputAddress } from '../models';
import { AUTORECOVER_FIELDS } from './constants';

/** @typedef {import('./types').MachineContext} MachineContext */
/**
 * @template T
 * @typedef {import('utils/types').ClassProperties<T>} ClassProperties
 */

/**
 * @param {string} fs
 * @param {string|number} companyId
 */
export function isFsEnabledForCompany(fs, companyId) {
  if (fs === '*') return true;
  return (fs ?? []).includes(parseInt(companyId, 10));
}

export const redirectToAddressState = (
  addressState,
  action,
  condFn = () => true
) => ({
  cond: condFn,
  target: addressState,
  actions: action
});

export function isIndespacho(serviceType) {
  return (
    serviceType === 'AMATEUR_SERVICE_TYPE_CORREIOS_PLP_PAC' ||
    serviceType === 'AMATEUR_SERVICE_TYPE_CORREIOS_PLP_SEDEX'
  );
}

export function isLoggiFacil(serviceType) {
  return serviceType === 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC';
}

/**
 * @param {MachineContext} ctx
 */
export function isGroupingShipmentInPickup(ctx) {
  return !!ctx.pickupOrderScheduleId;
}

/**
 * @param {MachineContext} context
 * @returns {boolean}
 */
export function isUsingInputAddress(ctx) {
  return !!ctx.originAddress && !!ctx.destinationAddress;
}

/**
 * @param {MachineContext} ctx
 * @returns {boolean}
 */
export const isSuccessPageV2Enabled = ctx =>
  ctx.featureSwitches.enable_success_page_v2 === '*' ||
  ctx.featureSwitches.enable_success_page_v2?.includes(
    parseInt(ctx.companyId, 10)
  ) === true;

/**
 * Verifies if either the legacy pickup and delivery or
 * the newer origin and destination addresses are present
 *
 * @param {MachineContext} ctx
 */
export function areAddressesPresent(ctx) {
  return (
    (!!ctx.pickupAddress && !!ctx.deliveryAddress) ||
    (!!ctx.originAddress && !!ctx.destinationAddress)
  );
}

export function machineContextToCorpPayload(context) {
  return {
    pickupForm: {
      shipper: {
        name: context.pickupCustomer.name,
        phone: context.pickupCustomer.phone,
        address: context.pickupAddress
      }
    },
    deconForm: {
      packageSize: context.packageSize,
      packageSizeDimensions: PACKAGE_SIZES[context.packageSize],
      totalValue: 0
    },
    recipientForm: {
      cnpjCpf: '',
      name: context.deliveryCustomer.name,
      phone: context.deliveryCustomer.phone,
      address: context.deliveryAddress
    },
    pickup: {
      address: context.pickupAddress
    },
    delivery: context.deliveryAddress,
    packageSize: context.packageSize,
    budget: context.estimate,
    pickupCustomer: {
      name: context.pickupCustomer.name,
      phone: context.pickupCustomer.phone
    },
    deliveryCustomer: {
      cnpjCpf: '',
      name: context.deliveryCustomer.name,
      phone: context.deliveryCustomer.phone
    }
  };
}

export function getPackageSizeDimensions(context) {
  if (context.packagingService === NO_PACKAGING)
    return {
      ...context.packageDimensions,
      weight: context.packageDimensions.weightG
    };
  if (context.packagingService === SIMPLE_PACKAGING)
    return {
      ...NEW_PACKAGE_SIZES[context.packageSize],
      weight: NEW_PACKAGE_SIZES[context.packageSize].weightG
    };

  return PACKAGE_SIZES[context.packageSize];
}

/**
 * if we have a credit card number, then ignore the stored document id
 * from previous payments
 */
function getPaymentDocumentId(context) {
  return !context.payment?.cardNumber && context.payment?.documentId;
}

function getPaymentMethod(context) {
  if (!context.payment) return null;

  return {
    cardHolderName: context.payment.cardHolderName,
    cardNumber: context.payment.cardNumber,
    expiryMonth: context.payment.expiryMonth,
    expiryYear: context.payment.expiryYear,
    securityCode: context.payment.securityCode,
    type: context.payment.type
  };
}

/**
 * Builds a machineContext with fields from PERSISTENCE_FIELDS, which are not at AUTORECOVER_FIELDS
 * @param {Partial<ClassProperties<MachineContext>>} storedContext
 * @returns null if empty
 */
export function buildPendingContext(storedContext) {
  if (!storedContext) return null;

  const persistedFieldsContext = {
    destinationAddress: InputAddress.fromObject(
      storedContext.destinationAddress
    ),
    deliveryAddress: Address.fromObject(storedContext.deliveryAddress),
    pickupCustomer: storedContext.pickupCustomer || null,
    deliveryCustomer: storedContext.deliveryCustomer || null,
    pickupSchedule: storedContext.pickupSchedule
      ? [
          new Date(storedContext.pickupSchedule[0]),
          new Date(storedContext.pickupSchedule[1])
        ]
      : null,
    packageSize: storedContext.packageSize || null,
    serviceType: storedContext.serviceType || null,
    insurance: storedContext.insurance || null,
    packageDimensions: storedContext.packageDimensions || null,
    packagingService: storedContext.packagingService || null,
    rechargeValue: storedContext.rechargeValue || null,
    rechargeFrom: storedContext.rechargeFrom || null,
    paymentMethod: storedContext.paymentMethod || null,
    addBalanceFrom: storedContext.addBalanceFrom || null
  };

  const nonEmptyFieldsContext = omitBy(persistedFieldsContext, isNil);
  const pendingContext = omit(nonEmptyFieldsContext, AUTORECOVER_FIELDS);

  if (isEmpty(pendingContext)) return null;
  return pendingContext;
}

/**
 * Builds a machineContext with fields from AUTORECOVER_FIELDS
 * @param {Partial<ClassProperties<MachineContext>>} storedContext
 */
export function buildAutoRecoveredContext(storedContext) {
  // TODO: remove this safe check when we don't have both fields on the code anymore
  if (storedContext?.originAddress && storedContext?.pickupAddress) {
    captureException(
      new Error('originAddress and pickupAddress should not be both filled')
    );
    return { originAddress: storedContext?.originAddress };
  }

  return {
    originAddress: InputAddress.fromObject(storedContext?.originAddress),
    pickupAddress: Address.fromObject(storedContext?.pickupAddress)
  };
}

/**
 * @typedef {import('models').AmateurQuoting} AmateurQuoting
 * @typedef {import('UI/shipment/models/validated-coupon.model').default} ValidatedCoupon
 * @param {import('models').MachineContext & { estimate: AmateurQuoting, coupon: ValidatedCoupon } } context
 */
export function machineContextToShipmentPayload(context) {
  const isIndespachoShipment = isIndespacho(context.serviceType);
  const paymentDocumentId = getPaymentDocumentId(context);
  const paymentMethod = getPaymentMethod(context);
  const quotation = context.coupon
    ? {
        fullPrice: context.coupon.totalAmount,
        price: context.coupon.amountWithAppliedDiscount
      }
    : {
        fullPrice: context.estimate?.price,
        price: context.estimate?.price
      };

  const payload = new ShippingPayload({
    companyId: String(context.companyId),
    couponCode: context.coupon?.couponCode,
    decon: {
      totalValue: context.insurance || 0,
      packageDescription: 'Item Pessoal',
      packageQuantity: '1',
      packageSizeDimensions: getPackageSizeDimensions(context)
    },
    insurance: context.insurance,
    estimate: context.estimate
      ? {
          price: context.estimate.price,
          serviceType: context.estimate.serviceType
        }
      : {
          price: 0,
          serviceType: 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC'
        },
    invoice: {
      estimate: {
        freightType: 0
      }
    },
    paymentDocumentId,
    paymentMethod,
    pickup: {
      instructions: '',
      schedulingTime: {
        startTime:
          context.pickupSchedule && context.pickupSchedule[0].toISOString(),
        endTime:
          context.pickupSchedule && context.pickupSchedule[1].toISOString()
      },
      shipper: {
        name: context.pickupCustomer.name || '',
        phone: context.pickupCustomer.phone || '',
        email: context.pickupCustomer?.email || '',
        address: {
          complement: context.pickupAddress.complement || '',
          description: context.pickupAddress.description,
          placeId: context.pickupAddress.placeId
        }
      }
    },
    quotation,
    recipient: {
      name: context.deliveryCustomer.name || '',
      phone: context.deliveryCustomer.phone || '',
      cnpjCpf: context.deliveryCustomer.cnpjCpf || '',
      address: {
        complement: context.deliveryAddress.complement || '',
        description: context.deliveryAddress.description,
        placeId: context.deliveryAddress.placeId
      }
    },
    pickupOrderScheduleId: context.pickupOrderScheduleId,
    allObjectsArePackaged: isIndespachoShipment,
    packagingService: context.packagingService
  });

  if (!isIndespachoShipment)
    Object.assign(payload, { indispatch_price: '0.00' });

  return payload;
}

/**
 * @typedef {import('models').AmateurQuoting} AmateurQuoting
 * @typedef {import('UI/shipment/models/validated-coupon.model').default} ValidatedCoupon
 * @typedef {import('UI/shipment/state-machine/types.d').MachineContext} MachineContext
 * @typedef {{
 *   cardHolderName: string,
 *   cardNumber: string,
 *   expiryMonth: string,
 *   expiryYear: string,
 *   securityCode: string,
 *   type: 'credit_card'
 * }} PaymentData
 */
export class ShippingPayloadBuilder {
  constructor() {
    /**
     * @type {MachineContext}
     * @private
     */
    this.context = null;

    /**
     * @type {AmateurQuoting}
     * @private
     */
    this.quoting = null;

    /**
     * @type {ValidatedCoupon | null}
     * @private
     */
    this.coupon = null;

    /**
     * @type {PaymentData | null}
     * @private
     */
    this.payment = null;
  }

  /**
   * @param {MachineContext} context
   */
  setShipmentContext(context) {
    this.context = context;

    return this;
  }

  /**
   * @param {AmateurQuoting} context
   */
  setQuoting(quoting) {
    this.quoting = quoting;

    return this;
  }

  /**
   * @param {ValidatedCoupon} context
   */
  setCoupon(coupon) {
    this.coupon = coupon;

    return this;
  }

  /**
   * @param {PaymentData} payment
   */
  setPayment(payment) {
    this.payment = payment;

    return this;
  }

  build() {
    return Object.freeze({
      packages: this.buildPackages(),
      pickup_order_schedule: this.buildPickupOrderSchedule(),
      pickup_order_schedule_id: this.context.pickupOrderScheduleId || undefined,
      indispatch_price: this.quoting.price.toFixed(2),
      payment_data: this.buildPaymentData(),
      coupon: this.buildCoupon()
    });
  }

  /**
   * @private
   */
  buildPackages() {
    const packageDimensions = getPackageSizeDimensions(this.context);

    return [
      {
        package: {
          freight_type_service: this.context.serviceType,
          height_cm: packageDimensions.heightCm,
          length_cm: packageDimensions.lengthCm,
          weight_g: packageDimensions.weight,
          width_cm: packageDimensions.widthCm,
          total_value: this.context.insurance || 0,
          // This field should be fixed on zero.
          slo: 0,
          packaging_service_type: this.context.packagingService,
          content_declaration: {
            items: [
              {
                // These fields should be fixed
                description: 'Item Pessoal',
                quantity: '1',

                unitary_value: this.context.insurance || 0,
                unitary_weight_g: packageDimensions.weightG
              }
            ]
          }
        },
        recipient: {
          address: this.context.destinationAddress.toJSON(),
          cpf_cnpj:
            this.context.deliveryCustomer.cnpjCpf?.replace(/\D/g, '') || '',
          name: this.context.deliveryCustomer.name,
          phone_number: this.context.deliveryCustomer.phone
        }
      }
    ];
  }

  /**
   * @private
   */
  buildPickupOrderSchedule() {
    return {
      all_objects_are_packaged:
        AmateurQuoting.isIndespacho(this.context) ||
        AmateurQuoting.isDropoff(this.context.serviceType),
      start_time: this.context.pickupSchedule?.[0].toISOString() ?? '',
      end_time: this.context.pickupSchedule?.[1].toISOString() ?? '',
      origin_address: this.context.originAddress.toJSON(),
      // This field is static
      shipper_instructions: '',
      shipper_name: this.context.pickupCustomer.name,
      shipper_phone: this.context.pickupCustomer.phone,
      shipper_email: this.context.pickupCustomer.email || ''
    };
  }

  /**
   * @private
   */
  buildPaymentData() {
    if (this.payment?.paymentDocumentId && !this.payment?.cardNumber) {
      return {
        payment_method: {
          payment_document_id: this.payment.paymentDocumentId
        },
        amount: this.buildAmount()
      };
    }

    if (this.payment) {
      return {
        payment_method: {
          card_holder_name: this.payment.cardHolderName,
          encrypted_card_number: this.payment.cardNumber,
          encrypted_expiry_month: this.payment.expiryMonth,
          encrypted_expiry_year: this.payment.expiryYear,
          encrypted_security_code: this.payment.securityCode,
          type: this.payment.type
        },
        amount: this.buildAmount()
      };
    }

    return undefined;
  }

  /**
   * @private
   */
  buildAmount() {
    if (this.coupon) return this.coupon.amountWithAppliedDiscount.toFixed(2);

    return parseFloat(this.quoting.price).toFixed(2);
  }

  /**
   * @private
   */
  buildCoupon() {
    if (this.coupon) {
      return {
        company_id: String(this.context.companyId),
        coupon_code: this.coupon.couponCode,
        total_amount: parseFloat(this.coupon.totalAmount).toFixed(2),
        amount_with_applied_discount: parseFloat(
          this.coupon.amountWithAppliedDiscount
        ).toFixed(2)
      };
    }

    return undefined;
  }
}

export const setBalanceSuccessullyAddedFromApi = {
  addedBalance: (_, ev) => ev.payload.addedBalance,
  rechargeId: (_, ev) => ev.payload.rechargeId,
  paymentDate: (_, ev) => ev.payload.paymentDate,
  currentBalance: (_, ev) => ev.payload.currentBalance,
  paymentMethod: (_, ev) => ev.payload.paymentMethod
};

export const setBalanceSuccessullyAdded = {
  addedBalance: (_, ev) => ev.payload.addedBalance,
  rechargeId: (_, ev) => ev.payload.rechargeId,
  paymentDate: new Date()
};
