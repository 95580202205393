import occasionalTrackingApi from 'service/occasional-tracking-api';
import { OccasionalTrackingInformation } from 'models/occasional-tracking-information.model';

export const fetchOccasionalTrackingDetailsUseCase = async (
  companyId,
  loggiKey
) => {
  return occasionalTrackingApi
    .details(companyId, loggiKey)
    .then(OccasionalTrackingInformation.parseApiResponse);
};

export default { fetchOccasionalTrackingDetailsUseCase };
