import { isEmpty } from 'lodash';
import {
  PICKUP_TYPE,
  TRACKING_STATUS,
  PACKAGE_SLO
} from 'constants/tracking.constants';
import { OccasionalTrackingStatus } from './occasional-tracking-status.model';
import PackageDetailsModel from './package-details.model';

export class OccasionalTrackingInformation {
  constructor({
    loggiKey,
    trackingCode,
    status,
    receiverName,
    trackingHistory,
    pickupAddress,
    destinationAddress,
    paymentDetails,
    packageDetails,
    packagePickupTraits,
    senderName,
    shipmentCanBeCanceled,
    billingInvoice,
    senderPostalCode,
    packageSlo,
    senderPhone,
    correiosTrackCode,
    receiverPhone,
    promisedDate,
    walletInformation
  }) {
    this.loggiKey = loggiKey;
    this.trackingCode = trackingCode;
    this.status = status;
    this.receiverName = receiverName;
    this.trackingHistory = trackingHistory;
    this.pickupAddress = pickupAddress;
    this.destinationAddress = destinationAddress;
    this.paymentDetails = paymentDetails;
    this.insuranceValue = packageDetails.insuranceValue;
    this.packageDetails = packageDetails;
    this.packagePickupTraits = packagePickupTraits;
    this.senderName = senderName;
    this.shipmentCanBeCanceled = shipmentCanBeCanceled;
    this.billingInvoice = billingInvoice;
    this.senderPostalCode = senderPostalCode;
    this.packageSlo = packageSlo;
    this.senderPhone = senderPhone;
    this.correiosTrackCode = correiosTrackCode;
    this.receiverPhone = receiverPhone;
    this.promisedDate = promisedDate;
    this.walletInformation = walletInformation;

    this.isDropoff = this.isDropoff.bind(this);
    this.isIndispatch = this.isIndispatch.bind(this);
  }

  static parseApiResponse(response) {
    if (Object.keys(response.package).length === 0) return null;

    const packageResponse = response.package;

    return new OccasionalTrackingInformation({
      loggiKey: packageResponse.loggiKey,
      trackingCode: packageResponse.trackingCode,
      status: OccasionalTrackingStatus.parseApiResponse(packageResponse.status),
      receiverName: packageResponse.receiverName,
      packageDetails: PackageDetailsModel.fromJson(
        packageResponse.packageDetails
      ),
      packagePickupTraits: packageResponse.packagePickupTraits,
      trackingHistory: Array.from(packageResponse.trackingHistory, item =>
        OccasionalTrackingStatus.parseApiResponse(item.status)
      ),
      pickupAddress: packageResponse.pickupAddress,
      destinationAddress: packageResponse.destinationAddress,
      paymentDetails: OccasionalTrackingInformation.parsePaymentDetails(
        packageResponse.paymentDetails
      ),
      senderName: packageResponse.senderName,
      shipmentCanBeCanceled: packageResponse.shipmentCanBeCanceled,
      billingInvoice: packageResponse.billingInvoice,
      senderPostalCode: packageResponse.senderPostalCode,
      packageSlo: packageResponse.packageSlo,
      senderPhone: packageResponse.senderPhone,
      correiosTrackCode: packageResponse.correiosTrackCode,
      receiverPhone: packageResponse.receiverPhone,
      promisedDate: packageResponse.promisedDate,
      walletInformation: OccasionalTrackingInformation.parseWalletInformation(
        packageResponse.walletInformation
      )
    });
  }

  static parseWalletInformation(walletInformation) {
    if (!walletInformation) return null;

    return {
      loggiKey: walletInformation.loggiKey,
      totalAmount: walletInformation.totalAmount
        ? parseFloat(walletInformation.totalAmount)
        : null,
      amount: walletInformation.amount
        ? parseFloat(walletInformation.amount)
        : null,
      couponCode: walletInformation.couponCode
    };
  }

  static parsePaymentDetails(paymentDetails) {
    if (!paymentDetails) return null;
    if (isEmpty(paymentDetails.paymentDocument)) return null;
    return {
      amount: paymentDetails.amount ? parseFloat(paymentDetails.amount) : null,
      status: paymentDetails.status || null,
      paymentDocument: {
        creditCard: {
          lastDigits:
            paymentDetails.paymentDocument?.creditCard.lastDigits || null,
          cardBrand:
            paymentDetails.paymentDocument?.creditCard.cardBrand || null
        }
      }
    };
  }

  get pickupDateTime() {
    return (
      this.trackingHistory.find(
        status =>
          status.occasionalShipperStatusCode === TRACKING_STATUS.processing
      )?.updateTime ?? null
    );
  }

  /**
   * Checks if package is drop off type
   * @returns boolean
   */
  isDropoff() {
    return this?.packagePickupTraits?.pickupType === PICKUP_TYPE.dropOff;
  }

  /**
   * Checks if package is indispatch type
   * @returns boolean
   */
  isIndispatch() {
    return this?.packageSlo === PACKAGE_SLO.INDISPATCH;
  }
}

export default OccasionalTrackingInformation;
