import * as Sentry from '@sentry/react';
import { Link } from '@material-ui/core';
import { Box } from '@mui/material';
import { useSnackbar } from 'hooks';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  AWS_COGNITO_IMPERSONATION_ERRORS as AUTH_ERROR,
  useAmplifyAuth,
  USER_NOT_AUTHENTICATED_ERROR
} from '@loggi/authentication-lib';
import { COGNITO_ERROR_MESSAGES } from 'constants/error-messages';
import { FORGOT_PASSWORD_EMAIL_KEY } from 'constants/index';
import {
  companyIdGuard,
  forgotPassword as forgotPasswordRoute,
  signUpUser as signUpUserRoute
} from 'routes/routes';
import SignInUI from 'UI/pages/sign-in/sign-in.page';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { useDomainUseCase } from 'UI/contexts';
import { BannerDownloadApp } from 'UI/acquisition/components';
import { withEventsObserver } from 'UI/decorators/events-observer/events-observer';
import UserCreationIntended from 'crm/entities/events/user-creation-intended/user-creation-intended';

const HELP_LINK =
  'https://ajuda.loggi.com/hc/pt-br/requests/new?ticket_form_id=448027?utm_source=beyond';

export const getSingleSignupUrl = () => {
  return process.env.REACT_APP_SINGLE_SIGNUP_URL;
};

const wrongEmailPassword = ({ code, message } = {}) => {
  if (
    message === COGNITO_ERROR_MESSAGES.NOT_AUTHORIZED_INVALID_CREDENTIALS ||
    code === AUTH_ERROR.UserNotFoundException.code
  ) {
    return 'signIn.errorMessages.wrongEmailPassword';
  }
  return null;
};

const socialAccountAlreadyExists = ({ message = '' } = {}) => {
  if (
    message ===
      COGNITO_ERROR_MESSAGES.NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT ||
    message ===
      COGNITO_ERROR_MESSAGES.USER_NOT_REGISTERED_AS_GOOGLE_FEDERATED_LOGIN
  )
    return 'signIn.errorMessages.socialAccountAlreadyExists';
  return null;
};

const passwordForceReset = ({ message = '' } = {}) => {
  if (message?.includes(COGNITO_ERROR_MESSAGES.PASSWORD_MUST_BE_RESET)) {
    return 'signIn.errorMessages.passwordForceReset';
  }
  return null;
};

const userDoesntHaveAccount = ({ message = '' } = {}) => {
  if (
    message.includes(
      COGNITO_ERROR_MESSAGES.CORPORATE_USER_MUST_EXIST_FOR_AUTOMATIC_USER_MIGRATION
    ) ||
    message.includes(
      COGNITO_ERROR_MESSAGES.CORPORATE_LOGGI_USER_MUST_EXIST_FOR_AUTOMATIC_USER_MIGRATION
    )
  ) {
    return 'signIn.errorMessages.userDoesntHaveAccount';
  }
  return null;
};

const emailLoggiWebAlreadyExists = ({ message = '' } = {}) => {
  if (message.includes(COGNITO_ERROR_MESSAGES.EMAIL_LOGGI_WEB_ALREADY_EXISTS)) {
    return 'signIn.errorMessages.emailLoggiWebAlreadyExists';
  }
  return null;
};

const accountIsDisabled = ({ message = '' } = {}) => {
  if (
    message?.includes(COGNITO_ERROR_MESSAGES.ACCOUNT_IS_DISABLED) ||
    message?.includes(COGNITO_ERROR_MESSAGES.ACCOUNT_IS_DISABLED_COGNITO)
  ) {
    return 'signIn.errorMessages.accountIsDisabled';
  }
  return null;
};

const passwordAttemptsExceeded = ({ message = '' } = {}) => {
  if (message?.includes(COGNITO_ERROR_MESSAGES.PASSWORD_ATTEMPTS_EXCEEDED)) {
    return 'signIn.errorMessages.passwordAttemptsExceeded';
  }
  return null;
};

const signUpGoogleLink = ({ message = '' } = {}) => {
  if (
    message?.includes(COGNITO_ERROR_MESSAGES.SIGN_UP_GOOGLE_USER_LINK_REDIRECT)
  ) {
    return 'signIn.errorMessages.signUpGoogleLink';
  }
  return null;
};

const irregularCpf = ({ message = '' } = {}) => {
  if (message?.includes(COGNITO_ERROR_MESSAGES.COMPANY_IRREGULAR_CPF_EXCEPTION))
    return 'signIn.errorMessages.CompanyCpfValidationException';
  return null;
};

const userMigrationFailed = ({ message = '' } = {}) => {
  if (message?.includes(COGNITO_ERROR_MESSAGES.API_USER_MIGRATION_ERROR))
    return 'signIn.errorMessages.APIMigrationError';
  return null;
};

const passwordResetRequired = ({ message = '' } = {}) => {
  if (message?.includes(COGNITO_ERROR_MESSAGES.PASSWORD_RESET_REQUIRED))
    return 'signIn.errorMessages.passwordResetRequiredException';
  return null;
};

const genericError = error => {
  if (error) {
    Sentry.captureException(error);
  }
  return 'signIn.errorMessages.genericError';
};

const SignIn = () => {
  const showSnackbar = useSnackbar();
  const history = useHistory();
  const isMobile = checkIsMobile();
  const { t } = useTranslation('containers');
  const {
    federatedSignIn,
    signIn,
    state: { error: stateError, authenticatedUser }
  } = useAmplifyAuth();
  const isSingleSignupRedirectEnabledUseCase = useDomainUseCase(
    'isSingleSignupRedirectEnabledUseCase'
  );

  const getErrorMessage = [
    userMigrationFailed,
    wrongEmailPassword,
    socialAccountAlreadyExists,
    passwordForceReset,
    userDoesntHaveAccount,
    emailLoggiWebAlreadyExists,
    accountIsDisabled,
    passwordAttemptsExceeded,
    signUpGoogleLink,
    irregularCpf,
    passwordResetRequired,
    genericError
  ].find(fn => fn(stateError?.message));

  const handleSignIn = async ({ email, password }) => {
    return signIn(email, password);
  };

  const handleOnForgotPassword = async email => {
    history.push({
      pathname: forgotPasswordRoute,
      search: `?${FORGOT_PASSWORD_EMAIL_KEY}=${email}`
    });
  };

  const handleOnSignUpClick = async () => {
    const isSingleSignupRedirectEnabled = await isSingleSignupRedirectEnabledUseCase();
    if (isSingleSignupRedirectEnabled) {
      window.location.assign(getSingleSignupUrl());
    } else {
      history.push({ pathname: signUpUserRoute });
    }
  };

  useEffect(() => {
    if (authenticatedUser) history.push(companyIdGuard);
  }, [authenticatedUser, history]);

  useEffect(() => {
    if (!stateError || stateError === USER_NOT_AUTHENTICATED_ERROR) return;

    const i18nKey = getErrorMessage(stateError?.message);

    const linkTag = (
      <Link
        target="_blank"
        href={HELP_LINK}
        underline="always"
        color="inherit"
      />
    );

    const errorMessage = (
      <Trans
        t={t}
        i18nKey={i18nKey}
        components={[linkTag]}
        detail={stateError?.message}
      />
    );

    showSnackbar({
      message: errorMessage,
      severity: 'error'
    });
  }, [stateError, showSnackbar, t, getErrorMessage]);

  return (
    <Box
      style={{ height: '100vh' }}
      display={isMobile ? 'auto' : 'flex'}
      alignItems="center"
    >
      <BannerDownloadApp />
      <SignInUI
        federatedSignIn={provider =>
          federatedSignIn(provider, history.location.from)
        }
        onForgotPassword={handleOnForgotPassword}
        onSubmit={handleSignIn}
        onSignUpClick={handleOnSignUpClick}
      />
    </Box>
  );
};

export default SignIn;

export const SignInPageWithEventsObserver = withEventsObserver(SignIn, {
  initial_signup_button: {
    click: () => {
      new UserCreationIntended().sendToCrm();
    }
  }
});
