import { Box, Link, Typography } from '@mui/material';
import { useFeatureSwitch } from 'hooks/feature-switch';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { yellow } from 'theme/colors';

export default function DisclaimerAlert({ isVisible }) {
  const { t } = useTranslation('ui');
  const externalPackagingHelpURL = useFeatureSwitch(
    'external_packaging_informations_url'
  ).data;

  if (!isVisible) return <></>;

  return (
    <Box bgcolor={yellow[50]} borderRadius="8px" padding={2}>
      <Typography variant="body2" fontWeight="medium" color={yellow[900]}>
        <Trans
          t={t}
          i18nKey="packageDimensionsPage.disclaimerLabel"
          components={[
            <Link
              key={0}
              underline="none"
              href={externalPackagingHelpURL}
              target="_blank"
            />
          ]}
        />
      </Typography>
    </Box>
  );
}

DisclaimerAlert.propTypes = { isVisible: PropTypes.bool.isRequired };
