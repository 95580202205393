import { fetchOccasionalTrackingDetailsUseCase } from 'domain/use-cases';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useTracking = () => {
  const { companyId, loggiKey } = useParams();

  const [state, setState] = useState({
    /** @type {import('models').OccasionalTrackingInformation} */
    tracking: null,
    isLoading: true,
    isError: false
  });

  const effectFn = useCallback(async () => {
    try {
      const tracking = await fetchOccasionalTrackingDetailsUseCase(
        companyId,
        loggiKey
      );

      setState(currentState => ({
        ...currentState,
        tracking,
        isLoading: false,
        isError: false
      }));
    } catch (_err) {
      setState(currentState => ({
        ...currentState,
        tracking: null,
        isLoading: false,
        isError: true
      }));
    }
  }, [companyId, loggiKey]);

  useEffect(() => {
    if (companyId && loggiKey) {
      effectFn();
    }
  }, [companyId, loggiKey, effectFn]);

  return { ...state, reFetch: effectFn };
};

export default { useTracking };
