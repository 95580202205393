import { InputAdornment, TextField as TextFieldMui } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const TextField = ({
  endAdornment,
  errorMessage,
  kind,
  label,
  onChange,
  placeholder,
  upperCase,
  value
}) => {
  const onChangeHandler = ({ target }) => {
    const { value: currentValue } = target;
    return onChange(upperCase ? currentValue.toUpperCase() : currentValue);
  };

  return (
    <TextFieldMui
      className={kind}
      error={!!errorMessage}
      fullWidth
      helperText={errorMessage}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        )
      }}
      label={label}
      onChange={onChangeHandler}
      placeholder={placeholder}
      value={value}
      variant="outlined"
    />
  );
};

TextField.defaultProps = {
  endAdornment: null,
  errorMessage: '',
  kind: '',
  label: '',
  placeholder: '',
  upperCase: false,
  value: undefined
};

TextField.propTypes = {
  endAdornment: PropTypes.node,
  errorMessage: PropTypes.string,
  kind: PropTypes.oneOf(['blank', '']),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  upperCase: PropTypes.bool,
  value: PropTypes.string
};

export default { TextField };
