import { Box, Fade, Slide } from '@mui/material';
import { ReactComponent as HighFiveIllustration } from 'assets/high-five.svg';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, InfiniteLoading, SuccessPage } from 'UI/components';
import { PasswordResetForm } from './password-reset-form/password-reset-form.component';

const SLIDE_TRANSITION_TIMEOUT = 640;
const FADE_TRANSITION_TIMEOUT = 800;
function FullScreenStep({ children }) {
  return (
    <Box display="flex" justifyContent="center" height="100%">
      {children}
    </Box>
  );
}

export function ForgotPasswordPage({
  onGoBack,
  onSubmit,
  onRequestConfirmationCode
}) {
  const [page, setPage] = useState(0);
  const { t } = useTranslation('ui');
  const alreadyRequested = useRef(false);
  const isMountedRef = useRef(true);

  const goPageBack = () =>
    isMountedRef.current && setPage(prev => Math.max(prev - 1, 0));
  const goPageNext = () =>
    isMountedRef.current && setPage(prev => Math.min(prev + 1, 3));

  const handlePasswordResetSubmit = async ({
    confirmationCode,
    newPassword
  }) => {
    goPageNext();
    const payload = { confirmationCode, newPassword };
    try {
      const result = await onSubmit(payload);
      if (result) goPageNext();
      else goPageBack();
    } catch {
      goPageBack();
    }
  };

  const handleRequestResendConfirmationCode = async () => {
    goPageBack();
    await onRequestConfirmationCode();
    goPageNext();
  };

  const requestPasswordRecovery = useCallback(async () => {
    await onRequestConfirmationCode();
    goPageNext();
  }, [onRequestConfirmationCode]);

  useEffect(() => {
    if (!alreadyRequested.current) {
      requestPasswordRecovery();
      alreadyRequested.current = true;
    }
  }, [requestPasswordRecovery]);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  return (
    <Box height="100vh">
      <Fade
        in={page === 0}
        unmountOnExit
        timeout={{ enter: FADE_TRANSITION_TIMEOUT, exit: 0 }}
      >
        <Box>
          <FullScreenStep>
            <InfiniteLoading
              title={t('requestLoading.title')}
              description={t('requestLoading.subtitle')}
            />
          </FullScreenStep>
        </Box>
      </Fade>
      <Slide
        direction="left"
        in={page === 1}
        mountOnEnter
        timeout={{ enter: SLIDE_TRANSITION_TIMEOUT, exit: 0 }}
        unmountOnExit
      >
        <Box>
          <Header onGoBack={onGoBack} />
          <PasswordResetForm
            onSubmit={handlePasswordResetSubmit}
            onRequestResendConfirmationCode={
              handleRequestResendConfirmationCode
            }
          />
        </Box>
      </Slide>
      <Fade
        in={page === 2}
        mountOnEnter
        timeout={{ enter: FADE_TRANSITION_TIMEOUT, exit: 0 }}
        unmountOnExit
      >
        <Box>
          <FullScreenStep>
            <InfiniteLoading
              title={t('resetLoading.title')}
              description={t('resetLoading.subtitle')}
            />
          </FullScreenStep>
        </Box>
      </Fade>
      <Slide
        direction="left"
        in={page === 3}
        mountOnEnter
        timeout={{ enter: SLIDE_TRANSITION_TIMEOUT, exit: 0 }}
      >
        <Box px={3} py={6}>
          <FullScreenStep>
            <SuccessPage
              goToHomeButtonLabel={t('success.finishButtonLabel')}
              illustration={<HighFiveIllustration />}
              subtitles={[t('success.subtitle1'), t('success.subtitle2')]}
              title={t('success.title')}
            />
          </FullScreenStep>
        </Box>
      </Slide>
    </Box>
  );
}

FullScreenStep.propTypes = { children: PropTypes.node.isRequired };
ForgotPasswordPage.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestConfirmationCode: PropTypes.func.isRequired
};

export default { ForgotPasswordPage };
