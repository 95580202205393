import { useQuery } from '@tanstack/react-query';
import { captureException } from '@sentry/react';
import walletApi from 'service/wallet-api';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { DEFAULT_POLLING_TIME } from 'UI/shipment/pages/payment/constants';

const getQueryKey = (companyId, rechargeId) => [
  'wallet-recharge-status',
  String(companyId),
  rechargeId
];

export default function useWalletRechargeStatus({
  companyId,
  rechargeId,
  enabled = true
}) {
  const key = getQueryKey(companyId, rechargeId);
  const { data: pollingTime } = useFeatureSwitch(
    'recharge_status_api_polling_time',
    DEFAULT_POLLING_TIME
  );

  return useQuery(
    key,
    async () => {
      const result = await walletApi.getRechargeStatus({
        companyId,
        rechargeId
      });
      return result;
    },
    {
      enabled: !!companyId && !!rechargeId && enabled,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: pollingTime,
      onError(err) {
        captureException(err);
      }
    }
  );
}
