import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography } from '@mui/material';

import LineInputBase from '../line-input-base.component';

export const AVERAGE_TICKET_INPUT_TEST_ID = 'average-ticket-input';

const AverageTicketInput = ({
  error,
  placeholder,
  onChange,
  inputRef,
  inputProps,
  className,
  onEnterKeyDown,
  value,
  variant
}) => {
  const [valueInputProp, setValueInputProp] = React.useState(value);

  const handleChange = ({ target }) => {
    const onlyDigits = String(target.value).replace(/\D/g, '');
    setValueInputProp(onlyDigits);
    onChange({ target: { ...target, value: onlyDigits } });
  };

  const MONEY_SYMBOL = 'R$';

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems={error ? 'flex-start' : 'center'}
    >
      <Box pr={1} mt={error ? 1 : 0}>
        <Typography variant="subtitle2">{MONEY_SYMBOL}</Typography>
      </Box>
      <LineInputBase
        className={className}
        error={error}
        placeholder={placeholder}
        onChange={handleChange}
        onEnterKeyDown={onEnterKeyDown}
        omitFromHotjar
        inputProps={{
          ...inputProps,
          type: 'text',
          'data-testid': AVERAGE_TICKET_INPUT_TEST_ID,
          maxLength: 15,
          value: valueInputProp
        }}
        inputRef={inputRef}
        variant={variant}
      />
    </Box>
  );
};

AverageTicketInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnterKeyDown: PropTypes.func,
  inputRef: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  value: PropTypes.string,
  variant: PropTypes.string
};

AverageTicketInput.defaultProps = {
  className: '',
  error: '',
  placeholder: '',
  onEnterKeyDown: null,
  inputRef: {},
  inputProps: {},
  value: '',
  variant: 'primary'
};

export default AverageTicketInput;
