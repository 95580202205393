import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Button, TextField } from 'UI/components';
import { useTranslation } from 'react-i18next';
import { blue, root } from 'theme/colors';

/**
 * @param {object} props
 * @param {(coupon: string) => void} props.onAddCoupon
 * @returns {React.Component}
 */
export default function CouponCode({ onAddCoupon }) {
  const { t } = useTranslation('ui');
  const [couponCode, setCouponCode] = React.useState('');

  const handleSubmit = () => {
    if (!couponCode) return;
    onAddCoupon(couponCode);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={3}
    >
      <Box width="75%">
        <TextField
          errorMessage=""
          label={t('couponsPageV2.placeholder')}
          onChange={setCouponCode}
          upperCase
          value={couponCode}
        />
      </Box>
      <Button
        bgColor={root[0]}
        label={t('couponsPageV2.addButton')}
        onClick={handleSubmit}
        textColor={blue[500]}
        variant="text"
      />
    </Box>
  );
}

CouponCode.propTypes = {
  onAddCoupon: PropTypes.func.isRequired
};
