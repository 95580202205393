import { Address } from 'models';
import CorreiosAddress from './correios-address.model';

/**
 * @typedef {import('./correios-address.model').default} CorreiosAddress
 */

/**
 * @class
 */
export default class InputAddress {
  /**
   * @param {object} params
   * @param {CorreiosAddress} params.correios
   */
  constructor(params) {
    /**
     * @type {CorreiosAddress}
     * @private
     */
    this._correios = params.correios;
  }

  /**
   * @returns {CorreiosAddress | undefined}
   */
  get correios() {
    return this._correios;
  }

  /**
   * Returns the address encoded in a string
   *
   * @returns {string}
   */
  description() {
    return this.correios.description();
  }

  /**
   * @param {import('utils/types').ClassProperties<InputAddress>} object
   * @returns {InputAddress|null}
   */
  static fromObject(object) {
    if (!object) return null;
    return new InputAddress({
      correios: new CorreiosAddress(object.correios)
    });
  }

  /**
   * Returns the JSON representation of an InputAddress
   * @override overrides serializer used by JSON.stringify()
   */
  toJSON() {
    return {
      correios: this._correios?.toJSON() ?? undefined
    };
  }

  /**
   * @returns {Address}
   */
  toLegacyAddress() {
    return new Address({ placeId: '', description: this._correios.cep });
  }

  toStructured() {
    return this.correios.toStructured();
  }
}
