import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Stack, Typography, Button } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';
import { currencyFormatter } from 'utils';

import { OptionCard } from 'UI/shipment/components/option-card/option-card.component';

export const WalletBalanceOptionCard = ({
  isSelected,
  hasInsuficientBalance,
  balance,
  onClick,
  onAddWalletBalance
}) => {
  const { t } = useTranslation('ui');

  return (
    <OptionCard isSelected={isSelected} onClick={onClick}>
      <Stack data-testid="wallet-balance-option" gap={spacing.stack.xxxsmall}>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={spacing.stack.nano} pr={spacing.stack.xxxsmall}>
            <Typography
              variant="subtitleSmall"
              color={colors.neutrals['on-surface']}
            >
              {t('payment.wallet.option.title')}
            </Typography>

            <Typography
              variant="subtitleLarge"
              color={colors.neutrals['on-surface']}
              data-testid="balance"
            >
              {currencyFormatter(balance)}
            </Typography>

            {hasInsuficientBalance && (
              <Typography
                variant="subtitleSmall"
                color={colors.neutrals['on-surface-variant']}
              >
                {t('payment.wallet.option.insuficientBalance')}
              </Typography>
            )}
          </Stack>
          {hasInsuficientBalance && (
            <Stack justifyContent="center">
              <Button
                variant="blank"
                onClick={onAddWalletBalance}
                data-testid="on-add-wallet-balance"
              >
                {t('payment.wallet.option.buttons.add')}
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </OptionCard>
  );
};

WalletBalanceOptionCard.defaultProps = {
  isSelected: false,
  hasInsuficientBalance: false,
  balance: 0
};

WalletBalanceOptionCard.propTypes = {
  isSelected: PropTypes.bool,
  hasInsuficientBalance: PropTypes.bool,
  balance: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onAddWalletBalance: PropTypes.func.isRequired
};

export default { WalletBalanceOptionCard };
