import { Search } from '@loggi/front-design-system';
import { colors } from '@loggi/front-design-tokens';
import { Typography } from '@mui/material';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import { debounce } from 'UI/shipments-v2/utils/helpers.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const SearchInput = () => {
  const { t } = useTranslation('ui');
  const {
    search,
    setSearch,
    displayedShipments,
    isLoading,
    isMobileDevice
  } = useShipments();

  const handleSearchChange = value => {
    setSearch(value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const showHelper = search && !displayedShipments.length && !isLoading;

  return (
    <div
      style={{
        position: 'relative',
        width: isMobileDevice && '100%',
        '.MuiInputLabel-root': { paddingRight: '20px' }
      }}
    >
      <Search
        defaultValue={search}
        label={t('myShipmentsV2.searchInputLabel')}
        size="small"
        onChange={debouncedHandleSearchChange}
      />
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 400,
          lineHeight: '16px',
          color: colors.neutrals['on-surface'],
          visibility: !showHelper && 'hidden',
          position: 'absolute',
          top: '100%',
          marginLeft: '20px'
        }}
      >
        {t('myShipmentsV2.searchInputAuxText')}
      </Typography>
    </div>
  );
};

export default SearchInput;
