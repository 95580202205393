/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import Header from 'UI/shipment/components/informations-deck/header';
import usePaginationDeck from 'UI/shipment/components/informations-deck/pagination';
import Footer from 'UI/shipment/components/informations-deck/footer';
import { grey, root } from 'theme/colors';
import { getInformationsAboutServicetype } from './about-service-carousel.help';
import { CustomStyles } from './about-service-carousel.styled';
import { AboutServiceDescriptions } from './about-service-descriptions';
import { AboutServiceListDescriptions } from './about-service-list-descriptions';

export const AboutServiceCarousel = ({
  onClose,
  onContinue,
  initialIndex,
  serviceName
}) => {
  const { t } = useTranslation('ui');
  const { image, slidesInformations } = getInformationsAboutServicetype(
    serviceName
  );
  const slidesTotal = slidesInformations?.length;
  const customStyles = CustomStyles();
  const pagination = usePaginationDeck({
    initialIndex,
    total: slidesTotal
  });
  const isLastSlide = pagination.isLast;

  return (
    <>
      <Box
        minHeight="320px"
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%, 100%'
        }}
      >
        <Header
          buttonsColors={root[0]}
          className={customStyles.header}
          isFirst={pagination.isFirst}
          onBack={() => pagination.prev()}
          onClose={onClose}
        />
      </Box>
      <Box px={4} pb={3} maxHeight="calc(100vh - 120px)">
        <Swiper
          className={customStyles.customPagination}
          initialSlide={initialIndex}
          modules={[Pagination, Virtual]}
          onSlideChange={({ activeIndex }) =>
            pagination.setCurrentIndex(activeIndex)
          }
          pagination={{ clickable: false }}
          slidesPerView={1}
          spaceBetween={30}
          style={{
            padding: '16px 0'
          }}
          onSwiper={pagination.setSwiper}
        >
          {slidesInformations.map(
            ({ availability, descriptions, list, title }, index) => (
              <SwiperSlide key={`slide-${index}`}>
                <Box>
                  <Box color={root[900]}>
                    <Typography mb={3} mt={2} variant="h2" fontWeight={700}>
                      <Trans t={t} i18nKey={title} />
                    </Typography>
                    <AboutServiceDescriptions descriptions={descriptions} />
                    <AboutServiceListDescriptions listDescriptions={list} />
                    <Box color={grey[200]}>
                      <Typography
                        variant="caption1"
                        fontSize={12}
                        fontWeight={500}
                      >
                        <Trans t={t} i18nKey={availability} />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            )
          )}
        </Swiper>
        <Footer
          continueButtonLabel="aboutServiceCarousel.button.continue"
          onFinish={onContinue}
          onNext={pagination.next}
          isLast={isLastSlide}
        />
      </Box>
    </>
  );
};

AboutServiceCarousel.defaultProps = {
  initialIndex: 0
};

AboutServiceCarousel.propTypes = {
  initialIndex: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired
};

export default { AboutServiceCarousel };
