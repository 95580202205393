export const BRT_TZ_OFFSET_MINUTES = 180;
export const TZ_OFFSET_MINUTES_HOURS =
  (new Date().getTimezoneOffset() - BRT_TZ_OFFSET_MINUTES) / 60;
export const MORNING_SLOT_START_HOUR = 10 - TZ_OFFSET_MINUTES_HOURS;
export const MORNING_SLOT_END_HOUR = 12 - TZ_OFFSET_MINUTES_HOURS;
export const AFTERNOON_START_HOUR = 12 - TZ_OFFSET_MINUTES_HOURS;
export const AFTERNOON_SLOT_START_HOUR = 14 - TZ_OFFSET_MINUTES_HOURS;
export const AFTERNOON_SLOT_END_HOUR = 16 - TZ_OFFSET_MINUTES_HOURS;
/* Attention: The values below are part of an experiment values, his usage is conditioned to the enable_experiment_pickup_slots FS status */
export const MORNING_SLOTS_START_HOUR_EXPERIMENT = 9 - TZ_OFFSET_MINUTES_HOURS;
export const MORNING_SLOTS_START_MIN_EXPERIMENT = 30;
export const AFTERNOON_SLOT_END_MIN_EXPERIMENT = 30;
/* Attention: The value above are been calculated with TZ Offset to be always aligned with UTC */
