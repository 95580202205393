import NonWorkingDay from 'models/non-working-days.model';
import nonWorkingDaysApi from 'service/non-working-days-api';

export const getNonWorkingDaysUseCase = async ({
  address,
  endDate,
  nonWorkingDaysType,
  startDate
}) => {
  return nonWorkingDaysApi
    .list({ address, endDate, nonWorkingDaysType, startDate })
    .then(response => NonWorkingDay.parseApiResponse(response));
};

export default { getNonWorkingDaysUseCase };
