import React from 'react';
import PropTypes from 'prop-types';
import useFeatureSwitch from 'hooks/feature-switch/feature-switch.hook';
import { useCurrentUser } from 'hooks/current-user/use-current-user.hook';

const INITIAL_CONTEXT = {
  isEnabled: false,
  isLoading: false,
  isOpen: false,
  open: () => {},
  close: () => {}
};

const CouponOfferContext = React.createContext(INITIAL_CONTEXT);

function useUserEnabledOnFeature() {
  const featureSwitch = useFeatureSwitch('display_coupon_offer', []);

  const userQuery = useCurrentUser();

  const isEnabled =
    featureSwitch.data === '*' ||
    featureSwitch.data?.includes(userQuery.user?.email);

  return {
    isLoading: userQuery.isLoading || featureSwitch.isLoading,
    isEnabled
  };
}

export function CouponOfferProvider({ children }) {
  const userEnabledOnFeature = useUserEnabledOnFeature();
  const [isOpen, setOpen] = React.useState(false);

  const open = () => {
    setOpen(true);
  };
  const close = () => {
    setOpen(false);
  };

  const value = {
    isEnabled: userEnabledOnFeature.isEnabled,
    isLoading: userEnabledOnFeature.isLoading,
    isOpen,
    open,
    close
  };

  return (
    <CouponOfferContext.Provider value={value}>
      {children}
    </CouponOfferContext.Provider>
  );
}

CouponOfferProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export function useCouponOffer() {
  return React.useContext(CouponOfferContext);
}

export function withCouponOffer(Component) {
  return function WithCouponOffer(props) {
    return (
      <CouponOfferProvider>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </CouponOfferProvider>
    );
  };
}
