import { Box, List, ListItem } from '@mui/material';
import { SkeletonLoader } from 'UI/components';
import { range } from 'lodash';
import React from 'react';

export default function LoadingView() {
  return (
    <Box data-testid="loading">
      <List>
        {range(3).map(index => (
          <ListItem key={index} divider>
            <Box py="16px" width="100%">
              <SkeletonLoader height={8} width="unset" />
              <Box height="8px" />
              <SkeletonLoader height={8} width="60%" />
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
