import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { grey, root } from 'theme/colors';
import TitleBar from 'UI/pages/tracking/title-bar/title-bar.component';

const LoadingItem = () => (
  <Box
    display="grid"
    gridTemplateColumns="40px 1fr"
    alignItems="center"
    style={{
      gridGap: '16px'
    }}
  >
    <Skeleton variant="circle" width="40px" height="40px" animation="pulse" />
    <Skeleton variant="text" animation="wave" height="1.5rem" />
  </Box>
);

const LoadingTrackingDetails = () => (
  <Box mt="46px" px={4} py={3}>
    <LoadingItem />
    <Box height="24px" />
    <LoadingItem />
  </Box>
);

const LoadingPackageDetails = () => (
  <Box px={4} py={3}>
    <LoadingItem />
    <Box height="24px" />
    <LoadingItem />
    <Box height="24px" />
    <LoadingItem />
  </Box>
);

const TrackingLoading = () => {
  const { t } = useTranslation('ui');
  const title = t('titleBar.back');

  return (
    <Box bgcolor={root[0]} data-testid="loading-tracking-page">
      <TitleBar title={title} />
      <Skeleton variant="rect" height="220px" animation="wave" />
      <LoadingTrackingDetails />
      <Box height="16px" bgcolor={grey[50]} />
      <LoadingPackageDetails />
    </Box>
  );
};

export default TrackingLoading;
