import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue, root } from 'theme/colors';
import { Button } from 'UI/components';
import CustomDialog from '../custom-dialog.styled';

const AlertDialog = ({ onClose, open, title, url }) => {
  const { t } = useTranslation('ui');

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Box color={root[900]} margin="44px 30px 25px" textAlign="center">
        <Typography variant="subtitle1">
          <strong>{title}</strong>
        </Typography>
      </Box>
      <Box width="calc(100% - 60px)">
        <Box mb={1.5}>
          <Button
            fullWidth
            href={url}
            label={t('redirectActions.continue')}
            size="lg"
          />
        </Box>
        <Box mb={1.5}>
          <Button
            fullWidth
            label={t('redirectActions.dismiss')}
            onClick={onClose}
            size="lg"
            textColor={blue[500]}
            variant="outlined"
          />
        </Box>
      </Box>
    </CustomDialog>
  );
};

AlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default AlertDialog;
