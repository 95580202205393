import { ArrowBackIosRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { root } from 'theme/colors';

export default function ArrowBackButton({ onGoBack }) {
  return (
    <IconButton
      color={root[900]}
      style={{ padding: '0px' }}
      onClick={onGoBack}
      data-testid="arrow-back-button"
    >
      <ArrowBackIosRounded />
    </IconButton>
  );
}

ArrowBackButton.defaultProps = {
  onGoBack: undefined
};

ArrowBackButton.propTypes = {
  onGoBack: PropTypes.func
};
