/**
 * Returns session storage params according to the key
 *
 * @typedef {{
 *   [key: string]: string;
 * }} SessionStorageParams
 *
 * @param {string} key
 * @returns {SessionStorageParams | null}
 */
export const getSessionStorageValue = key =>
  JSON.parse(sessionStorage.getItem(key));

/**
 * Sets session storage params by key
 * @typedef {{
 *   [key: string]: string;
 * }} SessionStorageParams
 *
 * @param {string} key
 * @param {SessionStorageParams} value
 * @returns {void}
 */
export const setSessionStorageValue = (key, value) =>
  sessionStorage.setItem(key, JSON.stringify(value));

/**
 * Remove item from session storage by key
 *
 * @param {string} key
 * @returns {void}
 */
export const removeSessionStorageValue = key => sessionStorage.removeItem(key);
