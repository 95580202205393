export const ADYEN_CONTINUE_BUTTON_CLASS_NAME = 'adyen-checkout__button';
export const QR_CODE_ELEMENT_CLASS_NAME = 'adyen-checkout__qr-loader';

export default function startPixPayment(handleStartPix) {
  /**
   * performs the action of
   * clicking the continue
   * button for the pix
   */
  const buttonElement = document.querySelector(
    `.${ADYEN_CONTINUE_BUTTON_CLASS_NAME}`
  );

  if (buttonElement) buttonElement.click();

  /**
   * Checks if the component
   * with qrcode was generated
   * and only then sets setIsloading
   * to false
   */
  const qrCodeElement = document.querySelector(
    `.${QR_CODE_ELEMENT_CLASS_NAME}`
  );
  if (qrCodeElement) {
    handleStartPix();
  }
}

export function calcCoutDownTime(initPaymentSessionTime) {
  const COUNT_DOWN_TIME_DEFAULT = 30;
  const currentTime = new Date().getTime();
  const differenceTime = currentTime - initPaymentSessionTime;
  const differenceTimeInMinutes = (differenceTime / 60000).toFixed(2);
  const countdownTime = COUNT_DOWN_TIME_DEFAULT - differenceTimeInMinutes;

  return countdownTime;
}
