import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import { getServiceTypeValue } from 'crm/utils';
import EVENTS from 'crm/constants';

export default class PackageDimensionsPageViewed extends CRMBaseEvent {
  constructor({
    originFullAddress,
    destinationFullAddress,
    price,
    slo,
    serviceType
  }) {
    super();
    this.eventName = EVENTS.PAGE_DIMENSIONS_VIEWED;
    this.payload = {
      origin_full_address: originFullAddress,
      destination_full_address: destinationFullAddress,
      price: price ? Number(price) : undefined,
      slo,
      shipment_type: serviceType
    };
  }

  static fromShipmentContext({ context, quoting }) {
    return new PackageDimensionsPageViewed({
      originFullAddress:
        context.pickupAddress?.description ||
        context.originAddress.description(),
      destinationFullAddress:
        context.deliveryAddress?.description ||
        context.destinationAddress.description(),
      price: quoting?.price,
      slo: quoting?.slo,
      serviceType: getServiceTypeValue(context.serviceType)
    });
  }
}
