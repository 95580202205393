import { Box } from '@mui/material';
import { ValidatorBuilder } from 'infra';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StepFormWrapper } from 'UI/components';
import { useUserRecords } from 'UI/contexts';
import AccountZipcodeInformed from 'crm/entities/events/account-zipcode-informed/account-zipcode-informed';
import CRMUser from 'crm/entities/user/crm-user.model';

const CepStepForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();

  const fields = {
    cep: {
      label: t('signUpCompany.input.cep.label'),
      type: 'cep',
      value: userRecords.cep,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .cepPattern(t('signUpCompany.input.cep.errorMessage'))
        .build()
    }
  };

  const handleSubmit = userCredentials => {
    const { cep } = userCredentials;
    setUserRecords({ ...userRecords, cep });

    new CRMUser({
      email: userRecords.email,
      cep
    }).sendToCrm();

    new AccountZipcodeInformed({
      ...userRecords,
      cep
    }).sendToCrm();

    handleOnNextStep();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <StepFormWrapper
        fields={fields}
        onSubmit={handleSubmit}
        buttonLabel={t('signUpCompany.submitButton.label')}
        data-testid="cep-input"
      />
    </Box>
  );
};

CepStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default CepStepForm;
