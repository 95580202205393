import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Address } from 'models';
import { SIMPLE_PACKAGING } from 'UI/shipment/constants/packaging-service-type';
import AddressInfo from '../address-info/address-info.component';
import PackageInfo from '../package-info/package-info.component';

export const ShipmentInfo = ({
  deliveryAddress,
  returnAddress,
  dimensions,
  packagingService
}) => {
  const { t } = useTranslation('ui');

  const getPackageSectionTitle = () => {
    if (packagingService === SIMPLE_PACKAGING) {
      return t(
        'indispatchSuccessPage.shipmentInfo.package.packagingType.loggiPackaging'
      );
    }

    return t(
      'indispatchSuccessPage.shipmentInfo.package.packagingType.selfPackaging'
    );
  };

  return (
    <>
      <AddressInfo
        title={t('indispatchSuccessPage.shipmentInfo.origin.title')}
        address={returnAddress}
        subtitle={t('indispatchSuccessPage.shipmentInfo.returnAddress')}
      />
      <AddressInfo
        title={t('indispatchSuccessPage.shipmentInfo.destination.title')}
        address={deliveryAddress}
      />
      <PackageInfo title={getPackageSectionTitle()} dimensions={dimensions} />
    </>
  );
};

ShipmentInfo.propTypes = {
  deliveryAddress: PropTypes.instanceOf(Address).isRequired,
  returnAddress: PropTypes.instanceOf(Address).isRequired,
  dimensions: PropTypes.shape({
    heightCm: PropTypes.number.isRequired,
    lengthCm: PropTypes.number.isRequired,
    weightG: PropTypes.number.isRequired,
    widthCm: PropTypes.number.isRequired
  }).isRequired,
  packagingService: PropTypes.string.isRequired
};

export default { ShipmentInfo };
