import { loggiWebApi } from '@loggi/authentication-lib';

/**
 * Get proof of delivery for package
 *
 * @param {string} companySecret
 * @param {string} trackingCode
 * @returns {Promise<{ postal_code: string }>}
 */
export default function getProofOfDeliveryUseCase(companySecret, trackingCode) {
  return loggiWebApi
    .url(`/security/fraud/proof-of-delivery/${companySecret}/${trackingCode}`)
    .get()
    .json();
}
