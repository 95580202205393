import { useFeatureSwitch } from 'hooks/feature-switch';
import { isFSEnabled } from 'utils';

/**
 * Returns if a feature switch is enabled for a company
 *
 * @param {string} fsName
 * @param {string} companyId
 * @param {string | string[]} defaultValue
 * @returns {boolean}
 */
function useFeatureSwitchEnabledByCompany(
  fsName,
  companyId,
  defaultValue = []
) {
  const fsData = useFeatureSwitch(fsName, defaultValue).data;

  return isFSEnabled(fsData, parseInt(companyId, 10));
}

/** @returns {Boolean} */
function useFeatureSwitchEnabledByCompanyId(fsName, companyId) {
  const { data: fs } = useFeatureSwitch(fsName);

  if (fs === '*') return true;
  return (fs ?? []).includes(parseInt(companyId, 10));
}

export { useFeatureSwitchEnabledByCompanyId };
export default useFeatureSwitchEnabledByCompany;
