import React from 'react';

import { Stack, Box } from '@mui/material';
import { borders, colors, spacing } from '@loggi/front-design-tokens';

export const LoadingOptionCards = () => {
  return (
    <Stack
      px={spacing.stack.xxsmall}
      pt={spacing.inset.medium}
      gap={spacing.stack.xxxsmall}
      data-testid="loading-options-card"
    >
      <Box
        borderRadius={borders.radius.large}
        backgroundColor={colors.neutrals['surface-container-low']}
      >
        <Box height={spacing.stack.huge} />
        <Box height={spacing.stack.quark} />
      </Box>
      <Box
        borderRadius={borders.radius.large}
        backgroundColor={colors.neutrals['surface-container-low']}
      >
        <Box height={spacing.stack.xlarge} />
        <Box height={spacing.stack.xsmall} />
      </Box>
      <Box
        borderRadius={borders.radius.large}
        backgroundColor={colors.neutrals['surface-container-low']}
      >
        <Box height={spacing.stack.xlarge} />
      </Box>
    </Stack>
  );
};

export default { LoadingOptionCards };
