/* eslint-disable no-console */
import { getPreloadedConfig } from '@loggi/components/src/one/remote-config';
import * as Sentry from '@sentry/react';

/**
 * What is this?
 * This is a monkey patch in both Node's removeChild and insertBefore methods.
 *
 * Why though?
 * Since React 16, it has a compatibility issue with CHROME AUTO-TRANSLATE,
 * because while chrome finds text nodes to translate, it also mutates the DOM
 * messing with React.
 * There's an issue on chrome with a better explanation about this
 * https://bugs.chromium.org/p/chromium/issues/detail?id=872770
 *
 * ----
 *
 * This is a SHORT-TERM solution that was suggest as a monkey-path on a issue
 * about this in the React repository itself.
 * https://github.com/facebook/react/issues/11538#issuecomment-417504600
 *
 * A LONG-TERM solution would be review the project entire code searching by
 * the text occurrences that match the criteria, and do a workaround, as
 * suggested in another comment on the same issue
 * https://github.com/facebook/react/issues/11538#issuecomment-390386520
 *
 * ----
 *
 * The code was slightly modified to comply with our ESLINT rules
 * */
const applyGoogleTranslatorMonkeyPatch = () => {
  const enabled = getPreloadedConfig(
    'enabled_google_translator_monkey_patch'
  ).asBoolean();

  if (enabled && typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function removeChild(...args) {
      const [child] = args;
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', {
            parentOuterHTML: this.outerHTML
          });
        }
        if (Sentry) {
          Sentry.captureException(
            new Error('Cannot remove a child from a different parent'),
            { level: 'warning' }
          );
        }
        return child;
      }
      return originalRemoveChild.apply(this, args);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function insertBefore(...args) {
      const [newNode, referenceNode] = args;
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent'
          );
        }
        if (Sentry) {
          Sentry.captureException(
            new Error(
              'Cannot insert before a reference node from a different parent'
            ),
            { level: 'warning' }
          );
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, args);
    };
  }
};

export default applyGoogleTranslatorMonkeyPatch;
