import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { TextInput } from 'UI/components/input';

export const AddressAutocompleteDisplay = ({
  address,
  error,
  isLoading,
  onTrigger,
  label
}) => {
  return (
    <TextInput
      id="address"
      label={label}
      value={address}
      onChange={noop}
      onFocus={onTrigger}
      error={error}
      isLoading={isLoading}
    />
  );
};

AddressAutocompleteDisplay.defaultProps = {
  address: '',
  error: '',
  label: '',
  isLoading: false
};

AddressAutocompleteDisplay.propTypes = {
  label: PropTypes.string,
  address: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onTrigger: PropTypes.func.isRequired
};

export default { AddressAutocompleteDisplay };
