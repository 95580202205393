import { Box, Typography } from '@mui/material';
import {
  TRACKING_DETAILS_PROPS_BY_STATUS,
  TRACKING_STATUS
} from 'constants/index';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { root } from 'theme/colors';
import { Button, SvgIcon } from 'UI/components';
import PackageStatusDot from 'UI/components/package-status-dot/package-status-dot.component';

const SHOW_ACTION_BUTTON_VARIANTS = [
  TRACKING_STATUS.problemNeedMoreInfo,
  TRACKING_STATUS.lost,
  TRACKING_STATUS.returningToShipper
];

const StatusTrackingDetailsCard = ({
  actionDescription,
  additionalInformation,
  description,
  label,
  onActionClick,
  status,
  isIndispatch
}) => {
  const { background, imgUrl, customIcon } = TRACKING_DETAILS_PROPS_BY_STATUS[
    status
  ];
  const showActionButton = SHOW_ACTION_BUTTON_VARIANTS.some(
    elem => status === elem
  );

  const handleClick = ev => {
    ev.stopPropagation();
    onActionClick();
  };

  return (
    <Box
      bgcolor={background}
      color={root[0]}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="220px"
      pl={3}
      position="relative"
      width="100%"
    >
      <Box flexDirection="row" display="flex">
        <Box width="65%" pb={4}>
          <Box mt={3} alignItems="center" display="flex" flexDirection="row">
            <Box mr={1}>
              {customIcon ? (
                <SvgIcon color={root[0]} icon={customIcon} />
              ) : (
                <PackageStatusDot />
              )}
            </Box>
            <Typography variant="body1">
              <strong>{label}</strong>
            </Typography>
          </Box>
          <Box mt={5}>
            <Typography variant="body1">
              <strong>{description}</strong>
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography variant="body1">{additionalInformation}</Typography>
          </Box>
          {showActionButton && (
            <Box mb={3} mt={2}>
              <Button
                bgColor={root[0]}
                label={actionDescription}
                onClick={handleClick}
                textColor={background}
              />
            </Box>
          )}
        </Box>
        {imgUrl && !isIndispatch && (
          <Box
            ml={1}
            width="35%"
            overflow="hidden"
            flexDirection="column"
            justifyContent="center"
            display="flex"
            minHeight="220px"
          >
            <img alt="img-id" src={imgUrl} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

StatusTrackingDetailsCard.propTypes = {
  actionDescription: PropTypes.string,
  additionalInformation: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
  status: PropTypes.string.isRequired,
  isIndispatch: PropTypes.bool.isRequired
};

StatusTrackingDetailsCard.defaultProps = {
  actionDescription: '',
  onActionClick: noop
};

export default StatusTrackingDetailsCard;
