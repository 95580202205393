import { Box } from '@mui/material';
import icons from 'constants/icons-urls.json';
import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from 'UI/components/svg-icon/svg-icon.component';

export const Icon = ({ color, icon, label, size }) => {
  const isIconInCollection = Object.keys(icons).includes(icon);
  return (
    <Box mr={1} height={`${size}px`}>
      {isIconInCollection ? (
        <SvgIcon color={color} icon={icon} size={size} />
      ) : (
        <img alt={label} height={`${size}px`} src={icon} width={`${size}px`} />
      )}
    </Box>
  );
};

Icon.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired
};

export default { Icon };
