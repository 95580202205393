import { fromPairs } from 'lodash';

const translateValidationErrs = errs => getTranslationFn =>
  fromPairs(
    Object.entries(errs).map(([key, translationKey]) => [
      key,
      getTranslationFn(translationKey)
    ])
  );

const parseYupValidationErrs = ({ inner }) => {
  const err = fromPairs(
    inner.map(({ message, path, params }) => {
      const { label = '' } = params;
      const key = label || path;
      return [key, message];
    })
  );
  return { translate: translateValidationErrs(err), err };
};

export class SchemaValidator {
  static get schema() {
    throw Error('[SchemaValidator] Your class must implement the Schema.');
  }

  validate() {
    try {
      const { schema: classSchema } = Object.getPrototypeOf(this).constructor;
      classSchema.validateSync(this, { abortEarly: false });
      return true;
    } catch (ex) {
      throw parseYupValidationErrs(ex);
    }
  }

  get isValid() {
    try {
      return this.validate();
    } catch {
      return false;
    }
  }
}

export default SchemaValidator;
