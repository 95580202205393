/** This is purposely here so sentry is started as soon as possible
 * so we don't miss any errors on the app first render */
import { AmplifyAuthProvider } from '@loggi/authentication-lib';
import { AppErrorBoundary } from '@loggi/components/src/one';
import { SnackbarProvider } from '@loggi/front-design-system';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'configuration/amplify-init';
import 'configuration/firebase-init';
import 'configuration/hotjar-init';
import 'configuration/i18n-init';
import 'configuration/rudder-init';
import 'configuration/singular-init';
import 'configuration/tag-manager-init';
import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import 'sentry/sentry-init';

import { RouteChangesObserver } from 'contexts';
import { NativeAppEventHandlerProvider } from 'mobile';
import theme from 'theme';
import { useIsMobile } from 'utils';
import App from './App';
import {
  CorpMiddlewareProvider,
  CouponsProvider,
  DomainUseCasesContext,
  UserRecordsContextProvider
} from './contexts';

const queryClient = new QueryClient();

function RootComponent({ useCases }) {
  const isMobile = useIsMobile();

  return (
    <DomainUseCasesContext.Provider value={useCases}>
      <QueryClientProvider client={queryClient}>
        <UserRecordsContextProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <NotistackSnackbarProvider
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                autoHideDuration={7000}
                maxSnack={3}
              >
                <SnackbarProvider isMobile={isMobile}>
                  <AppErrorBoundary
                    beforeCapture={scope => {
                      scope.setTag('app.region', 'one');
                    }}
                  >
                    <AmplifyAuthProvider>
                      <NativeAppEventHandlerProvider>
                        <CorpMiddlewareProvider>
                          <CouponsProvider>
                            <Router>
                              <RouteChangesObserver />
                              <App />
                            </Router>
                          </CouponsProvider>
                        </CorpMiddlewareProvider>
                      </NativeAppEventHandlerProvider>
                    </AmplifyAuthProvider>
                  </AppErrorBoundary>
                </SnackbarProvider>
              </NotistackSnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </UserRecordsContextProvider>
      </QueryClientProvider>
    </DomainUseCasesContext.Provider>
  );
}

RootComponent.propTypes = {
  useCases: PropTypes.shape({}).isRequired
};

export default RootComponent;
