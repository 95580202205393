/* eslint consistent-return: "off" */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  loggiDesignSystem as theme,
  useSnackbar
} from '@loggi/front-design-system';
import { spacing } from '@loggi/front-design-tokens';
import CheckoutHeader from 'UI/shipment/components/checkout-header/header.component';
import components from 'theme/components';
import { useFeatureSwitch } from 'hooks/feature-switch';
import useWalletRechargeStatus from 'hooks/wallet-recharge-status/wallet-recharge-status.hook';
import { PIX, RECHARGE_STATUS } from 'UI/shipment/pages/payment/constants';
import CreditCardPayment from 'UI/shipment/pages/payment/credit-card-payment';
import {
  useShipmentDispatcher,
  useShipmentContext
} from 'UI/shipment/state-machine/context';
import { LoadingRecharge } from 'UI/shipment/pages/wallet/loading-recharge/loading-recharge.component';

import OptionItem from './option-item/option-item';
import Footer from '../footer/footer.component';

const OTHER_VALUE = 'otherValue';

/**
 * Necessary for our drawer to continue
 * respecting the maxWidth stipulated in
 * our general theme
 */
const addGeneralDrawerComponentsTheme = () => {
  Object.assign(theme.components, { MuiDrawer: components.MuiDrawer });
};

export default function PreDefinedRechargeOptions() {
  const { t } = useTranslation('ui');
  const [optionSelected, setOptionSelected] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const shipmentDispatcher = useShipmentDispatcher();
  const { showSnackbar, dismissSnackbar } = useSnackbar();
  const { companyId } = useParams();
  const snackbarId = useRef(null);
  const [currentRechargeId, setCurrentRechargeId] = useState(null);

  const rechargeStatusQuery = useWalletRechargeStatus({
    companyId,
    rechargeId: currentRechargeId
  });

  const { context } = useShipmentContext();

  addGeneralDrawerComponentsTheme();

  const handleOptionSelected = value => {
    if (snackbarId.current) dismissSnackbar(snackbarId.current);
    setOptionSelected(value);
  };

  const handleMissingSelectedOption = () => {
    snackbarId.current = showSnackbar(
      {
        message: t('wallet.preDefinedRechargeOptions.optionNotSelected'),
        variant: 'negative',
        size: 'small'
      },
      { preventDuplicate: true }
    );
  };

  const { data } = useFeatureSwitch('pre_defined_recharge_options', []);
  const { optionsValues } = data;
  const isValidOption = optionSelected !== OTHER_VALUE && optionSelected;
  const handleSubmit = () => {
    if (!optionSelected) return handleMissingSelectedOption();

    if (optionSelected === OTHER_VALUE) {
      return shipmentDispatcher.clickOtherValue();
    }

    if (context.paymentMethod === PIX) {
      shipmentDispatcher.continue(optionSelected);
    } else {
      setShowPayment(true);
    }
  };

  const handlePaymentCompleted = rechargeId => {
    setShowPayment(false);
    setCurrentRechargeId(rechargeId);
  };

  useEffect(() => {
    const rechargeStatus = rechargeStatusQuery?.data?.['recharge_status'];

    if (rechargeStatus === RECHARGE_STATUS.SUCCESS) {
      const {
        payment_method: paymentMethod,
        current_balance: currentBalance,
        updated_at: updatedAt
      } = rechargeStatusQuery.data;

      const paymentDate = new Date(updatedAt);

      shipmentDispatcher.balanceSuccessfullyAdded({
        addedBalance: optionSelected,
        rechargeId: currentRechargeId,
        paymentMethod,
        currentBalance,
        paymentDate
      });
    }

    if (
      rechargeStatusQuery?.isError ||
      rechargeStatus === RECHARGE_STATUS.ERROR ||
      rechargeStatus === RECHARGE_STATUS.CANCELED_BY_GATEWAY
    ) {
      shipmentDispatcher.viewWalletBalanceError({
        paymentMethod: context.paymentMethod,
        addBalanceError: RECHARGE_STATUS.ERROR
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargeStatusQuery]);

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Stack pb={spacing.inset.medium}>
          <CheckoutHeader title={t('wallet.preDefinedRechargeOptions.title')} />
        </Stack>
        <Stack
          gap={spacing.stack.xxxsmall}
          px={spacing.stack.xxsmall}
          pb={spacing.stack.xxxlarge}
        >
          {optionsValues?.map(({ value, cashback }) => (
            <OptionItem
              key={value}
              cashback={cashback}
              isSelected={optionSelected === value}
              value={value}
              onClick={() => handleOptionSelected(value)}
            />
          ))}
          <OptionItem
            isSelected={optionSelected === OTHER_VALUE}
            otherValueOption
            onClick={() => handleOptionSelected(OTHER_VALUE)}
          />
        </Stack>

        <Footer>
          <Footer.PrimaryAction
            title={t('wallet.preDefinedRechargeOptions.continue')}
            onClick={handleSubmit}
          />
        </Footer>
      </Stack>
      {isValidOption && (
        <CreditCardPayment
          isOpen={showPayment}
          onClose={() => setShowPayment(false)}
          handlePaymentCompleted={handlePaymentCompleted}
          amount={optionSelected}
          onError={() =>
            shipmentDispatcher.viewWalletBalanceError({
              paymentMethod: context.paymentMethod
            })
          }
        />
      )}
      <LoadingRecharge isVisible={!!currentRechargeId} />
    </ThemeProvider>
  );
}
