import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useDomainUseCase } from 'UI/contexts';

const useCreateSupportTicket = () => {
  const [state, setState] = useState('idle');
  const createSupportTicketUseCase = useDomainUseCase(
    'createSupportTicketUseCase'
  );

  const createTicket = ({
    companyId,
    loggiKey,
    phoneNumber,
    reason,
    details
  }) => {
    setState('loading');
    createSupportTicketUseCase({
      companyId,
      loggiKey,
      phoneNumber,
      reason,
      details
    })
      .then(() => {
        setState('success');
      })
      .catch(error => {
        Sentry.captureException(error);
        setState('error');
      });
  };

  return {
    createTicket,
    isIdle: state === 'idle',
    isLoading: state === 'loading',
    isSuccess: state === 'success',
    isError: state === 'error'
  };
};

export default useCreateSupportTicket;
