import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { companyIdGuard } from 'routes/routes';
import Loader from 'UI/components/loader/loader.component';
import { useDomainUseCase } from 'UI/contexts';

export const ProtectFromAuthenticated = ({ children }) => {
  const getCurrentUser = useDomainUseCase('getCurrentUserUseCase');
  const [isAuthenticated, setAuthenticated] = useState(null);

  const checkCurrentUser = useCallback(async () => {
    const currentUser = await getCurrentUser();
    const thereIsCurrentUser = !!currentUser;
    setAuthenticated(thereIsCurrentUser);
  }, [getCurrentUser]);

  useEffect(() => {
    checkCurrentUser();
  }, [checkCurrentUser]);

  if (isAuthenticated === null) return <Loader />;
  if (isAuthenticated) return <Redirect from="*" to={companyIdGuard} />;
  return children;
};

ProtectFromAuthenticated.propTypes = {
  children: PropTypes.node.isRequired
};

export default { ProtectFromAuthenticated };
