import { IconButton, InputAdornment, styled } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import LineInputBase from '../line-input-base.component';

const StyledPasswordInput = styled(LineInputBase)({
  '& > input[type="password"]': {
    letterSpacing: '10px'
  },
  '& > input::placeholder': {
    letterSpacing: 'normal'
  }
});

const PasswordInput = ({
  className,
  error,
  placeholder,
  onChange,
  onEnterKeyDown,
  isActive,
  inputRef,
  inputProps,
  variant
}) => {
  const [isPasswordVisible, setPasswordVisibility] = React.useState(false);

  const togglePasswordVisibility = () => setPasswordVisibility(prev => !prev);

  const passwordVisibilityToggler = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={togglePasswordVisibility}
        color="primary"
        data-testid="password-input-toggle-visibility"
      >
        {isPasswordVisible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <StyledPasswordInput
      className={className}
      placeholder={placeholder}
      error={error}
      onChange={onChange}
      onEnterKeyDown={onEnterKeyDown}
      inputProps={{
        ...inputProps,
        type: isPasswordVisible ? 'text' : 'password',
        'data-testid': 'password-input'
      }}
      endAdornment={isActive && passwordVisibilityToggler}
      inputRef={inputRef}
      variant={variant}
    />
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnterKeyDown: PropTypes.func,
  isActive: PropTypes.bool,
  inputRef: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  variant: PropTypes.string
};

PasswordInput.defaultProps = {
  className: '',
  error: '',
  placeholder: '',
  onChange: null,
  onEnterKeyDown: null,
  isActive: false,
  inputRef: {},
  inputProps: {},
  variant: 'primary'
};

export default PasswordInput;
