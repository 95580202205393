const ENVIOS_URL = process.env.REACT_APP_ENVIOS_URL;
const BEYOND_WEB_CLIENT = process.env.REACT_APP_AWS_WEB_CLIENT;
const ENVIOS_WEB_CLIENT = process.env.REACT_APP_AWS_WEB_CLIENT_ENVIOS;

const setEnviosCognitoIdentityCookies = () => {
  Object.entries(localStorage).forEach(([key, value]) => {
    if (key.includes(BEYOND_WEB_CLIENT)) {
      const newKey = key.replace(BEYOND_WEB_CLIENT, ENVIOS_WEB_CLIENT);
      document.cookie = `${newKey}=${value}; path=/; domain=.loggi.com; secure; SameSite=None; expires: 365`;
    }
  });
};

const openEnvios = () => {
  setEnviosCognitoIdentityCookies();
  window.location.replace(`${ENVIOS_URL}/login?redirectFrom=beyond`);
};

export default openEnvios;
