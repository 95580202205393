/* eslint-disable no-shadow */
import { Address } from 'models';
import React from 'react';

/**
 * @param {object} options
 * @param {Address | null} options.address
 * @param {string | null} options.complement
 */
export default function useAddressForm({ address = null, complement = '' }) {
  const [state, setState] = React.useState({ address, complement });

  return {
    state,
    isAddressValid: state.address instanceof Address,
    setAddress: address => setState({ ...state, address }),
    setComplement: complement => setState({ ...state, complement })
  };
}
