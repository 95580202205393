import groupedShipmentPtBrTranslations from 'UI/contexts/grouped-shipment/pt-br.translations.json';
import corpMiddlewareContextPtBrTranslations from 'UI/contexts/corp-middleware/pt-br.translations.json';
import impersonationPtBrTranslations from 'UI/pages/impersonate/pt-br.translations.json';
import supportPagePtBrTranslations from 'UI/pages/support/pt-br.translations.json';
import trackingHistoryPtBrTranslations from 'UI/pages/tracking/history/pt-br.translations.json';
import shipmentFooterPtBrTranslations from 'UI/shipment/components/shipment-footer/pt-br.translations.json';
import cleaningConfirmationDialogPtBrTranslations from 'UI/pages/summary/cleaning-confirmation-dialog/pt-br.translations.json';
import validatorPtBrTranslations from '../infra/validators/pt-br.translations.json';
import addressAutoCompletePtBrTranslations from './components/address-autocomplete/pt-br.translations.json';
import deleteAlertDialogPtBrTranslations from './components/alert-dialog/delete-alert/pt-br.translations.json';
import alertDialogPtBrTranslations from './components/alert-dialog/redirect-alert/pt-br.translations.json';
import headerEnTranslations from './components/header/en.translations.json';
import headerPtBrTranslations from './components/header/pt-br.translations.json';
import bannerDownloadAppPtBrTranslations from './acquisition/components/banner-download-app/pt-br.translations.json';
import walletCardPtBrTranslations from './acquisition/components/wallet-card/pt-br.translations.json';
import deliveryCustomerDocumentPtBrTranslations from './components/line-inputs/delivery-customer-document-input/pt-br.translations.json';
import nativeSignInErrorAlertPtBrTranslations from './components/native-sign-in-error-alert/pt-br.translations.json';
import packageSizePtBrTranslations from './components/package-size-pt-br.translations.json';
import segmentSearchBarPtBrTranslations from './components/segment-search-bar/pt-br.translations.json';
import shipmentCancelDrawerPtBrTranslations from './components/shipment-cancel-drawer/pt-br.translations.json';
import shipmentCanceledDrawerPtBrTranslations from './components/shipment-canceled-drawer/pt-br.translations.json';
import shipmentPaymentSuccessPagePtBrTranslations from './components/shipment-payment-success-page/pt-br.translations.json';
import tabsEnTranslations from './components/tabs/en.translations.json';
import tabsPtBrTranslations from './components/tabs/pt-br.translations.json';
import trackingButtonPtBrTranslations from './components/tracking-button/pt-br.translations.json';
import trackingCodeDrawerEnTranslations from './components/tracking-code-drawer/en.translations.json';
import trackingCodeDrawerPtBrTranslations from './components/tracking-code-drawer/pt-br.translations.json';
import trackingErrorMessagePtBrTranslations from './components/tracking-error-message/pt-br.translations.json';
import userProfileButtonPtBrTranslations from './components/user-profile-button/pt-br.translations.json';
import couponOfferHomePtBrTranslations from './acquisition/components/coupon-offer/pt-br.translations.json';
import aboutServiceCarouselPtBrTranslations from './acquisition/components/about-service-carousel/pt-br.translations.json';
import accountProblemPagePtBrTranslations from './pages/account-problem/pt-br.translations.json';
import accessDeniedPagePtBrTranslations from './pages/error-pages/access-denied/pt-br.translations.json';
import forgotPasswordPtBrTranslations from './pages/forgot-password/pt-br.translations.json';
import initialPagePtBrTranslations from './pages/initial/pt-br.translations.json';
import shipmentPaymentPtBrTranslations from './pages/shipment-payment/pt-br.translations.json';
import signInPtBrTranslations from './pages/sign-in/pt-br.translations.json';
import signUpCompanyPtBrTranslations from './pages/sign-up/company/pt-br.translations.json';
import signUpUserPtBrTranslations from './pages/sign-up/user/pt-br.translations.json';
import summaryPagePtBrTranslations from './pages/summary/pt-br.translations.json';
import trackingDeliveryInfoEnTranslations from './pages/tracking/delivery-info/en.translations.json';
import trackingDeliveryInfoPtBrTranslations from './pages/tracking/delivery-info/pt-br.translations.json';
import packageDetailEnTranslations from './pages/tracking/package-detail/en.translations.json';
import packageDetailPtBrTranslations from './pages/tracking/package-detail/pt-br.translations.json';
import PaymentDetailsEnTranslations from './pages/tracking/payment-details/en.translations.json';
import PaymentDetailsPtBrTranslations from './pages/tracking/payment-details/pt-br.translations.json';
import trackingPagePtBrTranslations from './pages/tracking/pt-br.translations.json';
import titleBarEnTranslations from './pages/tracking/title-bar/en.translations.json';
import titleBarPtBrTranslations from './pages/tracking/title-bar/pt-br.translations.json';
import indispatchOnboardPagePtBrTranslations from './shipment/pages/indispatch/onboard/pt-br.translations.json';
import newHomePtBrTranslations from './acquisition/pages/home/pt-br.translations.json';
import dropOffDeliveryInfoTranslations from './pages/tracking/drop-off-delivery-info/pt-br.translations.json';
import dropOffAgenciesLocationTranslations from './pages/drop-off-agencies-location/pt-br.translations.json';
import trackerPageTranslations from './pages/tracker/pt-br.translations.json';
import indispatchDeliveryInfoTranslations from './pages/tracking/indispatch-delivery-info/pt-br.translations.json';
import myShipmentsV2Translations from './shipments-v2/pt-br.translations.json';

// shipment v2
import informationsDeckPtBrTranslations from './shipment/components/informations-deck/pt-br.translations.json';
import indispatchTermsDialogV2PtBrTranslations from './shipment/pages/indispatch/terms-dialog/pt-br.translations.json';
import packagingInformationsV2PtBrTranslations from './shipment/pages/about-packaging/pt-br.translations.json';
import budgetPageV2PtBrTranslations from './shipment/pages/budget/pt-br.translations.json';
import couponV2PtBrTranslations from './shipment/pages/coupons/pt-br.translations.json';
import deliveryCustomerV2PtBrTranslations from './shipment/pages/delivery-customer/pt-br.translations.json';
import deliveryAddressV2PtBrTranslations from './shipment/pages/delivery/pt-br.translations.json';
import insuranceValueV2PtBrTranslations from './shipment/pages/insurance-value/pt-br.translations.json';
import packageDimensionsV2PtbrTranslations from './shipment/pages/package-dimensions/pt-br.translations.json';
import newPackageSizeV2PtbrTranslations from './shipment/pages/package-size-new/pt-br.translations.json';
import packageSizeV2PtbrTranslations from './shipment/pages/package-size/pt-br.translations.json';
import packagingRequiringPtBrTranslations from './shipment/pages/packaging-requiring/pt-br.translations.json';
import pickupCustomerV2PtBrTranslations from './shipment/pages/pickup-customer/pt-br.translations.json';
import pickupScheduleV2PtBrTranslations from './shipment/pages/pickup-schedule/pt-br.translations.json';
import pickupAddressV2PtBrTranslations from './shipment/pages/pickup/pt-br.translations.json';
import shipmentDetailsPtBrTranslations from './shipment/components/shipment-details/pt-br.translations.json';
import shipmentCreatedV2PtBrTranslations from './shipment/pages/shipment_created_v2/pt-br.translations.json';
import addressesSummaryPtBrTranslations from './shipment/components/shipment-addresses-summary/pt-br.translations.json';
import agenciesPagePtBrTranslations from './shipment/pages/agencies/pt-br.translations.json';
import listAgenciesPtBrTranslations from './shipment/components/agencies/pt-br.translations.json';
import servicesPagePtBrTranslations from './shipment/pages/indispatch/services/pt-br.translations.json';
import dropoffOnboardingPtBrTranslations from './shipment/pages/dropoff-onboarding/pt-br.translations.json';
import postalCodeAddressesPagePtBrTranslations from './shipment/pages/postal-code-addresses/pt-br.translations.json';
import correiosAddressFormPtBrTranslations from './shipment/components/correios-address-form/pt-br.translations.json';
import originAddressPagePtBrTranslations from './shipment/pages/origin-address/pt-br.translations.json';
import destinationAddressPagePtBrTranslations from './shipment/pages/destination-address/pt-br.translations.json';
import unavailableServiceForAddressTypeDrawerTranslations from './shipment/components/unavailable-service-for-address-type-drawer/pt-br.translations.json';
import addressDisplayPtBrTranslations from './shipment/components/address-display/pt-br.translations.json';
import pixPaymentPtBrTranslations from './shipment/pages/pix-payment/pt-br.translations.json';
import checkoutPtBrTranslations from './shipment/pages/checkout/pt-br.translations.json';
import checkoutFooterPtBrTranslations from './shipment/pages/payment/footer/pt-br.translations.json';
import paymentPagePtBrTranslations from './shipment/pages/payment/pt-br.translations.json';
import shipmentWalletPtBrTranslations from './shipment/pages/wallet/pt-br.translations.json';
import walletPagePtBrTranslations from './pages/wallet/pt-br.translations.json';
import indispatchSuccessPageV2PtBrTranslations from './shipment/pages/indispatch/success_v2/pt-br.translations.json';

const translations = {
  'pt-BR': {
    ui: {
      // shipment v2
      ...budgetPageV2PtBrTranslations,
      ...pickupAddressV2PtBrTranslations,
      ...deliveryAddressV2PtBrTranslations,
      ...deliveryCustomerV2PtBrTranslations,
      ...insuranceValueV2PtBrTranslations,
      ...packageSizeV2PtbrTranslations,
      ...newPackageSizeV2PtbrTranslations,
      ...packageDimensionsV2PtbrTranslations,
      ...pickupCustomerV2PtBrTranslations,
      ...pickupScheduleV2PtBrTranslations,
      ...couponV2PtBrTranslations,
      ...packagingRequiringPtBrTranslations,
      ...shipmentFooterPtBrTranslations,
      ...packagingInformationsV2PtBrTranslations,
      ...informationsDeckPtBrTranslations,
      ...shipmentDetailsPtBrTranslations,
      ...indispatchTermsDialogV2PtBrTranslations,
      ...shipmentCreatedV2PtBrTranslations,
      ...addressesSummaryPtBrTranslations,
      ...agenciesPagePtBrTranslations,
      ...listAgenciesPtBrTranslations,
      ...servicesPagePtBrTranslations,
      ...dropoffOnboardingPtBrTranslations,
      ...postalCodeAddressesPagePtBrTranslations,
      ...correiosAddressFormPtBrTranslations,
      ...originAddressPagePtBrTranslations,
      ...destinationAddressPagePtBrTranslations,
      ...unavailableServiceForAddressTypeDrawerTranslations,
      ...addressDisplayPtBrTranslations,
      ...pixPaymentPtBrTranslations,
      ...checkoutPtBrTranslations,
      ...checkoutFooterPtBrTranslations,
      ...paymentPagePtBrTranslations,
      ...shipmentWalletPtBrTranslations,
      ...corpMiddlewareContextPtBrTranslations,

      ...cleaningConfirmationDialogPtBrTranslations,
      ...aboutServiceCarouselPtBrTranslations,
      ...newHomePtBrTranslations,
      ...accessDeniedPagePtBrTranslations,
      ...accountProblemPagePtBrTranslations,
      ...addressAutoCompletePtBrTranslations,
      ...alertDialogPtBrTranslations,
      ...impersonationPtBrTranslations,
      ...couponOfferHomePtBrTranslations,
      ...deleteAlertDialogPtBrTranslations,
      ...deliveryCustomerDocumentPtBrTranslations,
      ...forgotPasswordPtBrTranslations,
      ...groupedShipmentPtBrTranslations,
      ...headerPtBrTranslations,
      ...indispatchSuccessPageV2PtBrTranslations,
      ...indispatchOnboardPagePtBrTranslations,
      ...nativeSignInErrorAlertPtBrTranslations,
      ...packageDetailPtBrTranslations,
      ...packageSizePtBrTranslations,
      ...PaymentDetailsPtBrTranslations,
      ...shipmentCancelDrawerPtBrTranslations,
      ...shipmentCanceledDrawerPtBrTranslations,
      ...shipmentPaymentPtBrTranslations,
      ...shipmentPaymentSuccessPagePtBrTranslations,
      ...signInPtBrTranslations,
      ...signUpCompanyPtBrTranslations,
      ...signUpUserPtBrTranslations,
      ...summaryPagePtBrTranslations,
      ...supportPagePtBrTranslations,
      ...tabsPtBrTranslations,
      ...titleBarPtBrTranslations,
      ...trackingButtonPtBrTranslations,
      ...trackingCodeDrawerPtBrTranslations,
      ...trackingDeliveryInfoPtBrTranslations,
      ...trackingErrorMessagePtBrTranslations,
      ...trackingHistoryPtBrTranslations,
      ...trackingPagePtBrTranslations,
      ...userProfileButtonPtBrTranslations,
      ...validatorPtBrTranslations,
      ...segmentSearchBarPtBrTranslations,
      ...initialPagePtBrTranslations,
      ...dropOffDeliveryInfoTranslations,
      ...dropOffAgenciesLocationTranslations,
      ...trackerPageTranslations,
      ...bannerDownloadAppPtBrTranslations,
      ...walletCardPtBrTranslations,
      ...indispatchDeliveryInfoTranslations,
      ...myShipmentsV2Translations,
      ...walletPagePtBrTranslations
    }
  },
  en: {
    ui: {
      ...headerEnTranslations,
      ...packageDetailEnTranslations,
      ...PaymentDetailsEnTranslations,
      ...tabsEnTranslations,
      ...titleBarEnTranslations,
      ...trackingCodeDrawerEnTranslations,
      ...trackingDeliveryInfoEnTranslations
    }
  }
};

export default translations;
