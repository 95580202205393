/* eslint-disable camelcase */
export class LoggiUser {
  constructor({
    userId,
    userType,
    firstName,
    lastName,
    email,
    phone,
    document,
    accountCreatedDate
  }) {
    this.userId = userId;
    this.userType = userType;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.document = document;
    this.accountCreatedDate = accountCreatedDate;
  }

  static parseApiResponse(response) {
    const loggiUser = response?.loggi_user;

    if (!loggiUser) {
      return null;
    }

    return new LoggiUser({
      userId: loggiUser.user_id,
      userType: loggiUser.user_type,
      firstName: loggiUser.first_name,
      lastName: loggiUser.last_name,
      email: loggiUser.email,
      phone: loggiUser.phone,
      document: loggiUser.document,
      accountCreatedDate: loggiUser.account_created_date
    });
  }
}

export default LoggiUser;
