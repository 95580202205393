/* eslint-disable camelcase */
import { annotatorApi, loggiWebApi } from '@loggi/authentication-lib';

export const endpoints = {
  coverage: '/api/v1/coverage/',
  postalCode: companyId => {
    return `/one/api/addresses/v1/companies/${companyId}/address/postal_code/`;
  }
};

const addressApi = {
  getCoverage: ({ originPostalCode, destinationPostalCode }) => {
    const queryParams = {};
    const url = endpoints.coverage;

    if (originPostalCode) queryParams['origin-postal-code'] = originPostalCode;
    if (destinationPostalCode)
      queryParams['destination-postal-code'] = destinationPostalCode;

    return annotatorApi
      .url(url)
      .query(queryParams)
      .get();
  },
  getPostalCodeFromAddress: ({ companyId, address }) => {
    const { description, place_id } = address;
    const queryParams = {};
    const url = endpoints.postalCode(companyId);

    if (description) queryParams.address = description;
    if (place_id) queryParams.place_id = place_id;

    return loggiWebApi
      .url(url)
      .query(queryParams)
      .get();
  }
};

export default addressApi;
