import { Box, Chip, Grid, Typography } from '@mui/material';
import { blue, grey } from 'theme/colors';
import { Button } from 'UI/components';
import { CARD_BRANDS, PAYMENT_STATUSES } from 'constants/index';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  chipRoot: {
    '& .MuiChip-icon': {
      order: 1,
      marginRight: '5px',
      cursor: 'pointer'
    }
  }
});

const isPaymentStatusCanceled = status => {
  return status === 'PAYMENT_STATUS_CANCELLED';
};

const hasBillingInvoices = billingInvoice => {
  if (billingInvoice.length === 0) return false;

  return !!Object.keys(billingInvoice[0]).length;
};

export const getDocumentLink = billingInvoiceResponse => {
  const response = billingInvoiceResponse[0];

  return response.cteUrl ? response.cteUrl : response.nfeUrl;
};

const PaymentDetails = ({ paymentDetails, billingInvoice }) => {
  const classes = useStyles();

  const { t } = useTranslation('ui');

  const creditCard = paymentDetails.paymentDocument?.creditCard;

  const brand = get(CARD_BRANDS, creditCard.cardBrand, CARD_BRANDS.UNKNOWN);
  const BrandImage = brand.image;

  const paymentStatus = get(PAYMENT_STATUSES, paymentDetails.status, undefined);

  return (
    <Box>
      <Box mb={3}>
        <Typography variant="subtitle1">{t('paymentMethod.title')}</Typography>
      </Box>
      <Grid container>
        <Grid item xs={2}>
          <BrandImage height="40px" />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            <strong>{brand.name}</strong>
          </Typography>
          <Box color={grey[200]}>
            <Typography variant="body2">
              **** {creditCard.lastDigits}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          {paymentStatus && (
            <Chip
              classes={{
                root: classes.chipRoot
              }}
              label={t(paymentStatus.label)}
              icon={paymentStatus.icon}
              style={{
                backgroundColor: paymentStatus.backgroundColor,
                color: paymentStatus.color,
                float: 'right'
              }}
              size="small"
            />
          )}
        </Grid>
        {isPaymentStatusCanceled(paymentDetails.status) && (
          <Grid item xs={12}>
            <Box
              style={{
                borderRadius: '15px',
                backgroundColor: grey[50]
              }}
              p={2}
              mt={2}
            >
              <Typography variant="body2">
                {t('paymentMethod.paymentMessage.canceled')}
              </Typography>
            </Box>
          </Grid>
        )}
        {hasBillingInvoices(billingInvoice) && (
          <Grid item xs={12}>
            <Box pb={1} pt={2} width="100%">
              <Button
                textColor={blue[500]}
                variant="outlined"
                fullWidth
                href={getDocumentLink(billingInvoice)}
                label={t('paymentMethod.buttonDownloadBillingInvoices.text')}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

PaymentDetails.propTypes = {
  paymentDetails: PropTypes.shape({
    paymentDocument: PropTypes.shape({
      creditCard: PropTypes.shape({
        cardBrand: PropTypes.string.isRequired,
        lastDigits: PropTypes.string.isRequired
      })
    }).isRequired,
    status: PropTypes.string.isRequired
  }).isRequired,
  billingInvoice: PropTypes.arrayOf(
    PropTypes.shape({
      cteUrl: PropTypes.string,
      nfeUrl: PropTypes.string
    })
  )
};

PaymentDetails.defaultProps = { billingInvoice: [] };

export default PaymentDetails;
