import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteLoading } from 'UI/components';

const SupportLoading = () => {
  const { t } = useTranslation('ui');
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <InfiniteLoading
        title={t('support.loading.title')}
        description={t('support.loading.description')}
      />
    </Box>
  );
};

export default SupportLoading;
