import { DESKTOP_STYLES } from 'constants/desktop.constants';
import { grey } from 'theme/colors';

const containerStyle = {
  width: DESKTOP_STYLES.maxWidth,
  position: DESKTOP_STYLES.containerPosition,
  margin: '0 auto',
  bgColor: grey[75],
  overflowY: 'auto'
};

const smallScreenStyle = {
  marginTop: '24px',
  marginBottom: '24px',
  height: 'calc(100vh - 48px)'
};

const getStyle = (isDesktop, isSmallScreen) => {
  if (isDesktop) {
    if (isSmallScreen) {
      return { ...containerStyle, ...smallScreenStyle };
    }
    return containerStyle;
  }
  return {};
};

export default getStyle;
