import * as Sentry from '@sentry/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { loggiDesignSystem as theme } from '@loggi/front-design-system';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import useCoupon from 'UI/shipment/hooks/coupon';
import useServiceQuoting from 'UI/shipment/hooks/service-quoting';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { sendShipmentSentEventsToCrm } from 'crm/utils';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { useWaitingPickup } from 'hooks/waiting-pickups/waiting-pickup.hook';
import components from 'theme/components';

import { AmateurQuoting } from 'models';
import { accountProblem } from 'routes/routes';
import { fillCompanyId } from 'utils';

import Loader from 'UI/components/loader/loader.component';
import useCreateShipmentOrder from 'UI/shipment/hooks/create-shipment-order';
import {
  MAX_CREATE_ORDER_ATTEMPTS,
  PIX,
  RECHARGE_STATUS
} from 'UI/shipment/pages/payment/constants';
import { PaymentError } from 'UI/shipment/pages/payment/payment-error/payment-error';
import { LoadingRecharge } from 'UI/shipment/pages/wallet/loading-recharge/loading-recharge.component';
import {
  ShippingPayloadBuilder,
  isIndespacho
} from 'UI/shipment/state-machine/utils';
import ShipmentCreationFailed from 'crm/entities/events/shipment-creation-failed/shipment-creation-failed';
import useWalletRechargeStatus from 'hooks/wallet-recharge-status/wallet-recharge-status.hook';

import AttemptCreateOrderLimitDrawer from 'UI/shipment/components/attempt-create-order-limit-drawer/attempt-create-order-limit-drawer.component';
import { useSafeAreaInsetTop } from 'theme/safeAreaInsetTop';

import { Loading } from '../checkout/loading/loading.component';
import TermsOfService from '../payment/terms-of-service/terms-of-service';
import { buildMachinePayload } from '../utils';
import AdyenPixPayment from './adyen-pix-payment/adyen-pix-payment';
import { CancelShipmentDrawer } from './cancel-shipment-drawer/cancel-shipment-drawer';
import { PixPaymentInstructions } from './pix-payment-instructions/pix-payment-instructions';

/**
 * Necessary for our drawer to continue
 * respecting the maxWidth stipulated in
 * our general theme
 */
const addGeneralDrawerComponentsTheme = () => {
  Object.assign(theme.components, { MuiDrawer: components.MuiDrawer });
};

const sendShipmentCreationFailedToCrm = (error, { context, payload }) => {
  ShipmentCreationFailed.fromShipmentContext({
    context,
    price: payload?.['payment_data']?.amount,
    rechargeId: payload?.rechargeId,
    coupon: payload?.coupon,
    error
  }).sendToCrm();
};

export default function PixPaymentPage() {
  addGeneralDrawerComponentsTheme();
  const { t } = useTranslation('ui');
  const safeAreaInsetTop = useSafeAreaInsetTop();
  const { companyId } = useParams();
  const history = useHistory();
  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const quoting = useServiceQuoting();
  const couponQuery = useCoupon();
  const [isCancelDrawerOpen, setIsCancelDrawerOpen] = useState(false);
  const [isCreateOrderPending, setIsCreateOrderPending] = useState(false);
  const [shippingApiError, setShippingApiError] = useState(null);
  const [currentRechargeId, setCurrentRechargeId] = useState(null);
  const [countCreateOrderFails, setCountCreateOrderFails] = useState(0);
  const hasCurrentPixPaymentSession = useMemo(
    () => JSON.parse(window.localStorage.getItem('pix_payment_session')),
    []
  );
  const [isEnabledPolling, setIsEnabledPolling] = useState(
    !!hasCurrentPixPaymentSession?.paymentSession?.rechargeId
  );
  const { data: maximumAtteptsLimit } = useFeatureSwitch(
    'maximum_attempt_create_order_limit',
    MAX_CREATE_ORDER_ATTEMPTS
  );

  const rechargeStatusQuery = useWalletRechargeStatus({
    companyId,
    rechargeId:
      hasCurrentPixPaymentSession?.paymentSession?.rechargeId ??
      currentRechargeId,
    enabled: isEnabledPolling
  });

  const isIndispatchServiceType = isIndespacho(context.serviceType);

  const maximumAtteptsReached = countCreateOrderFails >= maximumAtteptsLimit;

  let amount = context.rechargeValue ?? quoting?.price;

  if (couponQuery.data) {
    amount = couponQuery.data.amountWithAppliedDiscount;
  }

  const handleCancelShipment = () => {
    window.localStorage.removeItem('pix_payment_session');
    shipmentDispatcher.cancelPixPayment();
  };

  const enableProblemPageFS = useFeatureSwitch(
    'enable_problem_page_at_shipment_order',
    []
  );

  const problemPageEnabled =
    enableProblemPageFS.data === '*' ||
    enableProblemPageFS.data.map(String).includes(String(companyId));

  const createShipmentOrder = useCreateShipmentOrder({
    onMutate: sendShipmentSentEventsToCrm,
    onError: sendShipmentCreationFailedToCrm
  });

  const waitingPickup = useWaitingPickup(companyId);

  const handleException = (ex, machinePayload) => {
    Sentry.captureException(ex);
    Sentry.setContext('payload', machinePayload);

    if (problemPageEnabled && ex?.status === 403) {
      shipmentDispatcher.clear();

      history.push(
        fillCompanyId({
          companyId,
          route: accountProblem
        })
      );
    } else {
      setShippingApiError(ex ?? {});
    }
  };

  const createOrder = async () => {
    if (isCreateOrderPending || maximumAtteptsReached) return;

    const paymentMethod = { type: 'wallet' };
    const payloadBuilder = new ShippingPayloadBuilder()
      .setShipmentContext(context)
      .setQuoting(quoting)
      .setCoupon(couponQuery.data)
      .setPayment(paymentMethod);

    let machinePayload = buildMachinePayload({
      context,
      paymentMethod,
      payloadBuilder,
      couponQuery,
      quoting
    });

    if (currentRechargeId) {
      machinePayload = { ...machinePayload, rechargeId: currentRechargeId };
    }

    try {
      setIsCreateOrderPending(true);

      const shipmentOrder = await createShipmentOrder.mutateAsync({
        companyId,
        payload: machinePayload,
        context
      });

      if (
        !AmateurQuoting.isIndespacho({
          serviceType: context.serviceType
        })
      ) {
        waitingPickup.setWaitingPickup(shipmentOrder?.pickupOrderSchedule);
      }

      shipmentDispatcher.orderCreated({
        ...shipmentOrder,
        rechargeId: currentRechargeId
      });
    } catch (ex) {
      setCountCreateOrderFails(prev => prev + 1);
      handleException(ex, machinePayload);
    } finally {
      setIsCreateOrderPending(false);
    }
  };

  const handleBalanceAdded = () => {
    const {
      payment_method: paymentMethod,
      current_balance: currentBalance,
      updated_at: updatedAt
    } = rechargeStatusQuery.data;

    const paymentDate = new Date(updatedAt);

    shipmentDispatcher.balanceSuccessfullyAdded({
      addedBalance: amount,
      rechargeId: currentRechargeId,
      paymentMethod,
      currentBalance,
      paymentDate
    });
  };

  const handlePaymentCompleted = rechargeId => {
    setCurrentRechargeId(rechargeId);
    setIsEnabledPolling(true);
  };

  const onError = rechargeStatus => {
    window.localStorage.removeItem('pix_payment_session');

    let payload = { paymentMethod: PIX };
    if (rechargeStatus === RECHARGE_STATUS.ERROR) {
      payload = { ...payload, addBalanceError: RECHARGE_STATUS.ERROR };
    }

    shipmentDispatcher.viewWalletBalanceError(payload);
  };

  const handleRechageStatusSuccess = () => {
    if (context?.rechargeValue) {
      handleBalanceAdded();
    } else {
      createOrder();
    }
  };

  useEffect(() => {
    const rechargeStatus = rechargeStatusQuery?.data?.['recharge_status'];

    if (rechargeStatus === RECHARGE_STATUS.SUCCESS && isEnabledPolling) {
      window.localStorage.removeItem('pix_payment_session');
      if (!currentRechargeId) {
        setCurrentRechargeId(
          hasCurrentPixPaymentSession?.paymentSession?.rechargeId
        );
      }
      setIsEnabledPolling(false);
      handleRechageStatusSuccess();
    }
    if (
      rechargeStatusQuery?.isError ||
      rechargeStatus === RECHARGE_STATUS.ERROR ||
      rechargeStatus === RECHARGE_STATUS.CANCELED_BY_GATEWAY
    ) {
      setIsEnabledPolling(false);
      onError(RECHARGE_STATUS.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargeStatusQuery, isEnabledPolling]);

  if (!amount) {
    return <Loader />;
  }

  const isCreateOrderPendingLoaderVisible =
    !!currentRechargeId && !context?.rechargeValue;
  const isRechargeStatusLoadingVisible =
    !!currentRechargeId && !!context?.rechargeValue;

  const headerTopPadding = `calc(${safeAreaInsetTop} + ${
    spacing.stack.xxsmall
  })`;

  return (
    <>
      <ThemeProvider theme={theme}>
        {!!shippingApiError && (
          <PaymentError
            error={shippingApiError}
            amount={amount}
            paymentMethod={PIX}
            onRetry={createOrder}
            onCancel={() => shipmentDispatcher.clear()}
          />
        )}
        <Box>
          <Box p={spacing.stack.xxsmall} paddingTop={headerTopPadding}>
            <Typography
              variant="headingMedium"
              color={colors.neutrals['on-surface']}
            >
              {t('pixPayment.title')}
            </Typography>
          </Box>

          <AdyenPixPayment
            amount={amount}
            companyId={companyId}
            handlePaymentCompleted={handlePaymentCompleted}
            onError={onError}
          />

          <Divider />
          <PixPaymentInstructions />
          <Stack margin={spacing.stack.xxsmall}>
            <Button
              variant="outlined"
              onClick={() => setIsCancelDrawerOpen(true)}
              data-testid="cancel-payment-btn"
            >
              {t('pixPayment.buttons.cancelPayment')}
            </Button>
            {!isIndispatchServiceType && <TermsOfService />}
          </Stack>
          <Box>
            <CancelShipmentDrawer
              handleCancelShipment={handleCancelShipment}
              isOpen={isCancelDrawerOpen}
              onClose={() => setIsCancelDrawerOpen(false)}
            />
          </Box>
        </Box>
        <LoadingRecharge isVisible={isRechargeStatusLoadingVisible} />
      </ThemeProvider>
      <AttemptCreateOrderLimitDrawer
        isVisible={maximumAtteptsReached}
        onClose={() => setCountCreateOrderFails(prev => prev - 1)}
      />
      <Loading isVisible={isCreateOrderPendingLoaderVisible} />
    </>
  );
}
