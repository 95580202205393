import { useQuery } from '@tanstack/react-query';
import { CorreiosAddress } from 'UI/shipment/models';
import cepApi from 'service/cep-api';

async function fetcher({ postalCode }) {
  const data = await cepApi.getCepInfos({ cep: postalCode });

  return new CorreiosAddress({
    cep: data.cep.replace(/\D/g, ''),
    logradouro: data.logradouro,
    uf: data.uf,
    cidade: data.localidade,
    bairro: data.bairro
  });
}

/**
 * Resolves a postal code to a CorreiosAddress
 *
 * @param {string} postalCode - The postal code to be resolved
 */
export default function usePostalCodeResolver(postalCode) {
  const cep = postalCode?.replace(/\D/g, '');
  const isValid = cep?.length === 8;

  return useQuery(['cep', postalCode], () => fetcher({ postalCode }), {
    enabled: isValid,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: 2,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false
  });
}
