import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';
import { Icon } from '@loggi/loggi-icons';
import { borders, colors, spacing } from '@loggi/front-design-tokens';

export default function OptionItem({ title, description, onClick }) {
  return (
    <Stack
      data-testid="add-balance-option-item"
      p={spacing.inset.large}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={`${borders.width.thin} solid ${
        colors.neutrals['outline-low']
      }`}
      onClick={onClick}
    >
      <Stack gap={spacing.stack.nano}>
        <Typography
          variant="subtitleMedium"
          color={colors.neutrals['on-surface']}
        >
          {title}
        </Typography>
        <Typography
          variant="bodyTextMedium"
          color={colors.neutrals['on-surface']}
        >
          {description}
        </Typography>
      </Stack>
      <Icon
        name="ChevronRight"
        size="small"
        color={colors.neutrals['on-surface']}
      />
    </Stack>
  );
}

OptionItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
