import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue, root } from 'theme/colors';
import { SvgIcon } from '../svg-icon/svg-icon.component';
import BaseDrawer from '../base-drawer/base-drawer.component';

const ShipmentCanceledDrawer = ({ onClose, open }) => {
  const { t } = useTranslation('ui');

  return (
    <BaseDrawer
      isOpen={open}
      labels={{ confirm: t('shipmentCanceledDrawer.button') }}
      onConfirmClick={onClose}
      onSwipeClose={onClose}
      hasPuller
    >
      <Box
        alignItems="center"
        color={root[900]}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={3}
      >
        <SvgIcon color={blue[500]} icon="checkCircle" size="144" />
        <Box my={3}>
          <Typography align="center" variant="subtitle1">
            <Box fontSize="22px">{t('shipmentCanceledDrawer.title')}</Box>
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography align="center" variant="subtitle2">
            {t('shipmentCanceledDrawer.description')}
          </Typography>
        </Box>
      </Box>
    </BaseDrawer>
  );
};

ShipmentCanceledDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default ShipmentCanceledDrawer;
