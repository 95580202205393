import React, { useState } from 'react';
import { useSnackbar } from 'hooks';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import PostalCodeInput from 'UI/components/input/postal-code-input';
import Footer from 'UI/shipment/components/shipment-footer';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import useAmateurAddressCoverage from 'UI/shipment/hooks/amateur-address-coverage';
import { useTranslation } from 'react-i18next';
import { InputAddress, CorreiosAddress } from 'UI/shipment/models';
import { isGroupingShipmentInPickup } from 'UI/shipment/state-machine/utils';
import ShipmentAddressByCepQuotationFailed from 'crm/entities/events/shipment-address-by-cep-quotation-failed/shipment-address-by-cep-quotation-failed';
import ShipmentAddressByCepQuotationSent from 'crm/entities/events/shipment-address-by-cep-quotation-sent/shipment-address-by-cep-quotation-sent';
import handleSnackbarError from './postal-code-addresses.helper';

/**
 * Renders the postal code addresses page. This page is
 * used to set the origin and destination addresses based
 * only on the postal code.
 */
export default function PostalCodeAddressesPage() {
  const { t } = useTranslation('ui');
  const showSnackbar = useSnackbar();
  const { context } = useShipmentContext();
  const dispatcher = useShipmentDispatcher();
  const [origin, setOrigin] = useState(
    context.originAddress?.correios.cep || ''
  );
  const [destination, setDestination] = useState(
    context.destinationAddress?.correios.cep || ''
  );

  const sendShipmentQuotationFailedEvent = ({ errorStatus, errorMessage }) => {
    new ShipmentAddressByCepQuotationFailed({
      cepOrigin: origin,
      cepDestination: destination,
      errorStatus,
      errorMessage
    }).sendToCrm();
  };

  const coverageQuery = useAmateurAddressCoverage({
    address: context.originAddress,
    onSuccess(coverage) {
      if (!coverage.serviceTypes.length) {
        handleSnackbarError(
          showSnackbar,
          t('postalCodeAddresses.errorMessages.postalCodeNotFound'),
          () => {
            dispatcher.setOriginAddress(null);
            coverageQuery.remove();
          }
        );
      }
    },
    onError(error) {
      sendShipmentQuotationFailedEvent({
        errorStatus: error?.status,
        errorMessage: t('postalCodeAddresses.errorMessages.postalCodeNotFound')
      });

      handleSnackbarError(
        showSnackbar,
        t('postalCodeAddresses.errorMessages.postalCodeNotFound'),
        () => {
          dispatcher.setOriginAddress(null);
          coverageQuery.remove();
        }
      );
    }
  });

  const enableContinue =
    origin &&
    origin.length === 8 &&
    destination &&
    destination.length === 8 &&
    coverageQuery.isSuccess &&
    coverageQuery.data.serviceTypes.length > 0;

  const handleSubmit = () => {
    new ShipmentAddressByCepQuotationSent({
      cepOrigin: origin,
      cepDestination: destination
    }).sendToCrm();

    if (origin === destination) {
      sendShipmentQuotationFailedEvent({
        errorMessage: t('postalCodeAddresses.errorMessages.postalCodesEqual')
      });

      handleSnackbarError(
        showSnackbar,
        t('postalCodeAddresses.errorMessages.postalCodesEqual')
      );
      return;
    }

    dispatcher.continue();
  };

  const onClickQuotationWithAddress = () => {
    return dispatcher.clickQuotationWithAddress();
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1">
          {t('postalCodeAddresses.title')}
        </Typography>
      </Box>
      <Box>
        <PostalCodeInput
          id="origin"
          label={t('postalCodeAddresses.origin.label')}
          value={origin}
          disabled={isGroupingShipmentInPickup(context)}
          onChange={setOrigin}
          onValid={postalCode =>
            dispatcher.setOriginAddress(
              new InputAddress({
                correios: new CorreiosAddress({ cep: postalCode })
              })
            )
          }
          endAdornment={
            coverageQuery.isFetching && <CircularProgress size={20} />
          }
        />
        <Box mt={2} />
        <PostalCodeInput
          id="destination"
          label={t('postalCodeAddresses.destination.label')}
          value={destination}
          onChange={setDestination}
          onValid={postalCode =>
            dispatcher.setDestinationAddress(
              new InputAddress({
                correios: new CorreiosAddress({ cep: postalCode })
              })
            )
          }
        />
        <Box pt={5}>
          <Button
            onClick={onClickQuotationWithAddress}
            data-testid="fill-with-address"
          >
            {t('postalCodeAddresses.quotationWithAddressButton')}
          </Button>
        </Box>
      </Box>

      <Footer>
        <Footer.PrimaryAction
          onClick={handleSubmit}
          disabled={!enableContinue}
        />
      </Footer>
    </>
  );
}
