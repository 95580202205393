import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue, root } from 'theme/colors';
import InfoLine from '../info-line/info-line.component';

const buttonStyle = {
  backgroundColor: blue[500],
  color: root[0],
  display: 'flex',
  fontWeight: 700,
  justifyContent: 'center',
  padding: '12px',
  width: 'fit-content',
  borderRadius: '8px'
};

const IndispatchDeliveryInfo = () => {
  const { t } = useTranslation('ui');

  const redirectToCorreiosAgenciesPage = ev => {
    ev.stopPropagation();
    const url = process.env.REACT_APP_CORREIOS_TRACKING_URL;
    window.open(url, '_blank', 'noopener');
  };

  return (
    <Box bgcolor={root[0]} display="flex" flexDirection="column">
      <InfoLine icon="pin">
        <Typography>{t('indispatchDeliveryInfo.description')}</Typography>
        <Box display="flex" style={{ marginTop: '8px' }}>
          <Button
            fullWidth
            onClick={redirectToCorreiosAgenciesPage}
            style={buttonStyle}
          >
            <span style={{ padding: '0px 16px' }}>
              {t('indispatchDeliveryInfo.button')}
            </span>
          </Button>
        </Box>
      </InfoLine>
    </Box>
  );
};

export default IndispatchDeliveryInfo;
