/* eslint-disable react/no-array-index-key */
import { List, ListItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * take a list of translations keys or a translation key to a list
 * @param {object} props
 * @param {string|string[]} props.listDescriptions - 18n key of a list, or list of i18n translation keys
 * @returns
 */
export const AboutServiceListDescriptions = ({ listDescriptions }) => {
  const { t } = useTranslation('ui');

  const isKey = typeof listDescriptions === 'string';
  const listDescriptionsTranslated = isKey
    ? t(listDescriptions, { returnObjects: true })
    : listDescriptions.map(itemKey => t(itemKey));

  return (
    <List sx={{ listStyleType: 'disc', pl: 3, my: 2 }}>
      {listDescriptionsTranslated.map((item, index) => (
        <ListItem
          key={index}
          disableGutters
          sx={{ display: 'list-item', padding: 0 }}
        >
          <Typography variant="caption2">{item}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

AboutServiceListDescriptions.propTypes = {
  listDescriptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default { AboutServiceListDescriptions };
