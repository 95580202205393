import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Stack, Button } from '@mui/material';

export const CorreiosTrackingInfo = ({
  correiosTrackingCode,
  trackingCode,
  alreadyPosted = false
}) => {
  const { t } = useTranslation('ui');

  const redirectToCorreiosTrackingPage = ev => {
    ev.stopPropagation();
    const url = process.env.REACT_APP_CORREIOS_TRACKING_URL;
    window.open(url, '_blank', 'noopener');
  };

  return (
    <Box pb={spacing.inset.large}>
      <Box px={spacing.inset.large} pt={spacing.inset.large}>
        <Stack gap={spacing.stack.xxxsmall}>
          <Typography variant="subtitleLarge">
            {alreadyPosted
              ? t('shipmentDetailsV2.correiosTrackingInfo.titlePosted')
              : t('shipmentDetailsV2.correiosTrackingInfo.title')}
          </Typography>
          {correiosTrackingCode && (
            <Stack>
              <Typography variant="bodyTextMedium">
                {t('shipmentDetailsV2.correiosTrackingInfo.trackingCode')}
              </Typography>
              <Typography variant="headingMedium">
                {correiosTrackingCode}
              </Typography>
            </Stack>
          )}
          <Typography variant="subtitleMedium">
            {t('shipmentDetailsV2.correiosTrackingInfo.loggiTrackingCode', {
              trackingCode
            })}
          </Typography>
        </Stack>
      </Box>
      <Box pl={spacing.inset.small} pt={spacing.stack.xxxsmall}>
        <Button
          onClick={redirectToCorreiosTrackingPage}
          size="medium"
          variant="text"
        >
          {t('shipmentDetailsV2.correiosTrackingInfo.button')}
        </Button>
      </Box>
    </Box>
  );
};

CorreiosTrackingInfo.defaultProps = {
  alreadyPosted: false
};

CorreiosTrackingInfo.propTypes = {
  correiosTrackingCode: PropTypes.string.isRequired,
  trackingCode: PropTypes.string.isRequired,
  alreadyPosted: PropTypes.bool
};

export default CorreiosTrackingInfo;
