import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Icon } from '@loggi/loggi-icons';
import { useTranslation } from 'react-i18next';

export const LoadingWalletCard = ({ onReload }) => {
  const { t } = useTranslation('ui');

  return (
    <Stack gap={spacing.stack.xxxsmall}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="subtitleSmall"
          color={colors.neutrals['on-surface']}
        >
          {t('walletCard.loggiBalance')}
        </Typography>
        <Stack
          data-testid="refresh-icon"
          onClick={e => {
            e.stopPropagation();
            onReload();
          }}
        >
          <Icon
            name="Sync02"
            size="medium"
            color={colors.neutrals['on-surface']}
          />
        </Stack>
      </Stack>
      <Stack
        backgroundColor={colors.neutrals['surface-container-low']}
        width={spacing.inline.huge}
        height={spacing.stack.xxsmall}
      />
    </Stack>
  );
};

LoadingWalletCard.propTypes = {
  onReload: PropTypes.func.isRequired
};

export default { LoadingWalletCard };
