import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { grey } from 'theme/colors';

const IconCircleContainer = ({ bg, children }) => (
  <Box
    alignItems="center"
    bgcolor={bg}
    borderRadius="50%"
    display="flex"
    height="40px"
    justifyContent="center"
    width="40px"
  >
    {children}
  </Box>
);

IconCircleContainer.defaultProps = {
  bg: grey[50]
};

IconCircleContainer.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string
};

export default IconCircleContainer;
