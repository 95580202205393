import { Box, Container, Typography } from '@mui/material';
import { ReactComponent as BoxesIllustration } from 'assets/boxes.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { login } from 'routes/routes';
import { blue, brand, root } from 'theme/colors';
import { Button } from 'UI/components';

export const AccessDenied = () => {
  const { t } = useTranslation('ui');

  return (
    <Box
      display="flex"
      height="100vh"
      style={{
        backgroundImage: `linear-gradient(90deg, ${brand[100]}, ${blue[500]})`
      }}
      color={root[0]}
    >
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          height={1}
          pt={10}
          justifyContent="space-between"
        >
          <Box mt={5}>
            <BoxesIllustration />
            <Box mt={2}>
              <Typography component="h2" variant="subtitle2">
                {t('accessDenied.title')}
              </Typography>
            </Box>
          </Box>
          <Box flexDirection="column" my={3}>
            <Button
              to={login}
              fullWidth
              label={t('accessDenied.backButton')}
              size="lg"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default { AccessDenied };
