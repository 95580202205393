import { useState } from 'react';
import { useDomainUseCase } from 'UI/contexts';

export const useCancelation = ({ onSuccess, onError }) => {
  const [isLoading, setLoading] = useState(false);
  const cancelShippingUseCase = useDomainUseCase('cancelShippingUseCase');

  const cancel = async ({ companyId, loggiKey }) => {
    setLoading(true);
    try {
      await cancelShippingUseCase(companyId, loggiKey);
      onSuccess();
    } catch (_err) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  return {
    cancel,
    isLoading
  };
};

export const useCancelationDrawer = ({ onConfirm }) => {
  const [isShowing, setShow] = useState(false);

  const show = () => setShow(true);
  const dismiss = () => setShow(false);
  const confirm = () => {
    dismiss();
    onConfirm();
  };

  return { isShowing, show, dismiss, confirm };
};

export const useConfirmationDrawer = ({ onClose }) => {
  const [isShowing, setShow] = useState(false);

  const show = () => setShow(true);
  const dismiss = () => {
    setShow(false);
    onClose();
  };

  return { isShowing, show, dismiss };
};

export default { useCancelation, useCancelationDrawer, useConfirmationDrawer };
