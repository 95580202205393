import { add, isBefore } from 'date-fns';
import {
  isUsingInputAddress,
  machineContextToShipmentPayload
} from '../state-machine/utils';

export const checkScheduleTimeAvailability = (
  pickupAddressCoverage,
  context
) => {
  const now = new Date();

  const { hours, minutes } =
    pickupAddressCoverage.data?.payload.initialSlot ?? {};
  const minCollectionTimeWindow = add(now, { hours, minutes });

  return isBefore(minCollectionTimeWindow, context.pickupSchedule[0]);
};

export const buildMachinePayload = ({
  paymentMethod,
  payloadBuilder,
  context,
  quoting,
  couponQuery
}) => {
  return isUsingInputAddress(context)
    ? payloadBuilder.build()
    : machineContextToShipmentPayload({
        ...context,
        payment: paymentMethod,
        estimate: quoting,
        coupon: couponQuery?.data
      });
};

export const hasAddressLengthExceeded = context => {
  const MAX_ADDRESS_LENGTH = 256;
  const {
    pickupAddress,
    deliveryAddress,
    destinationAddress,
    originAddress
  } = context;

  return (
    pickupAddress?.description?.length > MAX_ADDRESS_LENGTH ||
    deliveryAddress?.description?.length > MAX_ADDRESS_LENGTH ||
    destinationAddress?.description()?.length > MAX_ADDRESS_LENGTH ||
    originAddress?.description()?.length > MAX_ADDRESS_LENGTH
  );
};
