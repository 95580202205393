import { useQuery } from '@tanstack/react-query';
import { captureException } from '@sentry/react';
import PaymentSession from 'models/payment-session.model';
import walletApi from 'service/wallet-api';

const getQueryKey = (companyId, amount) => [
  'wallet-payment-session',
  String(companyId),
  amount
];

export default function useWalletPaymentSession({ companyId, amount }) {
  const key = getQueryKey(companyId, amount);

  return useQuery(
    key,
    async () => {
      const result = await walletApi.generateSessionRecharge({
        companyId,
        amount
      });
      return PaymentSession.parseApiResponse(result);
    },
    {
      enabled: !!companyId && !!amount,
      refetchOnWindowFocus: false,
      retry: false,
      onError(err) {
        captureException(err);
      }
    }
  );
}
