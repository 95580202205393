import Validator from '../validator';

const isNumeric = string => !Number.isNaN(Number(string));

const isAverageTicketValid = ticketNumber => {
  if (typeof ticketNumber !== 'string') return false;
  if (ticketNumber.trim() !== '' && !isNumeric(ticketNumber)) return false;

  return Number(ticketNumber) > 0;
};

export default class AverageTicketValidator extends Validator {
  static validate(string) {
    return isAverageTicketValid(string);
  }
}
