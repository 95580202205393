import { useAmplifyAuth } from '@loggi/authentication-lib';

/**
 * gets authenticated user from Amplify cognito
 * @returns {{
 * companyAccess: [],
 * name: string,
 * email: string,
 * sub: any,
 * userId: string,
 * phone: string,
 * cpf: string
 * }}
 */
const useCurrentUser = () => {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  if (!authenticatedUser) return null;

  const userAccess = JSON.parse(
    authenticatedUser['custom:access'] ||
      authenticatedUser?.challengeParam?.userAttributes['custom:access'] ||
      null
  );
  const companyAccess = userAccess?.companies || [];

  return { ...authenticatedUser, companyAccess };
};

export default useCurrentUser;
