import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class MenuOptionSelected extends CRMBaseEvent {
  constructor({ buttonOption }) {
    super();
    this.eventName = EVENTS.MENU_OPTION_SELECTED;
    this.payload = {
      button_option: buttonOption
    };
  }
}
