export const createInitialStateFromFieldValues = fields => {
  return Object.fromEntries(
    Object.keys(fields).map(fieldKey => [fieldKey, fields[fieldKey].value])
  );
};

export const getInitialStep = ({ fields, initialStep }) => {
  if (initialStep) return initialStep;
  const furtherValidStep = Object.entries(fields).find(
    ([, { validator, value }]) => {
      if (!validator || !value) return true;
      return !validator(value);
    }
  );
  const fieldsKeys = Object.keys(fields);
  if (!furtherValidStep) return fieldsKeys.length - 1;
  const [furtherValidStepKey] = furtherValidStep;
  return fieldsKeys.findIndex(stepKey => stepKey === furtherValidStepKey);
};
