/* eslint-disable prefer-destructuring */
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import getEstimate from 'domain/use-cases/get-estimate/get-estimate.use-case';
import { useShipmentContext } from '../state-machine/context';
import { usePackageDimensions, usePackagingService } from './estimate.helper';

/**
 * Queries the estimation for the selected pickup and delivery addresses.
 *
 *  - Defaults package size to PP if not selected.
 *  - Defaults packaging service to "NO_PACKAGING" if not selected.
 *
 * If indespacho is not enabled, we omit the indespacho services from the
 * response.
 *
 * @param {object} options
 * @param {(data: AmateurQuotingResponse) => void} options.onSuccess
 * @param {((error: Error) => void) | undefined} options.onError
 * @returns {import('@tanstack/react-query').UseQueryResult<AmateurQuotingResponse, Error>}
 */
export default function useEstimate({ onSuccess, onError } = {}) {
  const { context: shipmentContext } = useShipmentContext();
  const companyId = shipmentContext.companyId;
  const pickupAddress = shipmentContext.pickupAddress;
  const deliveryAddress = shipmentContext.deliveryAddress;
  const originAddress = shipmentContext.originAddress;
  const destinationAddress = shipmentContext.destinationAddress;
  const packageDimensions = usePackageDimensions(shipmentContext);
  const packagingService = usePackagingService(shipmentContext);
  const declaredValue = shipmentContext.insurance ?? undefined;
  const quotingServiceFilter = shipmentContext.serviceType
    ? [shipmentContext.serviceType]
    : undefined;

  return useQuery(
    [
      'quoting',
      companyId,
      pickupAddress?.placeId ?? originAddress?.correios.cep,
      deliveryAddress?.placeId ?? destinationAddress?.correios.cep,
      packagingService,
      packageDimensions,
      declaredValue,
      quotingServiceFilter
    ],
    async () =>
      getEstimate({
        companyId,
        pickupAddress,
        deliveryAddress,
        originAddress,
        destinationAddress,
        packageDimensions,
        packagingService,
        quotingServiceFilter,
        declaredValue
      }),
    {
      enabled:
        !!companyId &&
        ((!!originAddress && !!destinationAddress) ||
          (!!pickupAddress && !!deliveryAddress)),
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (onSuccess) onSuccess(data);
      },
      onError(err) {
        captureException(err);
        if (onError) onError(err);
      }
    }
  );
}
