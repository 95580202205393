import { useIsMobile } from 'utils';

const getSafeAreaInsetTop = () => {
  const div = document.createElement('div');
  document.body.appendChild(div);
  div.style.height = 'env(safe-area-inset-top)';
  const currSafeAreaInsetTop = getComputedStyle(div).height;
  document.body.removeChild(div);
  return currSafeAreaInsetTop;
};

// eslint-disable-next-line import/prefer-default-export
export const setSafeAreaInsetTop = () => {
  const safeAreaInsetTop = sessionStorage.getItem('safeAreaInsetTop');
  if (safeAreaInsetTop && safeAreaInsetTop !== '0px') {
    return;
  }
  sessionStorage.setItem('safeAreaInsetTop', getSafeAreaInsetTop());
};

/** @returns `0px` when is not on mobile */
export function useSafeAreaInsetTop() {
  const isMobile = useIsMobile();
  if (!isMobile) return '0px';

  const safeAreaInsetTop = sessionStorage.getItem('safeAreaInsetTop');
  if (!safeAreaInsetTop || safeAreaInsetTop === '0px') return '24px';

  return safeAreaInsetTop;
}
