import { getRemoteConfig } from '@loggi/components/src/one/remote-config';

export const getFsValueUseCase = async fsName => {
  const payload = await getRemoteConfig(fsName);
  const value = payload?.asString();
  try {
    return JSON.parse(value);
  } catch {
    return value ?? null;
  }
};

export default { getFsValueUseCase };
