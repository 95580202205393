import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Icon } from '@loggi/loggi-icons';
import { colors } from '@loggi/front-design-tokens';

export const ActionButtonsMobile = ({
  handleStatusFilterButtonClick,
  handleSortButtonClick
}) => {
  return (
    <>
      <IconButton
        onClick={() => handleStatusFilterButtonClick(true)}
        variant="blank"
        size="small"
        data-testid="filter-button"
      >
        <Icon
          name="Filter"
          color={colors.interaction['on-secondary']}
          size="small"
        />
      </IconButton>
      <IconButton
        onClick={() => handleSortButtonClick(true)}
        variant="blank"
        size="small"
        data-testid="sort-button"
      >
        <Icon
          name="SwitchVertical01"
          color={colors.interaction['on-secondary']}
          size="small"
        />
      </IconButton>
    </>
  );
};

ActionButtonsMobile.propTypes = {
  handleStatusFilterButtonClick: PropTypes.func.isRequired,
  handleSortButtonClick: PropTypes.func.isRequired
};

export default ActionButtonsMobile;
