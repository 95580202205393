import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'UI/components';
import { SHIPPING_QUALIFICATION_OPTION, USER_TYPE } from 'crm/constants';
import AccountShipmentQualificationInformed from 'crm/entities/events/account-shipment-qualification-informed/account-shipment-qualification-informed';
import { blue, root, grey } from 'theme/colors';
import { useUserRecords } from 'UI/contexts';
import CRMUser from 'crm/entities/user/crm-user.model';

const UsuallySendStepForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(userRecords.usuallySend);
  }, [userRecords.usuallySend]);

  const handleSubmit = () => {
    const { userType: actualUserType } = userRecords;
    let userType = actualUserType;
    if (selectedOption === SHIPPING_QUALIFICATION_OPTION.SOU_ENTREGADOR) {
      userType = USER_TYPE.DRIVER;
    }

    setUserRecords({
      ...userRecords,
      usuallySend: selectedOption,
      userType
    });

    new AccountShipmentQualificationInformed({
      ...userRecords,
      userType,
      document: userRecords?.cnpj || userRecords?.cpf,
      shipmentQualification: selectedOption
    }).sendToCrm();

    new CRMUser({
      email: userRecords?.email,
      shipmentQualification: selectedOption,
      userType
    }).sendToCrm();

    handleOnNextStep();
  };

  const options = [
    {
      label: t('signUpCompany.usuallySend.options.first'),
      value: SHIPPING_QUALIFICATION_OPTION.ENVIO_PRODUTOS_QUE_VENDO
    },
    {
      label: t('signUpCompany.usuallySend.options.second'),
      description: t('signUpCompany.usuallySend.options.secondDescription'),
      value: SHIPPING_QUALIFICATION_OPTION.ENVIO_ITENS_PARA_CONHECIDOS
    },
    {
      label: t('signUpCompany.usuallySend.options.third'),
      description: t('signUpCompany.usuallySend.options.thirdDescription'),
      value: SHIPPING_QUALIFICATION_OPTION.ENVIO_CORPORATIVO
    },
    {
      label: t('signUpCompany.usuallySend.options.fourth'),
      value: SHIPPING_QUALIFICATION_OPTION.QUERO_RASTREAR
    },
    {
      label: t('signUpCompany.usuallySend.options.fifth'),
      value: SHIPPING_QUALIFICATION_OPTION.SOU_ENTREGADOR
    }
  ];

  return (
    <Box p={3}>
      <Typography fontWeight="500" variant="subtitle1">
        {t('signUpCompany.usuallySend.title')}
      </Typography>
      <Box display="flex" py={2}>
        <Box flex={1}>
          {options.map(option => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              minHeight="80px"
              mt={1}
              p={2}
              key={option?.label}
              border={`2px solid ${
                selectedOption === option.value ? blue[500] : grey[50]
              }`}
              borderRadius="12px"
              onClick={() => setSelectedOption(option.value)}
            >
              <Typography
                fontWeight="500"
                fontSize="10px"
                variant="body2"
                color={root[900]}
              >
                {option.label}
              </Typography>
              <Typography
                textAlign="center"
                fontWeight="500"
                variant="caption"
                color={grey[200]}
              >
                {option.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        style={{ position: 'absolute', bottom: '0', left: '0', width: '100%' }}
        p={3}
      >
        <Button
          fullWidth
          disabled={!selectedOption}
          label={t('signUpCompany.submitButton.label')}
          onClick={() => handleSubmit()}
        />
      </Box>
    </Box>
  );
};

UsuallySendStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default UsuallySendStepForm;
