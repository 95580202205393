import React from 'react';
import { omit } from 'lodash';

const defaultState = {
  __commited: false,
  __errors: {}
};

function formReducer(state, { type, payload }) {
  switch (type) {
    case 'SET_VALUE':
      return { ...state, [payload.key]: payload.value };
    case 'SET_ERRORS':
      return { ...state, __errors: payload };
    case 'COMMITED':
      return { ...state, __commited: true };
    case 'RESET':
      return { ...state, ...defaultState };
    default:
      return state;
  }
}

export default function useFormManager({ fields, onSubmit }) {
  const initialValues = Object.entries(fields).reduce(
    (acc, [fieldName, field]) => ({
      ...acc,
      [fieldName]: field.defaultValue
    }),
    {}
  );
  const [state, dispatch] = React.useReducer(formReducer, {
    ...defaultState,
    ...initialValues
  });
  const values = omit(state, ['__errors', '__commited']);
  const errors = state.__errors;
  const isValid = Object.keys(errors).length === 0;
  const isCommited = state.__commited;

  function resetForm() {
    dispatch({ type: 'RESET' });
  }

  function setValue(fieldName, value) {
    // clear errors automatically
    if (!isValid && isCommited) resetForm();
    dispatch({ type: 'SET_VALUE', payload: { key: fieldName, value } });
  }

  function commit() {
    const _errors = {};
    Object.entries(fields).forEach(([fieldName, field]) => {
      if (!field.validator) return;
      const validation = field.validator(values[fieldName]);
      if (!validation.isValid) {
        _errors[fieldName] = validation.errorMessage;
      }
    });

    if (Object.keys(_errors).length === 0) {
      onSubmit(values);
    }
    dispatch({ type: 'SET_ERRORS', payload: _errors });
    dispatch({ type: 'COMMITED' });
  }

  return {
    values,
    errors,
    isValid,
    setValue,
    resetForm,
    commit
  };
}
