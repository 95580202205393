/* eslint-disable camelcase */
import {
  getCreationMethod,
  getFirstName,
  getLastName,
  formatPhoneNumber
} from 'crm/utils';
import { ACCESS_EMAIL_SIGNUP } from 'UI/pages/sign-up/user/form/user-credentials-form.component';
import { COMPANY_SEGMENT_PROTO_VALUES as SEGMENT_DICT } from '@loggi/components/src/one/segments';
import CRMBaseEntity from 'crm/entities/crm-base-entity.model';
import EVENTS from 'crm/constants';
import { getFsValueUseCase } from 'domain/use-cases';

/**
 * Represents a CRM User with specific attributes and capabilities to be mainly
 * used with CRMService.
 *
 * This class encapsulates the data structure and transformations required for
 * representing a user in the CRM context.
 *
 * New attributes can be added to this class as needed.
 *
 * @property {string} eventName - The name of the event associated with the user.
 * @property {Object} payload - The payload containing user information.
 */
export default class CRMUser extends CRMBaseEntity {
  constructor(payload) {
    super();
    this.eventName = EVENTS.SEND_USER;
    this.payload = this.formatPayload(payload);
  }

  formatPayload(payload = this.payload) {
    const {
      email,
      userId,
      phone,
      customAccess,
      companyAccess,
      cognitoGroups,
      firstName,
      lastName,
      gender,
      companyId,
      userType,
      registrationMethod,
      document,
      accountCreatedDate,
      cep,
      averageTicket,
      sharedName,
      noL4BStatus,
      integrators,
      otherIntegrator,
      segment,
      estimatedMonthlyPackages,
      shipmentQualification,
      volumeQualification,
      volumeQualificationString,
      phoneFromValidationBase
    } = payload;

    return {
      email,
      user_id: userId ? String(userId) : undefined,
      phone: formatPhoneNumber(phone),
      custom_access: customAccess,
      company_access: companyAccess,
      cognito_groups: cognitoGroups,
      first_name: firstName,
      last_name: lastName,
      email_optin: true,
      gender,
      company_id: companyId ? String(companyId) : undefined,
      user_type: userType,
      registration_method: getCreationMethod(registrationMethod) || undefined,
      document,
      account_created_date: accountCreatedDate,
      cep,
      average_ticket: averageTicket ? Number(averageTicket) : undefined,
      shared_name: sharedName,
      no_l4b_status: typeof noL4BStatus === 'boolean' ? noL4BStatus : undefined,
      integrators,
      other_integrator: otherIntegrator,
      segment,
      estimated_monthly_packages: estimatedMonthlyPackages
        ? String(estimatedMonthlyPackages)
        : undefined,
      shipment_qualification: shipmentQualification
        ? String(shipmentQualification)
        : undefined,
      volume_qualification: volumeQualification,
      volume_qualification_string: volumeQualificationString,
      phone_from_validation_base: formatPhoneNumber(phoneFromValidationBase)
    };
  }

  /**
   * Factory method to create a CRMUser instance from a Cognito user representation.
   *
   * This method performs necessary transformations to adapt the Cognito user structure
   * into the CRMUser format.
   *
   * @param {Object} cognitoUser - User representation from Cognito.
   * @param {string} companyId - The ID of the company associated with the user.
   * @returns {CRMUser} A new instance of CRMUser.
   */
  static fromCognito(cognitoUser, companyId = undefined) {
    const {
      email,
      sub: userId,
      phone,
      cpf,
      'custom:access': customAccess,
      'cognito:groups': cognitoGroups,
      name,
      gender: genderJson,
      identities: [identity = {}] = [],
      companyAccess
    } = cognitoUser || {};

    const gender = genderJson ? JSON.parse(genderJson)[0]?.value : undefined;
    const { providerName, primary: isProviderPrimary } = identity;
    const registrationMethod =
      providerName && isProviderPrimary ? providerName : ACCESS_EMAIL_SIGNUP;

    return new CRMUser({
      email,
      userId,
      phone,
      cpf,
      customAccess,
      companyAccess,
      cognitoGroups,
      firstName: getFirstName(name),
      lastName: getLastName(name),
      gender,
      registrationMethod,
      companyId
    });
  }

  /**
   * Factory method to create a CRMUser instance from company signup data.
   *
   * @param {Object} companyData - The data from the company signup form.
   * @param {string} email - The email of the user.
   * @returns {CRMUser} A new instance of CRMUser.
   */
  static fromCompanySignup(companyData, email = undefined) {
    const {
      companyId,
      phone,
      cpf,
      cnpj,
      cep,
      averageTicket,
      sharedName,
      noL4BStatus,
      integrators,
      otherIntegrator,
      segment,
      estimatedMonthlyPackages,
      phoneFromValidationBase
    } = companyData;

    const segmentKey = Object.keys(SEGMENT_DICT).find(
      key => SEGMENT_DICT[key] === segment
    );

    return new CRMUser({
      email,
      phone,
      document: cpf || cnpj,
      companyId,
      cep,
      averageTicket,
      sharedName,
      noL4BStatus,
      integrators: integrators?.length ? integrators : undefined,
      otherIntegrator: otherIntegrator || undefined,
      segment: segmentKey,
      estimatedMonthlyPackages,
      phoneFromValidationBase
    });
  }

  /**
   * Enriches the CRMUser instance with data from a LoggiUser.
   * @param {Object} loggiUser - The Loggi user data.
   */
  enrichWithLoggiUserData(loggiUser) {
    const { payload } = this;
    const {
      userId = payload?.user_id,
      userType = payload?.user_type,
      firstName = payload?.first_name,
      lastName = payload?.last_name,
      email = payload?.email,
      phone = payload?.phone,
      document = payload?.document,
      registrationMethod = payload?.registration_method,
      accountCreatedDate = payload?.account_created_date
    } = loggiUser;

    this.payload = {
      ...payload,
      user_id: userId ? String(userId) : undefined,
      user_type: userType,
      first_name: firstName,
      last_name: lastName,
      email,
      phone: formatPhoneNumber(phone),
      document,
      registration_method: registrationMethod,
      account_created_date: accountCreatedDate
    };
  }

  async sendToCrm() {
    const disabledEventsList = await getFsValueUseCase('disable_crm_events');
    if (
      disabledEventsList === '*' ||
      disabledEventsList.includes(this.eventName)
    ) {
      return;
    }

    this.enrichesPayloadWithGeneralAppData();
    const cleanedPayload = this.cleanPayload();
    this.crmService.sendEvent(this, cleanedPayload);
  }
}
