import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';
import {
  EXPIRED_REFRESH_TOKEN_ERROR_CODE,
  USER_NOT_AUTHENTICATED_ERROR
} from './constants';

/**
 * getUser returns the authenticated user payload from AWS Cognito.
 *
 * @return {Object}
 *
 * @example
 * {
 *  at_hash: 'z1aaZeTK6Tcv9R8cuOqLRQ',
 *  sub: 'ea84651e-b544-4601-a683-940330f61c47',
 *  'cognito:groups': ['us-east-2_SVlwV1xQz_Google'],
 *  email_verified: true,
 *  iss: 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_SVlwV1xQz',
 *  'cognito:username': 'google_112452289135631924915',
 *  'custom:access': '{"companies": [{"id": 3, "role": "ADMIN"}]}',
 *  aud: '2ei24aqo5dhqflr4d8h2cjl13d',
 *  identities: [
 *   {
 *      userId: '112452289135631924915',
 *      providerName: 'Google',
 *      providerType: 'Google',
 *      issuer: null,
 *      primary: 'true',
 *      dateCreated: '1589224416164'
 *    }
 *  ],
 *  token_use: 'id',
 *  auth_time: 1589476063,
 *  name: 'Teste',
 *  exp: 1589479665,
 *  iat: 1589476065,
 *  email: 'teste@loggi.com'
 * }
 */
const getUser = () => {
  return Auth.currentSession()
    .then(data => {
      const { payload } = data.getIdToken();
      return payload;
    })
    .catch(error => {
      if (
        error !== USER_NOT_AUTHENTICATED_ERROR &&
        error?.code !== EXPIRED_REFRESH_TOKEN_ERROR_CODE
      ) {
        Sentry.addBreadcrumb({
          level: 'fatal',
          message: `${error.message}`,
          type: 'error'
        });
        Sentry.captureException(error);
      }
      return null;
    });
};

export default getUser;
