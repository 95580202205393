import CRMAdapterInsider from '../../insider/crm-adapter-insider';
import CRMBaseConfig from '../crm-base-config';

export default class InsiderConfig extends CRMBaseConfig {
  static get name() {
    return 'insider';
  }

  static isInitialized() {
    return !!window.Insider;
  }

  static initialize() {}

  static createAdapter() {
    if (!InsiderConfig.isInitialized()) return null;
    return new CRMAdapterInsider();
  }
}
