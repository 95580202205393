import { occasionalTracking } from 'routes/routes';

export const getDocumentLink = billingInvoiceResponse => {
  const response = billingInvoiceResponse[0];

  return response.cteUrl ? response.cteUrl : response.nfeUrl;
};

export const hasBillingInvoices = billingInvoice => {
  if (billingInvoice.length === 0) return false;

  return !!Object.keys(billingInvoice[0]).length;
};

export const handleBillingInvoiceClick = (billingInvoice, showSnackbar, t) => {
  if (!hasBillingInvoices(billingInvoice)) {
    showSnackbar({
      variant: 'negative',
      size: 'small',
      message: t('shipmentDetailsV2.billingInvoicesFailed'),
      lightVariant: true
    });

    return;
  }

  const documentLink = getDocumentLink(billingInvoice);

  window.open(documentLink, '_blank', 'noopener');
};

export const getServiceType = (isIndispatch, isDropoff) => {
  if (isIndispatch()) {
    return 'myShipmentsV2.serviceType.indispatch';
  }
  if (isDropoff()) {
    return 'myShipmentsV2.serviceType.dropOff';
  }
  return 'myShipmentsV2.serviceType.beyond';
};

export const trackingPath = ({ companyId, loggiKey }) =>
  occasionalTracking
    .replace(':companyId', companyId)
    .replace(':loggiKey', loggiKey);

export const debounce = (func, delay) => {
  let timeoutId;

  // eslint-disable-next-line func-names
  return function(...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const formatPhoneNumber = rawPhoneNumber => {
  const areaCode = rawPhoneNumber.substring(0, 2);
  const restOfNumber = rawPhoneNumber.substring(2);

  const formattedRest =
    restOfNumber.length === 8
      ? `${restOfNumber.substring(0, 4)}-${restOfNumber.substring(4)}`
      : `${restOfNumber.substring(0, 5)}-${restOfNumber.substring(5)}`;

  return `(${areaCode}) ${formattedRest}`;
};
