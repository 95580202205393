export const scrollButtonIntoView = size => {
  const el = document.querySelector(`[data-package-size=${size}]`);
  el.scrollIntoView({ block: 'center', inline: 'center' });
};

export const scrollContainer = size => {
  const container = document.querySelector('#package-sizes-container');
  const el = document.querySelector(`[data-package-size=${size}]`);
  const { offsetLeft: left } = el;
  container.scrollTo({ left, behavior: 'smooth', top: 0 });
};

export default { scrollButtonIntoView, scrollContainer };
