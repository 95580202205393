import { Box, Fade } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { root } from 'theme/colors';
import { InfiniteLoading } from 'UI/components';

export const Loading = ({ isVisible }) => {
  const { t } = useTranslation('ui');
  return (
    <Fade in={isVisible} unmountOnExit mountOnEnter>
      <Box
        alignItems="center"
        bgcolor={root[0]}
        display="flex"
        height="100vh"
        left="0"
        position="fixed"
        top="0"
        width="100%"
        zIndex="9"
      >
        <InfiniteLoading
          title={t('checkout.loading.title')}
          description={t('checkout.loading.description')}
        />
      </Box>
    </Fade>
  );
};

Loading.defaultProps = { isVisible: false };
Loading.propTypes = { isVisible: PropTypes.bool };

export default { Loading };
