import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material/styles';
import { Button, Stack, Typography } from '@mui/material';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';
import { Icon } from '@loggi/loggi-icons';
import { borders, colors, spacing } from '@loggi/front-design-tokens';

import { currencyFormatter } from 'utils';
import { getDayAndMonth } from 'utils/datetime-helper/datetime.helper';

import useWalletBalance from 'hooks/wallet-balance/wallet-balance.hook';
import { LoadingWalletCard } from './loading-wallet-card';

export const WalletCard = ({ onAddBalance, onWalletClick }) => {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();

  const [showBalance, setShowBalance] = useState(true);

  const balanceQuery = useWalletBalance({ companyId });
  const showLoading = balanceQuery.isLoading || balanceQuery.isError;
  const lastRechargedAt = balanceQuery.data?.['last_recharged_at'];

  return (
    <ThemeProvider theme={theme}>
      <Stack
        pt={spacing.stack.small}
        pb={spacing.stack.nano}
        gap={spacing.stack.xxsmall}
        onClick={onWalletClick}
        data-testid="wallet-card"
      >
        <Typography
          variant="subtitleLarge"
          color={colors.neutrals['on-surface']}
        >
          {t('walletCard.title')}
        </Typography>
        <Stack
          border={`${borders.width.thin} solid ${colors.neutrals.outline}`}
          borderRadius={borders.radius.large}
          p={spacing.stack.xxsmall}
          gap={spacing.stack.xxxsmall}
        >
          {showLoading && (
            <LoadingWalletCard onReload={() => balanceQuery.refetch()} />
          )}
          {!showLoading && (
            <Stack gap={spacing.stack.xxxsmall}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  data-testid="last-recharged-date"
                  variant="subtitleSmall"
                  color={colors.neutrals['on-surface']}
                >
                  {lastRechargedAt
                    ? t('walletCard.lastRechargedDate', {
                        date: getDayAndMonth(new Date(lastRechargedAt))
                      })
                    : t('walletCard.loggiBalance')}
                </Typography>
                <Stack
                  data-testid="eye-icon"
                  onClick={e => {
                    e.stopPropagation();
                    setShowBalance(!showBalance);
                  }}
                >
                  <Icon
                    name={showBalance ? 'Eye' : 'EyeOff'}
                    size="medium"
                    color={colors.neutrals['on-surface']}
                  />
                </Stack>
              </Stack>
              <Typography
                data-testid="balance"
                variant="subtitleLarge"
                color={colors.neutrals['on-surface']}
              >
                {showBalance
                  ? currencyFormatter(balanceQuery.data?.balance)
                  : '****'}
              </Typography>
            </Stack>
          )}
          <Stack alignItems="flex-start">
            <Button
              size="small"
              variant="blank"
              startIcon={
                <Icon name="ActionPlus" size="small" color={colors.primary} />
              }
              onClick={e => {
                e.stopPropagation();
                onAddBalance();
              }}
            >
              {t('walletCard.addBalance')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

WalletCard.propTypes = {
  onAddBalance: PropTypes.func.isRequired,
  onWalletClick: PropTypes.func.isRequired
};

export default { WalletCard };
