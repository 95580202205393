/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { fillCompanyId } from 'utils';
import { AmateurQuoting } from 'models';
import { accountProblem } from 'routes/routes';

import { Box, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  loggiDesignSystem as theme,
  useSnackbar
} from '@loggi/front-design-system';
import { colors, spacing } from '@loggi/front-design-tokens';
import { useLocalStorageState } from 'use-local-storage-state';
import { Icon } from '@loggi/loggi-icons';
import components from 'theme/components';

import { useWaitingPickup } from 'hooks/waiting-pickups/waiting-pickup.hook';
import { checkIsMobile } from 'utils/check-is-mobile/check-is-mobile.helper';

import useCoupon from 'UI/shipment/hooks/coupon';
import useServiceQuoting from 'UI/shipment/hooks/service-quoting';
import useAddressCoverage from 'UI/shipment/hooks/address-coverage';
import useCreateShipmentOrder from 'UI/shipment/hooks/create-shipment-order';
import useWalletBalance from 'hooks/wallet-balance/wallet-balance.hook';

import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { ShippingPayloadBuilder } from 'UI/shipment/state-machine/utils';
import AttemptCreateOrderLimitDrawer from 'UI/shipment/components/attempt-create-order-limit-drawer/attempt-create-order-limit-drawer.component';
import useWalletRechargeStatus from 'hooks/wallet-recharge-status/wallet-recharge-status.hook';
import ShipmentCreationFailed from 'crm/entities/events/shipment-creation-failed/shipment-creation-failed';
import { ReactComponent as PixIcon } from 'assets/pix.svg';

import CheckoutHeader from 'UI/shipment/components/checkout-header/header.component';
import { sendShipmentSentEventsToCrm } from 'crm/utils';
import { useFeatureSwitch } from 'hooks/feature-switch';

import { CreditCardPayment } from './credit-card-payment';
import Footer from './footer/footer.component';
import { PaymentMethodOptionCard } from './option-card/payment-method-option-card';
import { WalletBalanceOptionCard } from './option-card/wallet-balance-option-card';
import { LoadingOptionCards } from './option-card/loading-option-cards';
import { Loading } from '../checkout/loading/loading.component';
import { PaymentError } from './payment-error/payment-error';
import {
  CREDIT_CARD,
  PIX,
  WALLET_BALANCE,
  RECHARGE_STATUS,
  MAX_CREATE_ORDER_ATTEMPTS
} from './constants';
import { NewPaymentMethodDrawer } from './new-payment-method-drawer/new-payment-method-drawer';
import { NewPaymentMethodDialog } from './new-payment-method-dialog/new-payment-method-dialog';
import {
  buildMachinePayload,
  checkScheduleTimeAvailability,
  hasAddressLengthExceeded
} from '../utils';
import TermsOfService from './terms-of-service/terms-of-service';

/**
 * Necessary for our drawer to continue
 * respecting the maxWidth stipulated in
 * our general theme
 */
const addGeneralDrawerComponentsTheme = () => {
  Object.assign(theme.components, { MuiDrawer: components.MuiDrawer });
};

const sendShipmentCreationFailedToCrm = (error, { context, payload }) => {
  ShipmentCreationFailed.fromShipmentContext({
    context,
    price: payload?.['payment_data']?.amount,
    rechargeId: payload?.rechargeId,
    coupon: payload?.coupon,
    error
  }).sendToCrm();
};

export default function PaymentPage() {
  addGeneralDrawerComponentsTheme();
  const { t } = useTranslation('ui');
  const { companyId } = useParams();
  const history = useHistory();
  const isMobileDevice = checkIsMobile();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const [countCreateOrderFails, setCountCreateOrderFails] = useState(0);
  const [scheduleAvailabilityError, setScheduleAvailabilityError] = useState();
  const [
    addressLengthExceededError,
    setAddressLengthExceededError
  ] = useState();
  const [shippingApiError, setShippingApiError] = useState();
  const [isCreateOrderPending, setIsCreateOrderPending] = useState(false);
  const [currentRechargeId, setCurrentRechargeId] = useState(null);
  const [isEnabledPolling, setIsEnabledPolling] = useState(false);
  const { data: maximumAtteptsLimit } = useFeatureSwitch(
    'maximum_attempt_create_order_limit',
    MAX_CREATE_ORDER_ATTEMPTS
  );

  const { showSnackbar, dismissSnackbar } = useSnackbar();
  const snackbarId = React.useRef(null);

  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const quoting = useServiceQuoting();
  const couponQuery = useCoupon();
  const balanceQuery = useWalletBalance({ companyId });
  const [
    showNewPaymentMethodDrawer,
    setShowNewPaymentMethodDrawer
  ] = useLocalStorageState('show-new-payment-method-drawer', true);
  const maximumAtteptsReached = countCreateOrderFails >= maximumAtteptsLimit;

  const isIndispatchServiceType = AmateurQuoting.isIndespacho({
    serviceType: context.serviceType
  });

  const rechargeStatusQuery = useWalletRechargeStatus({
    companyId,
    rechargeId: currentRechargeId,
    enabled: isEnabledPolling
  });

  const createShipmentOrder = useCreateShipmentOrder({
    onMutate: sendShipmentSentEventsToCrm,
    onError: sendShipmentCreationFailedToCrm
  });

  const waitingPickup = useWaitingPickup(companyId);

  const pickupAddressCoverage = useAddressCoverage({
    address: context.pickupAddress || context.originAddress,
    companyId
  });

  let amount = quoting?.price;

  if (couponQuery.data) {
    amount = couponQuery.data.amountWithAppliedDiscount;
  }

  const hasInsuficientBalance = balanceQuery.data?.balance < amount;

  useEffect(() => {
    if (!selectedPaymentMethod && balanceQuery.data?.balance >= amount) {
      setSelectedPaymentMethod(WALLET_BALANCE);
    }
  }, [balanceQuery, selectedPaymentMethod, amount]);

  const showErrorScreen =
    !!shippingApiError ||
    !!scheduleAvailabilityError ||
    !!addressLengthExceededError;

  const handleException = (ex, machinePayload) => {
    Sentry.captureException(ex);
    Sentry.setContext('payload', machinePayload);

    if (ex?.status === 403) {
      shipmentDispatcher.clear();

      history.push(
        fillCompanyId({
          companyId,
          route: accountProblem
        })
      );
    } else {
      setShippingApiError(ex ?? {});
    }
  };

  const createOrder = async () => {
    if (isCreateOrderPending || maximumAtteptsReached) return;
    const paymentMethod = { type: 'wallet' };
    const payloadBuilder = new ShippingPayloadBuilder()
      .setShipmentContext(context)
      .setQuoting(quoting)
      .setCoupon(couponQuery.data)
      .setPayment(paymentMethod);

    let machinePayload = buildMachinePayload({
      paymentMethod,
      payloadBuilder,
      context,
      quoting,
      couponQuery
    });

    if (currentRechargeId) {
      machinePayload = { ...machinePayload, rechargeId: currentRechargeId };
    }

    try {
      setIsCreateOrderPending(true);

      const shipmentOrder = await createShipmentOrder.mutateAsync({
        companyId,
        payload: machinePayload,
        context: { ...context, paymentMethod: selectedPaymentMethod }
      });

      if (
        !AmateurQuoting.isIndespacho({
          serviceType: context.serviceType
        })
      ) {
        waitingPickup.setWaitingPickup(shipmentOrder?.pickupOrderSchedule);
      }
      shipmentDispatcher.orderCreated({
        ...shipmentOrder,
        rechargeId: currentRechargeId
      });
    } catch (ex) {
      setCountCreateOrderFails(prev => prev + 1);
      handleException(ex, machinePayload);
    } finally {
      setIsCreateOrderPending(false);
    }
  };

  const handleOnAddWalletBalance = () => {
    if (snackbarId.current) dismissSnackbar(snackbarId.current);
    shipmentDispatcher.clickAddWalletBalance(amount);
  };

  const handleSelectPaymentMethod = paymentMethod => {
    if (snackbarId.current) dismissSnackbar(snackbarId.current);
    setSelectedPaymentMethod(paymentMethod);
  };

  const showSnackbarError = errorMessage => {
    snackbarId.current = showSnackbar(
      {
        message: t(errorMessage),
        variant: 'negative',
        size: 'small'
      },
      { preventDuplicate: true }
    );
  };

  const onSubmitHandler = () => {
    if (!selectedPaymentMethod) {
      return showSnackbarError('payment.wallet.errors.noSelectedPaymentMethod');
    }

    if (selectedPaymentMethod === WALLET_BALANCE && hasInsuficientBalance) {
      if (couponQuery?.data?.amountWithAppliedDiscount !== 0) {
        return showSnackbarError('payment.wallet.errors.insuficientBalance');
      }
    }

    if (
      AmateurQuoting.isBeyond(context.serviceType) &&
      !checkScheduleTimeAvailability(pickupAddressCoverage, context)
    ) {
      return setScheduleAvailabilityError({
        status: 'scheduleAvailabilityError'
      });
    }

    if (hasAddressLengthExceeded(context)) {
      return setAddressLengthExceededError({
        status: 'addressLengthExceededError'
      });
    }

    shipmentDispatcher.setPaymentMethod(selectedPaymentMethod);

    if (selectedPaymentMethod) {
      if (selectedPaymentMethod === CREDIT_CARD) {
        setShowPayment(true);
      } else if (selectedPaymentMethod === PIX) {
        shipmentDispatcher.selectPixPayment(PIX);
      } else {
        createOrder();
      }
    }

    return null;
  };

  const handlePaymentCompleted = rechargeId => {
    setIsEnabledPolling(true);
    setShowPayment(false);
    setCurrentRechargeId(rechargeId);
  };

  useEffect(() => {
    const rechargeStatus = rechargeStatusQuery?.data?.['recharge_status'];

    if (rechargeStatus === RECHARGE_STATUS.SUCCESS && !isCreateOrderPending) {
      setIsEnabledPolling(false);
      createOrder();
    }

    if (
      rechargeStatusQuery?.isError ||
      rechargeStatus === RECHARGE_STATUS.ERROR ||
      rechargeStatus === RECHARGE_STATUS.CANCELED_BY_GATEWAY
    ) {
      setIsEnabledPolling(false);
      shipmentDispatcher.viewWalletBalanceError({
        paymentMethod: CREDIT_CARD,
        addBalanceError: RECHARGE_STATUS.ERROR
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargeStatusQuery]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {showErrorScreen && (
          <PaymentError
            error={
              shippingApiError ||
              scheduleAvailabilityError ||
              addressLengthExceededError
            }
            amount={amount}
            paymentMethod={selectedPaymentMethod}
            onRetry={createOrder}
            onCancel={() => shipmentDispatcher.clear()}
          />
        )}
        {!showErrorScreen && (
          <Box>
            <CheckoutHeader title={t('payment.title')} />
            {!balanceQuery.isFetching && (
              <Stack
                px={spacing.stack.xxsmall}
                pt={spacing.inset.medium}
                gap={spacing.stack.xxxsmall}
                pb={spacing.stack.giant}
                mb={spacing.stack.xsmall}
              >
                <WalletBalanceOptionCard
                  isSelected={selectedPaymentMethod === WALLET_BALANCE}
                  hasInsuficientBalance={hasInsuficientBalance}
                  balance={balanceQuery.data?.balance}
                  onClick={() => handleSelectPaymentMethod(WALLET_BALANCE)}
                  onAddWalletBalance={handleOnAddWalletBalance}
                />
                <PaymentMethodOptionCard
                  title={t('payment.creditCard.option.title')}
                  icon={
                    <Icon
                      name="CreditCard02"
                      size="medium"
                      color={colors.neutrals['on-surface']}
                    />
                  }
                  isSelected={selectedPaymentMethod === CREDIT_CARD}
                  onClick={() => handleSelectPaymentMethod(CREDIT_CARD)}
                />
                <PaymentMethodOptionCard
                  title={t('payment.pix.option.title')}
                  icon={<PixIcon />}
                  isSelected={selectedPaymentMethod === PIX}
                  onClick={() => handleSelectPaymentMethod(PIX)}
                />
                {!isIndispatchServiceType &&
                  selectedPaymentMethod === WALLET_BALANCE && (
                    <TermsOfService />
                  )}
              </Stack>
            )}
            {(balanceQuery.isFetching || balanceQuery.isError) && (
              <LoadingOptionCards />
            )}
            <CreditCardPayment
              isOpen={showPayment}
              onClose={() => setShowPayment(false)}
              amount={amount}
              handlePaymentCompleted={handlePaymentCompleted}
              onError={() =>
                shipmentDispatcher.viewWalletBalanceError({
                  paymentMethod: CREDIT_CARD
                })
              }
            />
            <Footer>
              <Footer.Coupon
                validatedCoupon={couponQuery.data}
                onClick={() => shipmentDispatcher.clickViewCoupons()}
              />
              <Footer.ShipmentBar>
                <Footer.EstimatePrice
                  validatedCoupon={couponQuery.data}
                  subtotal={quoting?.price}
                />
                <Footer.PrimaryAction onClick={() => onSubmitHandler()} />
              </Footer.ShipmentBar>
            </Footer>
            {isMobileDevice ? (
              <NewPaymentMethodDrawer
                isOpen={showNewPaymentMethodDrawer}
                onClose={() => setShowNewPaymentMethodDrawer(false)}
              />
            ) : (
              <NewPaymentMethodDialog
                isOpen={showNewPaymentMethodDrawer}
                onClose={() => setShowNewPaymentMethodDrawer(false)}
              />
            )}
          </Box>
        )}
      </ThemeProvider>
      <AttemptCreateOrderLimitDrawer
        isVisible={maximumAtteptsReached}
        onClose={() => setCountCreateOrderFails(prev => prev - 1)}
      />
      <Loading isVisible={!!currentRechargeId || isCreateOrderPending} />
    </>
  );
}
