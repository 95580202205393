import { Box, Typography } from '@mui/material';
import loadingAnimation from 'assets/lotties/linear-undetermined-loading.json';
import PropTypes from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';

const InfiniteLoading = ({
  title,
  description,
  titleVariant,
  descriptionVariant
}) => {
  return (
    <Box
      alignItems="center"
      alignSelf="center"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box maxWidth="300px">
        <Typography align="center" variant={titleVariant}>
          {title}
        </Typography>
        {description && (
          <Box marginTop={3}>
            <Typography align="center" variant={descriptionVariant}>
              {description}
            </Typography>
          </Box>
        )}
        <Box mt="-96px">
          <Lottie
            options={{
              loop: true,
              animationData: loadingAnimation,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
            }}
            height="300px"
            isClickToPauseDisabled
          />
        </Box>
      </Box>
    </Box>
  );
};

InfiniteLoading.propTypes = {
  description: PropTypes.string,
  descriptionVariant: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string
};

InfiniteLoading.defaultProps = {
  description: '',
  descriptionVariant: 'subtitle2',
  titleVariant: 'subtitle1'
};

export default InfiniteLoading;
