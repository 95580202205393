import {
  getAndroidNativeAppVersion,
  getiOSNativeAppVersion
} from 'mobile/versioning/native-app-version';
import { appendLogRocketUrl } from '../configuration/logrocket-init';
import { addLocalStorageToEvent } from './sentry-helpers';

// Sentry encodes the / character that we use on our tags,
// so we need to replace them.
//
// Ex: "one/stg-20210316.02/beta" becomes "one-stg-20210316.02-beta"
const tag = process.env.REACT_APP_VERSION;
const formattedSentryRelease = tag.replace(/\//g, '-');

const sentryOptions = {
  beforeSend: event => addLocalStorageToEvent(appendLogRocketUrl(event)),
  dsn: process.env.REACT_APP_DSN_SENTRY,
  environment: process.env.REACT_APP_ENV_SENTRY,
  normalizeDepth: 10,
  release: formattedSentryRelease,
  nativeRelease: getAndroidNativeAppVersion() || getiOSNativeAppVersion()
};

export default sentryOptions;
