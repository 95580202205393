const masks = {
  cpf(val) {
    return val
      .replace(/(\d{11})\d/, '$1')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2');
  },
  cnpj(val) {
    return val
      .replace(/(\d{14})\d/, '$1')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
};

export const onlyDigits = val => {
  return val.replace(/\D/g, '');
};

export const formatCpfCnpj = cpfCnpj => {
  const digits = onlyDigits(cpfCnpj);
  if (digits.length <= 11) return masks.cpf(digits);
  return masks.cnpj(digits);
};
