/**
   * Converts the type object values from an object to string
   * @example
   * // Input
   * {
      a: 1,
      b: undefined,
      c: {
        d: 2,
        e: 'value'
      },
      f: [3, 4],
    }
   * // Output
   * {
      a: 1,
      b: undefined,
      c: "{\"d\":2,\"e\":\"value\"}",
      f: [3, 4]
    }
   */
export const stringifyTypeObjectFields = object => {
  const output = { ...object };
  Object.keys(output).forEach(key => {
    if (
      typeof output[key] === 'object' &&
      output[key] !== null &&
      !(output[key] instanceof Array)
    ) {
      output[key] = JSON.stringify(output[key]);
    }
  });
  return output;
};

export default { stringifyTypeObjectFields };
