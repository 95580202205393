import React from 'react';
import { Accordion } from '@loggi/front-design-system';
import { spacing, colors } from '@loggi/front-design-tokens';
import PropTypes from 'prop-types';
import { Typography, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const getPackageSize = (isPackaged, packageDetails, t) => {
  const packageDimensions = packageDetails.formatDimensions();

  if (isPackaged) {
    return `${packageDimensions} • ${packageDetails.formatWeightToKg()}`;
  }

  const packagingLabel = packageDetails.matchingPackagingLabel();
  return packagingLabel
    ? t(
        `shipmentDetailsV2.packageDetails.packagingDimensions.${packagingLabel}`
      )
    : packageDimensions;
};

export const PackageDetailsAccordion = ({ packageDetails, isPackaged }) => {
  const { t } = useTranslation('ui');
  const packageTypeLabel = isPackaged ? 'selfPackaging' : 'loggiPackaging';
  const packageType = t(
    `shipmentDetailsV2.packageDetails.packagingType.${packageTypeLabel}`
  );

  const packageSize = getPackageSize(isPackaged, packageDetails, t);

  return (
    <Box
      key="PackageDetailsAccordion"
      sx={{ borderBottom: `1px solid ${colors.neutrals['outline-low']}` }}
    >
      <Accordion
        arrow="start"
        title={t('shipmentDetailsV2.packageDetails.title')}
      >
        <Box px={spacing.inset.large}>
          <Stack>
            <Typography pb={spacing.stack.nano} variant="bodyHighlightMedium">
              {packageType}
            </Typography>
            <Typography variant="bodyTextMedium">{packageSize}</Typography>
          </Stack>
        </Box>
      </Accordion>
    </Box>
  );
};

PackageDetailsAccordion.propTypes = {
  packageDetails: PropTypes.shape({
    formatDimensions: PropTypes.func.isRequired,
    matchingPackagingLabel: PropTypes.func.isRequired,
    formatWeightToKg: PropTypes.func.isRequired
  }).isRequired,
  isPackaged: PropTypes.bool.isRequired
};

export default PackageDetailsAccordion;
