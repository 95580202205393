import { Box, ButtonBase, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue, grey, root } from 'theme/colors';
import { getPartOfDayString, getTimeString } from 'utils';

const PickupScheduleSelectSlot = ({ onClick, isActive, slotTime }) => {
  const base = {
    borderRadius: '8px',
    margin: '8px',
    width: '50%',
    height: '80px',
    background: 'transparent',
    border: `2px solid ${isActive ? blue[500] : grey[50]}`,
    color: root[900]
  };
  const buttonStyle = {
    [false]: {
      ...base
    },
    [true]: {
      ...base
    }
  };

  const { t } = useTranslation('partOfDay');

  return (
    <ButtonBase
      style={buttonStyle[isActive]}
      onClick={onClick}
      data-testid={`pickup-schedule-slot-${
        isActive ? 'selected' : 'unselected'
      }`}
    >
      <Box textAlign="left" width="100%" p={2}>
        <Box mb={0.5}>
          <Typography variant="body1">
            <strong>{t(getPartOfDayString(slotTime[0]))}</strong>
          </Typography>
        </Box>
        <Typography variant="body2">
          {`${getTimeString(slotTime[0])} - ${getTimeString(slotTime[1])}`}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

PickupScheduleSelectSlot.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  slotTime: PropTypes.arrayOf(PropTypes.shape).isRequired
};

export default PickupScheduleSelectSlot;
