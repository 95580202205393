import { Box, Button, IconButton, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { blue, root } from 'theme/colors';
import useStyles from 'theme/custom-classes';
import { SvgIcon } from 'UI/components';
import { useDomainUseCase } from 'UI/contexts';
import { fillCompanyId } from 'utils';
import { myShipmentsV2 } from 'routes/routes';

export const BACK_BUTTON_TEST_ID = 'title-bar.back-button';
export const HELP_LINK_TEST_ID = 'title-bar.help-link';

const TitleBar = ({ title, trackerUrl, onSupport, fromTrackingPage }) => {
  const { t } = useTranslation('ui');
  const history = useHistory();
  const { companyId } = useParams();
  const sharingUseCase = useDomainUseCase('sharingUseCase');
  const hasNavigatorShare = typeof navigator.share === 'function';
  const showShareButton = trackerUrl && hasNavigatorShare;
  const headerProps = {
    backgroundColor: root[0]
  };
  const classes = useStyles(headerProps);

  const handleShare = async () => {
    const shareData = {
      text: t('titleBar.textShare'),
      title: t('titleBar.titleShare'),
      url: trackerUrl
    };

    try {
      await sharingUseCase(shareData);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onGoBack = () =>
    fromTrackingPage
      ? history.push(fillCompanyId({ companyId, route: myShipmentsV2 }))
      : history.goBack();
  return (
    <Box className={classes.header} role="menubar">
      <IconButton
        color="inherit"
        data-testid={BACK_BUTTON_TEST_ID}
        disableRipple
        style={{ padding: '0px' }}
        onClick={onGoBack}
      >
        <ArrowBackIos color="primary" />
        <Box mx={0.5}>
          <Typography variant="body1">{title}</Typography>
        </Box>
      </IconButton>
      {showShareButton && (
        <IconButton
          disableRipple
          onClick={handleShare}
          size="small"
          style={{
            boxSizing: 'content-box',
            padding: '0px'
          }}
          title={t('titleBar.buttonShare')}
        >
          <SvgIcon color={blue[500]} icon="share" />
        </IconButton>
      )}
      {!!onSupport && (
        <Button
          title={t('titleBar.help')}
          id="cta_ajuda_rastreio_detalhado"
          data-testid={HELP_LINK_TEST_ID}
          style={{ padding: 0, minWidth: 'unset' }}
          onClick={onSupport}
        >
          <Box
            boxSizing="content-box"
            display="flex"
            width="24px"
            height="24px"
          >
            <SvgIcon color={blue[500]} icon="chatHelp" />
          </Box>
        </Button>
      )}
    </Box>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  trackerUrl: PropTypes.string,
  onSupport: PropTypes.func,
  fromTrackingPage: PropTypes.bool
};

TitleBar.defaultProps = {
  trackerUrl: null,
  onSupport: null,
  fromTrackingPage: false
};

export default TitleBar;
