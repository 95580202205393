import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { ROUTES } from 'routes';

export const DropoffServiceLocationsInfo = ({
  senderZipCode,
  pickupScheduledUpdatedDate
}) => {
  const { t } = useTranslation('ui');
  const history = useHistory();

  const redirectToDropoffLocations = () => {
    const route = ROUTES.dropOffAgenciesLocation
      .replace(':senderPostalCode', senderZipCode)
      .replace(':pickupScheduledUpdatedDate', pickupScheduledUpdatedDate);

    history.push(route);
  };

  return (
    <Box pb={spacing.inset.large} display="flex" flexDirection="column">
      <Box px={spacing.inset.large} pt={spacing.inset.large}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.dropoffLocationsInfo.title')}
        </Typography>
      </Box>
      <Box pl={spacing.inset.small} pt={spacing.stack.xxxsmall}>
        <Button
          onClick={redirectToDropoffLocations}
          size="small"
          variant="text"
        >
          {t('shipmentDetailsV2.dropoffLocationsInfo.button')}
        </Button>
      </Box>
    </Box>
  );
};

DropoffServiceLocationsInfo.propTypes = {
  senderZipCode: PropTypes.string.isRequired,
  pickupScheduledUpdatedDate: PropTypes.string.isRequired
};

export default DropoffServiceLocationsInfo;
