import CouponsResponse from 'models/coupons-response.model';
import couponsApi from 'service/coupons-api';

export const getCouponsUseCase = async (companyId, serviceType) => {
  const coupons = couponsApi
    .list(companyId, serviceType)
    .then(response => CouponsResponse.parseApiResponse(response));

  return coupons;
};

export default { getCouponsUseCase };
