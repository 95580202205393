import { makeStyles } from '@mui/styles';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';

export const calcPaddingTop = () => {
  const isMobile = checkIsMobile();
  if (!isMobile) return '16px';

  const safeAreaInsetTop = sessionStorage.getItem('safeAreaInsetTop');
  if (!safeAreaInsetTop || safeAreaInsetTop === '0px') {
    return '40px';
  }
  return `calc(${safeAreaInsetTop} + 16px)`;
};

const useStyles = makeStyles(() => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    display: 'flex',
    '-webkit-flex': '0 0 auto', // fix for safari bug on iOS 12
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: calcPaddingTop(),
    paddingBottom: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: props => props.backgroundColor,
    color: props => props.color
  }
}));

export default useStyles;
