import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';

export default function ShipmentBar({ children }) {
  return (
    <Stack
      px={spacing.stack.xxsmall}
      pt={spacing.stack.xxxsmall}
      pb={spacing.stack.small}
      gap={spacing.stack.xxxsmall}
      backgroundColor={colors.neutrals['surface-container-high']}
    >
      {children}
    </Stack>
  );
}

ShipmentBar.propTypes = {
  children: PropTypes.node.isRequired
};
