import { useFeatureSwitch } from 'hooks/feature-switch/feature-switch.hook';

export const useGroupedShipmentBlocklist = companyId => {
  const blocklistFS = useFeatureSwitch(
    'grouped_shipment_blocklist_by_company',
    []
  );

  return {
    isLoading: blocklistFS.isLoading,
    isBlocked: blocklistFS.data.map(String).includes(String(companyId))
  };
};

export default { useGroupedShipmentBlocklist };
