import translationsOverrides from 'locales/adyen-translation.json';
import { blue } from 'theme/colors';

export const adyenConfig = {
  locale: 'pt-BR',
  autoFocus: true,
  environment: process.env.REACT_APP_ADYEN_ENV,
  clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
  translations: { 'pt-BR': translationsOverrides },
  paymentMethodsConfiguration: {
    card: {
      hasHolderName: true,
      holderNameRequired: true
    }
  }
};

const baseStyle = {
  color: blue[500],
  fontSize: '18px',
  fontSmoothing: 'antialiased',
  fontWeight: 700,
  fontFamily:
    'Montserrat, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif'
};

export const adyenCardCheckoutConfig = {
  brands: ['mc', 'visa', 'amex'],
  ariaLabels: {
    lang: 'pt-BR',
    encryptedCardNumber: {
      label: 'Número do cartão',
      iframeTitle: 'cc'
    },
    encryptedExpiryDate: {
      label: 'Validade',
      iframeTitle: 'expiry-date'
    },
    encryptedSecurityCode: {
      label: 'CVV'
    },
    holderName: {
      label: 'Nome do titular do cartão'
    }
  },
  styles: {
    base: baseStyle,
    error: { color: blue[500] },
    // for some reason the placeholder style also affects the user input style
    // so lets just replicate the 'styles.base' to ensure that no
    // weird behavior/inconsistency happens
    placeholder: baseStyle,
    validated: { color: blue[500] }
  }
};
