import { loggiWebApi } from '@loggi/authentication-lib';
import ValidatedCoupon from 'UI/shipment/models/validated-coupon.model';

const URL = process.env.REACT_APP_PY_BEYOND_URL;

/**
 * @param {object} params
 * @param {number} params.companyId
 * @param {string} params.couponCode - e.g. 'COUPONCODE'
 * @param {number} params.totalAmount - e.g. 13.9
 * @returns {Promise<ValidatedCoupon>}
 */
export default async function validateCoupon({
  companyId,
  couponCode,
  totalAmount,
  serviceType
}) {
  return loggiWebApi
    .url(`${URL}/shipment/v1/coupons/validate/${companyId}/`, true)
    .post({
      coupon_code: couponCode,
      total_amount: totalAmount.toFixed(2),
      service_type: serviceType
    })
    .json()
    .then(ValidatedCoupon.fromJson)
    .catch(err => {
      const body = JSON.parse(err.text);
      throw body.extra.key;
    });
}
