/* eslint-disable camelcase */
export class Company {
  constructor({
    cnpj,
    configurationJson = {},
    cpf,
    created,
    id,
    maximumInsuranceAmount,
    landline1,
    name,
    secretId,
    sharedName,
    updated,
    hasAccessShipmentFlowV2
  }) {
    this.cnpj = cnpj;
    this.configurationJson = configurationJson;
    this.cpf = cpf;
    this.created = created;
    this.id = id;
    this.maximumInsuranceAmount = maximumInsuranceAmount || 1000;
    this.landline1 = landline1;
    this.name = name;
    this.secretId = secretId;
    this.sharedName = sharedName;
    this.updated = updated;
    this.hasAccessShipmentFlowV2 = hasAccessShipmentFlowV2 || false;
  }

  static parseApiResponse(response, companyId) {
    if (response.length === 0) {
      return null;
    }

    const company = response
      .filter(item => String(item.id) === String(companyId))
      .shift();

    if (company === undefined || Object.keys(company).length === 0) {
      return null;
    }

    return new Company({
      cnpj: company.cnpj,
      cpf: company.cpf,
      created: company.created,
      id: company.id,
      landline1: company.landline_1,
      name: company.name,
      sharedName: company.shared_name,
      updated: company.updated,
      secretId: company.secret_id,
      configurationJson: company.company_configuration_json,
      hasAccessShipmentFlowV2:
        company.company_configuration_json?.access_beyond_shipment_flow_v2,
      maximumInsuranceAmount:
        company.company_configuration_json?.maximum_insurance_amount
      /* eslint-enable camelcase */
    });
  }
}

export default Company;
