import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { Icon } from '@loggi/loggi-icons';
import { colors, spacing } from '@loggi/front-design-tokens';

export default function WalletEmptyStatements() {
  const { t } = useTranslation('ui');

  return (
    <Stack
      my={spacing.stack.large}
      data-testid="empty-statements"
      p={spacing.stack.xxsmall}
      gap={spacing.stack.xxxsmall}
      alignItems="center"
      textAlign="center"
    >
      <Icon
        name="Coins02"
        size="large"
        color={colors.neutrals['on-surface-variant']}
      />
      <Typography
        variant="subtitleMedium"
        color={colors.neutrals['on-surface-variant']}
      >
        {t('walletPage.emptyStatements.title')}
      </Typography>
      <Typography
        variant="bodyTextMedium"
        color={colors.neutrals['on-surface-variant']}
      >
        {t('walletPage.emptyStatements.description')}
      </Typography>
    </Stack>
  );
}
