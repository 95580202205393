import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';
import { Button } from 'UI/components';

export default function ImpersonateForm({ onSubmit, disableSubmit }) {
  const { t } = useTranslation('ui');
  const [email, setEmail] = React.useState('');
  const isSubmitDisabled = disableSubmit || email.length === 0;

  const handleSubmit = ev => {
    ev.preventDefault();

    onSubmit({ email });
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <TextField
          name="email"
          type="email"
          width="100%"
          label={t('impersonation.form.email.label')}
          data-testid="email-input"
          value={email}
          onChange={ev => setEmail(ev.target.value)}
        />
        <Box mb={3} />
        <Button
          label={t('impersonation.form.submit.label')}
          type="submit"
          disabled={isSubmitDisabled}
          id="submit-button"
          data-testid="submit-button"
          fullWidth
        />
      </form>
    </Box>
  );
}

ImpersonateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool
};

ImpersonateForm.defaultProps = {
  disableSubmit: false
};
