import Validator from '../validator';

const isPhoneNumberValid = phoneNumber => {
  if (typeof phoneNumber !== 'string') return false;
  const rawPhoneNumber = phoneNumber.replace(/\D/g, '');
  const { length } = rawPhoneNumber;
  return length === 11 || length === 10;
};

export default class PhoneValidator extends Validator {
  static validate(string) {
    return isPhoneNumberValid(string);
  }
}
