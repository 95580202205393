import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';
import { borders, colors, spacing } from '@loggi/front-design-tokens';

import { OptionCard } from 'UI/shipment/components/option-card/option-card.component';

export const PaymentMethodOptionCard = ({
  title,
  icon,
  isSelected,
  onClick
}) => {
  return (
    <OptionCard isSelected={isSelected} onClick={onClick}>
      <Stack
        data-testid="payment-method-option"
        direction="row"
        alignItems="center"
        gap={spacing.stack.xxxsmall}
      >
        <Stack
          px={spacing.inset.xxxsmall}
          py={spacing.inset.quark}
          border={`${borders.width.thin} solid ${colors.neutrals.outline}`}
          borderRadius={borders.radius.xsmall}
        >
          {icon}
        </Stack>
        <Stack gap={spacing.stack.xxxsmall} onClick={onClick}>
          <Typography
            variant="subtitleSmall"
            color={colors.neutrals['on-surface']}
          >
            {title}
          </Typography>
        </Stack>
      </Stack>
    </OptionCard>
  );
};

PaymentMethodOptionCard.defaultProps = {
  isSelected: false
};

PaymentMethodOptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default { PaymentMethodOptionCard };
