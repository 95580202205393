import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Icon } from '@loggi/loggi-icons';

import { currencyFormatter } from 'utils';

import Footer from '../../wallet/footer/footer.component';
import { CREDIT_CARD, PIX, LOGGI_FREE } from '../constants';

const AVAILABLE_MESSAGES = [
  400,
  409,
  412,
  500,
  'scheduleAvailabilityError',
  'addressLengthExceededError'
];
const USE_EX_MESSAGE_STATUS = [400];

export const PaymentError = ({
  error,
  amount,
  paymentMethod,
  onRetry,
  onCancel
}) => {
  const { t } = useTranslation('ui');

  const { status, message } = error;
  const errorStatusCode = AVAILABLE_MESSAGES.includes(status) ? status : 500;
  const hasMessage = !!message;
  const shouldUseTheExMessage = USE_EX_MESSAGE_STATUS.includes(status);
  const title = t(`payment.errors.${errorStatusCode}.title`);
  const description = t(`payment.errors.${errorStatusCode}.description`);

  const getPaymentMethod = () => {
    if (paymentMethod === LOGGI_FREE) {
      return t('payment.errorFooter.paymentMethod.loggiFree');
    }
    if (paymentMethod === CREDIT_CARD) {
      return t('payment.errorFooter.paymentMethod.creditCard');
    }
    if (paymentMethod === PIX) {
      return t('payment.errorFooter.paymentMethod.pix');
    }
    return t('payment.errorFooter.paymentMethod.wallet');
  };

  return (
    <Stack pt={spacing.stack.xxxlarge} data-testid="payment-error">
      <Stack p={spacing.stack.xxsmall} gap={spacing.stack.xxxsmall}>
        <Icon
          name="CircleError"
          size="large"
          color={colors.feedback.negative}
        />
        <Typography
          variant="headingMedium"
          color={colors.neutrals['on-surface']}
        >
          {title}
        </Typography>
        <Typography
          variant="bodyTextLarge"
          color={colors.neutrals['on-surface']}
        >
          {shouldUseTheExMessage && hasMessage ? message : description}
        </Typography>
        <Footer>
          <Footer.Details>
            <Footer.DetailsItem
              title={t('payment.errorFooter.value')}
              value={currencyFormatter(amount)}
            />
            <Footer.DetailsItem
              title={t('payment.errorFooter.paymentMethod.title')}
              value={getPaymentMethod()}
            />
          </Footer.Details>
          <Footer.PrimaryAction
            title={t('payment.errorFooter.buttons.tryAgain')}
            onClick={onRetry}
          />
          <Footer.SecondaryAction
            title={t('payment.errorFooter.buttons.cancel')}
            onClick={onCancel}
          />
        </Footer>
      </Stack>
    </Stack>
  );
};

PaymentError.defaultProps = {
  error: { status: 500, message: '' }
};
PaymentError.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    message: PropTypes.string
  }),
  amount: PropTypes.number.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default { PaymentError };
