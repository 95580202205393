import { getUser } from '@loggi/authentication-lib';
import TagManager from 'react-gtm-module';

export const addUserIdToGtmUseCase = async (userId = null) => {
  const { id } = await (!userId ? getUser() : { id: userId });
  const gtmDataLayersArgs = { dataLayer: { userId: id } };
  TagManager.dataLayer(gtmDataLayersArgs);
};

export default { addUserIdToGtmUseCase };
