import { getFsValueUseCase } from '../get-fs-value/get-fs-value.use-case';

export const isUserAllowedOnBeyondUseCase = async userEmail => {
  if (!userEmail) return false;
  const [enableForLoggers, allowedList] = await Promise.all([
    getFsValueUseCase('enable_beyond_for_loggers'),
    getFsValueUseCase('beyond_testers')
  ]);

  const isLogger = userEmail.endsWith('@loggi.com');
  const isInAllowedList = allowedList.includes(userEmail);
  const isAnEnabledLogger = isLogger && enableForLoggers;

  return isAnEnabledLogger || isInAllowedList;
};

export default { isUserAllowedOnBeyondUseCase };
