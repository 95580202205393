import PropTypes from 'prop-types';
import React from 'react';
import { Box, Checkbox, Link, styled } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { blue, red } from 'theme/colors';

export default function CheckboxTerms({ error, onChange, checked }) {
  const { t } = useTranslation('ui');

  const TERMS_OF_USE =
    'https://www.loggi.com/termos-condicoes-clientes-loggi-envios/';
  const PRIVACY_POLICIES = 'https://www.loggi.com/aviso-privacidade-clientes/';

  const CustomCheckbox = styled(Checkbox)({
    padding: '0px',
    marginRight: '16px',
    marginLeft: '-2px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: blue[500]
    }
  });

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box fontSize={16} lineHeight="18.29px">
        <Trans
          t={t}
          i18nKey="signUpCompany.input.checkbox.terms"
          components={[
            <Link
              href={TERMS_OF_USE}
              color="primary"
              key={1}
              underline="none"
              target="_blank"
            />,
            <Link
              href={PRIVACY_POLICIES}
              color="primary"
              key={2}
              underline="none"
              target="_blank"
            />
          ]}
        />
        <br />
        {error && (
          <Box
            color={red[500]}
            fontWeight="fontWeightBold"
            fontSize={14}
            lineHeight="16px"
            pt={2}
          >
            {t('signUpCompany.input.checkbox.errorMessage')}
          </Box>
        )}
      </Box>
      <Box alignItems="center">
        <CustomCheckbox
          checked={checked}
          onChange={onChange}
          size="medium"
          style={error ? { color: red[500], margin: 0 } : { margin: 0 }}
        />
      </Box>
    </Box>
  );
}

CheckboxTerms.propTypes = {
  error: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};
