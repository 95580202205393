import { NumericInput } from 'UI/components/input';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CommonPropTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

export const HeightInput = ({ value, onChange, error }) => {
  const { t } = useTranslation('ui');
  return (
    <NumericInput
      id="height-input"
      onChange={onChange}
      value={value}
      unit="cm"
      nonZero
      label={t('packageDimensionsPage.labels.height')}
      error={error}
    />
  );
};

HeightInput.propTypes = CommonPropTypes;
HeightInput.defaultProps = {
  value: undefined,
  error: undefined
};

export const WidthInput = ({ value, onChange, error }) => {
  const { t } = useTranslation('ui');
  return (
    <NumericInput
      id="width-input"
      onChange={onChange}
      value={value}
      unit="cm"
      nonZero
      label={t('packageDimensionsPage.labels.width')}
      error={error}
    />
  );
};

WidthInput.propTypes = CommonPropTypes;
WidthInput.defaultProps = {
  value: undefined,
  error: undefined
};

export const LengthInput = ({ value, onChange, error }) => {
  const { t } = useTranslation('ui');
  return (
    <NumericInput
      id="length-input"
      onChange={onChange}
      value={value}
      unit="cm"
      nonZero
      label={t('packageDimensionsPage.labels.length')}
      error={error}
    />
  );
};

LengthInput.propTypes = CommonPropTypes;
LengthInput.defaultProps = {
  value: undefined,
  error: undefined
};

export const WeightInput = ({ value, onChange, error }) => {
  // check nullable to avoid NaN
  const valueKg = value ? value / 1000 : undefined;
  const kgToG = kg => onChange(kg ? Math.round(kg * 1000) : undefined);

  const { t } = useTranslation('ui');
  return (
    <NumericInput
      id="weight-input"
      onChange={kgToG}
      value={valueKg}
      precisionDigits={3}
      unit="kg"
      nonZero
      label={t('packageDimensionsPage.labels.weight')}
      error={error}
    />
  );
};

WeightInput.propTypes = CommonPropTypes;
WeightInput.defaultProps = {
  value: undefined,
  error: undefined
};
