import { Box } from '@mui/material';
import React from 'react';
import { SkeletonLoader } from 'UI/components';

export const PickupScheduleLoader = () => {
  return (
    <Box role="progressbar">
      <SkeletonLoader height={16} width={64} />
      <Box
        borderRadius="8px"
        border="2px solid rgba(0, 0, 0, 0.25)"
        p={4}
        mt={1}
        mb={3}
        width="fit-content"
      >
        <Box mb={1}>
          <SkeletonLoader height={8} width={64} />
        </Box>
        <SkeletonLoader height={8} width={128} />
      </Box>
      <SkeletonLoader height={16} width={128} />
      <Box
        borderRadius="8px"
        border="2px solid rgba(0, 0, 0, 0.25)"
        p={4}
        mt={1}
        width="fit-content"
      >
        <Box mb={1}>
          <SkeletonLoader height={8} width={64} />
        </Box>
        <SkeletonLoader height={8} width={128} />
      </Box>
    </Box>
  );
};

export default { PickupScheduleLoader };
