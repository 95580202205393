import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import {
  formatPhoneNumber,
  getCreationMethod,
  getFirstName,
  getLastName
} from 'crm/utils';

export default class AccountIntegratorsInformed extends CRMBaseEvent {
  constructor({
    name,
    userType,
    cnpj,
    sharedName,
    email,
    phone,
    provider,
    cep,
    segmentName,
    averageTicket,
    integrators,
    otherIntegrator,
    shipmentQualification,
    volumeQualification
  }) {
    super();
    this.eventName = EVENTS.ACCOUNT_INTEGRATORS_INFORMED;
    this.payload = {
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      phone: formatPhoneNumber(phone),
      registration_method: getCreationMethod(provider),
      user_type: userType,
      document: cnpj,
      shared_name: sharedName,
      cep,
      segment: segmentName,
      average_ticket: averageTicket,
      integrators,
      other_integrator: otherIntegrator,
      shipment_qualification: shipmentQualification,
      volume_qualification: volumeQualification
    };
  }
}
