/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */

/**
 * This class represents a address in Brazil, it's based on the
 * CorreiosAddress proto.
 *
 * @class
 */
export default class CorreiosAddress {
  /**
   * @param {object} params
   * @param {string} params.logradouro
   * @param {string} params.numero
   * @param {string} params.complemento
   * @param {string} params.bairro
   * @param {string} params.cep
   * @param {string} params.cidade
   * @param {string} params.uf
   */
  constructor(params = {}) {
    /**
     * @type {string}
     * @public
     */
    this.cep = params.cep || '';

    /**
     * @type {string}
     * @public
     */
    this.logradouro = params.logradouro || '';

    /**
     * @type {string}
     * @public
     */
    this.numero = params.numero || '';

    /**
     * @type {string}
     * @public
     */
    this.complemento = params.complemento || '';

    /**
     * @type {string}
     * @public
     */
    this.bairro = params.bairro || '';

    /**
     * @type {string}
     * @public
     */
    this.cidade = params.cidade || '';

    /**
     * The state represented in two letters
     *
     * @example 'SP'
     *
     * @type {string}
     * @public
     */
    this.uf = params.uf || '';
  }

  /**
   * Returns the CEP formatted
   */
  get formattedCEP() {
    return this.cep.replace(/(\d{5})(\d{3})/, '$1-$2');
  }

  /**
   * Returns the address description formatted as a string.
   *
   * It returns the whole address if it's present, or just the formatted
   * postal code if only it is present.
   */
  description() {
    if (!!this.logradouro && !!this.numero) {
      const { structured } = this.toStructured();
      return `${structured.main} - ${structured.secondary}`;
    }

    return this.formattedCEP;
  }

  /**
   * @returns {Map<string, Error>}
   */
  errors() {
    const errors = new Map();
    const cepValidator = new ValidateCorreiosAddressCEP(this.cep);

    if (cepValidator.validate()) {
      errors.set('cep', cepValidator.validate());
    }

    return errors;
  }

  /**
   * Verifies if the address is valid
   * @returns {boolean}
   */
  isValid() {
    return !this.errors().size;
  }

  /** @override overrides serializer used by JSON.stringify() */
  toJSON() {
    return {
      logradouro: this.logradouro,
      numero: this.numero,
      complemento: this.complemento,
      bairro: this.bairro,
      cep: this.cep,
      cidade: this.cidade,
      uf: this.uf
    };
  }

  toStructured() {
    return {
      structured: {
        main: `${this.logradouro}, ${this.numero || 'S/N'}`,
        secondary: `${this.bairro}, ${this.cidade} - ${this.uf}, ${
          this.formattedCEP
        }`
      },
      complement: this.complemento
    };
  }
}

class ValidateCorreiosAddressCEP {
  constructor(cep) {
    this.cep = cep;
  }

  validate() {
    return this._isEmpty() || this._isLength();
  }

  /**
   * @private
   * @returns {Error | null}
   */
  _isEmpty() {
    if (!this.cep) {
      return new EmptyCEPError();
    }
    return null;
  }

  /**
   * @private
   * @returns {Error | null}
   */
  _isLength() {
    if (this.cep.length !== 8) {
      return new InvalidCEPLengthError();
    }
    return null;
  }
}

/**
 * Describes the error when the CEP is empty
 */
export class EmptyCEPError extends Error {
  constructor() {
    super('Insira um CEP');
  }
}

/**
 * Describes the error when the CEP length is invalid
 */
export class InvalidCEPLengthError extends Error {
  constructor() {
    super('CEP deve ter 8 dígitos');
  }
}
