const getHelpCenterPagePath = ({
  companyId,
  loggiKey,
  trackingKey,
  occasionalTrackingStatus,
  returnTo
}) => {
  let url = `${process.env.REACT_APP_ZENDESK_URL}/?company_id=${companyId}`;
  if (loggiKey) {
    url += `&loggi_key=${loggiKey}`;
  }
  if (trackingKey) {
    url += `&tracking_key=${trackingKey}`;
  }
  if (occasionalTrackingStatus) {
    url += `&occasional_status=${occasionalTrackingStatus}`;
  }
  if (returnTo) {
    url += `&return_to=${returnTo}`;
  }

  return url;
};

export function useSupport({
  companyId,
  loggiKey,
  trackingKey,
  occasionalTrackingStatus
}) {
  const returnTo = window.location.href;
  const supportPage = getHelpCenterPagePath({
    companyId,
    loggiKey,
    trackingKey,
    occasionalTrackingStatus,
    returnTo
  });

  const redirect = () => {
    return window.location.replace(supportPage);
  };

  return {
    redirect,
    supportPage
  };
}

export default { useSupport };
