import icons from 'constants/icons-urls.json';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/components';

const GoogleSignInButton = ({ onClick }) => {
  const { t } = useTranslation('ui');
  return (
    <Button
      fullWidth
      icon={icons.socialGoogle}
      label={t('signIn.googleButtonLabel')}
      onClick={onClick}
      variant="outlined-neutral"
    />
  );
};

GoogleSignInButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default GoogleSignInButton;
