/* eslint-disable react/jsx-props-no-spreading */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { grey, root } from 'theme/colors';
import { SvgIcon } from 'UI/components/svg-icon/svg-icon.component';

export const AddressAutocompleteOption = (props, option) => {
  const {
    structured_formatting: {
      main_text: mainText,
      secondary_text: secondaryText
    }
  } = option;

  return (
    <Box
      {...props}
      alignItems="center"
      borderBottom={`1px solid ${grey[100]}`}
      color={root[900]}
      component="li"
      display="flex"
      style={{ padding: '16px 24px' }}
      width="100%"
    >
      <Box
        alignItems="center"
        border={`1px solid ${grey[100]}`}
        borderRadius="50%"
        display="flex"
        height="40px"
        justifyContent="center"
        width="40px"
      >
        <SvgIcon color={root[900]} icon="pin" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        ml={2}
        width="calc(100% - 56px)"
      >
        <Typography variant="body1">
          <b>{mainText}</b>
        </Typography>
        <Typography variant="body1">{secondaryText}</Typography>
      </Box>
    </Box>
  );
};

export default {};
