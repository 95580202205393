/* eslint-disable camelcase */
export default class ShipmentOrderSummary {
  constructor({
    couponCode,
    loggiKey,
    paymentData,
    trackingKey,
    pickupOrderSchedule
  } = {}) {
    this.couponCode = couponCode;
    this.loggiKey = loggiKey;
    /** @type {{ brand: string, id: string, number: string}} */
    this.paymentData = paymentData;
    this.trackingKey = trackingKey;
    this.pickupOrderSchedule = pickupOrderSchedule;
  }

  static parseApiResponse({
    payment_data,
    successful_packages,
    pickup_order_schedule
  }) {
    const [{ tracking_key, tracking_code, loggi_key }] = successful_packages;
    const payload = {
      trackingKey: tracking_key || tracking_code,
      loggiKey: loggi_key,
      pickupOrderSchedule: pickup_order_schedule
    };
    if (payment_data) {
      const { payment_document } = payment_data;
      const { credit_card, id } = payment_document;
      const { last_digits: number, card_brand: brand } = credit_card;
      Object.assign(payload, { paymentData: { brand, id, number } });
    }
    return new ShipmentOrderSummary(payload);
  }
}
