import { Box } from '@mui/material';
import { ValidatorBuilder } from 'infra';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StepFormWrapper } from 'UI/components';
import { useUserRecords } from 'UI/contexts';
import AccountDocumentInformed from 'crm/entities/events/account-document-informed/account-document-informed';
import CRMUser from 'crm/entities/user/crm-user.model';

const CpfStepForm = ({ onSubmit }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();

  const fields = {
    cpf: {
      label: t('signUpCompany.input.cpf.label'),
      type: 'cpf',
      value: userRecords.cpf,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .cpfPattern(t('signUpCompany.input.cpf.errorMessage'))
        .build()
    }
  };

  const handleOnSubmit = userCredentials => {
    const { cpf } = userCredentials;
    const updatedUserRecords = { ...userRecords, cpf };
    setUserRecords(updatedUserRecords);
    const { email } = userRecords;

    new CRMUser({
      email,
      document: cpf
    }).sendToCrm();

    new AccountDocumentInformed({
      ...userRecords,
      document: cpf
    }).sendToCrm();

    return onSubmit(updatedUserRecords);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <StepFormWrapper
        fields={fields}
        onSubmit={handleOnSubmit}
        buttonLabel={t('signUpCompany.submitButton.label')}
      />
    </Box>
  );
};

CpfStepForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CpfStepForm;
