export const maxDelayDebounce = (fn, delay = 1000) => {
  let timeout = null;
  return function debounced(...args) {
    return new Promise((resolve, reject) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        fn.apply(fn, args)
          .then(resolve)
          .catch(reject);
      }, delay);
    });
  };
};

export default { maxDelayDebounce };
