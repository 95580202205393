import { useMemo } from 'react';
import { applyFiltersToShipments } from '../utils/filters.utils';

const useFilteredShipments = (shipments, statuses, search, grouping, sort) => {
  return useMemo(() => {
    return applyFiltersToShipments(shipments, statuses, search, grouping, sort);
  }, [shipments, statuses, search, grouping, sort]);
};

export default useFilteredShipments;
