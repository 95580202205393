import { useQuery } from '@tanstack/react-query';
import walletApi from 'service/wallet-api';

const getQueryKey = (companyId, entryType, startDate, endDate, limit, skip) => [
  'wallet-statement',
  String(companyId),
  entryType,
  startDate,
  endDate,
  limit,
  skip
];

export default function useWalletStatement({
  companyId,
  entryType,
  startDate,
  endDate,
  limit,
  skip
}) {
  const key = getQueryKey(
    companyId,
    entryType,
    startDate,
    endDate,
    limit,
    skip
  );

  return useQuery(
    key,
    async () => {
      try {
        const result = await walletApi.getStatements({
          companyId,
          entryType,
          startDate,
          endDate,
          limit,
          skip
        });
        return result;
      } catch (error) {
        // If the company does not have a wallet, the API returns a 404. It means the company has no statement.
        if (error.response && error.response.status === 404) {
          return { statements: [] };
        }

        throw error;
      }
    },
    {
      enabled: !!companyId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}
