import { getUser } from '@loggi/authentication-lib';
import { getUserCompanyIdUseCase } from '../get-user-company-id/get-user-company-id.use-case';

export const getCurrentUserUseCase = () =>
  getUser().then(async user => {
    if (!user) return null;
    const companyId = await getUserCompanyIdUseCase(user);
    const { email, id: userId, name } = user;
    return { companyId, email, name, userId };
  });

export default { getCurrentUserUseCase };
