import { Box, Typography } from '@mui/material';
import { AmateurQuotingResponse } from 'models';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { root } from 'theme/colors';
import { buildsIndispatchServiceInformations } from 'utils';
import useDeliveryServicesOrder from 'UI/shipment/hooks/delivery-services-order';
import { Loading } from './loading/loading.component';
import { ServiceButton } from './service-button/service-button.component';

export const DeliveryServices = ({
  onClick,
  quoting: { isLoading, value }
}) => {
  const { t } = useTranslation('ui');
  const servicesOrder = useDeliveryServicesOrder();

  if (!value && !isLoading) return null;
  if (isLoading) return <Loading />;

  const {
    availableServices: { corp, beyond, dropoff, indespachoPac, indespachoSedex }
  } = value;

  const indespacho = buildsIndispatchServiceInformations(
    indespachoPac,
    indespachoSedex
  );

  const servicesByName = { corp, beyond, dropoff, indespacho };
  const orderedServiceTypes = servicesOrder.map(
    serviceName => servicesByName[serviceName]
  );

  return (
    value.length > 0 && (
      <>
        <Box color={root[900]} pb={1}>
          <Typography variant="subtitle2">
            {t('budgetV2.labels.deliveryServices', {
              count: value.length
            })}
          </Typography>
        </Box>
        {orderedServiceTypes
          .filter(quoting => quoting)
          .map(quoting => (
            <Box key={quoting.serviceType} py={1}>
              <ServiceButton onClick={onClick} quoting={quoting} />
            </Box>
          ))}
      </>
    )
  );
};

DeliveryServices.propTypes = {
  onClick: PropTypes.func.isRequired,
  quoting: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    value: PropTypes.instanceOf(AmateurQuotingResponse)
  }).isRequired
};

export default { DeliveryServices };
