import React, { useState, useEffect } from 'react';
import { Box, Typography, Stack, IconButton } from '@mui/material';
import { Icon } from '@loggi/loggi-icons';
import { Drawer } from '@loggi/front-design-system';
import { colors, spacing } from '@loggi/front-design-tokens';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDeliveryGeolocation } from 'hooks/delivery-geolocation/use-delivery-geolocation.hook';
import { formatTimestampToFullDate } from 'UI/shipments-v2/utils/date.utils';

export const ShipmentDeliveryReceiptDrawer = ({
  open,
  senderName,
  handleClickBack,
  serviceType,
  trackingCode,
  recipientName,
  recipientDocument,
  address,
  locationDescription,
  links = [],
  deliveredTime
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const { isMobileDevice } = useShipments();
  const { isEnabled: isGeolocationEnabled } = useDeliveryGeolocation();
  const { t } = useTranslation('ui');

  const signature = links
    .filter(link => link.rel === 'digital_signature')
    .map(link => link.href)[0];

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const getDeliveryLocationDescriptionMap = key => {
    const translationsMap = {
      INVALID: 'invalid',
      RECIPIENT: 'recipient',
      OTHER_RECIPIENT: 'otherRecipient',
      CONCIERGE: 'concierge',
      MAILBOX: 'mailbox'
    };

    return `shipmentDetailsV2.packageDeliveredInfo.deliveryDetailsMap.${
      translationsMap[key]
    }`;
  };

  if (!isOpen) return <></>;

  return (
    <Drawer
      mobile={isMobileDevice}
      closeButton={false}
      backButton
      open={isOpen}
      onClickBack={handleClickBack}
    >
      <Box
        width={isMobileDevice ? '100%' : '480px'}
        bgcolor={colors.neutrals['on-surface-inverse']}
        py={spacing.inset.xxxsmall}
      >
        <Stack pb={spacing.stack.small}>
          <Box px={spacing.inset.large}>
            <Typography pb={spacing.inset.nano} variant="overlineSmall">
              {t(serviceType)} • {trackingCode}
            </Typography>
            <Typography variant="subtitleLarge">
              {t('shipmentDetailsV2.shipmentDeliveryReceipt.title')}
            </Typography>
          </Box>
        </Stack>
        <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
          <Typography variant="subtitleLarge">
            {t('shipmentDetailsV2.shipmentDeliveryReceipt.sender', {
              senderName
            })}
          </Typography>
          <Typography variant="bodyTextMedium">
            {t('shipmentDetailsV2.shipmentDeliveryReceipt.trackingCode', {
              trackingCode
            })}
          </Typography>
        </Stack>
        <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
          <Typography variant="subtitleMedium">
            {t('shipmentDetailsV2.shipmentDeliveryReceipt.name')}
          </Typography>
          <Typography variant="bodyTextMedium">{recipientName}</Typography>
        </Stack>
        <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
          <Typography variant="subtitleMedium">
            {t('shipmentDetailsV2.shipmentDeliveryReceipt.document')}
          </Typography>
          <Typography variant="bodyTextMedium">{recipientDocument}</Typography>
        </Stack>
        <Stack
          borderBottom={`1px solid ${colors.neutrals['outline-low']}`}
          p={spacing.inset.large}
          gap={spacing.stack.nano}
        >
          <Typography variant="subtitleMedium">
            {t('shipmentDetailsV2.shipmentDeliveryReceipt.locationDescription')}
          </Typography>
          <Typography variant="bodyTextMedium">
            {t(getDeliveryLocationDescriptionMap(locationDescription))}
          </Typography>
        </Stack>
        {isGeolocationEnabled && (
          <Box alignItems="center" display="flex" p={spacing.inset.large}>
            <Box flexGrow={1}>
              <Stack gap={spacing.stack.nano}>
                <Typography variant="subtitleMedium">
                  {t('shipmentDetailsV2.shipmentDeliveryReceipt.geolocation')}
                </Typography>
                <Typography variant="bodyTextMedium">{address}</Typography>
              </Stack>
            </Box>
            <Box>
              <IconButton>
                <Icon name="MarkerPin01" size="small" />
              </IconButton>
            </Box>
          </Box>
        )}
        <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
          <Typography variant="subtitleMedium">
            {t('shipmentDetailsV2.shipmentDeliveryReceipt.dateAndTime')}
          </Typography>
          <Typography variant="bodyTextMedium">
            {formatTimestampToFullDate(deliveredTime)}
          </Typography>
        </Stack>
        <Stack
          p={spacing.inset.large}
          borderBottom={`1px solid ${colors.neutrals['outline-low']}`}
        >
          <Typography variant="subtitleMedium">
            {t('shipmentDetailsV2.shipmentDeliveryReceipt.signature')}
          </Typography>
        </Stack>
        <Stack
          px={spacing.inset.xxlarge}
          py={spacing.inset.medium}
          justifyContent="center"
          alignItems="center"
        >
          <img
            style={{
              maxWidth: '250px',
              maxHeight: '250px',
              width: 'auto',
              height: 'auto'
            }}
            src={signature}
            alt={t('shipmentDetailsV2.shipmentDeliveryReceipt.signature')}
          />
        </Stack>
      </Box>
    </Drawer>
  );
};

ShipmentDeliveryReceiptDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  senderName: PropTypes.string.isRequired,
  handleClickBack: PropTypes.func.isRequired,
  serviceType: PropTypes.string.isRequired,
  trackingCode: PropTypes.string.isRequired,
  recipientName: PropTypes.string.isRequired,
  recipientDocument: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  locationDescription: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      rel: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  ).isRequired,
  deliveredTime: PropTypes.instanceOf(Date).isRequired
};

export default ShipmentDeliveryReceiptDrawer;
