import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';
import { colors } from '@loggi/front-design-tokens';

import { checkIsMobile } from 'utils/check-is-mobile/check-is-mobile.helper';

import PrimaryAction from './primary-action';
import Details from './details';
import DetailsItem from './details-item';
import SecondaryAction from './secondary-action';

export default function Footer({ children }) {
  const isMobileDevice = checkIsMobile();

  return (
    <ThemeProvider theme={theme}>
      <Stack
        width="100%"
        position={isMobileDevice ? 'fixed' : 'absolute'}
        bottom="0"
        right="0"
        left="0"
        backgroundColor={colors.neutrals.surface}
      >
        {children}
      </Stack>
    </ThemeProvider>
  );
}

Footer.propTypes = {
  children: PropTypes.node.isRequired
};

Footer.Details = Details;
Footer.DetailsItem = DetailsItem;
Footer.PrimaryAction = PrimaryAction;
Footer.SecondaryAction = SecondaryAction;
