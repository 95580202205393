const PACKAGE_SIZES = {
  /** @type {import("UI/shipment/state-machine/types.d").PackageDimensions} */
  PP: {
    lengthCm: 31,
    widthCm: 41,
    heightCm: 1,
    weightG: 201
  },
  /** @type {import("UI/shipment/state-machine/types.d").PackageDimensions} */
  P: {
    heightCm: 24,
    lengthCm: 16,
    widthCm: 12,
    weightG: 701
  },
  /** @type {import("UI/shipment/state-machine/types.d").PackageDimensions} */
  M: {
    lengthCm: 32,
    widthCm: 24,
    heightCm: 20,
    // We are appending +1 in all sizes as a workaround to signal that the package needs packaging
    weightG: 2.5 * 1000 + 1
  },
  /** @type {import("UI/shipment/state-machine/types.d").PackageDimensions} */
  G: {
    lengthCm: 48,
    widthCm: 37,
    heightCm: 34,
    weightG: 10 * 1000 + 1
  }
};

export default PACKAGE_SIZES;
