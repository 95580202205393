import { Box, Typography } from '@mui/material';
import { useCompanyData } from 'hooks/company-data/company-data.hook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from 'routes';
import { root } from 'theme/colors';
import { InfiniteLoading } from 'UI/components';
import TitleBar from 'UI/pages/tracking/title-bar/title-bar.component';
import SupportRequestConfirmationModal from './confirmation-modal/confirmation-modal.component';
import SupportForm from './form/form.component';
import SupportLoading from './loading/loading.component';
import useCreateSupportTicket from './use-create-support-ticket/use-create-support-ticket';

export const supportPagePath = ({ companyId, loggiKey, defaultReason = '' }) =>
  `${ROUTES.support
    .replace(':companyId', companyId)
    .replace(':loggiKey?', loggiKey)}?default_reason=${encodeURIComponent(
    defaultReason
  )}`;

const useDefaultValues = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return {
    defaultReason: params.get('default_reason') ?? ''
  };
};

const SupportPage = () => {
  const { t } = useTranslation('ui');
  const { companyId, loggiKey } = useParams();
  const { goBack } = useHistory();
  const { defaultReason } = useDefaultValues();
  const supportTicket = useCreateSupportTicket();

  const companyData = useCompanyData(companyId);

  const handleSubmit = ({ reason, details }) =>
    supportTicket.createTicket({ companyId, loggiKey, reason, details });

  const handleGoBack = () => goBack();

  if (companyData.isLoading) {
    return (
      <Box display="flex" height="100vh" mx={3}>
        <InfiniteLoading title={t('support.form.loading.message')} />
      </Box>
    );
  }
  if (supportTicket.isLoading) return <SupportLoading />;

  return (
    <Box bgcolor={root[0]}>
      <TitleBar title={t('support.form.titleBar')} />

      <SupportForm defaultReason={defaultReason} onSubmit={handleSubmit} />

      <Box px={3}>
        <Typography>
          <strong>{t('support.form.contact.title')}</strong>
        </Typography>
        <Typography>{t('support.form.contact.week')}</Typography>
        <Typography>{t('support.form.contact.weekend')}</Typography>
      </Box>

      <SupportRequestConfirmationModal
        open={supportTicket.isSuccess || supportTicket.isError}
        type={supportTicket.isSuccess ? 'success' : 'error'}
        onClick={handleGoBack}
      />
    </Box>
  );
};

export default SupportPage;
