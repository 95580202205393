import { spacing } from '@loggi/front-design-tokens';
import { Pagination, Stack } from '@mui/material';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import React from 'react';

export const ShipmentsPagination = () => {
  const { isMobileDevice, pagination } = useShipments();
  const { totalPages, page, setPage } = pagination;

  const handleChange = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {totalPages > 1 && (
        <Stack
          direction="row"
          pr={isMobileDevice ? 0 : spacing.inset.medium}
          pb={spacing.stack.medium}
          justifyContent={isMobileDevice ? 'center' : 'right'}
          alignItems="center"
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            showDivisor={false}
          />
        </Stack>
      )}
    </>
  );
};

export default ShipmentsPagination;
