import { getPackageSizeDimensions } from 'UI/shipment/state-machine/utils';
import { ADDRESS_BY, REVENUE_EVENTS } from 'crm/constants';
import CRMBaseRevenueEvent from 'crm/entities/revenue-events/crm-base-revenue-event';
import { getPackagingValue, getServiceTypeValue } from 'crm/utils';

export default class ShipmentCreationCompleted extends CRMBaseRevenueEvent {
  constructor(
    price,
    {
      trackingKey,
      packaging,
      serviceType,
      addressBy,
      originFullAddress,
      destinationFullAddress,
      originCity,
      destinationCity,
      recipientName,
      coupon,
      goodsvalue,
      dimensions
    }
  ) {
    super();
    super.eventName = REVENUE_EVENTS.SHIPMENT_CREATION_COMPLETED;
    super.amount = price ? Number(price) : 0;
    super.payload = {
      loggi_tracking_key: trackingKey,
      shipment_type: serviceType,
      packaging,
      address_by: addressBy,
      origin_full_address: originFullAddress,
      destination_full_address: destinationFullAddress,
      origin_city: originCity,
      destination_city: destinationCity,
      recipient_name: recipientName,
      coupon,
      goodsvalue: Number(goodsvalue),
      dimensions
    };
  }

  /**
   * @param {{ context: import('UI/shipment/state-machine/types').MachineContext }}
   */
  static fromShipmentContext({ context }) {
    return new ShipmentCreationCompleted(context.shipmentOrder.price, {
      trackingKey: context.shipmentOrder.trackingKey,
      packaging: getPackagingValue(context.packagingService),
      serviceType: getServiceTypeValue(context.serviceType),
      addressBy: context.pickupAddress ? ADDRESS_BY.GOOGLE : ADDRESS_BY.CEP,
      originFullAddress:
        context.pickupAddress?.description ||
        context.originAddress.description(),
      destinationFullAddress:
        context.deliveryAddress?.description ||
        context.destinationAddress.description(),
      originCity:
        context.pickupAddress?.getCityState() ||
        context.originAddress.correios.cidade,
      destinationCity:
        context.deliveryAddress?.getCityState() ||
        context.destinationAddress.correios.cidade,
      recipientName: context.deliveryCustomer.name,
      coupon: context.coupon,
      goodsvalue: context.insurance,
      dimensions: getPackageSizeDimensions(context)
    });
  }
}
