/* eslint-disable camelcase */
import { Box, Typography } from '@mui/material';
import PickupScheduleSelect from 'UI/shipment/components/pickup-schedule-select/pickup-schedule-select.component';
import Footer from 'UI/shipment/components/shipment-footer';
import useAddressCoverage from 'UI/shipment/hooks/address-coverage';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { calculateAvailableSchedulingTimeSlotsUseCase } from 'domain/use-cases';
import { useSnackbar } from 'hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { root } from 'theme/colors';

export default function PickupSchedulePage() {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();
  const [pickupScheduleValue, setPickupScheduleValue] = useState(null);
  const showSnackbar = useSnackbar();
  const [availableSlots, setAvailableSlots] = useState(null);
  const shipmentContext = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const { pickupAddress, originAddress } = shipmentContext.context;
  const addressCoverageQuery = useAddressCoverage({
    address: pickupAddress || originAddress,
    companyId,
    async onSuccess(response) {
      if (!response.payload?.pickupWorkingWeekdays) {
        showSnackbar({
          buttonLabel: t('pickupScheduleV2.goBackButtonLabel'),
          message: t('pickupScheduleV2.errorMessages.unexpected'),
          onClose: () => shipmentDispatcher.noPickupSchedule()
        });
      }
      const slots = await calculateAvailableSchedulingTimeSlotsUseCase(
        pickupAddress || originAddress.toLegacyAddress(),
        response.payload
      );

      setAvailableSlots(slots);
    }
  });

  const isSubmitDisabled =
    !pickupScheduleValue ||
    !addressCoverageQuery.data?.payload?.pickupWorkingWeekdays;

  const handleSubmit = () => {
    shipmentDispatcher.continue(pickupScheduleValue);
  };

  return (
    <>
      <Box color={root[900]}>
        <Typography variant="subtitle1">
          {t('pickupScheduleV2.title')}
        </Typography>
      </Box>
      <Box my={4}>
        <PickupScheduleSelect
          availableSlots={availableSlots}
          onChange={setPickupScheduleValue}
        />
      </Box>
      <Footer showBorder>
        <Footer.EstimatePrice />
        <Footer.PrimaryAction
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        />
      </Footer>
    </>
  );
}
