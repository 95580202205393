import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { getDateTimeFromChosenSlot } from 'crm/utils';

export default class PickupScheduleSlotSelected extends CRMBaseEvent {
  constructor({ chosenSlot }) {
    super();
    this.eventName = EVENTS.PICKUP_SCHEDULE_SLOT_SELECTED;
    this.payload = {
      chosen_slot: getDateTimeFromChosenSlot(chosenSlot)
    };
  }
}
