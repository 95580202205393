import { Box, Fade } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@loggi/front-design-tokens';
import { InfiniteLoading } from 'UI/components';

export const LoadingRecharge = ({ isVisible }) => {
  const { t } = useTranslation('ui');
  return (
    <Fade in={isVisible} unmountOnExit mountOnEnter>
      <Box
        alignItems="center"
        bgcolor={colors.interaction['on-primary']}
        display="flex"
        height="100vh"
        left="0"
        position="fixed"
        top="0"
        width="100%"
        zIndex="9"
      >
        <InfiniteLoading
          title={t('wallet.validateRechargeLoading.title')}
          description={t('wallet.validateRechargeLoading.subtitle')}
          titleVariant="headingMedium"
          descriptionVariant="headingSmall"
        />
      </Box>
    </Fade>
  );
};

LoadingRecharge.propTypes = { isVisible: PropTypes.bool.isRequired };

export default { LoadingRecharge };
