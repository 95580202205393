/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@mui/material';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import FocusTrap from 'react-focus-lock';
import { injectLoader } from '../address-autocomplete.helpers';
import * as Styles from './address-autocomplete-input.style.module.css';

export const ADDRESS_AUTOCOMPLETE_INPUT_TEST_ID = 'address-autocomplete-input';

export const AddressAutocompleteInput = ({
  errMessage,
  inputRef,
  isLoading,
  placeholder,
  ...props
}) => {
  const InputProps = injectLoader({
    InputProps: props.InputProps,
    isLoading,
    variant: 'primary'
  });

  return (
    <FocusTrap>
      <Box mx={3} position="relative" ref={InputProps.ref}>
        <input
          {...omit(props.inputProps, 'disabled', 'readOnly', 'onBlur')}
          className={Styles.autocompleteInput}
          data-testid={ADDRESS_AUTOCOMPLETE_INPUT_TEST_ID}
          placeholder={placeholder}
          ref={node => {
            Object.assign(inputRef, { current: node });
            Object.assign(props.inputProps.ref, { current: node });
          }}
          role="textbox"
          type="text"
          value={props.inputProps.value}
          style={{ paddingRight: InputProps.endAdornment ? '28px' : 0 }}
        />
        {InputProps.endAdornment}
        {errMessage && <p className={Styles.errorMessage}>{errMessage}</p>}
      </Box>
    </FocusTrap>
  );
};

AddressAutocompleteInput.defaultProps = {
  className: '',
  errMessage: '',
  inputProps: {},
  InputProps: {},
  inputRef: { current: null },
  isLoading: false,
  placeholder: ''
};

AddressAutocompleteInput.propTypes = {
  className: PropTypes.string,
  errMessage: PropTypes.string,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  isLoading: PropTypes.bool,
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    ref: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onMouseDown: PropTypes.func,
    onFocus: PropTypes.func
  }),
  InputProps: PropTypes.shape({}),
  placeholder: PropTypes.string
};

export default { AddressAutocompleteInput };
