import { Box, styled, Typography } from '@mui/material';
import PackageDetailsModel from 'models/package-details.model';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { grey } from 'theme/colors';
import { SvgIcon } from 'UI/components';
import { currencyFormatter } from 'utils/currency-formatter/currency-formatter.helper';

const PackageDetailsPropType = PropTypes.instanceOf(PackageDetailsModel);

const PackagePickupTraitsShape = PropTypes.shape({
  isPackaged: PropTypes.bool.isRequired,
  isLabelled: PropTypes.bool.isRequired
});

const boxStyle = {
  alignItems: 'center',
  background: grey[50],
  borderRadius: '100%',
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  width: '40px'
};

const CustomBoxIcon = styled(Box)(boxStyle);

/**
 *
 * @param {object} props
 * @param {PackageDetailsModel} props.packageDetails
 * @param {{ isPackaged: bool, isLabelled: bool }} props.packagePickupTraits
 */
function PackageDimensions({ packageDetails, packagePickupTraits }) {
  const { t } = useTranslation('ui');
  const { isPackaged } = packagePickupTraits;
  const formatDimensions = packageDetails.formatDimensions();

  if (!isPackaged) {
    const packagingLabel = packageDetails.matchingPackagingLabel();
    const packagingLabelTranslation = packagingLabel
      ? t(`packagingDimensions.${packagingLabel}`)
      : formatDimensions;
    return (
      <>
        <CustomBoxIcon>
          <SvgIcon icon="boxItem" data-testid="box-icon" />
        </CustomBoxIcon>
        <Box>
          <Typography variant="body1" data-testid="packaging-title">
            {t('packagingType.loggiPackaging')}
          </Typography>
          <Box style={{ color: grey[200] }}>
            <Typography variant="body2" data-testid="packaging-description">
              {packagingLabelTranslation ?? formatDimensions}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <CustomBoxIcon>
        <SvgIcon icon="boxItem" data-testid="box-icon" />
      </CustomBoxIcon>
      <Box>
        <Typography variant="body1" data-testid="packaging-title">
          {t('packagingType.selfPackaging')}
        </Typography>
        <Box style={{ color: grey[200] }}>
          <Typography variant="body2" data-testid="packaging-description">
            {formatDimensions} • {packageDetails.formatWeightToKg()}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

PackageDimensions.propTypes = {
  packageDetails: PackageDetailsPropType.isRequired,
  packagePickupTraits: PackagePickupTraitsShape.isRequired
};

const PackageDetail = ({
  insuredValue,
  packageDetails,
  packagePickupTraits,
  trackingCode,
  paymentAmount
}) => {
  const { t } = useTranslation('ui');
  const formatInsurance = t(`packageInsurance.insurance`, {
    insuredValue: currencyFormatter(insuredValue)
  });
  const formatPaymentAmount = t('paymentAmount', {
    paymentAmount: currencyFormatter(paymentAmount)
  });
  const formatTrackingCode = t(`shippingCode`, {
    trackingCode: trackingCode.toUpperCase()
  });
  /* eslint-disable-next-line no-restricted-globals */
  const hasPaymentAmount = !isNaN(paymentAmount);

  return (
    <Box
      alignItems="center"
      display="grid"
      gridTemplateColumns="40px 1fr"
      style={{
        gridGap: '16px',
        gridRowGap: '24px'
      }}
    >
      <CustomBoxIcon>
        <SvgIcon icon="barcode" data-testid="barcode-icon" />
      </CustomBoxIcon>
      <Typography variant="body1">{formatTrackingCode}</Typography>
      <PackageDimensions
        packageDetails={packageDetails}
        packagePickupTraits={packagePickupTraits}
      />
      {hasPaymentAmount && (
        <>
          <CustomBoxIcon>
            <SvgIcon icon="coins" data-testid="money-coins-icon" />
          </CustomBoxIcon>
          <Typography variant="body1">{formatPaymentAmount}</Typography>
        </>
      )}
      <CustomBoxIcon>
        <SvgIcon icon="cashShield" data-testid="dollar-icon" />
      </CustomBoxIcon>
      <Typography variant="body1">{formatInsurance}</Typography>
    </Box>
  );
};

PackageDetail.propTypes = {
  trackingCode: PropTypes.string.isRequired,
  packageDetails: PackageDetailsPropType.isRequired,
  packagePickupTraits: PackagePickupTraitsShape.isRequired,
  insuredValue: PropTypes.number.isRequired,
  paymentAmount: PropTypes.number
};

PackageDetail.defaultProps = {
  paymentAmount: null
};

export default PackageDetail;
