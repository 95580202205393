/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

/**
 * It adds event listeners to the DOM based on the eventMap.
 *
 * @param {React.Component} WrappedComponent
 * @param {object} eventMap
 * @returns {React.Component}
 *
 * @example
 * const eventMap = {
 *  elementId1: {
 *   click: () => {doSomething()}},
 *   mouseover: () => {doSomethingElse()}
 *  },
 *  elementId2: {
 *    change: () => {doSomething()}}
 *  }
 * }
 *
 * const WrappedComponent = () => (
 * <div>
 *  <button type="submit" id="elementId1">Button 1</button>
 *  <input type="text" id="elementId2" />
 * </div>
 * );
 *
 * const EnhancedComponent = withEventsObserver(WrappedComponent, eventMap);
 */
export const withEventsObserver = (WrappedComponent, eventMap) => {
  const EnhancedComponent = props => {
    useEffect(() => {
      const eventListeners = [];

      // Add event listeners based on eventMap
      Object.entries(eventMap).forEach(([elementId, eventHandlers]) => {
        const element = document.getElementById(elementId);
        if (element) {
          Object.entries(eventHandlers).forEach(([eventName, eventHandler]) => {
            const listener = event => eventHandler({ event });
            element.addEventListener(eventName, listener);
            eventListeners.push({ elementId, eventName, listener });
          });
        }
      });
    }, []);

    return <WrappedComponent {...props} />;
  };
  return EnhancedComponent;
};

export default withEventsObserver;
