export const getCardsInfo = ({ serviceName }) => {
  const services = {
    indispatch: [
      {
        icon: 'warehouse',
        infoDescription: 'onboardPage.cardInfo.indispatchCollectDescription'
      },
      {
        icon: 'barcode',
        infoDescription: 'onboardPage.cardInfo.indispatchTagDescription',
        listInformation: [
          'onboardPage.cardInfo.indispatchPdfPrint',
          'onboardPage.cardInfo.indispatchPdfInformation'
        ]
      }
    ]
  };
  return services[serviceName];
};

export default { getCardsInfo };
