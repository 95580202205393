const androidRegex = /Beyond-Android\s*(?<version>\d+\.\d+(\.\d+)?)/m;
const iosRegex = /Beyond-iOS\s*(?<version>\d+\.\d+(\.\d+)?)/m;

export const getAndroidNativeAppVersion = () => {
  const result = navigator.userAgent.match(androidRegex);
  return result?.groups?.version;
};

export const getiOSNativeAppVersion = () => {
  const result = navigator.userAgent.match(iosRegex);
  return result?.groups?.version;
};

export default { getAndroidNativeAppVersion, getiOSNativeAppVersion };
