import { loggiWebApi } from '@loggi/authentication-lib';

const endpoints = {
  support: companyId => `/beyond/api/support/v1/companies/${companyId}/`
};

const supportApi = {
  createTicket({ companyId, loggiKey, phoneNumber, reason, details }) {
    const url = endpoints.support(companyId);

    return loggiWebApi
      .url(url)
      .content('application/json')
      .post({ loggi_key: loggiKey, phone_number: phoneNumber, reason, details })
      .res();
  }
};

export default supportApi;
