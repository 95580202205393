const HOTJAR_ALLOWED_TYPES = ['number', 'text'];
export const HOTJAR_CLASS_NAME = 'data-hj-allow';

export const addHotjarClassToInputProps = ({ inputProps, omit, type }) => {
  const isAllowedType = HOTJAR_ALLOWED_TYPES.includes(type);
  if (omit || !isAllowedType) return inputProps;
  const { className } = inputProps;
  return {
    ...inputProps,
    className: `${className} ${HOTJAR_CLASS_NAME}`
  };
};
