import { validate as isCnpjValid } from 'cnpj';
import Validator from '../validator';

export function validateCnpj(cnpj) {
  if (!cnpj) return false;
  return isCnpjValid(cnpj);
}

export default class CnpjValidator extends Validator {
  static validate(string) {
    return validateCnpj(string);
  }
}
