import { Box, CircularProgress } from '@mui/material';
import { FORMS_VARIANTS } from 'constants/color-variants.constants';
import { get } from 'lodash';
import { Address } from 'models';
import * as utils from 'utils';
import React from 'react';

export const parseOptionIntoAddressModel = ({
  description,
  place_id: placeId,
  structured_formatting: { main_text: main, secondary_text: secondary }
}) => {
  return new Address({
    description,
    placeId,
    structured: { main, secondary }
  });
};

export const makeSyntheticEvent = val => ({ target: { value: val } });

export const isEstablishment = ({ types = [] }) => {
  return types.includes('establishment');
};

export const hasAddressNumber = ({
  structured_formatting: { main_text: main }
}) => {
  return utils.hasAddressNumber({ structured: { main } });
};

export const injectLoader = ({ InputProps = {}, isLoading, variant }) => {
  const colors = get(FORMS_VARIANTS, variant, FORMS_VARIANTS.primary);
  return isLoading
    ? {
        ...InputProps,
        fullWidth: true,
        endAdornment: (
          <Box
            alignSelf="center"
            color={colors.optionIcon}
            position="absolute"
            right={0}
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
            <CircularProgress color="inherit" size={20} />
          </Box>
        )
      }
    : InputProps;
};
