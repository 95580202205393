/* eslint-disable prefer-destructuring */
import { useShipmentContext } from '../state-machine/context';
import useEstimate from './estimate';

const SERVICES = {
  AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC: 'beyond',
  AMATEUR_SERVICE_TYPE_LOCAL_EXPRESS: 'corp',
  AMATEUR_SERVICE_TYPE_CORREIOS_PLP_PAC: 'indespachoPac',
  AMATEUR_SERVICE_TYPE_CORREIOS_PLP_SEDEX: 'indespachoSedex',
  AMATEUR_SERVICE_TYPE_DROPOFF: 'dropoff'
};

/**
 * Returns the quoting for the selected service.
 *
 * @returns {import('models').AmateurQuoting | null}
 */
export default function useServiceQuoting() {
  const shipmentContext = useShipmentContext();
  const serviceType = shipmentContext.context.serviceType;
  const serviceName = SERVICES[serviceType];
  const query = useEstimate();

  if (!serviceType) return null;
  if (!query.data) return null;
  return query.data.availableServices[serviceName];
}
