const MAX_DAYS_TO_DROPOFF_PACKAGE = 15;

export const maxDropoffDate = initialDate => {
  const dropoffDate = new Date(
    new Date(initialDate).setDate(
      initialDate.getDate() + MAX_DAYS_TO_DROPOFF_PACKAGE
    )
  );

  return dropoffDate.toLocaleDateString('pt-BR', {
    day: 'numeric',
    month: 'long'
  });
};

export default {
  maxDropoffDate
};
