import { Box, ButtonBase, Typography } from '@mui/material';
import { AmateurQuoting } from 'models';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { root, grey, green } from 'theme/colors';
import { getDeliveryTypeInfo } from './service-button.helper';

export const ServiceButton = ({ quoting, onClick }) => {
  const { t } = useTranslation('ui');
  const {
    estimateDelivery,
    image,
    estimatePrice,
    estimatePriceLabel,
    serviceName,
    cheapestPercentage,
    serviceDescription,
    serviceSubtitle
  } = getDeliveryTypeInfo({
    quoting,
    t
  });

  return (
    <ButtonBase style={{ width: '100%' }} onClick={() => onClick(quoting)}>
      <Box
        alignItems="center"
        bgcolor={root[0]}
        borderRadius="16px"
        border={`2px solid ${grey[50]}`}
        padding="16px"
        color={root[900]}
        display="flex"
        width="100%"
        position="relative"
      >
        <Box width="100%">
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            pb="8px"
          >
            {image}
            {cheapestPercentage && (
              <Box
                bgcolor={green[50]}
                borderRadius="100px"
                color={green[900]}
                display="flex"
                alignItems="center"
                px="8px"
              >
                <Typography variant="caption" fontWeight="700" component="p">
                  {cheapestPercentage}
                </Typography>
              </Box>
            )}
          </Box>
          <Box textAlign="left" width="60%">
            <Typography variant="body1">
              <strong>{serviceName}</strong>
            </Typography>
            <Typography variant="caption" fontWeight="500" component="p">
              {serviceSubtitle}
            </Typography>
            <Box mt="8px">
              <Typography variant="caption" fontWeight="500" component="p">
                {serviceDescription}
              </Typography>
              <Typography variant="caption" fontWeight="500" component="p">
                {estimateDelivery}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          textAlign="left"
          position="absolute"
          right={0}
          bottom={0}
          pb="16px"
          pr="16px"
        >
          <Box color={grey[200]}>
            <Typography variant="caption">{estimatePriceLabel}</Typography>
          </Box>
          <Box>
            <Typography variant="caption" component="span">
              <strong>R$</strong>
            </Typography>
            <Typography variant="subtitle1" component="span">
              <strong>{estimatePrice}</strong>
            </Typography>
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
};

ServiceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  quoting: PropTypes.oneOfType([
    PropTypes.instanceOf(AmateurQuoting),
    PropTypes.object
  ]).isRequired
};

export default { ServiceButton };
