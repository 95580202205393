import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useFeatureSwitch from 'hooks/feature-switch/feature-switch.hook';
import { blue, grey, root } from 'theme/colors';
import AlertDialog from 'UI/components/alert-dialog/redirect-alert/alert-dialog.component';
import { SvgIcon } from 'UI/components/svg-icon/svg-icon.component';
import { tracker as trackerRoute } from 'routes/routes';

const buttonStyle = {
  backgroundColor: root[0],
  border: `1px solid ${grey[100]}`,
  borderRadius: '32px',
  color: grey[200],
  display: 'flex',
  fontWeight: '400',
  justifyContent: 'flex-start',
  padding: '12px'
};

export const TrackingButton = () => {
  const [isDialogVisible, setDialogVisibility] = useState(false);
  const { t } = useTranslation('ui');
  const history = useHistory();

  const { data: isInternalTrackerPageEnabled } = useFeatureSwitch(
    'internal_tracker_page_enabled',
    false
  );

  const redirectToTracking = () => {
    if (isInternalTrackerPageEnabled) {
      history.push(trackerRoute);
      return;
    }

    setDialogVisibility(true);
  };

  return (
    <>
      <Button
        fullWidth
        onClick={redirectToTracking}
        rel="noopener"
        startIcon={<SvgIcon color={blue[500]} icon="search" size="16" />}
        style={buttonStyle}
        variant="outlined"
      >
        {t('trackingButton.label')}
      </Button>
      <AlertDialog
        onClose={() => setDialogVisibility(false)}
        open={isDialogVisible}
        title={t('trackingButton.dialog.title')}
        url={process.env.REACT_APP_TRACKER_URL}
      />
    </>
  );
};

export default { TrackingButton };
