import { INPUT_TYPES } from 'constants/index';
import {
  AddressAutocomplete,
  CodeConfirmationInput,
  DeliveryCustomerDocumentInput,
  CpfInput,
  LineInputBase,
  PasswordInput,
  PhoneInput,
  CepInput,
  AverageTicketInput,
  CnpjInput
} from 'UI/components';
import { withTransition } from './step-input.styled';

export const INPUT_PROPS_BY_TYPE = {
  [INPUT_TYPES.text]: { type: 'text' },
  [INPUT_TYPES.email]: { type: 'email', inputMode: 'email' },
  [INPUT_TYPES.password]: { type: 'password', inputMode: 'password' },
  [INPUT_TYPES.phone]: { type: 'tel', inputMode: 'numeric' },
  [INPUT_TYPES.averageTicket]: { type: 'text', inputMode: 'numeric' },
  [INPUT_TYPES.deliveryCustomerDocument]: {
    type: 'text',
    inputMode: 'numeric'
  },
  [INPUT_TYPES.cpf]: { type: 'text', inputMode: 'numeric' },
  [INPUT_TYPES.cep]: { type: 'text', inputMode: 'numeric' },
  [INPUT_TYPES.cnpj]: { type: 'text', inputMode: 'numeric' }
};

export const INPUT_BY_TYPE = {
  [INPUT_TYPES.text]: withTransition(LineInputBase),
  [INPUT_TYPES.email]: withTransition(LineInputBase),
  [INPUT_TYPES.password]: withTransition(PasswordInput),
  [INPUT_TYPES.phone]: withTransition(PhoneInput),
  [INPUT_TYPES.averageTicket]: withTransition(AverageTicketInput),
  [INPUT_TYPES.deliveryCustomerDocument]: withTransition(
    DeliveryCustomerDocumentInput
  ),
  [INPUT_TYPES.cpf]: withTransition(CpfInput),
  [INPUT_TYPES.cep]: withTransition(CepInput),
  [INPUT_TYPES.confirmationCode]: withTransition(CodeConfirmationInput),
  [INPUT_TYPES.address]: withTransition(AddressAutocomplete),
  [INPUT_TYPES.cnpj]: withTransition(CnpjInput)
};
