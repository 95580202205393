import { Box, Link, Typography } from '@mui/material';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { ReactComponent as Slide01Icon } from 'assets/warehouse.svg';
import { ReactComponent as Slide02Icon } from 'assets/barcode.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function Slide01() {
  const { t } = useTranslation('ui');
  const externalPackagingHelpURL = useFeatureSwitch(
    'external_packaging_informations_url'
  ).data;

  return (
    <Box>
      <Slide01Icon />
      <Box height="24px" />
      <Typography variant="h2" fontWeight="700">
        {t('dropoffOnboarding.slides.0.title')}
      </Typography>
      <Typography variant="h2">
        {t('dropoffOnboarding.slides.0.subtitle')}
      </Typography>

      <Box height="24px" />

      <Link
        href={externalPackagingHelpURL}
        color="primary"
        underline="none"
        target="_blank"
      >
        <Typography variant="body1">
          <strong>{t('dropoffOnboarding.slides.0.linkText')}</strong>
        </Typography>
      </Link>
    </Box>
  );
}

export function Slide02() {
  const { t } = useTranslation('ui');
  const externalPackagingHelpURL = useFeatureSwitch(
    'external_packaging_informations_url'
  ).data;

  return (
    <Box>
      <Slide02Icon />
      <Box height="24px" />
      <Typography variant="h2" fontWeight="700">
        {t('dropoffOnboarding.slides.1.title')}
      </Typography>
      <Typography variant="h2" fontWeight="500">
        {t('dropoffOnboarding.slides.1.subtitle')}
      </Typography>

      <Box height="24px" />

      <ul style={{ paddingInlineStart: '18px' }}>
        <li>
          <Typography variant="body1">
            {t('dropoffOnboarding.slides.1.lines.0')}
          </Typography>
        </li>
        <br />
        <li>
          <Typography variant="body1">
            {t('dropoffOnboarding.slides.1.lines.1')}
          </Typography>
        </li>
      </ul>

      <Box height="24px" />

      <Link
        href={externalPackagingHelpURL}
        color="primary"
        underline="none"
        target="_blank"
      >
        <Typography variant="body1">
          <strong>{t('dropoffOnboarding.slides.1.linkText')}</strong>
        </Typography>
      </Link>
    </Box>
  );
}
