import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import getServiceLocationAgencies from 'domain/use-cases/get-agencies-list/get-service-location-agencies.use-case';

export default function useServiceLocationAgencies(postalCode) {
  return useQuery(
    ['agencies-list', postalCode],
    () => getServiceLocationAgencies({ postalCode }),
    {
      enabled: !!postalCode,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError(err) {
        captureException(err);
      }
    }
  );
}
