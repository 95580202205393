import { useState } from 'react';
import {
  useShipmentContext,
  useShipmentDispatcher
} from '../state-machine/context';

export const MAX_DIMENSIONS_SUM_CM = 200;
export const MAX_SIZE_CM = 100;
export const MAX_WEIGHT_G = 30 * 1000;
export const MIN_WIDTH_INDISPATCH = 8;
export const MIN_HEIGHT_INDISPATCH = 0.4;
export const MIN_LENGTH_INDISPATCH = 13;
export const MIN_DIMENSIONS_SUM_CM_INDISPATCH = 26;

function calculateErrors({ heightCm, widthCm, lengthCm, weightG } = {}) {
  const indispatchErros = {
    minWidth: widthCm < MIN_WIDTH_INDISPATCH,
    minLength: lengthCm < MIN_LENGTH_INDISPATCH,
    minHeight: heightCm < MIN_HEIGHT_INDISPATCH,
    minSizesSumError:
      heightCm + widthCm + lengthCm < MIN_DIMENSIONS_SUM_CM_INDISPATCH
  };

  const commonErrors = {
    height: heightCm > MAX_SIZE_CM,
    width: widthCm > MAX_SIZE_CM,
    length: lengthCm > MAX_SIZE_CM,
    weight: weightG > MAX_WEIGHT_G,
    sizesSumError: heightCm + widthCm + lengthCm > MAX_DIMENSIONS_SUM_CM
  };

  return {
    ...indispatchErros,
    ...commonErrors
  };
}

/**
 * @returns {[
 * import('../state-machine/types').PackageDimensions & {
 * errors: {
 *  height: boolean
 *  width: boolean
 *  length: boolean
 *  weight: boolean,
 *  sizesSumError: boolean,
 *  minWidth: boolean,
 *  minLength: boolean,
 *  minHeight: boolean,
 *  minSizesSumError: boolean
 * }},
 * {
 * setHeight: (newValue: number) => void,
 * setWidth: (newValue: number) => void,
 * setLength: (newValue: number) => void,
 * setWeight: (newValue: number) => void
 * }]}
 */
export default function useShipmentPackageDimensions() {
  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const [dimensions, setDimensions] = useState(context.packageDimensions ?? {});
  const [errors, setErrors] = useState(calculateErrors(dimensions));

  /** @type {(fieldName: string) => (newValue: number) => void } */
  const getSizeChangeHandler = fieldName => newValue => {
    const newDimensions = { ...dimensions, [fieldName]: newValue };
    const newErrors = calculateErrors(newDimensions);
    setDimensions(newDimensions);
    setErrors(newErrors);

    const hasAllInputsFilled = !!(
      newDimensions?.heightCm &&
      newDimensions?.widthCm &&
      newDimensions?.lengthCm &&
      newDimensions?.weightG
    );
    const hasSizeError =
      newErrors.weight ||
      newErrors.height ||
      newErrors.width ||
      newErrors.length;

    if (hasAllInputsFilled && !hasSizeError)
      shipmentDispatcher.setPackageDimensions(newDimensions);
  };

  const setters = {
    setHeight: getSizeChangeHandler('heightCm'),
    setWidth: getSizeChangeHandler('widthCm'),
    setLength: getSizeChangeHandler('lengthCm'),
    setWeight: getSizeChangeHandler('weightG')
  };

  return [{ ...dimensions, errors }, setters];
}
