import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import {
  formatPhoneNumber,
  getCreationMethod,
  getFirstName,
  getLastName
} from 'crm/utils';

export default class AccountVolumeQualificationInformed extends CRMBaseEvent {
  constructor({
    name,
    userType,
    document,
    sharedName,
    email,
    phone,
    provider,
    cep,
    segmentName,
    shipmentQualification,
    volumeQualification
  }) {
    super();
    this.eventName = EVENTS.ACCOUNT_VOLUME_QUALIFICATION_INFORMED;
    this.payload = {
      volume_qualification: volumeQualification,
      shipment_qualification: shipmentQualification,
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      phone: formatPhoneNumber(phone),
      registration_method: getCreationMethod(provider),
      user_type: userType,
      document,
      shared_name: sharedName,
      cep: cep || null,
      segment: segmentName
    };
  }
}
