import { Box, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { blue, root } from 'theme/colors';

const StyledBox = styled(Box)({
  marginLeft: '2px',
  '&:first-of-type': {
    marginLeft: 0
  }
});

const TrackingCodeChar = styled(Typography)({
  fontFamily: 'Space Mono, monospace !important',
  fontWeight: 600
});

const TrackingCodeBar = ({ trackingCode }) => {
  const getCodeContent = useCallback(
    () =>
      Array.from(trackingCode).map((char, index) => (
        <TrackingCodeChar
          align="center"
          data-testid="tracking-code-bar-char"
          key={`tracking-code-item-${index + 1}`}
        >
          {char}
        </TrackingCodeChar>
      )),
    [trackingCode]
  );

  return (
    <StyledBox
      bgcolor={blue[900]}
      borderRadius="4px"
      color={root[0]}
      data-testid="tracking-code-bar"
      display="flex"
      flexGrow={1}
      justifyContent="space-evenly"
      py="4px"
    >
      {getCodeContent()}
    </StyledBox>
  );
};

TrackingCodeBar.propTypes = {
  trackingCode: PropTypes.string.isRequired
};

export default TrackingCodeBar;
