/* eslint-disable react/no-array-index-key */
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { REASONS_MAP } from 'constants/support.constants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/components';
import { TextArea } from 'UI/components/input';

const SupportForm = ({ defaultReason, onSubmit }) => {
  const { t } = useTranslation('ui');

  const [reason, setReason] = useState(defaultReason);
  const [details, setDetails] = useState('');
  const [errors, setErrors] = useState(null);

  const isContinueButtonDisabled = !reason;

  const onChangeReasonInput = event => {
    setReason(event.target.value);
  };

  const handleSubmit = ev => {
    ev.preventDefault();
    if (details.length > 2000) {
      setErrors({ details: t('support.form.errors.details_max_length') });
      return;
    }

    setErrors(null);
    onSubmit({ reason: REASONS_MAP[reason], details });
  };

  const lockReasonChange =
    defaultReason === REASONS_MAP.CONTACT_REASON_DELETE_ACCOUNT;

  const reasonList = [
    {
      value: 'CONTACT_REASON_SHIPPING_CREATION',
      text: t('support.form.reasons.creation')
    },
    {
      value: 'CONTACT_REASON_PICKUP',
      text: t('support.form.reasons.pickup')
    },
    {
      value: 'CONTACT_REASON_DELIVERY',
      text: t('support.form.reasons.delivery')
    },
    {
      value: 'CONTACT_REASON_PAYMENT',
      text: t('support.form.reasons.payment')
    },
    {
      value: 'CONTACT_REASON_DELETE_ACCOUNT',
      text: t('support.form.reasons.delete_account')
    },
    {
      value: 'CONTACT_REASON_BLOCKED_ACCOUNT',
      text: t('support.form.reasons.blocked_account')
    },
    {
      value: 'CONTACT_REASON_OTHERS',
      text: t('support.form.reasons.others')
    }
  ];

  return (
    <Box pt={6} px={3} component="form" onSubmit={handleSubmit}>
      <Box mb={4}>
        <Typography variant="h2">{t('support.form.title')}</Typography>
      </Box>
      <Box mb={4}>
        <Typography>{t('support.form.subtitle')}</Typography>
      </Box>
      <Box mb={4}>
        <Typography>{t('support.form.labels.reason')}</Typography>
        <Select
          fullWidth
          value={reason}
          onChange={onChangeReasonInput}
          displayEmpty
          disabled={lockReasonChange}
        >
          <MenuItem value="" disabled title={t('support.form.reasons.default')}>
            {t('support.form.reasons.default')}
          </MenuItem>
          {reasonList.map(item => (
            <MenuItem
              value={item.value}
              key={`${item.value}`}
              title={item.text}
            >
              {item.text}
            </MenuItem>
          ))}
        </Select>
        <Box height="16px" />
        <TextArea
          id="details-input"
          label={t('support.form.labels.details')}
          error={errors?.details}
          value={details}
          onChange={setDetails}
        />
      </Box>
      <Box mb={4}>
        <Button
          disabled={isContinueButtonDisabled}
          fullWidth
          label={t('support.form.button')}
          size="lg"
          type="submit"
        />
      </Box>
    </Box>
  );
};

SupportForm.propTypes = {
  defaultReason: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

SupportForm.defaultProps = {
  defaultReason: ''
};

export default SupportForm;
