import { loggiWebApi } from '@loggi/authentication-lib';

const BEYOND_API_URL = process.env.REACT_APP_PY_BEYOND_URL;

export const endpoints = {
  generateSessionRecharge: companyId => `/payment/v1/recharge/${companyId}/`,
  getBalance: companyId =>
    `${BEYOND_API_URL}/payment/v1/account/${companyId}/balance/`,
  getRechargeStatus: (companyId, rechargeId) =>
    `${BEYOND_API_URL}/payment/v1/recharge/${companyId}/${rechargeId}/`,
  getStatements: ({
    companyId,
    entryType,
    startDate,
    endDate,
    limit,
    skip
  }) => {
    const params = new URLSearchParams();

    if (entryType) params.append('entry_type', entryType);
    if (startDate) params.append('start_date', startDate);
    if (endDate) params.append('end_date', endDate);
    if (limit) params.append('limit', limit);
    if (skip) params.append('skip', skip);

    return `${BEYOND_API_URL}/payment/v1/account/${companyId}/statement/?${params.toString()}`;
  }
};

const walletApi = {
  /** @typedef {import ('./wallet-api.type').PaymentSession} PaymentSession */
  /** @returns {Promise<PaymentSession>} */
  generateSessionRecharge({ companyId, amount }) {
    const endpoint = endpoints.generateSessionRecharge(companyId);

    return loggiWebApi
      .url(`${BEYOND_API_URL}${endpoint}`, true)
      .post({ amount })
      .json();
  },
  /** @typedef {import('./wallet-api.type').WalletBalance} WalletBalance */
  /** @returns {Promise<WalletBalance>} */
  getBalance({ companyId }) {
    const url = endpoints.getBalance(companyId);

    return loggiWebApi
      .url(url, true)
      .get()
      .json();
  },
  /** @typedef {import('./wallet-api.type').RechargeStatus} RechargeStatus */
  /** @returns {Promise<RechargeStatus>} */
  getRechargeStatus({ companyId, rechargeId }) {
    const url = endpoints.getRechargeStatus(companyId, rechargeId);

    return loggiWebApi
      .url(url, true)
      .get()
      .json();
  },
  /** @typedef {import('./wallet-api.type').Statement} Statement */
  /** @returns {Promise<Statement[]>} */
  getStatements({ companyId, entryType, startDate, endDate, limit, skip }) {
    const url = endpoints.getStatements({
      companyId,
      entryType,
      startDate,
      endDate,
      limit,
      skip
    });

    return loggiWebApi
      .url(url, true)
      .get()
      .json();
  }
};

export default walletApi;
