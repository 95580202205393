import { Box } from '@mui/material';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { grey, red } from 'theme/colors';
import { Button } from 'UI/components';

const CancelButton = ({ href, onClick }) => {
  const { t } = useTranslation('ui');

  return (
    <>
      <Box height="16px" bgcolor={grey[50]} />
      <Box p={3}>
        <Button
          bgColor={red[500]}
          fullWidth
          href={href}
          label={t('trackingPage.cancelButton')}
          onClick={onClick}
        />
      </Box>
    </>
  );
};

CancelButton.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

CancelButton.defaultProps = {
  onClick: noop
};

export default CancelButton;
