/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { singularSdk } from 'singular-sdk';
import CRMBaseAdapter from 'crm/adapters/crm-base-adapter';
import { SINGULAR_EVENTS, SINGULAR_REVENUE_EVENTS } from 'crm/constants';

export default class CRMAdapterSingular extends CRMBaseAdapter {
  get events() {
    return SINGULAR_EVENTS;
  }

  get revenueEvents() {
    return SINGULAR_REVENUE_EVENTS;
  }

  sendUser(userPayload) {
    const identifier = userPayload?.email;
    if (!identifier) return;
    singularSdk.login(identifier);
  }

  /**
   * @param {string} eventName - Name of the event to be sent.
   * @param {Object<string, string>} eventData - Data associated with the event.
   */
  sendEvent(eventName, eventData) {
    singularSdk.event(eventName, eventData);
  }

  /**
   * @param {string} eventName - Name of the event to be sent.
   * @param {number} amount - Revenue amount associated with the event.
   * @param {Object<string, string>} eventData - Data associated with the event.
   */
  sendRevenueEvent(eventName, amount, eventData) {
    singularSdk.revenue(eventName, 'BRL', amount, eventData);
  }
}
