/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseInputField } from './base-input';

export function TextArea(props) {
  return (
    <BaseInputField
      id={props.id}
      name={props.name}
      label={props.label}
      value={props.value}
      error={props.error}
      onChange={props.onChange}
      rows={props.rows}
    />
  );
}

TextArea.defaultProps = {
  name: '',
  error: undefined,
  rows: 4
};

TextArea.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  rows: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
