import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import LineInputBase from 'UI/components/line-inputs/line-input-base.component';
import { injectLoader } from '../address-autocomplete.helpers';

export const AddressAutocompleteDisplay = ({
  address,
  error,
  isLoading,
  onTrigger,
  variant
}) => {
  const { endAdornment = null } = injectLoader({ isLoading, variant });
  return (
    <LineInputBase
      autoFocus={false}
      endAdornment={endAdornment}
      error={error}
      onChange={noop}
      onFocus={onTrigger}
      value={address}
      variant={variant}
    />
  );
};

AddressAutocompleteDisplay.defaultProps = {
  address: '',
  error: '',
  isLoading: false,
  variant: 'white'
};

AddressAutocompleteDisplay.propTypes = {
  address: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onTrigger: PropTypes.func.isRequired,
  variant: PropTypes.string
};

export default { AddressAutocompleteDisplay };
