import { authenticationLocales } from '@loggi/authentication';
import i18n from 'i18next';
import errorBoundary from 'locales/one-error-boundary-pt-br.translations';
import partOfDay from 'locales/part-of-day-pt-br.translations';
import weekDays from 'locales/weekdays-pt-br.translations';
import { initReactI18next } from 'react-i18next';
import { translations as containerTranslations } from '../containers';
import uiTranslations from '../UI/translations';

/**
 * i18next configuration
 */

i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      ...authenticationLocales.ptBr,
      ...containerTranslations['pt-BR'],
      ...partOfDay,
      ...uiTranslations['pt-BR'],
      ...weekDays,
      one: { ...errorBoundary }
    },
    en: {
      ...containerTranslations.en
    }
  },
  lng: 'pt-BR',
  fallbackLng: 'pt-BR'
});

export default i18n;
