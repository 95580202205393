/** @param {boolean} shipmentContext */
export function hasSelectedAddresses(shipmentContext) {
  return (
    (!!shipmentContext.context.pickupAddress &&
      !!shipmentContext.context.deliveryAddress) ||
    (!!shipmentContext.context.originAddress &&
      !!shipmentContext.context.destinationAddress)
  );
}

/**
 * @param {boolean} isAddressesSelected
 * @param {boolean} hasDeliveryServices
 * @param {boolean} isLoadingServices
 */
export function evaluateDeliveryServicesDisplay(
  isAddressesSelected,
  hasDeliveryServices,
  isLoadingServices
) {
  return (isAddressesSelected && hasDeliveryServices) || isLoadingServices;
}
