export const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'fbclid',
  'gclid'
];

export const MARKETING_UTMS_SESSION_STORAGE_KEY = 'marketing-utms-beyond';
