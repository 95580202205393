import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { grey } from 'theme/colors';

export const AddressDisplay = ({
  complement,
  main,
  secondary,
  secondaryTypographyVariant
}) => {
  return (
    <Box>
      <Typography variant="body2">{main}</Typography>
      <Typography
        sx={{ color: grey[200] }}
        variant={secondaryTypographyVariant}
      >
        {secondary}
      </Typography>
      {complement && (
        <Box>
          <Typography
            sx={{ color: grey[200] }}
            variant={secondaryTypographyVariant}
          >
            {complement}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

AddressDisplay.defaultProps = { complement: '' };

AddressDisplay.defaultProps = {
  secondaryTypographyVariant: 'caption'
};

AddressDisplay.propTypes = {
  complement: PropTypes.string,
  main: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  secondaryTypographyVariant: PropTypes.string
};

export default { AddressDisplay };
