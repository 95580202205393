import { Box, Card, styled, Typography } from '@mui/material';
import { TRACKING_STATUS } from 'constants/tracking.constants';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { red, root } from 'theme/colors';
import { shadow } from 'theme/shadows';
import { Button } from 'UI/components/button/button.component';
import PackageStatusDot from 'UI/components/package-status-dot/package-status-dot.component';

const CustomCard = styled(Card)({
  backgroundColor: `${red[500]}`,
  boxShadow: shadow.standard,
  color: root[0],
  height: '100%',
  minHeight: '416px'
});

const StatusProblemTrackingCard = ({
  actionDescription,
  onActionClick,
  additionalInformation,
  description,
  label,
  title,
  status
}) => {
  const sendTo = title.substr(0, 10);
  const recipientName = title.substr(11);

  const handleActionClick = ev => {
    ev.stopPropagation();
    onActionClick();
  };

  return (
    <CustomCard>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box pt={4} px={4}>
          <Box alignItems="center" display="flex" flexDirection="row">
            <PackageStatusDot status={status} />
            <Box ml={1}>
              <Typography variant="body1">
                <strong>{label}</strong>
              </Typography>
            </Box>
          </Box>
          <Box data-testid="status-with-problem-card.title" py={2.5}>
            <Typography variant="subtitle2">{sendTo}</Typography>
            <Typography variant="h2">{recipientName}</Typography>
          </Box>
          <Typography variant="body1">
            <strong>{description}</strong>
          </Typography>
          <Box py={2}>
            <Typography variant="body1">{additionalInformation}</Typography>
          </Box>
          {status !== TRACKING_STATUS.deliveryFailure && (
            <Box mb={3}>
              <Button
                bgColor={root[0]}
                fullWidth
                label={actionDescription}
                onClick={handleActionClick}
                textColor={red[500]}
              />
            </Box>
          )}
        </Box>
      </Box>
    </CustomCard>
  );
};

StatusProblemTrackingCard.propTypes = {
  actionDescription: PropTypes.string,
  additionalInformation: PropTypes.string,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

StatusProblemTrackingCard.defaultProps = {
  actionDescription: '',
  additionalInformation: '',
  onActionClick: noop
};

export default StatusProblemTrackingCard;
