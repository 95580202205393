import { blue, green, red } from 'theme/colors';
import { ReactComponent as Amex } from 'assets/amex.svg';
import { ReactComponent as Elo } from 'assets/elo.svg';
import { ReactComponent as Mastercard } from 'assets/mc.svg';
import { ReactComponent as Unknown } from 'assets/nocard.svg';
import { ReactComponent as Visa } from 'assets/visa.svg';
import { ReactComponent as LikeIcon } from 'assets/like.svg';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';
import { ReactComponent as DoneIcon } from 'assets/done.svg';
import React from 'react';

export const CARD_BRANDS = {
  CARD_BRAND_AMEX: {
    name: 'Amex Crédito',
    image: Amex
  },
  CARD_BRAND_ELO: {
    name: 'Elo Crédito',
    image: Elo
  },
  CARD_BRAND_MASTERCARD: {
    name: 'MasterCard Crédito',
    image: Mastercard
  },
  CARD_BRAND_VISA: {
    name: 'Visa Crédito',
    image: Visa
  },
  UNKNOWN: {
    name: 'Unknown',
    image: Unknown
  }
};

export const PAYMENT_STATUSES = {
  PAYMENT_STATUS_AUTHORIZED: {
    label: 'paymentMethod.paymentStatus.authorized',
    backgroundColor: blue[50],
    color: blue[900],
    icon: <LikeIcon />
  },
  PAYMENT_STATUS_CANCELLED: {
    label: 'paymentMethod.paymentStatus.canceled',
    backgroundColor: red[50],
    color: red[500],
    icon: <ErrorIcon />
  },
  PAYMENT_STATUS_CHARGED: {
    label: 'paymentMethod.paymentStatus.paid',
    backgroundColor: green[50],
    color: green[900],
    icon: <DoneIcon />
  }
};
