import AdyenCheckout from '@adyen/adyen-web';
import {
  OutlinedInput,
  styled,
  Typography,
  Box,
  Stack,
  Button
} from '@mui/material';
import {
  adyenCardCheckoutConfig,
  adyenConfig
} from 'configuration/adyen-component-config';
import { useParams } from 'react-router-dom';
import useWalletPaymentSession from 'hooks/wallet-payment-session/wallet-payment-session.hook';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDrawer, InfiniteLoading } from 'UI/components';
import { spacing, borders, colors } from '@loggi/front-design-tokens';
import { useShipmentContext } from 'UI/shipment/state-machine/context';
import { isIndespacho } from 'UI/shipment/state-machine/utils';
import { ON_PAYMENT_COMPLETED_STATUS_CODE } from './constants';
import './card-component-style.css';
import TermsOfService from './terms-of-service/terms-of-service';

const StyledOutlinedInput = styled(OutlinedInput)({
  '&.MuiOutlinedInput-root': {
    height: '40px',
    width: '100%',
    backgroundColor: colors.neutrals['surface-container-low'],
    borderRadius: borders.radius.small
  },
  '&.Mui-disabled': {
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    input: {
      '-webkit-text-fill-color': colors.neutrals['on-surface-variant']
    }
  }
});

function formatCardNumber(lastFourDigits) {
  const maskedNumber = `************${lastFourDigits}`;
  return maskedNumber.match(/.{1,4}/g).join(' ');
}

export const CreditCardPayment = ({
  isOpen,
  onClose,
  handlePaymentCompleted,
  onError,
  amount
}) => {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedStoredCard, setSelectedStoredCard] = useState(null);
  const [isChangeCardDisabled, setIsChangeCardDisabled] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const paymentSessionQuery = useWalletPaymentSession({ companyId, amount });
  const isFetchingPaymentSession = paymentSessionQuery.isFetching;

  const { context } = useShipmentContext();
  const { serviceType } = context;

  const isIndispatchServiceType = isIndespacho(serviceType);

  const initAdyenCheckout = useCallback(async () => {
    if (isFetchingPaymentSession || selectedStoredCard !== null || isMounted)
      return;

    const paymentSession = paymentSessionQuery.data;
    const onPaymentCompleted = res => {
      setIsChangeCardDisabled(false);
      if (res.resultCode === ON_PAYMENT_COMPLETED_STATUS_CODE.AUTHORISED) {
        handlePaymentCompleted(paymentSession.rechargeId);
      } else {
        onError();
      }
    };

    const checkout = await AdyenCheckout({
      ...adyenConfig,
      session: paymentSession,
      onPaymentCompleted,
      onError
    });

    checkout
      .create('card', {
        ...adyenCardCheckoutConfig,
        beforeSubmit: (data, component, actions) => {
          setIsChangeCardDisabled(true);
          component.setStatus('loading');
          actions.resolve(data);
        },
        onConfigSuccess: () => setIsLoading(false)
      })
      .mount(`#card-container`);

    const storedCardData =
      checkout?.paymentMethodsResponse?.storedPaymentMethods[0];

    if (storedCardData) {
      setSelectedStoredCard(true);
      const maskedNumber = formatCardNumber(storedCardData.lastFour);

      const storedCardNumberElement = document.getElementById(
        'stored-card-number'
      );
      storedCardNumberElement.value = maskedNumber;

      checkout
        .create('card', {
          ...adyenCardCheckoutConfig,
          ...storedCardData,
          beforeSubmit: (data, component, actions) => {
            setIsChangeCardDisabled(true);
            component.setStatus('loading');
            actions.resolve(data);
          },
          onConfigSuccess: () => setIsLoading(false)
        })
        .mount(`#stored-card-data`);
    }
    setIsMounted(true);
  }, [
    selectedStoredCard,
    isFetchingPaymentSession,
    handlePaymentCompleted,
    paymentSessionQuery.data,
    onError,
    isMounted
  ]);

  useEffect(() => isOpen && initAdyenCheckout(), [isOpen, initAdyenCheckout]);

  const handleChangeCreditCard = () => {
    if (isChangeCardDisabled) return;
    setSelectedStoredCard(!selectedStoredCard);
  };

  return (
    <BaseDrawer
      hasPuller
      isOpen={isOpen}
      onSwipeClose={onClose}
      variant="temporary"
      vertical
    >
      <Stack
        gap={spacing.stack.xxsmall}
        width="100%"
        alignItems="center"
        display={isLoading ? 'none' : 'auto'}
      >
        <Typography width="100%" variant="headingMedium">
          {selectedStoredCard
            ? t('payment.creditCard.drawer.titleWithSavedCard')
            : t('payment.creditCard.drawer.title')}
        </Typography>

        <Box
          id="card-container"
          width="100%"
          display={!selectedStoredCard ? 'block' : 'none'}
        />

        <Box
          id="stored-card-container"
          width="100%"
          display={selectedStoredCard ? 'block' : 'none'}
        >
          <Typography variant="bodyTextMedium">
            {t('payment.creditCard.drawer.cardNumber')}
          </Typography>
          <StyledOutlinedInput id="stored-card-number" disabled />
          <Box mt={3} id="stored-card-data" width="100%" />
        </Box>
      </Stack>

      {selectedStoredCard !== null && !isLoading && (
        <Box width="100%" justifyContent="center" display="flex" mt={1}>
          <Button variant="blank" onClick={handleChangeCreditCard}>
            {t('payment.creditCard.drawer.changeCard')}
          </Button>
        </Box>
      )}

      {isLoading && (
        <Stack width="100%">
          <Stack pt={spacing.stack.xxlarge} pb={spacing.stack.xxsmall}>
            <InfiniteLoading
              title={t('payment.creditCard.drawer.loading')}
              titleVariant="subtitleMedium"
            />
          </Stack>
        </Stack>
      )}

      {!isLoading && !isIndispatchServiceType && <TermsOfService />}
    </BaseDrawer>
  );
};

CreditCardPayment.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handlePaymentCompleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired
};

export default CreditCardPayment;
