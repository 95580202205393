import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Chip } from '@loggi/front-design-system';
import { colors, spacing } from '@loggi/front-design-tokens';

import useWalletStatement from 'hooks/wallet-statement/wallet-statement.hook';

import WalletStatementItem from './wallet-statement-item';
import WalletEmptyStatements from './wallet-empty-statements';

const FILTERS = {
  CREDIT: 'credit',
  DEBIT: 'debit',
  THIS_WEEK: 'thisWeek',
  THIS_MONTH: 'thisMonth'
};

export default function WalletStatements() {
  const { t } = useTranslation('ui');

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [statementsResponse, setStatementsResponse] = useState([]);
  const { companyId } = useParams();

  const STATEMENTS_PER_REQUEST = 30;

  const [entryType, setEntryType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [skip, setSkip] = useState(0);

  const statementQuery = useWalletStatement({
    companyId,
    entryType,
    startDate,
    endDate,
    limit: STATEMENTS_PER_REQUEST,
    skip
  });

  const handleChipClick = filter => {
    setStatementsResponse([]);
    setSkip(0);
    setEntryType(null);
    setStartDate(null);
    setEndDate(null);

    if (selectedFilter === filter) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(filter);

      const currentDate = new Date();
      const startOfWeek = currentDate;
      const startOfMonth = currentDate;

      switch (filter) {
        case FILTERS.CREDIT:
          setEntryType(FILTERS.CREDIT);
          break;
        case FILTERS.DEBIT:
          setEntryType(FILTERS.DEBIT);
          break;
        case FILTERS.THIS_WEEK:
          setEndDate(currentDate.toISOString());
          startOfWeek.setDate(
            currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
          ); // Last Monday

          setStartDate(startOfWeek.toISOString());
          break;
        case FILTERS.THIS_MONTH:
          setEndDate(currentDate.toISOString());

          startOfMonth.setDate(1);

          setStartDate(startOfMonth.toISOString());
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    function handleScroll() {
      const scrolledTo = window.scrollY + window.innerHeight;
      const threshold = 200;
      const isReachBottom =
        document.body.scrollHeight - threshold <= scrolledTo;

      if (!isReachBottom) return;
      if (statementQuery.isLoading) return;
      if (!statementsResponse.length) return;
      if (!statementQuery.data?.statements.length) return;

      setSkip(prevSkip => prevSkip + STATEMENTS_PER_REQUEST);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [statementQuery.isLoading, statementQuery.data, statementsResponse]);

  useEffect(() => {
    if (statementQuery.data) {
      setStatementsResponse(prevStatements => [
        ...prevStatements,
        ...statementQuery.data.statements
      ]);
    }
  }, [statementQuery.data]);

  useEffect(() => {
    const appRootContainer = document.querySelector('.app-root-container');

    if (appRootContainer) {
      const originalStyles = appRootContainer.getAttribute('style');

      appRootContainer.style = {};
      appRootContainer.style.minHeight = '100vh';
      appRootContainer.style.height = !statementsResponse.length
        ? '100vh'
        : '100%';

      return () => {
        appRootContainer.setAttribute('style', originalStyles);
      };
    }
    return () => {};
  }, [statementsResponse, statementQuery.isLoading]);

  return (
    <Stack>
      <Stack
        px={spacing.stack.xxsmall}
        py={spacing.stack.xxxsmall}
        mt={spacing.stack.xsmall}
      >
        <Typography
          variant="subtitleLarge"
          color={colors.neutrals['on-surface']}
        >
          {t('walletPage.statements.title')}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={spacing.stack.nano}
        px={spacing.stack.xxsmall}
        py={spacing.stack.nano}
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none'
        }}
      >
        <Box>
          <Chip
            label={t('walletPage.statements.filters.credit')}
            size="small"
            selected={selectedFilter === FILTERS.CREDIT}
            onClick={() => handleChipClick(FILTERS.CREDIT)}
          />
        </Box>
        <Box>
          <Chip
            label={t('walletPage.statements.filters.debit')}
            size="small"
            selected={selectedFilter === FILTERS.DEBIT}
            onClick={() => handleChipClick(FILTERS.DEBIT)}
          />
        </Box>
        <Box>
          <Chip
            label={t('walletPage.statements.filters.thisWeek')}
            size="small"
            selected={selectedFilter === FILTERS.THIS_WEEK}
            onClick={() => handleChipClick(FILTERS.THIS_WEEK)}
          />
        </Box>
        <Box>
          <Chip
            label={t('walletPage.statements.filters.thisMonth')}
            size="small"
            selected={selectedFilter === FILTERS.THIS_MONTH}
            onClick={() => handleChipClick(FILTERS.THIS_MONTH)}
          />
        </Box>
      </Stack>
      {!statementsResponse.length && !statementQuery.isLoading && (
        <WalletEmptyStatements />
      )}
      {statementsResponse.map(statement => (
        <WalletStatementItem
          key={statement.transaction_uuid}
          recharge={statement.recharge}
          refund={statement.refund}
          transactionType={statement.transaction_type}
          amount={statement.amount}
          entryType={statement.entry_type}
          createdAt={statement.created_at}
          payment={statement.payment}
          coupon={statement.coupon}
        />
      ))}
      {statementQuery.isLoading && (
        <Stack alignItems="center" py={spacing.stack.large}>
          <CircularProgress size={spacing.stack.xxsmall} />
        </Stack>
      )}
    </Stack>
  );
}
