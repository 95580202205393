import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Box, Button, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  loggiDesignSystem as theme,
  useSnackbar
} from '@loggi/front-design-system';
import { spacing } from '@loggi/front-design-tokens';
import CheckoutHeader from 'UI/shipment/components/checkout-header/header.component';
import components from 'theme/components';
import { currencyFormatter } from 'utils';
import CreditCardPayment from 'UI/shipment/pages/payment/credit-card-payment';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { PIX, RECHARGE_STATUS } from 'UI/shipment/pages/payment/constants';
import useWalletRechargeStatus from 'hooks/wallet-recharge-status/wallet-recharge-status.hook';
import useWalletRechargeBalanceLimits from 'hooks/feature-switch/wallet-recharge-balance-limit/wallet-recharge-balance-limit.hook';
import { LoadingRecharge } from 'UI/shipment/pages/wallet/loading-recharge/loading-recharge.component';

import RechargeInput from './recharge-input/recharge-input.component';
import {
  formatInput,
  formatValueToCurrency
} from './helper/input-recharge-value.helper';

/**
 * Necessary for our drawer to continue
 * respecting the maxWidth stipulated in
 * our general theme
 */
const addGeneralDrawerComponentsTheme = () => {
  Object.assign(theme.components, { MuiDrawer: components.MuiDrawer });
};

export default function InputRechargeValuePage() {
  addGeneralDrawerComponentsTheme();
  const { t } = useTranslation('ui');
  const [value, setValue] = useState(0);
  const [showPayment, setShowPayment] = useState(false);
  const shipmentDispatcher = useShipmentDispatcher();
  const { context } = useShipmentContext();
  const [
    showInsuficientRechargeValue,
    setShowInsuficientRechargeValue
  ] = useState(context.shipmentAmount > 0);
  const formatedCurrencyValue = formatValueToCurrency(value);
  const floatValue = parseFloat(value);
  const { showSnackbar, dismissSnackbar } = useSnackbar();
  const snackbarId = useRef(null);
  const { companyId } = useParams();
  const [currentRechargeId, setCurrentRechargeId] = useState(null);
  const rechargeStatusQuery = useWalletRechargeStatus({
    companyId,
    rechargeId: currentRechargeId
  });

  const maximumRechargeValueLimits = useWalletRechargeBalanceLimits();
  const maximumRechargeValueCreditCard = maximumRechargeValueLimits?.creditCard;
  const maximumRechargeValuePix = maximumRechargeValueLimits?.pix;
  const MINIMUM_RECHARGE_VALUE = 1;

  const handleChange = ev => {
    if (snackbarId.current) dismissSnackbar(snackbarId.current);

    const val = ev.target?.value;
    const formatedValue = formatInput(val);
    setValue(formatedValue);
    setShowInsuficientRechargeValue(formatedValue < context.shipmentAmount);
  };

  const handleRechargeValueOutOfRange = message => {
    snackbarId.current = showSnackbar(
      {
        message,
        variant: 'negative',
        size: 'small'
      },
      { preventDuplicate: true }
    );
  };

  const handleSubmit = () => {
    const maximumRechargeValue =
      context.paymentMethod === PIX
        ? maximumRechargeValuePix
        : maximumRechargeValueCreditCard;

    if (floatValue > maximumRechargeValue) {
      handleRechargeValueOutOfRange(
        t('wallet.inputRechargeValue.exceededValue', {
          maximumRechargeValue: currencyFormatter(maximumRechargeValue)
        })
      );
      return;
    }

    if (floatValue < MINIMUM_RECHARGE_VALUE) {
      handleRechargeValueOutOfRange(t('wallet.inputRechargeValue.lowerValue'));
      return;
    }

    if (context.paymentMethod === PIX) {
      shipmentDispatcher.continue(floatValue);
    } else {
      setShowPayment(true);
    }
  };

  const handlePaymentCompleted = rechargeId => {
    setShowPayment(false);
    setCurrentRechargeId(rechargeId);
  };

  useEffect(() => {
    const rechargeStatus = rechargeStatusQuery?.data?.['recharge_status'];
    if (rechargeStatus === RECHARGE_STATUS.SUCCESS) {
      const {
        payment_method: paymentMethod,
        current_balance: currentBalance,
        updated_at: updatedAt
      } = rechargeStatusQuery.data;

      const paymentDate = new Date(updatedAt);

      shipmentDispatcher.balanceSuccessfullyAdded({
        addedBalance: floatValue,
        rechargeId: currentRechargeId,
        paymentMethod,
        currentBalance,
        paymentDate
      });
    }
    if (
      rechargeStatusQuery?.isError ||
      rechargeStatus === RECHARGE_STATUS.CANCELED_BY_GATEWAY ||
      rechargeStatus === RECHARGE_STATUS.ERROR
    ) {
      shipmentDispatcher.viewWalletBalanceError({
        addBalanceError: RECHARGE_STATUS.ERROR,
        paymentMethod: context.paymentMethod
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargeStatusQuery]);

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Stack pb={spacing.inset.medium}>
          <CheckoutHeader title={t('wallet.inputRechargeValue.title')} />
        </Stack>
        <Stack
          gap={spacing.stack.xxxsmall}
          p={spacing.inset.large}
          alignItems="center"
        >
          <RechargeInput
            disabledFocus={showPayment}
            value={formatedCurrencyValue}
            handleChange={handleChange}
          />
          {showInsuficientRechargeValue && (
            <Alert icon={false} size="small" type="alert">
              <Trans
                t={t}
                i18nKey="wallet.inputRechargeValue.insuficientRechargeValue"
                values={{
                  amount: currencyFormatter(context.shipmentAmount)
                }}
              />
            </Alert>
          )}
        </Stack>
        <Box
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          p={spacing.stack.xxsmall}
        >
          <Stack>
            <Button
              variant="contained"
              onClick={handleSubmit}
              data-testid="continue-input-recharge-value"
            >
              {t('wallet.preDefinedRechargeOptions.continue')}
            </Button>
          </Stack>
        </Box>
      </Stack>
      {!!floatValue && showPayment && (
        <CreditCardPayment
          isOpen={showPayment}
          onClose={() => setShowPayment(false)}
          handlePaymentCompleted={handlePaymentCompleted}
          amount={floatValue}
          onError={() =>
            shipmentDispatcher.viewWalletBalanceError({
              paymentMethod: context.paymentMethod
            })
          }
        />
      )}
      <LoadingRecharge isVisible={!!currentRechargeId} />
    </ThemeProvider>
  );
}
