import { loggiWebApi } from '@loggi/authentication-lib';

/**
 * Get postal code from address
 *
 * @param {string|number} companyId
 * @param {import('models').Address} address
 * @returns {Promise<{ postal_code: string }>}
 */
export default function getPostalCodeFromAddress(companyId, address) {
  return loggiWebApi
    .url(`/one/api/addresses/v1/companies/${companyId}/address/postal_code/`)
    .query({
      address: address.description,
      place_id: address.placeId
    })
    .get()
    .json();
}
