import { useMutation } from '@tanstack/react-query';
import sendTelemetryData from 'domain/use-cases/send-telemetry/send-telemetry.use-case';

const VERSION = process.env.REACT_APP_VERSION;

/**
 * Sends telemetry data.
 *
 * This hook already includes the UI version in the extra data.
 *
 * @example <caption>Send telemetry data</caption>
 * const { send } = useTelemetry();
 * send({ message: 'telemetry data', extra: { foo: 'bar' } });
 *
 */
export default function useTelemetry() {
  const mutation = useMutation({
    mutationFn: sendTelemetryData,
    retry: 0,
    retryDelay: 1000
  });

  /**
   * @param {Parameters<typeof sendTelemetryData>} data
   * @returns {void}
   */
  const send = ({ message, extra }) => {
    mutation.mutate({
      message,
      extra: {
        ui_version: VERSION,
        ...extra
      }
    });
  };

  return {
    send,
    isLoading: mutation.isLoading,
    isPaused: mutation.isPaused,
    isIdle: mutation.isIdle,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  };
}
