import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, IconButton, Stack, Typography } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Icon } from '@loggi/loggi-icons';

import { BaseDialog } from 'UI/components';
import useWalletHelpLink from 'hooks/feature-switch/wallet-help-link/wallet-help-link.hook';

export const NewPaymentMethodDialog = ({ isOpen, onClose }) => {
  const { t } = useTranslation('ui');
  const helpLink = useWalletHelpLink();

  return (
    <BaseDialog isOpen={isOpen} onDismiss={onClose} labels={{ confirm: '' }}>
      <Stack data-testid="new-payment-method-dialog">
        <Stack
          alignItems="start"
          px={spacing.stack.xxxsmall}
          pb={spacing.stack.xxxsmall}
        >
          <IconButton aria-label="close" onClick={onClose}>
            <Icon
              name="ActionClose"
              size="medium"
              color={colors.neutrals['on-secondary']}
            />
          </IconButton>
        </Stack>
        <Stack
          px={spacing.stack.xxsmall}
          py={spacing.stack.xxsmall}
          gap={spacing.stack.xxxsmall}
        >
          <Typography variant="overline" color={colors.neutrals['on-surface']}>
            {t('payment.newPaymentMethodDisclaimer.supportTitle')}
          </Typography>
          <Typography
            variant="headingMedium"
            color={colors.neutrals['on-surface']}
          >
            {t('payment.newPaymentMethodDisclaimer.title')}
          </Typography>
          <Stack gap={spacing.stack.xxsmall}>
            <Stack
              direction="row"
              gap={spacing.stack.xxxsmall}
              alignItems="center"
            >
              <Stack>
                <Icon
                  name="WalletClosed"
                  size="medium"
                  color={colors.neutrals['on-surface']}
                />
              </Stack>
              <Typography
                variant="bodyTextLarge"
                color={colors.neutrals['on-surface']}
              >
                {t('payment.newPaymentMethodDisclaimer.firstDescription')}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={spacing.stack.xxxsmall}
              alignItems="center"
            >
              <Stack>
                <Icon
                  name="CreditCardCheck"
                  size="medium"
                  color={colors.neutrals['on-surface']}
                />
              </Stack>
              <Typography
                variant="bodyTextLarge"
                color={colors.neutrals['on-surface']}
              >
                {t('payment.newPaymentMethodDisclaimer.secondDescription')}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={spacing.stack.xxxsmall}
              alignItems="center"
            >
              <Stack>
                <Icon
                  name="CheckVerified02"
                  size="medium"
                  color={colors.neutrals['on-surface']}
                />
              </Stack>
              <Typography
                variant="bodyTextLarge"
                color={colors.neutrals['on-surface']}
              >
                {t('payment.newPaymentMethodDisclaimer.thirdDescription')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          px={spacing.stack.xxsmall}
          pt={spacing.stack.xxsmall}
          gap={spacing.inset.medium}
        >
          <Button variant="contained" size="medium" onClick={onClose}>
            {t('payment.newPaymentMethodDisclaimer.continueButton')}
          </Button>
          <Button
            variant="blank"
            size="large"
            onClick={() => {
              window.open(helpLink, '_blank');
            }}
            startIcon={<Icon name="CircleQuestion" />}
          >
            {t('payment.newPaymentMethodDisclaimer.helpButton')}
          </Button>
        </Stack>
      </Stack>
    </BaseDialog>
  );
};

NewPaymentMethodDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default NewPaymentMethodDialog;
