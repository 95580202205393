import React from 'react';
import PropTypes from 'prop-types';
import { Stepper } from '@loggi/front-design-system';
import { formatTimestampToFriendlyDate } from 'UI/shipments-v2/utils/date.utils';
import { spacing } from '@loggi/front-design-tokens';
import { Box } from '@mui/material';
import { TRACKING_STATUS } from 'constants/tracking.constants';

export const ShipmentDetailsHistory = ({
  history,
  description,
  additionalInformation,
  statusLabel,
  statusCode
}) => {
  const hasPagination = history.length > 2;
  const isLostPackage = statusCode === TRACKING_STATUS.lost;
  const descriptionText = isLostPackage ? description : additionalInformation;

  const stepperItems = history.map((h, i) => {
    if (i === 0) {
      return {
        date: formatTimestampToFriendlyDate(new Date(h.updateTime)),
        title: statusLabel,
        description: descriptionText
      };
    }

    return {
      date: formatTimestampToFriendlyDate(new Date(h.updateTime)),
      subtitle: h.title
    };
  });
  return (
    <Box pb={spacing.inset.large} px={spacing.inset.large}>
      <Stepper
        pagination={hasPagination}
        activeStep={0}
        paginationItems={2}
        items={stepperItems}
      />
    </Box>
  );
};

ShipmentDetailsHistory.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      occasionalShipperStatusCode: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      updateTime: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  additionalInformation: PropTypes.string.isRequired,
  statusLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  statusCode: PropTypes.string.isRequired
};

export default ShipmentDetailsHistory;
