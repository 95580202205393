import { Box, Typography } from '@mui/material';
import { get } from 'lodash';
import { useShipmentDispatcher } from 'UI/shipment/state-machine/context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useCoupon from 'UI/shipment/hooks/coupon';
import Footer from 'UI/shipment/components/shipment-footer';
import Header from 'UI/shipment/components/header';
import { COUPONS_ERRORS } from 'constants/quotation.constants';
import { CouponErrorDrawer } from './coupon-error-drawer/coupon-error-drawer.component';
import CouponCode from './coupon-code/coupon-code';
import CouponList from './coupon-list/coupon-list';

export const CouponsPage = () => {
  const { t } = useTranslation('ui');
  const shipmentDispatcher = useShipmentDispatcher();
  const coupon = useCoupon();

  const errorTitle =
    coupon.error && get(COUPONS_ERRORS, coupon.error, COUPONS_ERRORS.general);

  const onClose = () => {
    shipmentDispatcher.setCoupon(null);
  };

  return (
    <Box height="100vh">
      <Header>
        <Header.ArrowBackButton onGoBack={shipmentDispatcher.back} />
      </Header>
      <Box m={3}>
        <Typography variant="subtitle1">
          {t('couponsPageV2.pageTitle')}
        </Typography>
        <CouponCode
          onAddCoupon={couponCode => shipmentDispatcher.setCoupon(couponCode)}
        />
        <CouponList
          onSelectCoupon={couponCode =>
            shipmentDispatcher.setCoupon(couponCode)
          }
        />
        <Footer>
          <Footer.PrimaryAction
            disabled={!coupon.isSuccess}
            onClick={shipmentDispatcher.continue}
          />
        </Footer>
      </Box>
      <CouponErrorDrawer
        isOpen={coupon.isError}
        title={errorTitle}
        onClose={onClose}
      />
    </Box>
  );
};

export default { CouponsPage };
