import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { blue, grey } from 'theme/colors';
import { SkeletonLoader, SvgIcon } from 'UI/components';

export const CouponCardLoader = () => {
  return (
    <Box
      alignItems="center"
      border={`2px solid ${grey[50]}`}
      borderRadius="16px"
      display="flex"
      p={2}
      mb={2}
    >
      <SkeletonLoader height={24} width={24} />
      <Box display="flex" flexDirection="column" pl={2} width="100%">
        <Box mb={1}>
          <SkeletonLoader height={10} width={100} />
        </Box>
        <SkeletonLoader height={10} width={250} />
        <SkeletonLoader height={10} width={150} />
      </Box>
    </Box>
  );
};

export const CouponCard = ({ coupon, isActive, onSubmit }) => {
  const borderColor = isActive ? blue[500] : grey[50];

  return (
    <Box
      alignItems="center"
      border={`2px solid ${borderColor}`}
      borderRadius="16px"
      display="flex"
      p={2}
      mb={2}
      onClick={() => onSubmit(coupon)}
    >
      <SvgIcon color={grey[300]} icon="coupon" />
      <Box display="flex" flexDirection="column" pl={2} width="100%">
        <Box mb={1}>
          <Typography variant="body2">
            <strong>{coupon.title}</strong>
          </Typography>
        </Box>
        <Typography variant="caption">{coupon.description}</Typography>
      </Box>
    </Box>
  );
};

CouponCard.propTypes = {
  coupon: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  isActive: PropTypes.bool,
  onSubmit: PropTypes.func
};

CouponCard.defaultProps = {
  isActive: false,
  onSubmit: () => {}
};

export default { CouponCard };
