import React, { useState } from 'react';
import { spacing, colors } from '@loggi/front-design-tokens';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../search-input/search-input.component';
import { ShipmentsPagination } from '../shipments-pagination/shipments-pagination.component';
import { ActionButtonsMobile } from '../action-buttons-mobile/action-buttons-mobile.component';
import { GroupingFilters } from '../grouping-filters/grouping-filters.component';
import { ShipmentCards } from '../shipment-cards/shipment-cards.component';
import { LoggiHeader } from '../loggi-header/loggi-header.component';
import { StatusFilterMobile } from '../status-filter-mobile/status-filter-mobile.component';
import { SortMobile } from '../sort-mobile/sort-mobile.component';

export const MyShipmentsMobile = () => {
  const { t } = useTranslation('ui');

  const [showStatusFilters, setShowStatusFilters] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);

  if (showStatusFilters)
    return (
      <StatusFilterMobile handleClose={() => setShowStatusFilters(false)} />
    );
  if (showSortOptions)
    return <SortMobile handleClose={() => setShowSortOptions(false)} />;

  return (
    <Box
      data-testid="main-box-wrapper"
      display="flex"
      flexDirection="column"
      height="100vh"
    >
      <LoggiHeader />
      <Box display="flex" flexDirection="column" mt={spacing.stack.xxsmall}>
        <Box
          mx={spacing.stack.xxsmall}
          display="flex"
          alignItems="center"
          mb={spacing.stack.xxsmall}
        >
          <Box flexGrow={1} textAlign="left">
            <Typography
              variant="headingMedium"
              color={colors.neutrals['on-surface']}
            >
              {t('myShipmentsV2.title')}
            </Typography>
          </Box>
          <Box maxWidth={287} flexGrow={1} textAlign="right">
            <ActionButtonsMobile
              handleStatusFilterButtonClick={setShowStatusFilters}
              handleSortButtonClick={setShowSortOptions}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          px={spacing.inset.large}
          alignItems="center"
          mb={spacing.stack.xxsmall}
          style={{
            '.MuiTextField-root': {
              width: '100%'
            },
            '& .MuiFormLabel-root.MuiInputLabel-root': {
              paddingRight: '15px'
            }
          }}
        >
          <SearchInput />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          pb={spacing.stack.xxsmall}
          mx={spacing.inset.large}
          overflow="auto"
          gap={spacing.stack.xxxsmall}
          sx={{
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            '@media (min-width: 600px)': {
              justifyContent: 'center',
              '&::-webkit-scrollbar': {
                display: 'auto'
              }
            }
          }}
        >
          <GroupingFilters mobile />
        </Box>
        <ShipmentCards />
        <ShipmentsPagination />
      </Box>
    </Box>
  );
};

export default MyShipmentsMobile;
