import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { CARD_BRANDS } from 'constants/payment.constants';
import { currencyFormatter } from 'utils';

export const ShipmentDetailsPaymentInfo = ({
  cardLastDigits,
  cardBrand,
  totalValue,
  walletAmount
}) => {
  const { t } = useTranslation('ui');
  const brand = get(CARD_BRANDS, cardBrand, CARD_BRANDS.UNKNOWN);
  const BrandImage = brand?.image;

  const isWalletPayment = walletAmount && walletAmount > 0;

  return (
    <Box p={spacing.inset.large}>
      <Box pt={spacing.inset.large} pb={spacing.inset.medium}>
        <Typography variant="subtitleLarge">
          {t('shipmentDetailsV2.paymentInfo.title')}
        </Typography>
      </Box>
      {!isWalletPayment && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={spacing.stack.xxsmall}
          py={spacing.inset.large}
        >
          <Box>
            <Typography variant="subtitleSmall">
              {t('shipmentDetailsV2.paymentInfo.paymentMethod')}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="right">
            <Box mr={spacing.stack.nano}>
              <BrandImage />
            </Box>
            <Box>
              <Typography variant="subtitleSmall">
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: '700'
                  }}
                >
                  ••••
                </span>{' '}
                {cardLastDigits}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        gap={spacing.stack.xxsmall}
        py={spacing.inset.medium}
      >
        <Box>
          <Typography variant="subtitleSmall">
            {t('shipmentDetailsV2.paymentInfo.totalValue')}
          </Typography>
        </Box>
        <Box justifyContent="right">
          <Typography variant="headingMedium">
            {currencyFormatter(
              (isWalletPayment ? walletAmount : totalValue) || 0
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ShipmentDetailsPaymentInfo.defaultProps = {
  walletAmount: null,
  totalValue: null,
  cardLastDigits: '',
  cardBrand: ''
};

ShipmentDetailsPaymentInfo.propTypes = {
  totalValue: PropTypes.number,
  cardLastDigits: PropTypes.string,
  cardBrand: PropTypes.string,
  walletAmount: PropTypes.number
};

export default ShipmentDetailsPaymentInfo;
