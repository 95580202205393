import { Chip } from '@loggi/front-design-system';
import { Box } from '@mui/material';
import { GROUPING_OPTIONS } from 'UI/shipments-v2/constants/shipments.constants';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const GroupingFilters = ({ mobile = false }) => {
  const { grouping, setGrouping, numberOfShipmentsByGroup } = useShipments();
  const { t } = useTranslation('ui');

  const handleGroupingClick = option => {
    setGrouping(option);
  };

  const pickupPendingNumberOfShipments =
    numberOfShipmentsByGroup[GROUPING_OPTIONS.pickupPending] || 0;

  const pendingNumberOfShipments =
    numberOfShipmentsByGroup[GROUPING_OPTIONS.pending] || 0;

  const withProblemNumberOfShipments =
    numberOfShipmentsByGroup[GROUPING_OPTIONS.withProblem] || 0;

  const size = mobile ? 'small' : 'medium';
  return (
    <>
      <Box display="flex" minWidth="fit-content">
        <Chip
          label={`${t('myShipmentsV2.groupingOptions.pendingPickup')}${
            pickupPendingNumberOfShipments > 100
              ? `${t('myShipmentsV2.groupingOptions.moreThanOneHundred')}`
              : pickupPendingNumberOfShipments
          }`}
          icon={false}
          selected={grouping === GROUPING_OPTIONS.pickupPending}
          onClick={() => handleGroupingClick(GROUPING_OPTIONS.pickupPending)}
          size={size}
        />
      </Box>
      <Box display="flex" minWidth="fit-content">
        <Chip
          label={`${t('myShipmentsV2.groupingOptions.pending')}${
            pendingNumberOfShipments > 100
              ? `${t('myShipmentsV2.groupingOptions.moreThanOneHundred')}`
              : pendingNumberOfShipments
          }`}
          icon={false}
          selected={grouping === GROUPING_OPTIONS.pending}
          onClick={() => handleGroupingClick(GROUPING_OPTIONS.pending)}
          size={size}
        />
      </Box>
      <Box display="flex" minWidth="fit-content">
        <Chip
          label={`${t('myShipmentsV2.groupingOptions.withProblem')}${
            withProblemNumberOfShipments > 100
              ? `${t('myShipmentsV2.groupingOptions.moreThanOneHundred')}`
              : withProblemNumberOfShipments
          }`}
          icon={false}
          selected={grouping === GROUPING_OPTIONS.withProblem}
          onClick={() => handleGroupingClick(GROUPING_OPTIONS.withProblem)}
          size={size}
        />
      </Box>
    </>
  );
};

GroupingFilters.defaultProps = {
  mobile: false
};

GroupingFilters.propTypes = {
  mobile: PropTypes.bool
};

export default GroupingFilters;
