import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';
import { borders, colors, spacing } from '@loggi/front-design-tokens';

export default function Details({ children }) {
  const { t } = useTranslation('ui');

  return (
    <Stack
      gap={spacing.stack.xxxsmall}
      p={spacing.stack.xxsmall}
      backgroundColor={colors.neutrals.surface}
      borderTop={`${borders.width.thin} solid ${colors.neutrals.outline}`}
    >
      <Typography
        variant="subtitleMedium"
        color={colors.neutrals['on-surface']}
      >
        {t('wallet.footer.title')}
      </Typography>

      {children}
    </Stack>
  );
}

Details.propTypes = {
  children: PropTypes.node.isRequired
};
