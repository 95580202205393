import { MARKETING_UTMS_SESSION_STORAGE_KEY } from 'constants/index';
import CRMService from 'crm/services/crm-service';
import { getSessionStorageValue, removeUndefinedValues, stringifyTypeObjectFields } from 'utils';

/**
 * Abstract class representing a CRM entity.
 *
 * Subclasses should provide implementation for `sendToCrm()` method.
 *
 * Subclasses should define the following:
 * @property {string} eventName - The name of the event associated with the CRM entity.
 * @property {Object} payload - The payload to be sent to the CRM.
 *
 */
export default class CRMBaseEntity {
  /** @type {CRMService} The CRM service */
  static _crmService;

  constructor(crmService) {
    CRMBaseEntity._crmService =
      CRMBaseEntity._crmService ?? crmService ?? new CRMService();
    /** @type {string} The name of the event associated with the CRM entity. */
    this.eventName = undefined;
    /** @type {object} The payload to be sent to the CRM. */
    this.payload = undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  get crmService() {
    return CRMBaseEntity._crmService;
  }

  cleanPayload() {
    const cleanedPayload = removeUndefinedValues(this.payload);
    return stringifyTypeObjectFields(cleanedPayload);
  }

  enrichesPayloadWithGeneralAppData() {
    const utms = getSessionStorageValue(MARKETING_UTMS_SESSION_STORAGE_KEY);
    this.payload = {
      ...this.payload,
      ...utms
    };
    this.payload.beyond_ui_version = process.env.REACT_APP_VERSION;
  }

  /**
   * Sends this Entity to the CRM platform.
   * @throws {Error} Throws an error if the method is not implemented.
   */
  sendToCrm() {
    throw new Error(
      `sendToCrm method not implemented for ${this.constructor.name}`
    );
  }
}
