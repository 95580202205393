import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import LineInputBase from '../line-input-base.component';
import { formatConfirmationCode } from './confirmation-code-input.helper';

const StyledInput = styled(LineInputBase)({
  '& input': { letterSpacing: '10px' },
  '&.--display': {
    '& input': {
      letterSpacing: '20px'
    }
  }
});

const ConfirmationCodeInput = ({
  error,
  onChange,
  placeholder,
  inputRef,
  value,
  inputProps,
  className,
  onEnterKeyDown,
  variant
}) => {
  const [val, setVal] = useState(formatConfirmationCode(value).formatted);

  const handleChange = e => {
    const { value: newVal } = e.target;
    const { formatted, onlyDigits } = formatConfirmationCode(newVal);
    if (val !== formatted) {
      setVal(formatted);
      onChange({ ...e, target: { ...e.target, value: onlyDigits } });
    }
  };

  return (
    <StyledInput
      className={className}
      error={error}
      onChange={handleChange}
      placeholder={placeholder}
      onEnterKeyDown={onEnterKeyDown}
      inputProps={{
        ...inputProps,
        inputMode: 'numeric',
        maxLength: 7,
        value: val
      }}
      inputRef={inputRef}
      variant={variant}
    />
  );
};

ConfirmationCodeInput.propTypes = {
  error: PropTypes.string,
  inputRef: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onEnterKeyDown: PropTypes.func,
  className: PropTypes.string,
  inputProps: PropTypes.shape({}),
  variant: PropTypes.string
};

ConfirmationCodeInput.defaultProps = {
  error: '',
  inputRef: {},
  placeholder: '',
  value: '',
  onEnterKeyDown: null,
  className: '',
  inputProps: {},
  variant: 'primary'
};

export default ConfirmationCodeInput;
