import { getAuthorizationTokens } from '@loggi/authentication-lib';
import { captureException } from '@sentry/react';
import { GetAuthorizationTokensError } from 'erros';

const hasAuthorizationTokens = (authorization, exceptionMessage) => {
  if (!authorization?.accessToken || !authorization?.idToken) {
    captureException(new GetAuthorizationTokensError(exceptionMessage));
    return null;
  }
  return authorization;
};

export const getCurrentAuthorizationTokenUseCase = async exceptionMessage => {
  const authorization = await getAuthorizationTokens();
  return hasAuthorizationTokens(authorization, exceptionMessage);
};

export default { getCurrentAuthorizationTokenUseCase };
