import { Box, Typography } from '@mui/material';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'UI/shipment/components/shipment-footer';
import PACKAGE_OPTIONS_LIST from './options/options-list.constant';
import PackageOption from './options/package-option.component';

// This page component is meant to replace the current PackageSizePage (../package-size/package.size.page.js)
// remove the above comment when it happens
export default function PackageSizePage() {
  const { t } = useTranslation('ui');
  const shipmentDispatcher = useShipmentDispatcher();
  const { context } = useShipmentContext();
  const [selectedOption, setSelectedOption] = useState(context.packageSize);

  const handleChangeOption = packageSize => {
    shipmentDispatcher.selectPackageSize(packageSize);
    setSelectedOption(packageSize);
  };
  const onSubmit = () => shipmentDispatcher.continue();

  return (
    <>
      <Box>
        <Typography variant="subtitle1">
          {t('newPackageSizePage.label')}
        </Typography>
        <Box display="flex" flexDirection="column" gap={2} mt={3}>
          <PackageOptionsList
            selectedOption={selectedOption}
            onChangeOption={handleChangeOption}
          />
        </Box>
      </Box>

      <Footer showBorder>
        <Footer.EstimatePrice />
        <Footer.PrimaryAction onClick={onSubmit} disabled={!selectedOption} />
      </Footer>
    </>
  );
}

/**
 * @param {object} props
 * @param {string} props.selectedOption
 * @param {(option: import('UI/shipment/state-machine/types').PackageSize) => void} props.onChangeOption
 * @returns
 */
const PackageOptionsList = ({ selectedOption, onChangeOption }) => {
  const { t } = useTranslation('ui');

  return PACKAGE_OPTIONS_LIST.map(size => (
    <PackageOption
      key={size.keyName}
      title={t(`newPackageSizePage.labels.${size.keyName}`)}
      description={t(`newPackageSizePage.descriptions.${size.keyName}`)}
      icon={size.icon}
      isSelected={selectedOption === size.keyName}
      onClick={() => onChangeOption(size.keyName)}
    />
  ));
};
