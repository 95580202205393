/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'UI/shipments-v2/utils/helpers.utils';

export const DestinationInfo = ({
  receiverAddress,
  receiverName,
  receiverPhone,
  pickupStatus
}) => {
  const { t } = useTranslation('ui');
  return (
    <Box>
      <Stack
        p={spacing.inset.large}
        gap={spacing.stack.xxxsmall}
        sx={{ whiteSpace: 'normal' }}
      >
        <Typography variant="subtitleLarge">
          {t('shipmentDetailsV2.destinationInfo.address')}
        </Typography>
        <Typography variant="bodyTextMedium">{receiverAddress}</Typography>
      </Stack>
      {pickupStatus && (
        <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
          <Typography variant="subtitleMedium">
            {t('shipmentDetailsV2.destinationInfo.pickupTime')}
          </Typography>
          <Typography variant="bodyTextMedium">{pickupStatus}</Typography>
        </Stack>
      )}
      <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.destinationInfo.name')}
        </Typography>
        <Typography variant="bodyTextMedium">{receiverName}</Typography>
      </Stack>
      <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.destinationInfo.phone')}
        </Typography>
        <Typography variant="bodyTextMedium">
          {receiverPhone
            ? formatPhoneNumber(receiverPhone)
            : t('shipmentDetailsV2.destinationInfo.noPhone')}
        </Typography>
      </Stack>
    </Box>
  );
};

DestinationInfo.propTypes = {
  receiverAddress: PropTypes.string.isRequired,
  receiverName: PropTypes.string.isRequired,
  receiverPhone: PropTypes.string.isRequired,
  pickupStatus: PropTypes.string
};

export default DestinationInfo;
