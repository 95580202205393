import { useLayoutEffect, useState } from 'react';

export const checkIsMobileUserAgent = () => {
  const UA = navigator.userAgent;
  return (
    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
  );
};

const _checkIsMobile = () => {
  let isMobile = false;
  if ('maxTouchPoints' in navigator) {
    isMobile = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    isMobile = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      isMobile = !!mQ.matches;
    } else if ('orientation' in window) {
      isMobile = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      isMobile = checkIsMobileUserAgent();
    }
  }
  return isMobile;
};

/** @deprecated react hook {@link useIsMobile()}  is recommended instead. */
export const checkIsMobile = _checkIsMobile;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(_checkIsMobile());
  const _handleWindowResize = () => setIsMobile(_checkIsMobile());

  useLayoutEffect(() => {
    window.addEventListener('resize', _handleWindowResize);
    return () => {
      window.removeEventListener('resize', _handleWindowResize);
    };
  }, []);

  return isMobile;
};

export default checkIsMobile;
