import { Box, Typography } from '@mui/material';
import Footer from 'UI/shipment/components/shipment-footer';
import { TermsDialog } from 'UI/shipment/pages/indispatch/terms-dialog/terms-dialog.component';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { debounce } from 'lodash';
import { AmateurQuoting } from 'models';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { root, yellow } from 'theme/colors';
import { MoneyInput } from 'UI/components/input';
import { currencyFormatter } from 'utils';
import { MAX_SERVICE_TYPE_INSURANCE_VALUE } from './insurance-value.helper';

export default function InsuranceValuePage() {
  const { t } = useTranslation('ui');
  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const [insuranceValue, setInsuranceValue] = React.useState(
    (context?.insurance * 100).toString() ?? ''
  );
  const [indispatchshowDialog, setIndispatchShowDialog] = React.useState(false);
  const maxValue = MAX_SERVICE_TYPE_INSURANCE_VALUE[context.serviceType];
  const floatValue = parseFloat(insuranceValue / 100);
  const isIndispatch = AmateurQuoting.isIndespacho({
    serviceType: context.serviceType
  });

  const handleSubmit = () => {
    if (isIndispatch) {
      setIndispatchShowDialog(true);
    } else {
      shipmentDispatcher.continue(floatValue);
    }
  };

  const handleChange = state => {
    const value = parseFloat(state / 100);
    const stringMaxValue = (maxValue * 100).toString();
    const safeValue = value > maxValue ? stringMaxValue : state;
    setInsuranceValue(safeValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateDeclaredValue = React.useCallback(
    debounce(shipmentDispatcher.setDeclaredValue, 500),
    []
  );

  useEffect(() => {
    if (floatValue !== context.insurance) {
      handleUpdateDeclaredValue(floatValue);
    }
  }, [context.insurance, floatValue, handleUpdateDeclaredValue]);

  return (
    <>
      <Box color={root[900]} mb={3}>
        <Typography mb={3} variant="subtitle1">
          {t('insurancePage.title')}
        </Typography>
        <MoneyInput
          id="insurance-input"
          onChange={handleChange}
          value={insuranceValue}
          unit="$"
          label={t('insurancePage.inputLabel')}
        />
      </Box>
      <Box bgcolor={yellow[50]} borderRadius="8px" padding={2}>
        <Typography variant="body2" fontWeight="medium" color={yellow[900]}>
          {t('insurancePage.disclaimerLabel', {
            count: currencyFormatter(maxValue).replace('R$', '')
          })}
        </Typography>
      </Box>
      {isIndispatch && (
        <TermsDialog
          isOpen={indispatchshowDialog}
          onConfirm={() => shipmentDispatcher.continue(floatValue)}
          onDismiss={() => setIndispatchShowDialog(false)}
        />
      )}
      <Footer showBorder>
        <Footer.EstimatePrice />
        <Footer.PrimaryAction onClick={handleSubmit} />
      </Footer>
    </>
  );
}
