export default class CRMBaseConfig {
  static initialize() {
    throw new Error(`Method initialize not implemented.`);
  }

  static isInitialized() {
    throw new Error(`Method isInitialized not implemented.`);
  }

  static createAdapter() {
    throw new Error(`Method createAdapter not implemented.`);
  }
}
