import { ReactComponent as LoggiDropoff } from 'assets/loggi-dropoff.svg';
import { ReactComponent as LoggiEasy } from 'assets/loggi-easy.svg';
import { ReactComponent as LoggiEconomic } from 'assets/loggi-economic.svg';
import { ReactComponent as LoggiExpress } from 'assets/loggi-express.svg';
import { AmateurQuoting } from 'models';
import React from 'react';

import PropTypes from 'prop-types';
import { currencyFormatter } from 'utils';

export const getDeliveryTypeInfo = ({ quoting, t }) => {
  const { serviceName, price, slo } = quoting;

  const deliveryTypeInfo = {
    beyond: {
      estimateDelivery: t('budgetV2.serviceButton.beyondDeliveryEstimate', {
        count: slo
      }),
      image: <LoggiEasy />,
      serviceName: t('budgetV2.serviceButton.beyondServiceName'),
      serviceDescription: t('budgetV2.serviceButton.beyondServiceDescription'),
      estimatePriceLabel: t('budgetV2.serviceButton.priceEstimate')
    },
    corp: {
      estimateDelivery: t('budgetV2.serviceButton.corpDeliveryEstimate'),
      image: <LoggiExpress />,
      serviceName: t('budgetV2.serviceButton.corpServiceName'),
      serviceDescription: t('budgetV2.serviceButton.corpServiceDescription'),
      estimatePriceLabel: t('budgetV2.serviceButton.priceEstimate')
    },
    dropoff: {
      estimateDelivery: t('budgetV2.serviceButton.dropoffDeliveryEstimate', {
        count: slo
      }),
      image: <LoggiDropoff />,
      serviceName: t('budgetV2.serviceButton.dropoffServiceName'),
      serviceDescription: t('budgetV2.serviceButton.dropoffServiceDescription'),
      estimatePriceLabel: t('budgetV2.serviceButton.priceEstimate')
    }
  };

  const estimatePrice = currencyFormatter(price).replace('R$', '');

  if (AmateurQuoting.INDESPACHO_SERVICE_TYPES.includes(serviceName)) {
    const { sloPac, sloSedex } = quoting;
    const sloIsEqual = sloPac === sloSedex;
    const estimateDelivery = sloIsEqual
      ? t('budgetV2.serviceButton.indespachoServiceEstimateWithEqualSlo', {
          count: sloSedex
        })
      : t('budgetV2.serviceButton.indespachoServiceEstimate', {
          countPac: sloPac,
          count: sloSedex
        });

    const indespachoDeliveryInfo = {
      cheapestPercentage: t(
        'budgetV2.serviceButton.indespachoServiceCheapestPercentage'
      ),
      estimateDelivery,
      image: <LoggiEconomic />,
      serviceName: t('budgetV2.serviceButton.indespachoServiceName'),
      serviceDescription: t(
        'budgetV2.serviceButton.indespachoServiceDescription'
      ),
      serviceSubtitle: t('budgetV2.serviceButton.indespachoServiceSubtitle'),
      estimatePriceLabel: t('budgetV2.serviceButton.priceEstimate')
    };

    return { ...indespachoDeliveryInfo, estimatePrice };
  }

  return {
    ...deliveryTypeInfo[serviceName],
    estimatePrice
  };
};

getDeliveryTypeInfo.propTypes = {
  t: PropTypes.func.isRequired,
  quoting: PropTypes.instanceOf(AmateurQuoting).isRequired
};

export default getDeliveryTypeInfo;
