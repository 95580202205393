import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { fetchCompanyUseCase } from 'domain/use-cases/fetch-company/fetch-company.use-case';
import { useFeatureSwitch } from 'hooks/feature-switch';

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

export const useCompanyData = companyId => {
  const shipmentV2Enabled = useFeatureSwitch('shipment_v2_enabled', false);

  return useQuery(
    ['company-data', String(companyId), shipmentV2Enabled.data],
    async () => {
      const company = await fetchCompanyUseCase(companyId);
      company.hasAccessShipmentFlowV2 =
        shipmentV2Enabled.data || company.hasAccessShipmentFlowV2;
      return company;
    },
    {
      enabled: !!companyId,
      cacheTime: Infinity,
      staleTime: FIVE_MINUTES_IN_MS,
      onError(err) {
        captureException(err);
      }
    }
  );
};

export default { useCompanyData };
