export class NonWorkingDay {
  constructor(nonWorkingDates) {
    this.nonWorkingDates = nonWorkingDates;
  }

  static parseApiResponse(response) {
    if (Object.keys(response.non_working_days_by_type).length === 0) {
      return null;
    }

    const nonWorkingDaysResponse = response.non_working_days_by_type;

    const dates = [];
    Array.from(nonWorkingDaysResponse, item =>
      Array.from(item.non_working_days_time, dt =>
        dates.push(new Date(`${dt}T00:00:00Z`))
      )
    );
    return new NonWorkingDay(
      [...new Set(dates.map(date => date.toISOString()))].map(
        date => new Date(date)
      )
    );
  }
}

export default NonWorkingDay;
