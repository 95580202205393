import { Typography } from '@mui/material';
import { ListItem } from '@loggi/front-design-system';
import { spacing } from '@loggi/front-design-tokens';

import PropTypes from 'prop-types';
import React from 'react';
import { TRACKING_STATUS } from 'constants/tracking.constants';

export const ShipmentCard = ({
  status,
  receiverName,
  trackingCode,
  destinationAddress,
  serviceType,
  handleClickAction,
  loggiKey,
  lastCard,
  isIndispatch,
  correiosTrackCode
}) => {
  return (
    <ListItem
      showBorder={!lastCard}
      onClick={() => handleClickAction(loggiKey)}
    >
      <Typography
        variant="overlineSmall"
        letterSpacing="1px"
        pb={spacing.stack.nano}
      >
        {isIndispatch && correiosTrackCode ? correiosTrackCode : trackingCode} •{' '}
        {serviceType}
      </Typography>
      <Typography variant="subtitleSmall" pb={spacing.stack.quark}>
        {receiverName}
      </Typography>
      <Typography
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
        variant="bodyTextMedium"
        pb={spacing.stack.nano}
      >
        {destinationAddress}
      </Typography>
      <Typography variant="subtitleSmall">{status.label}</Typography>
    </ListItem>
  );
};

ShipmentCard.defaultProps = {
  serviceType: '',
  lastCard: false,
  correiosTrackCode: ''
};

ShipmentCard.propTypes = {
  status: PropTypes.shape({
    occasionalShipperStatusCode: PropTypes.oneOf(
      Object.values(TRACKING_STATUS)
    ),
    label: PropTypes.string
  }).isRequired,
  receiverName: PropTypes.string.isRequired,
  trackingCode: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string.isRequired,
  serviceType: PropTypes.string,
  handleClickAction: PropTypes.func.isRequired,
  loggiKey: PropTypes.string.isRequired,
  lastCard: PropTypes.bool,
  isIndispatch: PropTypes.bool.isRequired,
  correiosTrackCode: PropTypes.string
};

export default ShipmentCard;
