import { Box, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { root } from 'theme/colors';

const HiddenScrollbarBox = styled(Box)({
  color: root[0],
  height: '100%',
  padding: 0,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiAutocomplete-option[data-focus="true"]': {
    backgroundColor: 'transparent'
  },
  '& ul': {
    display: 'block',
    overflowY: 'scroll',
    height: '100%',
    minHeight: '100%'
  }
});

export const AddressAutocompleteOptionList = ({ children }) => {
  const { t } = useTranslation('ui');
  const [, , hasPlaceList] = children.props.ownerState.options;

  return (
    <HiddenScrollbarBox>
      {hasPlaceList && (
        <Box color={root[900]} mt={4} mx={3}>
          <Typography variant="body1">
            {t('addressField.addressSelectionIndicator')}
          </Typography>
        </Box>
      )}
      {children}
    </HiddenScrollbarBox>
  );
};

AddressAutocompleteOptionList.propTypes = {
  children: PropTypes.node.isRequired
};

export default AddressAutocompleteOptionList;
