import { Box, Button, Typography } from '@mui/material';
import { BaseDrawer, InfiniteLoading } from 'UI/components';
import { initLogRocket } from 'configuration/logrocket-init';
import Header from 'UI/shipment/components/header';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { ReactComponent as Truck } from 'assets/loggi-truck-without-background.svg';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { blue, grey, root } from 'theme/colors';
import { useFeatureSwitches } from 'hooks/feature-switches/feature-switches';
import useTelemetry from 'UI/shipment/hooks/telemetry';
import { useGroupedShipment } from 'UI/contexts/grouped-shipment/grouped-shipment-in-pickup.context';
import HomeServiceClicked from 'crm/entities/events/home-service-clicked/home-service-clicked';
import HomeCorpButtonClicked from 'crm/entities/events/home-corp-button-clicked/home-corp-button-clicked';
import VisitHomePageModel from 'UI/acquisition/models/telemetry/visit-home-page';
import {
  CouponOfferBanner,
  CouponOfferDrawer,
  ServicesCards,
  AboutServiceCarousel,
  BannerDownloadApp
} from 'UI/acquisition/components';

import { WalletCard } from 'UI/acquisition/components/wallet-card/wallet-card';

import {
  DestinationAddressDisplay,
  OriginAddressDisplay
} from 'UI/shipment/components';
import { hasWebViewInterface } from 'mobile';
import { useCorp } from 'UI/contexts';
import { useFeatureSwitchEnabledByCompany } from 'hooks';

const outlinedStyle = {
  borderColor: blue[500],
  color: blue[500],
  height: '48px',
  borderRadius: '12px',
  '&:active': {
    borderColor: blue[500]
  },
  '&:hover': {
    borderColor: blue[500]
  }
};

export default function HomePage() {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();
  const shipmentContext = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const groupedShipment = useGroupedShipment();
  const telemetry = useTelemetry();
  const isSinglePointOfAccessEnabled = useFeatureSwitchEnabledByCompany(
    'enable_single_point_of_access',
    companyId
  );
  const LOGGI_COMPANY_ID = 3;

  const isLoggiCompany = parseInt(companyId, 10) === LOGGI_COMPANY_ID;

  const { openCorp } = useCorp();
  const [
    showAboutServiceCarousel,
    setShowAboutServiceCarousel
  ] = React.useState(false);
  const [changeService, setChangeService] = React.useState('');
  const isOriginAddressFilled =
    !!shipmentContext.context.pickupAddress?.description ||
    !!shipmentContext.context.originAddress;
  const hasNativeEngine = hasWebViewInterface();

  useFeatureSwitches({
    onSuccess(featureSwitches) {
      if (shipmentContext.isIdle) {
        shipmentDispatcher.init({
          companyId,
          featureSwitches,
          newHomeIsEnabled: true
        });

        telemetry.send(
          new VisitHomePageModel({
            company_id: companyId,
            feature_switches: featureSwitches
          })
        );
      }
    }
  });

  /**
   * This effect resets the chosen pickup every time that this page renders
   * after opting. This runs everytime that the user visit this page after
   * accepting to reuse an existing pickup
   */
  useEffect(() => {
    if (groupedShipment.chosenPickup) {
      groupedShipment.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickPickup = () => {
    if (groupedShipment.isEnabled && !groupedShipment.chosenPickup)
      return groupedShipment.show({
        onClose: () => shipmentDispatcher.clickPickupAddress()
      });

    return shipmentDispatcher.clickPickupAddress();
  };

  const onClickDelivery = () => {
    if (groupedShipment.isEnabled && !groupedShipment.chosenPickup)
      return groupedShipment.show({
        onClose: () => shipmentDispatcher.clickDeliveryAddress()
      });

    return shipmentDispatcher.clickDeliveryAddress();
  };

  const handleAboutService = service => {
    new HomeServiceClicked({ serviceName: service }).sendToCrm();
    setChangeService(service);
    setShowAboutServiceCarousel(true);
  };

  const handleClickCorpButton = () => {
    new HomeCorpButtonClicked().sendToCrm();
    openCorp();
  };

  useEffect(() => {
    initLogRocket();
  }, []);

  if (groupedShipment.isLoader) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
      >
        <InfiniteLoading title={t('groupedShipment.loader.text')} />
      </Box>
    );
  }

  if (showAboutServiceCarousel) {
    return (
      <AboutServiceCarousel
        onClose={() => setShowAboutServiceCarousel(false)}
        onContinue={shipmentDispatcher.clickPickupAddress}
        serviceName={changeService}
      />
    );
  }

  return (
    <Box color={root[900]} minHeight="100vh">
      <BannerDownloadApp />
      <Header>
        <Header.LoggiLogo />
        <Box marginLeft="auto">
          {!hasNativeEngine && isSinglePointOfAccessEnabled && (
            <Button
              variant="outlined"
              sx={{ ...outlinedStyle, marginRight: '28px' }}
              onClick={handleClickCorpButton}
            >
              <Typography variant="body2" fontWeight="bold">
                {t('newHome.buttonPreviousVersion')}
              </Typography>
            </Button>
          )}
          <Header.UserMenuButton />
        </Box>
      </Header>
      <Box py={3} px={3}>
        <Box my={2}>
          <Typography variant="subtitle1">{t('newHome.title')}</Typography>
        </Box>
        <Box>
          <OriginAddressDisplay onClick={onClickPickup} />
        </Box>
        <Box mt={2}>
          <DestinationAddressDisplay
            isDisabled={!isOriginAddressFilled}
            onClick={onClickDelivery}
          />
        </Box>
        <Box mt={4}>
          <Box mb={2}>
            <Typography variant="subtitle2" fontWeight="500">
              {t('newHome.labels.servicesSectionTitle')}
            </Typography>
            <Typography variant="body2" color={grey[250]}>
              {t('newHome.labels.servicesSectionSubtitle')}
            </Typography>
          </Box>
          <ServicesCards onClick={handleAboutService} />
        </Box>
        {!isLoggiCompany && (
          <WalletCard
            onAddBalance={() => shipmentDispatcher.clickAddWalletBalance()}
            onWalletClick={() => shipmentDispatcher.clickWalletCard()}
          />
        )}
        <CouponOfferBanner />
      </Box>
      <CouponOfferDrawer />
      <BaseDrawer
        description={t('continueShipment.information')}
        illustration={<Truck />}
        isOpen={!!shipmentContext.context.pendingContext}
        labels={{
          confirm: t('continueShipment.confirmButton'),
          dismiss: t('continueShipment.cancelButton')
        }}
        onConfirmClick={() =>
          shipmentDispatcher.selectContinueShipment({ choice: true })
        }
        onDismissClick={() =>
          shipmentDispatcher.selectContinueShipment({ choice: false })
        }
        title={t('continueShipment.title')}
        variant="temporary"
      >
        <Box mt={2}>
          <Typography align="center" variant="body2">
            {t('continueShipment.question')}
          </Typography>
        </Box>
      </BaseDrawer>
    </Box>
  );
}
