import { loggiWebApi } from '@loggi/authentication-lib';

const shippingApi = {
  create(url, formattedPayload) {
    return loggiWebApi
      .url(url, true)
      .accept('application/json')
      .content('application/json')
      .post(formattedPayload)
      .json();
  }
};

export default shippingApi;
