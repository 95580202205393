export const FREIGHT_TYPE_EXPRESS = 0;
export const FREIGHT_TYPE_ECONOMIC = 1;

export const COUPONS_ERRORS = {
  general: 'Desculpe, o sistema está fora do ar. Tente novamente mais tarde',
  not_found: 'O código deste cupom não é válido',
  order_rules_violated: 'Este cupom não está disponível',
  quantity_exceeded: 'Cupom já foi utilizado',
  redemption_rules_violated: 'Cupom já foi utilizado',
  voucher_expired: 'O prazo para utilizar este cupom expirou',
  voucher_disabled: 'O prazo para utilizar este cupom expirou'
};
