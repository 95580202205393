/* eslint-disable no-shadow */
import {
  PACKAGE_TYPES,
  SORT_ASC,
  STATUS_FILTER_AND_SHIPPER_STATUS_RELATIONS
} from '../constants/shipments.constants';
import { getGroupingSettingsForPackageType } from './grouping.utils';

export const getPackageType = (isDropoff, isIndispatch) => {
  if (isDropoff) return PACKAGE_TYPES.dropOff;
  if (isIndispatch) return PACKAGE_TYPES.indispatch;

  return PACKAGE_TYPES.regular;
};

export const getCorrespondingShipperStatusByFilters = statuses => {
  return Object.keys(PACKAGE_TYPES).reduce((result, key) => {
    const packageType = PACKAGE_TYPES[key];

    const filters = statuses
      .map(
        status =>
          STATUS_FILTER_AND_SHIPPER_STATUS_RELATIONS[packageType]?.[
            status.value
          ]
      )
      .filter(correspondingStatus => correspondingStatus !== undefined);

    return { ...result, [packageType]: filters };
  }, {});
};

export const filterByStatuses = (shipments, statuses, nextFilter) => {
  const filtersByPackageType = getCorrespondingShipperStatusByFilters(statuses);

  return statuses.length > 0
    ? nextFilter(
        shipments.filter(shipment => {
          const packageType = getPackageType(
            shipment.isDropoff(),
            shipment.isIndispatch()
          );
          const filters = filtersByPackageType[packageType];

          return filters.includes(shipment.status.occasionalShipperStatusCode);
        })
      )
    : nextFilter(shipments);
};

export const filterByTrackingCode = (shipments, trackingCode, nextFilter) => {
  return trackingCode
    ? nextFilter(
        shipments.filter(
          shipment =>
            (!shipment.isIndispatch() &&
              shipment.trackingCode.includes(trackingCode.toUpperCase())) ||
            (shipment.isIndispatch() &&
              shipment.correiosTrackCode?.includes(
                trackingCode.toUpperCase()
              )) ||
            shipment.receiverName
              .toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '') // Remover acentos
              .includes(
                trackingCode
                  .toUpperCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '') // Remover acentos
              )
        )
      )
    : nextFilter(shipments);
};

export const filterByGroup = (shipments, groupOption) => {
  return groupOption
    ? shipments.filter(shipment => {
        const filter = getGroupingSettingsForPackageType(
          groupOption,
          shipment.isIndispatch(),
          shipment.isDropoff()
        );

        return (
          filter && filter.includes(shipment.status.occasionalShipperStatusCode)
        );
      })
    : shipments;
};

export const sort = (shipments, sortOption) => {
  return sortOption
    ? shipments.slice().sort((a, b) => {
        const dateA = new Date(a.packageCreatedTime);
        const dateB = new Date(b.packageCreatedTime);

        if (sortOption.value === SORT_ASC) {
          return dateA - dateB;
        }

        return dateB - dateA;
      })
    : shipments;
};

export const applyFilters = (shipments, ...filters) => {
  return filters.reduce(
    (result, filter) => filter(result, applyFilters),
    shipments
  );
};

export const applyFiltersToShipments = (
  shipments,
  statuses,
  trackingCode,
  groupingOption,
  sortOption
) => {
  const shipmentsWithoutGrouping = applyFilters(
    shipments,
    (shipments, nextFilter) =>
      filterByStatuses(shipments, statuses, nextFilter),
    (shipments, nextFilter) =>
      filterByTrackingCode(shipments, trackingCode, nextFilter),
    shipments => sort(shipments, sortOption)
  );

  const filteredShipments = filterByGroup(
    shipmentsWithoutGrouping,
    groupingOption
  );

  return { shipments: filteredShipments, shipmentsWithoutGrouping };
};
