import sizeProps from 'constants/package-size.constants';
import * as icons from './icons';

const PACKAGE_OPTIONS = [
  { keyName: 'PP', icon: icons.PP, ...sizeProps.PP },
  { keyName: 'P', icon: icons.P, ...sizeProps.P },
  { keyName: 'M', icon: icons.M, ...sizeProps.M },
  { keyName: 'G', icon: icons.G, ...sizeProps.G }
];

export default PACKAGE_OPTIONS;
