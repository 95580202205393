import CnpjCpfValidator from './cnpj-cpf/cnpj-cpf.validator';
import ConfirmationCodeLengthValidator from './confirmation-code-length/confirmation-code-length.validator';
import CpfValidator from './cpf/cpf.validator';
import CepValidator from './cep/cep.validator';
import CnpjValidator from './cnpj/cnpj.validator';
import EmailValidator from './email/email.validator';
import { ModelValidator } from './model/model.validator';
import NonEmptyStringValidator from './non-empty-string/non-empty-string.validator';
import PasswordLengthValidator from './password-length/password-length.validator';
import PhoneValidator from './phone/phone.validator';
import AverageTicketValidator from './average-ticket/average-ticket.validator';
import EqualAddressesValidator from './pickup-delivery-address/pickup-delivery-address.validator';

export const DEFAULT_ERROR_MESSAGES = {
  CNPJ_CPF_PATTERN: 'validationError.cnpjCpfPattern',
  CODE_LENGTH_CONFIRMATION: 'validationError.codeConfirmation',
  CPF_PATTERN: 'validationError.cpfPattern',
  CNPJ_PATTERN: 'validationError.cnpjPattern',
  EMAIL_PATTERN: 'signIn.errorMessages.emailInvalid',
  NON_EMPTY_STRING: 'validationError.nonEmptyString',
  PASSWORD_LENGTH: 'validationError.passwordShortLength',
  PHONE_NUMBER_PATTERN: 'validationError.phoneNumberPattern',
  PICKUP_DELIVERY_ADDRESS: 'validationError.pickupDeliveryAddress',
  CEP_PATTERN: 'validationError.cepPattern',
  NON_EMPTY_STRING_AVERAGE_TICKET: 'validationError.nonEmptyTicketAverage'
};

export default class ValidatorBuilder {
  constructor() {
    this._selectedValidators = [];
  }

  build() {
    return value => {
      const errorKey = this._selectedValidators.find(fieldValidator => {
        return !fieldValidator.validate(value);
      });

      return {
        errorMessage: errorKey?.errorMessage ?? null,
        isValid: !errorKey
      };
    };
  }

  cnpjCpfPattern(errorMessage = DEFAULT_ERROR_MESSAGES.CNPJ_CPF_PATTERN) {
    this._addValidator(CnpjCpfValidator.validate, errorMessage);
    return this;
  }

  codeLengthConfirmation(
    errorMessage = DEFAULT_ERROR_MESSAGES.CODE_LENGTH_CONFIRMATION
  ) {
    this._addValidator(ConfirmationCodeLengthValidator.validate, errorMessage);
    return this;
  }

  cpfPattern(errorMessage = DEFAULT_ERROR_MESSAGES.CPF_PATTERN) {
    this._addValidator(CpfValidator.validate, errorMessage);
    return this;
  }

  cepPattern(errorMessage = DEFAULT_ERROR_MESSAGES.CEP_PATTERN) {
    this._addValidator(CepValidator.validate, errorMessage);
    return this;
  }

  cnpjPattern(errorMessage = DEFAULT_ERROR_MESSAGES.CNPJ_PATTERN) {
    this._addValidator(CnpjValidator.validate, errorMessage);
    return this;
  }

  emailPattern(errorMessage = DEFAULT_ERROR_MESSAGES.EMAIL_PATTERN) {
    this._addValidator(EmailValidator.validate, errorMessage);
    return this;
  }

  nonEmptyString(errorMessage = DEFAULT_ERROR_MESSAGES.NON_EMPTY_STRING) {
    this._addValidator(NonEmptyStringValidator.validate, errorMessage);
    return this;
  }

  passwordLength(errorMessage = DEFAULT_ERROR_MESSAGES.PASSWORD_LENGTH) {
    this._addValidator(PasswordLengthValidator.validate, errorMessage);
    return this;
  }

  phoneNumberPattern(
    errorMessage = DEFAULT_ERROR_MESSAGES.PHONE_NUMBER_PATTERN
  ) {
    this._addValidator(PhoneValidator.validate, errorMessage);
    return this;
  }

  averageTicketPattern(
    errorMessage = DEFAULT_ERROR_MESSAGES.NON_EMPTY_STRING_AVERAGE_TICKET
  ) {
    this._addValidator(AverageTicketValidator.validate, errorMessage);
    return this;
  }

  pickupDeliveryAddress(
    errorMessage = DEFAULT_ERROR_MESSAGES.PICKUP_DELIVERY_ADDRESS
  ) {
    this._addValidator(EqualAddressesValidator.validate, errorMessage);
    return this;
  }

  model(errorMessage) {
    this._addValidator(ModelValidator.validate, errorMessage);
    return this;
  }

  _addValidator(validate, errorMessage) {
    this._selectedValidators.push({ validate, errorMessage });
  }
}
