import React from 'react';
import { Box, Button, Radio } from '@mui/material';
import { spacing } from '@loggi/front-design-tokens';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SORT_OPTIONS } from 'UI/shipments-v2/constants/shipments.constants';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import { ActionVisualizerMobile } from '../action-visualizer-mobile/action-visualizer-mobile.component';
import { CardSelectionInline } from '../card-selection-inline/card-selection-inline.component';

export const SortMobile = ({ handleClose }) => {
  const { t } = useTranslation('ui');
  const { setSort, sort } = useShipments();

  return (
    <ActionVisualizerMobile
      closeAction={handleClose}
      label={t('myShipmentsV2.sortShipmentsMobile.title')}
    >
      <Box
        flexDirection="column"
        gap={spacing.stack.xxxsmall}
        display="flex"
        mb={spacing.stack.xxsmall}
      >
        {SORT_OPTIONS.map(option => {
          const selected = sort.value === option.value;
          return (
            <CardSelectionInline
              key={option.value}
              selected={selected}
              label={option.label}
              handleClick={() => setSort(option)}
            >
              <Radio value={option.label} checked={selected} />
            </CardSelectionInline>
          );
        })}
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        marginTop={spacing.stack.xxsmall}
        px={spacing.inset.large}
        py={spacing.stack.xxsmall}
        gap={spacing.stack.nano}
      >
        <Button variant="contained" onClick={() => handleClose()} size="large">
          {t('myShipmentsV2.sortShipmentsMobile.button')}
        </Button>
      </Box>
    </ActionVisualizerMobile>
  );
};

SortMobile.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default SortMobile;
