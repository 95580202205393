import { spacing, colors } from '@loggi/front-design-tokens';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Alert, Button, Typography } from '@mui/material';
import React from 'react';

export const ShipmentDetailsPickupInfo = ({
  message,
  type = 'alert',
  supportPage,
  action = true,
  isMobileDevice
}) => {
  const redirectToSupportPage = ev => {
    ev.stopPropagation();
    const url = supportPage;

    if (isMobileDevice) {
      window.open(url, '_self');

      return;
    }

    window.open(url, '_blank', 'noopener');
  };

  const { t } = useTranslation('ui');

  return (
    <Box p={spacing.inset.large}>
      <Alert
        icon={false}
        actionButton={false}
        variant="inside"
        type={type}
        size="large"
      >
        <Box
          justifyContent="space-between"
          gap={spacing.stack.quark}
          alignItems="center"
          display="flex"
        >
          <Box flex={1}>
            <Typography variant="bodyTextLarge">{message}</Typography>
          </Box>
          {action && (
            <Box textAlign="right">
              <Button
                onClick={redirectToSupportPage}
                variant="text"
                size="medium"
              >
                <Typography
                  color={colors.feedback['on-container']}
                  variant="bodyTextSmall"
                >
                  {t('shipmentDetailsV2.pickupInfo.button')}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Alert>
    </Box>
  );
};

ShipmentDetailsPickupInfo.defaultProps = {
  type: 'alert',
  action: true
};

ShipmentDetailsPickupInfo.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  supportPage: PropTypes.string.isRequired,
  action: PropTypes.bool,
  isMobileDevice: PropTypes.bool.isRequired
};

export default ShipmentDetailsPickupInfo;
