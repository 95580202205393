export const getLowestPricedServiceAmongIndispatchServices = (
  indispatchPac,
  indispatchSedex
) => {
  if (indispatchSedex?.price < indispatchPac?.price) {
    return indispatchSedex;
  }
  return indispatchPac;
};

export default { getLowestPricedServiceAmongIndispatchServices };

export const buildsIndispatchServiceInformations = (
  indispatchPac,
  indispatchSedex
) => {
  if (!indispatchPac?.price) return null;

  const price =
    indispatchPac?.price < indispatchSedex?.price
      ? indispatchPac?.price
      : indispatchSedex?.price;
  const indespachoService = {
    sloPac: indispatchPac?.slo,
    price,
    sloSedex: indispatchSedex?.slo,
    serviceType: indispatchPac?.serviceType,
    serviceName: indispatchPac?.serviceName
  };

  return indespachoService;
};
