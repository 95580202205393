import { RECIPIENT_CONTACT_BEYOND_EMAIL } from 'constants/tracking.constants';

export const EMAIL_TYPE = 'cancel';

const getSubjectContactEmail = (trackingCode, type) => {
  if (type === EMAIL_TYPE) return `Cancelar pacote ${trackingCode}`;

  return `Pacote ${trackingCode}`;
};

const getBodyContactEmail = ({
  name,
  email,
  trackingCode,
  receiverName,
  pickupDateTime,
  pickupAddress,
  destinationAddress
}) =>
  `Logger, a Loggi vai te ligar para resolver seu problema. Precisamos só de algumas informações.

Meu telefone é: 
Meu problema é: 

----------------------------
Escreva sua mensagem acima da linha pontilhada.
Nome: ${name}
Email: ${email}
Pacote: ${trackingCode}
Data e horário da coleta: ${pickupDateTime ?? '-'}
Endereço da coleta: ${pickupAddress ?? '-'}
Endereço da entrega: ${destinationAddress ?? '-'}
Nome do destinatário: ${receiverName ?? '-'}
`;

export const createContactEmailUriUseCase = ({
  getCurrentUserUseCase
}) => async ({
  trackingCode,
  receiverName,
  pickupDateTime,
  pickupAddress,
  destinationAddress,
  type
}) => {
  const user = await getCurrentUserUseCase();
  const subject = encodeURIComponent(
    getSubjectContactEmail(trackingCode, type)
  );
  const body = encodeURIComponent(
    getBodyContactEmail({
      name: user.name,
      email: user.email,
      trackingCode,
      receiverName,
      pickupDateTime,
      pickupAddress,
      destinationAddress
    })
  );

  return `mailto:${RECIPIENT_CONTACT_BEYOND_EMAIL}?subject=${subject}&body=${body}`;
};

export default { createContactEmailUriUseCase };
