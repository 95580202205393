import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';

import { checkIsMobile } from 'utils/check-is-mobile/check-is-mobile.helper';

import Coupon from './coupon';
import EstimatePrice from './estimate-price';
import PrimaryAction from './primary-action';
import ShipmentBar from './shipment-bar';

export default function Footer({ children }) {
  const isMobileDevice = checkIsMobile();

  return (
    <ThemeProvider theme={theme}>
      <Stack
        width="100%"
        position={isMobileDevice ? 'fixed' : 'absolute'}
        bottom="0"
        right="0"
        left="0"
      >
        {children}
      </Stack>
    </ThemeProvider>
  );
}

Footer.propTypes = {
  children: PropTypes.node.isRequired
};

Footer.Coupon = Coupon;
Footer.EstimatePrice = EstimatePrice;
Footer.PrimaryAction = PrimaryAction;
Footer.ShipmentBar = ShipmentBar;
