import {
  registerInitializedSDK,
  isSDKInitialized
} from 'crm/adapters/registry/initialize';
import CRMAdapterNativeSingular from 'crm/adapters/singular/crm-adapter-native-singular';
import CRMAdapterSingular from 'crm/adapters/singular/crm-adapter-singular';
import { hasEventRegistered as hasNativeInterfaceEventRegistered } from 'mobile';
import { SingularConfig as Singular, singularSdk } from 'singular-sdk';
import CRMBaseConfig from '../crm-base-config';

export default class SingularConfig extends CRMBaseConfig {
  static get name() {
    return 'singular';
  }

  static get isNativeSDKAvailable() {
    return (
      hasNativeInterfaceEventRegistered('setSingularUserId') &&
      hasNativeInterfaceEventRegistered('logSingularAnalyticsEvent') &&
      hasNativeInterfaceEventRegistered('logSingularRevenueEvent')
    );
  }

  static isInitialized() {
    return isSDKInitialized(SingularConfig.name);
  }

  static initialize({ singularSdkKey, singularSdkSecret, singularProductId }) {
    if (!SingularConfig.isNativeSDKAvailable) {
      const config = new Singular(
        singularSdkKey,
        singularSdkSecret,
        singularProductId
      );
      singularSdk.init(config);
    }
    registerInitializedSDK(SingularConfig.name);
  }

  static createAdapter() {
    if (!SingularConfig.isInitialized()) return null;

    return SingularConfig.isNativeSDKAvailable
      ? new CRMAdapterNativeSingular()
      : new CRMAdapterSingular();
  }
}
