import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, styled } from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/search.svg';

const SearchInputContainer = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${palette.grey[500]}`,
  borderRadius: spacing(1),
  padding: spacing(0.5, 2),
  bgcolor: palette.common.white
}));

const SearchInput = styled(InputBase)(({ theme: { palette } }) => ({
  textOverflow: 'ellipsis',
  fontSize: '15px',
  color: palette.grey[900]
}));

const SegmentSearchBar = ({ segments, setFilteredSegments }) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation('ui');
  const DEBOUNCE_DELAY_MS = 400;

  const filterSearchedSegments = useCallback(() => {
    const filteredSegments = segments.filter(({ label }) =>
      label.toLowerCase().includes(searchValue)
    );
    setFilteredSegments(filteredSegments);
  }, [segments, setFilteredSegments, searchValue]);

  useEffect(() => {
    if (searchValue) {
      const debounceId = setTimeout(() => {
        filterSearchedSegments();
      }, DEBOUNCE_DELAY_MS);
      return () => clearTimeout(debounceId);
    }
    return setFilteredSegments([...segments]);
  }, [searchValue, segments, filterSearchedSegments, setFilteredSegments]);

  return (
    <SearchInputContainer py={3} mb={2}>
      <Box display="flex" align-items="stretch" pl={1} flexGrow={1}>
        <SearchInput
          fullWidth
          id="segment-search"
          name="segment-search"
          inputProps={{
            'data-testid': 'segment-search',
            variant: 'body2'
          }}
          placeholder={t('input.placeholder')}
          variant="body2"
          color="primary"
          type="search"
          onChange={event => setSearchValue(event.target.value.toLowerCase())}
          value={searchValue}
        />
      </Box>
      <SearchIcon color="primary" />
    </SearchInputContainer>
  );
};

SegmentSearchBar.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  setFilteredSegments: PropTypes.func.isRequired
};

export default SegmentSearchBar;
