/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { padStart, trimStart } from 'lodash';
import { BaseInputField } from './base-input';

function formatValue(value) {
  return trimStart(
    parseFloat(
      padStart(value, 3, '0').replace(/(\d+)(\d{2})/g, '$1.$2')
    ).toLocaleString('pt-BR', {
      currency: 'BRL',
      style: 'currency',
      minimumFractionDigits: 2
    }),
    'R$ '
  );
}

function unformatValue(value) {
  return trimStart(value, '0').replace(/\D/g, '');
}

const BRLPrefix = () => <span>R$</span>;

export function MoneyInput(props) {
  return (
    <BaseInputField
      id={props.id}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      startAdornment={<BRLPrefix />}
      formatValue={formatValue}
      unformatValue={unformatValue}
      inputMode="numeric"
    />
  );
}

MoneyInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
