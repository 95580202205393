import { useCurrentUser } from 'auth';
import { identifyUser } from 'configuration/logrocket-init';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { login, initial } from 'routes/routes';
import Loader from 'UI/components/loader/loader.component';
import { useDomainUseCase } from 'UI/contexts';
import { useInitialPageEnable } from 'UI/pages/initial/initial.page';

function PrivateRoute({ children }) {
  const getCurrentUser = useDomainUseCase('getCurrentUserUseCase');
  const user = useCurrentUser();
  const [isAuthenticated, setAuthenticated] = useState(null);
  const isInitialPageEnable = useInitialPageEnable();
  const firstRoute = isInitialPageEnable ? initial : login;

  const checkCurrentUser = useCallback(async () => {
    const currentUser = await getCurrentUser();
    setAuthenticated(!!currentUser);

    identifyUser({
      user: currentUser,
      userCustomAccess: user
    });
  }, [getCurrentUser, user]);

  useEffect(() => {
    checkCurrentUser();
  }, [checkCurrentUser]);

  if (isAuthenticated === null) return <Loader />;
  if (!isAuthenticated) return <Redirect from="*" to={firstRoute} />;
  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export function protect(Component) {
  return () => (
    <PrivateRoute>
      <Component />
    </PrivateRoute>
  );
}

export default { PrivateRoute };
