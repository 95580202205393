import { loggiWebApi } from '@loggi/authentication-lib';

const URL = process.env.REACT_APP_PY_BEYOND_URL;
/**
 * Generates a tag for a package.
 *
 * @param {string} loggiKey - The Loggi key associated with the package.
 * @param {string} companyId - The ID of the company associated with the package.
 * @returns {Promise<{ label: { file_name: string, content: string } }>} A promise that resolves to an object containing the generated tag information, including the file name and content wich is in base64.
 */
export const generateTagUseCase = (loggiKey, companyId) => {
  return loggiWebApi
    .url(
      `${URL}/documents-generation/v1/documents/${companyId}/${loggiKey}`,
      true
    )
    .get()
    .json();
};

export default generateTagUseCase;
