/* eslint-disable import/prefer-default-export */
/** @typedef {import('models').Address} Address */

/**
 * @param {Address} address
 * @returns {Boolean}
 */
export const hasAddressNumber = ({ structured: { main } }) => {
  const addressNumberRegex = /,\s\d+/;
  return addressNumberRegex.test(main);
};
