import companiesAccountApi from 'service/companies-account-api';
import { Company } from 'models/company.model';

export const fetchCompanyUseCase = companyId => {
  return companiesAccountApi
    .getCompany()
    .then(response => Company.parseApiResponse(response, companyId));
};

export default { fetchCompanyUseCase };
