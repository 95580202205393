/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import Footer from './footer';
import Header from './header';
import usePaginationDeck from './pagination';
import { carouselStyles } from './styles';

export default function Slides({
  children,
  title,
  onClose,
  onContinue,
  initialIndex = 0
}) {
  const length = React.Children.count(children);
  const styles = carouselStyles();
  const pagination = usePaginationDeck({
    initialIndex,
    total: length
  });

  return (
    <>
      <Header
        isFirst={pagination.isFirst}
        onBack={() => pagination.prev()}
        onClose={onClose}
      >
        <Typography variant="body2">
          <strong>{title}</strong>
        </Typography>
      </Header>

      <Box px={4} minHeight="calc(100vh - 120px)">
        <Swiper
          className={styles.customPagination}
          initialSlide={initialIndex}
          modules={[Pagination, Virtual]}
          onSlideChange={({ activeIndex }) =>
            pagination.setCurrentIndex(activeIndex)
          }
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={30}
          style={{
            padding: '16px 0'
          }}
          onSwiper={pagination.setSwiper}
        >
          {React.Children.map(children, (child, index) => (
            <SwiperSlide key={`slide-${index}`}>
              <Box mb={6}>{child}</Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <Footer
          isLast={pagination.isLast}
          onNext={pagination.next}
          onFinish={onContinue}
        />
      </Box>
    </>
  );
}

Slides.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  initialIndex: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired
};

Slides.defaultProps = {
  initialIndex: 0
};
