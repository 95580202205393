import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

export const UserRecordsContext = createContext({});

export function UserRecordsContextProvider({ children }) {
  const [userRecords, setLocalUserRecords] = useState({
    name: '',
    email: '',
    phone: '',
    cpf: '',
    cep: '',
    cnpj: '',
    averageTicket: '',
    sharedName: '',
    noL4BStatus: false,
    integrators: [],
    otherIntegrator: '',
    segment: null,
    estimatedMonthlyPackages: ''
  });

  function setUserRecords(updatedUserRecords) {
    if (!isEqual(userRecords, updatedUserRecords))
      setLocalUserRecords(updatedUserRecords);
  }

  return (
    <UserRecordsContext.Provider value={{ userRecords, setUserRecords }}>
      {children}
    </UserRecordsContext.Provider>
  );
}

UserRecordsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useUserRecords = () => {
  return useContext(UserRecordsContext);
};
