import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import useCoupons from 'UI/shipment/hooks/coupons';
import { ReactComponent as EmptyCouponsIllustration } from 'assets/empty-coupons.svg';
import { useTranslation } from 'react-i18next';
import {
  CouponCard,
  CouponCardLoader
} from '../coupon-card/coupon-card.component';

/**
 * @param {object} props
 * @param {(coupon: string) => void} props.onSelectCoupon
 * @returns {React.Component}
 */
export default function CouponList({ onSelectCoupon }) {
  const { t } = useTranslation('ui');
  const [selected, setSelected] = React.useState(null);
  const coupons = useCoupons();

  const handleSelect = coupon => {
    setSelected(coupon);
    onSelectCoupon(coupon.code);
  };

  if (coupons.isLoading) {
    return (
      <>
        <CouponCardLoader />
        <CouponCardLoader />
        <CouponCardLoader />
      </>
    );
  }

  if (coupons.data && coupons.data.coupons.length === 0) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        mt={5}
      >
        <Box mb={3}>
          <EmptyCouponsIllustration />
        </Box>
        <Typography variant="body1">
          {t('couponsPageV2.emptyList.message')}
        </Typography>
      </Box>
    );
  }

  return coupons.data.coupons.map(coupon => (
    <CouponCard
      coupon={coupon}
      key={coupon.code}
      onSubmit={handleSelect}
      isActive={selected?.code === coupon.code}
    />
  ));
}

CouponList.propTypes = {
  onSelectCoupon: PropTypes.func.isRequired
};
