import { Box, Card, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/components';
import { supportIsWithinWorkingHoursNow } from './modal.helpers';

const BaseModal = ({ title, open, onClick, children }) => {
  const { t } = useTranslation('ui');

  return (
    <Modal
      open={open}
      disablePortal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Card style={{ maxWidth: '400px', width: '88%' }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            px={3}
            py={4}
          >
            {title && (
              <Box textAlign="center" pb={4}>
                <Typography variant="subtitle1">
                  <strong>{title}</strong>
                </Typography>
              </Box>
            )}

            <Box textAlign="center" pb={4}>
              {children}
            </Box>
            <Button
              fullWidth
              label={t('support.modal.action')}
              onClick={onClick}
              size="lg"
            />
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

const SupportRequestSuccessfulModal = ({ open, onClick }) => {
  const { t } = useTranslation('ui');
  return (
    <BaseModal
      title={t('support.modal.success.title')}
      open={open}
      onClick={onClick}
    >
      <Typography variant="body2">
        {t('support.modal.success.description')}
      </Typography>
    </BaseModal>
  );
};

const SupportRequestSuccessOutOfWorkingHoursModal = ({ open, onClick }) => {
  const { t } = useTranslation('ui');
  return (
    <BaseModal
      title={t('support.modal.success-out-of-range.title')}
      open={open}
      onClick={onClick}
    >
      <Box>
        <Typography variant="body2">
          <strong>{t('support.modal.success-out-of-range.info-1')}</strong>
        </Typography>
        <Typography variant="body2">
          {t('support.modal.success-out-of-range.info-2')}
        </Typography>
      </Box>
      <Box pb={3}>
        <Typography variant="body2">
          <strong>{t('support.modal.success-out-of-range.info-3')}</strong>
        </Typography>
        <Typography variant="body2">
          {t('support.modal.success-out-of-range.info-4')}
        </Typography>
      </Box>
      <Typography variant="body2">
        {t('support.modal.success-out-of-range.description')}
      </Typography>
    </BaseModal>
  );
};

const SupportRequestErrorModal = ({ open, onClick }) => {
  const { t } = useTranslation('ui');
  return (
    <BaseModal open={open} onClick={onClick}>
      <Typography variant="body2">
        {t('support.modal.error.description')}
      </Typography>
    </BaseModal>
  );
};

const confirmationVariants = {
  success: SupportRequestSuccessfulModal,
  successOutOfTimeRange: SupportRequestSuccessOutOfWorkingHoursModal,
  error: SupportRequestErrorModal
};

const selectModal = type => {
  const supportIsActiveNow = supportIsWithinWorkingHoursNow(new Date());

  if (type === 'success' && supportIsActiveNow)
    return confirmationVariants.success;

  if (type === 'success' && !supportIsActiveNow)
    return confirmationVariants.successOutOfTimeRange;

  if (type === 'error') return confirmationVariants.error;

  return null;
};

const SupportRequestConfirmationModal = ({ type, open, onClick }) => {
  const Component = selectModal(type);
  return <Component open={open} onClick={onClick} />;
};

const ModalPropTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

SupportRequestSuccessfulModal.propTypes = ModalPropTypes;
SupportRequestSuccessOutOfWorkingHoursModal.propTypes = ModalPropTypes;
SupportRequestErrorModal.propTypes = ModalPropTypes;
BaseModal.propTypes = {
  ...ModalPropTypes,
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};
BaseModal.defaultProps = {
  title: ''
};

SupportRequestConfirmationModal.propTypes = {
  ...ModalPropTypes,
  type: PropTypes.oneOf(['success', 'error']).isRequired
};

export default SupportRequestConfirmationModal;
