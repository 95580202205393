import {
  Bling,
  Tiny,
  ContaAzul,
  Eccosys,
  Frenet,
  Linx,
  Omie,
  Totvs,
  Wbuy
} from 'assets/integrators';

const integratorsOptionsList = [
  {
    label: 'Bling',
    value: 'BLING',
    logo: Bling
  },
  {
    label: 'Tiny',
    value: 'TINY',
    logo: Tiny
  },
  {
    label: 'ContaAzul',
    value: 'CONTA_AZUL',
    logo: ContaAzul
  },
  {
    label: 'Eccosys',
    value: 'ECCOSYS',
    logo: Eccosys
  },
  {
    label: 'Frenet',
    value: 'FRENET',
    logo: Frenet
  },
  {
    label: 'Linx',
    value: 'LINX',
    logo: Linx
  },
  {
    label: 'Omie',
    value: 'OMIE',
    logo: Omie
  },
  {
    label: 'Totvs',
    value: 'TOTVS',
    logo: Totvs
  },
  {
    label: 'Wbuy',
    value: 'WBUY',
    logo: Wbuy
  }
];

export default integratorsOptionsList;
