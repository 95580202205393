import { get } from 'lodash';
import { fromMoneyToNumber } from 'utils';

export class AmateurQuoting {
  constructor({
    amateur_service_type: serviceType,
    slo_in_days: slo,
    total_amount: totalAmount
  }) {
    /** @type {number} */
    this.price = fromMoneyToNumber(totalAmount);
    /** @type {number} */
    this.slo = slo;
    /** @type {string} */
    this.serviceType = serviceType;
  }

  static get BEYOND_SERVICE_TYPE() {
    return 'beyond';
  }

  static get CORP_SERVICE_TYPE() {
    return 'corp';
  }

  static get DROPOFF_SERVICE_TYPE() {
    return 'dropoff';
  }

  static get INDESPACHO_PAC_SERVICE_TYPE() {
    return 'indespachoPac';
  }

  static get INDESPACHO_SEDEX_SERVICE_TYPE() {
    return 'indespachoSedex';
  }

  static get INVALID_SERVICE_TYPE() {
    return 'invalid';
  }

  static get INDESPACHO_SERVICE_TYPES() {
    return [
      AmateurQuoting.INDESPACHO_PAC_SERVICE_TYPE,
      AmateurQuoting.INDESPACHO_SEDEX_SERVICE_TYPE
    ];
  }

  static isBeyond(serviceType) {
    return serviceType === 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC';
  }

  static isCorp(serviceType) {
    return serviceType === 'AMATEUR_SERVICE_TYPE_LOCAL_EXPRESS';
  }

  static isDropoff(serviceType = '') {
    return serviceType === 'AMATEUR_SERVICE_TYPE_DROPOFF';
  }

  static isIndespacho({ serviceType = '' } = {}) {
    const indespachoServiceTypes = [
      'AMATEUR_SERVICE_TYPE_CORREIOS_PLP_PAC',
      'AMATEUR_SERVICE_TYPE_CORREIOS_PLP_SEDEX'
    ];
    return (
      indespachoServiceTypes.includes(serviceType) ||
      AmateurQuoting.INDESPACHO_SERVICE_TYPES.includes(serviceType)
    );
  }

  get serviceName() {
    const servicesByType = {
      AMATEUR_SERVICE_TYPE_LOCAL_EXPRESS: AmateurQuoting.CORP_SERVICE_TYPE,
      AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC:
        AmateurQuoting.BEYOND_SERVICE_TYPE,
      AMATEUR_SERVICE_TYPE_DROPOFF: AmateurQuoting.DROPOFF_SERVICE_TYPE,
      AMATEUR_SERVICE_TYPE_CORREIOS_PLP_PAC:
        AmateurQuoting.INDESPACHO_PAC_SERVICE_TYPE,
      AMATEUR_SERVICE_TYPE_CORREIOS_PLP_SEDEX:
        AmateurQuoting.INDESPACHO_SEDEX_SERVICE_TYPE,
      AMATEUR_SERVICE_TYPE_INVALID: AmateurQuoting.INVALID_SERVICE_TYPE
    };
    return get(
      servicesByType,
      this.serviceType,
      AmateurQuoting.INVALID_SERVICE_TYPE
    );
  }
}

export default { AmateurQuoting };
