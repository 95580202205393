import icons from 'constants/icons-urls.json';
import { blue, green, grey, red } from 'theme/colors';

export const TRACKING_CODE_URL_PARAM = 'tracking_code';
export const MY_SHIPMENTS_PAGE_URL_PARAM = 'page';
export const MY_SHIPMENTS_SEARCH_FILTER_VALUE_URL_PARAM = 'search';
export const MY_SHIPMENTS_STATUS_FILTER_VALUE_URL_PARAM = 'status';
export const MY_SHIPMENTS_DATE_ORDER_VALUE_URL_PARAM = 'order';
export const MY_SHIPMENTS_GENERAL_STATUS_FILTER_VALUE_URL_PARAM =
  'general_status';

export const TRACKING_STATUS = {
  delivered: 'OCCASIONAL_SHIPPER_STATUS_CODE_DELIVERED',
  deliveryFailure: 'OCCASIONAL_SHIPPER_STATUS_CODE_DELIVERY_FAILURE',
  lost: 'OCCASIONAL_SHIPPER_STATUS_CODE_LOST',
  onRouteToDelivery: 'OCCASIONAL_SHIPPER_STATUS_CODE_ON_ROUTE_TO_DELIVERY',
  inTransit: 'OCCASIONAL_SHIPPER_STATUS_CODE_IN_TRANSIT',
  pickupAllocated: 'OCCASIONAL_SHIPPER_STATUS_CODE_PICKUP_ALLOCATED',
  pickupScheduled: 'OCCASIONAL_SHIPPER_STATUS_CODE_PICKUP_SCHEDULED',
  problemNeedMoreInfo: 'OCCASIONAL_SHIPPER_STATUS_CODE_PROBLEM_NEED_MORE_INFO',
  processing: 'OCCASIONAL_SHIPPER_STATUS_CODE_PROCESSING',
  returningCompleted: 'OCCASIONAL_SHIPPER_STATUS_CODE_RETURNING_COMPLETED',
  returningToShipper: 'OCCASIONAL_SHIPPER_STATUS_CODE_RETURNING_TO_SHIPPER',
  waitingShipperPickup: 'OCCASIONAL_SHIPPER_STATUS_CODE_WAITING_SHIPPER_PICKUP',
  canceledByAllocation: 'OCCASIONAL_SHIPPER_STATUS_CODE_CANCELED_BY_ALLOCATION',
  dropOff: 'OCCASIONAL_SHIPPER_STATUS_CODE_DROP_OFF',
  labelSent: 'OCCASIONAL_SHIPPER_STATUS_CODE_LABEL_SENT',
  trackingCodeSent: 'OCCASIONAL_SHIPPER_STATUS_CODE_TRACKING_CODE_SENT',
  indispatch: 'OCCASIONAL_SHIPPER_STATUS_CODE_INDISPATCH'
};

export const PICKUP_TYPE = {
  dedicated: 'PICKUP_TYPE_DEDICATED',
  dropOff: 'PICKUP_TYPE_DROP_OFF',
  milkyRun: 'PICKUP_TYPE_MILK_RUN',
  spot: 'PICKUP_TYPE_SPOT',
  spotNotLabelled: 'PICKUP_TYPE_SPOT_NOT_LABELLED'
};

export const GENERAL_STATUS_FILTERS = {
  pickupPending: 'PICKUP_PENDING',
  pending: 'PENDING',
  withProblem: 'WITH_PROBLEM'
};

export const TRACKING_CARD_WITH_PROBLEM_STATUS = [
  TRACKING_STATUS.deliveryFailure,
  TRACKING_STATUS.lost,
  TRACKING_STATUS.problemNeedMoreInfo
];

export const VARIANTS_BY_STATUS = {
  [TRACKING_STATUS.delivered]: 'success',
  [TRACKING_STATUS.deliveryFailure]: 'danger',
  [TRACKING_STATUS.lost]: 'danger',
  [TRACKING_STATUS.onRouteToDelivery]: 'normal',
  [TRACKING_STATUS.pickupAllocated]: 'normal',
  [TRACKING_STATUS.inTransit]: 'normal',
  [TRACKING_STATUS.pickupScheduled]: 'normal',
  [TRACKING_STATUS.problemNeedMoreInfo]: 'danger',
  [TRACKING_STATUS.processing]: 'normal',
  [TRACKING_STATUS.returningCompleted]: 'info',
  [TRACKING_STATUS.returningToShipper]: 'info',
  [TRACKING_STATUS.waitingShipperPickup]: 'success',
  [TRACKING_STATUS.canceledByAllocation]: 'info',
  [TRACKING_STATUS.dropOff]: 'normal',
  [TRACKING_STATUS.labelSent]: 'normal',
  [TRACKING_STATUS.indispatch]: 'normal',
  [TRACKING_STATUS.trackingCodeSent]: 'normal'
};

export const TRACKING_ICONS_BY_STATUS = {
  [TRACKING_STATUS.inTransit]: {
    background: blue[50],
    color: blue[500],
    name: 'fastDelivery'
  },
  [TRACKING_STATUS.delivered]: {
    background: green[50],
    color: green[500],
    name: 'truck'
  },
  [TRACKING_STATUS.deliveryFailure]: {
    background: red[50],
    color: red[500],
    name: 'packageBlock'
  },
  [TRACKING_STATUS.lost]: {
    background: red[50],
    color: red[500],
    name: 'packageBlock'
  },
  [TRACKING_STATUS.onRouteToDelivery]: {
    name: 'truckFast',
    color: blue[500],
    background: blue[50]
  },
  [TRACKING_STATUS.pickupAllocated]: {
    background: blue[50],
    color: blue[500],
    name: 'bag'
  },
  [TRACKING_STATUS.pickupScheduled]: {
    background: blue[50],
    color: blue[500],
    name: 'alarmClockCheckmark'
  },
  [TRACKING_STATUS.problemNeedMoreInfo]: {
    background: red[50],
    color: red[500],
    name: 'packageBlock'
  },
  [TRACKING_STATUS.processing]: {
    background: blue[50],
    color: blue[500],
    name: 'packageHand'
  },
  [TRACKING_STATUS.returningCompleted]: {
    background: green[50],
    color: green[500],
    name: 'truck'
  },
  [TRACKING_STATUS.returningToShipper]: {
    background: blue[50],
    color: blue[500],
    name: 'boxDeliveryPackageReturnCustomer'
  },
  [TRACKING_STATUS.waitingShipperPickup]: {
    background: green[50],
    color: green[500],
    name: 'truck'
  },
  [TRACKING_STATUS.canceledByAllocation]: {
    background: grey[50],
    color: grey[500],
    name: 'packageBlock'
  },
  [TRACKING_STATUS.dropOff]: {
    background: grey[50],
    color: grey[500],
    name: 'packageHand'
  },
  [TRACKING_STATUS.labelSent]: {
    background: blue[50],
    color: blue[500],
    name: 'email'
  },
  [TRACKING_STATUS.indispatch]: {
    background: grey[50],
    color: grey[500],
    name: 'packageHand'
  },
  [TRACKING_STATUS.trackingCodeSent]: {
    background: blue[50],
    color: blue[500],
    name: 'barcode'
  }
};

export const TRACKING_DETAILS_PROPS_BY_STATUS = {
  [TRACKING_STATUS.delivered]: {
    background: green[500],
    imgUrl: icons.deliveredDetails
  },
  [TRACKING_STATUS.deliveryFailure]: {
    background: red[500],
    imgUrl: icons.withProblem,
    icon: 'warning'
  },
  [TRACKING_STATUS.lost]: {
    background: red[500],
    imgUrl: icons.withProblem,
    customIcon: 'warning'
  },
  [TRACKING_STATUS.onRouteToDelivery]: {
    background: blue[500],
    imgUrl: icons.onRouteToDelivery
  },
  [TRACKING_STATUS.pickupScheduled]: {
    background: blue[900],
    imgUrl: icons.scheduledWithPickup
  },
  [TRACKING_STATUS.problemNeedMoreInfo]: {
    background: red[500],
    imgUrl: icons.withProblem,
    customIcon: 'warning'
  },
  [TRACKING_STATUS.pickupAllocated]: {
    background: blue[500],
    imgUrl: icons.onRouteToDelivery
  },
  [TRACKING_STATUS.processing]: {
    background: blue[500],
    imgUrl: icons.withPickupCarriedOut
  },
  [TRACKING_STATUS.returningCompleted]: {
    background: grey[300],
    imgUrl: icons.devolutionCompleted
  },
  [TRACKING_STATUS.returningToShipper]: {
    background: grey[300],
    imgUrl: icons.devolution,
    customIcon: 'boxDeliveryPackageReturnCustomer'
  },
  [TRACKING_STATUS.waitingShipperPickup]: {
    background: green[500],
    imgUrl: icons.waitingShipperPickup
  },
  [TRACKING_STATUS.inTransit]: {
    background: blue[500],
    imgUrl: icons.detailedTrackingInTransit
  },
  [TRACKING_STATUS.canceledByAllocation]: {
    background: grey[300],
    imgUrl: icons.canceledByAllocation
  }
};

export const NOT_RENDER_CANCEL_BUTTON = [
  TRACKING_STATUS.delivered,
  TRACKING_STATUS.onRouteToDelivery,
  TRACKING_STATUS.returningCompleted,
  TRACKING_STATUS.returningToShipper,
  TRACKING_STATUS.canceledByAllocation
];

export const TRACKING_STATUS_TITLE = {
  [TRACKING_STATUS.labelSent]: 'history.status.labelSent.title',
  [TRACKING_STATUS.trackingCodeSent]: 'history.status.trackingCodeSent.title'
};

export const RECIPIENT_CONTACT_BEYOND_EMAIL = 'contatobeyond@loggi.com';

export const PACKAGE_SLO = {
  INDISPATCH: 2
};

export const TRACKING_CARD_WITH_SUCCESS_STATUS = [
  TRACKING_STATUS.delivered,
  TRACKING_STATUS.waitingShipperPickup
];

export default {
  TRACKING_CARD_WITH_PROBLEM_STATUS,
  TRACKING_CODE_URL_PARAM,
  TRACKING_ICONS_BY_STATUS,
  TRACKING_STATUS,
  VARIANTS_BY_STATUS,
  TRACKING_DETAILS_PROPS_BY_STATUS,
  NOT_RENDER_CANCEL_BUTTON,
  RECIPIENT_CONTACT_BEYOND_EMAIL,
  PACKAGE_SLO,
  TRACKING_CARD_WITH_SUCCESS_STATUS
};
