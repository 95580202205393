import { camelCase, fromPairs } from 'lodash';

import { Coupon } from './coupon.model';

const camelCaseObjectKeys = object =>
  fromPairs(
    Object.entries(object).map(([key, value]) => [camelCase(key), value])
  );

export class CouponsResponse {
  constructor({ coupons }) {
    this.coupons = coupons;
  }

  static parseApiResponse(response) {
    if (!response) return null;

    const coupons = response.map(
      coupon => new Coupon(camelCaseObjectKeys(coupon))
    );

    return new CouponsResponse({ coupons });
  }
}

export default CouponsResponse;
