import { Skeleton, styled } from '@mui/material';
import { root } from 'theme/colors';

const Base = styled(Skeleton)({
  borderRadius: '100px',
  '&::after': { borderRadius: '0 100px 100px 0' }
});

export const Dark = styled(Base)({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: root[900],
  '&::after': {
    background: `linear-gradient(90deg, transparent, ${root[900]})`
  }
});

export const Light = styled(Base)({
  backgroundColor: 'rgba(0, 0, 0, 0.125)',
  color: 'rgba(0, 0, 0, 0.025)',
  '&::after': {
    background:
      'linear-gradient(270deg, rgba(0, 0, 0, 0.125) 0%, rgba(0, 0, 0, 0.025) 100%);'
  }
});
