import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { root } from 'theme/colors';
import TrackingErrorMessage from 'UI/components/tracking-error-message/tracking-error-message.component';
import TitleBar from 'UI/pages/tracking/title-bar/title-bar.component';

const TrackingError = ({ onTryAgain }) => {
  const { t } = useTranslation('ui');

  return (
    <Box bgcolor={root[0]}>
      <TitleBar title={t('trackingPage.error.title')} />
      <Box
        bgcolor={root[0]}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box bgcolor={root[0]} display="flex" p={3}>
          <TrackingErrorMessage onTryAgain={onTryAgain} />
        </Box>
      </Box>
    </Box>
  );
};

TrackingError.propTypes = {
  onTryAgain: PropTypes.func.isRequired
};

export default TrackingError;
