import React from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Icon } from '@loggi/loggi-icons';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';
import { colors, spacing } from '@loggi/front-design-tokens';

import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { currencyFormatter } from 'utils';

import CloseHeader from '../close-header/close-header';
import Footer from '../footer/footer.component';
import { PIX, CREDIT_CARD, RECHARGE_STATUS } from '../../payment/constants';

export default function AddBalanceErrorPage() {
  const { t } = useTranslation('ui');

  function dateFormatter(date) {
    return format(date, 'dd MMM. HH:mm', { locale: pt }).toLocaleUpperCase();
  }

  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();

  const { addedBalance, addBalanceError, paymentDate, paymentMethod } = context;

  const errorMessages = {
    [RECHARGE_STATUS.ERROR]: {
      title: t('wallet.addBalanceError.poolingRechargeStatus.title'),
      description: t('wallet.addBalanceError.poolingRechargeStatus.description')
    },
    [PIX]: {
      title: t('wallet.addBalanceError.pix.title'),
      description: t('wallet.addBalanceError.pix.description')
    },
    [CREDIT_CARD]: {
      title: t('wallet.addBalanceError.creditCard.title'),
      description: t('wallet.addBalanceError.creditCard.description')
    }
  };

  const { title, description } =
    errorMessages[addBalanceError] || errorMessages[paymentMethod];

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <CloseHeader onClick={() => shipmentDispatcher.back()} />
        <Stack p={spacing.stack.xxsmall} gap={spacing.stack.xxsmall}>
          <Icon
            name="CircleError"
            size="large"
            color={colors.feedback.negative}
          />
          <Typography
            variant="headingMedium"
            color={colors.neutrals['on-surface']}
          >
            {title}
          </Typography>
          <Typography
            variant="bodyTextLarge"
            color={colors.neutrals['on-surface']}
          >
            {description}
          </Typography>
        </Stack>
        <Footer>
          <Footer.Details>
            {addedBalance && (
              <Footer.DetailsItem
                title={t('wallet.footer.amount')}
                value={currencyFormatter(addedBalance)}
              />
            )}
            {paymentDate && (
              <Footer.DetailsItem
                title={t('wallet.footer.canceled')}
                value={dateFormatter(paymentDate)}
              />
            )}
            <Footer.DetailsItem
              title={t('wallet.footer.paymentMethod.title')}
              value={
                paymentMethod === PIX
                  ? t('wallet.footer.paymentMethod.pix')
                  : t('wallet.footer.paymentMethod.creditCard')
              }
            />
          </Footer.Details>
          <Footer.PrimaryAction
            title={
              paymentMethod === PIX
                ? t('wallet.footer.payAgainButton')
                : t('wallet.footer.tryAgainButton')
            }
            onClick={() => shipmentDispatcher.back()}
          />
        </Footer>
      </Stack>
    </ThemeProvider>
  );
}
