import { Icon } from '@loggi/loggi-icons';
import { spacing, colors } from '@loggi/front-design-tokens';
import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const RequestError = () => {
  const { t } = useTranslation('ui');

  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <Stack alignItems="center" textAlign="center">
      <Stack mb={spacing.stack.xxxsmall}>
        <Icon
          size="large"
          name="RefreshForward"
          color={colors.neutrals['on-surface-variant']}
        />
      </Stack>
      <Typography mb={spacing.stack.xxxsmall} variant="subtitleMedium">
        {t('myShipmentsV2.requestError.title')}
      </Typography>
      <Typography
        color={colors.neutrals['on-surface-variant']}
        variant="subtitleSmall"
        mb={spacing.stack.xxxsmall}
      >
        {t('myShipmentsV2.requestError.subtitle')}
      </Typography>
      <Button onClick={refreshPage} variant="contained">
        {t('myShipmentsV2.requestError.button')}
      </Button>
    </Stack>
  );
};

export default RequestError;
