import {
  DROPOFF_GROUPING_SETTINGS,
  GROUPING_OPTIONS,
  GROUPING_SETTINGS,
  INDISPATCH_GROUPING_SETTINGS
} from '../constants/shipments.constants';

export const getGroupingSettingsForPackageType = (
  groupingType,
  isIndispatch,
  isDropoff
) => {
  if (isIndispatch) return INDISPATCH_GROUPING_SETTINGS[groupingType];
  if (isDropoff) return DROPOFF_GROUPING_SETTINGS[groupingType];

  return GROUPING_SETTINGS[groupingType];
};

export const getNumberOfShipmentsByGroupingOptions = (shipments = []) => {
  const options = {};

  shipments.forEach(shipment => {
    Object.keys(GROUPING_OPTIONS).forEach(key => {
      const groupingOption = GROUPING_OPTIONS[key];
      const filters = getGroupingSettingsForPackageType(
        groupingOption,
        shipment.isIndispatch(),
        shipment.isDropoff()
      );

      if (
        filters &&
        filters.includes(shipment.status.occasionalShipperStatusCode)
      ) {
        let increment;
        if (options[groupingOption]) {
          increment = options[groupingOption] + 1;
        } else {
          increment = 1;
        }

        options[groupingOption] = increment;
      }
    });
  });

  return options;
};
