import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

export default function PrimaryAction({ onClick }) {
  const { t } = useTranslation('ui');

  return (
    <Button variant="contained" onClick={onClick}>
      {t('checkoutFooter.primaryAction')}
    </Button>
  );
}

PrimaryAction.propTypes = {
  onClick: PropTypes.func.isRequired
};
