import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { companyIdGuard } from 'routes/routes';
import { Button } from '../button/button.component';

export const SuccessPage = ({
  goToHomeButtonLabel,
  illustration,
  subtitles,
  title
}) => {
  return (
    <Box display="flex" flexDirection="column" pt={3} width="100%">
      <Typography variant="subtitle1">{title}</Typography>
      <Box mt={4}>
        {subtitles.map(subtitle => (
          <Typography key={subtitle} variant="body1">
            {subtitle}
          </Typography>
        ))}
      </Box>
      <Box alignSelf="center" my={7}>
        {illustration}
      </Box>
      <Button fullWidth label={goToHomeButtonLabel} to={companyIdGuard} />
    </Box>
  );
};

SuccessPage.propTypes = {
  goToHomeButtonLabel: PropTypes.string.isRequired,
  illustration: PropTypes.node.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
};

export default { SuccessPage };
