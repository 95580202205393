import { useQuery } from '@tanstack/react-query';
import getPostalCodeFromAddress from 'domain/use-cases/get-postal-code-from-address/get-postal-code-from-address.use-case';
import { InputAddress } from '../models';

/**
 * @param {string|number} companyId
 * @param {import('models').Address | InputAddress} address
 */
export default function usePostalCode(companyId, address) {
  const isInputAddress = address instanceof InputAddress;
  return useQuery(
    [
      'postal-code',
      companyId,
      isInputAddress ? address.correios.cep : address.placeId
    ],
    async () => {
      if (isInputAddress) {
        return address.correios.cep.replace('-', '');
      }

      return getPostalCodeFromAddress(companyId, address).then(data =>
        data.postal_code.replace('-', '')
      );
    },
    {
      enabled: !!companyId && !!address,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
}
