import {
  getFormattedDate,
  getFormattedDateForMobile
} from 'utils/datetime-helper/datetime.helper';
import { LIMIT_DAYS_FOR_POSTING } from '../constants/shipments.constants';

export const formatTimestampToFriendlyDate = timestamp => {
  const dateObj = new Date(timestamp);
  const formattedDate = getFormattedDate(dateObj);

  return formattedDate;
};

export const calculatePostingLimitDate = timestamp => {
  const dateObj = new Date(timestamp);
  dateObj.setDate(dateObj.getDate() + LIMIT_DAYS_FOR_POSTING);

  return getFormattedDateForMobile(dateObj);
};

export const calculatePostingLimitForDetails = timestamp => {
  const dateObj = new Date(timestamp);
  dateObj.setDate(dateObj.getDate() + LIMIT_DAYS_FOR_POSTING);

  return getFormattedDate(dateObj);
};

export const formatTimestampToFullDate = timestamp => {
  const date = new Date(timestamp);

  const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  });

  return dateFormatter.format(date);
};

export const formatTimestampToFriendlyDateForMobile = date => {
  const formattedDate = getFormattedDateForMobile(date);

  return formattedDate;
};

export const groupShipmentsByDate = shipments => {
  const groupedData = {};

  shipments.forEach(item => {
    const date = new Date(item.packageCreatedTime);
    date.setHours(0, 0, 0, 0);

    if (!groupedData[date]) {
      groupedData[date] = [];
    }

    groupedData[date].push(item);
  });

  return groupedData;
};
