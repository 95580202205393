import { Box, Typography } from '@mui/material';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { root } from 'theme/colors';
import Footer from 'UI/shipment/components/shipment-footer';
import { PackageSizeOptionSm } from './package-size-select/options/package-size-option-sm.component';
import { PackageSizeSelect } from './package-size-select/package-size-select.component';
import { scrollContainer } from './package-size-select/package-size-select.helper';

export default function PackageSizePage() {
  const { t } = useTranslation('ui');
  const shipmentContext = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const [selected, setSelected] = React.useState(
    shipmentContext.context.packageSize
  );

  const handleSubmit = () => {
    shipmentDispatcher.continue(selected);
  };

  const handleChange = packageSize => {
    setSelected(packageSize);
  };

  useEffect(() => {
    if (selected) {
      window.scrollTo({ behavior: 'smooth', top: document.body.clientHeight });
    }
  }, [selected]);

  return (
    <>
      <Box color={root[900]} mb={3}>
        <Typography variant="subtitle1">
          {t('packageSizePage.label')}
        </Typography>
      </Box>
      <Box mb={4} mx={-3}>
        <PackageSizeSelect
          value={selected}
          OptionComponent={PackageSizeOptionSm}
          onChange={handleChange}
          scrollIntoView={scrollContainer}
        />
      </Box>
      <Footer>
        <Footer.EstimatePrice />
        <Footer.PrimaryAction onClick={handleSubmit} disabled={!selected} />
      </Footer>
    </>
  );
}
