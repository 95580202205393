import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'UI/shipments-v2/utils/helpers.utils';

export const ReturningAddressInfo = ({
  receiverAddress,
  receiverName,
  receiverPhone
}) => {
  const { t } = useTranslation('ui');

  const formattedPhone = receiverPhone
    ? formatPhoneNumber(receiverPhone)
    : t('shipmentDetailsV2.returningAddressInfo.noPhone');
  return (
    <Box>
      <Stack
        p={spacing.inset.large}
        gap={spacing.stack.xxxsmall}
        sx={{ whiteSpace: 'normal' }}
      >
        <Typography variant="subtitleLarge">
          {t('shipmentDetailsV2.returningAddressInfo.address')}
        </Typography>
        <Typography variant="bodyTextMedium">{receiverAddress}</Typography>
      </Stack>
      <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.returningAddressInfo.name')}
        </Typography>
        <Typography variant="bodyTextMedium">{receiverName}</Typography>
      </Stack>
      <Stack p={spacing.inset.large} gap={spacing.stack.nano}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.returningAddressInfo.phone')}
        </Typography>
        <Typography variant="bodyTextMedium">{formattedPhone}</Typography>
      </Stack>
    </Box>
  );
};

ReturningAddressInfo.propTypes = {
  receiverAddress: PropTypes.string.isRequired,
  receiverName: PropTypes.string.isRequired,
  receiverPhone: PropTypes.string.isRequired
};

export default ReturningAddressInfo;
