import { Typography } from '@mui/material';
import { trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { BaseInputField } from './base-input';

/**
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.unit - label displayed at the end of the input field. Ex: Kg, Cm, $
 * @param {number} props.value
 * @param {number|undefined} props.precisionDigits - defaults to zero
 * @param {number|undefined} props.maxDigits - defaults to 3
 * @param {(value: number) => void} props.onChange
 * @param {error|undefined} props.error - error message
 * @param {boolean|undefined} props.nonZero - forbids zero to be an accepted value from user. When true, zero will be turn into `undefined`
 * @returns {React.ReactNode}
 */
function NumericInput({
  id,
  label,
  unit,
  value,
  precisionDigits,
  maxDigits,
  onChange,
  error,
  nonZero
}) {
  const initialValue = value || (nonZero ? undefined : 0);
  const maxLength = maxDigits + (precisionDigits ? precisionDigits + 1 : 0);

  /** @param {number} v */
  const formatValue = v => v?.toFixed(precisionDigits).replace('.', ',') || '';

  const formatUserInput = v => {
    let onlyDigits = trimStart(v.replace(/\D/g, ''), '0');
    if (onlyDigits.length === 0) {
      if (nonZero) return undefined;
      onlyDigits = '0';
    }
    const result = parseFloat(onlyDigits) / 10 ** precisionDigits;
    // avoid floating point precision weirdness
    return parseFloat(result.toFixed(precisionDigits));
  };

  const unitLabel = (
    <Typography variant="body1" fontWeight="medium">
      {unit}
    </Typography>
  );

  return (
    <BaseInputField
      id={id}
      label={label}
      endAdornment={unitLabel}
      autohideAdornment
      value={initialValue}
      formatValue={formatValue}
      unformatValue={formatUserInput}
      onChange={onChange}
      error={error}
      maxLength={maxLength}
      inputMode="numeric"
    />
  );
}

export default NumericInput;

NumericInput.defaultProps = {
  precisionDigits: 0,
  maxDigits: 3,
  value: undefined,
  nonZero: false,
  error: undefined
};

NumericInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  value: PropTypes.number,
  precisionDigits: PropTypes.number,
  maxDigits: PropTypes.number,
  nonZero: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};
