/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
import { ROUTES } from 'routes';
import { STATES } from './constants';

/**
 * Creates a function to handle the navigation based on state values.
 *
 * @param {object} history
 * @param {function} history.push
 * @returns {function} onStateChange
 */
export default function createShipmentMachineNavigationProcessor({ history }) {
  return function onStateChange(state) {
    const companyId = state.context.companyId;
    const trackingKey = state.context.shipmentOrder?.trackingKey;

    switch (state.state) {
      case STATES.BUDGET:
        return history.push(ROUTES.budgetV2.replace(':companyId', companyId));
      case STATES.HOME:
        return history.push(ROUTES.newHome.replace(':companyId', companyId));
      case STATES.WALLET:
        return history.push(ROUTES.wallet.replace(':companyId', companyId));
      case STATES.PICKUP_ADDRESS:
        return history.push(
          ROUTES.shipmentPickupAddressV2.replace(':companyId', companyId)
        );
      case STATES.DELIVERY_ADDRESS:
        return history.push(
          ROUTES.shipmentDeliveryAddressV2.replace(':companyId', companyId)
        );
      case STATES.POSTAL_CODE_ADDRESSES:
        return history.push(
          ROUTES.shipmentPostalCodeAddresses.replace(':companyId', companyId)
        );
      case STATES.ORIGIN_ADDRESS:
        return history.push(
          ROUTES.shipmentOriginAddress.replace(':companyId', companyId)
        );
      case STATES.DESTINATION_ADDRESS:
        return history.push(
          ROUTES.shipmentDestinationAddress.replace(':companyId', companyId)
        );
      case STATES.REQUIRE_PACKAGING:
        return history.push(
          ROUTES.shipmentRequirePackagingV2.replace(':companyId', companyId)
        );
      case STATES.NEW_PACKAGE_SIZE:
        return history.push(
          ROUTES.newShipmentPackageSizeV2.replace(':companyId', companyId)
        );
      case STATES.PACKAGE_DIMENSIONS:
        return history.push(
          ROUTES.shipmentPackageDimensionsV2.replace(':companyId', companyId)
        );
      case STATES.ABOUT_PACKAGING:
        return history.push(
          ROUTES.shipmentPackagingInformationsV2.replace(
            ':companyId',
            companyId
          )
        );
      case STATES.DROPOFF_ONBOARDING:
        return history.push(
          ROUTES.shipmentDropoffOnboarding.replace(':companyId', companyId)
        );
      case STATES.PICKUP_SCHEDULE:
        return history.push(
          ROUTES.shipmentPickupScheduleV2.replace(':companyId', companyId)
        );
      case STATES.PACKAGE_SIZE:
        return history.push(
          ROUTES.shipmentPackageSizeV2.replace(':companyId', companyId)
        );
      case STATES.INSURANCE:
        return history.push(
          ROUTES.shipmentInsuranceValueV2.replace(':companyId', companyId)
        );
      case STATES.LIST_AGENCIES:
        return history.push(
          ROUTES.shipmentListAgenciesV2.replace(':companyId', companyId)
        );
      case STATES.PICKUP_CUSTOMER:
        return history.push(
          ROUTES.shipmentPickupCustomerV2.replace(':companyId', companyId)
        );
      case STATES.DELIVERY_CUSTOMER:
        return history.push(
          ROUTES.shipmentDeliveryCustomerV2.replace(':companyId', companyId)
        );
      case STATES.COUPONS:
        return history.push(
          ROUTES.shipmentSummaryCouponsV2.replace(':companyId', companyId)
        );
      case STATES.ONBOARD_INDESPACHO:
        return history.push(
          ROUTES.shipmentIndispatchOnboardingV2.replace(':companyId', companyId)
        );
      case STATES.SUMMARY:
        return history.push(
          ROUTES.shipmentSummaryV2.replace(':companyId', companyId)
        );
      case STATES.SUMMARY_INDESPACHO:
        return history.push(
          ROUTES.shipmentIndispatchSummaryV2.replace(':companyId', companyId)
        );
      case STATES.SHIPMENT_CREATED:
        return history.push({
          pathname: ROUTES.shipmentSummarySuccessV2.replace(
            ':companyId',
            companyId
          ),
          search: `?tracking_code=${trackingKey}`
        });
      case STATES.SHIPMENT_CREATED_V2:
        return history.push(
          ROUTES.shipmentCreatedV2.replace(':companyId', companyId)
        );
      case STATES.SHIPMENT_CREATED_INDESPACHO:
        return history.push(
          ROUTES.shipmentIndispatchSuccessV2.replace(':companyId', companyId)
        );
      case STATES.SERVICES_INDESPACHO:
        return history.push(
          ROUTES.shipmentIndispatchServicesV2.replace(':companyId', companyId)
        );
      case STATES.PIX_PAYMENT:
        return history.push(
          ROUTES.shipmentPaymentPix.replace(':companyId', companyId)
        );
      case STATES.PAYMENT:
        return history.push(
          ROUTES.shipmentPayment.replace(':companyId', companyId)
        );
      case STATES.PAYMENT_COUPONS:
        return history.push(
          ROUTES.shipmentPaymentCoupons.replace(':companyId', companyId)
        );
      case STATES.ADD_WALLET_BALANCE_OPTIONS:
        return history.push(
          ROUTES.shipmentAddWalletBalanceOptions.replace(
            ':companyId',
            companyId
          )
        );
      case STATES.PRE_DEFINED_RECHARGE_OPTIONS:
        return history.push(
          ROUTES.shipmentPreDefinedRechargeOptions.replace(
            ':companyId',
            companyId
          )
        );
      case STATES.INPUT_RECHARGE_VALUE:
        return history.push(
          ROUTES.shipmentInputRechargeValue.replace(':companyId', companyId)
        );
      case STATES.WALLET_BALANCE_SUCCESSFULLY_ADDED:
        return history.push(
          ROUTES.shipmentAddWalletBalanceSuccess.replace(
            ':companyId',
            companyId
          )
        );
      case STATES.ADD_WALLET_BALANCE_ERROR:
        return history.push(
          ROUTES.shipmentAddWalletBalanceError.replace(':companyId', companyId)
        );
      case STATES.IDLE:
      default:
        break;
    }
  };
}
