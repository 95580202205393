/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import CRMBaseAdapter from 'crm/adapters/crm-base-adapter';
import { SINGULAR_EVENTS, SINGULAR_REVENUE_EVENTS } from 'crm/constants';
import {
  logSingularAnalyticsEvent,
  logSingularRevenueEvent,
  setSingularUserId
} from 'mobile';

export default class CRMAdapterNativeSingular extends CRMBaseAdapter {
  get events() {
    return SINGULAR_EVENTS;
  }

  get revenueEvents() {
    return SINGULAR_REVENUE_EVENTS;
  }

  async sendUser(userPayload) {
    const identifier = userPayload?.email;
    if (identifier) await setSingularUserId(identifier);
  }

  /**
   * @param {string} eventName - Name of the event to be sent.
   * @param {Object<string, string>} eventData - Data associated with the event.
   */
  async sendEvent(eventName, eventData) {
    return logSingularAnalyticsEvent({
      event: eventName,
      att: eventData
    });
  }

  /**
   * @param {string} eventName - Name of the event to be sent.
   * @param {number} amount - Revenue amount associated with the event.
   * @param {Object<string, string|number>} eventData - Data associated with the event.
   */
  async sendRevenueEvent(eventName, amount, eventData) {
    return logSingularRevenueEvent({
      event: eventName,
      currency: 'BRL',
      amount,
      att: eventData
    });
  }
}
