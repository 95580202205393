import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'UI/components';
import { useTranslation } from 'react-i18next';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { isUsingInputAddress } from 'UI/shipment/state-machine/utils';
import { useSupport } from 'hooks';

/**
 * @param {object} props
 * @param {boolean} props.isVisible
 * @param {function} props.onClose
 */
export default function AttemptCreateOrderLimitDrawer({ isVisible, onClose }) {
  const { t } = useTranslation('ui');
  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const support = useSupport({ companyId: context?.companyId });

  const isInputAddress = isUsingInputAddress(context);

  let description = t(
    'payment.AttemptCreateOrderLimitDrawer.descriptionByGoogleAddress'
  );
  let confirmButtonLabel = t(
    'payment.AttemptCreateOrderLimitDrawer.createShipmentAgainLabel'
  );
  let dimissButtonLabel = t('payment.errorFooter.buttons.tryAgain');

  if (isInputAddress) {
    description = t(
      'payment.AttemptCreateOrderLimitDrawer.descriptionByCepAddress'
    );
    confirmButtonLabel = t(
      'payment.AttemptCreateOrderLimitDrawer.accessHelpCenterLabel'
    );
    dimissButtonLabel = t(
      'payment.AttemptCreateOrderLimitDrawer.createShipmentAgainLabel'
    );
  }

  const handleDismissButton = () => {
    if (!isInputAddress) {
      onClose();
    } else {
      shipmentDispatcher.clear();
    }
  };

  const handleConfirmButton = () => {
    if (!isInputAddress) {
      shipmentDispatcher.clickPickupAddress();
    } else {
      window.open(support.supportPage, '_blank', 'noopener');
    }
  };

  return (
    <BaseDrawer
      isOpen={isVisible}
      title={t('payment.AttemptCreateOrderLimitDrawer.title')}
      description={description}
      labels={{
        confirm: confirmButtonLabel,
        dismiss: dimissButtonLabel
      }}
      onConfirmClick={handleConfirmButton}
      onDismissClick={handleDismissButton}
      vertical
      alignTitleLeft
      alignDescriptionLeft
    />
  );
}

AttemptCreateOrderLimitDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
