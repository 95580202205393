import React from 'react';
import PropTypes from 'prop-types';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ShipperPickupInfo = ({ shipperPickupAddress }) => {
  const { t } = useTranslation('ui');
  return (
    <Box>
      <Stack
        p={spacing.inset.large}
        borderBottom={`1px solid ${colors.neutrals['outline-low']}`}
        gap={spacing.stack.xxxsmall}
        sx={{ whiteSpace: 'normal' }}
      >
        <Typography variant="subtitleLarge">
          {t('shipmentDetailsV2.shipperPickup.address')}
        </Typography>
        <Typography variant="bodyTextMedium">{shipperPickupAddress}</Typography>
      </Stack>
      <Stack
        p={spacing.inset.large}
        borderBottom={`1px solid ${colors.neutrals['outline-low']}`}
        gap={spacing.stack.nano}
      >
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.shipperPickup.timeInformation')}
        </Typography>
        <Typography variant="bodyTextMedium">
          {t('shipmentDetailsV2.shipperPickup.weekdaysTime')}
        </Typography>
        <Typography variant="bodyTextMedium">
          {t('shipmentDetailsV2.shipperPickup.weekendTime')}
        </Typography>
        <Typography variant="bodyTextMedium">
          {t('shipmentDetailsV2.shipperPickup.sundaysAndHolydaysTime')}
        </Typography>
      </Stack>
    </Box>
  );
};

ShipperPickupInfo.propTypes = {
  shipperPickupAddress: PropTypes.string.isRequired
};

export default ShipperPickupInfo;
