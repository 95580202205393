import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'UI/components';
import { blue, root, grey } from 'theme/colors';
import { VOLUME_QUALIFICATION_OPTION } from 'crm/constants';
import AccountVolumeQualificationInformed from 'crm/entities/events/account-volume-qualification-informed/account-volume-qualification-informed';
import { useUserRecords } from 'UI/contexts';
import { useFeatureSwitch } from 'hooks/feature-switch';

const EstimatedMonthlyPackagesNaturalPerson = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const perMonthlyPackagesRange = useFeatureSwitch(
    'enable_per_monthly_packages_range_natural_person',
    []
  );
  const { userRecords, setUserRecords } = useUserRecords();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(userRecords.estimatedMonthlyPackagesNaturalPerson);
  }, [userRecords.estimatedMonthlyPackagesNaturalPerson]);

  const handleSubmit = () => {
    setUserRecords({
      ...userRecords,
      estimatedMonthlyPackagesNaturalPerson: selectedOption
    });
    new AccountVolumeQualificationInformed({
      volumeQualification: selectedOption
    }).sendToCrm();
    handleOnNextStep();
  };

  const { firstRange, secondRange, thirdRange } = perMonthlyPackagesRange.data;

  const fromToLabel = (initialRange, finalRange) =>
    t('signUpCompany.estimatedMonthlyPackagesNaturalPerson.options.fromTo', {
      initialRange,
      finalRange
    });

  const options = [
    {
      id: 'first-option',
      label: t(
        'signUpCompany.estimatedMonthlyPackagesNaturalPerson.options.fewTimes'
      ),
      value: VOLUME_QUALIFICATION_OPTION.ALGUNS_PCTS_POR_ANO
    },
    {
      id: 'second-option',
      label: fromToLabel(firstRange?.[0], firstRange?.[1]),
      value: VOLUME_QUALIFICATION_OPTION.ENTRE_1_A_4_PCTS_POR_MES
    },
    {
      id: 'third-option',
      label: fromToLabel(secondRange?.[0], secondRange?.[1]),
      value: VOLUME_QUALIFICATION_OPTION.ENTRE_5_A_30_PCTS_POR_MES
    },
    {
      id: 'fourth-option',
      label: fromToLabel(thirdRange?.[0], thirdRange?.[1]),
      value: VOLUME_QUALIFICATION_OPTION.ENTRE_31_A_100_PCTS_POR_MES
    },
    {
      id: 'fifth-option',
      label: t(
        'signUpCompany.estimatedMonthlyPackagesNaturalPerson.options.moreThan',
        {
          finalRange: thirdRange?.[1]
        }
      ),
      value: VOLUME_QUALIFICATION_OPTION.MAIS_100_PCTS_POR_MES
    }
  ];

  return (
    <Box p={3}>
      <Typography fontWeight="500" variant="subtitle1">
        {t('signUpCompany.estimatedMonthlyPackagesNaturalPerson.title')}
      </Typography>
      <Box display="flex" py={2}>
        <Box flex={1}>
          {options.map(option => (
            <Box mt={1} key={option.id}>
              <Button
                key={option.id}
                bgColor={root[0]}
                borderColor={
                  selectedOption === option.value ? blue[500] : grey[50]
                }
                borderWidth={2}
                fullWidth
                label={option.label}
                value={selectedOption}
                fontWeight={500}
                textColor={root[900]}
                variant="outlined"
                onClick={() => setSelectedOption(option.value)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        style={{ position: 'absolute', bottom: '0', left: '0', width: '100%' }}
        p={3}
      >
        <Button
          fullWidth
          disabled={!selectedOption}
          label={t('signUpCompany.submitButton.label')}
          onClick={() => handleSubmit()}
        />
      </Box>
    </Box>
  );
};

EstimatedMonthlyPackagesNaturalPerson.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default EstimatedMonthlyPackagesNaturalPerson;
