import {
  Box,
  Button,
  Container,
  Typography,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIcon } from '../../assets/alert-icon.svg';

const ImpersonationBanner = ({ name, signOut }) => {
  const { spacing } = useTheme();
  const { t } = useTranslation('impersonationBanner');

  return (
    <Box
      bgcolor="warning.main"
      display="flex"
      height={spacing(7)}
      left={0}
      position="sticky"
      top={0}
      width="100%"
      zIndex="appBar"
    >
      <Container component={Box} style={{ display: 'flex' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <AlertIcon data-testid="alert-icon" />
            <Box pl={{ xs: 2, md: 3 }} clone>
              <Typography variant="caption">
                <span>{t('header.message')}</span> <strong>{name}</strong>.
              </Typography>
            </Box>
          </Box>
          {signOut && (
            <Box>
              <Button variant="outlinedPrimary" onClick={signOut} size="small">
                {t('logoutButton.message')}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

ImpersonationBanner.propTypes = {
  name: PropTypes.string.isRequired,
  signOut: PropTypes.func
};

ImpersonationBanner.defaultProps = {
  signOut: null
};

export default ImpersonationBanner;
