import { getFsValueUseCase } from '../get-fs-value/get-fs-value.use-case';

export const isSingleSignupRedirectEnabledUseCase = async () => {
  const value = await getFsValueUseCase(
    'enable_beyond_redirect_to_single_signup'
  );
  return typeof value === 'boolean' ? value : false;
};

export default { isSingleSignupRedirectEnabledUseCase };
