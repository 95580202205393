import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { blue, grey, root } from 'theme/colors';
import { ReactComponent as Illustration } from './illustration.svg';
import { useCouponOffer } from './coupon-offer';

export const CouponOfferBanner = () => {
  const { t } = useTranslation('ui');
  const couponOffer = useCouponOffer();

  if (!couponOffer.isEnabled) return null;

  return (
    <Box
      width="100%"
      mt="32px"
      height="160px"
      borderRadius="16px"
      border={`1px solid ${grey[100]}`}
      bgcolor="white"
      role="button"
      position="relative"
      onClick={couponOffer.open}
      data-testid="coupon-offer-banner"
    >
      <Box position="absolute" top="16px" left="16px" maxWidth="204px">
        <Typography variant="subtitle2" color={root[900]} fontWeight="500">
          {t('couponOfferBannerHome.message')}
        </Typography>
      </Box>
      <Illustration
        style={{ position: 'absolute', bottom: '0px', right: '0px', zindex: 0 }}
      />
      <Box position="absolute" bottom="16px" left="16px">
        <Button
          type="button"
          variant="contained"
          style={{
            background: blue[500],
            borderRadius: '8px',
            padding: '8px 16px'
          }}
          onClick={couponOffer.open}
          data-testid="coupon-offer-button"
        >
          <Typography variant="caption" color={root[0]} fontWeight="700">
            {t('couponOfferBannerHome.button')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default { CouponOfferBanner };
