import { Box } from '@mui/material';
import {
  TRACKING_ICONS_BY_STATUS,
  TRACKING_STATUS
} from 'constants/tracking.constants';
import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from 'UI/components';

const HistoryIcon = ({ status }) => {
  const iconSpec = TRACKING_ICONS_BY_STATUS[status];
  if (!iconSpec) return null;

  const { name, color, background } = iconSpec;

  return (
    <Box
      height="40px"
      width="40px"
      display="flex"
      justifyContent="center"
      bgcolor={background}
      borderRadius="50%"
      alignItems="center"
      data-testid={`status__${status}`}
    >
      <SvgIcon color={color} icon={name} />
    </Box>
  );
};

HistoryIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(TRACKING_STATUS)).isRequired
};

export default HistoryIcon;
