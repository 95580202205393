import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { borders, colors, spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from 'utils';

export default function OptionItem({
  value,
  isSelected,
  cashback,
  onClick,
  otherValueOption
}) {
  const { t } = useTranslation('ui');

  return (
    <Stack
      data-testid="pre-defined-recharge-option-item"
      p={spacing.stack.xxsmall}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      border={
        isSelected
          ? `${borders.width.thick} solid ${colors.interaction.primary}`
          : `${borders.width.thin} solid ${colors.neutrals.outline}`
      }
      borderRadius={borders.radius.large}
      onClick={onClick}
    >
      <Stack gap={spacing.stack.nano}>
        {otherValueOption && (
          <Typography
            variant="subtitleLarge"
            color={colors.neutrals['on-surface']}
          >
            {t('wallet.preDefinedRechargeOptions.options.otherValue')}
          </Typography>
        )}
        {value && (
          <Typography
            variant="subtitleLarge"
            color={colors.neutrals['on-surface']}
          >
            {currencyFormatter(value)}
          </Typography>
        )}
        {cashback && (
          <Typography variant="subtitleMedium" color={colors.feedback.positive}>
            {t('wallet.preDefinedRechargeOptions.options.cashback', {
              cashback: currencyFormatter(cashback)
            })}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

OptionItem.defaultProps = {
  value: null,
  cashback: null,
  otherValueOption: false
};

OptionItem.propTypes = {
  value: PropTypes.number,
  cashback: PropTypes.number,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  otherValueOption: PropTypes.bool
};
