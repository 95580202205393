import icons from 'constants/icons-urls.json';
import PropTypes from 'prop-types';
import React from 'react';
import { root } from 'theme/colors';

export const SvgIcon = ({ icon, color, size }) => {
  const mask = `url(${icons[icon]}) no-repeat center / contain`;
  return (
    <div
      role="img"
      style={{
        backgroundColor: color,
        flexShrink: 0,
        mask,
        WebkitMask: mask,
        height: `${size}px`,
        width: `${size}px`
      }}
    />
  );
};

SvgIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  color: PropTypes.string,
  size: PropTypes.string
};

SvgIcon.defaultProps = {
  color: root[900],
  size: '24'
};

export default { SvgIcon };
