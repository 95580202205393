import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { buildShipmentSentEventsPayload } from 'crm/utils';

export default class ShipmentCreationSent extends CRMBaseEvent {
  constructor({
    packaging,
    serviceType,
    addressBy,
    originFullAddress,
    destinationFullAddress,
    originCity,
    destinationCity,
    recipientName,
    price,
    coupon,
    goodsvalue,
    dimensions,
    paymentType,
    reference
  }) {
    super();
    this.eventName = EVENTS.SHIPMENT_CREATION_SENT;
    this.payload = {
      shipment_type: serviceType,
      packaging,
      address_by: addressBy,
      origin_full_address: originFullAddress,
      destination_full_address: destinationFullAddress,
      origin_city: originCity,
      destination_city: destinationCity,
      recipient_name: recipientName,
      price: price ? Number(price) : undefined,
      coupon,
      goodsvalue: goodsvalue ? Number(goodsvalue) : undefined,
      dimensions,
      payment_type: paymentType,
      reference
    };
  }

  /**
   * @param {{ context: import('UI/shipment/state-machine/types').MachineContext }}
   */
  static fromShipmentContext({ context, price, coupon, utms, rechargeId }) {
    return new ShipmentCreationSent(
      buildShipmentSentEventsPayload(context, price, coupon, utms, rechargeId)
    );
  }
}
