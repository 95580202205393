import { Box, Typography } from '@mui/material';
import Footer from 'UI/shipment/components/shipment-footer';
import {
  NOT_INFORMED,
  NO_PACKAGING,
  SIMPLE_PACKAGING
} from 'UI/shipment/constants/packaging-service-type';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WithPackagingOption, WithoutPackagingOption } from './options';

export default function PackagingRequiringPage() {
  const { t } = useTranslation('ui');
  const shipmentDispatcher = useShipmentDispatcher();
  const { context } = useShipmentContext();
  const [packagingService, setPackagingService] = useState(
    context.packagingService
  );

  const handleChangeOption = packagingOption => {
    shipmentDispatcher.selectPackagingService(packagingOption);
    setPackagingService(packagingOption);
  };

  return (
    <>
      <Box>
        <Typography variant="subtitle1">
          {t('packagingRequiringPage.titleLabel')}
        </Typography>
        <Box display="flex" flexDirection="column" gap={2} mt={3}>
          <WithPackagingOption
            isSelected={packagingService === SIMPLE_PACKAGING}
            onClick={() => handleChangeOption(SIMPLE_PACKAGING)}
          />
          <WithoutPackagingOption
            isSelected={packagingService === NO_PACKAGING}
            onClick={() => handleChangeOption(NO_PACKAGING)}
          />
        </Box>
      </Box>

      <Footer>
        <Footer.SecondaryAction onClick={shipmentDispatcher.viewAboutPackaging}>
          {t('packagingRequiringPage.viewInformations')}
        </Footer.SecondaryAction>
        <Footer.Border />
        <Footer.EstimatePrice />
        <Footer.PrimaryAction
          onClick={shipmentDispatcher.continue}
          disabled={packagingService === NOT_INFORMED}
        />
      </Footer>
    </>
  );
}
