import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import {
  loggiDesignSystem as theme,
  MenuShortcut,
  MenuShortcutTrailItem
} from '@loggi/front-design-system';

import useWalletHelpLink from 'hooks/feature-switch/wallet-help-link/wallet-help-link.hook';
import { useFeatureSwitches } from 'hooks/feature-switches/feature-switches';

import { colors, spacing } from '@loggi/front-design-tokens';
import { Icon } from '@loggi/loggi-icons';

import useWalletBalance from 'hooks/wallet-balance/wallet-balance.hook';
import CheckoutHeader from 'UI/shipment/components/checkout-header/header.component';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';

import WalletBalanceInfo from './wallet-balance-info';
import WalletStatements from './wallet-statements';

export default function WalletPage() {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();
  const shipmentDispatcher = useShipmentDispatcher();
  const shipmentContext = useShipmentContext();
  const [showBalance, setShowBalance] = useState(true);

  const balanceQuery = useWalletBalance({ companyId });
  const helpLink = useWalletHelpLink();

  useFeatureSwitches({
    onSuccess(featureSwitches) {
      if (shipmentContext.isIdle) {
        shipmentDispatcher.init({
          companyId,
          featureSwitches,
          fromWallet: true
        });
      }
    }
  });

  return (
    <Stack>
      <CheckoutHeader goBack={() => shipmentDispatcher.back()} />
      <ThemeProvider theme={theme}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={spacing.stack.xxsmall}
          py={spacing.stack.xsmall}
        >
          <Typography
            variant="headingMedium"
            color={colors.neutrals['on-surface']}
          >
            {t('walletPage.title')}
          </Typography>
          <Stack
            data-testid="eye-icon"
            onClick={() => setShowBalance(!showBalance)}
          >
            <Icon
              name={showBalance ? 'Eye' : 'EyeOff'}
              size="medium"
              color={colors.neutrals['on-surface']}
            />
          </Stack>
        </Stack>

        <WalletBalanceInfo
          balance={balanceQuery.data?.balance}
          showBalance={showBalance}
          isLoading={balanceQuery.isLoading}
        />

        <Stack data-testid="shortcut-menu">
          <MenuShortcut>
            <MenuShortcutTrailItem
              label={t('walletPage.shortcutItem.addBalance')}
              iconName="ActionPlus"
              onClick={() => shipmentDispatcher.clickAddWalletBalance()}
            />
            <Stack>
              <MenuShortcutTrailItem
                label={t('walletPage.shortcutItem.help')}
                iconName="CircleQuestion"
                onClick={() => {
                  window.open(helpLink, '_blank');
                }}
              />
            </Stack>
          </MenuShortcut>
        </Stack>
        <WalletStatements />
      </ThemeProvider>
    </Stack>
  );
}
