/* eslint-disable camelcase */
import React from 'react';
import { Box, Typography } from '@mui/material';
import Footer from 'UI/shipment/components/shipment-footer';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { Trans, useTranslation } from 'react-i18next';
import usePostalCode from 'UI/shipment/hooks/postal-code';
import ServiceLocationAgencies from 'UI/shipment/components/agencies/list';
import { maxDropoffDate } from 'UI/shipment/pages/agencies/agencies.helper';
import LoadingListView from 'UI/shipment/components/agencies/loading-view';

const today = new Date();
export default function AgenciesPage() {
  const { t } = useTranslation('ui');
  const shipmentDispatcher = useShipmentDispatcher();
  const { context } = useShipmentContext();
  const postalCodeQuery = usePostalCode(
    context.companyId,
    context.pickupAddress || context.originAddress
  );
  const AgenciesLocationList = () =>
    postalCodeQuery.isLoading ? (
      <LoadingListView />
    ) : (
      <ServiceLocationAgencies postalCode={postalCodeQuery.data} />
    );

  return (
    <>
      <Typography variant="subtitle1">{t('agenciesPage.title')}</Typography>
      <Box py="24px">
        <Typography variant="body2">
          <Trans
            t={t}
            i18nKey="agenciesPage.subtitle"
            values={{ date: maxDropoffDate(today) }}
          />
        </Typography>
      </Box>

      <AgenciesLocationList />

      <Footer>
        <Footer.PrimaryAction onClick={shipmentDispatcher.continue} />
      </Footer>
    </>
  );
}
