import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentAddressByCepDestinationInfosInputed extends CRMBaseEvent {
  constructor({ cep, state, city, address, number, district, complement }) {
    super();
    this.eventName = EVENTS.SHIPMENT_ADDRESS_BY_CEP_DESTINATION_INFOS_INPUTED;
    this.payload = {
      cep,
      state,
      city,
      address,
      number,
      district,
      complement
    };
  }
}
