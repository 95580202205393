import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Box, CircularProgress } from '@mui/material';
import { Button } from 'UI/components';
import { TextInput, PostalCodeInput } from 'UI/components/input';
import { CorreiosAddress } from 'UI/shipment/models';
import { useTranslation } from 'react-i18next';
import useCorreiosAddressForm from './hook';
import {
  CITY_MAX_LENGTH,
  COMPLEMENT_MAX_LENGTH,
  MISSING_CORREIOS_ADDRESS_REQUIRED_FIELD,
  NEIGHBORHOOD_MAX_LENGTH,
  NUMBER_MAX_LENGTH,
  STATE_MAX_LENGTH,
  STREET_MAX_LENGTH
} from './constants';
import {
  MissingComplementWarning,
  MissingNumberAndComplementWarning,
  MissingNumberWarning
} from './warnings';

/**
 * This component defines the Correios address form. It includes the submission
 * and validation logic due to business needs. But it can easily be moved to
 * outside of this component if needed because all the form logic relies on
 * the `useCorreiosAddressForm` hook.
 *
 * @param {object} props
 * @param {CorreiosAddress} props.initialAddress
 * @param {(address: CorreiosAddress) => void} props.onSubmit callback to when must attempt to submit the form
 * @param {({ address: CorreiosAddress, errorMessage: string }) => void} props.onSubmitClickEvent callback to when user clicks submit button
 * @param {boolean} props.isEnableNewEndpoint - true if the new CEP endpoint is enabled, false otherwise
 */
export default function CorreiosAddressForm({
  initialAddress,
  onSubmit,
  onSubmitClickEvent
}) {
  const { t } = useTranslation('ui');
  const form = useCorreiosAddressForm(initialAddress, {
    onSubmit
  });

  const getOnSubmitErrorMessage = () => {
    let errorMessage;

    if (!form.isValid) {
      errorMessage = MISSING_CORREIOS_ADDRESS_REQUIRED_FIELD;
    } else if (form.isMissingComplement && form.isMissingNumber) {
      errorMessage = t(
        'correiosAddressWarnings.missingNumberAndComplement.title'
      );
    } else if (form.isMissingNumber) {
      errorMessage = t('correiosAddressWarnings.missingNumber.title');
    } else if (form.isMissingComplement) {
      errorMessage = t('correiosAddressWarnings.missingComplement.title');
    }

    return errorMessage;
  };

  return (
    <Box display="flex" flexDirection="column">
      <PostalCodeInput
        id="postal-code"
        label={t('correiosAddressForm.labels.cep')}
        value={form.address.cep}
        endAdornment={
          form.isLoading && !form.isError ? (
            <CircularProgress size={20} />
          ) : null
        }
        disabled
      />
      <Box height="32px" />
      <TextInput
        id="state"
        maxLength={STATE_MAX_LENGTH}
        label={t('correiosAddressForm.labels.state')}
        value={form.address.uf || ''}
        error={form.errors.state}
        onChange={state => form.onChange('uf', state)}
        disabled={
          form.isSuccess && form.isResolved('uf') && !form.isSliced('uf')
        }
      />
      <Box height="32px" />
      <TextInput
        id="city"
        maxLength={CITY_MAX_LENGTH}
        label={t('correiosAddressForm.labels.city')}
        value={form.address.cidade || ''}
        error={form.errors.city}
        onFocus={() => form.touch('cidade')}
        onChange={city => form.onChange('cidade', city)}
        helperText={
          form.isSliced('cidade') && !form.isTouched('cidade')
            ? t('correiosAddressForm.helpers.maximumLength')
            : undefined
        }
        disabled={
          form.isSuccess &&
          form.isResolved('cidade') &&
          !form.isSliced('cidade')
        }
      />
      <Box height="32px" />
      <TextInput
        id="address"
        maxLength={STREET_MAX_LENGTH}
        label={t('correiosAddressForm.labels.street')}
        value={form.address.logradouro || ''}
        error={form.errors.street}
        onFocus={() => form.touch('logradouro')}
        onChange={street => form.onChange('logradouro', street)}
        helperText={
          form.isSliced('logradouro') && !form.isTouched('logradouro')
            ? t('correiosAddressForm.helpers.maximumLength')
            : undefined
        }
        disabled={
          form.isSuccess &&
          form.isResolved('logradouro') &&
          !form.isSliced('logradouro')
        }
      />
      <Box height="32px" />
      <TextInput
        id="number"
        maxLength={NUMBER_MAX_LENGTH}
        label={t('correiosAddressForm.labels.number')}
        value={form.address.numero || ''}
        onChange={number => form.onChange('numero', number)}
      />
      <Box height="32px" />
      <TextInput
        id="neighborhood"
        maxLength={NEIGHBORHOOD_MAX_LENGTH}
        label={t('correiosAddressForm.labels.neighborhood')}
        value={form.address.bairro || ''}
        error={form.errors.neighborhood}
        onFocus={() => form.touch('bairro')}
        onChange={neighborhood => form.onChange('bairro', neighborhood)}
        helperText={
          form.isSliced('bairro') && !form.isTouched('bairro')
            ? t('correiosAddressForm.helpers.maximumLength')
            : undefined
        }
        disabled={
          form.isSuccess &&
          form.isResolved('bairro') &&
          !form.isSliced('bairro')
        }
      />
      <Box height="32px" />
      <TextInput
        id="complement"
        maxLength={COMPLEMENT_MAX_LENGTH}
        label={t('correiosAddressForm.labels.complement')}
        value={form.address.complemento || ''}
        onChange={complement => form.onChange('complemento', complement)}
      />
      <Box height="32px" />
      <Button
        id="submit-button"
        label={t('correiosAddressForm.labels.submit')}
        onClick={() => {
          onSubmitClickEvent({
            address: form.address,
            errorMessage: getOnSubmitErrorMessage()
          });
          form.onSubmit();
        }}
        disableShadow
        fullWidth
      />
      <MissingNumberWarning
        isVisible={
          form.isValid &&
          form.isMissingNumber &&
          !form.isMissingComplement &&
          form.submitted
        }
        onConfirm={form.resetWarnings}
        onClose={() => form.onSubmit({ dismissNumber: true })}
      />
      <MissingComplementWarning
        isVisible={
          form.isValid &&
          !form.isMissingNumber &&
          form.isMissingComplement &&
          form.submitted
        }
        onConfirm={form.resetWarnings}
        onClose={() => form.onSubmit({ dismissComplement: true })}
      />
      <MissingNumberAndComplementWarning
        isVisible={
          form.isValid &&
          form.isMissingNumber &&
          form.isMissingComplement &&
          form.submitted
        }
        onConfirm={form.resetWarnings}
        onClose={() =>
          form.onSubmit({ dismissComplement: true, dismissNumber: true })
        }
      />
    </Box>
  );
}

CorreiosAddressForm.defaultProps = {
  initialAddress: new CorreiosAddress(),
  onSubmitClickEvent: noop
};

CorreiosAddressForm.propTypes = {
  initialAddress: PropTypes.instanceOf(CorreiosAddress),
  onSubmit: PropTypes.func.isRequired,
  onSubmitClickEvent: PropTypes.func
};
