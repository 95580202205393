/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@mui/styles';
import { grey, root } from 'theme/colors';

export const CustomStyles = makeStyles(() => ({
  customPagination: {
    '&': {
      height: '60vh'
    },
    '& .swiper-pagination-bullet': {
      backgroundColor: grey[100],
      opacity: 0.5,
      borderRadius: '50%',
      margin: '0 5px',
      height: '8px',
      width: '8px',
      transition: 'background-color 0.3s, opacity 0.3s'
    },
    '& .swiper-pagination-bullet-active': {
      backgroundColor: root[900],
      opacity: 1,
      width: '24px',
      borderRadius: '100px'
    }
  }
}));
