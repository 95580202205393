import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import {
  formatPhoneNumber,
  getCreationMethod,
  getFirstName,
  getLastName
} from 'crm/utils';

export default class AccountDocumentInformed extends CRMBaseEvent {
  constructor({
    name,
    userType,
    document,
    sharedName,
    email,
    phone,
    provider
  }) {
    super();
    this.eventName = EVENTS.ACCOUNT_DOCUMENT_INFORMED;
    this.payload = {
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      phone: formatPhoneNumber(phone),
      registration_method: getCreationMethod(provider),
      user_type: userType,
      document,
      shared_name: sharedName
    };
  }
}
