const formatCnpjMask = cnpjNumber => {
  return cnpjNumber
    .replace(/(\d{14})\d/, '$1')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

export default formatCnpjMask;
