import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { grey } from 'theme/colors';
import { BaseDrawer, SvgIcon } from 'UI/components';
import { useSnackbar } from 'hooks/snackbar/use-snackbar.hook';
import CopyToClipboard from 'UI/components/copy-to-clipboard/copy-to-clipboard.component';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { useCouponOffer } from './coupon-offer';

export const CouponOfferDrawer = () => {
  const { t } = useTranslation('ui');
  const snackbar = useSnackbar();
  const couponOffer = useCouponOffer();

  const { title, description, message, coupon } = useFeatureSwitch(
    'coupon_drawer_info',
    { coupon: '' }
  ).data;

  const showMessage = () => {
    snackbar({
      buttonLabel: t('couponOfferBannerHome.drawer.snackbar.button'),
      message: t('couponOfferBannerHome.drawer.snackbar.message')
    });
  };

  return (
    <BaseDrawer
      isOpen={couponOffer.isOpen}
      labels={{ dismiss: t('couponOfferBannerHome.drawer.button') }}
      onDismissClick={couponOffer.close}
      onSwipeClose={couponOffer.close}
      variant="temporary"
      hasPuller
    >
      <Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={grey[50]}
          width="32px"
          height="32px"
          borderRadius="8px"
        >
          <SvgIcon icon="coupon" />
        </Box>
        <Box height="8px" />
        <Typography variant="subtitle1">{title}</Typography>
        <Box height="16px" />
        <Typography variant="body1">
          <Trans>{description}</Trans>
        </Typography>
        <Box height="32px" />
        <CopyToClipboard text={coupon} onCopy={showMessage} />
        <Box height="16px" />
        <Box color={grey[200]} fontWeight="700">
          <Typography variant="caption">{message}</Typography>
        </Box>
      </Box>
    </BaseDrawer>
  );
};

export default { CouponOfferDrawer };
