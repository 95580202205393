import { Box } from '@mui/material';
import { ValidatorBuilder } from 'infra';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepFormWrapper } from 'UI/components';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { useUserRecords } from 'UI/contexts';
import AccountCreationStarted from 'crm/entities/events/account-creation-started/account-creation-started';
import AccessStarted from 'crm/entities/events/access-started/access-started';
import { getSignUpOrigin } from 'crm/utils';
import useQueryParams from 'hooks/query-params/query-params.hook';
import { ProgressBar } from '../../progress-bar/progress-bar.component';

export const ACCESS_EMAIL_SIGNUP = 'email_signup';

const UserCredentialsForm = ({ onSubmit }) => {
  const [activeStep, setActiveStep] = useState(1);
  const { t } = useTranslation('ui');
  const { redirect } = useQueryParams();
  const isMobile = checkIsMobile();
  const { userRecords, setUserRecords } = useUserRecords();
  const fields = {
    name: {
      label: t('signUpUser.input.name.label'),
      type: 'text',
      value: userRecords.name,
      validator: new ValidatorBuilder()
        .nonEmptyString(t('signUpUser.input.name.errorMessage'))
        .build()
    },
    email: {
      label: t('signUpUser.input.email.label'),
      type: 'email',
      value: userRecords.email,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .emailPattern(t('signUpUser.input.email.errorMessage'))
        .build()
    },
    password: {
      label: t('signUpUser.input.password.label'),
      type: 'password',
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .passwordLength(t('signUpUser.input.password.errorMessage'))
        .build()
    }
  };

  const maxSteps = Object.keys(fields).length;

  const onStepChange = step => {
    setActiveStep(step + 1);
  };

  const _sendEventsToCrm = (name, email) => {
    new AccessStarted({ originAccess: ACCESS_EMAIL_SIGNUP }).sendToCrm();
    new AccountCreationStarted({
      name,
      email,
      origin: getSignUpOrigin(redirect),
      provider: ACCESS_EMAIL_SIGNUP
    }).sendToCrm();
  };

  const handleSubmit = userCredentials => {
    const { name, email } = userCredentials;
    _sendEventsToCrm(name, email);
    setUserRecords({
      ...userRecords,
      name,
      email
    });
    return onSubmit(userCredentials);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <StepFormWrapper
        fields={fields}
        onSubmit={handleSubmit}
        onStepChange={onStepChange}
        buttonLabel={t('signUpUser.submitButton.label')}
      />
      {isMobile && (
        <Box position="fixed" bottom="0" left="0" width="100%">
          <ProgressBar activeStep={activeStep} maxSteps={maxSteps} />
        </Box>
      )}
    </Box>
  );
};

UserCredentialsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default UserCredentialsForm;
