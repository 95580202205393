import { CREATE_SHIPMENT_STEPS } from 'constants/index';
import { convertBeyondToCorpAddress } from 'mobile/utils';

export const getCorpShipmentDataUseCase = async beyondShipmentData => {
  const {
    [CREATE_SHIPMENT_STEPS.PICKUP]: { address: pickupAddress },
    [CREATE_SHIPMENT_STEPS.DELIVERY]: deliveryAddress,
    [CREATE_SHIPMENT_STEPS.PICKUP_CUSTOMER]: pickupCustomer,
    [CREATE_SHIPMENT_STEPS.DELIVERY_CUSTOMER]: deliveryCustomer
  } = beyondShipmentData;

  const pickup = {
    address: await convertBeyondToCorpAddress(pickupAddress),
    phone: beyondShipmentData[CREATE_SHIPMENT_STEPS.PICKUP_CUSTOMER]?.phone,
    instructionText: `Retirar com ${pickupCustomer.name}`
  };
  const delivery = {
    address: await convertBeyondToCorpAddress(deliveryAddress),
    phone: beyondShipmentData[CREATE_SHIPMENT_STEPS.DELIVERY_CUSTOMER]?.phone,
    instructionText: `Entregar para ${deliveryCustomer.name}`
  };

  return { pickup, delivery };
};

export default { getCorpShipmentDataUseCase };
