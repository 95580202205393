/* eslint-disable react/jsx-props-no-spreading */
import { Button, Alert } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const Snackbar = React.forwardRef(
  ({ buttonLabel, message, onClose, severity, ...other }, ref) => {
    const renderButton = buttonLabel ? (
      <Button color="inherit" onClick={onClose} size="small">
        {buttonLabel}
      </Button>
    ) : null;

    return (
      <Alert
        action={renderButton}
        elevation={6}
        icon={false}
        onClose={onClose}
        severity={severity}
        ref={ref}
        variant="filled"
        {...other}
      >
        {message}
      </Alert>
    );
  }
);

Snackbar.defaultProps = {
  buttonLabel: '',
  severity: 'info'
};

Snackbar.propTypes = {
  buttonLabel: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
  severity: PropTypes.oneOf(['error', 'info', 'success'])
};

export default { Snackbar };
