/* eslint-disable import/prefer-default-export */

// regex values based on https://github.com/shalanah/inapp-spy
const inAppRegExps = [
  'WebView',
  '(iPhone|iPod|iPad)(?!.*Safari/)', // Apple devices but not with "Safari/" following
  'Android.*wv\\)',
  'FB_\\w|FB\\w', // Match Facebook FB_ or FB then word char
  'Snapchat'
];

const inappRegex = new RegExp(
  `${inAppRegExps.map(reg => `(${reg})`).join('|')}`,
  'ig'
);

/** @param {string} userAgent */
export function isInApp(userAgent) {
  return userAgent.match(inappRegex) !== null;
}
