import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack, Typography } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';

import { Address } from 'models';

export default function AddressInfo({ title, address, subtitle }) {
  return (
    <>
      <Stack
        direction="row"
        gap={spacing.inline.xsmall}
        p={spacing.inset.large}
        justifyContent="space-between"
      >
        <Stack gap={spacing.stack.xxxsmall}>
          <Typography
            variant="subtitleLarge"
            color={colors.neutrals['on-surface']}
          >
            {title}
          </Typography>
          <Typography
            variant="bodyTextMedium"
            color={colors.neutrals['on-surface']}
            data-testid="address-infos"
          >
            {address.structured.main}
            <br />
            {address.structured.secondary.replace(/,/g, ' • ')}
            <br />
            {address.complement && `${address.complement}`}
          </Typography>
          {subtitle && (
            <Typography
              variant="bodyTextMedium"
              color={colors.neutrals['on-surface']}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Divider color={colors.neutrals['outline-low']} />
    </>
  );
}

AddressInfo.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.instanceOf(Address).isRequired,
  subtitle: PropTypes.string
};

AddressInfo.defaultProps = {
  subtitle: ''
};
