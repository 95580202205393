import { FORMS_KEYS } from 'constants/index';
import { merge } from 'lodash';

const removeNonDigits = (string = '') => string.replace(/\D/g, '');

const SHIPPING_PAYLOAD_STATIC_VALUES = {
  decon: {
    packageDescription: 'Item Pessoal',
    packageQuantity: '1'
  },
  invoice: { estimate: { freightType: 0 } },
  pickup: { instructions: '' }
};

export class ShippingPayload {
  constructor({
    companyId,
    couponCode,
    decon,
    estimate = {
      price: 0,
      serviceType: 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC'
    },
    invoice,
    paymentDocumentId,
    paymentMethod,
    pickup,
    quotation,
    recipient,
    pickupOrderScheduleId = null,
    allObjectsArePackaged = false,
    packagingService = undefined
  }) {
    if (pickupOrderScheduleId) {
      this.pickup_order_schedule_id = pickupOrderScheduleId;
    }
    this.packages = [
      {
        package: {
          freight_type_service: estimate.serviceType,
          height_cm: decon.packageSizeDimensions.heightCm,
          length_cm: decon.packageSizeDimensions.lengthCm,
          weight_g: decon.packageSizeDimensions.weight,
          width_cm: decon.packageSizeDimensions.widthCm,
          total_value: decon.totalValue,
          slo: invoice.estimate.freightType,
          packaging_service_type: packagingService,
          content_declaration: {
            items: [
              {
                description: decon.packageDescription,
                quantity: decon.packageQuantity,
                unitary_value: decon.totalValue,
                unitary_weight_g: decon.packageSizeDimensions.weightG
              }
            ]
          }
        },
        recipient: {
          address: {
            address_complement: recipient.address.complement,
            full_address: recipient.address.description,
            place_id: recipient.address.placeId
          },
          cpf_cnpj: removeNonDigits(recipient.cnpjCpf),
          name: recipient.name,
          phone_number: recipient.phone
        }
      }
    ];
    this.pickup_order_schedule = {
      all_objects_are_packaged: allObjectsArePackaged,
      start_time: pickup.schedulingTime?.startTime || '',
      end_time: pickup.schedulingTime?.endTime || '',
      origin_address: {
        address_complement: pickup.shipper.address.complement,
        full_address: pickup.shipper.address.description,
        place_id: pickup.shipper.address.placeId
      },
      shipper_instructions: pickup.instructions,
      shipper_name: pickup.shipper.name,
      shipper_phone: pickup.shipper.phone,
      shipper_email: pickup.shipper.email
    };
    this.indispatch_price = estimate.price.toFixed(2);
    if (paymentDocumentId) {
      this.payment_data = {
        payment_method: { payment_document_id: paymentDocumentId }
      };
    } else if (paymentMethod) {
      this.payment_data = {
        payment_method: {
          card_holder_name: paymentMethod.cardHolderName,
          encrypted_card_number: paymentMethod.cardNumber,
          encrypted_expiry_month: paymentMethod.expiryMonth,
          encrypted_expiry_year: paymentMethod.expiryYear,
          encrypted_security_code: paymentMethod.securityCode,
          type: paymentMethod.type
        }
      };
    }
    if (quotation && this.payment_data) {
      this.payment_data.amount = parseFloat(quotation.price).toFixed(2);
    }
    if (quotation?.fullPrice !== undefined && couponCode) {
      this.coupon = {
        company_id: companyId,
        coupon_code: couponCode,
        total_amount: parseFloat(quotation.fullPrice).toFixed(2),
        amount_with_applied_discount: parseFloat(quotation.price).toFixed(2)
      };
    }
  }

  static build({
    [FORMS_KEYS.deconForm]: decon,
    [FORMS_KEYS.pickupForm]: pickup,
    [FORMS_KEYS.recipientForm]: recipient,
    ...rest
  }) {
    return new ShippingPayload(
      merge(
        { ...rest, decon, pickup, recipient },
        SHIPPING_PAYLOAD_STATIC_VALUES
      )
    );
  }
}

export default ShippingPayload;
