/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { BaseInputField } from './base-input';

const Loading = () => (
  <CircularProgress size={20} data-testid="input-loading-adornment" />
);

export function TextInput(props) {
  return (
    <BaseInputField
      id={props.id}
      label={props.label}
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      disabled={props.disabled}
      maxLength={props.maxLength}
      onFocus={props.onFocus}
      onChange={props.onChange}
      endAdornment={props.isLoading ? <Loading /> : null}
      type="text"
    />
  );
}

TextInput.defaultProps = {
  value: '',
  error: undefined,
  helperText: undefined,
  maxLength: undefined,
  onFocus: undefined,
  isLoading: false,
  disabled: false,
  onChange: () => {}
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};
