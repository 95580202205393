import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';
import { borders, colors, spacing } from '@loggi/front-design-tokens';

export const OptionCard = ({ children, isSelected, onClick }) => {
  return (
    <Stack
      data-testid="option-card"
      p={spacing.stack.xxsmall}
      border={
        isSelected
          ? `${borders.width.thick} solid ${colors.interaction.primary}`
          : `${borders.width.thin} solid ${colors.neutrals.outline}`
      }
      borderRadius={borders.radius.large}
      onClick={onClick}
    >
      {children}
    </Stack>
  );
};

OptionCard.defaultProps = { isSelected: false };

OptionCard.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default { OptionCard };
