import { generateTagUseCase } from 'domain/use-cases';
import { useState, useCallback, useEffect } from 'react';

export const useGenerateTag = (loggiKey, companyId) => {
  const [state, setState] = useState({
    data: {},
    isError: false,
    isLoading: true
  });

  const effectFn = useCallback(async () => {
    try {
      const {
        label,
        content_declaration: contentDeclaration
      } = await generateTagUseCase(loggiKey, companyId);

      setState({
        data: {
          label,
          contentDeclaration
        },
        isError: false,
        isLoading: false
      });
    } catch (err) {
      setState({
        isLoading: false,
        isError: true,
        data: {}
      });
    }
  }, [companyId, loggiKey]);

  useEffect(() => {
    if (companyId && loggiKey) {
      effectFn();
    }
  }, [companyId, loggiKey, effectFn]);

  return { ...state };
};

export default useGenerateTag;
