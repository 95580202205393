/* eslint-disable camelcase */
import { loggiWebApi } from '@loggi/authentication-lib';
import { isString } from 'lodash';
import { InputAddress } from 'UI/shipment/models';
import getPostalCodeFromAddress from '../get-postal-code-from-address/get-postal-code-from-address.use-case';

const URL = process.env.REACT_APP_PY_BEYOND_URL;

/**
 * Get schedule coverage
 *
 * @param {string} companyId
 * @param {import('models').Address | import('UI/shipment/models').InputAddress} address
 * @returns {Promise<{ hasError: boolean, payload: any, message: string }>}
 */
export default async function getPickupSchedule(companyId, address) {
  const isInputAddress = address instanceof InputAddress;
  const postalCodeResponse = isInputAddress
    ? address.correios.cep
    : await getPostalCodeFromAddress(companyId, address);

  const response = await loggiWebApi
    .url(`${URL}/coverage/v1/national-economic/`, true)
    .query({
      origin_postal_code: isInputAddress
        ? address.correios.cep
        : postalCodeResponse?.postal_code?.replace(/\D/g, '')
    })
    .get()
    .json();

  if (isString(response)) {
    return { hasError: true, payload: null, message: response };
  }
  return { hasError: false, payload: response, message: '' };
}
