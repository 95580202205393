import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class HomeCorpButtonClicked extends CRMBaseEvent {
  constructor() {
    super();
    this.eventName = EVENTS.HOME_CORP_BUTTON_CLICKED;
    this.payload = {};
  }
}
