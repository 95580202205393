import { AmateurQuotingResponse } from 'models';
import { getQuoting } from 'service/amateurs-pricing-api';

/**
 * Get the estimation from the Beyond microservice
 *
 * @param {object} options
 * @param {string|number} options.companyId
 * @param {import('models/company.model').Company | undefined} options.company
 * @param {import('models').Address} options.pickupAddress
 * @param {import('models').Address} options.deliveryAddress
 * @param {import('UI/shipment/models').InputAddress} options.originAddress
 * @param {import('UI/shipment/models').InputAddress} options.destinationAddress
 * @param {import('UI/shipment/state-machine/types.d').PackageDimensions} options.packageDimensions
 * @param {import('UI/shipment/state-machine/types.d').PackagingService} options.packagingService
 * @param {Array<import('UI/shipment/state-machine/types.d').ServiceType>} options.quotingServiceFilter
 * @param {number} options.declaredValue
 * @returns {Promise<AmateurQuotingResponse>}
 */
export default async function getEstimate({
  companyId,
  pickupAddress,
  deliveryAddress,
  originAddress,
  destinationAddress,
  packageDimensions,
  packagingService,
  quotingServiceFilter,
  declaredValue
}) {
  const payload = {
    ship_from: {
      place_id: originAddress ? undefined : pickupAddress.placeId,
      address: originAddress
        ? originAddress.correios.cep
        : pickupAddress.getDescription()
    },
    ship_to: {
      place_id: destinationAddress ? undefined : deliveryAddress.placeId,
      address: destinationAddress
        ? destinationAddress.correios.cep
        : deliveryAddress.getDescription()
    },
    package_dimensions: {
      weight_g: packageDimensions.weightG,
      height_cm: packageDimensions.heightCm,
      length_cm: packageDimensions.lengthCm,
      width_cm: packageDimensions.widthCm
    },
    packaging_service_type: packagingService,
    quote_service_types: quotingServiceFilter,
    declared_value: declaredValue
  };

  return getQuoting({ companyId, payload }).then(
    ({ amateur_price_estimates: amateurPriceEstimates }) =>
      AmateurQuotingResponse.parse(amateurPriceEstimates)
  );
}
