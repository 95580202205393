import {
  getSessionStorageValue,
  setSessionStorageValue,
  removeSessionStorageValue
} from 'utils';

/**
 * Returns a session storage get and set functions
 *
 * @typedef {{
 *   [key: string]: string;
 * }} SessionStorageParams
 *
 * @param {string} key
 * @returns {{getValue: () => SessionStorageParams | null, setValue: (value: any) => void}}
 */

const useSessionStorage = key => {
  return {
    getValue: getSessionStorageValue.bind(null, key),
    setValue: setSessionStorageValue.bind(null, key),
    removeValue: removeSessionStorageValue.bind(null, key)
  };
};

export default useSessionStorage;
