import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Typography, Stack } from '@mui/material';
import { borders, colors, spacing } from '@loggi/front-design-tokens';

import { currencyFormatter } from 'utils';

const WalletStatementItem = ({
  recharge,
  refund,
  amount,
  entryType,
  createdAt,
  payment,
  coupon
}) => {
  const { t } = useTranslation('ui');

  const paymentDescriptions = {
    card: paymentParam =>
      t('walletPage.statements.paymentType.credit', {
        lastDigits: paymentParam.credit_card_last_digits
      }),
    pix: () => t('walletPage.statements.paymentType.pix')
  };

  const entryTypeOperator = {
    credit: '+',
    debit: '-'
  };

  const transactionTypes = {
    withdrawal: 'withdrawal',
    recharge: 'recharge',
    refund: 'refund'
  };

  const getPaymentDescription = () => {
    if (coupon) {
      return t('walletPage.statements.paymentType.coupon');
    }
    if (!payment) {
      return t('walletPage.statements.paymentType.walletBalance');
    }
    return paymentDescriptions[payment.type.toLowerCase()](payment);
  };

  const formattedDate = () => {
    const date = format(new Date(createdAt), "dd 'de' MMM • HH:mm", {
      locale: ptBR
    });

    const month = date.split(' ')[2];
    const monthCapitalized = month[0].toUpperCase() + month.slice(1);

    return date.replace(month, monthCapitalized);
  };

  const getTransactionType = () => {
    if (recharge) {
      return transactionTypes.recharge;
    }
    if (refund) {
      return transactionTypes.refund;
    }
    // default
    return transactionTypes.withdrawal;
  };

  return (
    <Stack
      p={spacing.inset.large}
      gap={spacing.stack.nano}
      borderBottom={`${borders.width.thin} solid ${
        colors.neutrals['outline-low']
      }`}
      data-testid="wallet-statement-item"
    >
      <Typography variant="subtitleMedium">
        {t(`walletPage.statements.transactionType.${getTransactionType()}`)}
      </Typography>
      <Stack>
        <Typography variant="bodyTextMedium">{formattedDate()}</Typography>
        <Typography variant="bodyTextMedium" data-testid="payment-description">
          {getPaymentDescription()}
        </Typography>
      </Stack>
      <Typography variant="subtitleSmall">
        {amount > 0 && entryTypeOperator[entryType]} {currencyFormatter(amount)}
      </Typography>
    </Stack>
  );
};

WalletStatementItem.defaultProps = {
  payment: null,
  recharge: null,
  refund: null,
  coupon: null
};

WalletStatementItem.propTypes = {
  recharge: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }),
  refund: PropTypes.shape(),
  amount: PropTypes.number.isRequired,
  entryType: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  payment: PropTypes.shape({
    type: PropTypes.string.isRequired,
    credit_card_last_digits: PropTypes.string
  }),
  coupon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired
  })
};

export default WalletStatementItem;
