const CREDIT_CARD = 'credit-card';
const PIX = 'pix';
const LOGGI_FREE = 'loggi-free';
const WALLET_BALANCE = 'wallet-balance';
const ON_PAYMENT_COMPLETED_STATUS_CODE = {
  AUTHORISED: 'Authorised'
};
const DEFAULT_POLLING_TIME = 2000;
const RECHARGE_STATUS = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING_GATEWAY',
  ERROR: 'ERROR',
  CANCELED_BY_GATEWAY: 'CANCELED_BY_GATEWAY'
};
const MAX_CREATE_ORDER_ATTEMPTS = 5;

export {
  CREDIT_CARD,
  PIX,
  WALLET_BALANCE,
  LOGGI_FREE,
  ON_PAYMENT_COMPLETED_STATUS_CODE,
  RECHARGE_STATUS,
  DEFAULT_POLLING_TIME,
  MAX_CREATE_ORDER_ATTEMPTS
};
