import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Skeleton } from '@mui/material';
import { useGenerateTag } from 'UI/shipments-v2/hooks/use-generate-tag.hook';

const DownloadSection = ({
  titleKey,
  textKey,
  buttonKey,
  filename,
  content,
  t,
  generatePdf
}) => (
  <Box pb={spacing.inset.large} display="flex" flexDirection="column">
    <Box px={spacing.inset.large} pt={spacing.inset.large}>
      <Typography pb={spacing.stack.nano} variant="subtitleMedium">
        {t(titleKey)}
      </Typography>
      <Typography variant="bodyTextMedium">{t(textKey)}</Typography>
    </Box>
    <Box pl={spacing.inset.small} pt={spacing.stack.nano}>
      <Button
        href={generatePdf(filename, content)}
        target="_blank"
        size="small"
        variant="text"
      >
        {t(buttonKey)}
      </Button>
    </Box>
  </Box>
);

DownloadSection.propTypes = {
  titleKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  buttonKey: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  generatePdf: PropTypes.string.isRequired
};

export const TagDownloadInfo = ({ companyId, loggiKey }) => {
  const { t } = useTranslation('ui');
  const { data, isLoading, isError } = useGenerateTag(loggiKey, companyId);

  const generatePdf = (_filename, content) => {
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    return URL.createObjectURL(blob);
  };

  if (isLoading) {
    return (
      <Box p={spacing.inset.large} display="flex" flexDirection="column">
        <Skeleton height={200} width={200} />
      </Box>
    );
  }

  if (isError) {
    return <></>;
  }

  const { label, contentDeclaration } = data;

  return (
    <>
      <DownloadSection
        titleKey="shipmentDetailsV2.tagDownloadInfo.title"
        textKey="shipmentDetailsV2.tagDownloadInfo.text"
        buttonKey="shipmentDetailsV2.tagDownloadInfo.button"
        filename={label.filename}
        content={label.content}
        t={t}
        generatePdf={generatePdf}
      />
      <DownloadSection
        titleKey="shipmentDetailsV2.contentDeclarationDownloadInfo.title"
        textKey="shipmentDetailsV2.contentDeclarationDownloadInfo.text"
        buttonKey="shipmentDetailsV2.contentDeclarationDownloadInfo.button"
        filename={contentDeclaration.filename}
        content={contentDeclaration.content}
        t={t}
        generatePdf={generatePdf}
      />
    </>
  );
};

TagDownloadInfo.propTypes = {
  companyId: PropTypes.string.isRequired,
  loggiKey: PropTypes.string.isRequired
};

export default TagDownloadInfo;
