import { isValid as isCpfValid } from 'cpf';
import { validate as isCnpjValid } from 'cnpj';
import Validator from '../validator';

export function validateCnpjCpf(cnpjCpf) {
  if (!cnpjCpf) return false;
  return isCnpjValid(cnpjCpf) || isCpfValid(cnpjCpf);
}

export default class CnpjCpfValidator extends Validator {
  static validate(string) {
    return validateCnpjCpf(string);
  }
}
