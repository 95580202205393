import {
  REDIRECT_SIGN_UP_CORP_KEY,
  REDIRECT_SIGN_UP_ENVIOS_KEY
} from 'constants/auth.constants';
import { useFeatureSwitch } from 'hooks/feature-switch';

/**
 * Function to get the feature switch name based on the redirectTo parameter.
 *
 * @param {string} redirectTo - The key to determine which feature switch to check.
 * @returns {string} The name of the feature switch.
 */

const getFSName = redirectTo => {
  if (redirectTo === REDIRECT_SIGN_UP_CORP_KEY)
    return 'enable_single_point_of_access_corp';
  if (redirectTo === REDIRECT_SIGN_UP_ENVIOS_KEY)
    return 'enable_single_point_of_access_envios';
  return '';
};

/**
 * Hook to determine if the single point of access feature is enabled.
 *
 * @param {string} redirectTo - The key to determine which feature switch to check.
 * @returns {boolean} The state of the feature switch.
 *
 * @example
 * const isSinglePointOfAccessEnabled = useIsSinglePointOfAccessEnabled(REDIRECT_SIGN_UP_CORP_KEY);
 */

const useIsSinglePointOfAccessEnabled = redirectTo => {
  const fsName = getFSName(redirectTo);
  const fsData = useFeatureSwitch(fsName, false).data;
  return fsData === 'true' || fsData === true;
};

export default useIsSinglePointOfAccessEnabled;
