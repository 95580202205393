import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS, { ADDRESS_BY } from 'crm/constants';
import { getServiceTypeValue } from 'crm/utils';

export default class ShipmentServiceSelected extends CRMBaseEvent {
  constructor({ addressBy, service }) {
    super();
    this.eventName = EVENTS.SHIPMENT_SERVICE_SELECTED;
    this.payload = {
      address_by: addressBy,
      shipment_type: getServiceTypeValue(service)
    };
  }

  /**
   * @param {{ context: import('UI/shipment/state-machine/types').MachineContext }}
   */
  static fromShipmentContext({ context }) {
    return new ShipmentServiceSelected({
      addressBy: context.pickupAddress ? ADDRESS_BY.GOOGLE : ADDRESS_BY.CEP,
      service: context.serviceType
    });
  }
}
