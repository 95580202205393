import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import usePostalCodeResolver from 'UI/shipment/hooks/postal-code-resolver';
import { CorreiosAddress } from 'UI/shipment/models';
import {
  CITY_MAX_LENGTH,
  COMPLEMENT_MAX_LENGTH,
  NEIGHBORHOOD_MAX_LENGTH,
  NUMBER_MAX_LENGTH,
  STATE_MAX_LENGTH,
  STREET_MAX_LENGTH
} from './constants';

const emptyAddress = new CorreiosAddress();

const defaultOptions = {
  onSubmit: () => {},
  isEnableNewEndpoint: false
};

function formatFormField(
  changed,
  formData,
  initialAddress,
  resolvedAddress,
  field
) {
  if (changed[field]) return formData[field];

  return initialAddress[field] || resolvedAddress?.[field] || '';
}

function buildAddress(form, changed, initialAddress, resolvedAddress) {
  return new CorreiosAddress({
    cep: form.cep,
    complemento: form.complemento.slice(0, COMPLEMENT_MAX_LENGTH),
    numero: form.numero.slice(0, NUMBER_MAX_LENGTH),
    bairro: formatFormField(
      changed,
      form,
      initialAddress,
      resolvedAddress,
      'bairro'
    ).slice(0, NEIGHBORHOOD_MAX_LENGTH),
    cidade: formatFormField(
      changed,
      form,
      initialAddress,
      resolvedAddress,
      'cidade'
    ).slice(0, CITY_MAX_LENGTH),
    logradouro: formatFormField(
      changed,
      form,
      initialAddress,
      resolvedAddress,
      'logradouro'
    ).slice(0, STREET_MAX_LENGTH),
    uf: formatFormField(
      changed,
      form,
      initialAddress,
      resolvedAddress,
      'uf'
    ).slice(0, STATE_MAX_LENGTH)
  });
}

/**
 * This hook represents the domain logic of the CorreiosAddressForm. It's the
 * logical part of the view, decoupled of it.
 *
 * @param {CorreiosAddress | null | undefined} initial - Initial address
 * @param {typeof defaultOptions} opts
 */
export default function useCorreiosAddressForm(
  initial = emptyAddress,
  opts = defaultOptions
) {
  const [form, setForm] = useState(initial.toJSON());
  const [submitState, setSubmitState] = useState({
    submitted: false,
    dismissNumber: false,
    dismissComplement: false
  });
  const [touched, setTouched] = useState({
    bairro: false,
    cidade: false,
    logradouro: false,
    uf: false
  });
  const [changed, setChanged] = useState({
    bairro: false,
    cidade: false,
    logradouro: false,
    uf: false,
    numero: false,
    complemento: false
  });
  const { t } = useTranslation('ui');
  const postalCodeQuery = usePostalCodeResolver(
    form.cep,
    opts.isEnableNewEndpoint
  );

  const onChange = useCallback((field, value) => {
    setForm(prev => ({
      ...prev,
      [field]: value
    }));
    setChanged(prev => ({ ...prev, [field]: true }));
    setSubmitState(prev => ({ ...prev, submitted: false }));
  }, []);

  const correiosAddress = postalCodeQuery.data?.toJSON();
  const address = buildAddress(form, changed, initial, correiosAddress);

  const errors = {
    state:
      (!address.uf &&
        submitState.submitted &&
        t('correiosAddressForm.errors.state.required')) ||
      '',
    city:
      (!address.cidade &&
        submitState.submitted &&
        t('correiosAddressForm.errors.city.required')) ||
      '',
    street:
      (!address.logradouro &&
        submitState.submitted &&
        t('correiosAddressForm.errors.street.required')) ||
      '',
    neighborhood:
      (!address.bairro &&
        submitState.submitted &&
        t('correiosAddressForm.errors.neighborhood.required')) ||
      ''
  };

  /** @param {{dismissNumber: boolean, dismissComplement: boolean}} param0 */
  const onSubmit = ({ dismissNumber, dismissComplement } = {}) => {
    setSubmitState({ submitted: true, dismissNumber, dismissComplement });

    const isMissingNumber = !address.numero && !dismissNumber;
    const isMissingComplement = !address.complemento && !dismissComplement;
    if (address.isValid() && !isMissingNumber && !isMissingComplement)
      opts.onSubmit(address);
  };

  return {
    address,
    isValid:
      !!address.cep &&
      !!address.uf &&
      !!address.cidade &&
      !!address.logradouro &&
      !!address.bairro,
    errors,
    touch: field => setTouched(fields => ({ ...fields, [field]: true })),
    isTouched: field => touched[field] || false,
    isMissingNumber: !address.numero && !submitState.dismissNumber,
    isMissingComplement: !address.complemento && !submitState.dismissComplement,
    isResolved: field => !!correiosAddress?.[field],
    isSliced: field =>
      !!correiosAddress?.[field] &&
      !initial[field] &&
      address[field] !== correiosAddress[field],
    submitted: submitState.submitted,
    onChange,
    onSubmit,
    /**
     * Resets the warnings so that we can show them
     * again if the user tries to submit.
     */
    resetWarnings: () => {
      setSubmitState({
        submitted: false,
        dismissNumber: false,
        dismissComplement: false
      });
    },
    isLoading: postalCodeQuery.isFetching,
    isSuccess: postalCodeQuery.isSuccess,
    isError: postalCodeQuery.isError
  };
}
