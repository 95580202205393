import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentDestinationInputed extends CRMBaseEvent {
  constructor({ shipmentDestination }) {
    super();
    this.eventName = EVENTS.SHIPMENT_DESTINATION_INPUTED;
    this.payload = {
      shipment_destination: shipmentDestination
    };
  }
}
