import { useQuery } from '@tanstack/react-query';
import { useDomainUseCase } from 'UI/contexts';

export const useCurrentUser = () => {
  const getCurrentUser = useDomainUseCase('getCurrentUserUseCase');
  const query = useQuery(['current-user'], getCurrentUser);

  return {
    user: query.data,
    isLoading: query.isLoading,
    isError: query.isError
  };
};

export default { useCurrentUser };
