import { DESKTOP_STYLES } from 'constants/desktop.constants';

export const getGeneralAppStyles = ({ isMobileDevice }) => {
  const maxWidth = isMobileDevice ? '100%' : DESKTOP_STYLES.maxWidth;
  const containerPosition = isMobileDevice
    ? 'inherit'
    : DESKTOP_STYLES.containerPosition;
  const maxListHeight = isMobileDevice ? '100%' : DESKTOP_STYLES.maxListHeight;
  const scrollbarWidth = isMobileDevice
    ? 'auto'
    : DESKTOP_STYLES.scrollbarWidth;
  const containerHeight = isMobileDevice
    ? '100%'
    : DESKTOP_STYLES.containerHeight;

  return {
    maxWidth,
    containerHeight,
    containerPosition,
    maxListHeight,
    scrollbarWidth
  };
};

export default { getGeneralAppStyles };
