import { AFTERNOON_START_HOUR } from 'constants/index';
import { add, endOfDay, format, getTime as getTimestamp } from 'date-fns';
import { pt } from 'date-fns/locale';
import enUS from 'date-fns/locale/en-US';

export function getRelativeDay(
  date,
  todayDate = new Date(),
  options = { dateFormat: 'EEEE', locale: enUS }
) {
  const endOfToday = endOfDay(todayDate);
  const endOfTomorrow = add(endOfToday, { days: 1 });
  if (getTimestamp(date) <= getTimestamp(endOfToday)) return 'Today';
  if (getTimestamp(date) <= getTimestamp(endOfTomorrow)) return 'Tomorrow';
  return format(date, options.dateFormat, { locale: options.locale || enUS });
}

export function getTimeString(date) {
  return new Intl.DateTimeFormat('pt-BR', {
    timeZone: 'America/Sao_Paulo',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
}

export function getTimeWindowInfo(startTimeISO, endTimeISO) {
  const startDatetime = new Date(startTimeISO);
  const endDatetime = new Date(endTimeISO);
  const startTime = getTimeString(startDatetime);
  const endTime = getTimeString(endDatetime);
  const weekDay = getRelativeDay(startDatetime);
  return { startTime, endTime, weekDay };
}

export function getPartOfDayString(date) {
  const hour = date.getHours();
  if (hour < AFTERNOON_START_HOUR) return 'Morning';
  return 'Afternoon';
}

export function getDayAndMonth(date) {
  return format(date, 'dd MMM', { locale: pt }).toLocaleUpperCase();
}

export function getFormattedDate(date) {
  const dateParts = new Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).formatToParts(date);

  return dateParts
    .filter(part => ['day', 'month', 'year'].includes(part.type))
    .map(part => {
      if (part.type === 'month') return part.value.replace('.', ',');
      if (part.type === 'day' && part.value.length === 1)
        return `0${part.value}`;
      return part.value;
    })
    .join(' ');
}

export function getFormattedDateForMobile(date) {
  const dateParts = new Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).formatToParts(date);

  return dateParts
    .filter(part => ['day', 'month'].includes(part.type))
    .map(part => {
      if (part.type === 'month') return part.value.replace('.', '');
      if (part.type === 'day' && part.value.length === 1)
        return `0${part.value}`;
      return part.value;
    })
    .join(' ');
}
