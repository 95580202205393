import { Box } from '@mui/material';
import { PACKAGE_SIZES } from 'constants/index';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { PackageSizeOption } from './options/package-size-option.component';
import { scrollButtonIntoView } from './package-size-select.helper';
import { HiddenScrollbarBox } from './package-size-select.styled';

export const PackageSizeSelect = ({
  onChange,
  OptionComponent,
  scrollIntoView,
  value
}) => {
  const [selected, setSelected] = useState(null);

  const handleSelectOption = useCallback(
    size => {
      setSelected(size);
      onChange(size);
      scrollIntoView(size);
    },
    [onChange, scrollIntoView]
  );

  useEffect(() => {
    if (value && selected === null) handleSelectOption(value);
  }, [handleSelectOption, selected, value]);

  return (
    <Box style={{ overflow: 'hidden' }}>
      <HiddenScrollbarBox
        id="package-sizes-container"
        display="flex"
        px={3}
        style={{
          gridGap: '16px'
        }}
      >
        {Object.keys(PACKAGE_SIZES).map(size => (
          <OptionComponent
            isActive={selected === size}
            key={size}
            onSelect={handleSelectOption}
            size={size}
          />
        ))}
      </HiddenScrollbarBox>
    </Box>
  );
};

PackageSizeSelect.defaultProps = {
  OptionComponent: PackageSizeOption,
  scrollIntoView: scrollButtonIntoView,
  value: ''
};
PackageSizeSelect.propTypes = {
  OptionComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  scrollIntoView: PropTypes.func,
  value: PropTypes.string
};

export default { PackageSizeSelect };
