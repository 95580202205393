import React from 'react';
import { Stack, Typography, Link } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import useWalletTermsOfServiceLink from 'hooks/feature-switch/wallet-terms-of-service-link/wallet-terms-of-service-link.hook';

const TermsOfService = () => {
  const { t } = useTranslation('ui');

  const termsOfServiceLink = useWalletTermsOfServiceLink();

  return (
    <Stack
      data-testid="terms-of-service"
      pt={spacing.stack.xxxsmall}
      textAlign="center"
    >
      <Typography variant="bodyTextMedium">
        {t('payment.termsOfService.text')}
        <br />
        <Link
          target="_blank"
          underline="always"
          color={colors.interaction.primary}
          style={{ textDecorationColor: 'inherit' }}
          href={termsOfServiceLink}
        >
          {t('payment.termsOfService.linkText')}
        </Link>
      </Typography>
    </Stack>
  );
};

export default TermsOfService;
