export const MAX_PAC_INSURANCE_VALUE = 3000;
export const MAX_SEDEX_INSURANCE_VALUE = 10000;
export const MAX_DEFAULT_INSURANCE_VALUE = 1000;
export const MAX_SERVICE_TYPE_INSURANCE_VALUE = {
  AMATEUR_SERVICE_TYPE_CORREIOS_PLP_PAC: MAX_PAC_INSURANCE_VALUE,
  AMATEUR_SERVICE_TYPE_CORREIOS_PLP_SEDEX: MAX_SEDEX_INSURANCE_VALUE,
  AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC: MAX_DEFAULT_INSURANCE_VALUE,
  AMATEUR_SERVICE_TYPE_DROPOFF: MAX_DEFAULT_INSURANCE_VALUE
};

export default { MAX_SERVICE_TYPE_INSURANCE_VALUE };
