import { PACKAGE_SLO, PICKUP_TYPE } from 'constants/tracking.constants';
import { OccasionalTrackingStatus } from './occasional-tracking-status.model';

export class OccasionalTrackingSummaryItem {
  constructor({
    loggiKey,
    trackingCode,
    status,
    receiverName,
    pickupAddress,
    destinationAddress,
    packagePickupTraits,
    senderName,
    packageSlo,
    packageCreatedTime,
    correiosTrackCode
  }) {
    this.loggiKey = loggiKey;
    this.trackingCode = trackingCode;
    this.status = status;
    this.receiverName = receiverName;
    this.pickupAddress = pickupAddress;
    this.destinationAddress = destinationAddress;
    this.packagePickupTraits = packagePickupTraits;
    this.senderName = senderName;
    this.packageSlo = packageSlo;
    this.packageCreatedTime = packageCreatedTime;
    this.correiosTrackCode = correiosTrackCode;

    this.isDropoff = this.isDropoff.bind(this);
    this.isIndispatch = this.isIndispatch.bind(this);
  }

  static parseApiResponse(packageResponse) {
    if (!packageResponse) return null;

    return new OccasionalTrackingSummaryItem({
      loggiKey: packageResponse.loggiKey,
      trackingCode: packageResponse.trackingCode,
      status: OccasionalTrackingStatus.parseApiResponse(packageResponse.status),
      receiverName: packageResponse.receiverName,
      packagePickupTraits: packageResponse.packagePickupTraits,
      pickupAddress: packageResponse.pickupAddress,
      destinationAddress: packageResponse.destinationAddress,
      senderName: packageResponse.senderName,
      packageSlo: packageResponse.packageSlo,
      packageCreatedTime: packageResponse.packageCreatedTime,
      correiosTrackCode: packageResponse.correiosTrackCode
    });
  }

  /**
   * Checks if package is drop off type
   * @returns boolean
   */
  isDropoff() {
    return this?.packagePickupTraits?.pickupType === PICKUP_TYPE.dropOff;
  }

  /**
   * Checks if package is indispatch
   * @returns boolean
   */
  isIndispatch() {
    return this?.packageSlo === PACKAGE_SLO.INDISPATCH;
  }
}

export default OccasionalTrackingSummaryItem;
