import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'UI/components';
import { blue, root, grey } from 'theme/colors';
import { getMonthlyPackagesRangeUseCase } from 'domain/use-cases';
import { useUserRecords } from 'UI/contexts';

const EstimatedMonthlyPackagesStepForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const [
    estimatedMonthlyPackagesOptions,
    setEstimatedMonthlyPackagesOptions
  ] = useState([]);

  useEffect(() => {
    setSelectedOption(userRecords.estimatedMonthlyPackages);
  }, [userRecords.estimatedMonthlyPackages]);

  const handleSubmit = () => {
    setUserRecords({
      ...userRecords,
      estimatedMonthlyPackages: selectedOption
    });
    handleOnNextStep();
  };

  useEffect(() => {
    getMonthlyPackagesRangeUseCase().then(estimatedPackagesRange => {
      const upToLabel = initialRange =>
        t('signUpCompany.estimatedMonthlyPackages.options.upTo', {
          initialRange
        });
      const fromToLabel = (initialRange, finalRange) =>
        t('signUpCompany.estimatedMonthlyPackages.options.fromTo', {
          initialRange,
          finalRange
        });
      const moreThanLabel = finalRange =>
        t('signUpCompany.estimatedMonthlyPackages.options.moreThan', {
          finalRange
        });

      const { micro, small, medium, large } = estimatedPackagesRange;

      const options = [
        {
          id: 'underMicro',
          label: upToLabel(micro?.[0] - 1),
          value: micro?.[0] - 1
        },
        {
          id: 'micro',
          label: fromToLabel(micro?.[0], micro?.[1]),
          value: micro?.[1]
        },
        {
          id: 'small',
          label: fromToLabel(small?.[0], small?.[1]),
          value: small?.[1]
        },
        {
          id: 'medium',
          label: fromToLabel(medium?.[0], medium?.[1]),
          value: medium?.[1]
        },
        {
          id: 'large',
          label: moreThanLabel(large?.[0] - 1),
          value: large?.[0]
        }
      ];

      if (loading) {
        setEstimatedMonthlyPackagesOptions(options);
      }
    });
    return () => setLoading(false);
  }, [t, loading]);

  return (
    <Box p={3}>
      <Typography fontWeight="500" variant="h5">
        {t('signUpCompany.estimatedMonthlyPackages.title')}
      </Typography>
      <Box display="flex" py={2}>
        <Box flex={1}>
          {estimatedMonthlyPackagesOptions.map(option => (
            <Box mt={1} key={option?.id}>
              <Button
                key={option.id}
                bgColor={root[0]}
                borderColor={
                  selectedOption === option.value ? blue[500] : grey[50]
                }
                borderWidth={2}
                fullWidth
                label={option.label}
                value={selectedOption}
                fontWeight={500}
                textColor={root[900]}
                variant="outlined"
                onClick={() => setSelectedOption(option.value)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <Button
          fullWidth
          disabled={!selectedOption}
          label={t('signUpCompany.submitButton.label')}
          onClick={() => handleSubmit()}
        />
      </Box>
    </Box>
  );
};

EstimatedMonthlyPackagesStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default EstimatedMonthlyPackagesStepForm;
