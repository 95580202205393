/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const AboutServiceDescriptions = ({ descriptions }) => {
  const { t } = useTranslation('ui');

  return (
    <>
      {descriptions.map(description => (
        <Typography
          mb={3}
          variant="caption1"
          fontWeight={500}
          key={description}
          component="p"
        >
          <Trans t={t} i18nKey={description} />
        </Typography>
      ))}
    </>
  );
};

AboutServiceDescriptions.propTypes = {
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default { AboutServiceDescriptions };
