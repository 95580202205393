import forgotPasswordPtBrTranslations from './forgot-password/pt-br.translations.json';
import signInPtBrTranslations from './sign-in/pt-br.translations.json';
import signUpPtBrTranslations from './sign-up/pt-br.translations.json';

const translations = {
  'pt-BR': {
    containers: {
      ...forgotPasswordPtBrTranslations,
      ...signInPtBrTranslations,
      ...signUpPtBrTranslations
    }
  }
};

export default translations;
