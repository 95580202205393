import { Box, Typography } from '@mui/material';
import { ReactComponent as Car } from 'assets/car.svg';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BaseDrawer } from 'UI/components';
import { getRelativeDay } from 'utils';

export const GroupedShipmentDrawer = ({
  isVisible,
  onConfirm,
  onCancel,
  pickupStartTime,
  pickupEndTime,
  fullAddress
}) => {
  const { t } = useTranslation('ui');
  const startTime = format(pickupStartTime, 'HH:mm');
  const endTime = format(pickupEndTime, 'HH:mm');
  const relativeDayFormat = t('groupedShipment.days.format');
  const relativeDay = getRelativeDay(pickupStartTime, new Date(), {
    dateFormat: relativeDayFormat,
    locale: ptBR
  });
  const day = t(`groupedShipment.days.${relativeDay}`, {
    defaultValue: relativeDay
  });

  const question = t('groupedShipment.question');
  const labels = {
    confirm: t('groupedShipment.confirmAction'),
    dismiss: t('groupedShipment.cancelAction')
  };

  return (
    <BaseDrawer
      illustration={<Car />}
      isOpen={isVisible}
      labels={labels}
      onConfirmClick={onConfirm}
      onDismissClick={onCancel}
      title={
        <Trans
          t={t}
          i18nKey="groupedShipment.title"
          values={{ day, startTime, endTime }}
          components={[<strong />]}
        />
      }
      description={fullAddress}
    >
      <Box mt={2}>
        <Typography align="center" variant="body2">
          {question}
        </Typography>
      </Box>
    </BaseDrawer>
  );
};

GroupedShipmentDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  pickupStartTime: PropTypes.instanceOf(Date).isRequired,
  pickupEndTime: PropTypes.instanceOf(Date).isRequired,
  fullAddress: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default GroupedShipmentDrawer;
