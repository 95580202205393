import { loggiWebApi } from '@loggi/authentication-lib';

export const endpoints = {
  details: (companyId, loggiKey) =>
    `/integration/api/v1/companies/${companyId}/occasional-tracking/${loggiKey}/details/`,
  list: companyId =>
    `/integration/api/v1/companies/${companyId}/occasional-tracking/`
};

const occasionalTrackingApi = {
  details(companyId, loggiKey) {
    const url = endpoints.details(companyId, loggiKey);
    return loggiWebApi
      .url(url)
      .get()
      .json();
  },
  list(companyId) {
    const url = endpoints.list(companyId);
    return loggiWebApi
      .url(url)
      .get()
      .json();
  }
};

export default occasionalTrackingApi;
