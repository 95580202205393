import {
  PACKAGE_SIZES,
  PACKAGE_SIZE_G,
  PACKAGE_SIZE_M,
  PACKAGE_SIZE_P
} from 'constants/index';
import PropTypes from 'prop-types';
import { ReactComponent as IllustrationPackG } from './pack-g.svg';
import { ReactComponent as IllustrationPackM } from './pack-m.svg';
import { ReactComponent as IllustrationPackP } from './pack-p.svg';

export const ILLUSTRATION_BY_TYPE = {
  [PACKAGE_SIZE_G]: IllustrationPackG,
  [PACKAGE_SIZE_M]: IllustrationPackM,
  [PACKAGE_SIZE_P]: IllustrationPackP
};

export const getPackageSizeDimensions = size => {
  const { sizeCm, weightG } = PACKAGE_SIZES[size];
  const weightKg = weightG / 1000;
  return { sizeCm, weightKg };
};

export const defaultProps = { isActive: false };
export const propTypes = {
  isActive: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.keys(ILLUSTRATION_BY_TYPE)).isRequired
};
