import { Address } from 'models';
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks';
import Footer from 'UI/shipment/components/shipment-footer';
import useAmateurAddressCoverage from 'UI/shipment/hooks/amateur-address-coverage';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { AddressAutocomplete } from 'UI/shipment/components/address-autocomplete/address-autocomplete.component';
import { TextInput } from 'UI/components/input';
import useAddressForm from 'UI/shipment/hooks/address-form';
import {
  SHIPMENT_ADDRESS_BY_CEP_PAGE,
  SHIPMENT_BY_GOOGLE_TYPE
} from 'crm/constants';
import ShipmentOriginByGoogleAlertAnswered from 'crm/entities/events/shipment-origin-by-google-alert-answered/shipment-origin-by-google-alert-answered';
import ShipmentOriginByGoogleAlertPresented from 'crm/entities/events/shipment-origin-by-google-alert-presented/shipment-origin-by-google-alert-presented';
import ShipmentOriginByGoogleAddressSelected from 'crm/entities/events/shipment-origin-by-google-address-selected/shipment-origin-by-google-address-selected';
import ShipmentOriginByGoogleInputStarted from 'crm/entities/events/shipment-origin-by-google-input-started/shipment-origin-by-google-input-started';
import ShipmentAddressByCepSelected from 'crm/entities/events/shipment-address-by-cep-selected/shipment-address-by-cep-selected';

function useAddressCoverage({
  defaultAddress,
  defaultComplement,
  outOfCoverageErrorMessage,
  onError = () => {}
}) {
  const form = useAddressForm({
    address: defaultAddress,
    complement: defaultComplement
  });
  const [error, setError] = React.useState(null);

  const handleSetAddress = _address => {
    form.setAddress(_address);
    if (error) setError(null);
  };

  const addressCoverage = useAmateurAddressCoverage({
    address: form.state.address,
    onSuccess(coverage) {
      if (!coverage.hasCoverage)
        return setError(outOfCoverageErrorMessage ?? 'Out of coverage');

      return setError(null);
    },
    onError
  });

  return {
    address: form.state.address,
    complement: form.state.complement,
    error,
    coverage: addressCoverage.data,
    isLoading: addressCoverage.isLoading,
    setAddress: handleSetAddress,
    setComplement: form.setComplement
  };
}

export default function PickupAddressPage() {
  const { t } = useTranslation('ui');
  const shipmentContext = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const [error, setError] = React.useState(null);
  const showSnackbar = useSnackbar();
  const addressForm = useAddressCoverage({
    defaultAddress: shipmentContext.context.pickupAddress,
    defaultComplement: shipmentContext.context.pickupAddress?.complement,
    outOfCoverageErrorMessage: t('pickupV2.errorMessages.outOfCoverage'),
    onError() {
      showSnackbar({
        message: t('pickupV2.errorMessages.unexpected'),
        severity: 'error'
      });
    }
  });

  const handleSubmit = () => {
    const isAddressEqual =
      addressForm.address.placeId ===
      shipmentContext.context.deliveryAddress?.placeId;

    if (isAddressEqual)
      return setError(t('pickupV2.errorMessages.addressesEqual'));

    const { address, complement } = addressForm;
    Object.assign(address, { complement });
    return shipmentDispatcher.continue(new Address(address));
  };

  const handleAddressEvent = ({ eventType, params }) => {
    switch (eventType) {
      case SHIPMENT_BY_GOOGLE_TYPE.ALERT_ANSWERED:
        new ShipmentOriginByGoogleAlertAnswered(params).sendToCrm();
        break;
      case SHIPMENT_BY_GOOGLE_TYPE.ALERT_PRESENTED:
        new ShipmentOriginByGoogleAlertPresented(params).sendToCrm();
        break;
      case SHIPMENT_BY_GOOGLE_TYPE.ADDRESS_SELECTED:
        new ShipmentOriginByGoogleAddressSelected(params).sendToCrm();
        break;
      case SHIPMENT_BY_GOOGLE_TYPE.INPUT_STARTED:
        new ShipmentOriginByGoogleInputStarted().sendToCrm();
        break;
      default:
        break;
    }
  };

  const onClickQuotationWithPostalCode = () => {
    new ShipmentAddressByCepSelected({
      addressInputed: addressForm.address?.description,
      page: SHIPMENT_ADDRESS_BY_CEP_PAGE.ORIGIN
    }).sendToCrm();

    shipmentDispatcher.clickQuotationWithPostalCode();
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1">{t('pickupV2.title')}</Typography>
      </Box>
      <Box>
        <AddressAutocomplete
          label={t('pickupV2.label')}
          error={addressForm.error || error}
          isLoading={!!addressForm.address && addressForm.isLoading}
          onChange={ev =>
            ev.target.value instanceof Address &&
            addressForm.setAddress(ev.target.value)
          }
          value={addressForm.address}
          onAddressEvent={handleAddressEvent}
        />

        <Box mb={5} mt={2}>
          <TextInput
            id="address-complement"
            label={t('pickupV2.addressComplement')}
            value={addressForm.complement}
            onChange={addressForm.setComplement}
          />
        </Box>
      </Box>

      <Box>
        <Button
          onClick={onClickQuotationWithPostalCode}
          data-testid="fill-with-postal-code"
        >
          {t('pickupV2.quotationWithPostalCodeButton')}
        </Button>
      </Box>

      <Footer>
        <Footer.PrimaryAction
          onClick={handleSubmit}
          disabled={
            !addressForm.address ||
            addressForm.isLoading ||
            !!addressForm.error ||
            !!error
          }
        />
      </Footer>
    </>
  );
}
