import { loggiWebApi } from '@loggi/authentication-lib';

const endpoint = (companyId, loggiKey) =>
  `/one/api/shipping/v1/companies/${companyId}/shipping/${loggiKey}/cancel/`;

const cancelShippingApi = {
  cancel(companyId, loggiKey) {
    const url = endpoint(companyId, loggiKey);
    return loggiWebApi
      .url(url)
      .post()
      .res();
  }
};

export default cancelShippingApi;
