import { APP_INFO } from 'crm/constants';
import { getFsValueUseCase } from 'domain/use-cases';
import CRMBaseEntity from '../crm-base-entity.model';

export default class CRMBaseEvent extends CRMBaseEntity {
  cleanPayload() {
    const cleanedPayload = super.cleanPayload();
    return {
      product: APP_INFO.PRODUCT,
      service: APP_INFO.SERVICE,
      stack: APP_INFO.STACK,
      ...cleanedPayload
    };
  }

  async sendToCrm() {
    const disabledEventsList = await getFsValueUseCase('disable_crm_events');
    if (
      disabledEventsList === '*' ||
      disabledEventsList.includes(this.eventName)
    ) {
      return;
    }
    
    this.enrichesPayloadWithGeneralAppData();
    const cleanedPayload = this.cleanPayload();
    this.crmService.sendEvent(this, cleanedPayload);
  }
}
