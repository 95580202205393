/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseInputField } from './base-input';

const formatValue = (value = '') => {
  return value.substring(0, 8).replace(/(\d{5})(\d{3})/, '$1-$2');
};

const unformatValue = (value = '') => {
  return value.replace(/\D/g, '').substring(0, 8);
};

const isPostalCodeValid = postalCode => postalCode && postalCode.length === 8;

/**
 * This input field renders the postal code using the format DDDDD-DDD,
 * known as CEP in Brazil.
 *
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.label
 * @param {string | undefined} props.error
 * @param {string | undefined} props.value
 * @param {boolean | undefined} props.disabled
 * @param {((value: string) => void) | undefined} props.onChange - Called when the postal code changes
 * @param {(value: string) => void} props.onValid - Called when the postal code is valid
 * @param {any} props.endAdornment - Adornment to be displayed at the end of the input
 */
export default function PostalCodeInput(props) {
  const handleChange = value => {
    if (props.onChange) props.onChange(value);
    if (isPostalCodeValid(value) && props.onValid) props.onValid(value);
  };

  return (
    <BaseInputField
      id={props.id}
      label={props.label}
      value={props.value}
      onChange={handleChange}
      error={props.error}
      formatValue={formatValue}
      unformatValue={unformatValue}
      endAdornment={props.endAdornment}
      disabled={props.disabled}
      type="text"
      inputMode="text"
    />
  );
}

PostalCodeInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onValid: PropTypes.func,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  endAdornment: PropTypes.node
};
