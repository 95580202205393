import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/components';

const AppleSignInButton = ({ onClick }) => {
  const { t } = useTranslation('ui');

  return (
    <Button
      fullWidth
      icon="apple"
      label={t('signIn.appleButtonLabel')}
      onClick={onClick}
      variant="outlined-neutral"
    />
  );
};

AppleSignInButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AppleSignInButton;
