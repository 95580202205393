import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { colors, spacing } from '@loggi/front-design-tokens';

export const PixPaymentInstructions = () => {
  const { t } = useTranslation('ui');

  return (
    <Box data-testid="pix-payment-instructions" p={spacing.stack.xxsmall}>
      <Box mb={spacing.stack.xxxsmall}>
        <Typography
          variant="subtitleMedium"
          color={colors.neutrals['on-surface']}
        >
          {t('pixPayment.instructions.title')}
        </Typography>
      </Box>
      <Box component="ol" pl={spacing.stack.xxxsmall} margin={0}>
        <Typography
          variant="bodyTextMedium"
          color={colors.neutrals['on-surface']}
        >
          <Box component="li" mb={spacing.stack.xxxsmall}>
            {t('pixPayment.instructions.step1')}
          </Box>
          <Box component="li" mb={spacing.stack.xxxsmall}>
            {t('pixPayment.instructions.step2')}
          </Box>
          <Box component="li">{t('pixPayment.instructions.step3')}</Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default PixPaymentInstructions;
