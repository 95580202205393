import { Box, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { root } from 'theme/colors';
import { getRelativeDay } from 'utils';
import { PickupScheduleLoader } from './pickup-schedule-loader.component';
import PickupScheduleSelectSlot from './pickup-schedule-select-slot/pickup-schedule-select-slot.component';

const PickupScheduleSelect = ({ availableSlots, onChange }) => {
  const [value, setValue] = useState([]);
  const { t } = useTranslation('weekDays');

  const handleValueChanges = newValue => {
    setValue(newValue);
    onChange(newValue);
  };

  if (!availableSlots) return <PickupScheduleLoader />;
  return availableSlots.map(
    (slots, i) =>
      slots.length >= 1 && (
        <Box key={slots[0][0]} mt={i > 0 ? 3 : 0} textAlign="left">
          <Box mb={2} textAlign="left" color={root[900]}>
            <Typography variant="subtitle1">
              {t(getRelativeDay(slots[0][0]))}
            </Typography>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            maxWidth="500px"
            mx={-1}
          >
            {slots.map(slot => (
              <PickupScheduleSelectSlot
                isActive={isEqual(value, slot)}
                key={slot[0]}
                onClick={() => handleValueChanges(slot)}
                slotTime={slot}
              />
            ))}
          </Box>
        </Box>
      )
  );
};

PickupScheduleSelect.defaultProps = { availableSlots: null };
PickupScheduleSelect.propTypes = {
  availableSlots: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.instanceOf(Date)))
  ),
  onChange: PropTypes.func.isRequired
};

export default PickupScheduleSelect;
