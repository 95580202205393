import { Box, Button, Typography } from '@mui/material';
import { TextInput } from 'UI/components/input';
import { AddressAutocomplete } from 'UI/shipment/components/address-autocomplete/address-autocomplete.component';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { Address } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'UI/shipment/components/shipment-footer';
import useAddressForm from 'UI/shipment/hooks/address-form';
import {
  SHIPMENT_ADDRESS_BY_CEP_PAGE,
  SHIPMENT_BY_GOOGLE_TYPE
} from 'crm/constants';
import ShipmentDestinationByGoogleAlertAnswered from 'crm/entities/events/shipment-destination-by-google-alert-answered/shipment-destination-by-google-alert-answered';
import ShipmentDestinationByGoogleAlertPresented from 'crm/entities/events/shipment-destination-by-google-alert-presented/shipment-destination-by-google-alert-presented';
import ShipmentDestinationByGoogleAddressSelected from 'crm/entities/events/shipment-destination-by-google-address-selected/shipment-destination-by-google-address-selected';
import ShipmentDestinationByGoogleInputStarted from 'crm/entities/events/shipment-destination-by-google-input-started/shipment-destination-by-google-input-started';
import ShipmentAddressByCepSelected from 'crm/entities/events/shipment-address-by-cep-selected/shipment-address-by-cep-selected';

export default function DeliveryAddressPage() {
  const { t } = useTranslation('ui');
  const [error, setError] = React.useState(null);
  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const { state, setAddress, setComplement, isAddressValid } = useAddressForm({
    address: context.deliveryAddress,
    complement: context.deliveryAddress?.complement
  });

  const handleSubmit = () => {
    const { address, complement } = state;
    Object.assign(address, { complement });
    if (address.placeId === context.pickupAddress?.placeId)
      return setError(t('deliveryV2.errorMessages.addressesEqual'));

    return shipmentDispatcher.continue(new Address(address));
  };

  const handleAddressEvent = ({ eventType, params }) => {
    switch (eventType) {
      case SHIPMENT_BY_GOOGLE_TYPE.ALERT_ANSWERED:
        new ShipmentDestinationByGoogleAlertAnswered(params).sendToCrm();
        break;
      case SHIPMENT_BY_GOOGLE_TYPE.ALERT_PRESENTED:
        new ShipmentDestinationByGoogleAlertPresented(params).sendToCrm();
        break;
      case SHIPMENT_BY_GOOGLE_TYPE.ADDRESS_SELECTED:
        new ShipmentDestinationByGoogleAddressSelected(params).sendToCrm();
        break;
      case SHIPMENT_BY_GOOGLE_TYPE.INPUT_STARTED:
        new ShipmentDestinationByGoogleInputStarted().sendToCrm();
        break;
      default:
        break;
    }
  };

  const onClickQuotationWithPostalCode = () => {
    new ShipmentAddressByCepSelected({
      addressInputed: state.address?.description,
      page: SHIPMENT_ADDRESS_BY_CEP_PAGE.DESTINATION
    }).sendToCrm();

    shipmentDispatcher.clickQuotationWithPostalCode();
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1">{t('deliveryV2.title')}</Typography>
      </Box>
      <Box>
        <AddressAutocomplete
          label={t('deliveryV2.addressLabel')}
          error={error}
          onChange={ev =>
            ev.target.value instanceof Address && setAddress(ev.target.value)
          }
          value={state.address}
          onAddressEvent={handleAddressEvent}
        />
        <Box mb={5} mt={2}>
          <TextInput
            id="address-complement"
            label={t('deliveryV2.addressComplement')}
            value={state.complement}
            onChange={setComplement}
          />
        </Box>
      </Box>

      <Box>
        <Button
          onClick={onClickQuotationWithPostalCode}
          data-testid="fill-with-postal-code"
        >
          {t('deliveryV2.quotationWithPostalCodeButton')}
        </Button>
      </Box>

      <Footer>
        <Footer.PrimaryAction
          disabled={!isAddressValid}
          onClick={handleSubmit}
        />
      </Footer>
    </>
  );
}
