import { useFeatureSwitch } from 'hooks/feature-switch';

export const useDeliveryGeolocation = () => {
  const featureSwitch = useFeatureSwitch('enable_delivery_geolocation', false);

  return {
    isLoading: featureSwitch.isFetching,
    isEnabled: featureSwitch.data
  };
};

export default useDeliveryGeolocation;
