import React from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Icon } from '@loggi/loggi-icons';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';
import { colors, spacing } from '@loggi/front-design-tokens';

import { currencyFormatter } from 'utils';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';

import CloseHeader from '../close-header/close-header';
import Footer from '../footer/footer.component';
import { CREDIT_CARD } from '../../payment/constants';

export default function BalanceSuccessfullyAddedPage() {
  const { t } = useTranslation('ui');
  function dateFormatter(date) {
    return format(date, 'dd MMM. HH:mm', { locale: pt }).toLocaleUpperCase();
  }

  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();

  const {
    addedBalance,
    currentBalance: balance,
    cashback,
    paymentDate,
    paymentMethod
  } = context;

  const currentBalance = balance + addedBalance;

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <CloseHeader onClick={() => shipmentDispatcher.continue()} />
        <Stack p={spacing.stack.xxsmall} gap={spacing.stack.xxsmall}>
          <Icon
            name="CheckVerified02"
            size="large"
            color={colors.feedback.positive}
          />
          <Typography
            variant="headingMedium"
            color={colors.neutrals['on-surface']}
          >
            {t('wallet.balanceSuccessfullyAdded.title')}
          </Typography>
        </Stack>
        <Stack p={spacing.stack.xxsmall} gap={spacing.stack.nano}>
          <Typography
            variant="subtitleMedium"
            color={colors.neutrals['on-surface']}
          >
            {t('wallet.balanceSuccessfullyAdded.subtitle')}
          </Typography>
          <Typography
            variant="headingLarge"
            color={colors.neutrals['on-surface']}
          >
            {currencyFormatter(addedBalance)}
          </Typography>
          {cashback && (
            <Typography
              variant="subtitleMedium"
              color={colors.feedback.positive}
            >
              {t('wallet.balanceSuccessfullyAdded.cashbackSubtitle', {
                cashback: currencyFormatter(cashback)
              })}
            </Typography>
          )}
        </Stack>
        <Footer>
          <Footer.Details>
            {currentBalance && (
              <Footer.DetailsItem
                title={t('wallet.footer.currentBalance')}
                value={currencyFormatter(currentBalance)}
              />
            )}
            {paymentDate && (
              <Footer.DetailsItem
                title={t('wallet.footer.paymentDate')}
                value={dateFormatter(paymentDate)}
              />
            )}
            {paymentMethod && (
              <Footer.DetailsItem
                title={t('wallet.footer.paymentMethod.title')}
                value={
                  paymentMethod === CREDIT_CARD
                    ? t('wallet.footer.paymentMethod.creditCard')
                    : t('wallet.footer.paymentMethod.pix')
                }
              />
            )}
          </Footer.Details>
          <Footer.PrimaryAction
            title={t('wallet.footer.continueButton')}
            onClick={() => shipmentDispatcher.continue()}
          />
        </Footer>
      </Stack>
    </ThemeProvider>
  );
}
