import { Box, MobileStepper, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { root } from 'theme/colors';

const StyledStepper = styled(MobileStepper)({
  height: '100%',
  padding: '17px 0px',
  '& .MuiMobileStepper-progress': {
    width: '100%'
  }
});

export const ProgressBar = ({ activeStep, maxSteps }) => {
  return (
    <Box
      bgcolor={root[0]}
      borderRadius="16px 16px 0px 0px"
      boxShadow="0px -5px 30px rgba(0, 0, 0, 0.15)"
      height="56px"
      px={3}
    >
      <StyledStepper
        activeStep={activeStep}
        position="static"
        steps={maxSteps + 1}
        variant="progress"
        nextButton={
          <Typography
            style={{ paddingLeft: '24px' }}
            size="small"
            disabled={activeStep === maxSteps}
          >
            {activeStep}/{maxSteps}
          </Typography>
        }
      />
    </Box>
  );
};

ProgressBar.propTypes = {
  activeStep: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired
};

export default { ProgressBar };
