import { useQuery } from '@tanstack/react-query';
import { useDomainUseCase } from 'UI/contexts';
import { useCurrentUser } from 'hooks/current-user/use-current-user.hook';

export const useUserAllowedOnGroupedShipmentInPickup = () => {
  const { user } = useCurrentUser();
  const isUserAllowedOnGroupedShipmentInPickup = useDomainUseCase(
    'isUserAllowedOnGroupedShipmentInPickupUseCase'
  );

  const { data, isLoading } = useQuery(
    ['allowed_users_on_grouped_shipment', user?.email],
    () => isUserAllowedOnGroupedShipmentInPickup(user.email),
    {
      enabled: !!user?.email
    }
  );

  return { isAllowed: data, isLoading };
};

export default { useUserAllowedOnGroupedShipmentInPickup };
