import React, { useEffect, useState } from 'react';
import { Chip, MenuDropdown } from '@loggi/front-design-system';
import PropTypes from 'prop-types';
import { Popover } from '@mui/material';

export const DropdownChip = ({
  label,
  options,
  onSelectItem,
  activeFilter = {},
  multiSelect = false
}) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    setSelectedItem(activeFilter);
  }, [activeFilter]);

  const openDropdownMenu = e => {
    const isExpanded = !expanded;
    setAnchorEl(e.currentTarget);
    setExpanded(isExpanded);
  };

  const handleCloseDropdown = () => {
    setExpanded(false);
    setAnchorEl(null);
  };

  const handleSelectItem = item => {
    setSelectedItem(item || null);
    onSelectItem(item || null);

    if (!multiSelect) {
      handleCloseDropdown();
    }
  };

  return (
    <>
      <Chip
        label={label}
        expanded={expanded}
        icon
        onClick={e => openDropdownMenu(e)}
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPaper-root': {
            width: multiSelect ? '300px' : 'auto',
            maxHeight: multiSelect ? '500px !important' : 'auto'
          }
        }}
      >
        {multiSelect ? (
          <MenuDropdown
            onSelect={handleSelectItem}
            defaultSelected={selectedItem}
            items={options}
            multiSelect
          />
        ) : (
          <MenuDropdown
            onSelect={handleSelectItem}
            defaultSelected={selectedItem}
            items={options}
            variant="chips"
            withActiveItemIcon={false}
          />
        )}
      </Popover>
    </>
  );
};

DropdownChip.defaultProps = {
  activeFilter: null
};

DropdownChip.propTypes = {
  label: PropTypes.string.isRequired,
  activeFilter: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    )
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool.isRequired
};

export default DropdownChip;
