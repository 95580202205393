import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import ServiceLocationAgencies from 'UI/shipment/components/agencies/list';
import { root } from 'theme/colors';
import useStyles from 'theme/custom-classes';
import { useHistory, useParams } from 'react-router-dom';
import { maxDropoffDate } from 'UI/shipment/pages/agencies/agencies.helper';

export const BACK_BUTTON_TEST_ID = 'title-bar.back-button';

export default function DropOffAgenciesLocation() {
  const { t } = useTranslation('ui');
  const history = useHistory();
  const { senderPostalCode, pickupScheduledUpdatedDate } = useParams();
  const packagePickupScheduledDate = new Date(pickupScheduledUpdatedDate);

  const headerProps = {
    backgroundColor: root[0]
  };
  const classes = useStyles(headerProps);

  return (
    <>
      <Box bgcolor={root[0]} data-testid="tracking-page">
        <Box className={classes.header} role="menubar">
          <IconButton
            color="inherit"
            data-testid={BACK_BUTTON_TEST_ID}
            disableRipple
            style={{ padding: '0px' }}
            onClick={history.goBack}
          >
            <ArrowBackIos color="primary" />
            <Box mx={0.5}>
              <Typography variant="body1">
                {t('dropOffAgenciesLocation.back')}
              </Typography>
            </Box>
          </IconButton>
        </Box>
        <Box p={2}>
          <Typography variant="subtitle1">
            {t('dropOffAgenciesLocation.title')}
          </Typography>
          <Box py="24px">
            <Typography variant="body2">
              <Trans
                t={t}
                i18nKey="dropOffAgenciesLocation.subtitle"
                values={{ date: maxDropoffDate(packagePickupScheduledDate) }}
              />
            </Typography>
          </Box>
          <ServiceLocationAgencies postalCode={senderPostalCode} />
        </Box>
      </Box>
    </>
  );
}
