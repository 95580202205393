import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { getAccessMethod } from 'crm/utils';

export default class AccessStarted extends CRMBaseEvent {
  constructor({ originAccess }) {
    super();
    this.eventName = EVENTS.ACCESS_STARTED;
    this.payload = {
      origin: getAccessMethod(originAccess)
    };
  }
}
