import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'UI/components';
import { useTranslation } from 'react-i18next';

/**
 * @param {object} props
 * @param {boolean} props.isVisible
 * @param {() => void} props.onClose
 * @param {() => void} props.onConfirm
 */
export function MissingNumberWarning({ isVisible, onClose, onConfirm }) {
  const { t } = useTranslation('ui');

  return (
    <BaseDrawer
      isOpen={isVisible}
      title={t('correiosAddressWarnings.missingNumber.title')}
      description={t('correiosAddressWarnings.missingNumber.description')}
      labels={{
        confirm: t('correiosAddressWarnings.missingNumber.confirmButton'),
        dismiss: t('correiosAddressWarnings.missingNumber.dismissButton')
      }}
      onConfirmClick={onConfirm}
      onDismissClick={onClose}
      vertical
      alignTitleLeft
      alignDescriptionLeft
    />
  );
}

MissingNumberWarning.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

/**
 * @param {object} props
 * @param {boolean} props.isVisible
 * @param {() => void} props.onClose
 * @param {() => void} props.onConfirm
 */
export function MissingComplementWarning({ isVisible, onClose, onConfirm }) {
  const { t } = useTranslation('ui');
  return (
    <BaseDrawer
      isOpen={isVisible}
      title={t('correiosAddressWarnings.missingComplement.title')}
      description={t('correiosAddressWarnings.missingComplement.description')}
      labels={{
        confirm: t('correiosAddressWarnings.missingComplement.confirmButton'),
        dismiss: t('correiosAddressWarnings.missingComplement.dismissButton')
      }}
      onConfirmClick={onConfirm}
      onDismissClick={onClose}
      vertical
      alignTitleLeft
      alignDescriptionLeft
    />
  );
}

MissingComplementWarning.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

/**
 * @param {object} props
 * @param {boolean} props.isVisible
 * @param {() => void} props.onClose
 * @param {() => void} props.onConfirm
 */
export function MissingNumberAndComplementWarning({
  isVisible,
  onClose,
  onConfirm
}) {
  const { t } = useTranslation('ui');

  return (
    <BaseDrawer
      isOpen={isVisible}
      title={t('correiosAddressWarnings.missingNumberAndComplement.title')}
      description={t(
        'correiosAddressWarnings.missingNumberAndComplement.description'
      )}
      labels={{
        confirm: t(
          'correiosAddressWarnings.missingNumberAndComplement.confirmButton'
        ),
        dismiss: t(
          'correiosAddressWarnings.missingNumberAndComplement.dismissButton'
        )
      }}
      onConfirmClick={onConfirm}
      onDismissClick={onClose}
      vertical
      alignTitleLeft
      alignDescriptionLeft
    />
  );
}

MissingNumberAndComplementWarning.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
