import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue, grey, root } from 'theme/colors';
import {
  defaultProps,
  getPackageSizeDimensions,
  ILLUSTRATION_BY_TYPE,
  propTypes
} from './package-size-select-options.helper';

export const PackageSizeOption = ({ isActive, onSelect, size }) => {
  const { t } = useTranslation('ui');
  const Illustration = ILLUSTRATION_BY_TYPE[size];
  const { sizeCm, weightKg } = getPackageSizeDimensions(size);
  const color = isActive ? grey[500] : root[0];
  const bgColor = isActive ? root[0] : 'transparent';
  const borderColor = isActive ? root[0] : 'rgba(0, 0, 0, 0.24)';

  const handleClick = () => onSelect(size);

  return (
    <Box
      display="inline-flex"
      minWidth="280px"
      style={{ scrollSnapAlign: 'center' }}
    >
      <Button
        data-package-size={size}
        onClick={handleClick}
        style={{ padding: '0', width: '100%' }}
      >
        <Box
          borderRadius={16}
          color={color}
          textAlign="left"
          width="100%"
          style={{ boxSizing: 'border-box' }}
        >
          <Box
            alignItems="center"
            borderRadius="16px 16px 0 0"
            display="flex"
            height="184px"
            justifyContent="center"
            style={{ backgroundColor: blue[900] }}
          >
            <Illustration />
          </Box>
          <Box
            bgcolor={bgColor}
            borderRadius="0 0 16px 16px"
            border={`2px solid ${borderColor}`}
            pt={4}
            pb={2}
            px={2}
            style={{ borderTopColor: 'transparent' }}
          >
            <Typography variant="subtitle1">
              <b>{t(`packageSizePage.labels.${size}`)}</b>
            </Typography>
            <Box display="flex" my={1}>
              <Box mr={2}>
                <Typography variant="subtitle2">
                  {t('packageSizePage.maxSize', { sizeCm })}
                </Typography>
              </Box>
              <Typography variant="subtitle2">
                {t('packageSizePage.maxWeight', { weightKg })}
              </Typography>
            </Box>
            <Typography variant="body2">
              <b>{t(`packageSizePage.examples.${size}`)}</b>
            </Typography>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};

PackageSizeOption.defaultProps = defaultProps;
PackageSizeOption.propTypes = propTypes;

export default { PackageSizeOption };
