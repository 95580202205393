/* eslint-disable import/prefer-default-export */
import {
  FormControl as MuiFormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
  Typography
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { grey, root } from 'theme/colors';
import * as border from 'theme/border';
import * as typography from 'theme/typography';

const StyledOutlinedInput = styled(OutlinedInput)({
  '&.MuiOutlinedInput-root': {
    fontWeight: typography.weigth.medium
  },
  '& .MuiOutlinedInput-notchedOutline ': {
    borderWidth: border.thickness,
    borderColor: grey[100]
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: border.focusedThickness,
    borderColor: root[900]
  },
  '&.Mui-focused .MuiInputAdornment-root': {
    color: 'black'
  }
});

const StyledFormControl = styled(MuiFormControl)({
  // data-shrink="true" when input focused or filled
  '[data-shrink="true"] ~ .MuiInputBase-root .MuiInputAdornment-root': {
    visibility: 'visible'
  },
  '[data-shrink="false"] ~ .MuiInputBase-root .MuiInputAdornment-root': {
    visibility: 'hidden'
  },
  // stop occupying space when not showing anything (error/helper text)
  '.MuiFormHelperText-root:not(.MuiFormHelperText-filled)': {
    marginTop: 0
  }
});

/** @param {import('./base-input-props').BaseInputProps} props */
export function BaseInputField(props) {
  const isError = !!props.error;
  const testId = props.testId ? props.testId : props.id;
  const value = props.formatValue(props.value);
  const handleChange = ev => {
    if (props.onChange) props.onChange(props.unformatValue(ev.target.value));
  };

  const FormControl = props.autohideAdornment
    ? StyledFormControl
    : MuiFormControl;

  return (
    <FormControl error={isError} disabled={props.disabled} fullWidth>
      <InputLabel
        htmlFor={props.id}
        // center label position, to custom input height=48px
        sx={{ top: '-2px', '&.MuiInputLabel-shrink': { top: 0 } }}
      >
        <Typography fontWeight="medium" variant="body2">
          {props.label}
        </Typography>
      </InputLabel>
      <StyledOutlinedInput
        sx={{ height: props.rows ? 'unset' : '48px' }}
        id={props.id}
        label={props.label}
        value={value}
        placeholder={props.placeholder}
        rows={props.rows}
        multiline={props.rows > 1}
        onChange={handleChange}
        onFocus={props.onFocus}
        type={props.type}
        inputProps={{
          inputMode: props.inputMode,
          'data-testid': testId,
          maxLength: props.maxLength
        }}
        startAdornment={
          props.startAdornment && (
            <InputAdornment position="start">
              {props.startAdornment}
            </InputAdornment>
          )
        }
        endAdornment={
          props.endAdornment && (
            <InputAdornment position="end">{props.endAdornment}</InputAdornment>
          )
        }
        fullWidth
      />
      <FormHelperText>{props.helperText || props.error}</FormHelperText>
    </FormControl>
  );
}

BaseInputField.defaultProps = {
  value: undefined,
  rows: 1,
  error: undefined,
  helperText: undefined,
  placeholder: undefined,
  testId: '',
  onChange: undefined,
  onFocus: undefined,
  endAdornment: null,
  startAdornment: null,
  autohideAdornment: false,
  maxLength: undefined,
  type: 'text',
  inputMode: 'text',
  formatValue: value => value,
  unformatValue: value => value,
  disabled: false
};

BaseInputField.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  helperText: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.oneOf(['text', 'number', 'tel', 'password']),
  inputMode: PropTypes.string,
  label: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  formatValue: PropTypes.func,
  unformatValue: PropTypes.func,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  autohideAdornment: PropTypes.bool,
  disabled: PropTypes.bool
};
