import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { root } from 'theme/colors';
import useDeliveryServices from 'UI/shipment/hooks/delivery-services';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import Footer from 'UI/shipment/components/shipment-footer';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import ServiceOption from './service-option/service-option.component';
import { Loading } from './loading/loading.component';

export const ServicesPage = () => {
  const { t } = useTranslation('ui');
  const { context } = useShipmentContext();
  const [serviceSelected, setServiceSelected] = useState(context.serviceType);
  const deliveryServices = useDeliveryServices();
  const shipmentDispatcher = useShipmentDispatcher();

  if (!deliveryServices) {
    return <Loading />;
  }

  const { availableServices } = deliveryServices;
  const services = Object.keys(availableServices).map(
    key => availableServices[key]
  );

  const handleSubmitOption = () => {
    shipmentDispatcher.continue(serviceSelected);
  };

  const isMobileDevice = checkIsMobile();

  return (
    <Box height={isMobileDevice ? 'auto' : 'calc(100vh - 80px)'}>
      <Box px={3} my={3}>
        <Typography variant="subtitle1" color={root[900]}>
          {t('servicesPage.title')}
        </Typography>
      </Box>
      <Box mx={3}>
        {services?.map(service => (
          <ServiceOption
            key={service.serviceType}
            name={service.serviceName}
            price={service.price}
            isSelected={serviceSelected === service.serviceType}
            slo={service.slo}
            onClick={() => setServiceSelected(service.serviceType)}
            other={service}
          />
        ))}
      </Box>
      <Footer>
        <Footer.PrimaryAction onClick={handleSubmitOption} />
      </Footer>
    </Box>
  );
};

export default { ServicesPage };
