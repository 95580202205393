/* eslint-disable import/prefer-default-export */
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useDomainUseCase } from 'UI/contexts';

const fsNames = [
  'allowed_to_view_debug_info',
  'grouped_shipment_blocklist_by_company',
  'is_indispatch_payment_disabled_by_company',
  'enable_payment_session_data_from_api_response'
];

async function fetchFeatureSwitches(fetchFS) {
  const values = await Promise.all(fsNames.map(fetchFS));
  const aggregated = values.reduce((acc, value, index) => {
    acc[fsNames[index]] = value;
    return acc;
  }, {});

  return aggregated;
}

/**
 * Fetches a set of feature switches and returns an object with the values
 *
 * @typedef {import(@tanstack/react-query).UseQueryResult} UseQueryResult
 * @param {object} options
 * @param {(data: { [key: string]: any }) => void} options.onSuccess callback when the query is successful
 * @returns {UseQueryResult<{ [key: string]: any }>} feature switches
 */
export function useFeatureSwitches({ onSuccess } = {}) {
  const getFsValue = useDomainUseCase('getFsValueUseCase');

  return useQuery(
    ['feature-switches'],
    () => fetchFeatureSwitches(getFsValue),
    {
      onSuccess,
      onError(error) {
        captureException(error);
      }
    }
  );
}
