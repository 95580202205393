export const infoServiceCards = [
  {
    name: 'loggiPonto',
    title: 'newHome.serviceCard.labels.loggiPontoTitle',
    description: 'newHome.serviceCard.labels.loggiPontoDescription',
    buttonLabel: 'newHome.serviceCard.labels.loggiPontoOnConfirm'
  },
  {
    name: 'correios',
    title: 'newHome.serviceCard.labels.correiosTitle',
    description: 'newHome.serviceCard.labels.correiosDescription',
    buttonLabel: 'newHome.serviceCard.labels.correiosOnConfirm'
  },
  {
    name: 'loggiFacil',
    title: 'newHome.serviceCard.labels.loggiFacilTitle',
    description: 'newHome.serviceCard.labels.loggiFacilDescription',
    buttonLabel: 'newHome.serviceCard.labels.loggiFacilOnConfirm'
  },
  {
    name: 'loggiExpresso',
    title: 'newHome.serviceCard.labels.loggiExpressoTitle',
    description: 'newHome.serviceCard.labels.loggiExpressoDescription',
    buttonLabel: 'newHome.serviceCard.labels.loggiExpressoOnConfirm'
  }
];

export default infoServiceCards;
