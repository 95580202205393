import { ThemeProvider } from '@mui/material';
import Header from 'UI/shipment/components/header';
import React from 'react';
import theme from 'theme';

export const LoggiHeader = () => (
  <ThemeProvider theme={theme}>
    <Header>
      <Header.LoggiLogo />
      <Header.UserMenuButtonWithEventsObserver variant="primary" />
    </Header>
  </ThemeProvider>
);

export default LoggiHeader;
