import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { colors, spacing, borders } from '@loggi/front-design-tokens';

export const CardSelectionInline = ({
  label,
  children,
  selected = false,
  handleClick
}) => {
  const borderColor = selected
    ? colors.interaction.primary
    : colors.neutrals['outline-low'];
  return (
    <Box
      mx={spacing.inset.large}
      data-testid="card-selection-inline"
      onClick={handleClick}
    >
      <Box
        display="flex"
        alignItems="center"
        borderRadius={borders.radius.small}
        style={{
          border: `1px solid ${borderColor}`
        }}
        p={spacing.inset.medium}
        gap={spacing.inline.xxxsmall}
      >
        <Box display="flex" alignItems="center">
          {children}
        </Box>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="subtitleSmall">{label}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

CardSelectionInline.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default CardSelectionInline;
