import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';
import { colors } from '@loggi/front-design-tokens';

export default function DetailsItem({ title, value }) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography
        variant="bodyTextMedium"
        color={colors.neutrals['on-surface-variant']}
      >
        {title}
      </Typography>
      <Typography
        variant="bodyTextMedium"
        color={colors.neutrals['on-surface-variant']}
      >
        {value}
      </Typography>
    </Stack>
  );
}

DetailsItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};
