import { captureException } from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getWaitingPickupUseCase } from 'domain/use-cases';

export const useWaitingPickup = companyId => {
  const queryKey = ['waiting-pickup', String(companyId)];
  const queryClient = useQueryClient();

  const setWaitingPickup = waitingPickup => {
    queryClient.setQueryData(queryKey, waitingPickup);
  };

  const query = useQuery(queryKey, () => getWaitingPickupUseCase(companyId), {
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    onError(err) {
      captureException(err);
    }
  });

  return {
    setWaitingPickup,
    ...query
  };
};

export default { useWaitingPickup };
