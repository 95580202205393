import { getAndroidNativeAppVersion } from 'mobile/versioning/native-app-version';
import { isAndroid } from './native.helpers';

export const createSwitchToNativeEvent = ({ idToken, hasBeyondAccess }) => {
  if (isAndroid()) {
    const androidVersion = getAndroidNativeAppVersion();
    if (!androidVersion) return { idToken };
  }

  return {
    idToken,
    hasBeyondAccess
  };
};

export default { createSwitchToNativeEvent };
