import { Box } from '@mui/material';
import { PAYMENT_STATUSES } from 'constants/index';
import {
  NOT_RENDER_CANCEL_BUTTON,
  TRACKING_STATUS
} from 'constants/tracking.constants';
import { useSnackbar } from 'hooks';
import { useCompanyData } from 'hooks/company-data/company-data.hook';
import { useSupport } from 'hooks/support/support.hook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as ROUTES from 'routes/routes';
import { grey, root } from 'theme/colors';
import {
  InfiniteLoading,
  ShipmentCancelDrawer,
  ShipmentCanceledDrawer
} from 'UI/components';
import Tabs from 'UI/components/tabs/tabs.component';
import { fillCompanyId } from 'utils';
import StatusTrackingDetailsCard from 'UI/pages/tracking/details-card/status-tracking-details-card.component';
import TrackingDeliveryInfo from 'UI/pages/tracking/delivery-info/delivery-info.component';
import TrackingHistory from 'UI/pages/tracking/history/history.component';
import DropOffDeliveryInfo from 'UI/pages/tracking/drop-off-delivery-info/drop-off-delivery-info.component';
import PackageDetail from 'UI/pages/tracking/package-detail/package-detail.component';
import TitleBar from 'UI/pages/tracking/title-bar/title-bar.component';
import { useTrackingSummaryCachedData } from 'hooks/tracking-summary/tracking-summary.hook';
import ShipmentCancelled from 'crm/entities/events/shipment-cancelled/shipment-cancelled';
import CancelButton from './cancel-button/cancel-button.component';
import {
  useCancelation,
  useCancelationDrawer,
  useConfirmationDrawer
} from './cancelation.hook';
import ErrorPage from './error/error.page';
import LoadingPage from './loading/loading.page';
import PaymentDetails from './payment-details/payment-details.component';
import { useTracking } from './tracking.hook';
import IndispatchDeliveryInfo from './indispatch-delivery-info/indispatch-delivery-info.component';

const TABS = {
  DELIVERY: 'DELIVERY',
  PICKUP: 'PICKUP'
};

const useCompanySecretId = companyId => {
  const { data } = useCompanyData(companyId);

  return data?.secretId;
};

const isKnownPaymentStatus = tracking => {
  return tracking?.paymentDetails?.status in PAYMENT_STATUSES;
};

const defaultTabByStatus = status => {
  if (status === TRACKING_STATUS.pickupScheduled) return TABS.PICKUP;
  return TABS.DELIVERY;
};

const firstName = name => name?.split(' ')[0] ?? '';

export const Separator = () => <Box height="16px" bgcolor={grey[50]} />;

const getPickupScheduledUpdatedDate = (trackingHistory = []) => {
  const pickupScheduledTrackingHistory = trackingHistory.find(
    i => i.occasionalShipperStatusCode === TRACKING_STATUS.pickupScheduled
  );

  return pickupScheduledTrackingHistory?.updateTime;
};

export default function TrackingPage() {
  const { t } = useTranslation('ui');
  const { companyId, loggiKey } = useParams();
  const history = useHistory();
  const showSnackbar = useSnackbar();
  const { tracking, isLoading, isError, reFetch } = useTracking();
  const trackingSummary = useTrackingSummaryCachedData(companyId);
  const home = fillCompanyId({ route: ROUTES.newHome, companyId });
  const isTheOnlyShipment = trackingSummary?.length === 1;
  const confirmationDrawer = useConfirmationDrawer({
    onClose: () => {
      if (isTheOnlyShipment) {
        const path = home;

        return history.push(path);
      }
      return history.push(
        fillCompanyId({ companyId, route: ROUTES.myShipmentsV2 })
      );
    }
  });
  const packageCancellation = useCancelation({
    onSuccess: () => {
      confirmationDrawer.show();
    },
    onError: () => {
      showSnackbar({
        message: t('trackingPage.error.shipmentCancelError'),
        severity: 'error'
      });
    }
  });
  const cancelationDrawer = useCancelationDrawer({
    onConfirm: () => {
      new ShipmentCancelled({ loggiKey }).sendToCrm();
      packageCancellation.cancel({ companyId, loggiKey });
    }
  });

  const trackingKey = tracking?.trackingCode;
  const occasionalTrackingStatus = tracking?.status.occasionalShipperStatusCode;
  const support = useSupport({
    companyId,
    loggiKey,
    trackingKey,
    occasionalTrackingStatus
  });

  const secretId = useCompanySecretId(companyId);

  const shouldShowPaymentStatus = isKnownPaymentStatus(tracking);

  if (isError) return <ErrorPage onTryAgain={reFetch} />;
  if (isLoading) return <LoadingPage />;

  const {
    pickupAddress,
    receiverName,
    destinationAddress,
    trackingCode,
    paymentDetails,
    insuranceValue,
    status,
    trackingHistory,
    senderName,
    shipmentCanBeCanceled,
    packageDetails,
    packagePickupTraits,
    billingInvoice,
    senderPostalCode,
    walletInformation
  } = tracking;

  const trackerUrl = secretId
    ? `${process.env.REACT_APP_SHARE_TRACKER_URL}/${secretId}/${trackingCode}`
    : '';

  const title = t('trackingPage.title', { name: firstName(receiverName) });

  const isCancelButtonVisible = !NOT_RENDER_CANCEL_BUTTON.includes(
    status.occasionalShipperStatusCode
  );

  const isDropOffPackage = tracking.isDropoff();

  const isIndispatchPackage = tracking.isIndispatch();

  const pickupScheduledUpdatedDate =
    isDropOffPackage && getPickupScheduledUpdatedDate(trackingHistory);

  const handleCancelButtonClick = e => {
    if (shipmentCanBeCanceled) {
      cancelationDrawer.show();
      e.preventDefault();
    }
  };

  const handleActionClick = () => {
    return history.push(support.supportPage);
  };

  const renderPickupTab = () => {
    if (isDropOffPackage)
      return (
        <DropOffDeliveryInfo
          senderPostalCode={senderPostalCode}
          pickupScheduledUpdatedDate={pickupScheduledUpdatedDate}
        />
      );

    if (isIndispatchPackage) return <IndispatchDeliveryInfo />;

    return <TrackingDeliveryInfo name={senderName} address={pickupAddress} />;
  };

  return (
    <Box bgcolor={root[0]} data-testid="tracking-page">
      {packageCancellation.isLoading && (
        <InfiniteLoading title={t('trackingPage.cancelLoadingMessage')} />
      )}
      <ShipmentCancelDrawer
        open={cancelationDrawer.isShowing}
        onDismiss={cancelationDrawer.dismiss}
        onConfirm={cancelationDrawer.confirm}
      />
      <ShipmentCanceledDrawer
        open={confirmationDrawer.isShowing}
        onClose={confirmationDrawer.dismiss}
      />
      <TitleBar
        title={title}
        trackerUrl={trackerUrl}
        onSupport={support.redirect}
        fromTrackingPage
      />
      <Box width="100%">
        <StatusTrackingDetailsCard
          status={status.occasionalShipperStatusCode}
          label={status.label}
          description={status.title}
          additionalInformation={status.description}
          actionDescription={status.actionRequired}
          onActionClick={handleActionClick}
          isIndispatch={isIndispatchPackage}
        />
      </Box>

      <Box pt={2}>
        <Tabs
          initialTab={defaultTabByStatus(status.occasionalShipperStatusCode)}
          tabs={[
            {
              label: t('trackingPage.delivery'),
              value: TABS.DELIVERY,
              component: (
                <TrackingDeliveryInfo
                  name={receiverName}
                  address={destinationAddress}
                />
              )
            },
            {
              label: t('trackingPage.pickup'),
              value: TABS.PICKUP,
              component: renderPickupTab()
            }
          ]}
        />
      </Box>

      <Separator />

      <Box p={3}>
        <PackageDetail
          packageDetails={packageDetails}
          packagePickupTraits={packagePickupTraits}
          trackingCode={trackingCode}
          insuredValue={insuranceValue}
          paymentAmount={walletInformation?.amount || paymentDetails?.amount}
        />
      </Box>
      <Separator />

      {shouldShowPaymentStatus && (
        <Box>
          <Box p={3}>
            <PaymentDetails
              paymentDetails={paymentDetails}
              billingInvoice={billingInvoice}
            />
          </Box>
          <Separator />
        </Box>
      )}

      <TrackingHistory
        trackingHistory={trackingHistory}
        isDropoffPackage={isDropOffPackage}
        isIndispatchPackage={isIndispatchPackage}
      />

      {isCancelButtonVisible && (
        <CancelButton
          href={support.supportPage}
          onClick={handleCancelButtonClick}
        />
      )}
    </Box>
  );
}
