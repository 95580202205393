/* eslint-disable import/prefer-default-export */
import NativeCopyToClipboardNotImplementedError from 'erros/native-copy-to-clipboard-not-implemented.error';
import { copyToClipboardNative, hasEventRegistered } from 'mobile';

const hasCopyToClipboardEventOnBridge = () =>
  hasEventRegistered('copyToClipboard');

export const copyToClipboard = async text => {
  try {
    return await navigator.clipboard.writeText(text);
  } catch (err) {
    if (!hasCopyToClipboardEventOnBridge()) {
      throw new NativeCopyToClipboardNotImplementedError();
    }

    return copyToClipboardNative(text);
  }
};
