import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Hidden } from '@mui/material';
import PropTypes from 'prop-types';
import { ValidatorBuilder } from 'infra';
import { useSnackbar } from 'hooks';
import { BaseDrawer, StepFormWrapper, BaseDialog } from 'UI/components';
import { useUserRecords } from 'UI/contexts';
import { ReactComponent as ReactionEita } from 'assets/eita.svg';
import companiesAccountApi from 'service/companies-account-api';
import CRMUser from 'crm/entities/user/crm-user.model';
import AccountDocumentInformed from 'crm/entities/events/account-document-informed/account-document-informed';

const CnpjStepForm = ({ handleOnNextStep }) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();
  const showSnackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const fields = {
    cnpj: {
      label: t('signUpCompany.input.cnpj.label'),
      type: 'cnpj',
      value: userRecords.cnpj,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .cnpjPattern(t('signUpCompany.input.cnpj.errorMessage'))
        .build()
    },
    sharedName: {
      label: t('signUpCompany.input.sharedName.label'),
      type: 'text',
      value: userRecords.sharedName,
      validator: new ValidatorBuilder().nonEmptyString().build()
    }
  };

  const getIsNoL4BStatus = error => {
    return error.message.includes('accountableLoggiDoesNotExist');
  };

  const getErrorMessage = useCallback(
    error => {
      let errorMessage = t('signUpCompany.errorMessages.default');
      const parsedError = error.message.toString().toLowerCase();

      if (error.message.includes('CompanyAlreadyExists')) {
        setOpenModal(true);
        errorMessage = null;
      } else if (
        (parsedError.includes('cnpj') && parsedError.includes('invalid')) ||
        parsedError.includes('bigdatacorp') ||
        parsedError.includes('nfe') ||
        error.message.includes('legalPersonModelValidationError')
      ) {
        errorMessage = t('signUpCompany.errorMessages.invalidCnpj');
      } else if (parsedError.includes('sharedname')) {
        errorMessage = t('signUpCompany.errorMessages.invalidSharedName');
      } else if (error.message.includes('accountableLoggiDoesNotExist')) {
        errorMessage = null;
      }

      return errorMessage;
    },
    [t]
  );

  const handleCompanyDataValidation = async userCredentials => {
    const { cnpj, sharedName } = userCredentials;
    const { email } = userRecords;
    const cnpjWithoutMask = cnpj.replace(/[\W]/g, '');

    setIsLoading(true);
    try {
      await companiesAccountApi.validateCompanyData(
        sharedName,
        cnpjWithoutMask
      );
      setUserRecords({ ...userRecords, cnpj, sharedName });
      setIsLoading(false);

      new CRMUser({
        email,
        document: cnpjWithoutMask,
        sharedName
      }).sendToCrm();

      new AccountDocumentInformed({
        ...userRecords,
        sharedName,
        document: cnpjWithoutMask
      }).sendToCrm();

      handleOnNextStep();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      const isNoL4BStatus = getIsNoL4BStatus(error);

      if (errorMessage) {
        showSnackbar({ message: getErrorMessage(error), severity: 'error' });
      }

      if (isNoL4BStatus) {
        setUserRecords({ ...userRecords, cnpj, sharedName, noL4BStatus: true });
        handleOnNextStep();
      }
      setIsLoading(false);
    }
  };

  const handleSubmit = userCredentials =>
    handleCompanyDataValidation(userCredentials);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <StepFormWrapper
        fields={fields}
        onSubmit={handleSubmit}
        buttonLabel={t('signUpCompany.submitButton.label')}
        hasCheckbox={false}
        isLoading={isLoading}
      />
      <Hidden smUp>
        <BaseDrawer
          variant="temporary"
          description={t('signUpCompany.companyAlreadyExistsModal.description')}
          illustration={<ReactionEita />}
          isOpen={openModal}
          labels={{
            confirm: t(
              'signUpCompany.companyAlreadyExistsModal.confirmButtonLabel'
            )
          }}
          onConfirmClick={() => setOpenModal(false)}
          title={t('signUpCompany.companyAlreadyExistsModal.title')}
        />
      </Hidden>
      <Hidden smDown>
        <BaseDialog
          isOpen={openModal}
          labels={{
            confirm: t(
              'signUpCompany.companyAlreadyExistsModal.confirmButtonLabel'
            )
          }}
          onConfirm={() => setOpenModal(false)}
        >
          <Box
            gap={3}
            alignItems="center"
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <ReactionEita />
            <Typography variant="subtitle1">
              {t('signUpCompany.companyAlreadyExistsModal.title')}
            </Typography>
            <Typography variant="body2">
              {t('signUpCompany.companyAlreadyExistsModal.description')}
            </Typography>
          </Box>
        </BaseDialog>
      </Hidden>
    </Box>
  );
};

CnpjStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default CnpjStepForm;
