import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * @typedef {{
 *   [key: string]: string;
 * }} QueryParams
 *
 * Returns object with query params
 *
 * @returns {QueryParams}
 */
const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params);
  }, [search]);
};

export default useQueryParams;
