import { loggiWebApi } from '@loggi/authentication-lib';

const PY_BEYOND_URL = `${process.env.REACT_APP_PY_BEYOND_URL}/telemetry/v1/`;

/**
 * @param {object} param
 * @param {string} param.message
 * @param {object} param.extra
 * @returns {Promise<void>}
 */
export default function sendTelemetryData({ message, extra }) {
  return loggiWebApi
    .url(PY_BEYOND_URL, true)
    .put({ message, extra })
    .res();
}
