import { createContext, useContext } from 'react';

/**
 * @deprecated call the _useCase_ itself instead.
 * See {@link useDomainUseCase} for more details
 */
export const DomainUseCasesContext = createContext();

/**
 * @deprecated call the _useCase_ itself instead. For example:
 * ```js
 * // before
 * import { useDomainUseCase } from 'UI/contexts';
 * const sharingUseCase = useDomainUseCase('sharingUseCase');
 * sharingUseCase();
 * // after
 * import { sharingUseCase } from 'domain/use-cases';
 * sharingUseCase();
 * ```
 * @see {@link https://github.com/loggi/ui/blob/g/create-beyond-guidelines/targets/beyond/docs/GUIDELINES.md | GUIDELINES.md} fore more details about guidelines
 */
export function useDomainUseCase(useCaseId) {
  const useCases = useContext(DomainUseCasesContext);
  return useCases[useCaseId];
}
