/* eslint-disable prefer-promise-reject-errors */
import { generateRandomString as generateUUID } from '@aws-amplify/core';
import { getFsValueUseCase } from 'domain/use-cases/get-fs-value/get-fs-value.use-case';

const ANDROID_BRIDGE_METHOD_NOT_FOUND_ERROR = 'Method not found';

/** @type {{promiseId: {resolve: (value) => void, reject: (reason?) => void}}} */
const promises = {};

// these functions are called by native methods
function resolvePromise(promiseId, value) {
  promises[promiseId].resolve(value);
  delete promises[promiseId];
}
function rejectPromise(promiseId, reason) {
  promises[promiseId].reject(reason);
  delete promises[promiseId];
}
window.resolvePromise = resolvePromise;
window.rejectPromise = rejectPromise;

const hasIOSWebInterface = !!window.webkit?.messageHandlers;
const hasAndroidWebInterface = !!window.BeyondWebInterface;

export const hasWebViewInterface = () =>
  hasIOSWebInterface || hasAndroidWebInterface;

export const hasEventRegistered = eventName =>
  !!window.webkit?.messageHandlers?.[eventName] ||
  !!window.BeyondWebInterface?.hasEvent?.(eventName);

export const hasCreateNativeCorpOrder = () =>
  hasEventRegistered('switchToNativeApp') &&
  hasEventRegistered('fillNativeCorpOrder');

const handlers = {
  noop: () => {},
  iOS: (event, message, promiseId) => {
    const payload = JSON.stringify({ promiseId, ...message });
    window.webkit.messageHandlers[event]?.postMessage(payload);
  },
  Android: (event, message, promiseId) => {
    const payload = JSON.stringify(message);
    // attemp to send payload in new format (with JS promise support)
    try {
      window.BeyondWebInterface?.sendEvent(event, payload, promiseId);
    } catch (err) {
      if (err.message === ANDROID_BRIDGE_METHOD_NOT_FOUND_ERROR)
        window.BeyondWebInterface?.sendEvent(event, payload);
      else throw err;
    }
  }
};

const nativeAppEventBridge =
  (hasIOSWebInterface && handlers.iOS) ||
  (hasAndroidWebInterface && handlers.Android) ||
  handlers.noop;

/**
 * @param {string} event
 * @param {*} message
 */
const nativeAppEventSender = (event, message) => {
  return new Promise((resolve, reject) => {
    const promiseId = generateUUID();
    promises[promiseId] = { resolve, reject };
    nativeAppEventBridge(event, message, promiseId);
  });
};

/** @param {{idToken: String, hasBeyondAccess: Boolean}} payload */
export const switchToNativeApp = payload =>
  nativeAppEventSender('switchToNativeApp', payload);

/** @typedef {{lat, lng, description, complement, addressComponents: []}} Address */
/**
 * @param {{
 *  idToken: String,
 *  hasBeyondAccess: Boolean,
 *  pickup: { address: Address, phone: String, instructionText: String },
 *  delivery: { address: Address, phone: String, instructionText: String }
 * }} payload
 */
export const createNativeCorpOrder = payload => {
  const { idToken, hasBeyondAccess } = payload;
  const { pickup, delivery } = payload;

  return nativeAppEventSender('switchToNativeApp', {
    idToken,
    hasBeyondAccess
  })
    .then(() =>
      nativeAppEventSender('fillNativeCorpOrder', { pickup, delivery })
        .then(value => value ?? {}) // ensure compatability with older native devices: expected Object but they answer undefined)
        .catch(error =>
          // eslint-disable-next-line
          Promise.reject({ error, origin: 'fillNativeCorpOrder' })
        )
    )
    .catch(error => {
      if (error.origin) return Promise.reject(error); // if already treated by fillNativeCorpOrder.catch(), don't overwrite it
      // eslint-disable-next-line
      return Promise.reject({ error, origin: 'switchToNativeApp' });
    });
};

/**
 * Copy a text to clipboard using native implementation
 *
 * @param {String} text
 * @returns {Promise}
 */
export const copyToClipboardNative = text =>
  nativeAppEventSender('copyToClipboard', { text });

/**
 * Send an event to native Analytics
 * @param {String} event event name
 */
export const logNativeAnalyticsEvent = async event => {
  if (hasEventRegistered('logAnalyticsEvent'))
    await nativeAppEventSender('logAnalyticsEvent', { event });
};

/**
 * Send an event to singular analytics
 * @param {{event: String, att: Object.<string, string>?}} payload
 */
export const logSingularAnalyticsEvent = async payload => {
  const enableSendEventsToSingular = await getFsValueUseCase(
    'enable_send_events_to_singular'
  );
  if (!enableSendEventsToSingular) return;

  if (hasEventRegistered('logSingularAnalyticsEvent'))
    await nativeAppEventSender('logSingularAnalyticsEvent', payload);
};

/**
 * Send an event to singular revenue
 * @param {{
 * event: String,
 * currency: String,
 * amount: Double,
 * att: Object.<string, string>?
 * }} payload
 */
export const logSingularRevenueEvent = async payload => {
  const enableSendRevenueEventsToSingular = await getFsValueUseCase(
    'enable_send_revenue_events_to_singular'
  );
  if (!enableSendRevenueEventsToSingular) return;

  if (hasEventRegistered('logSingularRevenueEvent'))
    await nativeAppEventSender('logSingularRevenueEvent', payload);
};

/**
 * Sets the user on Singular native SDK
 * @param {string} identifier
 */
export const setSingularUserId = async identifier => {
  if (hasEventRegistered('setSingularUserId'))
    await nativeAppEventSender('setSingularUserId', { identifier });
};

/**
 * Send an event to update user object in Insider SDK
 * @param {{
 * email: String,
 * companyId: String,
 * }} payload
 */
export const updateInsiderUserObject = async payload => {
  if (hasEventRegistered('updateInsiderUserObject'))
    await nativeAppEventSender('updateInsiderUserObject', payload);
};

/**
 * Send an event to logout user in Insider SDK
 */
export const logoutInsiderUser = async () => {
  if (hasEventRegistered('logoutInsiderUser'))
    await nativeAppEventSender('logoutInsiderUser', {});
};

export default nativeAppEventSender;
