import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link, Typography, ListItem, List } from '@mui/material';
import { Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { blue, root } from 'theme/colors';
import { Button, SvgIcon } from 'UI/components';
import { useShipmentDispatcher } from 'UI/shipment/state-machine/context';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { Header } from './header/header.component';
import { getCardsInfo } from './onboard.helper';
import { carouselStyles } from './onboard.styled';
import 'swiper/swiper-bundle.min.css';

export const OnboardingPage = () => {
  const { t } = useTranslation('ui');
  const styles = carouselStyles();
  const initialCardIndex = 0;
  const [sliderIndex, setSliderIndex] = useState(initialCardIndex);
  const [slider, setSlider] = useState(null);
  const shipmentDispatcher = useShipmentDispatcher();
  const cardsInfo = getCardsInfo({ serviceName: 'indispatch' });
  const lastCard = sliderIndex === cardsInfo.length - 1;
  const firstCard = sliderIndex === initialCardIndex;

  const externalIndispatchFaqURL = useFeatureSwitch(
    'external_indispatch_faq_url'
  ).data;

  const handleClick = ({ method }) => {
    if (method === 'prev' && !firstCard) return slider?.slidePrev();
    if (method === 'next' && !lastCard) return slider?.slideNext();
    if (method === 'prev') return shipmentDispatcher.back();
    return shipmentDispatcher.continue();
  };

  return (
    <>
      <Header
        handlePrev={() => handleClick({ method: 'prev' })}
        handleClose={shipmentDispatcher.back}
        title={t('onboardPage.header.indispatchTitle')}
      />
      <Box px={4} minHeight="calc(100vh - 120px)" pb={2}>
        <Swiper
          className={styles.customPagination}
          initialSlide={initialCardIndex}
          modules={[Pagination, Virtual]}
          onSlideChange={({ activeIndex }) => setSliderIndex(activeIndex)}
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={30}
          style={{
            padding: '36px 0'
          }}
          onSwiper={setSlider}
        >
          {cardsInfo.map(({ icon, infoDescription, listInformation }) => (
            <SwiperSlide key={icon}>
              <Box mb={6}>
                <Box mb={3}>
                  <SvgIcon icon={icon} size="45" />
                </Box>
                <Box mb={3}>
                  <Typography variant="h2" fontWeight={500}>
                    <Trans t={t} i18nKey={infoDescription} />
                  </Typography>
                </Box>
                <List sx={{ listStyleType: 'disc', pl: 3, mb: 1 }}>
                  {listInformation?.map(item => (
                    <ListItem
                      key={item}
                      disableGutters
                      sx={{ display: 'list-item' }}
                    >
                      <Typography variant="body1">{t(item)}</Typography>
                    </ListItem>
                  ))}
                </List>
                <Link
                  href={externalIndispatchFaqURL}
                  color="primary"
                  underline="none"
                  target="_blank"
                  display="flex"
                  flexWrap="wrap"
                >
                  <Typography variant="body1" sx={{ fontWeight: '600' }}>
                    {t('onboardPage.cardInfo.indispatchFaq')}
                  </Typography>
                  <CallMadeIcon sx={{ height: '20px', paddingLeft: '8px' }} />
                </Link>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <Button
          label={
            lastCard
              ? t('onboardPage.button.continue')
              : t('onboardPage.button.next')
          }
          fullWidth
          onClick={() => handleClick({ method: 'next' })}
          textColor={lastCard ? root[0] : blue[500]}
          variant={lastCard ? 'contained' : 'outlined'}
        />
      </Box>
    </>
  );
};

export default { OnboardingPage };
