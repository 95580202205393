import { Workbox } from 'workbox-window';

export function register() {
  if (process.env.REACT_APP_ENABLE_SERVICE_WORKER !== 'true') {
    return;
  }

  if ('serviceWorker' in navigator) {
    const wb = new Workbox(`${process.env.PUBLIC_URL}/service-worker.js`);

    // Register the service worker
    wb.register();
  }
}

export default { register };
