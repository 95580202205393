import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Stack } from '@mui/material';

export const TagInfo = ({ trackingCode, tagSentByMail }) => {
  const { t } = useTranslation('ui');
  return (
    <Box
      pb={spacing.inset.large}
      px={spacing.inset.large}
      pt={spacing.inset.medium}
    >
      <Stack>
        <Typography pb={spacing.stack.xxxsmall} variant="subtitleLarge">
          {t('shipmentDetailsV2.tagInfo.title')}
        </Typography>
        <Stack>
          <Typography variant="bodyTextMedium">
            {t('shipmentDetailsV2.tagInfo.trackingCode')}
          </Typography>
          <Typography variant="headingMedium">{trackingCode}</Typography>
        </Stack>
        {tagSentByMail && (
          <Typography pt={spacing.stack.xxxsmall} variant="subtitleSmall">
            {t('shipmentDetailsV2.tagInfo.tagSentByEmail')}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

TagInfo.defaultProps = {
  tagSentByMail: false
};

TagInfo.propTypes = {
  trackingCode: PropTypes.string.isRequired,
  tagSentByMail: PropTypes.bool
};

export default TagInfo;
