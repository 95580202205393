import { Box } from '@mui/material';
import { useSnackbar as useNotistackSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { Snackbar } from './snackbar.component';

export const useSnackbar = () => {
  const { closeSnackbar, enqueueSnackbar } = useNotistackSnackbar();
  /** @param {{ severity: import("@mui/material/Alert/Alert").AlertColor }} */
  const showSnackbar = ({
    buttonLabel,
    message,
    severity,
    onClose = () => {}
  }) => {
    const handleSnackbarClick = key => () => {
      closeSnackbar(key);
      onClose();
    };

    const options = {
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      content: (key, textMessage) => (
        <Box width="100%" zIndex={999999999}>
          <Snackbar
            buttonLabel={buttonLabel}
            key={key}
            data-gtm-error={severity === 'error' ? textMessage : undefined}
            message={textMessage}
            onClose={handleSnackbarClick(key)}
            severity={severity}
          />
        </Box>
      )
    };
    enqueueSnackbar(message, options);
  };
  return useCallback(showSnackbar, [closeSnackbar, enqueueSnackbar]);
};

export default { useSnackbar };
