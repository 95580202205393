import React, { useState, useEffect } from 'react';
import { Box, Skeleton, Typography, Button } from '@mui/material';
import { Drawer, Dialog, useSnackbar } from '@loggi/front-design-system';
import { calculatePostingLimitForDetails } from 'UI/shipments-v2/utils/date.utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { colors, spacing } from '@loggi/front-design-tokens';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import { useShipmentDetails } from 'UI/shipments-v2/hooks/use-shipment-details.hook';
import {
  getServiceType,
  handleBillingInvoiceClick
} from 'UI/shipments-v2/utils/helpers.utils';
import { TRACKING_STATUS } from 'constants/tracking.constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useProofOfDelivery } from 'UI/shipments-v2/hooks/use-proof-of-delivery.hook';
import { useCancelation } from 'UI/pages/tracking/cancelation.hook';
import ShipmentCancelled from 'crm/entities/events/shipment-cancelled/shipment-cancelled';
import { useSupport } from 'hooks';
import { ShipmentDetailsHeader } from '../shipment-details-header/shipment-details-header.component';
import { ShipmentDetailsHistory } from '../shipment-details-history/shipment-details-history.component';
import { ShipmentDetailsInfo } from '../shipment-details-info/shipment-details-info.component';
import { ShipmentDetailsAccordions } from '../shipment-details-accordions/shipment-details-acordions.component';
import { ShipmentDetailsNeedHelp } from '../shipment-details-need-help/shipment-details-need-help.component';
import { ShipmentDetailsCancelShipment } from '../shipment-details-cancel-shipment/shipment-details-cancel-shipment.component';
import { ShipmentDetailsPaymentInfo } from '../shipment-details-payment-info/shipment-details-payment-info.component';
import { ShipmentDetailsPickupInfo } from '../shipment-details-pickup-info/shipment-details-pickup-info.component';
import { ShipmentDetailsDeliveryProblem } from '../shipment-details-delivery-problem/shipment-details-delivery-problem.component';
import { ShipmentDeliveryReceiptDrawer } from '../shipment-delivery-receipt-drawer/shipment-delivery-receipt-drawer.component';

const DrawerSkeleton = ({ isMobileDevice }) => {
  const skeletonWidth = isMobileDevice ? 330 : 400;
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing.stack.medium}
      p={spacing.inset.large}
    >
      <Skeleton variant="rectangular" width={skeletonWidth} height={50} />

      <Skeleton variant="rectangular" width={skeletonWidth} height={150} />

      <Skeleton variant="rectangular" width={skeletonWidth} height={200} />

      <Skeleton variant="rectangular" width={skeletonWidth} height={50} />

      <Skeleton variant="rectangular" width={skeletonWidth} height={50} />
    </Box>
  );
};

DrawerSkeleton.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired
};

function getStatusDescription(t, displayedShipment, isDropoff, isIndispatch) {
  if (
    (isDropoff || isIndispatch) &&
    displayedShipment?.status?.occasionalShipperStatusCode ===
      TRACKING_STATUS.pickupScheduled
  ) {
    return t('shipmentDetailsV2.headerPendingPostStatus', {
      deadline: calculatePostingLimitForDetails(
        displayedShipment?.status?.updateTime
      )
    });
  }

  if (
    displayedShipment?.status?.occasionalShipperStatusCode ===
    TRACKING_STATUS.lost
  ) {
    return displayedShipment?.status?.description;
  }
  return displayedShipment?.status?.actionRequired;
}

export const ShipmentDetailsDrawer = () => {
  const {
    selectedShipment,
    setSelectedShipment,
    isMobileDevice
  } = useShipments();
  const { shipment } = useShipmentDetails(selectedShipment);
  const { companyId } = useParams();

  const { t } = useTranslation('ui');

  const [displayedShipment, setDisplayedShipment] = useState();
  const [showSkeleton, setShowSkeleton] = useState(true);

  const [showDeliveryReceiptDrawer, setShowDeliveryReceiptDrawer] = useState(
    false
  );

  const handleDeliveryReceiptClickBack = () => {
    setShowDeliveryReceiptDrawer(false);
  };

  const handleShowDeliveryReceipt = () => {
    setShowDeliveryReceiptDrawer(true);
  };

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (shipment) {
      setDisplayedShipment(shipment);
      setShowSkeleton(false);
    }
  }, [shipment]);

  const support = useSupport({
    companyId,
    loggiKey: displayedShipment?.loggiKey,
    trackingKey: displayedShipment?.trackingCode,
    occasionalTrackingStatus:
      displayedShipment?.status?.occasionalShipperStatusCode
  });

  const [openDialog, setOpenDialog] = useState(false);

  const openCancelDialog = () => {
    setOpenDialog(true);
  };

  const packageCancellation = useCancelation({
    onSuccess: () => {
      showSnackbar({
        variant: 'positive',
        size: 'small',
        message: t('shipmentDetailsV2.cancelSnackbar.success'),
        lightVariant: true
      });
      setDisplayedShipment(null);
      setShowSkeleton(true);
      setSelectedShipment({});
      setOpenDialog(false);
    },
    onError: () => {
      showSnackbar({
        variant: 'negative',
        size: 'small',
        lightVariant: true,
        message: t('shipmentDetailsV2.cancelSnackbar.error')
      });
    }
  });

  const cancelShipment = () => {
    new ShipmentCancelled({
      loggiKey: displayedShipment?.loggiKey
    }).sendToCrm();
    packageCancellation.cancel({
      companyId,
      loggiKey: displayedShipment?.loggiKey
    });
  };

  const isDropoff = displayedShipment?.isDropoff();
  const isIndispatch = displayedShipment?.isIndispatch();

  const showLoggiContactInfo =
    displayedShipment &&
    !isDropoff &&
    !isIndispatch &&
    displayedShipment.status.occasionalShipperStatusCode ===
      TRACKING_STATUS.pickupAllocated;

  const showLoggiChangeDeliveryAddress =
    displayedShipment &&
    displayedShipment.status.occasionalShipperStatusCode ===
      TRACKING_STATUS.problemNeedMoreInfo;

  const showLostPackageInfo =
    displayedShipment &&
    displayedShipment.status.occasionalShipperStatusCode ===
      TRACKING_STATUS.lost;

  const showBillingInvoice =
    displayedShipment &&
    displayedShipment.billingInvoice?.length > 0 &&
    !isIndispatch &&
    displayedShipment.status.occasionalShipperStatusCode ===
      TRACKING_STATUS.delivered;

  const [proofOfDelivery, setProofOfDelivery] = useState({});

  const { data: proofOfDeliveryData } = useProofOfDelivery(
    companyId,
    displayedShipment?.trackingCode,
    displayedShipment?.status?.occasionalShipperStatusCode
  );

  useEffect(() => {
    setProofOfDelivery(proofOfDeliveryData);
  }, [proofOfDeliveryData]);

  const {
    recipientName,
    recipientDocument,
    address,
    locationDescription,
    links = [],
    deliveredTime
  } = proofOfDelivery;

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const statusDescription = getStatusDescription(
    t,
    displayedShipment,
    isDropoff,
    isIndispatch
  );

  return (
    <>
      <Dialog
        mobile={isMobileDevice}
        handleClose={handleCloseDialog}
        open={openDialog}
      >
        <Box py={spacing.inset.medium} px={spacing.inset.xxlarge}>
          <Typography pb={spacing.stack.xxxsmall} variant="subtitleLarge">
            {t('shipmentDetailsV2.cancelDialog.title')}
          </Typography>
          <div>
            <Typography
              sx={{ display: 'inline' }}
              variant="bodyHighlightMedium"
            >
              {t('shipmentDetailsV2.cancelDialog.text')}
            </Typography>{' '}
            <Typography sx={{ display: 'inline' }} variant="bodyTextMedium">
              {t('shipmentDetailsV2.cancelDialog.auxText')}
            </Typography>
          </div>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobileDevice ? 'column' : 'row'}
          justifyContent={isMobileDevice ? 'center' : 'flex-end'}
          py={spacing.inset.medium}
          px={spacing.inset.xxlarge}
          gap={spacing.inline.xxsmall}
        >
          <Button
            onClick={handleCloseDialog}
            variant={isMobileDevice ? 'contained' : 'blank'}
          >
            {t('shipmentDetailsV2.cancelDialog.cancelButton')}
          </Button>
          <Button
            onClick={cancelShipment}
            variant={isMobileDevice ? 'blank' : 'contained'}
          >
            {t('shipmentDetailsV2.cancelDialog.confirmButton')}
          </Button>
        </Box>
      </Dialog>
      {displayedShipment && (
        <ShipmentDeliveryReceiptDrawer
          senderName={displayedShipment?.senderName}
          serviceType={getServiceType(
            displayedShipment?.isIndispatch,
            displayedShipment?.isDropoff
          )}
          companyId={companyId}
          trackingCode={displayedShipment?.trackingCode}
          open={showDeliveryReceiptDrawer}
          handleClickBack={handleDeliveryReceiptClickBack}
          recipientName={recipientName}
          recipientDocument={recipientDocument}
          address={address}
          locationDescription={locationDescription}
          links={links}
          deliveredTime={deliveredTime}
        />
      )}
      <Drawer
        open={
          Object.keys(selectedShipment).length > 0 && !showDeliveryReceiptDrawer
        }
        closeButton
        mobile={isMobileDevice}
        onClickClose={() => {
          setDisplayedShipment(null);
          setShowSkeleton(true);
          setSelectedShipment({});
          setProofOfDelivery({});
        }}
      >
        <Box
          width={isMobileDevice ? '100%' : '480px'}
          bgcolor={colors.neutrals['on-surface-inverse']}
          py={spacing.inset.xxxsmall}
        >
          {showSkeleton && <DrawerSkeleton isMobileDevice={isMobileDevice} />}
          {!showSkeleton && displayedShipment && (
            <Box>
              <ShipmentDetailsHeader
                packageType={getServiceType(
                  displayedShipment.isIndispatch,
                  displayedShipment.isDropoff
                )}
                statusDescription={statusDescription}
              />
              {showLoggiContactInfo && (
                <ShipmentDetailsPickupInfo
                  type="neutral"
                  message={t('shipmentDetailsV2.pickupSoon')}
                  supportPage={support.supportPage}
                  isMobileDevice={isMobileDevice}
                />
              )}
              {showLoggiChangeDeliveryAddress && (
                <ShipmentDetailsPickupInfo
                  message={t('shipmentDetailsV2.addressChange')}
                  supportPage={support.supportPage}
                  isMobileDevice={isMobileDevice}
                />
              )}
              {showLostPackageInfo && (
                <ShipmentDetailsPickupInfo
                  message={t('shipmentDetailsV2.packageValue')}
                  supportPage={support.supportPage}
                  isMobileDevice={isMobileDevice}
                />
              )}
              <ShipmentDetailsHistory
                history={displayedShipment.trackingHistory}
                additionalInformation={
                  displayedShipment.status.additionalInformation
                }
                statusLabel={displayedShipment.status.label}
                statusCode={
                  displayedShipment.status.occasionalShipperStatusCode
                }
                description={displayedShipment.status.description}
              />
              <ShipmentDetailsInfo
                isDropoff={isDropoff}
                isIndispatch={isIndispatch}
                trackingCode={displayedShipment.trackingCode}
                status={displayedShipment.status.occasionalShipperStatusCode}
                pickupStatus={displayedShipment.status.description}
                senderPostalCode={displayedShipment.senderPostalCode}
                receiverName={displayedShipment.receiverName}
                destinationAddress={displayedShipment.destinationAddress}
                handleShowDeliveryReceipt={handleShowDeliveryReceipt}
                companyId={companyId}
                proofOfDelivery={proofOfDelivery}
                statusUpdateTime={displayedShipment.status.updateTime}
                correiosTrackingCode={displayedShipment.correiosTrackCode}
                loggiKey={displayedShipment.loggiKey}
                isMobileDevice={isMobileDevice}
              />
              <ShipmentDetailsAccordions
                isDropoff={isDropoff}
                isIndispatch={isIndispatch}
                trackingCode={displayedShipment.trackingCode}
                status={displayedShipment.status.occasionalShipperStatusCode}
                receiverName={displayedShipment.receiverName}
                destinationAddress={displayedShipment.destinationAddress}
                packageDetails={displayedShipment.packageDetails}
                isPackaged={displayedShipment.packagePickupTraits.isPackaged}
                pickupAddress={displayedShipment.pickupAddress}
                senderPhone={displayedShipment.senderPhone}
                senderName={displayedShipment.senderName}
                receiverPhone={displayedShipment.receiverPhone}
              />
              <ShipmentDetailsPaymentInfo
                totalValue={displayedShipment.paymentDetails?.amount}
                cardLastDigits={
                  displayedShipment.paymentDetails?.paymentDocument?.creditCard
                    ?.lastDigits
                }
                cardBrand={
                  displayedShipment.paymentDetails?.paymentDocument?.creditCard
                    ?.cardBrand
                }
                walletAmount={displayedShipment?.walletInformation?.amount}
              />
              {displayedShipment.status.occasionalShipperStatusCode ===
                TRACKING_STATUS.delivered && (
                <ShipmentDetailsDeliveryProblem
                  supportPage={support.supportPage}
                />
              )}
              <ShipmentDetailsNeedHelp
                isMobileDevice={isMobileDevice}
                supportPage={support.supportPage}
              />
              {displayedShipment.status.occasionalShipperStatusCode ===
                TRACKING_STATUS.pickupScheduled && (
                <ShipmentDetailsCancelShipment
                  handlePackageCancellation={openCancelDialog}
                />
              )}
              {showBillingInvoice && (
                <Box display="flex" p={spacing.inset.xlarge}>
                  <Box flexGrow={1}>
                    <Button
                      sx={{
                        width: '100%'
                      }}
                      variant="contained"
                      onClick={() =>
                        handleBillingInvoiceClick(
                          displayedShipment.billingInvoice,
                          showSnackbar,
                          t
                        )
                      }
                    >
                      {t('shipmentDetailsV2.billingInvoicesButton')}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default ShipmentDetailsDrawer;
