import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS, { NEW_VOLUME_QUALIFICATION_OPTION_STRING } from 'crm/constants';
import {
  formatPhoneNumber,
  getCreationMethod,
  getFirstName,
  getLastName
} from 'crm/utils';

export default class AccountCreationSubmitted extends CRMBaseEvent {
  constructor({
    email,
    name,
    phone,
    cnpj,
    cpf,
    userType,
    sharedName,
    provider,
    cep,
    segmentName,
    averageTicket,
    integrators,
    otherIntegrator,
    shipmentQualification,
    volumeQualification
  }) {
    super();
    this.eventName = EVENTS.ACCOUNT_CREATION_SUBMITTED;
    this.payload = {
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      phone: formatPhoneNumber(phone),
      document: cpf || cnpj,
      user_type: userType,
      shared_name: sharedName,
      cep,
      segment: segmentName,
      average_ticket: averageTicket,
      integrators,
      other_integrator: otherIntegrator,
      shipment_qualification: shipmentQualification,
      volume_qualification: volumeQualification,
      volume_qualification_string:
        NEW_VOLUME_QUALIFICATION_OPTION_STRING[volumeQualification],
      registration_method: getCreationMethod(provider)
    };
  }
}
