import { Box, Grid } from '@mui/material';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { GridButton } from './button-grid/button-grid.component';
import { getButtonColumns } from './buttons-grid.helper';

export const ButtonsGrid = ({
  confirmationButtonId,
  disabled,
  isLoading,
  labels,
  onConfirm,
  onDismiss,
  vertical
}) => {
  const buttonsCount = Object.values(labels).filter(e => e).length;
  const buttonGridColumns = getButtonColumns({
    buttonsCount,
    vertical
  });

  const renderButtons = () => {
    const buttons = [
      <GridButton
        cols={buttonGridColumns}
        disabled={disabled}
        isLoading={isLoading}
        key="dismiss"
        label={labels.dismiss}
        onClick={onDismiss}
        outlined
      />,
      <GridButton
        id={confirmationButtonId}
        cols={buttonGridColumns}
        disabled={disabled}
        isLoading={isLoading}
        key="confirm"
        label={labels.confirm}
        onClick={onConfirm}
      />
    ];
    if (vertical) return [...buttons].reverse();
    return buttons;
  };

  if (buttonsCount === 0) return null;

  return (
    <Box mt={4} width="100%">
      <Grid container spacing={2}>
        {renderButtons()}
      </Grid>
    </Box>
  );
};

ButtonsGrid.defaultProps = {
  confirmationButtonId: '',
  disabled: false,
  isLoading: false,
  onDismiss: noop,
  vertical: false
};

ButtonsGrid.propTypes = {
  confirmationButtonId: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  labels: PropTypes.shape({
    confirm: PropTypes.string,
    dismiss: PropTypes.string
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  vertical: PropTypes.bool
};

export default { ButtonsGrid };
