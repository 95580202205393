import { Box, Card, styled, Typography, useMediaQuery } from '@mui/material';
import { TRACKING_STATUS, VARIANTS_BY_STATUS } from 'constants/index';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { blue, green, grey } from 'theme/colors';
import { shadow } from 'theme/shadows';
import { Button } from 'UI/components/button/button.component';
import PackageStatusDot from 'UI/components/package-status-dot/package-status-dot.component';
import TrackingCardIllustration from 'UI/components/tracking-card-illustration/tracking-card-illustration.component';
import TrackingCodeBar from 'UI/components/tracking-code-bar/tracking-code-bar.component';

export const STYLES_BY_VARIANTS = {
  info: { labelColor: grey[300], color: grey[300] },
  normal: { labelColor: blue[500], color: blue[500] },
  success: { labelColor: green[500], color: green[500] }
};

const cardStyle = ({ color }) => {
  return {
    borderBottom: `18px solid ${color}`,
    boxShadow: shadow.standard,
    boxSizing: 'border-box',
    height: '100%',
    minHeight: '416px'
  };
};

const StatusTrackingCard = ({
  actionDescription,
  onActionClick,
  additionalInformation,
  description,
  additionalDescription,
  label,
  status,
  title,
  trackingCode,
  isDropoff,
  isIndispatch
}) => {
  const { t } = useTranslation('ui');
  const sendTo = title.substr(0, 10);
  const recipientName = title.substr(11);
  const variant = STYLES_BY_VARIANTS[VARIANTS_BY_STATUS[status]];
  const { color, labelColor } = variant;
  const [showTrackingCodeBar, setShowTrackingCodeBar] = useState(false);
  const CustomCard = React.useMemo(() => styled(Card)(cardStyle({ color })), [
    color
  ]);

  const showActionButton =
    status === TRACKING_STATUS.returningToShipper ||
    status === TRACKING_STATUS.returningCompleted;

  const showCorreiosTrackingButton =
    isIndispatch && status === TRACKING_STATUS.processing;

  const handleActionClick = ev => {
    ev.stopPropagation();
    onActionClick();
  };

  const handleCorreiosTrackerButtonClick = ev => {
    ev.stopPropagation();
    const url = process.env.REACT_APP_CORREIOS_TRACKING_URL;
    window.open(url, '_blank', 'noopener');
  };

  useEffect(() => {
    setShowTrackingCodeBar(
      status === TRACKING_STATUS.pickupScheduled && !isDropoff && !isIndispatch
    );
  }, [isDropoff, isIndispatch, status]);

  const isMinScreenSize = useMediaQuery(
    '(min-width: 320px) and (min-height:553px)'
  );

  const illustrationRef = useRef();
  const [illustrationHeight, setIllustrationHeight] = useState(0);
  const minIllustrationHeight = 134;
  const showTrackingCardIllustration =
    illustrationHeight >= minIllustrationHeight;

  useEffect(() => {
    setIllustrationHeight(illustrationRef?.current?.clientHeight);
  }, []);

  return (
    <CustomCard>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        minHeight="398px"
      >
        <Box px={4} pt={4}>
          <Box alignItems="center" display="flex" flexDirection="row">
            <PackageStatusDot status={status} />
            <Box color={labelColor} data-testid="label-id" ml={1}>
              <Typography variant="body1">
                <strong>{label}</strong>
              </Typography>
            </Box>
          </Box>
          <Box data-testid="status-tracking-card.title" py={2.5}>
            <Typography variant="subtitle2">{sendTo}</Typography>
            <Typography variant="h2">{recipientName}</Typography>
          </Box>
          <Typography variant="body1">
            <strong>{description}</strong>
          </Typography>
          <Typography variant="body1">{additionalDescription}</Typography>
          <Box pb={2}>
            <Typography variant="body1">{additionalInformation}</Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontWeight: 'light', color: grey[300] }}
          >
            {additionalDescription}
          </Typography>

          {showActionButton && (
            <Button
              bgColor={color}
              fullWidth
              label={actionDescription}
              onClick={handleActionClick}
            />
          )}
        </Box>
        <Box display="flex" flex="1" flexDirection="column">
          {showTrackingCodeBar && (
            <Box px={4} width="100%">
              <TrackingCodeBar trackingCode={trackingCode.toUpperCase()} />
            </Box>
          )}
          <Box display="flex" flex="1" p={1} ref={illustrationRef}>
            {showCorreiosTrackingButton && (
              <Box display="flex" flex="1" alignItems="flex-end" pl={2} pb={5}>
                <Button
                  data-testid="correios-tracking-button"
                  bgColor={color}
                  label={t('statusTrackingCards.trackingButtonLabel')}
                  onClick={handleCorreiosTrackerButtonClick}
                />
              </Box>
            )}
            {!showCorreiosTrackingButton &&
              showTrackingCardIllustration &&
              isMinScreenSize && (
                <Box display="flex" flex="1" p={1}>
                  <TrackingCardIllustration
                    status={status}
                    isDropoff={isDropoff}
                    isIndispatch={isIndispatch}
                  />
                </Box>
              )}
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};

StatusTrackingCard.propTypes = {
  actionDescription: PropTypes.string,
  onActionClick: PropTypes.func,
  additionalInformation: PropTypes.string,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  trackingCode: PropTypes.string,
  isDropoff: PropTypes.bool.isRequired,
  isIndispatch: PropTypes.bool.isRequired,
  additionalDescription: PropTypes.string
};

StatusTrackingCard.defaultProps = {
  actionDescription: '',
  onActionClick: noop,
  additionalInformation: '',
  trackingCode: '',
  additionalDescription: ''
};

export default StatusTrackingCard;
