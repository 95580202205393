/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Box } from '@mui/material';
import { Button } from 'UI/components';
import { blue } from 'theme/colors';
import { useTranslation } from 'react-i18next';
import { useShipmentFooter } from './context';

export function PrimaryAction({ children, onClick = noop, disabled = false }) {
  const { t } = useTranslation('ui');
  const footer = useShipmentFooter();
  const label = children ?? t('shipmentFooter.continue');

  return (
    <Box
      width="100%"
      sx={{ opacity: disabled && footer.theme === 'dark' ? 0.2 : 1 }}
    >
      <Button
        data-testid="footer-primary-action"
        textColor={disabled ? footer.textColor : undefined}
        bgColor={disabled ? 'white' : undefined}
        onClick={onClick}
        disabled={disabled}
        label={label}
        theme={footer.theme}
        disableShadow
        fullWidth
      />
    </Box>
  );
}

PrimaryAction.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export function SecondaryAction({ children, onClick = noop }) {
  return (
    <Box width="100%">
      <Button
        variant="text"
        data-testid="footer-secondary-action"
        textColor={blue[500]}
        label={children}
        onClick={onClick}
        fullWidth
      />
    </Box>
  );
}

SecondaryAction.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func
};
