import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { useTrackingSummary } from 'hooks/tracking-summary/tracking-summary.hook';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import {
  loggiDesignSystem,
  SnackbarProvider
} from '@loggi/front-design-system';
import {
  GROUPING_OPTIONS,
  GROUPS_AND_STATUS_RELATIONS,
  SHIPMENTS_PER_PAGE_DESKTOP,
  SHIPMENTS_PER_PAGE_MOBILE,
  SHIPMENT_FILTERS_SESSION_STORAGE_KEY,
  SORT_DESC,
  SORT_OPTIONS,
  STATUS_FILTER_OPTIONS
} from '../constants/shipments.constants';
import useFilteredShipments from '../hooks/use-filtered-shipments.hook';
import usePagination from '../hooks/use-pagination.hook';
import useSaveSessionStorageFilters from '../hooks/use-save-session-storage-filters.hook';
import { getNumberOfShipmentsByGroupingOptions } from '../utils/grouping.utils';

export const ShipmentsContext = createContext({
  companyId: 0,
  shipments: [],
  shipmentsPerPage: 0,
  isLoading: true,
  isMobileDevice: true,
  displayedShipments: [],
  selectedShipment: '',
  pagination: {
    page: 1,
    totalPages: 1,
    setPage: () => {}
  },
  statusFilter: [],
  setStatusFilter: () => {},
  grouping: '',
  setGrouping: () => {},
  search: '',
  setSearch: () => {},
  sort: {},
  setSort: () => {},
  numberOfShipmentsByGroup: {
    [GROUPING_OPTIONS.pending]: 0,
    [GROUPING_OPTIONS.pickupPending]: 0,
    [GROUPING_OPTIONS.withProblem]: 0
  },
  isError: false
});

const DEFAULT_SORT = SORT_OPTIONS.find(option => option.value === SORT_DESC);

export const ShipmentsProvider = ({ children }) => {
  const sessionStorageFilterValues = sessionStorage.getItem(
    SHIPMENT_FILTERS_SESSION_STORAGE_KEY
  );

  const parsedSessionStorageFilterValues =
    sessionStorageFilterValues && JSON.parse(sessionStorageFilterValues);

  const [statusFilterValue, setStatusFilterValue] = useState(
    parsedSessionStorageFilterValues?.statusFilterValue || []
  );
  const [searchValue, setSearchValue] = useState(
    parsedSessionStorageFilterValues?.searchValue || ''
  );
  const [sortValue, setSortValue] = useState(
    parsedSessionStorageFilterValues?.sortValue || DEFAULT_SORT
  );
  const [groupingValue, setGroupingValue] = useState(
    parsedSessionStorageFilterValues?.groupingValue || ''
  );
  const { companyId } = useParams();
  const isMobileDevice = checkIsMobile();

  const [isFilterAutoSelected, setIsFilterAutoSelected] = useState(
    parsedSessionStorageFilterValues?.isFilterAutoSelected || false
  );

  const [selectedShipment, setSelectedShipment] = useState(
    parsedSessionStorageFilterValues?.selectedShipment || {}
  );

  const [page, setPage] = useState(parsedSessionStorageFilterValues?.page || 1);

  const setSearch = value => {
    setPage(1);
    setSearchValue(value);
  };

  const setSort = option => {
    setPage(1);
    setSortValue(option);
  };

  const setStatusFilter = options => {
    setPage(1);
    if (isFilterAutoSelected) {
      setGroupingValue('');
    }

    if (!options) {
      setStatusFilterValue([]);
      return;
    }

    setStatusFilterValue(options);
    setIsFilterAutoSelected(false);
  };

  const setGrouping = option => {
    setPage(1);
    if (groupingValue === option) {
      if (isFilterAutoSelected) {
        setStatusFilterValue([]);
      }
      setGroupingValue('');
      return;
    }

    const selectedGroupStatusFilter = GROUPS_AND_STATUS_RELATIONS[option];
    const relatedStatusFilters = STATUS_FILTER_OPTIONS.filter(filter =>
      selectedGroupStatusFilter.includes(filter.value)
    );

    setStatusFilter(relatedStatusFilters);
    setIsFilterAutoSelected(true);

    setGroupingValue(option);
  };

  const shipmentsPerPage = isMobileDevice
    ? SHIPMENTS_PER_PAGE_MOBILE
    : SHIPMENTS_PER_PAGE_DESKTOP;

  const { data = [], isLoading, isError } = useTrackingSummary(companyId);

  const { shipments } = useFilteredShipments(
    data,
    statusFilterValue,
    searchValue,
    groupingValue,
    sortValue
  );

  const numberOfShipmentsByGroup = getNumberOfShipmentsByGroupingOptions(data);

  const { totalPages, displayedData } = usePagination(
    shipments,
    shipmentsPerPage,
    page,
    setPage
  );

  useSaveSessionStorageFilters(
    statusFilterValue,
    sortValue,
    groupingValue,
    searchValue,
    page,
    isFilterAutoSelected,
    selectedShipment
  );

  return (
    <ThemeProvider theme={loggiDesignSystem}>
      <SnackbarProvider>
        <ShipmentsContext.Provider
          value={{
            companyId,
            isMobileDevice,
            shipmentsPerPage,
            sort: sortValue,
            setSort,
            isLoading,
            isError,
            shipments,
            search: searchValue,
            setSearch,
            statusFilter: statusFilterValue,
            setStatusFilter,
            grouping: groupingValue,
            setGrouping,
            displayedShipments: displayedData,
            pagination: {
              page,
              setPage,
              totalPages
            },
            numberOfShipmentsByGroup,
            selectedShipment,
            setSelectedShipment
          }}
        >
          {children}
        </ShipmentsContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

ShipmentsProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useShipments = () => {
  const context = useContext(ShipmentsContext);

  return context;
};
