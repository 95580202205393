export const INPUT_TYPES = {
  text: 'text',
  email: 'email',
  password: 'password',
  phone: 'phone',
  cpf: 'cpf',
  cep: 'cep',
  cnpj: 'cnpj',
  deliveryCustomerDocument: 'delivery-customer-document',
  confirmationCode: 'confirmation-code',
  address: 'address-autocomplete',
  averageTicket: 'average-ticket'
};

export default { INPUT_TYPES };
