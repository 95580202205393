import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const {
  NODE_ENV: env,
  REACT_APP_LOG_ROCKET_ENABLED: enabled = false,
  REACT_APP_LOG_ROCKET_APP_ID: appId
} = process?.env || {};

export const initLogRocket = () => {
  if (env === 'development') return;
  if (!enabled) return;
  if (!appId) throw new Error('Unknown LogRocket App ID');
  LogRocket.init(appId, {
    network: {
      requestSanitizer(request) {
        if (!request.url.toLowerCase().includes('loggi.com')) return null;
        request.headers.authorization = null;
        return request;
      }
    }
  });
  LogRocket.getSessionURL(sessionUrl => {
    Sentry.configureScope(scope => {
      scope.setExtra('Log Rocket Session', sessionUrl);
    });
  });
  setupLogRocketReact(LogRocket);
};

export const identifyUser = ({ isAllowedOnBeyond, user, userCustomAccess }) => {
  if (!user) return;
  const getLoggiUserId = () => {
    const customAccess = JSON.parse(
      userCustomAccess?.['custom:access'] || '{}'
    );
    const { loggi_user_id: loggiUserId = 'N/D' } = customAccess;
    return loggiUserId;
  };
  LogRocket.identify(user.email, {
    ...user,
    isAllowedOnBeyond,
    loggiUserId: getLoggiUserId(),
    release: window.loggi.version
  });
};

export const appendLogRocketUrl = sentryEvent => {
  const event = { ...sentryEvent };
  const sessionUrl = LogRocket.sessionURL;
  if (sessionUrl) {
    if (!event.extra) event.extra = {};
    event.extra.LogRocket = sessionUrl;
  }
  return event;
};
