import PropTypes from 'prop-types';
import React from 'react';
import LineInputBase from '../line-input-base.component';
import formatCepMask from './cep-input.helper';

export const CEP_INPUT_TEST_ID = 'cep-input';

const CepInput = ({
  error,
  placeholder,
  onChange,
  inputRef,
  inputProps,
  className,
  onEnterKeyDown,
  value,
  variant
}) => {
  const [valueInputProp, setValueInputProp] = React.useState(
    value ? formatCepMask(value) : ''
  );

  const handleChange = ({ target }) => {
    const onlyDigits = String(target.value).replace(/\D/g, '');
    setValueInputProp(formatCepMask(onlyDigits));
    onChange({ target: { ...target, value: onlyDigits } });
  };

  return (
    <LineInputBase
      className={className}
      error={error}
      placeholder={placeholder}
      onChange={handleChange}
      onEnterKeyDown={onEnterKeyDown}
      omitFromHotjar
      inputProps={{
        ...inputProps,
        type: 'text',
        'data-testid': CEP_INPUT_TEST_ID,
        maxLength: 9,
        value: valueInputProp
      }}
      inputRef={inputRef}
      variant={variant}
    />
  );
};

CepInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnterKeyDown: PropTypes.func,
  inputRef: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  value: PropTypes.string,
  variant: PropTypes.string
};

CepInput.defaultProps = {
  className: '',
  error: '',
  placeholder: '',
  onEnterKeyDown: null,
  inputRef: {},
  inputProps: {},
  value: '',
  variant: 'primary'
};

export default CepInput;
