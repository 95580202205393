import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentAddressByCepSelected extends CRMBaseEvent {
  constructor({ addressInputed, page }) {
    super();
    this.eventName = EVENTS.SHIPMENT_ADDRESS_BY_CEP_SELECTED;
    this.payload = {
      address_inputed: addressInputed,
      page
    };
  }
}
