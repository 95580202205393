import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { blue, grey } from 'theme/colors';
import { Button } from 'UI/components';

export const ServiceCard = ({ description, buttonLabel, onClick, title }) => {
  return (
    <Box
      bgcolor={grey[150]}
      minWidth="230px"
      pt={3}
      pb={2}
      px={3}
      borderRadius="16px"
    >
      <Box mb={1}>
        <Typography variant="body1" fontWeight={600} mb={1}>
          {title}
        </Typography>
        <Typography variant="body2" fontWeight={500}>
          {description}
        </Typography>
      </Box>
      <Button
        label={buttonLabel}
        variant="text"
        onClick={onClick}
        textColor={blue[500]}
      />
    </Box>
  );
};

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default { ServiceCard };
