import { TRACKING_STATUS } from 'constants/tracking.constants';
import getProofOfDeliveryUseCase from 'domain/use-cases/get-proof-of-delivery/get-proof-of-delivery.use-case';
import { useCompanyData } from 'hooks/company-data/company-data.hook';
import { useCallback, useState, useEffect } from 'react';

export const useProofOfDelivery = (companyId, trackingCode, status) => {
  const [state, setState] = useState({
    data: {},
    isLoading: true
  });

  const { data: companyData } = useCompanyData(companyId);

  const effectFn = useCallback(async () => {
    if (status !== TRACKING_STATUS.delivered) {
      setState(currentState => ({
        ...currentState,
        data: {},
        isLoading: false
      }));

      return;
    }

    const secretId = companyData?.secretId;
    const data = await getProofOfDeliveryUseCase(secretId, trackingCode);

    setState(currentState => ({
      ...currentState,
      data,
      isLoading: false
    }));
  }, [companyData, trackingCode, status]);

  useEffect(() => {
    if (companyId && trackingCode) {
      effectFn();
    }
  }, [companyId, trackingCode, status, effectFn]);

  return { ...state, reFetch: effectFn };
};

export default useProofOfDelivery;
