import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { addHotjarClassToInputProps } from 'utils';
import { PrimaryInput, WhiteInput } from './line-input.styled';

const INPUTS_BY_VARIANT = { primary: PrimaryInput, white: WhiteInput };

const LineInputBase = ({
  autoFocus,
  className,
  endAdornment,
  error,
  inputProps,
  InputProps,
  inputRef,
  omitFromHotjar,
  onChange,
  onEnterKeyDown,
  onFocus,
  placeholder,
  style,
  type,
  value,
  variant
}) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter' && onEnterKeyDown) onEnterKeyDown();
  };

  const InputComponent = get(
    INPUTS_BY_VARIANT,
    variant,
    INPUTS_BY_VARIANT.primary
  );

  return (
    <InputComponent
      autoFocus={autoFocus}
      className={className}
      error={Boolean(error)}
      fullWidth
      helperText={error}
      InputProps={{
        ...InputProps,
        endAdornment,
        inputProps: addHotjarClassToInputProps({
          inputProps,
          omit: omitFromHotjar,
          type
        }),
        inputRef
      }}
      inputRef={inputRef}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      style={style}
      type={type}
      value={value}
    />
  );
};

LineInputBase.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  endAdornment: PropTypes.node,
  error: PropTypes.string,
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  omitFromHotjar: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEnterKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.shape({}),
  type: PropTypes.oneOf(['email', 'number', 'password', 'text']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(Object.keys(INPUTS_BY_VARIANT))
};

LineInputBase.defaultProps = {
  autoFocus: true,
  className: '',
  endAdornment: null,
  error: '',
  inputProps: {},
  InputProps: {},
  inputRef: {},
  omitFromHotjar: false,
  onEnterKeyDown: null,
  onFocus: noop,
  placeholder: '',
  style: {},
  type: 'text',
  value: undefined,
  variant: 'primary'
};

export default LineInputBase;
