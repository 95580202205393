/* eslint-disable no-unused-vars */

/**
 * Represents the base class for CRM adapters.
 * Derived classes should provide implementation for `sendUser` and `sendEvent` methods
 * and list of events they can handle in the `events` getter.
 *
 * @example
 * class RandomAdapter extends CRMBaseAdapter {
 *   get events() {
 *    return [EVENTS.SEND_USER, EVENTS.SOME_SPECIFIC_EVENT];
 *   }
 *
 *   sendUser(user) { }
 *
 *   sendEvent(eventName, eventData) { }
 * }
 */
export default class CRMBaseAdapter {
  get name() {
    return this.constructor.name;
  }

  /**
   * Represents the list of event names the adapter can handle.
   * Derived classes must override this getter and provide a list of events
   * from the EVENTS object defined in constants.js.
   *
   * @returns {string[]} List of event names.
   *
   * @example
   * get events() {
   *   return [EVENTS.SEND_USER, EVENTS.SOME_SPECIFIC_EVENT];
   * }
   */

  /* eslint-disable class-methods-use-this */
  get events() {
    return [];
  }

  get revenueEvents() {
    return [];
  }
  /* eslint-enable class-methods-use-this */

  /**
   * Checks if the adapter can handle the given event name.
   *
   * @param {string} eventName - Name of the event to be checked.
   * @returns {boolean} True if the adapter can handle the event, otherwise false.
   */
  canHandleEvent(eventName) {
    return this.events.includes(eventName);
  }

  /**
   * Checks if the adapter can handle the given revenue event name.
   *
   * @param {string} eventName - Name of the revenue event to be checked.
   * @returns {boolean} True if the adapter can handle the revenue event, otherwise false.
   */
  canHandleRevenue(eventName) {
    return this.revenueEvents.includes(eventName);
  }

  /**
   * Sends user data to the CRM platform.
   * Derived classes must provide their own implementation for this method.
   *
   * @param {Object} user - User data to be sent.
   * @throws {Error} Throws an error if the method is not implemented.
   */
  sendUser(user) {
    throw new Error(`sendUser method not implemented for ${this.name}`);
  }

  /**
   * Sends an event to the CRM platform.
   * Derived classes must provide their own implementation for this method.
   *
   * @param {string} eventName - Name of the event to be sent.
   * @param {Object} eventData - Data associated with the event.
   * @throws {Error} Throws an error if the method is not implemented.
   */
  sendEvent(eventName, eventData) {
    throw new Error(`sendEvent method not implemented for ${this.name}`);
  }

  /**
   * Sends an revenue event to the CRM platform.
   * Derived classes must provide their own implementation for this method.
   *
   * @param {string} eventName - Name of the event to be sent.
   * @param {number} amount - Revenue amount associated with the event.
   * @param {Object} eventData - Data associated with the event.
   * @throws {Error} Throws an error if the method is not implemented.
   */
  sendRevenueEvent(eventName, amount, eventData) {
    throw new Error(`sendRevenueEvent method not implemented for ${this.name}`);
  }
}
