import React from 'react';
import PropTypes from 'prop-types';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ShipmentDetailsHeader = ({ packageType, statusDescription }) => {
  const { t } = useTranslation('ui');

  const descriptionIncludeNone =
    statusDescription && statusDescription.includes('None');

  return (
    <Box p={spacing.inset.large}>
      <Typography
        color={colors.neutrals['on-surface-variant']}
        pb={spacing.stack.nano}
        variant="overlineSmall"
      >
        {t(packageType)}
      </Typography>
      {!descriptionIncludeNone &&
        statusDescription !== 'O pacote chega em ' &&
        statusDescription !== 'Coleta feita em ' &&
        statusDescription !== 'Postagem feita em ' && (
          <Typography variant="subtitleLarge">{statusDescription}</Typography>
        )}
    </Box>
  );
};

ShipmentDetailsHeader.propTypes = {
  packageType: PropTypes.oneOf([
    'myShipmentsV2.serviceType.indispatch',
    'myShipmentsV2.serviceType.dropOff',
    'myShipmentsV2.serviceType.beyond'
  ]).isRequired,
  statusDescription: PropTypes.string.isRequired
};

export default ShipmentDetailsHeader;
