import { useFeatureSwitch } from 'hooks/feature-switch';
import { useFeatureSwitchEnabledByCompany } from 'hooks';

export const useShipmentDetailsV2 = companyId => {
  const featureSwitch = useFeatureSwitch('enable_shipment_details_v2', false);

  const featureSwitchByCompany = useFeatureSwitchEnabledByCompany(
    'enable_shipment_details_v2_for_company',
    companyId,
    []
  );

  return {
    isLoading: featureSwitch.isFetching,
    isEnabled: featureSwitchByCompany || featureSwitch.data
  };
};

export default useShipmentDetailsV2;
