import { Box, Grid, Typography } from '@mui/material';
import AutohideOnInputFocus from 'UI/shipment/components/autohide-on-input-focus/autohide-on-input-focus';
import Footer from 'UI/shipment/components/shipment-footer';
import useShipmentPackageDimensions, {
  MAX_DIMENSIONS_SUM_CM,
  MAX_SIZE_CM,
  MAX_WEIGHT_G,
  MIN_DIMENSIONS_SUM_CM_INDISPATCH,
  MIN_HEIGHT_INDISPATCH,
  MIN_LENGTH_INDISPATCH,
  MIN_WIDTH_INDISPATCH
} from 'UI/shipment/hooks/shipment-package-dimensions';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { useSnackbar } from 'hooks';
import { AmateurQuoting } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeightInput,
  LengthInput,
  WeightInput,
  WidthInput
} from './dimensions-inputs';
import DisclaimerAlert from './disclaimer-alert';
import { ReactComponent as Illustration } from './icon.svg';

const getMaxSizeError = ({ t, error, field } = {}) => {
  if (!error[field]) return null;
  return t('packageDimensionsPage.errors.maxSize', { max: MAX_SIZE_CM });
};

const getMinSizeError = ({ t, error, field } = {}) => {
  const indispatchMinSizeErrorsKeys = {
    width: 'minWidth',
    height: 'minHeight',
    length: 'minLength',
    sizesSumError: 'minSizesSumError'
  };
  const minSizeErrorsValues = {
    minWidth: MIN_WIDTH_INDISPATCH,
    minLength: MIN_LENGTH_INDISPATCH,
    minHeight: MIN_HEIGHT_INDISPATCH,
    minSizesSumError: MIN_DIMENSIONS_SUM_CM_INDISPATCH
  };
  const minErrorKey = indispatchMinSizeErrorsKeys[field];

  if (!error[minErrorKey]) return null;
  return t('packageDimensionsPage.errors.minSize', {
    min: minSizeErrorsValues[minErrorKey]
  });
};

const getWeightError = t =>
  t('packageDimensionsPage.errors.maxWeight', { max: MAX_WEIGHT_G / 1000 });

export default function PackageDimensionsPage() {
  const { t } = useTranslation('ui');
  const showSnackbar = useSnackbar();
  const shipmentDispatcher = useShipmentDispatcher();
  const { context } = useShipmentContext();
  const [
    { heightCm, lengthCm, widthCm, weightG, errors },
    { setHeight, setLength, setWidth, setWeight }
  ] = useShipmentPackageDimensions();
  const isIndispatch = AmateurQuoting.isIndespacho({
    serviceType: context.serviceType
  });

  const hasSizeError =
    errors.weight || errors.height || errors.width || errors.length;
  const hasIndispatchSizeError =
    isIndispatch && (errors.minHeight || errors.minWidth || errors.minLength);
  const hasAllInputsFilled = !!(heightCm && widthCm && lengthCm && weightG);
  const submitEnabled =
    hasAllInputsFilled && !hasSizeError && !hasIndispatchSizeError;
  const showPriceEstimate = !!hasAllInputsFilled;

  const showSizesMaxSumLimitError = () => {
    showSnackbar({
      message: t('packageDimensionsPage.errors.maxSizesSum', {
        max: MAX_DIMENSIONS_SUM_CM
      }),
      severity: 'error',
      buttonLabel: t('packageDimensionsPage.labels.dismissNotification')
    });
  };

  const showSizesMinSumLimitError = () => {
    showSnackbar({
      message: t('packageDimensionsPage.errors.minSizesSum', {
        min: MIN_DIMENSIONS_SUM_CM_INDISPATCH
      }),
      severity: 'error',
      buttonLabel: t('packageDimensionsPage.labels.dismissNotification')
    });
  };

  const onSubmit = () => {
    if (errors.sizesSumError) showSizesMaxSumLimitError();
    else if (errors.minSizesSumError && isIndispatch)
      showSizesMinSumLimitError();
    else shipmentDispatcher.continue();
  };

  return (
    <>
      <Box marginBottom={5}>
        <AutohideOnInputFocus>
          <Typography variant="subtitle1">
            {t('packageDimensionsPage.label')}
          </Typography>
          <Box py="10px" my={2} display="flex" justifyContent="center">
            <Illustration />
          </Box>
        </AutohideOnInputFocus>

        <Grid container spacing={2} columns={2}>
          <Grid item xs={1}>
            <HeightInput
              onChange={setHeight}
              value={heightCm}
              error={
                getMaxSizeError({ t, error: errors, field: 'height' }) ||
                (isIndispatch
                  ? getMinSizeError({ t, error: errors, field: 'height' })
                  : undefined)
              }
            />
          </Grid>
          <Grid item xs={1}>
            <WidthInput
              onChange={setWidth}
              value={widthCm}
              error={
                getMaxSizeError({ t, error: errors, field: 'width' }) ||
                (isIndispatch
                  ? getMinSizeError({ t, error: errors, field: 'width' })
                  : undefined)
              }
            />
          </Grid>
          <Grid item xs={1}>
            <LengthInput
              onChange={setLength}
              value={lengthCm}
              error={
                getMaxSizeError({ t, error: errors, field: 'length' }) ||
                (isIndispatch
                  ? getMinSizeError({ t, error: errors, field: 'length' })
                  : undefined)
              }
            />
          </Grid>
          <Grid item xs={1}>
            <WeightInput
              onChange={setWeight}
              value={weightG}
              error={errors.weight ? getWeightError(t) : undefined}
            />
          </Grid>
        </Grid>
      </Box>

      <AutohideOnInputFocus>
        <DisclaimerAlert isVisible={!isIndispatch} />
      </AutohideOnInputFocus>

      <Footer showBorder={showPriceEstimate}>
        {showPriceEstimate && <Footer.EstimatePrice />}
        <Footer.PrimaryAction onClick={onSubmit} disabled={!submitEnabled} />
      </Footer>
    </>
  );
}
