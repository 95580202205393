import { loggiWebApi } from '@loggi/authentication-lib';

const endpoint = companyId =>
  `/one/api/accounts/v1/companies/${companyId}/user-info/loggi-user/`;

const userApi = {
  getUser: companyId => {
    const url = endpoint(companyId);
    return loggiWebApi
      .url(url)
      .get()
      .json();
  }
};

export default userApi;
