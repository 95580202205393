import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { useTranslation } from 'react-i18next';
import { InputAddress } from 'UI/shipment/models';
import { CorreiosAddressForm } from 'UI/shipment/components/correios-address-form';
import ShipmentAddressByCepDestinationInfosFailed from 'crm/entities/events/shipment-address-by-cep-destination-infos-failed/shipment-address-by-cep-destination-infos-failed';
import ShipmentDestinationByCepOriginInfosInputed from 'crm/entities/events/shipment-address-by-cep-destination-infos-inputed/shipment-address-by-cep-destination-infos-inputed';

export default function DestinationAddressPage() {
  const { t } = useTranslation('ui');
  const dispatcher = useShipmentDispatcher();
  const { context } = useShipmentContext();

  const handleSubmit = correios => {
    dispatcher.continue(new InputAddress({ correios }));
  };

  const handleOnSubmitEvent = ({ address, errorMessage }) => {
    const addressParams = {
      complement: address.complemento,
      district: address.bairro,
      number: address.numero,
      city: address.cidade,
      address: address.logradouro,
      state: address.uf,
      cep: address.cep
    };

    new ShipmentDestinationByCepOriginInfosInputed(addressParams).sendToCrm();

    if (errorMessage) {
      new ShipmentAddressByCepDestinationInfosFailed({
        ...addressParams,
        errorMessage
      }).sendToCrm();
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" mb={5} gap={2}>
        <Typography variant="subtitle1">
          {t('destinationAddressPage.title')}
        </Typography>
        <Typography variant="body1" fontWeight="medium">
          {t('destinationAddressPage.subtitle')}
        </Typography>
      </Box>
      <CorreiosAddressForm
        initialAddress={context.destinationAddress?.correios}
        onSubmit={handleSubmit}
        onSubmitClickEvent={handleOnSubmitEvent}
      />
    </>
  );
}
