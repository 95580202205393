import { getUser } from '@loggi/authentication-lib';
import { get } from 'lodash';

export const getUserCompanyIdUseCase = async (user = null) => {
  const _user = await (!user ? getUser() : user);
  const rawCustomAccess = get(_user, 'custom:access', null);
  if (!rawCustomAccess) return null;
  const customAccess = JSON.parse(rawCustomAccess);
  const [company] = get(customAccess, 'companies', [{ id: null }]);
  return get(company, 'id', null);
};

export default { getUserCompanyIdUseCase };
