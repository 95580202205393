import PACKAGE_SIZES from 'constants/package-size.constants';
import {
  NOT_INFORMED,
  NO_PACKAGING,
  SIMPLE_PACKAGING
} from '../constants/packaging-service-type';

/**
 * @param {import('../state-machine/types').MachineContext} shipmentContext
 * @returns {import('../state-machine/types').PackageDimensions}
 */
export function usePackageDimensions(shipmentContext) {
  let value;
  if (shipmentContext.packagingService === SIMPLE_PACKAGING)
    value = PACKAGE_SIZES[shipmentContext.packageSize];
  if (shipmentContext.packagingService === NO_PACKAGING)
    value = shipmentContext.packageDimensions;

  return value ?? PACKAGE_SIZES.PP;
}

/**
 * @param {import('../state-machine/types').MachineContext} shipmentContext
 */
export function usePackagingService({ packagingService }) {
  if (packagingService === NOT_INFORMED) return NO_PACKAGING;
  return packagingService ?? NO_PACKAGING;
}
