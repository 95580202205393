export class PaymentSession {
  constructor({ sessionData, sessionId, rechargeId, expiresAt }) {
    this.sessionData = sessionData;
    this.id = sessionId;
    this.rechargeId = rechargeId;
    this.expiresAt = expiresAt;
  }

  static parseApiResponse(response) {
    if (!response) {
      return null;
    }

    return new PaymentSession({
      sessionData: response.session_data,
      sessionId: response.session_id,
      rechargeId: response.recharge_id,
      expiresAt: response.expires_at
    });
  }
}

export default PaymentSession;
