import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { getWalletCreditAddedOption } from 'crm/utils';

export default class WalletCreditAdded extends CRMBaseEvent {
  constructor({ amount, paymentType, reference }) {
    super();
    this.eventName = EVENTS.WALLET_CREDIT_ADDED;
    this.payload = {
      amount,
      payment_type: getWalletCreditAddedOption(paymentType),
      reference
    };
  }
}
