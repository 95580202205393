import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { hasWebViewInterface } from 'mobile';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { blue, grey, root } from 'theme/colors';
import { colors } from '@loggi/mar';

import LogoLoggi from './app-icon.png';

export const BannerDownloadApp = () => {
  const { t } = useTranslation('ui');
  const appDynamicLink = useFeatureSwitch('download_banner_link', '').data;
  const { data: renderDownloadBanner } = useFeatureSwitch(
    'render_download_banner',
    true
  );
  const isMobileBrowser = checkIsMobile() && !hasWebViewInterface();

  if (!renderDownloadBanner || !isMobileBrowser) return null;

  return (
    <>
      <Box p={2} pb={1}>
        <Grid container columnSpacing={1}>
          <Grid item pt="2px">
            <img src={LogoLoggi} alt={t('banner.logoAlt')} />
          </Grid>
          <Grid item xs>
            <Grid container direction="column" spacing={0}>
              <Grid item height="max-content">
                <Typography
                  variant="caption"
                  display="block"
                  fontFamily="Helvetica!important"
                  fontWeight="bold"
                  color={root[900]}
                  lineHeight={1}
                  sx={{ verticalAlign: 'top' }}
                >
                  {t('banner.title')}
                </Typography>
              </Grid>
              <Grid item height="max-content">
                <Typography
                  variant="caption"
                  fontFamily="Helvetica!important"
                  color={grey[200]}
                  sx={{ verticalAlign: 'top' }}
                  height="auto"
                >
                  {t('banner.subtitle')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item width="72px" textAlign="right">
            <Button
              href={appDynamicLink}
              type="button"
              variant="contained"
              style={{
                background: blue[500],
                borderRadius: '24px',
                padding: '4px 0px'
              }}
            >
              <Typography
                variant="caption"
                fontFamily="Helvetica!important"
                color={root[0]}
                fontWeight="bold"
                textTransform="uppercase"
              >
                {t('banner.button')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider color={colors.smoke[100]} />
    </>
  );
};

export default BannerDownloadApp;
