import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue, grey, root } from 'theme/colors';
import {
  ILLUSTRATION_BY_TYPE,
  defaultProps,
  getPackageSizeDimensions,
  propTypes
} from './package-size-select-options.helper';

export const PackageSizeOptionSm = ({ isActive, onSelect, size }) => {
  const { t } = useTranslation('ui');
  const Illustration = ILLUSTRATION_BY_TYPE[size];
  const { sizeCm, weightKg } = getPackageSizeDimensions(size);

  const handleClick = () => onSelect(size);

  return (
    <Box
      minWidth="216px"
      bgcolor={root[0]}
      border={`2px solid ${isActive ? blue[500] : grey[50]}`}
      borderRadius="16px"
      display="inline-flex"
      style={{ scrollSnapAlign: 'center' }}
    >
      <Button
        data-package-size={size}
        disableRipple
        onClick={handleClick}
        style={{ padding: '0', width: '100%' }}
      >
        <Box color={root[900]} p={2} textAlign="left" width="100%">
          <Box
            alignItems="center"
            borderRadius="16px 16px 0 0"
            display="flex"
            justifyContent="flex-start"
            mb={3}
          >
            <Illustration height="80px" />
          </Box>
          <Typography variant="subtitle2">
            <b>{t(`packageSizePage.labels.${size}`)}</b>
          </Typography>
          <Box display="flex" mt={1}>
            <Box mr={2}>
              <Typography variant="body1">
                {t('packageSizePage.maxSize', { sizeCm })}
              </Typography>
            </Box>
            <Typography variant="body1">
              {t('packageSizePage.maxWeight', { weightKg })}
            </Typography>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};

PackageSizeOptionSm.defaultProps = defaultProps;
PackageSizeOptionSm.propTypes = propTypes;

export default { PackageSizeOptionSm };
