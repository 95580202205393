import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import getPickupScheduleCoverage from 'domain/use-cases/get-pickup-schedule-coverage/get-pickup-schedule-coverage.use-case';
import InputAddress from '../models/input-address.model';

/**
 * Requests the pickup schedule coverage for the given address.
 *
 * @param {object} options
 * @param {import('models').Address | import('UI/shipment/models').InputAddress} options.address
 * @param {string} options.companyId
 * @param {(data: {hasError: boolean, payload: any, message: string}) => void} options.onSuccess
 * @param {(error: Error) => void} options.onError
 * @returns {import('@tanstack/react-query').UseQueryResult<{hasError: boolean, payload: any, message: string}, Error>}
 */
export default function useAddressCoverage({
  address,
  companyId,
  onSuccess,
  onError
}) {
  const isInputAddress = address instanceof InputAddress;

  return useQuery(
    [
      'address-coverage',
      isInputAddress ? address.correios.cep : address?.placeId
    ],
    async () => getPickupScheduleCoverage(companyId, address),
    {
      enabled: !!address && !!companyId,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (onSuccess) onSuccess(data);
      },
      onError(error) {
        captureException(error);
        if (onError) onError(error);
      }
    }
  );
}
