import { Box, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { blue, root } from 'theme/colors';
import { BaseDialog, SvgIcon } from 'UI/components';

const CORREIOS_TERM_OF_USAGE =
  'https://www.correios.com.br/enviar/proibicoes-e-restricoes';

export const TermsDialog = ({ isOpen, onConfirm, onDismiss }) => {
  const { t } = useTranslation('ui');

  return (
    <BaseDialog
      confirmationButtonId="usage-terms-confirmation"
      isOpen={isOpen}
      labels={{
        confirm: t('indispatchTermsDialog.confirmButtonLabel'),
        dismiss: t('indispatchTermsDialog.dismissButtonLabel')
      }}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        top={4}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          color={root[900]}
        >
          <Typography variant="body1">
            {t('indispatchTermsDialog.confirmPhrase')}
          </Typography>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          gap={3}
          my={2}
          color={root[900]}
        >
          <SvgIcon icon="clipboardDelete" color={blue[500]} />
          <Typography variant="body2">
            <Trans
              t={t}
              i18nKey="indispatchTermsDialog.itemCode"
              components={[
                <Link
                  href={CORREIOS_TERM_OF_USAGE}
                  key={1}
                  color="primary"
                  rel="noreferrer"
                  underline="none"
                  target="_blank"
                />
              ]}
            />
          </Typography>
        </Box>
      </Box>
    </BaseDialog>
  );
};

TermsDialog.defaultProps = { isOpen: false };

TermsDialog.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default { TermsDialog };
