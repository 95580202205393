import React from 'react';
import { Accordion } from '@loggi/front-design-system';
import PropTypes from 'prop-types';
import { spacing, colors } from '@loggi/front-design-tokens';
import { Typography, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'UI/shipments-v2/utils/helpers.utils';

export const DestinationInfoAccordion = ({
  receiverName,
  receiverAddress,
  receiverPhone
}) => {
  const { t } = useTranslation('ui');
  return (
    <Box
      key="DestinationInfoAccordion"
      sx={{ borderBottom: `1px solid ${colors.neutrals['outline-low']}` }}
    >
      <Accordion
        arrow="start"
        title={t('shipmentDetailsV2.destinationAccordion.title')}
      >
        <Box px={spacing.inset.large}>
          <Stack pb={spacing.stack.nano}>
            <Typography variant="bodyHighlightMedium">
              {t('shipmentDetailsV2.destinationAccordion.name')}
            </Typography>
            <Typography variant="bodyTextMedium">{receiverName}</Typography>
          </Stack>
          <Stack pb={spacing.stack.nano}>
            <Typography variant="bodyHighlightMedium">
              {t('shipmentDetailsV2.destinationAccordion.address')}
            </Typography>
            <Typography variant="bodyTextMedium">{receiverAddress}</Typography>
          </Stack>
          <Stack pb={spacing.stack.nano}>
            <Typography variant="bodyHighlightMedium">
              {t('shipmentDetailsV2.destinationAccordion.phone')}
            </Typography>
            <Typography variant="bodyTextMedium">
              {receiverPhone
                ? formatPhoneNumber(receiverPhone)
                : t('shipmentDetailsV2.destinationAccordion.noPhone')}
            </Typography>
          </Stack>
        </Box>
      </Accordion>
    </Box>
  );
};

DestinationInfoAccordion.propTypes = {
  receiverName: PropTypes.string.isRequired,
  receiverAddress: PropTypes.string.isRequired,
  receiverPhone: PropTypes.string.isRequired
};

export default DestinationInfoAccordion;
