import { MARKETING_UTMS_SESSION_STORAGE_KEY, UTM_KEYS } from 'constants/index';
import useSessionStorage from '../session-storage/session-storage.hook';

const setValue = (localStorageHook, value) => {
  const getUTMsFromObject = Object.fromEntries(
    Object.entries(value).filter(([key]) => UTM_KEYS.includes(key))
  );
  localStorageHook.setValue(getUTMsFromObject);
};

/**
 * Returns UTMs from local storage
 *
 * @typedef {{
 *   [key: string]: string;
 * }} LocalStorageParams
 *
 * @returns {{getValue: LocalStorageParams | null, setValue: void}}
 */
const useUTMs = () => {
  const localStorageHook = useSessionStorage(
    MARKETING_UTMS_SESSION_STORAGE_KEY
  );
  return {
    getValue: localStorageHook.getValue,
    setValue: setValue.bind(null, localStorageHook)
  };
};

export default useUTMs;
