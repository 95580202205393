import { Box } from '@mui/material';
import Custody from 'assets/custody.svg';
import CustodyExchange from 'assets/custody-exchange.svg';
import Delivered from 'assets/delivered.svg';
import Deliveryman from 'assets/deliveryman.svg';
import PaperAndPen from 'assets/paper-and-pen.svg';
import ReturnedItem from 'assets/returned-item.svg';
import Skateboarder from 'assets/skateboarder.svg';
import Truck from 'assets/truck.svg';
import { TRACKING_STATUS } from 'constants/tracking.constants';
import { findKey } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const ILLUSTRATION_BY_STATUS = {
  [TRACKING_STATUS.delivered]: Delivered,
  [TRACKING_STATUS.deliveryFailure]: null,
  [TRACKING_STATUS.lost]: null,
  [TRACKING_STATUS.onRouteToDelivery]: Deliveryman,
  [TRACKING_STATUS.pickupAllocated]: Deliveryman,
  [TRACKING_STATUS.pickupScheduled]: PaperAndPen,
  [TRACKING_STATUS.problemNeedMoreInfo]: null,
  [TRACKING_STATUS.processing]: CustodyExchange,
  [TRACKING_STATUS.returningCompleted]: ReturnedItem,
  [TRACKING_STATUS.returningToShipper]: ReturnedItem,
  [TRACKING_STATUS.waitingShipperPickup]: Skateboarder,
  [TRACKING_STATUS.inTransit]: Truck
};

export const DROP_OFF_ILLUSTRATION_BY_STATUS = {
  ...ILLUSTRATION_BY_STATUS,
  [TRACKING_STATUS.pickupScheduled]: Custody
};

export const INDISPATCH_ILLUSTRATION_BY_STATUS = {
  ...ILLUSTRATION_BY_STATUS,
  [TRACKING_STATUS.pickupScheduled]: Deliveryman,
  [TRACKING_STATUS.processing]: null
};

const getIllustrationMapper = (isDropoff, isIndispatch) => {
  if (isDropoff) return DROP_OFF_ILLUSTRATION_BY_STATUS;
  if (isIndispatch) return INDISPATCH_ILLUSTRATION_BY_STATUS;

  return ILLUSTRATION_BY_STATUS;
};

const TrackingCardIllustration = ({ status, isDropoff, isIndispatch }) => {
  const illustrationMapper = getIllustrationMapper(isDropoff, isIndispatch);
  const illustration = illustrationMapper[status];
  const key = findKey(TRACKING_STATUS, val => val === status);
  return illustration ? (
    <Box
      style={{
        backgroundImage: `url(${illustration})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
      data-testid={`tracking-illustration-${key}`}
      flex="1"
    />
  ) : null;
};

TrackingCardIllustration.propTypes = {
  status: PropTypes.oneOf(Object.keys(ILLUSTRATION_BY_STATUS)).isRequired,
  isDropoff: PropTypes.bool.isRequired,
  isIndispatch: PropTypes.bool.isRequired
};

export default TrackingCardIllustration;
