import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS, { ADDRESS_BY } from 'crm/constants';
import { getPackageSizeDimensions } from 'UI/shipment/state-machine/utils';
import {
  getServiceTypeValue,
  getPackagingValue,
  getPaymentype,
  formatFullAddress,
  formatCityCorreios
} from 'crm/utils';

export default class ShipmentCreationFailed extends CRMBaseEvent {
  constructor({
    packaging,
    serviceType,
    addressBy,
    originFullAddress,
    destinationFullAddress,
    originCity,
    destinationCity,
    recipientName,
    price,
    coupon,
    goodsvalue,
    dimensions,
    errorStatus,
    errorMessage,
    errorJson,
    paymentType,
    reference
  }) {
    super();
    this.eventName = EVENTS.SHIPMENT_CREATION_FAILED;
    this.payload = {
      shipment_type: serviceType,
      packaging,
      address_by: addressBy,
      origin_full_address: originFullAddress,
      destination_full_address: destinationFullAddress,
      origin_city: originCity,
      destination_city: destinationCity,
      recipient_name: recipientName,
      price: price ? Number(price) : undefined,
      coupon,
      goodsvalue: goodsvalue ? Number(goodsvalue) : undefined,
      dimensions,
      error_status: errorStatus ? String(errorStatus) : undefined,
      error_message: errorMessage,
      error_json: errorJson,
      payment_type: paymentType,
      reference
    };
  }

  static fromShipmentContext({ context, price, coupon, error, rechargeId }) {
    const errorJson = error?.message;
    let errorMessage;
    try {
      const errorDict = JSON.parse(errorJson);
      errorMessage = errorDict?.message;
    } catch {
      errorMessage = '';
    }

    const originAddress = context?.pickupAddress ?? context?.originAddress;
    const destinationAddress =
      context?.deliveryAddress ?? context?.destinationAddress;

    return new ShipmentCreationFailed({
      packaging: getPackagingValue(context.packagingService),
      serviceType: getServiceTypeValue(context.serviceType),
      addressBy: context.pickupAddress ? ADDRESS_BY.GOOGLE : ADDRESS_BY.CEP,
      originCity:
        context.pickupAddress?.getCityState() ||
        formatCityCorreios(context.originAddress.correios),
      destinationCity:
        context.deliveryAddress?.getCityState() ||
        formatCityCorreios(context.destinationAddress.correios),
      originFullAddress: formatFullAddress(originAddress),
      destinationFullAddress: formatFullAddress(destinationAddress),
      recipientName: context.deliveryCustomer.name,
      price,
      coupon,
      goodsvalue: context.insurance,
      dimensions: getPackageSizeDimensions(context),
      errorStatus: error?.status,
      errorMessage,
      errorJson,
      paymentType: getPaymentype(context?.paymentMethod),
      reference: rechargeId
    });
  }
}
