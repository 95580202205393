import { loggiWebApi } from '@loggi/authentication-lib';
import { isEnabledNewCouponsListEndpointUseCase } from 'domain/use-cases';

const getCouponsNewEndPoint = (companyId, serviceType) => {
  const endpoint = `/shipment/v1/coupons/${companyId}/`;
  const BEYOND_API_URL = process.env.REACT_APP_PY_BEYOND_URL;
  const url = BEYOND_API_URL + endpoint;
  return loggiWebApi
    .url(url, true)
    .query({ service_type: serviceType })
    .get()
    .json();
};

export const couponsApi = {
  list: async (companyId, serviceType) => {
    const isEnabledNewEndpoint = await isEnabledNewCouponsListEndpointUseCase(
      companyId
    );

    if (isEnabledNewEndpoint) {
      return getCouponsNewEndPoint(companyId, serviceType);
    }

    return loggiWebApi
      .url(`/beyond/api/shipment/v1/companies/${companyId}/coupons/`)
      .post()
      .json();
  }
};

export default couponsApi;
