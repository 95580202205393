import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon } from './icons/with-packaging.svg';
import PackagingOption from './packaging-option.component';

export default function WithPackagingOption({ isSelected, onClick }) {
  const { t } = useTranslation('ui');
  return (
    <PackagingOption
      isSelected={isSelected}
      onClick={onClick}
      Icon={Icon}
      title={t('packagingRequiringPage.withPackaging.label')}
      description={t('packagingRequiringPage.withPackaging.description')}
    />
  );
}

WithPackagingOption.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
