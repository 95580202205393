import React from 'react';

export default function usePaginationDeck({ initialIndex = 0, total }) {
  const [swiper, setSwiper] = React.useState(null);
  const [currentIndex, setCurrentIndex] = React.useState(initialIndex);

  return {
    currentIndex,
    isLast: currentIndex === total - 1,
    isFirst: currentIndex === 0,
    setSwiper,
    setCurrentIndex,
    next: () => {
      swiper?.slideNext();
      setCurrentIndex(currentIndex + 1);
    },
    prev: () => {
      swiper?.slidePrev();
      setCurrentIndex(currentIndex - 1);
    }
  };
}
