import { Box, Slider, SliderThumb, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { root } from 'theme/colors';
import { ReactComponent as Knob } from './knob.svg';

const CustomSlider = styled(Slider)({
  '& .MuiSlider-rail, & .MuiSlider-track': {
    backgroundColor: root[0],
    borderRadius: '100px',
    height: '8px'
  },
  '& .MuiSlider-rail': {
    backgroundColor: root[0],
    opacity: 0.25
  },
  '& .MuiSlider-thumb': {
    height: '40px',
    width: '40px'
  },
  '& .MuiSlider-thumb.MuiSlider-active': {
    boxShadow: 'none'
  },
  '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
    boxShadow: 'none'
  }
});

const KnobThumb = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SliderThumb {...props}>
    {children}
    <Knob height="40px" />
  </SliderThumb>
);

KnobThumb.propTypes = { children: PropTypes.node.isRequired };

const ensureSafeValue = ({ value, min, max }) => {
  if (value > max) return max;
  if (value < min) return min;
  return value;
};

const ValueSlider = ({ max, min, onChange, step, value }) => {
  const [currentValue, setCurrentValue] = useState(null);
  const safeCurrentValue = currentValue ?? 0;
  const handleCommittedChange = useCallback(
    (_, newValue) => onChange(ensureSafeValue({ max, min, value: newValue })),
    [max, min, onChange]
  );
  const handleChange = useCallback(
    (_, newValue) =>
      setCurrentValue(ensureSafeValue({ max, min, value: newValue })),
    [max, min]
  );

  useEffect(() => {
    if (value && currentValue === null) {
      handleChange(null, value);
      handleCommittedChange(null, value);
    }
  }, [currentValue, value, handleChange, handleCommittedChange]);

  return (
    <Box px={1}>
      <Box
        alignItems="flex-end"
        color={root[0]}
        display="flex"
        justifyContent="center"
        mb={2}
      >
        <Box mr={0.5}>
          <Typography variant="body2">
            <b>R$</b>
          </Typography>
        </Box>
        <Typography variant="h2" style={{ lineHeight: 1 }}>
          <b>{safeCurrentValue}</b>
        </Typography>
      </Box>
      <CustomSlider
        components={{ Thumb: KnobThumb }}
        max={max}
        min={min}
        onChange={handleChange}
        onChangeCommitted={handleCommittedChange}
        step={step}
        value={safeCurrentValue}
        valueLabelDisplay="off"
      />
    </Box>
  );
};

ValueSlider.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  value: PropTypes.number
};

ValueSlider.defaultProps = { step: 50, value: 0 };

export default ValueSlider;
