import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentContentDeclarationInputed extends CRMBaseEvent {
  constructor({ goodsvalue }) {
    super();
    this.eventName = EVENTS.SHIPMENT_GOODSVALUE_INPUTED;
    this.payload = {
      goodsvalue: parseFloat(goodsvalue)
    };
  }
}
