import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { blue, grey, root } from 'theme/colors';
import { currencyFormatter } from 'utils';
import { AmateurQuoting } from 'models';

const ServiceOption = ({ isSelected, slo, price, onClick, name }) => {
  const { t } = useTranslation('ui');

  const names = {
    [AmateurQuoting.INDESPACHO_PAC_SERVICE_TYPE]: 'PAC',
    [AmateurQuoting.INDESPACHO_SEDEX_SERVICE_TYPE]: 'SEDEX'
  };
  const estimatePrice = currencyFormatter(price).replace('R$', '');

  return (
    <Box
      alignItems="center"
      bgcolor={root[0]}
      borderRadius="8px"
      border={`2px solid ${isSelected ? blue[500] : grey[50]}`}
      color={root[900]}
      onClick={onClick}
      display="flex"
      width="100%"
      mb={2}
    >
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        py={3}
        px={2}
      >
        <Box
          textAlign="left"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Typography mb={1} variant="body1" fontWeight="500" color={root[900]}>
            {names[name]}
          </Typography>
          <Typography variant="caption" color={grey[200]} fontWeight="500">
            {t('servicesPage.button.serviceEstimate', { count: slo })}
          </Typography>
        </Box>
        <Box
          display="flex"
          bgcolor={blue[50]}
          borderRadius="100px"
          height="32px"
          px={2}
          justifyContent="center"
          alignItems="center"
          color={blue[900]}
        >
          <Typography
            style={{ whiteSpace: 'nowrap' }}
            variant="body1"
            component="span"
            fontWeight="500"
          >
            {t('servicesPage.button.servicePrice', { count: estimatePrice })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ServiceOption.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  slo: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

export default ServiceOption;
