import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class UserCreationIntended extends CRMBaseEvent {
  constructor() {
    super();
    this.eventName = EVENTS.USER_CREATION_INTENDED;
    this.payload = {};
  }
}
