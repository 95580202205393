import PropTypes from 'prop-types';
import React from 'react';

export const PageHelmet = ({ title }) => {
  document.title = title;
  return <title>{title}</title>;
};

PageHelmet.propTypes = { title: PropTypes.string.isRequired };

export default { PageHelmet };
