import React from 'react';
import PropTypes from 'prop-types';
import { ShipmentsProvider, useShipments } from '../contexts/shipments.context';
import { MyShipmentsDesktop } from '../components/my-shipments-desktop/my-shipments-desktop.component';
import { MyShipmentsMobile } from '../components/my-shipments-mobile/my-shipments-mobile.component';
import { ShipmentDetailsDrawer } from '../components/shipment-details-drawer/shipment-details-drawer.component';

export const MyShipmentsWithProvider = ({ children }) => {
  return <ShipmentsProvider>{children}</ShipmentsProvider>;
};

MyShipmentsWithProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const MyShipmentsPage = () => {
  return (
    <MyShipmentsWithProvider>
      <MyShipmentsPageContent />
    </MyShipmentsWithProvider>
  );
};

const MyShipmentsPageContent = () => {
  const { isMobileDevice } = useShipments();

  return (
    <>
      <ShipmentDetailsDrawer />
      {isMobileDevice ? <MyShipmentsMobile /> : <MyShipmentsDesktop />}
    </>
  );
};

export default MyShipmentsPage;
