import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@loggi/front-design-tokens';
import { Box } from '@mui/material';
import { useTagDownloadOnMobile } from 'hooks/tag-download-on-mobile/tag-download-on-mobile.hook';
import { TRACKING_STATUS } from 'constants/tracking.constants';
import { PickupInfo } from './pickup-info/pickup-info.component';
import { TagInfo } from './tag-info/tag-info.component';
import { DropoffServiceLocationsInfo } from './dropoff-service-locations-info/dropoff-service-locations-info.component';
import { DestinationInfo } from './destination-info/destination-info.component';
import { TagDownloadInfo } from './tag-download-info/tag-download-info.component';
import { ShipperPickupInfo } from './shipper-pickup-info/shipper-pickup-info.component';
import { ReturningAddressInfo } from './returning-address-info/returning-address-info.component';
import { PackageDeliveredInfo } from './package-delivered-info/package-delivered-info.component';
import { HowToTrackInfo } from './how-to-track-info/how-to-track-info.component';
import { CorreiosTrackingInfo } from './correios-tracking-info/correios-tracking-info.component';

const conditionComponents = {
  showCorreiosTrackingInfo: ({
    trackingCode,
    correiosTrackingCode,
    status,
    isIndispatch
  }) => ({
    component: CorreiosTrackingInfo,
    shouldRender: isIndispatch,
    props: {
      trackingCode,
      correiosTrackingCode,
      alreadyPosted: status !== TRACKING_STATUS.pickupScheduled
    }
  }),
  showTagInfo: ({ isDropoff, isIndispatch, status, trackingCode }) => ({
    component: TagInfo,
    shouldRender:
      !isDropoff &&
      !isIndispatch &&
      [
        TRACKING_STATUS.pickupScheduled,
        TRACKING_STATUS.pickupAllocated
      ].includes(status),
    props: { tagSentByMail: false, trackingCode }
  }),
  showPickupInfo: ({ isDropoff, isIndispatch, status, pickupStatus }) => ({
    component: PickupInfo,
    shouldRender:
      !isDropoff && !isIndispatch && status === TRACKING_STATUS.pickupScheduled,
    props: { pickupStatus }
  }),
  showTagInfoForDropoff: ({ isDropoff, status, trackingCode }) => ({
    component: TagInfo,
    shouldRender: isDropoff && status === TRACKING_STATUS.pickupScheduled,
    props: { tagSentByMail: true, trackingCode }
  }),
  showDropoffServiceLocations: ({
    isDropoff,
    status,
    senderPostalCode,
    statusUpdateTime
  }) => ({
    component: DropoffServiceLocationsInfo,
    shouldRender: isDropoff && status === TRACKING_STATUS.pickupScheduled,
    props: {
      senderZipCode: senderPostalCode,
      pickupScheduledUpdatedDate: statusUpdateTime
    }
  }),
  showDestinationInfo: ({
    isDropoff,
    isIndispatch,
    status,
    destinationAddress,
    receiverName,
    receiverPhone
  }) => ({
    component: DestinationInfo,
    shouldRender:
      (!isDropoff &&
        !isIndispatch &&
        [
          TRACKING_STATUS.processing,
          TRACKING_STATUS.onRouteToDelivery,
          TRACKING_STATUS.problemNeedMoreInfo,
          TRACKING_STATUS.lost,
          TRACKING_STATUS.deliveryFailure,
          TRACKING_STATUS.inTransit
        ].includes(status)) ||
      (isDropoff &&
        [
          TRACKING_STATUS.processing,
          TRACKING_STATUS.onRouteToDelivery,
          TRACKING_STATUS.problemNeedMoreInfo,
          TRACKING_STATUS.lost,
          TRACKING_STATUS.deliveryFailure,
          TRACKING_STATUS.inTransit
        ].includes(status)) ||
      (isIndispatch &&
        [
          TRACKING_STATUS.processing,
          TRACKING_STATUS.canceledByAllocation
        ].includes(status)),
    props: { receiverAddress: destinationAddress, receiverName, receiverPhone }
  }),
  showShipperPickupInfo: ({ status, shipperPickupAddress }) => ({
    component: ShipperPickupInfo,
    shouldRender: [
      TRACKING_STATUS.returningToShipper,
      TRACKING_STATUS.waitingShipperPickup
    ].includes(status),
    props: { shipperPickupAddress }
  }),
  showReturningAddressInfo: ({
    status,
    destinationAddress,
    receiverName,
    receiverPhone
  }) => ({
    component: ReturningAddressInfo,
    shouldRender: status === TRACKING_STATUS.returningCompleted,
    props: { receiverAddress: destinationAddress, receiverName, receiverPhone }
  }),
  showPackageDeliveredInfo: ({
    status,
    trackingCode,
    companyId,
    handleShowDeliveryReceipt,
    proofOfDelivery
  }) => ({
    component: PackageDeliveredInfo,
    shouldRender: status === TRACKING_STATUS.delivered,
    props: {
      trackingCode,
      companyId,
      handleShowDeliveryReceipt,
      ...proofOfDelivery
    }
  }),
  showHowToTrackInfo: ({ isIndispatch, status }) => ({
    component: HowToTrackInfo,
    shouldRender:
      isIndispatch && [TRACKING_STATUS.pickupScheduled].includes(status),
    props: {}
  }),
  showTagDownloadInfo: ({
    isDropoff,
    isIndispatch,
    status,
    loggiKey,
    companyId,
    isTagDownloadEnabledOnMobile,
    isMobileDevice
  }) => ({
    component: TagDownloadInfo,
    shouldRender:
      (isTagDownloadEnabledOnMobile || !isMobileDevice) &&
      ((isDropoff &&
        [
          TRACKING_STATUS.pickupScheduled,
          TRACKING_STATUS.pickupAllocated
        ].includes(status)) ||
        (isIndispatch && status === TRACKING_STATUS.pickupScheduled)),
    props: { loggiKey, companyId }
  })
};

export const ShipmentDetailsInfo = ({
  isDropoff,
  isIndispatch,
  status,
  trackingCode,
  pickupStatus,
  senderPostalCode,
  destinationAddress,
  receiverName,
  handleShowDeliveryReceipt,
  companyId,
  proofOfDelivery,
  statusUpdateTime,
  correiosTrackingCode,
  loggiKey,
  receiverPhone,
  isMobileDevice
}) => {
  const { isEnabled: isTagDownloadEnabledOnMobile } = useTagDownloadOnMobile();

  const renderedComponents = Object.keys(conditionComponents).map(key => {
    const { component: Component, shouldRender, props } = conditionComponents[
      key
    ]({
      isDropoff,
      isIndispatch,
      status,
      trackingCode,
      pickupStatus,
      senderPostalCode,
      destinationAddress,
      receiverName,
      handleShowDeliveryReceipt,
      companyId,
      proofOfDelivery,
      statusUpdateTime,
      correiosTrackingCode,
      loggiKey,
      receiverPhone,
      isMobileDevice,
      isTagDownloadEnabledOnMobile
    });

    // eslint-disable-next-line react/jsx-props-no-spreading
    return shouldRender ? <Component key={key} {...props} /> : <></>;
  });

  return <Box mb={spacing.stack.xxsmall}>{renderedComponents}</Box>;
};

ShipmentDetailsInfo.defaultProps = {
  correiosTrackingCode: '',
  shipperPickupAddress: ''
};

ShipmentDetailsInfo.propTypes = {
  isDropoff: PropTypes.bool.isRequired,
  isIndispatch: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  trackingCode: PropTypes.string.isRequired,
  pickupStatus: PropTypes.string.isRequired,
  senderPostalCode: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string.isRequired,
  receiverName: PropTypes.string.isRequired,
  handleShowDeliveryReceipt: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  proofOfDelivery: PropTypes.shape({
    recipientName: PropTypes.string.isRequired,
    recipientDocument: PropTypes.string.isRequired,
    locationDescription: PropTypes.string.isRequired
  }).isRequired,
  statusUpdateTime: PropTypes.string.isRequired,
  correiosTrackingCode: PropTypes.string,
  loggiKey: PropTypes.string.isRequired,
  receiverPhone: PropTypes.string.isRequired,
  shipperPickupAddress: PropTypes.string,
  isMobileDevice: PropTypes.bool.isRequired
};

export default ShipmentDetailsInfo;
