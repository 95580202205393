import React from 'react';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import {
  formatTimestampToFriendlyDateForMobile,
  groupShipmentsByDate
} from 'UI/shipments-v2/utils/date.utils';
import { useTranslation } from 'react-i18next';
import {
  getServiceType,
  trackingPath
} from 'UI/shipments-v2/utils/helpers.utils';
import { useShipmentDetailsV2 } from 'hooks/shipment-details-v2/shipment-details-v2.hook';
import { ShipmentCard } from '../shipment-card/shipment-card.component';
import { LoadingScreen } from '../loading-screen/loading-screen.component';
import { NoResults } from '../no-results/no-results.component';
import { RequestError } from '../request-error/request-error.component';

export const ShipmentCards = () => {
  const { t } = useTranslation('ui');
  const {
    displayedShipments,
    companyId,
    isLoading,
    isError,
    setSelectedShipment
  } = useShipments();

  const { push } = useHistory();
  const { isEnabled } = useShipmentDetailsV2(companyId);

  const goTrackingDetailsPage = loggiKey => {
    if (isEnabled) {
      setSelectedShipment({ companyId, loggiKey });

      return;
    }

    push(trackingPath({ companyId, loggiKey }));
  };

  if (isLoading) return <LoadingScreen />;

  if (isError)
    return (
      <Box display="flex" my={spacing.inset.xxlarge} mx={spacing.inset.large}>
        <Box
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifyContent="center"
          flexGrow={1}
          mb={spacing.stack.xxxsmall}
        >
          <RequestError />
        </Box>
      </Box>
    );

  if (!displayedShipments.length)
    return (
      <Box display="flex" my={spacing.inset.xxlarge} mx={spacing.inset.large}>
        <Box
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifyContent="center"
          flexGrow={1}
          mb={spacing.stack.xxxsmall}
        >
          <NoResults />
        </Box>
      </Box>
    );

  const groupedByDate = groupShipmentsByDate(displayedShipments);
  let generalIndex = 0;

  return (
    <Box mb={spacing.stack.xxsmall}>
      {Object.keys(groupedByDate).map(key => {
        const timestamp = Date.parse(key);
        const formattedDate = formatTimestampToFriendlyDateForMobile(timestamp);

        const shipmentsPerDate = groupedByDate[key];

        const infoLabel =
          shipmentsPerDate.length === 1
            ? t('myShipmentsV2.shipmentCardDateGroups.single')
            : t('myShipmentsV2.shipmentCardDateGroups.multi');

        return (
          <Box key={key}>
            <Box
              display="flex"
              gap={spacing.inline.xxsmall}
              paddingY={spacing.inset.medium}
              alignItems="center"
              mx={spacing.stack.xxsmall}
            >
              <Box flex={1}>
                <Typography
                  color={colors.neutrals['on-surface-variant']}
                  variant="subtitleSmall"
                >
                  {infoLabel} {formattedDate}
                </Typography>
              </Box>
              <Box flex={1}>
                <div
                  style={{
                    border: `1px solid ${colors.neutrals['outline-low']}`
                  }}
                />
              </Box>
            </Box>
            {shipmentsPerDate.map(
              (
                {
                  loggiKey,
                  trackingCode,
                  receiverName,
                  status: { label, occasionalShipperStatusCode },
                  isDropoff,
                  isIndispatch,
                  destinationAddress,
                  correiosTrackCode
                },
                index
              ) => {
                const isLastCardInGroup = index === shipmentsPerDate.length - 1;
                const lastCardInPage =
                  generalIndex === displayedShipments.length - 1;

                generalIndex += 1;
                return (
                  <ShipmentCard
                    key={loggiKey}
                    loggiKey={loggiKey}
                    status={{ occasionalShipperStatusCode, label }}
                    receiverName={receiverName}
                    trackingCode={trackingCode}
                    serviceType={t(getServiceType(isIndispatch, isDropoff))}
                    isIndispatch={isIndispatch()}
                    correiosTrackCode={correiosTrackCode}
                    destinationAddress={destinationAddress}
                    handleClickAction={goTrackingDetailsPage}
                    lastCard={isLastCardInGroup || lastCardInPage}
                  />
                );
              }
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default ShipmentCards;
