import { Box } from '@mui/material';
import React from 'react';
import { SkeletonLoader } from 'UI/components';

const ServiceButtonLoading = () => {
  return (
    <Box
      alignItems="center"
      bgcolor="rgba(255, 255, 255, 0.1)"
      borderRadius="8px"
      display="flex"
      p={2}
    >
      <SkeletonLoader height={55} variant="light" width={55} />
      <Box ml={4}>
        <SkeletonLoader height={14} variant="light" width={124} />
        <Box mt={1}>
          <SkeletonLoader height={14} variant="light" width={180} />
        </Box>
      </Box>
    </Box>
  );
};

export const Loading = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      mt={1}
      role="progressbar"
      width="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Box width="100%">
          <SkeletonLoader variant="light" width={188} height={14} />
          <Box mt={1} mb={2}>
            <SkeletonLoader variant="light" width={308} height={14} />
          </Box>
          <ServiceButtonLoading />
          <Box mb={2} />
          <ServiceButtonLoading />
        </Box>
      </Box>
    </Box>
  );
};

export default { Loading };
