import SingularConfig from 'crm/adapters/configuration/singular/singular-config';

const {
  REACT_APP_ENV_SENTRY: env,
  REACT_APP_SINGULAR_SDK_KEY: singularSdkKey,
  REACT_APP_SINGULAR_SDK_SECRET: singularSdkSecret,
  REACT_APP_SINGULAR_PRODUCT_ID: singularProductId
} = process?.env || {};

if (env === 'production') {
  SingularConfig.initialize({
    singularSdkKey,
    singularSdkSecret,
    singularProductId
  });
}
