import { Box, Dialog, Paper } from '@mui/material';
import { merge, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonsGrid } from '../buttons-grid/buttons-grid.component';

/* eslint-disable react/jsx-props-no-spreading */
const PaperComponent = props => {
  const style = merge(props.style, {
    bottom: 'auto',
    borderRadius: '16px',
    margin: '32px 0 0',
    padding: '8px 0',
    maxWidth: '640px',
    width: '88%'
  });
  return <Paper {...props} style={style} />;
};
PaperComponent.defaultProps = { style: {} };
PaperComponent.propTypes = { style: PropTypes.shape({}) };

const BaseDialog = ({
  children,
  confirmationButtonId,
  isOpen,
  labels,
  onConfirm,
  onDismiss
}) => {
  return (
    <Dialog PaperComponent={PaperComponent} onClose={onDismiss} open={isOpen}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={3}
      >
        {children}

        <ButtonsGrid
          confirmationButtonId={confirmationButtonId}
          labels={labels}
          onConfirm={onConfirm}
          onDismiss={onDismiss}
        />
      </Box>
    </Dialog>
  );
};

BaseDialog.defaultProps = {
  confirmationButtonId: '',
  labels: {
    dismiss: ''
  },
  onDismiss: noop
};

BaseDialog.propTypes = {
  children: PropTypes.node.isRequired,
  confirmationButtonId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    confirm: PropTypes.string.isRequired,
    dismiss: PropTypes.string
  }),
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func
};

export default BaseDialog;
