import { Dialog, styled } from '@mui/material';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    paddingBottom: '16px',
    width: '100%',
    [breakpoints.only('xs')]: {
      position: 'relative',
      minWidth: '298px'
    }
  },
  '& .MuiDialogActions-root': {
    [breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
});

export default CustomDialog;
