/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A flex <Box /> that ocuppy full row inside Header
 * @param {import('@mui/material').BoxProps} props
 */
export default function Row({ children, ...props }) {
  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      {...props}
      // ensure those below are not overrided
      display="flex"
      flex="100%"
    >
      {children}
    </Box>
  );
}

Row.defaultProps = {
  children: null
};

Row.propTypes = {
  children: PropTypes.node
};
