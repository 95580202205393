import React from 'react';
import { Box, Typography } from '@mui/material';
import { IconButton, Button } from 'UI/components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { blue, root } from 'theme/colors';
import { supportPagePath } from 'UI/pages/support/support.page';
import { fillCompanyId } from 'utils';
import { accountProblem, newHome } from 'routes/routes';

export const AccountProblemPage = () => {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();
  const history = useHistory();
  const route = fillCompanyId({ companyId, route: newHome });

  const supportPageURL = supportPagePath({
    companyId,
    loggiKey: '',
    defaultReason: 'CONTACT_REASON_BLOCKED_ACCOUNT'
  });

  return (
    <Box height="100vh" px={3}>
      <Box position="fixed" mt={6}>
        <IconButton
          borderColor="transparent"
          icon="chevronLeft"
          onClick={() => history.push(route, { from: accountProblem })}
          variant="outlined"
        />
      </Box>

      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box pb={3}>
          <Typography variant="h4">
            {t('accountProblem.title.start')}
          </Typography>
          <Typography variant="h4" fontWeight="fontWeightBold">
            {t('accountProblem.title.highlight')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            {t('accountProblem.subtitle')}
          </Typography>
        </Box>
      </Box>

      <Box
        position="fixed"
        width="100%"
        px={3}
        paddingBottom={5}
        bottom={0}
        left={0}
      >
        <Button
          fullWidth
          bgColor={blue[500]}
          label={t('accountProblem.helpButton')}
          to={supportPageURL}
          textColor={root[0]}
        />
      </Box>
    </Box>
  );
};

export default AccountProblemPage;
