export const REASONS_MAP = {
  CONTACT_REASON_SHIPPING_CREATION: 'CONTACT_REASON_SHIPPING_CREATION',
  CONTACT_REASON_PICKUP: 'CONTACT_REASON_PICKUP',
  CONTACT_REASON_DELIVERY: 'CONTACT_REASON_DELIVERY',
  CONTACT_REASON_PAYMENT: 'CONTACT_REASON_PAYMENT',
  CONTACT_REASON_DELETE_ACCOUNT: 'CONTACT_REASON_DELETE_ACCOUNT',
  CONTACT_REASON_BLOCKED_ACCOUNT: 'CONTACT_REASON_BLOCKED_ACCOUNT',
  CONTACT_REASON_OTHERS: 'CONTACT_REASON_OTHERS'
};

export default {
  REASONS_MAP
};
