import { Box, Typography } from '@mui/material';
import { ReactComponent as IdentifyItemIcon } from 'assets/identify-item.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDrawer } from 'UI/components';
import TrackingCodeBar from 'UI/components/tracking-code-bar/tracking-code-bar.component';

const TrackingCodeDrawer = ({ trackingCode, onClose }) => {
  const { t } = useTranslation('ui');

  return (
    <BaseDrawer
      isOpen
      data-testid="tracking-code-drawer"
      labels={{ confirm: t('trackingCodeDrawer.button') }}
      onConfirmClick={onClose}
      onSwipeClose={onClose}
      variant="temporary"
      hasPuller
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
      >
        <Box>
          <IdentifyItemIcon />
        </Box>
        <Box mt={2}>
          <Typography align="center" component="h2" variant="subtitle2">
            <strong>{t('trackingCodeDrawer.title')}</strong>
          </Typography>
        </Box>
        <Box
          data-testid="tracking-code-drawer-wrapper"
          display="flex"
          flexWrap="nowrap"
          justifyContent="space-evenly"
          mt={3}
          width="100%"
        >
          <TrackingCodeBar trackingCode={trackingCode} />
        </Box>
      </Box>
    </BaseDrawer>
  );
};

TrackingCodeDrawer.propTypes = {
  trackingCode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default TrackingCodeDrawer;
