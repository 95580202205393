import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentAddressByCepQuotationFailed extends CRMBaseEvent {
  constructor({ cepOrigin, cepDestination, errorStatus, errorMessage }) {
    super();
    this.eventName = EVENTS.SHIPMENT_ADDRESS_BY_CEP_QUOTATION_FAILED;
    this.payload = {
      cep_origin: cepOrigin,
      cep_destination: cepDestination,
      error_status: errorStatus,
      error_message: errorMessage
    };
  }
}
