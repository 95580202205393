/* eslint-disable class-methods-use-this */
import sdks from 'crm/adapters/configuration';

/**
 * Factory for creating CRM adapters.
 *
 * This factory encapsulates the logic for instantiating CRM adapters,
 * providing a unified interface for creating various types of adapters.
 *
 * @example
 * // Usage:
 * const allAdapters = CRMAdapterFactory.createAdapters();
 *
 * Note:
 * - When a new adapter is added, its creation logic must also be added to this factory,
 *  if there is one.
 * - To use an adapter in the CRMService, it must first be supported by this factory.
 */
export default class CRMAdapterFactory {
  /**
   * Creates instances of all supported CRM adapters.
   *
   * Currently, this method creates instances of CRMAdapterInsider, CRMAdapterRudder, and CRMAdapterSingular.
   *
   * @returns An array of CRM adapter instances.
   */
  static createAdapters() {
    return Object.values(sdks)
      .map(sdk => sdk.createAdapter())
      .filter(adapter => !!adapter);
  }
}
