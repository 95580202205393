import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  shipmentIndispatchOnboardingV2,
  shipmentIndispatchSummaryV2,
  shipmentIndispatchSuccessV2,
  shipmentIndispatchServicesV2
} from 'routes/routes';
import CaptureShipmentPageView from 'UI/decorators/crm-integration/page-view/capture-page-view';
import PageCheckoutViewed from 'crm/entities/events/page-checkout-viewed/page-checkout-viewed';
import CheckoutPage from '../checkout/checkout.page';
import { OnboardingPage } from './onboard/onboard.page';
import { ServicesPage } from './services/services.page';
import { SuccessPageV2 } from './success_v2/success.page';

export default function IndispatchPage() {
  return (
    <Switch>
      <Route component={OnboardingPage} path={shipmentIndispatchOnboardingV2} />
      <Route path={shipmentIndispatchSummaryV2}>
        <CaptureShipmentPageView
          CRMEvent={PageCheckoutViewed}
          eventKey="PageCheckoutViewed"
        >
          <CheckoutPage />
        </CaptureShipmentPageView>
      </Route>
      <Route component={SuccessPageV2} path={shipmentIndispatchSuccessV2} />
      <Route component={ServicesPage} path={shipmentIndispatchServicesV2} />
    </Switch>
  );
}
