/* eslint-disable react/require-default-props */
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { SkeletonLoader } from 'UI/components';
import useServiceQuoting from 'UI/shipment/hooks/service-quoting';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from 'utils';
import { useShipmentFooter } from './context';

export default function EstimatePrice({ coupon = null }) {
  const { t } = useTranslation('ui');
  const footer = useShipmentFooter();
  const quoting = useServiceQuoting();
  let amount = quoting?.price;
  if (coupon) {
    amount = coupon.amountWithAppliedDiscount;
  }

  const total =
    typeof amount === 'number' ? currencyFormatter(amount) : <SkeletonLoader />;

  return (
    <Box
      data-testid="footer-estimate-price"
      display="flex"
      color={footer.textColor}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="40px"
    >
      <Typography variant="body2" fontWeight="medium">
        {t('shipmentFooter.estimate.total')}
      </Typography>
      <Typography
        variant="body1"
        fontWeight="medium"
        data-testid="total-amount"
      >
        {total}
      </Typography>
    </Box>
  );
}

EstimatePrice.propTypes = {
  coupon: PropTypes.shape({
    totalAmount: PropTypes.number,
    amountWithAppliedDiscount: PropTypes.number
  })
};
