import { AmateurQuotingResponse } from 'models';
import { useShipmentContext } from '../state-machine/context';
import useAmateurAddressCoverage from './amateur-address-coverage';
import useEstimate from './estimate';

/**
 * Combines the pickup address coverage with the estimate response to filter
 * out results that has price estimate but is not applicable to the pickup
 * address.
 *
 * It filters out the dropoff service if the dropoff feature is not enabled.
 *
 * @returns {AmateurQuotingResponse | null}
 */
export default function useDeliveryServices() {
  const shipmentContext = useShipmentContext();
  const pickupCoverageQuery = useAmateurAddressCoverage({
    address:
      shipmentContext.context.pickupAddress ||
      shipmentContext.context.originAddress
  });
  const estimateQuery = useEstimate();

  if (!pickupCoverageQuery.data || !estimateQuery.data) return null;

  const availableServices = Object.fromEntries(
    Object.entries(estimateQuery.data.availableServices).filter(entry =>
      pickupCoverageQuery.data.serviceTypes.includes(entry[1].serviceType)
    )
  );

  return new AmateurQuotingResponse({ availableServices });
}
