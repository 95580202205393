/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Amex } from 'assets/amex.svg';
import { ReactComponent as Elo } from 'assets/elo.svg';
import { ReactComponent as Mastercard } from 'assets/mc.svg';
import { ReactComponent as Unknown } from 'assets/nocard.svg';
import { ReactComponent as Visa } from 'assets/visa.svg';
import { get, noop } from 'lodash';
import { Box, Typography, Button as MuiButton } from '@mui/material';
import { blue } from 'theme/colors';
import { Button } from 'UI/components';
import { usePaymentOption } from 'repositories';
import { useTranslation } from 'react-i18next';
import { useShipmentFooter } from './context';

export const brands = {
  CARD_BRAND_AMERICAN_EXPRESS: Amex,
  CARD_BRAND_ELO: Elo,
  CARD_BRAND_MASTERCARD: Mastercard,
  UNKNOWN: Unknown,
  CARD_BRAND_VISA: Visa
};

export default function Payment({
  onAddCreditCard = noop,
  onChangeCreditCard = noop
}) {
  const footer = useShipmentFooter();
  const [{ brand, number }] = usePaymentOption();
  const hasStoredPaymentOption = !!brand && !!number;

  return (
    <Box data-testid="footer-payment" width="100%" color={footer.textColor}>
      {hasStoredPaymentOption && (
        <ChangePayment onChangeCreditCard={onChangeCreditCard} />
      )}
      {!hasStoredPaymentOption && (
        <AddPayment onAddCreditCard={onAddCreditCard} />
      )}
    </Box>
  );
}

Payment.propTypes = {
  onAddCreditCard: PropTypes.func,
  onChangeCreditCard: PropTypes.func
};

function AddPayment({ onAddCreditCard = noop }) {
  const { t } = useTranslation('ui');

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="32px"
      fontWeight={500}
    >
      <Typography variant="body2" fontWeight="medium">
        {t('shipmentFooter.payment.paymentMethod')}
      </Typography>
      <MuiButton
        variant="contained"
        onClick={onAddCreditCard}
        sx={{
          bgcolor: 'white',
          color: blue[500],
          borderRadius: '8px'
        }}
        disableElevation
      >
        <Typography variant="caption" fontWeight="bold">
          {t('shipmentFooter.payment.buttons.add')}
        </Typography>
      </MuiButton>
    </Box>
  );
}

AddPayment.propTypes = {
  onAddCreditCard: PropTypes.func
};

function ChangePayment({ onChangeCreditCard = noop }) {
  const { t } = useTranslation('ui');
  const [{ brand, number }] = usePaymentOption();
  const Brand = get(brands, brand, brands.UNKNOWN);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      py="8px"
      fontWeight={500}
    >
      <Box>
        <Typography variant="body2">
          {t('shipmentFooter.payment.creditCard')}
        </Typography>
        <Box display="flex">
          <Brand />
          <Box ml="8px" />
          <Typography variant="body2">•••• {number}</Typography>
        </Box>
      </Box>

      <Button
        variant="outlined"
        onClick={onChangeCreditCard}
        size="sm"
        textColor="white"
        label={t('shipmentFooter.payment.buttons.change')}
      />
    </Box>
  );
}

ChangePayment.propTypes = {
  onChangeCreditCard: PropTypes.func
};
