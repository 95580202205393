import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'UI/components';
import { blue, root, grey } from 'theme/colors';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { useUserRecords } from 'UI/contexts';
import AccountVolumeQualificationInformed from 'crm/entities/events/account-volume-qualification-informed/account-volume-qualification-informed';
import CRMUser from 'crm/entities/user/crm-user.model';
import { NEW_VOLUME_QUALIFICATION_OPTION_STRING } from 'crm/constants';

const NewEstimatedMonthlyPackagesStepForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    setSelectedOption(userRecords.estimatedMonthlyPackages);
  }, [userRecords.estimatedMonthlyPackages]);

  const handleSubmit = () => {
    setUserRecords({
      ...userRecords,
      estimatedMonthlyPackages: selectedOption
    });

    new AccountVolumeQualificationInformed({
      ...userRecords,
      document: userRecords?.cnpj || userRecords?.cpf,
      shipmentQualification: userRecords?.usuallySend,
      volumeQualification: selectedOption
    }).sendToCrm();

    new CRMUser({
      email: userRecords?.email,
      volumeQualification: selectedOption,
      volumeQualificationString:
        NEW_VOLUME_QUALIFICATION_OPTION_STRING[selectedOption]
    }).sendToCrm();

    handleOnNextStep();
  };

  const { data: monthlyPackagesRange, isFetching } = useFeatureSwitch(
    'new_monthly_packages_range',
    []
  );

  if (!isFetching && !options) {
    const {
      defaultValue,
      micro,
      small,
      medium,
      large,
      extra
    } = monthlyPackagesRange;
    const fromToLabel = (initialRange, finalRange) =>
      t('signUpCompany.newEstimatedMonthlyPackages.options.fromTo', {
        initialRange,
        finalRange
      });

    const moreThanLabel = finalRange =>
      t('signUpCompany.newEstimatedMonthlyPackages.options.moreThan', {
        finalRange
      });

    const optionsValues = [
      {
        id: 'underMicro',
        label: t('signUpCompany.newEstimatedMonthlyPackages.options.default'),
        value: defaultValue?.[0]
      },
      {
        id: 'micro',
        label: fromToLabel(micro?.[0], micro?.[1]),
        value: micro?.[1]
      },
      {
        id: 'small',
        label: fromToLabel(small?.[0], small?.[1]),
        value: small?.[1]
      },
      {
        id: 'medium',
        label: fromToLabel(medium?.[0], medium?.[1]),
        value: medium?.[1]
      },
      {
        id: 'large',
        label: fromToLabel(large?.[0], large?.[1]),
        value: large?.[1]
      },
      {
        id: 'extra',
        label: moreThanLabel(extra?.[0]),
        value: extra?.[0] + 1
      }
    ];

    setOptions(optionsValues);
  }

  return (
    <Box p={3}>
      <Typography fontWeight="500" variant="subtitle1">
        {t('signUpCompany.newEstimatedMonthlyPackages.title')}
      </Typography>
      <Box display="flex" py={2}>
        <Box flex={1}>
          {options?.map(option => (
            <Box mt={1} key={option?.id}>
              <Button
                key={option.id}
                bgColor={root[0]}
                borderColor={
                  selectedOption === option.value ? blue[500] : grey[50]
                }
                borderWidth={2}
                fullWidth
                label={option.label}
                value={selectedOption}
                fontWeight={500}
                textColor={root[900]}
                variant="outlined"
                onClick={() => setSelectedOption(option.value)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        style={{ position: 'absolute', bottom: '0', left: '0', width: '100%' }}
        p={3}
      >
        <Button
          fullWidth
          disabled={!selectedOption}
          label={t('signUpCompany.submitButton.label')}
          onClick={() => handleSubmit()}
        />
      </Box>
    </Box>
  );
};

NewEstimatedMonthlyPackagesStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default NewEstimatedMonthlyPackagesStepForm;
