/* eslint-disable react/jsx-props-no-spreading */
import useGooglePlacesApi from '@loggi/components/src/one/address-autocomplete/hooks/useGooglePlacesApi';
import { Autocomplete, Box } from '@mui/material';
import { styled } from '@mui/system';
import { BaseDrawer } from 'UI/components';
import { ALERT_ANSWER, SHIPMENT_BY_GOOGLE_TYPE } from 'crm/constants';
import { get, noop } from 'lodash';
import { Address } from 'models';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { AddressAutocompleteDisplay } from './address-autocomplete-display/address-autocomplete-display.component';
import { AddressAutocompleteInput } from './address-autocomplete-input/address-autocomplete-input.component';
import { AddressAutocompleteOptionList } from './address-autocomplete-option-list/address-autocomplete-option-list.component';
import { AddressAutocompleteOption } from './address-autocomplete-option/address-autocomplete-option.component';
import {
  hasAddressNumber,
  isEstablishment,
  makeSyntheticEvent,
  parseOptionIntoAddressModel
} from './address-autocomplete.helpers';
import { AddressNumberDrawer } from './address-number-drawer/address-number-drawer.component';

const Listbox = styled('ul')({
  height: '100%',
  maxHeight: '100%',
  padding: '0',
  overflow: 'visible',
  '& li': { padding: 0 }
});

const TransparentBackground = React.forwardRef(({ children }, ref) => (
  <div
    ref={ref}
    style={{
      overflow: 'unset',
      boxSizing: 'border-box',
      height: '100%',
      paddingBottom: '16px'
    }}
  >
    {children}
  </div>
));

TransparentBackground.propTypes = { children: PropTypes.node.isRequired };

export const AddressAutocomplete = ({
  className,
  error,
  inputRef,
  isLoading,
  label,
  onChange,
  placeholder,
  value,
  variant,
  onAddressEvent
}) => {
  const { t } = useTranslation('ui');
  const [isSuggestionsListOpen, setIsSuggestionsListOpen] = useState(false);
  const [addressState, setAddressState] = useState({
    autocompleteDrawerVisibility: false,
    numberDrawerVisibility: false,
    value: new Address(value ?? {})
  });
  const [inputValue, setInputValue] = useState(value?.description || '');
  const [addressInputed, setAddressInputed] = useState('');
  const numberInput = useRef(null);
  const { autocompleteSessionToken, googleMaps } = useGooglePlacesApi();
  const requestOptions = useMemo(
    () => ({
      sessionToken: autocompleteSessionToken,
      componentRestrictions: { country: 'br' }
    }),
    [autocompleteSessionToken]
  );
  const {
    ready,
    setValue: setPlacesAutoCompleteValue,
    suggestions: { data: placesSuggestions }
  } = usePlacesAutocomplete({
    callbackName: 'initGoogleMaps',
    debounce: 400,
    googleMaps,
    requestOptions
  });

  const handleAutocompleteInputChange = event => {
    if (!event) return;
    const { target } = event;
    const { value: val } = target;
    setPlacesAutoCompleteValue(val);
    if (val) {
      setAddressInputed(val);
    }
    onChange({ target: { value: val } });
  };

  const handleChange = ({ target }, option, type) => {
    const handlers = {
      clear: () => {
        setAddressState(prev => ({ ...prev, value: new Address({}) }));
        onChange(makeSyntheticEvent(null));
      },
      selectOption: input => {
        onAddressEvent({
          eventType: SHIPMENT_BY_GOOGLE_TYPE.ADDRESS_SELECTED,
          params: {
            addressInputed,
            addressSelected: input.description
          }
        });
        target.blur();
        const address = parseOptionIntoAddressModel(input);
        if (!isEstablishment(input) && !hasAddressNumber(input)) {
          setAddressState(prev => ({
            ...prev,
            autocompleteDrawerVisibility: false,
            numberDrawerVisibility: true,
            value: address
          }));
          onAddressEvent({
            eventType: SHIPMENT_BY_GOOGLE_TYPE.ALERT_PRESENTED,
            params: {
              addressInputed,
              addressSelected: input.description
            }
          });
          return setTimeout(() => numberInput.current?.focus(), 300);
        }
        setAddressState(prev => ({
          ...prev,
          autocompleteDrawerVisibility: false,
          value: address
        }));
        window.dataLayer?.push({
          event: 'addressAutocomplete::complete'
        });
        // This setTimeout is needed because MUI is focusing on the input
        // after the drawer is no longer visible.
        setTimeout(() => inputRef.current?.blur(), 100);
        return onChange(makeSyntheticEvent(address));
      }
    };
    return get(handlers, type, noop)(option);
  };

  const getOptionLabel = option => {
    if (typeof option === 'string') return option;
    return option.description;
  };

  const addressNumberDrawerChangeHandler = addressNumber => {
    inputRef.current.blur();
    numberInput.current.blur();
    setAddressState(prev => ({
      ...prev,
      autocompleteDrawerVisibility: true,
      numberDrawerVisibility: false
    }));
    window.scrollTo({ top: 0 });
    inputRef.current.scrollIntoView();
    const { value: address } = addressState;
    if (addressNumber === '') {
      onAddressEvent({
        eventType: SHIPMENT_BY_GOOGLE_TYPE.ALERT_ANSWERED,
        params: {
          addressInputed,
          addressSelected: addressState?.value?.description,
          answered: ALERT_ANSWER.WITHOUT_NUMBER
        }
      });
      setAddressState(prev => ({
        ...prev,
        autocompleteDrawerVisibility: false
      }));
      return onChange(makeSyntheticEvent(address));
    }
    const { main, secondary } = address.structured;
    const addressWithNumber = `${main}, ${addressNumber} - ${secondary}`;
    setInputValue(addressWithNumber);
    setPlacesAutoCompleteValue(addressWithNumber);
    onAddressEvent({
      eventType: SHIPMENT_BY_GOOGLE_TYPE.ALERT_ANSWERED,
      params: {
        addressInputed,
        addressSelected: addressWithNumber,
        answered: ALERT_ANSWER.WITH_NUMBER
      }
    });
    return setIsSuggestionsListOpen(true);
  };

  const displayTriggerHandler = ({ target }) => {
    setTimeout(() => {
      // have to lose the focus on next iteration of JS event loop, which is done using setTimeout
      target.blur();
      /* state changes must also run inside setTimeout, or else onFocus event keeps triggering 
       when addressDrawer loses focus, causing this function to run & re-open the drawner.
       Why? only God and MUI devs know it... */
      setAddressState(prev => ({
        ...prev,
        autocompleteDrawerVisibility: true
      }));
    }, 0);

    setTimeout(() => {
      inputRef.current.focus();
      window.scrollTo({ top: 0 });
      inputRef.current.scrollIntoView();
    }, 300);

    onAddressEvent({
      eventType: SHIPMENT_BY_GOOGLE_TYPE.INPUT_STARTED
    });
  };

  return (
    <>
      <AddressAutocompleteDisplay
        label={label}
        address={addressState.value?.description}
        error={error}
        isLoading={isLoading}
        onTrigger={displayTriggerHandler}
        variant={variant}
      />
      <BaseDrawer
        fluid
        fullHeight
        hasPuller
        isOpen={addressState.autocompleteDrawerVisibility}
        onSwipeClose={() => {
          setAddressState(prev => ({
            ...prev,
            autocompleteDrawerVisibility: false
          }));
          setTimeout(() => {
            // This setTimeout is forcing the blur on the input after the
            // element goes out of the viewport. This is needed because
            // MUI is keeping the input mounted and with focus, letting iOS
            // to display the keyboard even after close the drawer.
            inputRef.current?.blur();
          }, 100);
        }}
        variant="temporary"
      >
        <Box height="100%" overflow="hidden" width="100%">
          <Autocomplete
            autoComplete
            autoSelect
            disableClearable={isLoading}
            disabled={!ready}
            filterOptions={x => x}
            freeSolo
            getOptionLabel={getOptionLabel}
            ListboxComponent={Listbox}
            noOptionsText={t('addressAutocomplete.noOptions')}
            onChange={handleChange}
            onClose={() => setIsSuggestionsListOpen(false)}
            onInputChange={handleAutocompleteInputChange}
            onOpen={() => setIsSuggestionsListOpen(true)}
            open={isSuggestionsListOpen}
            options={placesSuggestions}
            PaperComponent={TransparentBackground}
            PopperComponent={AddressAutocompleteOptionList}
            renderInput={props =>
              AddressAutocompleteInput({
                ...props,
                className,
                errMessage: error,
                error: Boolean(error),
                inputRef,
                isLoading,
                placeholder: placeholder || t('addressField.placeholder')
              })
            }
            renderOption={AddressAutocompleteOption}
            selectOnFocus
            value={inputValue}
          />
        </Box>
      </BaseDrawer>
      <AddressNumberDrawer
        address={addressState.value}
        inputRef={numberInput}
        isOpen={addressState.numberDrawerVisibility}
        onChange={addressNumberDrawerChangeHandler}
      />
    </>
  );
};

AddressAutocomplete.defaultProps = {
  label: '',
  className: '',
  error: '',
  inputRef: { current: null },
  isLoading: false,
  placeholder: '',
  variant: 'white',
  value: null,
  onAddressEvent: noop
};

AddressAutocomplete.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Address), PropTypes.string]),
  variant: PropTypes.string,
  onAddressEvent: PropTypes.func
};

export default {};
