import { captureException } from '@sentry/react';
import {
  AFTERNOON_SLOT_END_HOUR,
  AFTERNOON_SLOT_END_MIN_EXPERIMENT,
  AFTERNOON_SLOT_START_HOUR,
  MORNING_SLOTS_START_HOUR_EXPERIMENT,
  MORNING_SLOTS_START_MIN_EXPERIMENT,
  MORNING_SLOT_END_HOUR,
  MORNING_SLOT_START_HOUR,
  NON_WORKING_DAY_TYPE
} from 'constants/index';
import {
  add,
  differenceInMilliseconds,
  format,
  isAfter,
  isBefore,
  isSameDay,
  set,
  startOfDay,
  sub
} from 'date-fns';
import { getFsValueUseCase } from '../get-fs-value/get-fs-value.use-case';
import { getNonWorkingDaysUseCase } from '../get-non-working-days/get-non-working-days.use-case';

const LIMIT_DAYS = 7;
const truncateMinMsSecs = datetime =>
  set(datetime, { minutes: 0, milliseconds: 0, seconds: 0 });

const addInitialSlotThreshold = ({ hours, minutes }, threshold = 15) => ({
  hours,
  minutes: minutes + threshold
});

const isMorningSlotAvailable = (
  { initialSlot },
  relative,
  isExperimentEnabled
) => {
  const today = startOfDay(relative);
  const morningSlotLimit = sub(
    set(today, { hours: MORNING_SLOT_START_HOUR }),
    addInitialSlotThreshold(initialSlot)
  );
  if (isExperimentEnabled) {
    const experimentLimit = sub(morningSlotLimit, {
      minutes: MORNING_SLOTS_START_MIN_EXPERIMENT
    });
    return isBefore(
      relative,
      set(experimentLimit, { milliseconds: 0, seconds: 0 })
    );
  }
  return isBefore(relative, truncateMinMsSecs(morningSlotLimit));
};

const isAfternoonSlotAvailable = ({ initialSlot }, relative = new Date()) => {
  const today = startOfDay(relative);
  const afternoonSlotLimit = sub(
    set(today, { hours: AFTERNOON_SLOT_START_HOUR }),
    addInitialSlotThreshold(initialSlot)
  );
  return isBefore(relative, truncateMinMsSecs(afternoonSlotLimit));
};

export const calculateNextWorkingDay = (time, pickupWorkingWeekdays) => {
  let startOfDayDate = startOfDay(time);

  if (pickupWorkingWeekdays.length === 0)
    throw new Error('No working week day available!');

  do {
    startOfDayDate = add(startOfDayDate, { days: 1 });
  } while (
    !pickupWorkingWeekdays.includes(
      format(startOfDayDate, 'EEEE').toUpperCase()
    )
  );

  return startOfDayDate;
};

export const calculateSchedulingStartDatetime = (
  {
    initialSlot,
    minCollectTimeWindow,
    pickupWorkingHours: { closeAt },
    pickupWorkingWeekdays
  },
  relative = new Date()
) => {
  const timeLimit = sub(
    // The limit must consider the initial slot interval
    sub(
      // The limit must allow a full length slot
      set(relative, closeAt),
      minCollectTimeWindow
    ),
    initialSlot
  );
  let schedulingTime = set(relative, { milliseconds: 0, seconds: 0 });
  schedulingTime = add(schedulingTime, {
    minutes: (60 - schedulingTime.getMinutes()) % 60
  }); // Round to the next hour
  const isNotWorkingDay = !pickupWorkingWeekdays.includes(
    format(schedulingTime, 'EEEE').toUpperCase()
  );
  const isPastTimeLimit =
    differenceInMilliseconds(timeLimit, schedulingTime) < 0;

  if (isNotWorkingDay || isPastTimeLimit) {
    schedulingTime = calculateNextWorkingDay(timeLimit, pickupWorkingWeekdays);
  }
  return schedulingTime;
};

export const calculateTimeSlots = (
  schedulingTime,
  { pickupWorkingHours: { openAt, closeAt }, initialSlot, minCollectTimeWindow }
) => {
  const availableSlots = [];
  const firstPossibleSlot = add(schedulingTime, initialSlot);

  const openingTime = set(firstPossibleSlot, openAt);
  const closingTime = set(firstPossibleSlot, closeAt);

  let slotStart =
    firstPossibleSlot > openingTime ? firstPossibleSlot : openingTime;

  while (differenceInMilliseconds(closingTime, slotStart) >= 0) {
    const slotEnd = add(slotStart, minCollectTimeWindow);

    if (differenceInMilliseconds(closingTime, slotEnd) < 0) {
      break;
    }
    availableSlots.push([slotStart, slotEnd]);

    slotStart = slotEnd;
  }

  return availableSlots;
};

const genExperimentStaticSlots = ({ day }) => {
  return [
    [
      set(day, {
        hours: MORNING_SLOTS_START_HOUR_EXPERIMENT,
        minutes: MORNING_SLOTS_START_MIN_EXPERIMENT
      }),
      set(day, { hours: MORNING_SLOT_END_HOUR })
    ],
    [
      set(day, { hours: AFTERNOON_SLOT_START_HOUR }),
      set(day, {
        hours: AFTERNOON_SLOT_END_HOUR,
        minutes: AFTERNOON_SLOT_END_MIN_EXPERIMENT
      })
    ]
  ];
};

const genDayStaticSlots = ({ day }, isExperimentEnabled) => {
  /* WARNING: this function does not currently consider whether the agency in question provides coverage for these arbitrary pick-up times. */
  if (isExperimentEnabled) return genExperimentStaticSlots({ day });
  return [
    [
      set(day, { hours: MORNING_SLOT_START_HOUR }),
      set(day, { hours: MORNING_SLOT_END_HOUR })
    ],
    [
      set(day, { hours: AFTERNOON_SLOT_START_HOUR }),
      set(day, { hours: AFTERNOON_SLOT_END_HOUR })
    ]
  ];
};

const getTodaySlots = ({ coverage: { initialSlot } }, isExperimentEnabled) => {
  const today = truncateMinMsSecs(new Date());
  const todaySlots = genDayStaticSlots({ day: today }, isExperimentEnabled);
  const morningSlotAvailability = isMorningSlotAvailable(
    { initialSlot },
    new Date(),
    isExperimentEnabled
  );
  if (!morningSlotAvailability) todaySlots.shift();
  if (!isAfternoonSlotAvailable({ initialSlot })) todaySlots.pop();
  return todaySlots;
};

const getDaySlots = (day, { coverage }, isExperimentEnabled) => {
  if (isSameDay(day, new Date())) {
    return getTodaySlots({ coverage }, isExperimentEnabled);
  }
  return genDayStaticSlots({ day }, isExperimentEnabled);
};

const getNonWorkingDays = async ({ endDate, startDate, storageAddress }) => {
  const isNonWorkingDaysApiDisabled = await getFsValueUseCase(
    'disable_non_working_days_api'
  );
  if (isNonWorkingDaysApiDisabled) return [];
  const address = {
    full_address: storageAddress.description,
    place_id: storageAddress.placeId
  };
  const nonWorkingDaysType = [NON_WORKING_DAY_TYPE.pickup];
  try {
    const result = await getNonWorkingDaysUseCase({
      address,
      endDate,
      nonWorkingDaysType,
      startDate
    });
    return result?.nonWorkingDates;
  } catch (ex) {
    captureException(ex);
    return null;
  }
};

const getWeekDates = coverage => {
  const today = new Date();
  const dates = [...Array(LIMIT_DAYS).keys()].map(i => {
    const relative =
      i === 0 ? today : startOfDay(add(set(today, { hours: 0 }), { days: i }));

    const weekday = format(relative, 'EEEE').toUpperCase();
    if (coverage.pickupWorkingWeekdays.includes(weekday)) {
      return relative;
    }
    return null;
  });

  return dates.filter(item => item);
};

const getAvailableDays = async (storageAddress, coverage) => {
  const weekDates = getWeekDates(coverage);
  const [startDate] = weekDates;
  const endDate = weekDates[weekDates.length - 1];
  const nonWorkingDays = await getNonWorkingDays({
    endDate,
    startDate,
    storageAddress
  });
  let hasEqualDate = false;
  const availableDates = [];
  if (!nonWorkingDays) return weekDates;
  weekDates.forEach(weekDate => {
    hasEqualDate = false;
    nonWorkingDays.forEach(nonWorkingDay => {
      const weekDateStr = weekDate.toISOString().split('T')[0];
      const nonWorkingDayStr = nonWorkingDay.toISOString().split('T')[0];
      if (weekDateStr === nonWorkingDayStr) hasEqualDate = true;
    });
    if (!hasEqualDate) availableDates.push(weekDate);
  });
  return availableDates;
};

const isBetweenDate = (date, [initial, final]) =>
  !(isBefore(date, initial) || isAfter(date, final));

export const calculateAvailableSchedulingTimeSlotsUseCase = async (
  storageAddress,
  coverage
) => {
  const isExperimentEnabled = await getFsValueUseCase(
    'enable_experiment_pickup_slots'
  );
  const rawDisabledSlots = await getFsValueUseCase('disable_pickup_slots');
  const disabledSlots = rawDisabledSlots.map(
    rawDatetime => new Date(rawDatetime)
  );
  const availableDates = await getAvailableDays(storageAddress, coverage);
  const availableSlots = availableDates.map(day => {
    return getDaySlots(day, { coverage }, isExperimentEnabled);
  });
  return availableSlots
    .map(dailySlots =>
      dailySlots.filter(
        slot =>
          !disabledSlots.some(disabledSlot => isBetweenDate(disabledSlot, slot))
      )
    )
    .filter(e => e.length)
    .slice(0, 2);
};

export default { calculateAvailableSchedulingTimeSlotsUseCase };
