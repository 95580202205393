import { Box } from '@mui/material';
import React from 'react';
import { SkeletonLoader } from 'UI/components';

const ServiceOptionLoading = () => {
  return (
    <Box
      bgcolor="rgba(255, 255, 255, 0.1)"
      borderRadius="8px"
      display="flex"
      justifyContent="space-between"
      my={8}
    >
      <Box>
        <Box mb={2}>
          <SkeletonLoader height={14} variant="light" width={100} />
        </Box>
        <SkeletonLoader height={14} variant="light" width={140} />
      </Box>
      <Box ml={4}>
        <SkeletonLoader height={24} variant="light" width={80} />
      </Box>
    </Box>
  );
};

export const Loading = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      mt={1}
      role="progressbar"
      width="100%"
      height="100vh"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Box width="100%" m={3}>
          <SkeletonLoader variant="light" width={240} height={14} />
          <Box mt={1} mb={3}>
            <SkeletonLoader variant="light" width={180} height={14} />
          </Box>
          <ServiceOptionLoading />
          <ServiceOptionLoading />
        </Box>
      </Box>
    </Box>
  );
};

export default { Loading };
