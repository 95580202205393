import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentAddressByCepCorpAlertClicked extends CRMBaseEvent {
  constructor({ cepOrigin, cepDestination }) {
    super();
    this.eventName = EVENTS.SHIPMENT_ADDRESS_BY_CEP_CORP_ALERT_CLICKED;
    this.payload = {
      cep_origin: cepOrigin,
      cep_destination: cepDestination
    };
  }
}
