import { Icon } from '@loggi/loggi-icons';
import { colors, spacing } from '@loggi/front-design-tokens';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NoResults = () => {
  const { t } = useTranslation('ui');
  return (
    <Stack alignItems="center" textAlign="center">
      <Stack mb={spacing.stack.xxxsmall}>
        <Icon
          size="large"
          name="SearchMedium"
          color={colors.neutrals['on-surface-variant']}
        />
      </Stack>
      <Typography mb={spacing.stack.nano} variant="subtitleMedium">
        {t('myShipmentsV2.noResults.title')}
      </Typography>
      <Typography
        color={colors.neutrals['on-surface-variant']}
        variant="subtitleSmall"
      >
        {t('myShipmentsV2.noResults.subtitle')}
      </Typography>
    </Stack>
  );
};

export default NoResults;
