import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './configuration/register-service-worker';
import { domainUseCases } from './domain';
import Root from './UI';
import 'configuration/polyfills';

ReactDOM.render(
  <Root useCases={domainUseCases} />,
  document.getElementById('root')
);

serviceWorker.register();
