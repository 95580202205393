import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { root } from 'theme/colors';
import useStyles from 'theme/custom-classes';
import ArrowBackButton from './composables/arrow-back-button';
import Row from './composables/header-row';
import LoggiLogo from './composables/loggi-logo';
import UserMenuButton, {
  UserMenuButtonWithEventsObserver
} from './composables/user-menu-button';

const HeaderBorder = () => {
  return (
    <Divider
      sx={{
        borderBottom: `1px solid rgb(204, 204, 204, 0.6)` // grey[500] with 0.6 opacity
      }}
    />
  );
};

export default function Header({ children, showBorder }) {
  const classes = useStyles({
    color: root[900],
    backgroundColor: root[0]
  });

  return (
    <>
      <Box className={classes.header} bgcolor="blue" flexWrap="wrap">
        {children}
      </Box>
      {showBorder && <HeaderBorder />}
    </>
  );
}

Header.defaultProps = {
  children: null,
  showBorder: false
};

Header.propTypes = {
  children: PropTypes.node,
  showBorder: PropTypes.bool
};

Header.ArrowBackButton = ArrowBackButton;
Header.Row = Row;
Header.UserMenuButton = UserMenuButton;
Header.LoggiLogo = LoggiLogo;
Header.UserMenuButtonWithEventsObserver = UserMenuButtonWithEventsObserver;
