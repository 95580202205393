import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class MenuOpened extends CRMBaseEvent {
  constructor() {
    super();
    this.eventName = EVENTS.MENU_OPENED;
    this.payload = {};
  }
}
