import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useUserRecords } from 'UI/contexts';
import { StepFormWrapper } from 'UI/components';

const TermsAndConditionsStepForm = ({ onSubmit, user }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();

  const handleSubmit = () => {
    const updatedUserRecords = {
      ...userRecords,
      email: user?.email
    };
    setUserRecords(updatedUserRecords);
    return onSubmit(updatedUserRecords);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <Typography fontWeight="fontWeightMedium" variant="subtitle1" mb={3}>
        {t('signUpCompany.termsAndConditions.title')}
      </Typography>
      <StepFormWrapper
        fields={{}}
        onSubmit={handleSubmit}
        buttonLabel={t('signUpCompany.submitButton.label')}
        hasCheckbox
      />
    </Box>
  );
};

TermsAndConditionsStepForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({ email: PropTypes.string }).isRequired
};

export default TermsAndConditionsStepForm;
