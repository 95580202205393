import { pxToRem } from '@loggi/mar/src/utils';
import { root } from 'theme/colors';

export const weigth = {
  regular: 400,
  medium: 500
};

export default {
  fontFamily: 'Montserrat, sans-serif !important',
  h1: {
    fontSize: `${pxToRem(40)} !important`,
    lineHeight: '1.5 !important',
    fontWeight: '400'
  },
  h2: {
    fontSize: `${pxToRem(30)} !important`,
    lineHeight: '1.5 !important',
    fontWeight: '400'
  },
  subtitle1: {
    fontSize: `${pxToRem(22)} !important`,
    lineHeight: '1.5 !important',
    fontWeight: '500',
    color: root[900]
  },
  subtitle2: {
    fontSize: `${pxToRem(18)} !important`,
    lineHeight: '1.5 !important',
    fontWeight: '400'
  },
  body1: {
    fontSize: `${pxToRem(16)} !important`,
    lineHeight: '1.5 !important',
    fontWeight: '400'
  },
  body2: {
    fontSize: `${pxToRem(14)} !important`,
    lineHeight: '1.5 !important',
    fontWeight: '400'
  },
  button: {
    fontSize: `${pxToRem(14)} !important`,
    lineHeight: '1.5 !important',
    fontWeight: '600',
    textTransform: 'none'
  },
  caption: {
    fontSize: `${pxToRem(12)} !important`,
    lineHeight: '1.5 !important',
    fontWeight: '400'
  }
};
