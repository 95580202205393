import { loggiWebApi } from '@loggi/authentication-lib';
import ServiceLocationModel from 'UI/shipment/models/service-location.model';

const URL = process.env.REACT_APP_PY_BEYOND_URL;

/**
 * @typedef {import('UI/shipment/models/service-location.model').default} ServiceLocationModel
 *
 * @param {object} params
 * @param {string} params.postalCode
 * @returns {Promise<ServiceLocationModel[]>}
 */
export default function getServiceLocationAgencies({ postalCode }) {
  const onlyDigits = postalCode.replace(/\D/g, '');
  return loggiWebApi
    .url(`${URL}/service-providers/v1/agencies/`, true)
    .query({ origin_postal_code: onlyDigits })
    .get()
    .json()
    .then(data => data.map(ServiceLocationModel.fromJSON));
}
