import React from 'react';
import { Box } from '@mui/material';
import { spacing } from '@loggi/front-design-tokens';
import {
  SORT_OPTIONS,
  STATUS_FILTER_OPTIONS
} from 'UI/shipments-v2/constants/shipments.constants';
import { useTranslation } from 'react-i18next';
import { useShipments } from 'UI/shipments-v2/contexts/shipments.context';
import { DropdownChip } from '../dropdown-chip/dropdown-chip.component';
import { GroupingFilters } from '../grouping-filters/grouping-filters.component';

export const TableActions = () => {
  const { t } = useTranslation('ui');
  const { statusFilter, setStatusFilter, sort, setSort } = useShipments();

  const handleSelectStatusFilter = option => {
    setStatusFilter(option);
  };

  const handleSelectOrder = option => {
    setSort(option);
  };

  const statusFilterLabel =
    statusFilter.length > 1
      ? `${t(
          'myShipmentsV2.actions.statusFilterMulti'
        )}${statusFilter.length.toString().padStart(2, '0')}`
      : t('myShipmentsV2.actions.statusFilter');

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="left"
        flexGrow={1}
        mb={spacing.stack.xxsmall}
        gap={spacing.stack.xxsmall}
        mx={spacing.stack.xxsmall}
      >
        <GroupingFilters mobile={false} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="right"
        flexGrow={1}
        mb={spacing.stack.xxsmall}
        gap={spacing.stack.xxsmall}
        mx={spacing.stack.xxsmall}
      >
        <DropdownChip
          label={statusFilterLabel}
          options={STATUS_FILTER_OPTIONS}
          onSelectItem={handleSelectStatusFilter}
          activeFilter={statusFilter}
          multiSelect
        />

        <DropdownChip
          label={t('myShipmentsV2.actions.sort')}
          options={SORT_OPTIONS}
          onSelectItem={handleSelectOrder}
          activeFilter={sort}
        />
      </Box>
    </>
  );
};

export default TableActions;
