export const CREATE_SHIPMENT_STEPS = {
  BUDGET: 'budget',
  CONTENT_DECLARATION: 'contentDeclaration',
  DELIVERY_CUSTOMER: 'deliveryCustomer',
  DELIVERY: 'delivery',
  INSURANCE_VALUE: 'insuranceValue',
  PACKAGE_SIZE: 'packageSize',
  PICKUP_CUSTOMER: 'pickupCustomer',
  PICKUP_SCHEDULE: 'pickupSchedule',
  PICKUP: 'pickup',
  SUMMARY: 'summary'
};

export default {};
