import React from 'react';
import { Accordion } from '@loggi/front-design-system';
import PropTypes from 'prop-types';
import { spacing, colors } from '@loggi/front-design-tokens';
import { Typography, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'UI/shipments-v2/utils/helpers.utils';

export const SenderInfoAccordion = ({
  senderName,
  senderAddress,
  senderPhone
}) => {
  const { t } = useTranslation('ui');
  return (
    <Box
      key="SenderInfoAccordion"
      sx={{ borderBottom: `1px solid ${colors.neutrals['outline-low']}` }}
    >
      <Accordion
        arrow="start"
        title={t('shipmentDetailsV2.senderInfoAccordion.title')}
      >
        <Box px={spacing.inset.large}>
          <Stack pb={spacing.stack.nano}>
            <Typography variant="bodyHighlightMedium">
              {t('shipmentDetailsV2.senderInfoAccordion.name')}
            </Typography>
            <Typography variant="bodyTextMedium">{senderName}</Typography>
          </Stack>
          <Stack pb={spacing.stack.nano}>
            <Typography variant="bodyHighlightMedium">
              {t('shipmentDetailsV2.senderInfoAccordion.address')}
            </Typography>
            <Typography variant="bodyTextMedium">{senderAddress}</Typography>
          </Stack>
          <Stack pb={spacing.stack.nano}>
            <Typography variant="bodyHighlightMedium">
              {t('shipmentDetailsV2.senderInfoAccordion.phone')}
            </Typography>
            <Typography variant="bodyTextMedium">
              {senderPhone
                ? formatPhoneNumber(senderPhone)
                : t('shipmentDetailsV2.senderInfoAccordion.noPhone')}
            </Typography>
          </Stack>
        </Box>
      </Accordion>
    </Box>
  );
};

SenderInfoAccordion.propTypes = {
  senderName: PropTypes.string.isRequired,
  senderAddress: PropTypes.string.isRequired,
  senderPhone: PropTypes.string.isRequired
};

export default SenderInfoAccordion;
