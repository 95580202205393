import mar from '@loggi/mar';
import merge from 'lodash/merge';
import { blue, green, red, root } from './colors';

export default merge(mar.palette, {
  primary: {
    light: blue[50],
    main: blue[500],
    dark: blue[900],
    contrastText: root[0]
  },
  secondary: {
    light: blue[50],
    main: blue[500],
    dark: blue[900],
    contrastText: blue[50]
  },
  error: {
    main: red[500]
  },
  success: {
    main: green[500]
  },
  background: {
    default: root[0]
  }
});
