import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { blue, grey } from 'theme/colors';

export default function PackageOption({
  title,
  description,
  icon: Icon,
  isSelected,
  onClick
}) {
  return (
    <Box
      onClick={onClick}
      alignItems="center"
      borderRadius="8px"
      border={`2px solid ${isSelected ? blue[500] : grey[50]}`}
      padding={2}
      display="flex"
      gap={3}
      width="100%"
    >
      <Box height="64px" width="80px" display="flex" justifyContent="center">
        <Icon />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography align="left" variant="body1" fontWeight="medium">
          {title}
        </Typography>
        <Typography
          align="left"
          variant="body2"
          fontWeight="medium"
          color={grey[200]}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

PackageOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
