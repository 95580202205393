import { useQuery } from '@tanstack/react-query';
import walletApi from 'service/wallet-api';

const getQueryKey = companyId => ['wallet-balance', String(companyId)];

export default function useWalletBalance({ companyId }) {
  const key = getQueryKey(companyId);

  return useQuery(
    key,
    async () => {
      try {
        const result = await walletApi.getBalance({ companyId });
        return result;
      } catch (error) {
        // If the company does not have a wallet, the API returns a 404. It means the company has no balance.
        if (error.response && error.response.status === 404) {
          return { balance: 0 };
        }

        throw error;
      }
    },
    {
      enabled: !!companyId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}
