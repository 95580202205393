import { getOriginCoverageParams } from '@loggi/components/src/one/address';
import { isString } from 'lodash';
import { getCoverage } from 'service/amateurs-pricing-api';

export const getAddressCoverageUseCase = async ({ address, companyId }) => {
  const response = await getOriginCoverageParams(companyId, address);
  if (isString(response)) {
    return { hasError: true, payload: null, message: response };
  }
  return { hasError: false, payload: response, message: '' };
};

export const getAmateurCoverageUseCase = ({ address, companyId }) => {
  const payload = { shipFrom: address.toWidget() };
  return getCoverage({ companyId, payload }).then(
    ({ has_coverage: hasCoverage, service_types: serviceTypes }) => ({
      hasCoverage,
      serviceTypes
    })
  );
};
