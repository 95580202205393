import React from 'react';
import { spacing, colors } from '@loggi/front-design-tokens';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../search-input/search-input.component';
import { TableActions } from '../table-actions/table-actions.component';

import { ShipmentsTable } from '../shipments-table/shipments-table.component';
import { ShipmentsPagination } from '../shipments-pagination/shipments-pagination.component';
import { LoggiHeader } from '../loggi-header/loggi-header.component';

export const MyShipmentsDesktop = () => {
  const { t } = useTranslation('ui');

  return (
    <Box
      data-testid="main-box-wrapper"
      display="flex"
      flexDirection="column"
      height="100vh"
    >
      <LoggiHeader />
      <Box display="flex" flexDirection="column" mt={spacing.stack.xxsmall}>
        <Box
          mx={spacing.stack.xxsmall}
          display="flex"
          alignItems="center"
          mb={spacing.stack.xxsmall}
        >
          <Box flexGrow={1} textAlign="left">
            <Typography
              variant="headingMedium"
              color={colors.neutrals['on-surface']}
            >
              {t('myShipmentsV2.title')}
            </Typography>
          </Box>
          <Box maxWidth={350} flexGrow={1} textAlign="right">
            <SearchInput />
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <TableActions />
      </Box>
      <ShipmentsTable />
      <ShipmentsPagination />
    </Box>
  );
};

export default MyShipmentsDesktop;
