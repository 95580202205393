import React, { useEffect, useRef } from 'react';
import { Input, InputAdornment, FormControl, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { root } from 'theme/colors';
import {
  INITIAL_WIDTH,
  MAX_WIDTH,
  calcDynamicWidth
} from 'UI/shipment/pages/wallet/input-recharge-value/helper/input-recharge-value.helper';

const CustomInput = styled(Input)(() => ({
  '& input': {
    fontSize: '40px',
    width: `${INITIAL_WIDTH}px`,
    maxWidth: `${MAX_WIDTH}px`
  },
  '& .MuiInputAdornment-root': {
    fontSize: '24px',
    color: root[900]
  }
}));

export default function RechargeInput({ disabledFocus, value, handleChange }) {
  const inputRef = useRef(null);

  useEffect(() => {
    const newWidth = calcDynamicWidth(value);
    if (inputRef.current) {
      inputRef.current.style.width = `${newWidth}px`;
    }
  }, [value]);

  const handleOnBlur = () => {
    if (!disabledFocus && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <FormControl>
      <CustomInput
        disableUnderline
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        sx={{ alignItems: 'baseline' }}
        autoFocus
        type="text"
        inputProps={{
          inputMode: 'numeric'
        }}
        startAdornment={
          <InputAdornment position="start" disablePointerEvents>
            <span style={{ fontWeight: 'bold' }}>R$</span>
          </InputAdornment>
        }
        onBlur={handleOnBlur}
      />
    </FormControl>
  );
}

RechargeInput.defaultProps = {
  disabledFocus: false
};

RechargeInput.propTypes = {
  disabledFocus: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleChange: PropTypes.func.isRequired
};
