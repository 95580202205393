import { loggiWebApi } from '@loggi/authentication-lib';

const URL = process.env.REACT_APP_PY_BEYOND_URL;

/**
 * @typedef {import('../models/input-address.model').default} InputAddress
 *
 * @class
 */
export default class AddressCoverageUseCase {
  /**
   * @param {object} options
   * @param {InputAddress} options.address
   * @param {string} options.companyId
   */
  constructor({ address, companyId }) {
    this.address = address;
    this.companyId = companyId;
  }

  async execute() {
    const data = await loggiWebApi
      .url(this.url, true)
      .post(this.payload)
      .json();

    return {
      hasCoverage: data.has_coverage,
      serviceTypes: data.service_types
    };
  }

  /**
   * @private
   */
  get url() {
    return `${URL}/coverage/v1/coverage/${this.companyId}/`;
  }

  /**
   * @private
   */
  get payload() {
    return {
      shipFrom: this.address.toJSON()
    };
  }
}
