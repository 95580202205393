import { useCurrentUser } from 'auth';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import React, { useEffect } from 'react';
import {
  getUserCompanyIdUseCase,
  fetchLoggiUserUseCase
} from 'domain/use-cases';
import CRMUser from 'crm/entities/user/crm-user.model';
import { maxDelayDebounce } from 'utils';
import { useFeatureSwitch } from 'hooks/feature-switch';

const debouncedLoggiUser = maxDelayDebounce(fetchLoggiUserUseCase, 1000);

const getLoggiUser = async companyId => {
  let loggiUser;
  try {
    loggiUser = await debouncedLoggiUser(companyId);
  } catch (error) {
    Sentry.captureException(error);
  }
  return loggiUser;
};

const getUserAndSendToCrm = async (user, isFetchLoggiUserEnabled) => {
  const companyId = await getUserCompanyIdUseCase(user);
  const crmUser = CRMUser.fromCognito(user, companyId);
  if (companyId && isFetchLoggiUserEnabled) {
    const loggiUser = await getLoggiUser(companyId);
    if (loggiUser) crmUser.enrichWithLoggiUserData(loggiUser);
  }
  crmUser.sendToCrm();
};

function CRMIntegration({ children }) {
  const { data: isFetchLoggiUserEnabled, isFetched } = useFeatureSwitch(
    'enable_fetch_loggi_user'
  );
  const user = useCurrentUser();

  useEffect(() => {
    if (user && isFetched) {
      getUserAndSendToCrm(user, isFetchLoggiUserEnabled);
    }
  }, [user, isFetchLoggiUserEnabled, isFetched]);

  return children;
}

CRMIntegration.propTypes = {
  children: PropTypes.node.isRequired
};

export function withCRMIntegration(Component) {
  return () => (
    <CRMIntegration>
      <Component />
    </CRMIntegration>
  );
}

export default CRMIntegration;
