import * as Sentry from '@sentry/react';

const setSentryContext = ({ user, company = null }) => {
  if (user) {
    const { email, companyAccess, name } = user;
    Sentry.setUser({ username: name, email, companyAccess });
    Sentry.setTag('company.id', company?.id);
    Sentry.setContext('company', company);
  }
};

export default setSentryContext;
