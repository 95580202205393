import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { colors, spacing } from '@loggi/front-design-tokens';

import { currencyFormatter } from 'utils';

export default function WalletBalanceInfo({ balance, isLoading, showBalance }) {
  const { t } = useTranslation('ui');

  return (
    <Stack p={spacing.stack.xxsmall} gap={spacing.stack.xxxsmall}>
      <Typography
        variant="subtitleMedium"
        color={colors.neutrals['on-surface']}
      >
        {t('walletPage.balance')}
      </Typography>

      {isLoading && (
        <Stack data-testid="balance-info-loading" gap={spacing.stack.xxxsmall}>
          <Stack
            backgroundColor={colors.neutrals['surface-container-low']}
            width={spacing.inline.huge}
            height={spacing.stack.xxsmall}
          />
          <Stack
            backgroundColor={colors.neutrals['surface-container-low']}
            width={spacing.inline.giant}
            height={spacing.stack.xxsmall}
          />
        </Stack>
      )}

      {!isLoading && (
        <Stack gap={spacing.stack.xxxsmall}>
          <Typography
            variant="headingMedium"
            color={colors.neutrals['on-surface']}
          >
            {showBalance ? currencyFormatter(balance) : '****'}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

WalletBalanceInfo.defaultProps = {
  balance: 0,
  isLoading: false
};

WalletBalanceInfo.propTypes = {
  balance: PropTypes.number,
  isLoading: PropTypes.bool,
  showBalance: PropTypes.bool.isRequired
};
