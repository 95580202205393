import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS, { ADDRESS_BY } from 'crm/constants';
import { getServiceTypeValue, convertToNumberOrDefault } from 'crm/utils';
import { getLowestPricedServiceAmongIndispatchServices } from 'utils';

/**
 * @typedef {import('models').AmateurQuoting} AmateurQuoting
 * @typedef {import('UI/shipment/state-machine/types').MachineContext} MachineContext
 */

export default class ShipmentQuotationPresented extends CRMBaseEvent {
  constructor({
    addressBy,
    originFullAddress,
    destinationFullAddress,
    originCity,
    destinationCity,
    companyId,
    coverages,
    corpPrice,
    corpSlo,
    beyondPrice,
    beyondSlo,
    indispatchPrice,
    indispatchSlo,
    dropoffPrice,
    dropoffSlo
  }) {
    super();
    this.eventName = EVENTS.SHIPMENT_QUOTATION_PRESENTED;
    this.payload = {
      address_by: addressBy,
      origin_full_address: originFullAddress,
      destination_full_address: destinationFullAddress,
      origin_city: originCity,
      destination_city: destinationCity,
      company_id: companyId ? String(companyId) : undefined,
      coverages,
      corp_price: convertToNumberOrDefault(corpPrice),
      corp_slo: convertToNumberOrDefault(corpSlo),
      beyond_price: convertToNumberOrDefault(beyondPrice),
      beyond_slo: convertToNumberOrDefault(beyondSlo),
      indispatch_price: convertToNumberOrDefault(indispatchPrice),
      indispatch_slo: convertToNumberOrDefault(indispatchSlo),
      dropoff_price: convertToNumberOrDefault(dropoffPrice),
      dropoff_slo: convertToNumberOrDefault(dropoffSlo)
    };
  }

  /**
   * @param {{
   *  context: MachineContext,
   *  companyId: string|number,
   *  availableServices: Object.<string, AmateurQuoting>
   * }}
   */
  static fromShipmentContext({ context, companyId, availableServices }) {
    const indespacho = getLowestPricedServiceAmongIndispatchServices(
      availableServices.indespachoPac,
      availableServices.indespachoSedex
    );
    const uniqueServiceTypes = new Set(
      Object.values(availableServices).map(service =>
        getServiceTypeValue(service.serviceType)
      )
    );
    const coverages = Array.from(uniqueServiceTypes).join(', ');

    return new ShipmentQuotationPresented({
      addressBy: context.pickupAddress ? ADDRESS_BY.GOOGLE : ADDRESS_BY.CEP,
      originFullAddress:
        context.pickupAddress?.description ||
        context.originAddress?.correios.cep,
      destinationFullAddress:
        context.deliveryAddress?.description ||
        context.destinationAddress?.correios.cep,
      originCity: context.pickupAddress?.getCityState() || undefined,
      destinationCity: context.deliveryAddress?.getCityState() || undefined,
      companyId,
      coverages,
      corpPrice: availableServices.corp?.price,
      corpSlo: availableServices.corp?.slo,
      beyondPrice: availableServices.beyond?.price,
      beyondSlo: availableServices.beyond?.slo,
      indispatchPrice: indespacho?.price,
      indispatchSlo: indespacho?.slo,
      dropoffPrice: availableServices.dropoff?.price,
      dropoffSlo: availableServices.dropoff?.slo
    });
  }
}
