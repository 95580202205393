import { useMemo } from 'react';

const usePagination = (data, itemsPerPage, page) => {
  const totalPages = useMemo(() => Math.ceil(data.length / itemsPerPage), [
    data,
    itemsPerPage
  ]);

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedData = useMemo(() => {
    return data.slice(startIndex, endIndex);
  }, [data, startIndex, endIndex]);

  return {
    page,
    totalPages,
    startIndex,
    endIndex,
    displayedData
  };
};

export default usePagination;
