import RudderConfig from 'crm/adapters/configuration/rudder/rudder-config';

const {
  REACT_APP_ENV_SENTRY: env,
  REACT_APP_RUDDER_WRITE_KEY: writeKey,
  REACT_APP_RUDDER_DATAPLANE_URL: dataPlaneUrl
} = process?.env || {};

if (env === 'production') {
  RudderConfig.initialize({ writeKey, dataPlaneUrl });
}
