import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class AccountDeleted extends CRMBaseEvent {
  constructor({ email }) {
    super();
    this.eventName = EVENTS.ACCOUNT_DELETED;
    this.payload = {
      email
    };
  }
}
