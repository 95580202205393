export const COGNITO_ERROR_MESSAGES = {
  CORPORATE_USER_MUST_EXIST_FOR_AUTOMATIC_USER_MIGRATION:
    'PreSignUp+failed+with+error+Corporate+user+must+exist+for+automatic+user+migration',
  CORPORATE_LOGGI_USER_MUST_EXIST_FOR_AUTOMATIC_USER_MIGRATION:
    'PreSignUp+failed+with+error+Corporate+user+must+exist+for+automatic+loggiuser+migration',
  SIGN_UP_GOOGLE_USER_LINK_REDIRECT:
    'PreSignUp+failed+with+error+User+account+has+been+linked+to+corresponding+Google+federated+account',
  EMAIL_LOGGI_WEB_ALREADY_EXISTS:
    'PreSignUp+failed+with+error+There+is+already+a+LoggiUser+with+this+email',
  NOT_AUTHORIZED_INVALID_CREDENTIALS: 'Incorrect username or password.',
  NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT:
    'User is not authorized to get auth details.',
  PASSWORD_MUST_BE_RESET:
    'Temporary password has expired and must be reset by an administrator',
  PASSWORD_ATTEMPTS_EXCEEDED: 'Password attempts exceeded',
  ACCOUNT_IS_DISABLED: 'User is disabled',
  ACCOUNT_IS_DISABLED_COGNITO: 'User+is+not+enabled',
  USER_NOT_REGISTERED_AS_GOOGLE_FEDERATED_LOGIN:
    "PreAuthentication failed with error The user state is invalid to log in, it's expected EXTERNAL_PROVIDER_STATE to @loggi.com account.",
  API_USER_MIGRATION_ERROR: 'APIMigrationError - Failed with unknown error.',
  COMPANY_IRREGULAR_CPF_EXCEPTION: 'CompanyCpfValidationException',
  PASSWORD_RESET_REQUIRED: 'Password reset required for the user'
};

export default { COGNITO_ERROR_MESSAGES };
