import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { grey, root } from 'theme/colors';
import { Button } from 'UI/components';
import { useShipmentContext } from 'UI/shipment/state-machine/context';

export const AddressDisplay = ({ address, label, onClick, isDisabled }) => {
  return (
    <Button
      variant={isDisabled ? 'text' : 'outlined'}
      alignment="left"
      disabled={isDisabled}
      bgColor={root[0]}
      textColor={address ? root[900] : grey[200]}
      borderWidth={2}
      borderColor={grey[50]}
      fullWidth
      icon="pin"
      label={address || label}
      size="lg"
      onClick={onClick}
    />
  );
};

AddressDisplay.defaultProps = {
  address: '',
  onClick: () => {},
  isDisabled: false
};

AddressDisplay.propTypes = {
  address: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired
};

export const OriginAddressDisplay = ({ onClick, isDisabled }) => {
  const { t } = useTranslation('ui');
  const { context } = useShipmentContext();
  const address =
    context.pickupAddress?.description ??
    context.originAddress?.correios?.formattedCEP ??
    '';

  return (
    <AddressDisplay
      address={address}
      label={t('addressDisplay.origin')}
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
};

OriginAddressDisplay.defaultProps = {
  isDisabled: false,
  onClick: null
};

OriginAddressDisplay.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};

export const DestinationAddressDisplay = ({ onClick, isDisabled }) => {
  const { t } = useTranslation('ui');
  const { context } = useShipmentContext();
  const address =
    context.deliveryAddress?.description ??
    context.destinationAddress?.correios?.formattedCEP ??
    '';

  return (
    <AddressDisplay
      address={address}
      label={t('addressDisplay.destination')}
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
};

DestinationAddressDisplay.defaultProps = {
  isDisabled: false,
  onClick: null
};

DestinationAddressDisplay.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};
