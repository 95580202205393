import { getGeocode, getLatLng } from 'use-places-autocomplete';

const googlePlacesNotIntializedError =
  "Cannot read properties of undefined (reading 'maps')";

function handleAddressApiError(e) {
  if (e instanceof TypeError && e.message === googlePlacesNotIntializedError)
    throw ReferenceError(
      'GoogleplacesApi não está inicializada. Tente chamar useGooglePlacesApi() antes desta operação'
    );
  throw e;
}

async function safeGetgeoCode(placeId) {
  let geoReturn;
  try {
    geoReturn = await getGeocode({ placeId });
  } catch (e) {
    handleAddressApiError(e);
  }
  return geoReturn;
}

/** @typedef {import('models').Address} Address */
/** @param {Address} address */
export async function convertBeyondToCorpAddress(address) {
  const [geocode] = await safeGetgeoCode(address.placeId);
  const { lat, lng } = await getLatLng(geocode);
  const {
    address_components: addressComponents,
    formatted_address: formattedAddress
  } = geocode;
  const corpExtraAddressData = { lat, lng, addressComponents };

  return {
    description: formattedAddress,
    complement: address.complement,
    ...corpExtraAddressData
  };
}

export default convertBeyondToCorpAddress;
