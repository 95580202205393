import { TRACKING_STATUS } from 'constants/tracking.constants';

export const SHIPMENT_FILTERS_SESSION_STORAGE_KEY = 'my_shipment_filters';

export const SHIPMENTS_PER_PAGE_MOBILE = 5;
export const SHIPMENTS_PER_PAGE_DESKTOP = 5;

export const SHIPMENTS_SHIPPER_STATUS = {};

export const STATUS_FILTER_PICKUP_PENDING = 'coleta_pendente';
export const STATUS_FILTER_PENDING_POST = 'postagem_pendente';
export const STATUS_FILTER_COLLECTED = 'coletado';
export const STATUS_FILTER_POSTED = 'postado';
export const STATUS_FILTER_ON_ROUTE_TO_PICKUP = 'em_rota_de_coleta';
export const STATUS_FILTER_IN_TRANSIT = 'em_transito';
export const STATUS_FILTER_ON_ROUTE_TO_DELIVERY = 'em_rota_de_entrega';
export const STATUS_FILTER_PACKAGE_STOPPED = 'pacote_parado';
export const STATUS_FILTER_RECEIVER_NOT_FOUND = 'destinatario_ausente';
export const STATUS_FILTER_MISPLACED = 'extraviado';
export const STATUS_FILTER_RETURN_TO_SHIPPER_STARTED = 'devolucao_iniciada';
export const STATUS_FILTER_RETURNED_TO_SHIPPER = 'devolvido';
export const STATUS_FILTER_CANCELLED = 'cancelado';
export const STATUS_FILTER_DELIVERED = 'entregue';
export const STATUS_FILTER_READY_TO_PICKUP = 'pronto_para_retirada';
export const STATUS_FILTER_INTERNAL_PENDING = 'pendencia_interna';

export const STATUS_FILTER_OPTIONS = [
  { label: 'Coleta pendente', value: STATUS_FILTER_PICKUP_PENDING },
  { label: 'Postagem pendente', value: STATUS_FILTER_PENDING_POST },
  { label: 'Coletado', value: STATUS_FILTER_COLLECTED },
  { label: 'Postado', value: STATUS_FILTER_POSTED },
  { label: 'Em rota de coleta', value: STATUS_FILTER_ON_ROUTE_TO_PICKUP },
  { label: 'Em trânsito', value: STATUS_FILTER_IN_TRANSIT },
  { label: 'Em rota de entrega', value: STATUS_FILTER_ON_ROUTE_TO_DELIVERY },
  { label: 'Pacote parado', value: STATUS_FILTER_PACKAGE_STOPPED },
  { label: 'Destinatário ausente', value: STATUS_FILTER_RECEIVER_NOT_FOUND },
  { label: 'Extraviado', value: STATUS_FILTER_MISPLACED },
  {
    label: 'Devolução iniciada',
    value: STATUS_FILTER_RETURN_TO_SHIPPER_STARTED
  },
  { label: 'Devolvido', value: STATUS_FILTER_RETURNED_TO_SHIPPER },
  { label: 'Cancelado', value: STATUS_FILTER_CANCELLED },
  { label: 'Entregue', value: STATUS_FILTER_DELIVERED },
  { label: 'Aguardando retirada', value: STATUS_FILTER_READY_TO_PICKUP }
];

export const GROUPING_OPTIONS = {
  pickupPending: 'PICKUP_PENDING',
  pending: 'PENDING',
  withProblem: 'WITH_PROBLEM'
};

export const PACKAGE_TYPES = {
  indispatch: 'INDISPATCH',
  dropOff: 'DROPOFF',
  regular: 'LOGGI_FACIL'
};

export const STATUS_FILTER_AND_SHIPPER_STATUS_RELATIONS = {
  [PACKAGE_TYPES.regular]: {
    [STATUS_FILTER_PICKUP_PENDING]: TRACKING_STATUS.pickupScheduled,
    [STATUS_FILTER_COLLECTED]: TRACKING_STATUS.processing,
    [STATUS_FILTER_ON_ROUTE_TO_PICKUP]: TRACKING_STATUS.pickupAllocated,
    [STATUS_FILTER_IN_TRANSIT]: TRACKING_STATUS.inTransit,
    [STATUS_FILTER_PACKAGE_STOPPED]: TRACKING_STATUS.problemNeedMoreInfo,
    [STATUS_FILTER_DELIVERED]: TRACKING_STATUS.delivered,
    [STATUS_FILTER_RECEIVER_NOT_FOUND]: TRACKING_STATUS.deliveryFailure,
    [STATUS_FILTER_MISPLACED]: TRACKING_STATUS.lost,
    [STATUS_FILTER_RETURN_TO_SHIPPER_STARTED]:
      TRACKING_STATUS.returningToShipper,
    [STATUS_FILTER_RETURNED_TO_SHIPPER]: TRACKING_STATUS.returningCompleted,
    [STATUS_FILTER_INTERNAL_PENDING]: TRACKING_STATUS.problemNeedMoreInfo,
    [STATUS_FILTER_READY_TO_PICKUP]: TRACKING_STATUS.waitingShipperPickup,
    [STATUS_FILTER_CANCELLED]: TRACKING_STATUS.canceledByAllocation
  },
  [PACKAGE_TYPES.indispatch]: {
    [STATUS_FILTER_PENDING_POST]: TRACKING_STATUS.pickupScheduled,
    [STATUS_FILTER_POSTED]: TRACKING_STATUS.processing,
    [STATUS_FILTER_DELIVERED]: TRACKING_STATUS.delivered,
    [STATUS_FILTER_CANCELLED]: TRACKING_STATUS.canceledByAllocation
  },
  [PACKAGE_TYPES.dropOff]: {
    [STATUS_FILTER_PENDING_POST]: TRACKING_STATUS.pickupScheduled,
    [STATUS_FILTER_POSTED]: TRACKING_STATUS.processing,
    [STATUS_FILTER_IN_TRANSIT]: TRACKING_STATUS.inTransit,
    [STATUS_FILTER_PACKAGE_STOPPED]: TRACKING_STATUS.problemNeedMoreInfo,
    [STATUS_FILTER_DELIVERED]: TRACKING_STATUS.delivered,
    [STATUS_FILTER_RECEIVER_NOT_FOUND]: TRACKING_STATUS.deliveryFailure,
    [STATUS_FILTER_MISPLACED]: TRACKING_STATUS.lost,
    [STATUS_FILTER_RETURN_TO_SHIPPER_STARTED]:
      TRACKING_STATUS.returningToShipper,
    [STATUS_FILTER_RETURNED_TO_SHIPPER]: TRACKING_STATUS.returningCompleted,
    [STATUS_FILTER_INTERNAL_PENDING]: TRACKING_STATUS.problemNeedMoreInfo,
    [STATUS_FILTER_READY_TO_PICKUP]: TRACKING_STATUS.waitingShipperPickup,
    [STATUS_FILTER_CANCELLED]: TRACKING_STATUS.canceledByAllocation
  }
};

export const GROUPS_AND_STATUS_RELATIONS = {
  [GROUPING_OPTIONS.pickupPending]: [
    STATUS_FILTER_PICKUP_PENDING,
    STATUS_FILTER_ON_ROUTE_TO_PICKUP
  ],
  [GROUPING_OPTIONS.pending]: [STATUS_FILTER_PENDING_POST],
  [GROUPING_OPTIONS.withProblem]: [
    STATUS_FILTER_RECEIVER_NOT_FOUND,
    STATUS_FILTER_MISPLACED,
    STATUS_FILTER_RETURN_TO_SHIPPER_STARTED,
    STATUS_FILTER_INTERNAL_PENDING,
    STATUS_FILTER_PACKAGE_STOPPED
  ]
};

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_OPTIONS = [
  { label: 'Mais recentes primeiro', value: SORT_DESC },
  { label: 'Mais antigos primeiro', value: SORT_ASC }
];

export const GROUPING_SETTINGS = {
  [GROUPING_OPTIONS.pickupPending]: [
    TRACKING_STATUS.pickupAllocated,
    TRACKING_STATUS.pickupScheduled
  ],
  [GROUPING_OPTIONS.withProblem]: [
    TRACKING_STATUS.problemNeedMoreInfo,
    TRACKING_STATUS.deliveryFailure,
    TRACKING_STATUS.lost,
    TRACKING_STATUS.returningToShipper
  ]
};

export const DROPOFF_GROUPING_SETTINGS = {
  [GROUPING_OPTIONS.pending]: [TRACKING_STATUS.pickupScheduled],
  [GROUPING_OPTIONS.withProblem]: [
    TRACKING_STATUS.problemNeedMoreInfo,
    TRACKING_STATUS.deliveryFailure,
    TRACKING_STATUS.lost,
    TRACKING_STATUS.returningToShipper
  ]
};

export const INDISPATCH_GROUPING_SETTINGS = {
  [GROUPING_OPTIONS.pending]: [TRACKING_STATUS.pickupScheduled]
};

export const LIMIT_DAYS_FOR_POSTING = 15;
