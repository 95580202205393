import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentOriginInputed extends CRMBaseEvent {
  constructor({ shipmentOrigin }) {
    super();
    this.eventName = EVENTS.SHIPMENT_ORIGIN_INPUTED;
    this.payload = {
      shipment_origin: shipmentOrigin
    };
  }
}
