import React from 'react';
import { Accordion } from '@loggi/front-design-system';
import PropTypes from 'prop-types';
import { spacing, colors } from '@loggi/front-design-tokens';
import { Typography, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const TrackingCodeAccordion = ({ trackingCode }) => {
  const { t } = useTranslation('ui');
  return (
    <Box
      key="TrackingCodeAccordion"
      sx={{ borderBottom: `1px solid ${colors.neutrals['outline-low']}` }}
    >
      <Accordion
        arrow="start"
        title={t('shipmentDetailsV2.trackingCodeAccordion.title')}
      >
        <Box px={spacing.inset.large}>
          <Stack>
            <Typography variant="bodyHighlightMedium">
              {t('shipmentDetailsV2.trackingCodeAccordion.trackingCode')}
            </Typography>
            <Typography variant="bodyTextMedium">{trackingCode}</Typography>
          </Stack>
        </Box>
      </Accordion>
    </Box>
  );
};

TrackingCodeAccordion.propTypes = {
  trackingCode: PropTypes.string.isRequired
};

export default TrackingCodeAccordion;
