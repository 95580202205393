import { BaseDrawer } from 'UI/components';
import { ReactComponent as HandWithMobileError } from 'assets/hand-with-mobile-error.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CouponErrorDrawer = ({ isOpen, title, onClose }) => {
  const { t } = useTranslation('ui');
  return (
    <BaseDrawer
      illustration={<HandWithMobileError />}
      description={t(`couponsPageV2.errorDrawer.description`)}
      isOpen={isOpen}
      labels={{
        confirm: t('couponsPageV2.errorDrawer.confirmButton')
      }}
      onConfirmClick={onClose}
      title={title}
    />
  );
};

CouponErrorDrawer.defaultProps = {
  isOpen: false,
  title: ''
};
CouponErrorDrawer.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default { CouponErrorDrawer };
