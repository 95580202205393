import { spacing } from '@loggi/front-design-tokens';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Stack, Box, Typography, Button } from '@mui/material';
import React from 'react';

export const ShipmentDetailsDeliveryProblem = ({
  supportPage,
  isMobileDevice
}) => {
  const { t } = useTranslation('ui');

  const redirectToSupportPage = ev => {
    ev.stopPropagation();
    const url = supportPage;
    if (isMobileDevice) {
      window.open(url, '_self');

      return;
    }

    window.open(url, '_blank', 'noopener');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="left"
      px={spacing.inset.small}
      py={spacing.inset.large}
      gap={spacing.stack.nano}
    >
      <Stack pl={spacing.inset.xxsmall} gap={spacing.stack.nano}>
        <Typography variant="subtitleMedium">
          {t('shipmentDetailsV2.deliveryProblems.title')}
        </Typography>
        <Typography variant="bodyTextMedium">
          {t('shipmentDetailsV2.deliveryProblems.text')}
        </Typography>
      </Stack>
      <Box>
        <Button onClick={redirectToSupportPage} size="medium" variant="text">
          {t('shipmentDetailsV2.deliveryProblems.button')}
        </Button>
      </Box>
    </Box>
  );
};

ShipmentDetailsDeliveryProblem.propTypes = {
  supportPage: PropTypes.string.isRequired,
  isMobileDevice: PropTypes.bool.isRequired
};

export default ShipmentDetailsDeliveryProblem;
