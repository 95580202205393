import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { blue, grey } from 'theme/colors';

const PackagingOption = ({ isSelected, onClick, Icon, title, description }) => {
  return (
    <Box
      onClick={onClick}
      alignItems="center"
      borderRadius="8px"
      border={`2px solid ${isSelected ? blue[500] : grey[50]}`}
      padding={2}
      display="flex"
      gap={2}
      width="100%"
    >
      <Box height="80px">
        <Icon />
      </Box>
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography align="left" variant="body1" fontWeight="bold">
          {title}
        </Typography>
        <Typography align="left" variant="body2" fontWeight="medium">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

PackagingOption.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default PackagingOption;
