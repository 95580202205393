/* eslint-disable max-classes-per-file */
import WorkingHours from './working-hours.model';

class ServiceLocationAddress {
  constructor(params) {
    this.street = params.street;
    this.number = params.number;
    this.neighborhood = params.neighborhood;
    this.city = params.city;
    this.state = params.state;
    this.complement = params.complement;
  }

  formattedAddress() {
    return [
      `${this.street}, ${this.number}`,
      `${this.neighborhood} • ${this.city} - ${this.state}`,
      `${this.complement}`
    ].join(`\n`);
  }

  static fromJSON(json) {
    return new ServiceLocationAddress({
      street: json.correiosAddress.logradouro,
      number: json.correiosAddress.numero,
      neighborhood: json.correiosAddress.bairro,
      city: json.correiosAddress.cidade,
      state: json.correiosAddress.uf,
      complement: json.correiosAddress.complemento
    });
  }
}

class WorkingTime {
  /**
   * @param {WorkingHours[]} workingTime
   */
  constructor(workingTime) {
    this.workingTime = workingTime;
  }

  formattedWorkingTime() {
    return this.workingTime.map(wh => wh.formattedWorkingHours()).join(' — ');
  }

  static fromJSON(json) {
    return new WorkingTime(json.workingTime.map(wh => new WorkingHours(wh)));
  }
}

export default class ServiceLocationModel {
  /**
   * @param {{
   * name: string,
   * distanceFromOrigin: number|string,
   * address: ServiceLocationAddress,
   * workingTime: WorkingTime
   * }} params
   */
  constructor(params) {
    this.name = params.name;
    this.distanceFromOrigin = params.distanceFromOrigin;
    this.address = params.address;
    this.workingTime = params.workingTime;
  }

  formattedAddress() {
    return this.address.formattedAddress();
  }

  formattedWorkingTime() {
    return this.workingTime.formattedWorkingTime();
  }

  formattedDistance() {
    const distance = parseInt(this.distanceFromOrigin, 10);

    if (distance < 1000) return `${distance} m`;
    return `${(distance / 1000).toFixed(1)} km`;
  }

  static fromJSON(json) {
    return new ServiceLocationModel({
      name: json.name,
      distanceFromOrigin: json.distanceFromOrigin,
      address: ServiceLocationAddress.fromJSON(json.serviceLocationAddress),
      workingTime: WorkingTime.fromJSON(json)
    });
  }
}
