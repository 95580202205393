import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { getPackageSizeDimensions } from 'UI/shipment/state-machine/utils';
import { getServiceTypeValue } from 'crm/utils';

export default class PageSenderViewed extends CRMBaseEvent {
  constructor({
    originFullAddress,
    destinationFullAddress,
    price,
    slo,
    serviceType,
    goodsvalue,
    dimensions
  }) {
    super();
    this.eventName = EVENTS.PAGE_SENDER_VIEWED;
    this.payload = {
      shipment_type: serviceType,
      slo,
      origin_full_address: originFullAddress,
      destination_full_address: destinationFullAddress,
      price: price ? Number(price) : undefined,
      goodsvalue: goodsvalue ? Number(goodsvalue) : undefined,
      dimensions
    };
  }

  static fromShipmentContext({ context, quoting }) {
    return new PageSenderViewed({
      serviceType: getServiceTypeValue(context.serviceType),
      originFullAddress:
        context.pickupAddress?.description ||
        context.originAddress.description(),
      destinationFullAddress:
        context.deliveryAddress?.description ||
        context.destinationAddress.description(),
      price: quoting?.price,
      slo: quoting?.slo,
      goodsvalue: context.insurance,
      dimensions: getPackageSizeDimensions(context)
    });
  }
}
