import { get } from 'lodash';
import Validator from '../validator';

const comparisonKeysByForm = {
  delivery: 'pickup.address',
  'budget-pickup-form': 'delivery',
  pickup: 'delivery'
};

export default class EqualAddressesValidator extends Validator {
  static validate({ name, state, storedValues }) {
    const { address } = state;
    const otherAddress = get(storedValues, comparisonKeysByForm[name], null);

    if (!otherAddress) return true;
    return address.placeId !== otherAddress.placeId;
  }
}
