export const PACKAGE_SIZE_P = 'P';
export const PACKAGE_SIZE_M = 'M';
export const PACKAGE_SIZE_G = 'G';

/**
 * @typedef {{
 * heightCm: number,
 * lengthCm: number,
 * sizeCm: number,
 * weight: number,
 * weightG: number,
 * widthCm: number
 * }} OLD_PACKAGE_SIZE
 */

export const PACKAGE_SIZES = {
  /** @type {OLD_PACKAGE_SIZE} */
  [PACKAGE_SIZE_P]: {
    sizeCm: 10,
    weight: 301,
    // the weight shown to the user
    weightG: 2 * 1000,
    // these 3 fields are required by shipping and quoting API,
    // and should hold the same value as 'sizeCm
    heightCm: 10,
    lengthCm: 13,
    widthCm: 10
  },
  /** @type {OLD_PACKAGE_SIZE} */
  [PACKAGE_SIZE_M]: {
    heightCm: 25,
    lengthCm: 25,
    sizeCm: 25,
    weight: 2501,
    weightG: 6 * 1000,
    widthCm: 25
  },
  /** @type {OLD_PACKAGE_SIZE} */
  [PACKAGE_SIZE_G]: {
    heightCm: 38,
    lengthCm: 40,
    sizeCm: 38,
    weight: 10 * 1000 + 1,
    weightG: 10 * 1000,
    widthCm: 39
  }
};
