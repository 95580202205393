import Validator from '../validator';

const isCEPValid = cep => {
  if (typeof cep !== 'string') return false;
  const rawCep = cep.replace(/\D/g, '');
  const { length } = rawCep;
  return length === 8;
};

export default class CepValidator extends Validator {
  static validate(string) {
    return isCEPValid(string);
  }
}
