import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as EmptyTrackingIllustration } from 'assets/empty-tracking.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function TrackingErrorMessage({ onTryAgain }) {
  const { t } = useTranslation('ui');
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      textAlign="center"
    >
      <Box height="80px" mb={2} width="80px">
        <EmptyTrackingIllustration />
      </Box>
      <Typography component="h2" variant="subtitle1">
        {t('trackingErrorMessage.title')}
      </Typography>
      <Box mb={3} mt={2}>
        <Typography>{t('trackingErrorMessage.message')}</Typography>
      </Box>
      <Button color="primary" onClick={onTryAgain}>
        {t('trackingErrorMessage.tryAgain')}
      </Button>
    </Box>
  );
}

TrackingErrorMessage.propTypes = {
  onTryAgain: PropTypes.func.isRequired
};

export default TrackingErrorMessage;
