import * as Yup from 'yup';

import { isValid as isCpfValid } from 'cpf';
import { validate as isCnpjValid } from 'cnpj';

import { SchemaValidator } from './schema-validator.model';
import { Address } from './address.model';

export function validateCnpjCpf(cnpjCpf) {
  return isCnpjValid(cnpjCpf) || isCpfValid(cnpjCpf);
}

export class Recipient extends SchemaValidator {
  constructor({ cnpjCpf, name, phone, address = new Address() } = {}) {
    super();
    this.cnpjCpf = cnpjCpf;
    this.name = name;
    this.phone = phone;
    this.address = new Address(address);
  }

  static get schema() {
    return Yup.object().shape({
      cnpjCpf: Yup.string().test(
        'cnpjCpf',
        'Not a valid CNPJ or CPF',
        validateCnpjCpf
      ),
      name: Yup.string()
        .min(2)
        .max(64)
        .required(),
      phone: Yup.string()
        .max(30)
        .required(),
      address: Address.schema.required()
    });
  }
}

export default Recipient;
