import { AmateurQuoting } from './amateur-quoting.model';

export class AmateurQuotingResponse {
  constructor({ availableServices }) {
    /** @type {Object.<string, AmateurQuoting>} */
    this.availableServices = availableServices;
    this.length = Object.values(availableServices).length;
  }

  static parse(apiResponse) {
    const availableServices = {};
    apiResponse.forEach(rawAmateurQuoting => {
      const quoting = new AmateurQuoting(rawAmateurQuoting);
      availableServices[quoting.serviceName] = quoting;
    });
    return new AmateurQuotingResponse({ availableServices });
  }
}

export default { AmateurQuotingResponse };
