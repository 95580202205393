import { Box, styled, Typography } from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator
} from '@mui/lab';
import {
  TRACKING_STATUS,
  TRACKING_STATUS_TITLE
} from 'constants/tracking.constants';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { grey } from 'theme/colors';
import HistoryIcon from 'UI/pages/tracking/history/icon/icon.component';

const TrackingTimelineItem = styled(TimelineItem)({
  '&::before': {
    display: 'none'
  }
});

const TrackingTimeline = styled(Timeline)({
  padding: 'unset',
  margin: 'unset'
});

const TrackingTimelineConnector = styled(TimelineConnector)({
  backgroundColor: grey[50]
});

const formatDate = date => format(new Date(date), 'dd/MM, HH:mm');

const buildLabelSentStatus = (status, t) => {
  return {
    occasionalShipperStatusCode: TRACKING_STATUS.labelSent,
    updateTime: status.updateTime,
    title: t(TRACKING_STATUS_TITLE[TRACKING_STATUS.labelSent])
  };
};

const buildTrackingCodeSentStatus = (status, t) => {
  return {
    occasionalShipperStatusCode: TRACKING_STATUS.trackingCodeSent,
    updateTime: status.updateTime,
    title: t(TRACKING_STATUS_TITLE[TRACKING_STATUS.trackingCodeSent])
  };
};

const replaceStatusCode = (
  trackingHistory,
  statusCodeToBeReplaced,
  newStatusCode
) => {
  return trackingHistory.map(history => {
    if (history.occasionalShipperStatusCode === statusCodeToBeReplaced) {
      return {
        ...history,
        occasionalShipperStatusCode: newStatusCode
      };
    }
    return history;
  });
};

const buildIndispatchHistory = (trackingHistory, t) => {
  const trackingHistoryWithIndispatch = replaceStatusCode(
    trackingHistory,
    TRACKING_STATUS.pickupScheduled,
    TRACKING_STATUS.indispatch
  );

  const indispatchStatus = trackingHistoryWithIndispatch.find(
    status => status.occasionalShipperStatusCode === TRACKING_STATUS.indispatch
  );
  const initialStatuses = [
    buildTrackingCodeSentStatus(indispatchStatus, t),
    buildLabelSentStatus(indispatchStatus, t)
  ];

  return [...trackingHistoryWithIndispatch, ...initialStatuses];
};

const buildDropoffHistory = (trackingHistory, t) => {
  const trackingHistoryWithDropOff = replaceStatusCode(
    trackingHistory,
    TRACKING_STATUS.pickupScheduled,
    TRACKING_STATUS.dropOff
  );

  const dropOffStatus = trackingHistoryWithDropOff.find(
    status => status.occasionalShipperStatusCode === TRACKING_STATUS.dropOff
  );
  const initialStatuses = [buildLabelSentStatus(dropOffStatus, t)];

  return [...trackingHistoryWithDropOff, ...initialStatuses];
};

const TrackingHistory = ({
  trackingHistory,
  isDropoffPackage,
  isIndispatchPackage
}) => {
  const { t } = useTranslation('ui');

  const getEnrichedTrackingHistory = () => {
    if (isDropoffPackage) return buildDropoffHistory(trackingHistory, t);

    if (isIndispatchPackage) return buildIndispatchHistory(trackingHistory, t);

    return trackingHistory;
  };

  const enrichedTrackingHistory = getEnrichedTrackingHistory();

  const lastIndex = enrichedTrackingHistory.length - 1;

  const contentPadding = (index = 0) =>
    `0 0 ${index !== lastIndex ? 24 : 0}px 16px`;

  return (
    <Box px={3} py={6}>
      <Box mb={6}>
        <Typography variant="subtitle1" data-testid="history-title">
          {t('history.title')}
        </Typography>
      </Box>

      <TrackingTimeline align="left">
        {enrichedTrackingHistory.map((item, index) => (
          <TrackingTimelineItem key={item.occasionalShipperStatusCode}>
            <TimelineSeparator>
              <HistoryIcon status={item.occasionalShipperStatusCode} />
              {index !== lastIndex && <TrackingTimelineConnector />}
            </TimelineSeparator>

            <TimelineContent style={{ padding: contentPadding(index) }}>
              <Box display="flex" flexDirection="column" width="100%">
                <Box mb="4px">
                  <strong>{item.title}</strong>
                </Box>

                <Box color={grey[200]}>
                  <Typography variant="body2">
                    {item.description ?? formatDate(item.updateTime)}
                  </Typography>
                </Box>
              </Box>
            </TimelineContent>
          </TrackingTimelineItem>
        ))}
      </TrackingTimeline>
    </Box>
  );
};

TrackingHistory.defaultProps = {
  trackingHistory: [],
  isDropoffPackage: false,
  isIndispatchPackage: false
};

TrackingHistory.propTypes = {
  trackingHistory: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.shape({
        occasionalShipperStatusCode: PropTypes.oneOf(
          Object.values(TRACKING_STATUS)
        ).isRequired,
        title: PropTypes.string.isRequired,
        updateTime: PropTypes.string.isRequired
      })
    })
  ),
  isDropoffPackage: PropTypes.bool,
  isIndispatchPackage: PropTypes.bool
};

export default TrackingHistory;
