import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue, root } from 'theme/colors';
import { Button } from 'UI/components';
import InfoLine from '../info-line/info-line.component';

const DeliveryInfo = ({ address, name, onEdit }) => {
  const { t } = useTranslation('ui');

  return (
    <Box bgcolor={root[0]} display="flex" flexDirection="column">
      <InfoLine icon="userProfile">
        <Typography>{name}</Typography>
      </InfoLine>
      <Box height="24px" />
      <InfoLine icon="pin">
        <Typography>{address}</Typography>
      </InfoLine>
      {!!onEdit && (
        <>
          <Box height="24px" />
          <Button
            fullWidth
            label={t('deliveryInfo.edit')}
            onClick={onEdit}
            textColor={blue[500]}
            variant="outlined"
          />
        </>
      )}
    </Box>
  );
};

DeliveryInfo.defaultProps = {
  onEdit: null
};

DeliveryInfo.propTypes = {
  address: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onEdit: PropTypes.func
};

export default DeliveryInfo;
