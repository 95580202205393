import { ClickAwayListener, Tooltip } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { root } from 'theme/colors';
import LineInputBase from '../line-input-base.component';
import {
  formatCpfCnpj,
  onlyDigits
} from './delivery-customer-document-input.helper';

const DeliveryCustomerDocumentTooltip = ({
  isOpen,
  onClose,
  onOpen,
  title
}) => (
  <ClickAwayListener onClickAway={onClose}>
    <Tooltip arrow open={isOpen} title={title}>
      <ErrorOutlineOutlinedIcon
        data-testid="delivery-customer-document-help-trigger"
        style={{ color: root[0] }}
        onClick={onOpen}
      />
    </Tooltip>
  </ClickAwayListener>
);

const DeliveryCustomerDocumentInput = ({
  error,
  onChange,
  placeholder,
  inputRef,
  inputProps,
  className,
  onEnterKeyDown,
  value,
  variant
}) => {
  const { t } = useTranslation('ui');
  const [valueInputProp, setValueInputProp] = React.useState(
    value ? formatCpfCnpj(value) : ''
  );
  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = ({ target }) => {
    const digits = onlyDigits(String(target.value));

    setValueInputProp(formatCpfCnpj(digits));
    onChange({ target: { ...target, value: digits } });
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <LineInputBase
      className={className}
      error={error}
      omitFromHotjar
      onChange={handleChange}
      onEnterKeyDown={onEnterKeyDown}
      placeholder={placeholder}
      inputProps={{
        ...inputProps,
        'data-testid': 'delivery-customer-document-input',
        inputMode: 'numeric',
        maxLength: 18,
        value: valueInputProp
      }}
      endAdornment={
        <DeliveryCustomerDocumentTooltip
          isOpen={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          title={t('deliveryCustomerDocument.information')}
        />
      }
      inputRef={inputRef}
      variant={variant}
    />
  );
};

DeliveryCustomerDocumentInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  inputProps: PropTypes.shape({}),
  inputRef: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onEnterKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string
};

DeliveryCustomerDocumentInput.defaultProps = {
  className: '',
  error: '',
  placeholder: '',
  onEnterKeyDown: null,
  inputRef: {},
  inputProps: {},
  value: '',
  variant: 'primary'
};

DeliveryCustomerDocumentTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default DeliveryCustomerDocumentInput;
