import EVENTS from 'crm/constants';
import CRMBaseEvent from 'crm/entities/events/crm-base-event';

export default class PagePickupScheduleViewed extends CRMBaseEvent {
  constructor() {
    super();
    this.eventName = EVENTS.PAGE_PICKUP_SCHEDULE_VIEWED;
    this.payload = {};
  }

  static fromShipmentContext() {
    return new PagePickupScheduleViewed();
  }
}
