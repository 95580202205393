import supportApi from 'service/support-api';

export const createSupportTicketUseCase = ({
  companyId,
  loggiKey,
  phoneNumber,
  reason,
  details
}) => {
  return supportApi.createTicket({
    companyId,
    loggiKey,
    phoneNumber,
    reason,
    details
  });
};

export default { createSupportTicketUseCase };
