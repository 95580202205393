import CRMAdapterFactory from 'crm/services/crm-adapter-factory';
import EVENTS from 'crm/constants';

/** @typedef {import('crm/entities/crm-base-entity.model').default} CRMBaseEntity */
/** @typedef {import('crm/entities/events/crm-base-event').default} CRMBaseEvent */
/** @typedef {import('crm/entities/revenue-events/crm-base-revenue-event').default} CRMBaseRevenueEvent */

/**
 * Class to handle sending user data and events to CRM platforms.
 *
 * Each new CRM adapter have to be implemented as an extension from
 * CRMBaseAdapter, with both sendUser and sendEvent class methods,
 * while also mapping which events it can handle inside a get events() method.
 *
 * If a new adapter is created, it must be added to the CRMAdapterFactory in order
 * to be used by CRMService.
 *
 * @example
 * ```js
 * class RandomAdapter extends CRMBaseAdapter {
 *   get events() {
 *    return [EVENTS.SEND_USER, EVENTS.SOME_SPECIFIC_EVENT];
 *   }
 *
 *   sendUser(user) { }
 *
 *   sendEvent(eventName, eventData) { }
 * }
 *
 * class AnotherRandomAdapter extends CRMBaseAdapter {
 *    get events() {
 *     return [EVENTS.SEND_USER];
 *    }
 *
 *   sendUser(user) { }
 *
 *   sendEvent(eventName, eventData) { }
 * }
 *
 * // Considering the following code will create a CRMService with the two hypothetical adapters above:
 * const crmService = new CRMService();
 * const crmUser = new CRMUser(attributes);
 * crmService.sendUser(crmUser);
 * crmService.sendEvent(EVENTS.SOME_SPECIFIC_EVENT, eventData)
 * ```
 * The crmService.sendUser method will call adapter.sendUser on both adapters, while the
 * crmService.sendEvent will call adapter.sendEvent only on the first adapter.
 */

export default class CRMService {
  constructor() {
    this.adapters = CRMAdapterFactory.createAdapters();
  }

  /**
   * @param {CRMBaseEvent} event
   * @param {object} eventPayload
   */
  sendEvent({ eventName }, eventPayload) {
    this.adapters
      .filter(adapter => adapter.canHandleEvent(eventName))
      .forEach(adapter =>
        eventName === EVENTS.SEND_USER
          ? adapter.sendUser({ ...eventPayload })
          : adapter.sendEvent(eventName, { ...eventPayload })
      );
  }

  /**
   * @param {CRMBaseRevenueEvent} event
   * @param {object} eventPayload
   */
  sendRevenueEvent({ eventName, amount }, eventPayload) {
    this.adapters
      .filter(adapter => adapter.canHandleRevenue(eventName))
      .forEach(adapter =>
        adapter.sendRevenueEvent(eventName, amount, { ...eventPayload })
      );
  }
}
