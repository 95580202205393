import { trimStart } from 'lodash';

/**
 * minimum size to represent
 * the initial value 0,00
 */
export const INITIAL_WIDTH = 100;
export const MAX_WIDTH = 300;
export const CHARACTER_PIXEL_MULTIPLIER = 24;

/**
 * value >= 500 -> 8% cashBack
 * value >= 250 -> 6% cashBack
 * value >= 100 -> 4% cashBack
 * value >= 50 -> 2% cashBack
 * value < 50 -> 0 cashBack
 * @param {number} value
 * @returns {number | null}
 */
export const calcCashBack = value => {
  let cashBack;
  if (value < 50) return null;

  if (value >= 500) {
    cashBack = (value * 8) / 100;
  } else if (value >= 250) {
    cashBack = (value * 6) / 100;
  } else if (value >= 100) {
    cashBack = (value * 4) / 100;
  } else {
    cashBack = (value * 2) / 100;
  }
  return cashBack;
};

/**
 * calc the dynamic input width
 * @param {number | string} value
 * @returns {number}
 */
export const calcDynamicWidth = value => {
  return Math.max(
    INITIAL_WIDTH,
    CHARACTER_PIXEL_MULTIPLIER * value.toString().length
  );
};

/**
 * @param {number | string} value
 * @returns {string}
 */
export const formatValueToCurrency = value => {
  return parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

/**
 *
 * @param {number | string} value
 * @returns {string}
 */
export const formatInput = value => {
  let onlyDigits = trimStart(value.replace(/\D/g, ''), '0');
  if (onlyDigits.length === 0) {
    // Default value
    onlyDigits = '0';
  }
  const result = parseFloat(onlyDigits) / 100;
  return result.toFixed(2);
};

export default {
  INITIAL_WIDTH,
  MAX_WIDTH,
  calcCashBack,
  calcDynamicWidth,
  formatInput,
  formatValueToCurrency
};
