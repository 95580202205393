import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { grey, blue } from 'theme/colors';
import { copyToClipboard } from 'utils';
import { SvgIcon } from '../svg-icon/svg-icon.component';

export default function CopyToClipboard({ text, onCopy }) {
  const handleClick = () =>
    copyToClipboard(text)
      .then(() => {
        if (onCopy) {
          onCopy();
        }
      })
      .catch(error => {
        captureException(error);
      });

  return (
    <Box
      bgcolor={grey[50]}
      width="100%"
      py="12px"
      px="24px"
      borderRadius="64px"
      position="relative"
    >
      <Typography variant="body1">
        <strong>{text}</strong>
      </Typography>
      <Button
        data-testid="copy-to-clipboard"
        type="button"
        onClick={handleClick}
        style={{ position: 'absolute', right: 0, top: 0, bottom: 0 }}
      >
        <SvgIcon icon="copyPaste" color={blue[500]} />
      </Button>
    </Box>
  );
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  onCopy: PropTypes.func
};

CopyToClipboard.defaultProps = {
  onCopy: null
};
