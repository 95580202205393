export const COMPANY_SEGMENT_PROTO_VALUES = {
  SEGMENT_INVALID: 0,
  SEGMENT_UNSET: 1,
  SEGMENT_ARTIGOS_ESPORTIVOS: 2,
  SEGMENT_JOIAS_SEMI_JOIAS_E_BIJUTERIAS: 3,
  SEGMENT_VESTUARIO_E_MODA: 4,
  SEGMENT_CALCADOS: 5,
  SEGMENT_OTICAS: 6,
  SEGMENT_ALIMENTOS: 7,
  SEGMENT_ELETRODOMESTICOS: 8,
  SEGMENT_MOVEIS_E_DECORACAO: 9,
  SEGMENT_CAMA_MESA_E_BANHO: 10,
  SEGMENT_CONSTRUCAO_E_HOMECENTER: 11,
  SEGMENT_PETSHOP_E_PETCENTER: 12,
  SEGMENT_AGRONEGOCIO: 13,
  SEGMENT_COSMETICOS_E_PERFUMARIA: 14,
  SEGMENT_FARMACIAS: 15,
  SEGMENT_HOSPITALAR_E_CLINICAS: 16,
  SEGMENT_SEX_SHOP: 17,
  SEGMENT_SERVICOS_FINANCEIROS: 18,
  SEGMENT_PAPELARIA_E_IMPRESSAO_GRAFICA: 19,
  SEGMENT_LIVRARIA: 20,
  SEGMENT_JOGOS_E_BRINQUEDOS: 21,
  SEGMENT_SERVICOS_LOGISTICOS: 22,
  SEGMENT_MAQUINAS_E_EQUIPAMENTOS: 23,
  SEGMENT_AUTOMOTIVO: 24,
  SEGMENT_SERVICOS_GERAIS: 25,
  SEGMENT_ELETRONICOS_E_INFORMATICA: 26,
  SEGMENT_TOP_PLAYER: 27
};

export const segmentOptions = {
  SEGMENT_ARTIGOS_ESPORTIVOS: 'Artigos Esportivos',
  SEGMENT_JOIAS_SEMI_JOIAS_E_BIJUTERIAS: 'Joias, Semi-Joias e Bijuterias',
  SEGMENT_VESTUARIO_E_MODA: 'Vestuário e Moda',
  SEGMENT_CALCADOS: 'Calçados',
  SEGMENT_OTICAS: 'Óticas',
  SEGMENT_ALIMENTOS: 'Alimentos',
  SEGMENT_ELETRODOMESTICOS: 'Eletrodomésticos',
  SEGMENT_MOVEIS_E_DECORACAO: 'Móveis e Decoração',
  SEGMENT_CAMA_MESA_E_BANHO: 'Cama, Mesa e Banho',
  SEGMENT_CONSTRUCAO_E_HOMECENTER: 'Construção e HomeCenter',
  SEGMENT_PETSHOP_E_PETCENTER: 'PetShop e PetCenter',
  SEGMENT_AGRONEGOCIO: 'Agronegócio',
  SEGMENT_COSMETICOS_E_PERFUMARIA: 'Cosméticos e Perfumaria',
  SEGMENT_FARMACIAS: 'Farmácias',
  SEGMENT_HOSPITALAR_E_CLINICAS: 'Hospitalar e Clínicas',
  SEGMENT_SEX_SHOP: 'Sex Shop',
  SEGMENT_SERVICOS_FINANCEIROS: 'Serviços Financeiros',
  SEGMENT_PAPELARIA_E_IMPRESSAO_GRAFICA: 'Papelaria e Impressão Gráfica',
  SEGMENT_LIVRARIA: 'Livraria',
  SEGMENT_JOGOS_E_BRINQUEDOS: 'Jogos e Brinquedos',
  SEGMENT_SERVICOS_LOGISTICOS: 'Serviços Logísticos',
  SEGMENT_MAQUINAS_E_EQUIPAMENTOS: 'Máquinas e Equipamentos',
  SEGMENT_AUTOMOTIVO: 'Automotivo',
  SEGMENT_SERVICOS_GERAIS: 'Serviços Gerais',
  SEGMENT_ELETRONICOS_E_INFORMATICA: 'Eletrônicos e Informática'
};

export const segmentInputOptions = [
  {
    label: segmentOptions.SEGMENT_ARTIGOS_ESPORTIVOS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_ARTIGOS_ESPORTIVOS
  },
  {
    label: segmentOptions.SEGMENT_JOIAS_SEMI_JOIAS_E_BIJUTERIAS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_JOIAS_SEMI_JOIAS_E_BIJUTERIAS
  },
  {
    label: segmentOptions.SEGMENT_VESTUARIO_E_MODA,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_VESTUARIO_E_MODA
  },
  {
    label: segmentOptions.SEGMENT_CALCADOS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_CALCADOS
  },
  {
    label: segmentOptions.SEGMENT_OTICAS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_OTICAS
  },
  {
    label: segmentOptions.SEGMENT_ALIMENTOS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_ALIMENTOS
  },
  {
    label: segmentOptions.SEGMENT_ELETRODOMESTICOS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_ELETRODOMESTICOS
  },
  {
    label: segmentOptions.SEGMENT_MOVEIS_E_DECORACAO,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_MOVEIS_E_DECORACAO
  },
  {
    label: segmentOptions.SEGMENT_CAMA_MESA_E_BANHO,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_CAMA_MESA_E_BANHO
  },
  {
    label: segmentOptions.SEGMENT_CONSTRUCAO_E_HOMECENTER,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_CONSTRUCAO_E_HOMECENTER
  },
  {
    label: segmentOptions.SEGMENT_PETSHOP_E_PETCENTER,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_PETSHOP_E_PETCENTER
  },
  {
    label: segmentOptions.SEGMENT_AGRONEGOCIO,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_AGRONEGOCIO
  },
  {
    label: segmentOptions.SEGMENT_COSMETICOS_E_PERFUMARIA,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_COSMETICOS_E_PERFUMARIA
  },
  {
    label: segmentOptions.SEGMENT_FARMACIAS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_FARMACIAS
  },
  {
    label: segmentOptions.SEGMENT_HOSPITALAR_E_CLINICAS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_HOSPITALAR_E_CLINICAS
  },
  {
    label: segmentOptions.SEGMENT_SEX_SHOP,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_SEX_SHOP
  },
  {
    label: segmentOptions.SEGMENT_SERVICOS_FINANCEIROS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_SERVICOS_FINANCEIROS
  },
  {
    label: segmentOptions.SEGMENT_PAPELARIA_E_IMPRESSAO_GRAFICA,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_PAPELARIA_E_IMPRESSAO_GRAFICA
  },
  {
    label: segmentOptions.SEGMENT_LIVRARIA,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_LIVRARIA
  },
  {
    label: segmentOptions.SEGMENT_JOGOS_E_BRINQUEDOS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_JOGOS_E_BRINQUEDOS
  },
  {
    label: segmentOptions.SEGMENT_SERVICOS_LOGISTICOS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_SERVICOS_LOGISTICOS
  },
  {
    label: segmentOptions.SEGMENT_MAQUINAS_E_EQUIPAMENTOS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_MAQUINAS_E_EQUIPAMENTOS
  },
  {
    label: segmentOptions.SEGMENT_AUTOMOTIVO,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_AUTOMOTIVO
  },
  {
    label: segmentOptions.SEGMENT_SERVICOS_GERAIS,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_SERVICOS_GERAIS
  },
  {
    label: segmentOptions.SEGMENT_ELETRONICOS_E_INFORMATICA,
    value: COMPANY_SEGMENT_PROTO_VALUES.SEGMENT_ELETRONICOS_E_INFORMATICA
  }
];

export const sortedInputOptions = [...segmentInputOptions].sort(
  (segmentOption, anotherSegmentOption) =>
    segmentOption.label
      .toLowerCase()
      .localeCompare(anotherSegmentOption.label.toLowerCase())
);
