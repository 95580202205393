import {
  borders,
  colors,
  spacing,
  typography
} from '@loggi/front-design-tokens';
import { Icon } from '@loggi/loggi-icons';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Button as MuiButton,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import { Button, InfiniteLoading, StepFormWrapper } from 'UI/components';
import { useUserRecords } from 'UI/contexts';
import getStyle from 'UI/pages/utils/utils';
import { ReactComponent as LogoLoggiWithName } from 'assets/logo-loggi-with-name.svg';
import AccessStarted from 'crm/entities/events/access-started/access-started';
import { ValidatorBuilder } from 'infra';
import {
  isAndroid as isLoggiAndroid,
  isIOS as isLoggiIOS
} from 'mobile/native.helpers';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { blue, brand, grey } from 'theme/colors';
import useStyles from 'theme/custom-classes';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { isInApp } from 'utils/in-app-detector';
import AppleSignInButton from './apple-sign-in-button/apple-sign-in-button.component';
import GoogleSignInButton from './google-sign-in-button/google-sign-in-button.component';

export const PROVIDERS = {
  GOOGLE: 'Google',
  APPLE: 'SignInWithApple'
};
export const ACCESS_EMAIL_LOGIN = 'email_login';
const HELP_LINK = 'https://ajuda.loggi.com/hc/pt-br?utm_source=beyond';
const SLIDE_TRANSITION_TIMEOUT = 640;

function isLoggiNative() {
  return isLoggiAndroid() || isLoggiIOS();
}

const SignIn = ({
  federatedSignIn,
  onForgotPassword,
  onSubmit,
  onSignUpClick
}) => {
  const { t } = useTranslation('ui');
  const isDesktop = !checkIsMobile();
  const [isForgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const { userRecords, setUserRecords } = useUserRecords();
  const [loading, setLoading] = useState(false);
  const [
    showModalLeaveSocialMediaBrowser,
    setShowModalLeaveSocialMediaBrowser
  ] = useState(false);
  const classes = useStyles({ color: brand[100] });

  const isInAppBesidesLoggi = useMemo(() => {
    return isInApp(window.navigator.userAgent) && !isLoggiNative();
  }, []);

  const fields = {
    email: {
      label: t('signIn.emailInputPlaceholder'),
      placeholder: t('signIn.emailInputPlaceholder'),
      type: 'email',
      value: userRecords.email,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .emailPattern('signIn.errorMessages.emailInvalid')
        .build()
    },
    password: {
      label: t('signIn.passwordInputPlaceholder'),
      placeholder: t('signIn.passwordInputPlaceholder'),
      type: 'password',
      validator: new ValidatorBuilder()
        .nonEmptyString('signIn.errorMessages.passwordEmpty')
        .build()
    }
  };

  const handleStepChange = (step, { email }) => {
    if (email) setUserRecords({ ...userRecords, email });
    if (step === 1) {
      setForgotPasswordVisible(true);
      return;
    }
    setForgotPasswordVisible(false);
  };

  const handleSubmit = async userCredentials => {
    new AccessStarted({ originAccess: ACCESS_EMAIL_LOGIN }).sendToCrm();
    setLoading(true);
    const result = await onSubmit(userCredentials);
    if (!result) setLoading(false);
  };

  const handleFederatedSignIn = provider => {
    /* workarround to force login on device browser */
    if (isInAppBesidesLoggi && provider === PROVIDERS.GOOGLE) {
      return setShowModalLeaveSocialMediaBrowser(true);
    }

    new AccessStarted({ originAccess: provider }).sendToCrm();
    setLoading(true);
    return federatedSignIn(provider);
  };

  return (
    <Box style={{ ...getStyle(isDesktop), overflowY: 'auto' }}>
      {!loading && (
        <Box display="flex" flexDirection="column">
          <Box position="relative">
            <Box className={classes.header}>
              <LogoLoggiWithName />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            px={3}
            pt={3}
          >
            <Box>
              <Typography variant="h2">{t('signIn.title')}</Typography>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                my={3}
              >
                <StepFormWrapper
                  fields={fields}
                  onSubmit={handleSubmit}
                  isInputLabelVisible={false}
                  onStepChange={handleStepChange}
                  buttonLabel={t('signIn.signInButton')}
                />
                {isForgotPasswordVisible && (
                  <Box mt={2}>
                    <Button
                      fullWidth
                      label={t('signIn.forgotPasswordButton')}
                      onClick={() => onForgotPassword(userRecords.email)}
                      textColor={blue[500]}
                      variant="outlined"
                    />
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <Box color={grey[200]} textAlign="center">
                  <Typography variant="body2">
                    {t('signIn.alternativesLabel')}
                  </Typography>
                </Box>
                <Box my={2}>
                  <GoogleSignInButton
                    onClick={() => handleFederatedSignIn(PROVIDERS.GOOGLE)}
                  />
                </Box>
                <AppleSignInButton
                  onClick={() => handleFederatedSignIn(PROVIDERS.APPLE)}
                />
              </Box>
              <Box my={3}>
                <Divider />
              </Box>
              <Box color={grey[200]} mb={3} textAlign="center">
                <Typography variant="body2">
                  {t('signIn.signUpLabel')}
                </Typography>
              </Box>
              <Box mb={3}>
                <Button
                  id="initial_signup_button"
                  fullWidth
                  label={t('signIn.signUpButton')}
                  textColor={blue[500]}
                  onClick={onSignUpClick}
                  variant="outlined"
                />
              </Box>
            </Box>
            <Box color={grey[200]} pb={3} textAlign="center">
              <Typography variant="body2">
                <Trans
                  t={t}
                  i18nKey="signIn.helpLabel"
                  components={[
                    <Link
                      href={HELP_LINK}
                      color="primary"
                      underline="none"
                      target="_blank"
                    />
                  ]}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Slide
        direction="left"
        in={loading}
        mountOnEnter
        timeout={SLIDE_TRANSITION_TIMEOUT}
        unmountOnExit
      >
        <Box
          display="flex"
          height={isDesktop ? '100%' : '100vh'}
          justifyContent="center"
        >
          <InfiniteLoading
            title={t('signIn.loadingScreen.title')}
            description={t('signIn.loadingScreen.description')}
          />
        </Box>
      </Slide>
      <AskUserToOpenOnBrowser
        isOpen={showModalLeaveSocialMediaBrowser}
        onClickClose={() => setShowModalLeaveSocialMediaBrowser(false)}
      />
    </Box>
  );
};

SignIn.propTypes = {
  federatedSignIn: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSignUpClick: PropTypes.func.isRequired
};

function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

// this component is a hotfix workarround, and was made in hurry not 100% following code patterns
const AskUserToOpenOnBrowser = ({ isOpen, onClickClose }) => {
  const openOnBrowserMenuOption = isAndroid()
    ? 'Abrir no Chrome'
    : 'Abrir no navegador externo';
  const MenuIcon = () =>
    isAndroid() ? (
      <Icon name="MoreVertical" size="small" />
    ) : (
      <Icon name="MoreHorizontal" size="small" />
    );

  return (
    <Drawer
      open={isOpen}
      anchor="bottom"
      PaperProps={{
        style: {
          borderTopLeftRadius: borders.radius.large,
          borderTopRightRadius: borders.radius.large,
          paddingTop: spacing.inset.large,
          paddingBottom: spacing.inset.large,
          width: '100%'
        }
      }}
      data-testid="open-in-browser-drawer"
    >
      <Stack
        flexDirection="row"
        alignItems="start"
        paddingX={spacing.inset.xxxsmall}
        paddingBottom={spacing.inset.xxxsmall}
      >
        <IconButton
          sx={{ padding: spacing.inset.xsmall }}
          onClick={onClickClose}
          variant="blank"
          data-testid="drawer-close-icon"
        >
          <Icon
            name="ActionClose"
            color={colors.interaction['on-secondary']}
            size="medium"
          />
        </IconButton>
      </Stack>
      <Stack
        paddingY={spacing.inset.medium}
        paddingX={spacing.inset.large}
        gap={spacing.stack.xxxsmall}
      >
        <span
          style={{
            fontSize: typography.subtitle.large.size,
            lineHeight: typography.subtitle.large.height,
            fontWeight: typography.subtitle.large.weight
          }}
        >
          Entre no Google com mais facilidade!
        </span>
        <List disablePadding>
          {[
            <>
              {' '}
              Clique no menu <MenuIcon /> no canto superior direito.{' '}
            </>,
            `Toque em "${openOnBrowserMenuOption}".`,
            'Faça login com a conta Google.'
          ].map((key, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={idx} disablePadding>
              <ListItemText
                sx={{
                  fontSize: typography.body.text.large.size,
                  lineHeight: typography.body.text.large.height,
                  fontWeight: typography.body.text.large.weight
                }}
                primary={
                  <>
                    {idx + 1}. {key}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Stack>
      <Box alignSelf="end" paddingX={spacing.inset.xxxsmall}>
        <MuiButton
          variant="text"
          size="large"
          sx={{ padding: spacing.inset.medium }}
          onClick={onClickClose}
        >
          <span style={{ fontSize: '16px' }}>Fechar</span>
        </MuiButton>
      </Box>
    </Drawer>
  );
};

AskUserToOpenOnBrowser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClickClose: PropTypes.func.isRequired
};

export default SignIn;
