import { Box, SwipeableDrawer, Typography } from '@mui/material';
import { noop } from 'lodash';
import { Address } from 'models';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FocusTrap from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import { blue, root } from 'theme/colors';
import { Button } from 'UI/components/button/button.component';
import LineInputBase from 'UI/components/line-inputs/line-input-base.component';

export const AddressNumberDrawer = ({
  address,
  inputRef,
  isOpen,
  onChange
}) => {
  const { t } = useTranslation('ui');
  const [addressNumber, setAddressNumber] = useState(null);

  const handleChange = ({ target }) =>
    setAddressNumber(String(target.value).trim());

  return (
    <SwipeableDrawer
      anchor="bottom"
      onClose={noop}
      onOpen={noop}
      open={isOpen}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.56)',
          height: '100vh',
          justifyContent: 'flex-end'
        }
      }}
      variant="persistent"
    >
      <Box
        alignItems="center"
        bgcolor={root[0]}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="auto"
        style={{ borderRadius: '16px 16px 0 0' }}
        px={3}
        py={2}
      >
        <Box alignSelf="flex-start" color={root[900]} pt={3}>
          <Typography color="inherit" variant="subtitle2">
            {t('addressAutocomplete.addressNumberDrawer.title')}
          </Typography>
        </Box>
        <Box alignSelf="flex-start" py={4}>
          <Typography variant="body2">
            <b>{address.structured.main}</b>
          </Typography>
          <Typography variant="body2">
            {address.structured.secondary}
          </Typography>
        </Box>
        <Box width="100%">
          <FocusTrap>
            <LineInputBase inputRef={inputRef} onChange={handleChange} />
          </FocusTrap>
        </Box>
        <Box pb={1} pt={2} width="100%">
          <Button
            disabled={!addressNumber}
            fullWidth
            label={t('addressAutocomplete.addressNumberDrawer.continueButton')}
            onClick={() => onChange(addressNumber)}
            size="lg"
          />
        </Box>
        <Button
          fullWidth
          label={t(
            'addressAutocomplete.addressNumberDrawer.withoutNumberButton'
          )}
          onClick={() => onChange('')}
          size="lg"
          textColor={blue[500]}
          variant="outlined"
        />
      </Box>
    </SwipeableDrawer>
  );
};

AddressNumberDrawer.defaultProps = { inputRef: null };
AddressNumberDrawer.propTypes = {
  address: PropTypes.instanceOf(Address).isRequired,
  isOpen: PropTypes.bool.isRequired,
  inputRef: PropTypes.shape(),
  onChange: PropTypes.func.isRequired
};

export default { AddressNumberDrawer };
