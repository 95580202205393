import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentDestinationByGoogleAlertAnswered extends CRMBaseEvent {
  constructor({ addressInputed, addressSelected, answered }) {
    super();
    this.eventName = EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_ALERT_ANSWERED;
    this.payload = {
      address_inputed: addressInputed,
      address_selected: addressSelected,
      answered
    };
  }
}
