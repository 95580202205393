import { useAmplifyAuth } from '@loggi/authentication-lib';
import { FORGOT_PASSWORD_EMAIL_KEY } from 'constants/index';
import { useSnackbar } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ForgotPasswordPage } from 'UI/pages/forgot-password/forgot-password.page';
import { Link } from '@material-ui/core';
import { KNOWN_ERRORS, THROWBACK_ERRORS } from './forgot-password.constants';

const HELP_LINK =
  'https://ajuda.loggi.com/hc/pt-br/requests/new?ticket_form_id=448027?utm_source=beyond';

function ForgotPassword() {
  const history = useHistory();
  const { t } = useTranslation('containers');
  const {
    forgotPassword,
    sendPasswordRecovery,
    state: { error: authError }
  } = useAmplifyAuth();
  const showSnackbar = useSnackbar();

  const email = useMemo(() => {
    const query = new URLSearchParams(history.location.search.substring(1));
    return query.get(FORGOT_PASSWORD_EMAIL_KEY);
  }, [history.location.search]);

  const handleSubmit = ({ confirmationCode, newPassword }) =>
    sendPasswordRecovery(email, confirmationCode, newPassword);

  const handleRequestConfirmationCode = async () => {
    await forgotPassword(email);
  };

  useEffect(() => {
    if (authError) {
      let errorMessageKey = KNOWN_ERRORS.find(msg =>
        authError.message?.message?.includes(msg)
      );

      if (!errorMessageKey) {
        errorMessageKey = KNOWN_ERRORS.includes(authError?.message?.code)
          ? authError.message.code
          : 'default';
      }
      const i18nKey = `forgotPassword.errorMessages.${errorMessageKey}`;
      const linkTag = (
        <Link
          target="_blank"
          href={HELP_LINK}
          underline="always"
          color="inherit"
        />
      );

      const errorMessage = (
        <Trans
          t={t}
          i18nKey={i18nKey}
          components={[linkTag]}
          detail={authError.message.message}
        />
      );

      showSnackbar({
        message: errorMessage,
        severity: 'error'
      });

      if (THROWBACK_ERRORS.includes(errorMessageKey)) {
        history.goBack();
      }
    }
  }, [authError, showSnackbar, t, history]);

  return (
    <ForgotPasswordPage
      onGoBack={history.goBack}
      onSubmit={handleSubmit}
      onRequestConfirmationCode={handleRequestConfirmationCode}
    />
  );
}

export default ForgotPassword;
