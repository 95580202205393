import React from 'react';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from 'theme/custom-classes';
import { SvgIcon } from 'UI/components';
import { root } from 'theme/colors';

export default function Header({
  buttonsColors,
  children,
  isFirst,
  onBack,
  onClose
}) {
  const { header } = useStyles();

  return (
    <Box className={header} mb={2} px="16px !important">
      <Box
        display="grid"
        gridTemplateColumns="48px 1fr 48px"
        gridTemplateAreas={'"back child close"'}
        width="100%"
      >
        <Box gridArea="back">
          <IconButton
            onClick={isFirst ? onClose : onBack}
            data-testid="informations-header-back"
          >
            <SvgIcon icon="chevronLeft" size="24" color={buttonsColors} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gridArea="child"
        >
          {children}
        </Box>
        <Box gridArea="close">
          <IconButton onClick={onClose} data-testid="informations-header-close">
            <SvgIcon icon="delete" size="32" color={buttonsColors} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

Header.defaultProps = {
  buttonsColors: root[900],
  children: null
};

Header.propTypes = {
  buttonsColors: PropTypes.string,
  children: PropTypes.node,
  isFirst: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};
