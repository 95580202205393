import { get } from 'lodash';
import { blue, grey, root } from 'theme/colors';

export const getDisabledStyle = (disabled, variant) => {
  if (!disabled) return null;
  if (variant === 'outlined') {
    return { borderColor: grey[100], color: grey[200] };
  }
  return { backgroundColor: blue[50], color: blue[500] };
};

export const dimensions = {
  medium: {
    height: '56px',
    iconSize: '32',
    width: '56px'
  },
  small: {
    height: '40px',
    iconSize: '24',
    width: '40px'
  }
};

export const getStylesByVariant = (variant, borderColor) => {
  const styles = {
    contained: { backgroundColor: blue[500], color: root[0] },
    outlined: {
      backgroundColor: 'transparent',
      border: `1px solid ${borderColor || blue[500]}`,
      color: blue[500]
    }
  };
  return get(styles, variant, styles.contained);
};
