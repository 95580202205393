import { useSnackbar } from 'hooks';
import {
  hasWebViewInterface,
  hasCreateNativeCorpOrder,
  switchToNativeApp,
  createNativeCorpOrder,
  useNativeAppEventHandler
} from 'mobile';
import { createSwitchToNativeEvent } from 'mobile/native-app-event';
import { PropTypes } from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { NativeSignInErrorAlert, RedirectDialog } from 'UI/components';
import { useDomainUseCase } from 'UI/contexts';

const getUtmSource = () => {
  const atBudget = window.location.pathname.includes('orcamento');
  if (atBudget) return 'beyond-orcamento';
  return 'beyond';
};

const getCorpUrl = ({ idToken }) => {
  const ssoUrl = process.env.REACT_APP_SSO_URL;
  const corpUrl = process.env.REACT_APP_CORP_URL;
  const utmSource = getUtmSource();
  return `${ssoUrl}?utm_source=${utmSource}&next=${corpUrl}#t=${idToken}`;
};

export const CorpMiddlewareContext = createContext({});

export const CorpMiddlewareProvider = ({ children }) => {
  const { t } = useTranslation('ui');
  const showSnackbar = useSnackbar();
  const { setNativeLoginErrorHandler } = useNativeAppEventHandler();
  const [url, setUrl] = useState('');
  const [isDialogCorpOpen, setIsDialogOpen] = useState(false);
  const [shouldMoveOrderToCorp, setShouldMoveOrderToCorp] = useState(false);
  const getCurrentAuthorizationToken = useDomainUseCase(
    'getCurrentAuthorizationTokenUseCase'
  );

  const getIdToken = async () => {
    const hasNativeEngine = hasWebViewInterface();
    const typeErrorMessage = hasNativeEngine
      ? 'handleSwitchToNativeApp'
      : 'handleOpenDialog';
    const exceptionMessage = `${typeErrorMessage} - idToken is null`;
    const { idToken } = await getCurrentAuthorizationToken(exceptionMessage);
    if (!idToken)
      return showSnackbar({
        message: t('error.unableToGetAuthorizationToken'),
        severity: 'error'
      });
    return idToken;
  };

  const openCorpOnBrowser = idToken => {
    setUrl(getCorpUrl({ idToken }));
    setIsDialogOpen(true);
  };

  const nativeLoginErrorHandler = error =>
    showSnackbar({
      message: <NativeSignInErrorAlert error={error} />,
      severity: 'error'
    });

  const openCorp = async (isWithoutDialog = false) => {
    const idToken = await getIdToken();
    if (!idToken) return idToken;
    if (isWithoutDialog === true)
      return window.location.replace(getCorpUrl({ idToken }));
    if (!hasWebViewInterface()) return openCorpOnBrowser(idToken);

    /** Remove the below line approach, which was left for legacy reasons (see {@link native-app-event-handler.context.js}) */
    setNativeLoginErrorHandler(nativeLoginErrorHandler);

    return switchToNativeApp(
      createSwitchToNativeEvent({ idToken, hasBeyondAccess: true })
    ).catch(nativeLoginErrorHandler);
  };

  const createCorpOrder = async ({ pickup, delivery }) => {
    if (!hasCreateNativeCorpOrder()) return openCorp();
    const idToken = await getIdToken();
    if (!idToken) return idToken;
    if (!hasCreateNativeCorpOrder()) return openCorpOnBrowser(idToken);

    return createNativeCorpOrder({
      hasBeyondAccess: true,
      idToken,
      pickup,
      delivery
    }).catch(({ error, origin }) => {
      switch (origin) {
        case 'switchToNativeApp':
          nativeLoginErrorHandler(error); // error handler temporario, pois aqui o contexto é outro, então as mensagens devem ser outras também
          throw new Error(`Native authentication failure - reason: ${error}`);
        case 'fillNativeCorpOrder':
          throw new Error(error);
        default:
          throw new Error(error);
      }
    });
  };

  return (
    <CorpMiddlewareContext.Provider
      value={{
        openCorp,
        createCorpOrder,
        shouldMoveOrderToCorp,
        setShouldMoveOrderToCorp
      }}
    >
      {children}
      <RedirectDialog
        open={isDialogCorpOpen}
        onClose={() => setIsDialogOpen(false)}
        title={t('dialogToCorp.dialogTitle')}
        url={url}
      />
    </CorpMiddlewareContext.Provider>
  );
};

CorpMiddlewareProvider.propTypes = { children: PropTypes.node.isRequired };

/** @typedef {{status: ('created'|'notCreated'), orderId: Number}} CorpOrderResult */
/**
 * @returns {{
 * openCorp: (isWithoutDialog: boolean) => Promise<void>,
 * createCorpOrder: () => Promise<CorpOrderResult>,
 * shouldMoveOrderToCorp: Boolean,
 * setShouldMoveOrderToCorp: (Boolean) => void
 * }}
 */
export const useCorp = () => {
  return useContext(CorpMiddlewareContext);
};
