import { loggiWebApi } from '@loggi/authentication-lib';

const cepApi = {
  async getCepInfos({ cep }) {
    const BEYOND_API_URL = process.env.REACT_APP_PY_BEYOND_URL;
    const url = `${BEYOND_API_URL}/cep/v1/cep/${cep}`;

    return loggiWebApi
      .url(url, true)
      .content('application/json')
      .get()
      .json();
  }
};

export default cepApi;
