export const KNOWN_ERRORS = [
  'UserNotFoundException',
  'LimitExceededException',
  'NetworkError',
  'DefaultException',
  'CodeMismatchException',
  'ExpiredCodeException',
  'InvalidUserException',
  'NotAuthorizedException',
  'APIMigrationError',
  'CompanyCpfValidationException'
];

export const THROWBACK_ERRORS = [
  'default',
  'UserNotFoundException',
  'LimitExceededException',
  'InvalidUserException',
  'NotAuthorizedException',
  'APIMigrationError',
  'CompanyCpfValidationException'
];
