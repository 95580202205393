import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue, red, root } from 'theme/colors';
import { Button } from 'UI/components';
import CustomDialog from '../custom-dialog.styled';

const AccountDeleteAlert = ({ onContinue, onClose, open, title, url }) => {
  const { t } = useTranslation('ui');

  const handleContinue = () => {
    onContinue();
    onClose();
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Box color={root[900]} margin="28px 30px 32px" textAlign="center">
        <Typography variant="body2">
          <strong>{title}</strong>
        </Typography>
      </Box>
      <Box mb={1} width="calc(100% - 60px)">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              label={t('dialogActions.dismiss')}
              onClick={onClose}
              textColor={blue[500]}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              bgColor={red[500]}
              fullWidth
              to={url}
              label={t('dialogActions.continue')}
              onClick={handleContinue}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

AccountDeleteAlert.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default AccountDeleteAlert;
