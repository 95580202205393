import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';

export default class ShipmentAddressByCepOriginInfosFailed extends CRMBaseEvent {
  constructor({
    cep,
    state,
    city,
    address,
    number,
    district,
    complement,
    errorMessage
  }) {
    super();
    this.eventName = EVENTS.SHIPMENT_ADDRESS_BY_CEP_ORIGIN_INFOS_FAILED;
    this.payload = {
      cep,
      state,
      city,
      address,
      number,
      district,
      complement,
      error_message: errorMessage
    };
  }
}
